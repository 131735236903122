const actions = {

  USERSPWDCHANGE_UPDATE_BEGIN: 'USERSPWDCHANGE_UPDATE_BEGIN',
  USERSPWDCHANGE_UPDATE_SUCCESS: 'USERSPWDCHANGE_UPDATE_SUCCESS',
  USERSPWDCHANGE_UPDATE_ERR: 'USERSPWDCHANGE_UPDATE_ERR',
  
  userspwdchangeUpdateBegin: () => {
    return {
      type: actions.USERSPWDCHANGE_UPDATE_BEGIN,
    };
  },

  userspwdchangeUpdateSuccess: (data) => {
    return {
      type: actions.USERSPWDCHANGE_UPDATE_SUCCESS,
      data,
    };
  },

  userspwdchangeUpdateErr: (err) => {
    return {
      type: actions.USERSPWDCHANGE_UPDATE_ERR,
      err,
    };
  },

};

export default actions;
