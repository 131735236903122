import actions from './actions';

const {
  BADGES_ADD_BEGIN,
  BADGES_ADD_SUCCESS,
  BADGES_ADD_ERR,
  
  BADGES_READ_BEGIN,
  BADGES_READ_SUCCESS,
  BADGES_READ_ERR,
  
  BADGES_UPDATE_BEGIN,
  BADGES_UPDATE_SUCCESS,
  BADGES_UPDATE_ERR,
  
  BADGES_DELETE_BEGIN,
  BADGES_DELETE_SUCCESS,
  BADGES_DELETE_ERR,
} = actions;

const initialState = {
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
};

const updateStateSingle = {
  data: null,
  loading: false,
  error: null,
};

const BADGESReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case BADGES_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case BADGES_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case BADGES_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const BADGESADDReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case BADGES_ADD_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case BADGES_ADD_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case BADGES_ADD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };


    default:
      return state;
  }
};

const BADGESDELETEReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case BADGES_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case BADGES_DELETE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case BADGES_DELETE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const BADGESUpdateReducer = (state = updateStateSingle, action) => {
  const { type, data, err } = action;
  switch (type) {
    
    case BADGES_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case BADGES_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case BADGES_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};
export { BADGESReducer, BADGESUpdateReducer, BADGESADDReducer, BADGESDELETEReducer };
