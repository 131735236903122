import {
  UilUsersAlt,
  UilGraduationCap,
  UilBookOpen,
  UilArrowGrowth,
  UilAward,
  UilCalender,
  UilBookAlt,
  UilUserMd,
  UilEye,
  UilApps,
  UilTable,
  UilPresentationCheck,
  UilWrench,
  UilUniversity,
  UilMeetingBoard,
  UilSync
} from '@iconscout/react-unicons';
import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
import { getItem } from '../utility/localStorageControl';

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getMenuItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const path = '/admin';

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const username = getItem('username')

  let specialMenuItems = []
  
  username == "sai6868" &&  
  specialMenuItems.push(
    getMenuItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/evaluate`}>
        {t('Evaluate')}
      </NavLink>,
      'evaluate',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/evaluate`}>
          <UilPresentationCheck />
        </NavLink>
      ),
      null,
    )
  )  

  const items = [

    getMenuItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
        {t('Dashboard')}
      </NavLink>,
      'dashboard',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
          <UilApps />
        </NavLink>
      ),
      null
    ),
    getMenuItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/colleges`}>
        {t('Colleges')}
      </NavLink>,
      'colleges',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/colleges`}>
          <UilUniversity />
        </NavLink>
      ),
      null
    ),
    getMenuItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/cohorts`}>
        {t('Batches')}
      </NavLink>,
      'cohorts',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/cohorts`}>
          <UilCalender />
        </NavLink>
      ),
      null
    ),

    getMenuItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/students`}>
        {t('Students')}
      </NavLink>,
      'students',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/students`}>
          <UilUsersAlt />
        </NavLink>
      ),
      null,
    ),

    getMenuItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/subjects`}>
        {t('Subjects')}
      </NavLink>,
      'subjects',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/subjects`}>
          <UilBookAlt />
        </NavLink>
      ),
      null
    ),
    getMenuItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/teachers`}>
        {t('Teachers')}
      </NavLink>,
      'teachers',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/teachers`}>
          <UilUserMd />
        </NavLink>
      ),
      null
    ),

    getMenuItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/topics`}>
        {t('Topics')}
      </NavLink>,
      'topics',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/topics`}>
          <UilBookOpen />
        </NavLink>
      ),
      null
    ),

    // getMenuItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/badges`}>
    //     {t('Badges')}
    //   </NavLink>,
    //   'Badges',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/badges`}>
    //       <UilAward />
    //     </NavLink>
    //   ),
    //   null,
    // ),
    getMenuItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/schedules`}>
        Schedules
      </NavLink>,
      'schedules',
      !topMenu &&
      <NavLink className="menuItem-iocn" to={`${path}/schedules`}>
        <UilCalender />
      </NavLink>,
    ),
    ...specialMenuItems,
    getMenuItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/interviews`}>
        {t('Interview Results')}
      </NavLink>,
      'interviews',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/interviews`}>
          <UilMeetingBoard />
        </NavLink>
      ),
      null,
    ),
    // getMenuItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/sync`}>
    //     {t('Sync')}
    //   </NavLink>,
    //   'sync',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/sync`}>
    //       <UilSync />
    //     </NavLink>
    //   ),
    //   null,
    // ),

    // getMenuItem(t('Assign'), 'Assign', !topMenu && <UilWrench />, [
    //   getMenuItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/students-cohort`}>
    //       {t('Student to a Cohort')}
    //     </NavLink>,
    //     'students-cohort',
    //     null,
    //   ),
    //   getMenuItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/teachers-cohort`}>
    //       {t('Teachers to a Cohort')}
    //     </NavLink>,
    //     'teachers-cohort',
    //     null,
    //   ),
    //   getMenuItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/subjects-cohort`}>
    //       {t('Subjects to a Cohort')}
    //     </NavLink>,
    //     'subjects-cohort',
    //     null,
    //   ),
    // ]),

    getMenuItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/resetpassword`}>
        {t('Reset Password')}
      </NavLink>,
      'resetpassword',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/resetpassword`}>
          <UilWrench />
        </NavLink>
      ),
      null,
    ),

    // getMenuItem(t('Operations'), 'Operations', !topMenu && <UilWrench />, [
    //   getMenuItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/resetpassword`}>
    //       {t('Reset Password')}
    //     </NavLink>,
    //     'resetpassword',
    //     null,
    //   ),
    //   getMenuItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/notice`}>
    //       {t('Notice')}
    //     </NavLink>,
    //     'notice',
    //     null,
    //   ),
    // ]),
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
            `${mainPathSplit.length === 1 ? 'dashboard' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
            }`,
          ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
