import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { getItem } from '../../utility/localStorageControl';

const SubjectsView = lazy(() => import('../../container/teacher/Subjects'))
const NotFound = lazy(() => import('../../container/pages/404'));


function SubjectRoutes() {
  return (
    <Routes>
      {/* <Route path="/subjects" element={<SubjectsView/>} /> */}
      {/* <Route path="/subject/:subjectId/unit/:unitId/topic/:topicId/*" element={<TopicRoutes />} />            */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default SubjectRoutes;