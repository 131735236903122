import { message, notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';

const {

  evaluateWithAudioBegin,
  evaluateWithAudioSuccess,
  evaluateWithAudioErr,
  
  evaluateWithoutAudioBegin,
  evaluateWithoutAudioSuccess,
  evaluateWithoutAudioErr,
  
  reEvaluateSingleBegin,
  reEvaluateSingleSuccess,
  reEvaluateSingleErr

} = actions;

const evaluateWithAudio = (scheduleName) => {
  return async (dispatch) => {
    try {
      await dispatch(evaluateWithAudioBegin());
      const query = await DataService.get(`/interview-attempts/evalwithaudioflask/${scheduleName}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(evaluateWithAudioSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(evaluateWithAudioSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(evaluateWithAudioSuccess(null));
        }
      }
      else {
        await dispatch(evaluateWithAudioSuccess(null));
      }
    } catch (err) {
      await dispatch(evaluateWithAudioErr(err));
    }
  };
};

const evaluateWithoutAudio = (scheduleName) => {
  return async (dispatch) => {
    try {
      await dispatch(evaluateWithoutAudioBegin());
      const query = await DataService.get(`/interview-attempts/evalwithoutaudio/${scheduleName}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(evaluateWithoutAudioSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(evaluateWithoutAudioSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(evaluateWithoutAudioSuccess(null));
        }
      }
      else {
        await dispatch(evaluateWithoutAudioSuccess(null));
      }
    } catch (err) {
      await dispatch(evaluateWithoutAudioErr(err));
    }
  };
};

const ReEvaluateSingle = (scheduleName, rollno) => {
  return async (dispatch) => {
    try {
      await dispatch(reEvaluateSingleBegin());
      const query = await DataService.get(`/interview-attempts/singleevalwithaudio/${scheduleName}/${rollno}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(reEvaluateSingleSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(reEvaluateSingleSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(reEvaluateSingleSuccess(null));
        }
      }
      else {
        await dispatch(reEvaluateSingleSuccess(null));
      }
    } catch (err) {
      await dispatch(reEvaluateSingleErr(err));
    }
  };
};

export {
  evaluateWithAudio,
  evaluateWithoutAudio,
  ReEvaluateSingle
};
