import actions from './actions';

const {

  CREATE_TOPIC_BEGIN,
  CREATE_TOPIC_SUCCESS,
  CREATE_TOPIC_ERR,

  TOPIC_SINGLE_DATA_BEGIN,
  TOPIC_SINGLE_DATA_SUCCESS,
  TOPIC_SINGLE_DATA_ERR,

  TOPIC_STATUS_UPDATE_BEGIN,
  TOPIC_STATUS_UPDATE_SUCCESS,
  TOPIC_STATUS_UPDATE_ERR,

  TOPICS_READ_BEGIN,
  TOPICS_READ_SUCCESS,
  TOPICS_READ_ERR,

  TOPIC_CLEAR_BEGIN,
  TOPIC_CLEAR_SUCCESS,
  TOPIC_CLEAR_ERR,

  STUDENT_TOPICS_READ_BEGIN,
  STUDENT_TOPICS_READ_SUCCESS,
  STUDENT_TOPICS_READ_ERR,

  TOPICS_BY_SUBJECT_ID_BEGIN,
  TOPICS_BY_SUBJECT_ID_SUCCESS,
  TOPICS_BY_SUBJECT_ID_ERR,

  IMPORT_TOPIC_BEGIN,
  IMPORT_TOPIC_SUCCESS,
  IMPORT_TOPIC_ERR

} = actions;


const createTopicInitialState = {
  data: null,
  loading: false,
  error: null,
};

const initialStateSingle = {
  data: null,
  loading: false,
  error: null,
};


const topicStatusInitial = {
  data: null,
  loading: false,
  error: null,
};

const studentTopicStatusInitial = {
  data: null,
  loading: false,
  error: null,
};

const topicsInitial = {
  data: null,
  loading: false,
  error: null,
};

const SubjectTopicsState = {
  data: [],
  loading: false,
  error: null,
};

const importTopicInitialState = {
  data: null,
  loading: false,
  error: null,
};


const CreateTopicReducer = (state = createTopicInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CREATE_TOPIC_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CREATE_TOPIC_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case CREATE_TOPIC_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const TOPICSingleReducer = (state = initialStateSingle, action) => {
  const { type, data, err } = action;
  switch (type) {
    case TOPIC_SINGLE_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TOPIC_SINGLE_DATA_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TOPIC_SINGLE_DATA_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const TOPICSTATUSReducer = (state = topicStatusInitial, action) => {
  const { type, data, err } = action;
  switch (type) {
    case TOPIC_STATUS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TOPIC_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TOPIC_STATUS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};


const TOPICSByUnitReducer = (state = topicsInitial, action) => {
  const { type, data, err } = action;
  switch (type) {
    case TOPICS_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TOPICS_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TOPICS_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const StudentTopicsByUnitReducer = (state = studentTopicStatusInitial, action) => {
  const { type, data, err } = action;
  switch (type) {
    case STUDENT_TOPICS_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case STUDENT_TOPICS_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case STUDENT_TOPICS_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const TopicClearReducer = (state = topicsInitial, action) => {
  const { type, data, err } = action;
  switch (type) {
    case TOPIC_CLEAR_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TOPIC_CLEAR_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TOPIC_CLEAR_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const TopicsBySubjectIdReducer = (state = SubjectTopicsState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case TOPICS_BY_SUBJECT_ID_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TOPICS_BY_SUBJECT_ID_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TOPICS_BY_SUBJECT_ID_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};


const ImportTopicReducer = (state = importTopicInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case IMPORT_TOPIC_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case IMPORT_TOPIC_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case IMPORT_TOPIC_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { CreateTopicReducer, TOPICSingleReducer, TOPICSTATUSReducer, TOPICSByUnitReducer, TopicClearReducer, StudentTopicsByUnitReducer, TopicsBySubjectIdReducer, ImportTopicReducer };
