const actions = {

  READ_TOPICS_BEGIN: 'READ_TOPICS_BEGIN',
  READ_TOPICS_SUCCESS: 'READ_TOPICS_SUCCESS',
  READ_TOPICS_ERR: 'READ_TOPICS_ERR',
  
  READ_TOPIC_CONTENT_BEGIN: 'READ_TOPIC_CONTENT_BEGIN',
  READ_TOPIC_CONTENT_SUCCESS: 'READ_TOPIC_CONTENT_SUCCESS',
  READ_TOPIC_CONTENT_ERR: 'READ_TOPIC_CONTENT_ERR',



  ReadTopicsBegin: () => {
    return {
      type: actions.READ_TOPICS_BEGIN,
    };
  },

  ReadTopicsSuccess: (data) => {    
    return {
      type: actions.READ_TOPICS_SUCCESS,      
      data,
    };
  },

  ReadTopicsErr: (err) => {
    return {
      type: actions.READ_TOPICS_ERR,
      err,
    };
  },
  
  ReadTopicContentBegin: () => {
    return {
      type: actions.READ_TOPIC_CONTENT_BEGIN,
    };
  },

  ReadTopicContentSuccess: (data) => {    
    return {
      type: actions.READ_TOPIC_CONTENT_SUCCESS,      
      data,
    };
  },

  ReadTopicContentErr: (err) => {
    return {
      type: actions.READ_TOPIC_CONTENT_ERR,
      err,
    };
  },
};

export default actions;
