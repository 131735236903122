import React, { Suspense, useEffect, useState } from 'react';
import { Card, Col, Form, Input, Row, Spin, Upload, message } from 'antd';
import UilExport from "@iconscout/react-unicons/icons/uil-export";
import Heading from '../../components/heading/heading';
import { Button } from '../../components/buttons/buttons';
import { ImportStyleWrap } from './styles/styled';
import { FormValidationWrap } from '../forms/overview/Style';
import { NoteCardWrap } from '../note/style';
import { TopicContentSubmit } from '../../redux/uploadContent/actionCreator';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const { Dragger } = Upload;

const FileUpload = ({ topicStatus }) => {

  const [form] = Form.useForm();

  const navigate = useNavigate()
  const dispatch = useDispatch();

  const { topicId } = useParams()

  const { UploadRes, isUploading } = useSelector((state) => {
    return {
      UploadRes: state.UploadContentRes.data,
      isUploading: state.UploadContentRes.loading,
    };
  });

  const [textFile, setTextFile] = useState(null);
  const [textFileList, setTextFileList] = useState([])

  const [pdfFile, setPdfFile] = useState(null);
  const [pdfFileList, setPdfFileList] = useState([])

  const handleTextFileChange = (uploadedFile) => {
    const reader = new FileReader();
    reader.onload = (uploadedFile) => {
      const fileContent = uploadedFile.target.result;      
      if (fileContent === "") {
        setTextFile(null);
        setTextFileList([])
        return message.error("Empty .text file is not allowed!")
      }
    };
    reader.readAsText(textFile);
    if (uploadedFile !== null && uploadedFile.fileList.length !== 0 && uploadedFile.fileList[0].type === "text/plain") {
      setTextFileList(uploadedFile.fileList);
    }
    else if (uploadedFile !== null && uploadedFile.fileList.length !== 0 && uploadedFile.fileList[0].type !== "text/plain") {
      message.error("Only .txt file is allowed")
      setTextFile(null);
      setTextFileList([])
    }
  };

  const handleRemoveTextFile = () => {
    message.warn("File has been removed")
    setTextFile(null);
    setTextFileList([])
  }

  const handlePdfChange = (uploadedFile) => {
    var sizeInMB = (uploadedFile !== null && uploadedFile.file.size / (1024 * 1024)).toFixed(0);
    if (parseInt(sizeInMB) <= 10) {
      if (uploadedFile !== null && uploadedFile.fileList.length !== 0 && uploadedFile.fileList[0].type === "application/pdf") {
        setPdfFileList(uploadedFile.fileList);
      }
      else if (uploadedFile !== null && uploadedFile.fileList.length !== 0 && uploadedFile.fileList[0].type !== "application/pdf") {
        message.error("Only .pdf file is allowed")
        setPdfFile(null);
        setPdfFileList([])
      }
    }
    else {
      message.error("Pdf file size exceeds max limit 10MB")
      setPdfFile(null);
      setPdfFileList([])
    }
  };

  const handleRemovePdfFile = () => {
    message.warn("File has been removed")
    setPdfFile(null);
    setPdfFileList([])
  }

  useEffect(() => {
    if (UploadRes !== null && UploadRes.message === "updated") {
      form.resetFields()
      setTextFile(null);
      setPdfFile(null);
      setTextFileList([])
      setPdfFileList([])
    }
  }, [UploadRes])

  const handleFileUpload = (formData) => {
    if (textFile !== null && pdfFile !== null && topicId > 0) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContent = e.target.result;
        if (fileContent !== "") {
          const data = new FormData();
          data.append("id", parseInt(topicId))
          data.append("videourl", formData.fileVideourl === undefined ? null : formData.fileVideourl)
          data.append("videodescription", formData.videodescription === undefined ? null : formData.videodescription)
          data.append("content", fileContent)
          data.append("pdf", pdfFile)
          // posting data to the server
          dispatch(TopicContentSubmit(data, topicId));
        }
        else {
          message.error("Empty .text file is not allowed!")
        }
      };
      reader.readAsText(textFile);
    }
    else {
      message.error('Select file(s) to upload.');
    }
  };

  // const fileUploadProps = {
  //   name: 'file',
  //   multiple: true,
  //   action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  //   onChange(info) {
  //     const { status } = info.file;
  //     if (status !== 'uploading') {
  //       setState({ ...state, file: info.file, list: info.fileList });
  //     }
  //     if (status === 'done') {
  //       // message.success(`${info.file.name} file uploaded successfully.`);
  //     } else if (status === 'error') {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //   },
  //   listType: 'picture',
  //   defaultFileList: [],
  //   showUploadList: {
  //     showRemoveIcon: true,
  //     removeIcon: <UilTrashAlt />,
  //   },
  // };

  return (
    <div>
      {
        isUploading ?
          <div className="custom-spin">
            <CustomSpinner />
          </div>
          :
          <FormValidationWrap>
            <Form id="fileupload_uploadform" name="sDash_validation-form" form={form} layout="vertical" onFinish={handleFileUpload}>
              <Form.Item
                name="pdf"
                rules={[{ required: true, message: 'Study Material is required!' }]}
                label="Upload Topic Study Material"
              >
                <ImportStyleWrap>
                  <Dragger
                    id="pdf-file-input"
                    maxCount={1}
                    beforeUpload={uploadedFile => {
                      if (uploadedFile !== null) {
                        setPdfFile(uploadedFile);
                        return false;
                      }
                    }}
                    onChange={handlePdfChange}
                    onRemove={handleRemovePdfFile}
                    fileList={pdfFileList}
                    accept='.pdf'
                  >
                    <p className="ant-upload-drag-icon">
                      <UilExport />
                    </p>
                    <Heading as="h4" className="ant-upload-text">
                      Click here to select Topic Study Material (.pdf) file
                    </Heading>
                  </Dragger>
                </ImportStyleWrap>
              </Form.Item>

              <Form.Item
                name="fileContent"
                rules={[{ required: true, message: 'Text file required!' }]}
                label="Upload Topic Text Content for Q & A"
              >
                <ImportStyleWrap>
                  <Dragger
                    id="txt-file-input"
                    maxCount={1}
                    beforeUpload={uploadedFile => {
                      setTextFile(uploadedFile);
                      return false;
                    }}
                    onChange={handleTextFileChange}
                    onDrop={(e) => console.log(e)}
                    onRemove={() => handleRemoveTextFile()}
                    fileList={textFileList}
                    accept='.txt'
                  >
                    <p className="ant-upload-drag-icon">
                      <UilExport />
                    </p>
                    <Heading as="h4" className="ant-upload-text">
                      Click here to select Topic Text Content (.txt) file
                    </Heading>
                  </Dragger>
                </ImportStyleWrap>
              </Form.Item>

              <Form.Item
                name="fileVideourl"
                rules={[{ required: false, message: 'Input is not valid URL', type: "url" }]}
                label="Video URL"
              >
                <Input id="video-url-input" size="medium" type='url' placeholder="Video URL" />
              </Form.Item>

              <Form.Item
                name="videodescription"
                rules={[{ required: false, message: '', type: 'string' }]}
                label="Video Description"
              >
                <Input id="video-desc-input" size="medium" placeholder="Video Description" />
              </Form.Item>

              <div className='text-right mb-20'>
                <Button id="fileupload_upload-button" htmlType="submit" type="primary" >
                  Upload
                </Button>
              </div>
            </Form>
          </FormValidationWrap>
      }
    </div>
  )
}

export default FileUpload
