import { readAnswersBegin, readAnswersSuccess, readAnswersError } from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { message } from 'antd';
import { getItem } from '../../utility/localStorageControl';


export const getAnswersData = (subId, unitId, topicId) => {
    return async (dispatch) => {
        try {
            await dispatch(readAnswersBegin());
            const response = await DataService.get(`/studentmaster/studentans/${topicId}`);
            const token = getItem('access_token');
            const data = response.data;
            if (token && data.Error === false) {
                await dispatch(readAnswersSuccess(data.payload));
            }
            else if (data !== null && data.Error === true) {
                if (Object.keys(data).length >= 3 && data.statusCode == 401) {
                    message.error(data.message)
                    await dispatch(readAnswersSuccess([]));
                    dispatch(logOut())
                }
                else {
                    await dispatch(readAnswersSuccess([]));
                }
            }
            else {
                await dispatch(readAnswersSuccess([]));
            }
        } catch (error) {
            await dispatch(readAnswersError(error));
        }
    };
};
