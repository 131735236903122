import React from 'react'
import { BorderLessHeading, TableDefaultStyle } from '../../styled';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { TopSellerWrap } from '../styles/dashboardStyle';
import { Table } from 'antd';

export default function ScheduledTopics({ studentSchedules }) {

    const columns = [
        {
            title: 'Subject',
            dataIndex: 'subjectname',
            key: 'subjectname',
        },
        {
            title: 'Topic',
            dataIndex: 'topicname',
            key: 'topicname',
        },
        {
            title: 'Deadline',
            dataIndex: 'deadline',
            key: 'deadline',
        },
    ];

    let data = []

    studentSchedules.length !== 0 && studentSchedules.map((obj, index) => {
        data.push({
            key: index,
            id: obj.id,
            subjectId: obj.topic?.subject?.id,
            subjectname: obj.topic?.subject?.name,
            topicId: obj.topic?.id,
            topicname: obj.topic?.name,
            deadline: obj.deadline,
            // link: <div className='table-actions'>
            //   <Link to={`subject/${subjectId}/unit/${unitId}/topic/${topicId}`}><UilExternalLinkAlt style={{ width: '18px' }} /></Link>
            // </div>
        })
    })

    return (
        <div>
            <BorderLessHeading>
                <Cards title='Scheduled Topics'>
                    <TableDefaultStyle className="ninjadash-having-header-bg" style={{ height: '325px' }} >
                        <TopSellerWrap>
                            <div className="table-bordered top-seller-table table-responsive">
                                <Table columns={columns} dataSource={data} pagination={false} />
                            </div>
                        </TopSellerWrap>
                    </TableDefaultStyle>
                </Cards>
            </BorderLessHeading>
        </div>
    )
}
