export const READ_STUDENT_RECENT_ACTIONS_BEGIN = 'READ_STUDENT_RECENT_ACTIONS_BEGIN';
export const READ_STUDENT_RECENT_ACTIONS_SUCCESS = 'READ_STUDENT_RECENT_ACTIONS_SUCCESS';
export const READ_STUDENT_RECENT_ACTIONS_ERROR = 'READ_STUDENT_RECENT_ACTIONS_ERROR';

export const readStudentActionsBegin = () => ({
    type: READ_STUDENT_RECENT_ACTIONS_BEGIN,
});

export const readStudentActionsSuccess = (data) => ({
    type: READ_STUDENT_RECENT_ACTIONS_SUCCESS,
    data,
});

export const readStudentActionsError = (err) => ({
    type: READ_STUDENT_RECENT_ACTIONS_ERROR,
    err,
});
