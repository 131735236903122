import react from 'react'

function TopicStatusId(topicStatus)
{
    const statusArray = [
        'NOTHING',
        'CONTENT UPLOADED',
        'CONTENT REVIEWED',
        'QUESTIONS GENERATED',
        'QUESTIONS REVIEWED',
        'QUIZ GENERATED',
        'QUIZ REVIEWED',
        'PROGRAMS VERIFY',
        'CONTENT FREEZED'
    ]

    const index = statusArray.indexOf(topicStatus)
    return index        
}

export default TopicStatusId