import actions from './actions'

const {
    SUBJECT_READ_BEGIN,
    SUBJECT_READ_SUCCESS,
    SUBJECT_READ_ERR,

    GENERATE_QUESTIONS_OF_TOPIC_READ_BEGIN,
    GENERATE_QUESTIONS_OF_TOPIC_READ_SUCCESS,
    GENERATE_QUESTIONS_OF_TOPIC_READ_ERR,

    GET_QUESTIONS_OF_TOPIC_READ_BEGIN,
    GET_QUESTIONS_OF_TOPIC_READ_SUCCESS,
    GET_QUESTIONS_OF_TOPIC_READ_ERR,

    TEACHER_SUBJECTS_READ_BEGIN,
    TEACHER_SUBJECTS_READ_SUCCESS,
    TEACHER_SUBJECTS_READ_ERR,

    TOPICS_OF_SUBJECT_READ_BEGIN,
    TOPICS_OF_SUBJECT_READ_SUCCESS,
    TOPICS_OF_SUBJECT_READ_ERR,

    TOPIC_UPDATE_BEGIN,
    TOPIC_UPDATE_SUCCESS,
    TOPIC_UPDATE_ERR,

    REGENERATE_QUESTIONS_BEGIN,
    REGENERATE_QUESTIONS_SUCCESS,
    REGENERATE_QUESTIONS_ERR,

    ACCEPT_OR_REJECT_QUESTION_BEGIN,
    ACCEPT_OR_REJECT_QUESTION_SUCCESS,
    ACCEPT_OR_REJECT_QUESTION_ERR,

    UNIT_READ_BEGIN,
    UNIT_READ_SUCCESS,
    UNIT_READ_ERR,

    UPDATE_QUESTION_BEGIN,
    UPDATE_QUESTION_SUCCESS,
    UPDATE_QUESTION_ERR,

    CREATE_QUESTION_BEGIN,
    CREATE_QUESTION_SUCCESS,
    CREATE_QUESTION_ERR,

    IMPORT_QUESTIONS_BEGIN,
    IMPORT_QUESTIONS_SUCCESS,
    IMPORT_QUESTIONS_ERR,
} = actions


const initialState = {
    data: [],
    loading: false,
    error: null,
};

const importquestionsinitialState = {
    data: [],
    loading: false,
    error: null,
};

const createquestioninitialState = {
    data: [],
    loading: false,
    error: null,
};

const regeneratequestionsinitialState = {
    data: [],
    loading: false,
    error: null,
};

const updatequestioninitialState = {
    data: [],
    loading: false,
    error: null,
};

const acceptorrejectquestioninitialstate = {
    data: [],
    loading: false,
    error: null,
};

const topicsofSubjectinitialState = {
    data: null,
    loading: false,
    error: null,
};

const GenerateQuestionsinitialState = {
    data: null,
    loading: false,
    error: null,
};

const TopicUpdateinitialState = {
    data: null,
    loading: false,
    error: null,
};

const GetQuestionsinitialState = {
    data: null,
    loading: false,
    error: null,
};

const initialStateTopics = {
    data: [],
    loading: false,
    error: null,
};

const initialStateUnit = {
    data: null,
    loading: false,
    error: null,
};

const TeacherSubjectsReducer = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {

        case TEACHER_SUBJECTS_READ_BEGIN:
            return {
                ...state,
                loading: true,
            };

        case TEACHER_SUBJECTS_READ_SUCCESS:
            return {
                ...state,
                data,
                error: false,
                loading: false,
            };

        case TEACHER_SUBJECTS_READ_ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const ImportQuestionsReducer = (state = importquestionsinitialState, action) => {
    const { type, data, err } = action;
    switch (type) {

        case IMPORT_QUESTIONS_BEGIN:
            return {
                ...state,
                loading: true,
            };

        case IMPORT_QUESTIONS_SUCCESS:
            return {
                ...state,
                data,
                error: false,
                loading: false,
            };

        case IMPORT_QUESTIONS_ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const RegenerateQUESTIONS_Reducer = (state = regeneratequestionsinitialState, action) => {
    const { type, data, err } = action;
    switch (type) {

        case REGENERATE_QUESTIONS_BEGIN:
            return {
                ...state,
                loading: true,
            };

        case REGENERATE_QUESTIONS_SUCCESS:
            return {
                ...state,
                data,
                error: false,
                loading: false,
            };

        case REGENERATE_QUESTIONS_ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const CreateQUESTION_Reducer = (state = createquestioninitialState, action) => {
    const { type, data, err } = action;
    switch (type) {

        case CREATE_QUESTION_BEGIN:
            return {
                ...state,
                loading: true,
            };

        case CREATE_QUESTION_SUCCESS:
            return {
                ...state,
                data,
                error: false,
                loading: false,
            };

        case CREATE_QUESTION_ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const UPDATE_QUESTIONReducer = (state = updatequestioninitialState, action) => {
    const { type, data, err } = action;
    switch (type) {

        case UPDATE_QUESTION_BEGIN:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_QUESTION_SUCCESS:
            return {
                ...state,
                data,
                error: false,
                loading: false,
            };

        case UPDATE_QUESTION_ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const SUBJECTReducer = (state = topicsofSubjectinitialState, action) => {
    const { type, data, err } = action;
    switch (type) {

        case SUBJECT_READ_BEGIN:
            return {
                ...state,
                loading: true,
            };

        case SUBJECT_READ_SUCCESS:
            return {
                ...state,
                data,
                error: false,
                loading: false,
            };

        case SUBJECT_READ_ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const QUESTIONSOF_TOPICReducer = (state = GetQuestionsinitialState, action) => {
    const { type, data, err } = action;
    switch (type) {

        case GET_QUESTIONS_OF_TOPIC_READ_BEGIN:
            return {
                ...state,
                loading: true,
            };

        case GET_QUESTIONS_OF_TOPIC_READ_SUCCESS:
            return {
                ...state,
                data,
                error: false,
                loading: false,
            };

        case GET_QUESTIONS_OF_TOPIC_READ_ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const TOPICSOF_SUBJECTReducer = (state = initialStateTopics, action) => {
    const { type, data, err } = action;
    switch (type) {

        case TOPICS_OF_SUBJECT_READ_BEGIN:
            return {
                ...state,
                loading: true,
            };

        case TOPICS_OF_SUBJECT_READ_SUCCESS:
            return {
                ...state,
                data,
                error: false,
                loading: false,
            };

        case TOPICS_OF_SUBJECT_READ_ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const GENERATE_QUESTIONSReducer = (state = GenerateQuestionsinitialState, action) => {
    const { type, data, err } = action;
    switch (type) {

        case GENERATE_QUESTIONS_OF_TOPIC_READ_BEGIN:
            return {
                ...state,
                loading: true,
            };

        case GENERATE_QUESTIONS_OF_TOPIC_READ_SUCCESS:
            return {
                ...state,
                data,
                error: false,
                loading: false,
            };

        case GENERATE_QUESTIONS_OF_TOPIC_READ_ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const ACCEPTORREJECT_Reducer = (state = acceptorrejectquestioninitialstate, action) => {
    const { type, data, err } = action;
    switch (type) {

        case ACCEPT_OR_REJECT_QUESTION_BEGIN:
            return {
                ...state,
                loading: true,
            };

        case ACCEPT_OR_REJECT_QUESTION_SUCCESS:
            return {
                ...state,
                data,
                error: false,
                loading: false,
            };

        case ACCEPT_OR_REJECT_QUESTION_ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const TopicUpdateReducer = (state = TopicUpdateinitialState, action) => {
    const { type, data, err } = action;
    switch (type) {

        case TOPIC_UPDATE_BEGIN:
            return {
                ...state,
                loading: true,
            };

        case TOPIC_UPDATE_SUCCESS:
            return {
                ...state,
                data,
                error: false,
                loading: false,
            };

        case TOPIC_UPDATE_ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const UNITReducer = (state = initialStateUnit, action) => {
    const { type, data, err } = action;
    switch (type) {

        case UNIT_READ_BEGIN:
            return {
                ...state,
                loading: true,
            };

        case UNIT_READ_SUCCESS:
            return {
                ...state,
                data,
                error: false,
                loading: false,
            };

        case UNIT_READ_ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

export { TeacherSubjectsReducer, ImportQuestionsReducer, RegenerateQUESTIONS_Reducer,  CreateQUESTION_Reducer, UPDATE_QUESTIONReducer,  ACCEPTORREJECT_Reducer, SUBJECTReducer, UNITReducer, QUESTIONSOF_TOPICReducer, TOPICSOF_SUBJECTReducer, GENERATE_QUESTIONSReducer, TopicUpdateReducer };