import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { getItem } from '../../utility/localStorageControl';
import { message } from 'antd';


const {
  teacherSubjectsReadBegin,
  teacherSubjectsReadSuccess,
  teacherSubjectsReadErr,

  subjectReadBegin,
  subjectReadSuccess,
  subjectReadErr,

  unitReadBegin,
  unitReadSuccess,
  unitReadErr,

  topicsofSubjectReadBegin,
  topicsofSubjectReadSuccess,
  topicsofSubjectReadErr,

  generateQuestionsofTopicReadBegin,
  generateQuestionsofTopicReadSuccess,
  generateQuestionsofTopicReadErr,

  GetQuestionsOfTopicReadBegin,
  GetQuestionsOfTopicReadSuccess,
  GetQuestionsOfTopicReadErr,

  TopicUpdateBegin,
  TopicUpdateSuccess,
  TopicUpdateErr,

  AcceptOrRejectQuestionBegin,
  AcceptOrRejectQuestionSuccess,
  AcceptOrRejectQuestionErr,

  UpdateQuestionBegin,
  UpdateQuestionSuccess,
  UpdateQuestionErr,

  CreateQuestionBegin,
  CreateQuestionSuccess,
  CreateQuestionErr,

  RegenerateQuestionsBegin,
  RegenerateQuestionsSuccess,
  RegenerateQuestionsErr,

  ImportQuestionsBegin,
  ImportQuestionsSuccess,
  ImportQuestionsErr,
} = actions;


const teacherSubjectsDataRead = () => {
  return async (dispatch) => {
    try {
      // const userId = getItem('userId')
      await dispatch(teacherSubjectsReadBegin());
      const query = await DataService.get(`/teacher-subject-cohort/subjects`);
      const token = getItem('access_token');
      const data = query.data;
      if (token && data !== null && data.Error === false) {
        await dispatch(teacherSubjectsReadSuccess(query.data.payload));
      }
      else {
        await dispatch(teacherSubjectsReadSuccess([]));
        // await dispatch(teacherSubjectsAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(teacherSubjectsReadErr(err));
    }
  };
};

const topicsOfSubjectDataRead = (id) => {
  return async (dispatch) => {
    try {
      await dispatch(topicsofSubjectReadBegin());
      const query = await DataService.get(`/topic-master/topics/${id}`);
      const token = getItem('access_token');
      const data = query.data;
      if (token && data !== null && data.Error === false) {
        await dispatch(topicsofSubjectReadSuccess(query.data.payload));
      }
      else {
        await dispatch(topicsofSubjectReadErr([]));
      }
    } catch (err) {
      await dispatch(topicsofSubjectReadErr(err));
    }
  };
};

const GetQuestionsOfTopicDataRead = (id) => {
  return async (dispatch) => {
    try {
      await dispatch(GetQuestionsOfTopicReadBegin());
      const query = await DataService.get(`/question-master/find/${id}`);
      const token = getItem('access_token');
      const data = query.data;
      if (token && data !== null && data.Error === false) {
        await dispatch(GetQuestionsOfTopicReadSuccess(query.data.payload));
      }
      else {
        await dispatch(GetQuestionsOfTopicReadErr(null));
      }
    } catch (err) {
      await dispatch(GetQuestionsOfTopicReadErr(err));
    }
  };
};

const GenerateQuestionsOfTopic = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(generateQuestionsofTopicReadBegin());
      const query = await DataService.post(`/question-master/generatequestions`,values);
      const token = getItem('access_token');
      const data = query.data;
      if (token && data !== null && data.Error === false) {
        await dispatch(generateQuestionsofTopicReadSuccess(query.data));        
      }
      else {
        message.error(query.data.message);
        await dispatch(generateQuestionsofTopicReadErr(null));
      }
    } catch (err) {
      await dispatch(generateQuestionsofTopicReadErr(err));
      message.error(err);
    }
  };
};

const ReGenerateQuestionsOfTopic = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(RegenerateQuestionsBegin());
      const query = await DataService.post(`/question-master/regeneratequestions`,values);
      const token = getItem('access_token');
      const data = query.data;
      if (token && data !== null && data.Error === false) {
        await dispatch(RegenerateQuestionsSuccess(query.data));
        message.success('Questions Regenerated');
      }
      else {
        message.error(query.data.message);
        await dispatch(RegenerateQuestionsErr(null));
      }
    } catch (err) {
      await dispatch(RegenerateQuestionsErr(err));
      message.error(err);
    }
  };
};

const ImportQuestionsOfTopic = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(ImportQuestionsBegin());
      const query = await DataService.post(`/question-master/bulkupload`,values);
      const token = getItem('access_token');
      const data = query.data;
      if (token && data !== null && data.Error === false) {
        await dispatch(ImportQuestionsSuccess(query.data));
        query.data.payload.dupCount > 0 ? message.success(`${query.data.message} with ${query.data.payload.dupCount} duplicates`) : message.success(query.data.message);
      }
      else {
        message.error(query.data.message);
        await dispatch(ImportQuestionsErr(null));
      }
    } catch (err) {
      await dispatch(ImportQuestionsErr(err));
      message.error(err);
    }
  };
};

const CreateQuestionOfTopic = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(CreateQuestionBegin());
      const query = await DataService.post(`/question-master/create`,values);
      const token = getItem('access_token');
      const data = query.data;
      if (token && data !== null && data.Error === false) {
        await dispatch(CreateQuestionSuccess(query.data));
        message.success('Created Successfully');
      }
      else {
        message.error(query.data.message);
        await dispatch(CreateQuestionErr(null));
      }
    } catch (err) {
      await dispatch(CreateQuestionErr(err));
      message.error(err);
    }
  };
};

const TopicUpdate = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(TopicUpdateBegin());
      const query = await DataService.put(`/topic-master/update`,values);
      const token = getItem('access_token');
      const data = query.data;
      if (token && data !== null && data.Error === false) {        
        await dispatch(TopicUpdateSuccess(data));
        message.success(data.message);
      }
      else {
        message.error(data.message);
        await dispatch(TopicUpdateErr(null));
      }
    } catch (err) {
      await dispatch(TopicUpdateErr(err));
      message.error(err);
    }
  };
};

const UpdateQuestion = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(UpdateQuestionBegin());
      const query = await DataService.put(`/question-master/update`,values);
      const token = getItem('access_token');
      const data = query.data;
      if (token && data !== null && data.Error === false) {        
        await dispatch(UpdateQuestionSuccess(data));
        message.success(data.message);
      }
      else {
        message.error(data.message);
        await dispatch(UpdateQuestionErr(null));
      }
    } catch (err) {
      await dispatch(UpdateQuestionErr(err));
      message.error(err);
    }
  };
};

const AcceptOrRejectQuestion = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(AcceptOrRejectQuestionBegin());
      const query = await DataService.put(`/question-master/changestatus`,values);
      const token = getItem('access_token');
      const data = query.data;
      if (token && data !== null && data.Error === false) {        
        await dispatch(AcceptOrRejectQuestionSuccess(data));
        message.success(data.message);
      }
      else {
        message.error(data.message);
        await dispatch(AcceptOrRejectQuestionErr(null));
      }
    } catch (err) {
      await dispatch(AcceptOrRejectQuestionErr(err));
      message.error(err);
    }
  };
};

const getSubjectById = (subjectId) => {
  return async (dispatch) => {
    try {
      await dispatch(subjectReadBegin());
      const query = await DataService.get(`/subjectmaster/${subjectId}`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(subjectReadSuccess(query.data.payload));
      } else {
        await dispatch(subjectReadSuccess(null));
        // await dispatch(subjectsUnitsAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(subjectReadErr(err));
    }
  };
};

const getUnitById = (unitId) => {
  return async (dispatch) => {
    try {
      await dispatch(unitReadBegin());
      const query = await DataService.get(`/unitmaster/${unitId}`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(unitReadSuccess(query.data.payload));
      } else {
        await dispatch(unitReadSuccess(null));
        // await dispatch(subjectsUnitsAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(unitReadErr(err));
    }
  };
};


export {
  teacherSubjectsDataRead,
  getSubjectById,
  getUnitById,
  topicsOfSubjectDataRead,
  GenerateQuestionsOfTopic,
  GetQuestionsOfTopicDataRead,
  TopicUpdate,
  AcceptOrRejectQuestion,
  UpdateQuestion,
  CreateQuestionOfTopic,
  ReGenerateQuestionsOfTopic,
  ImportQuestionsOfTopic,
};