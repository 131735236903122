import { DataService } from '../../config/dataService/dataService';
import { 
    getPdfFileBegin, 
    getPdfFileSuccess, 
    getPdfFileError,

} from './actions';
import { getItem } from '../../utility/localStorageControl';

const getPdfFile = (pdfPath) => {
    return async (dispatch) => {
        try {
            await dispatch(getPdfFileBegin());
            const response = await DataService.get(`${pdfPath}`);
            const token = getItem('access_token');
            const data = response.data;            
            if (token) {
                await dispatch(getPdfFileSuccess(data));
            } else {                
            }
        } catch (error) {
            await dispatch(getPdfFileError(error));
        }
    };
};

export {getPdfFile}