export const READ_STUDENT_BADGES_BEGIN = 'READ_STUDENT_BADGES_BEGIN';
export const READ_STUDENT_BADGES_SUCCESS = 'READ_STUDENT_BADGES_SUCCESS';
export const READ_STUDENT_BADGES_ERROR = 'READ_STUDENT_BADGES_ERROR';

export const READ_BADGE_BY_ID_BEGIN = 'READ_BADGE_BY_ID_BEGIN';
export const READ_BADGE_BY_ID_SUCCESS = 'READ_BADGE_BY_ID_SUCCESS';
export const READ_BADGE_BY_ID_ERROR = 'READ_BADGE_BY_ID_ERROR';

export const READ_SUB_BADGE_BY_ID_BEGIN = 'READ_SUB_BADGE_BY_ID_BEGIN';
export const READ_SUB_BADGE_BY_ID_SUCCESS = 'READ_SUB_BADGE_BY_ID_SUCCESS';
export const READ_SUB_BADGE_BY_ID_ERROR = 'READ_SUB_BADGE_BY_ID_ERROR';

export const readStudentBadgesBegin = () => ({
    type: READ_STUDENT_BADGES_BEGIN,
});

export const readStudentBadgesSuccess = (data) => ({
    type: READ_STUDENT_BADGES_SUCCESS,
    data,
});

export const readStudentBadgesError = (err) => ({
    type: READ_STUDENT_BADGES_ERROR,
    err,
});

export const readBadgeByIdBegin = () => ({
    type: READ_BADGE_BY_ID_BEGIN,
});

export const readBadgeByIdSuccess = (data) => ({
    type: READ_BADGE_BY_ID_SUCCESS,
    data,
});

export const readBadgeByIdError = (err) => ({
    type: READ_BADGE_BY_ID_ERROR,
    err,
});

export const readSubBadgeByIdBegin = () => ({
    type: READ_SUB_BADGE_BY_ID_BEGIN,
});

export const readSubBadgeByIdSuccess = (data) => ({
    type: READ_SUB_BADGE_BY_ID_SUCCESS,
    data,
});

export const readSubBadgeByIdError = (err) => ({
    type: READ_SUB_BADGE_BY_ID_ERROR,
    err,
});
