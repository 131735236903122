import actions from './actions';

const {
  READ_TOPICS_BEGIN,
  READ_TOPICS_SUCCESS,
  READ_TOPICS_ERR,

  READ_TOPIC_CONTENT_BEGIN,
  READ_TOPIC_CONTENT_SUCCESS,
  READ_TOPIC_CONTENT_ERR
} = actions;

const initialState = {
  data: [],    
  loading: false,
  error: null,
};

const initialContentState = {
  data: null,    
  loading: false,
  error: null,
};

const TOPICSReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case READ_TOPICS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case READ_TOPICS_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case READ_TOPICS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const TOPICContentReducer = (state = initialContentState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case READ_TOPIC_CONTENT_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case READ_TOPIC_CONTENT_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case READ_TOPIC_CONTENT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { TOPICSReducer, TOPICContentReducer };
