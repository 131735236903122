import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { GetSingleSubjectSummary } from '../../redux/studentPerformance/actionCreator'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Cards } from '../../components/cards/frame/cards-frame'
import { NoteCardWrap } from '../note/style'
import { Col, Result, Row } from 'antd'
import CustomSpinner from '../ui-elements/spinner/CustomSpinner'
import { OverviewDataStyleWrap } from './styles/dashboardStyle'
import OverviewCard from '../../components/cards/OverviewCard'
import { UilSuitcase, UilStopwatch, UilShieldQuestion, UilAward, UilAwardAlt, UilBookmark, UilBullseye } from '@iconscout/react-unicons'

export default function SingleSubjectSummary({ customWidth }) {
    const { subjectId, subjectName } = useParams()
    const dispatch = useDispatch()

    const { singleSubjectSummary, singleSubjectSummaryLoading } = useSelector((state) => {
        return {
            singleSubjectSummary: state.singleSubjectSummary.data,
            singleSubjectSummaryLoading: state.singleSubjectSummary.loading,
        }
    })

    useEffect(() => {
        dispatch(GetSingleSubjectSummary(subjectId))
    }, [subjectId])

    const [summaryData, setSummaryData] = useState([])
    useEffect(() => {
        const Data = [
            {
                key: 1,
                type: "primary",
                icon: <UilStopwatch />,
                label: "Total Time Spent (Mins)",
                total: singleSubjectSummary?.TotalTimeSpentInMinutes || 0,
            },
            {
                key: 2,
                type: "primary",
                icon: <UilShieldQuestion />,
                label: "Total Questions Answered",
                total: singleSubjectSummary?.TotalQuestionsAnswered || 0,
            },
            // {
            //     key: 1,
            //     type: "primary",
            //     icon: <UilSuitcase />,
            //     label: "Topics Attempted",
            //     total: singleSubjectSummary?.TopicsAttempted,
            // },
            {
                key: 3,
                type: "primary",
                icon: <UilBullseye />,
                label: "Interviews Taken",
                total: singleSubjectSummary?.InterviewsTaken || 0,
            },
            {
                key: 4,
                type: "primary",
                icon: <UilBookmark />,
                label: "Easy Interviews",
                total: singleSubjectSummary?.EasyInterviewsTaken || 0,
            },
            {
                key: 5,
                type: "primary",
                icon: <UilAwardAlt />,
                label: "Intermediate Interviews",
                total: singleSubjectSummary?.IntermediateInterviewsTaken || 0,
            },
            {
                key: 6,
                type: "primary",
                icon: <UilAward />,
                label: "Advanced Interviews",
                total: singleSubjectSummary?.AdvancedInterviewsTaken || 0,
            },
        ]
        setSummaryData(Data)
    }, [singleSubjectSummary])

    return (
        <div>
            <Cards headless>
                <h2 className='mb-20 text-center'>{subjectName}</h2>
                <NoteCardWrap style={customWidth}>
                    {
                        singleSubjectSummaryLoading ?
                            <div className="custom-spin">
                                <CustomSpinner Message={'Loading Summary'} marginTop={40} />
                            </div>
                            // :
                            // singleSubjectSummary == null ?
                            //     <div>
                            //         <Result
                            //             title="Summary not found"
                            //             status={'warning'}
                            //         />
                            //     </div>
                            :
                            summaryData.length !== 0 &&
                            <div>
                                <OverviewDataStyleWrap>
                                    <Row gutter={25}>
                                        {summaryData.map((item, i) => {
                                            return (
                                                <Col xxl={8} sm={12} xs={24} key={i}>
                                                    <OverviewCard data={item} contentFirst bottomStatus={false} />
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </OverviewDataStyleWrap>
                            </div>
                    }


                </NoteCardWrap>
            </Cards>
        </div>
    )
}
