import React from 'react'
import { NoteCardWrap } from '../note/style'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import CustomSpinner from '../ui-elements/spinner/CustomSpinner'
import TopicStatusId from './TopicStatusId'
import { Result } from 'antd'
import { Button } from '../../components/buttons/buttons';
import { useEffect } from 'react'
import { getTopicContentByTopic } from '../../redux/teacherTopicsMenu/actionCreator'

function ViewTxtFile({ topicStatus }) {
  const { subjectId, unitId, topicId } = useParams()
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { topicContent, isTopicContentLoading } = useSelector((state) => {
    return {
      topicContent: state.TopicContent.data,
      isTopicContentLoading: state.TopicContent.loading,
    };
  });

  useEffect(() => {
    dispatch(getTopicContentByTopic(topicId))
  },[])
  
  return (
    <div>
      <NoteCardWrap>
        {
          TopicStatusId(topicStatus) === 0 ?
            <div className='' style={{ fontSize: '18px' }}>
              <Result
                status="warning"
                title="Content not found"
                extra={
                  <Button id="viewtxt_uploadcontent-button" type="primary" key="upload" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/upload`)}>
                    Upload
                  </Button>
                }
              />
            </div>
            :
            TopicStatusId(topicStatus) === 1 ?
              <Result
                status="warning"
                title="Review Content"
                extra={
                  <Button id="viewtxt_reviewcontent-button" type="primary" key="navigateToReviewContent" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/review/content`)}>
                    Review
                  </Button>
                }
              />
              :
              TopicStatusId(topicStatus) === 2 ?
                <Result
                  status="warning"
                  title="Questions and Answers not found"
                  extra={
                    <Button id="viewtxt_generateqa-button" type="primary" key="generateqa" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/generate/questions`)}>
                      Generate Q & A
                    </Button>
                  }
                />
                :
                TopicStatusId(topicStatus) == 3 ?
                  <Result
                    status="warning"
                    title="Questions and Answers generated"
                    extra={
                      <Button id="viewtxt_reviewqa-button" type="primary" key="reviewqa" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/review/questions`)}>
                        Review Q & A
                      </Button>
                    }
                  />
                  :
                  TopicStatusId(topicStatus) == 4 ?
                    <Result
                      status="warning"
                      title="MCQS not found"
                      extra={
                        <Button id="viewtxt_generatemcq-button" type="primary" key="generatemcqs" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/review/quiz`)}>
                          Generate MCQS
                        </Button>
                      }
                    />
                    :
                    TopicStatusId(topicStatus) == 5 ?
                      <Result
                        status="warning"
                        title="MCQS has been generated for this topic"
                        extra={
                          <Button id="viewtxt_reviewmcq-button" type="primary" key="navigateToReviewmcqs" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/review/quiz`)}>
                            Review MCQS
                          </Button>
                        }
                      />
                      :
                      TopicStatusId(topicStatus) == 6 ?
                        <Result
                          status="warning"
                          title="MCQS has been reviewed. Verify programs"
                          extra={
                            <Button id="viewtxt_verifyprograms-button" type="primary" key="verifyprograms" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/programs`)}>
                              Verify Programs
                            </Button>
                          }
                        />
                        :
                        TopicStatusId(topicStatus) === 7 ?
                          <div>
                            <Result
                              status='success'
                              title="Programs Verified"
                              extra={
                                <Button id="viewtxt_freezetopic-button" type="primary" key="freeze topic" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/freezeschedule`)}>
                                  Freeze Topic
                                </Button>
                              }
                            />
                          </div>
                          :
                          isTopicContentLoading ?
                            <div className="custom-spin">
                              <CustomSpinner />
                            </div>
                            :
                            <div>
                              {
                                topicContent !== null && <p>{topicContent.content}</p>
                              }
                            </div>
        }
      </NoteCardWrap>
    </div>
  )
}

export default ViewTxtFile