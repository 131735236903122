import actions from './actions';

const {
  GET_INTERVIEW_QUESTIONS_BEGIN,
  GET_INTERVIEW_QUESTIONS_SUCCESS,
  GET_INTERVIEW_QUESTIONS_ERR,

  SAVE_INTERVIEW_BEGIN,
  SAVE_INTERVIEW_SUCCESS,
  SAVE_INTERVIEW_ERR,

  SKIP_INTERVIEW_BEGIN,
  SKIP_INTERVIEW_SUCCESS,
  SKIP_INTERVIEW_ERR,

  SKIP_AND_SUBMIT_INTERVIEW_BEGIN,
  SKIP_AND_SUBMIT_INTERVIEW_SUCCESS,
  SKIP_AND_SUBMIT_INTERVIEW_ERR,

  SUBMIT_INTERVIEW_BEGIN,
  SUBMIT_INTERVIEW_SUCCESS,
  SUBMIT_INTERVIEW_ERR,

  GET_INTERVIEW_BY_TOPIC_ID_BEGIN,
  GET_INTERVIEW_BY_TOPIC_ID_SUCCESS,
  GET_INTERVIEW_BY_TOPIC_ID_ERR,

  GET_INTERVIEW_BY_SCHEDULE_BEGIN,
  GET_INTERVIEW_BY_SCHEDULE_SUCCESS,
  GET_INTERVIEW_BY_SCHEDULE_ERR,

  GET_INTERVIEW_ATTEMPTS_BEGIN,
  GET_INTERVIEW_ATTEMPTS_SUCCESS,
  GET_INTERVIEW_ATTEMPTS_ERR,

  QUIT_TIMEOUT_INTERVIEW_BEGIN,
  QUIT_TIMEOUT_INTERVIEW_SUCCESS,
  QUIT_TIMEOUT_INTERVIEW_ERR,

  GET_STUDENT_INTERVIEWS_BEGIN,
  GET_STUDENT_INTERVIEWS_SUCCESS,
  GET_STUDENT_INTERVIEWS_ERR,

} = actions;

const interviewQuestionsInitialState = {
  data: null,
  loading: false,
  error: null,
};

const saveInitialState = {
  data: null,
  loading: false,
  error: null,
}

const skipInitialState = {
  data: null,
  loading: false,
  error: null,
}

const skipandsubmitInitialState = {
  data: null,
  loading: false,
  error: null,
}

const submitInitialState = {
  data: null,
  loading: false,
  error: null,
}

const InterviewsByTopicInitialState = {
  data: [],
  loading: false,
  error: null,
}

const InterviewsByScheduleInitialState = {
  data: [],
  loading: false,
  error: null,
}

const interviewAttemptsInitialState = {
  data: [],
  loading: false,
  error: null,
}

const quitTimeoutInitialState = {
  data: null,
  loading: false,
  error: null,
}

const studentInterviewsInitialState = {
  data: [],
  loading: false,
  error: null,
}

const GetInterviewQuestionsReducer = (state = interviewQuestionsInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case GET_INTERVIEW_QUESTIONS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_INTERVIEW_QUESTIONS_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_INTERVIEW_QUESTIONS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const SaveInterviewReducer = (state = saveInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SAVE_INTERVIEW_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case SAVE_INTERVIEW_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case SAVE_INTERVIEW_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const SkipInterviewReducer = (state = skipInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SKIP_INTERVIEW_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case SKIP_INTERVIEW_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case SKIP_INTERVIEW_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const SkipandSubmitReducer = (state = skipandsubmitInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SKIP_AND_SUBMIT_INTERVIEW_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case SKIP_AND_SUBMIT_INTERVIEW_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case SKIP_AND_SUBMIT_INTERVIEW_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const SubmitInterviewReducer = (state = submitInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SUBMIT_INTERVIEW_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case SUBMIT_INTERVIEW_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case SUBMIT_INTERVIEW_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const getInterviewsByTopicReducer = (state = InterviewsByTopicInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_INTERVIEW_BY_TOPIC_ID_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_INTERVIEW_BY_TOPIC_ID_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_INTERVIEW_BY_TOPIC_ID_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const getInterviewsByScheduleReducer = (state = InterviewsByScheduleInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_INTERVIEW_BY_SCHEDULE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_INTERVIEW_BY_SCHEDULE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_INTERVIEW_BY_SCHEDULE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const getInterviewAttemptsReducer = (state = interviewAttemptsInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_INTERVIEW_ATTEMPTS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_INTERVIEW_ATTEMPTS_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_INTERVIEW_ATTEMPTS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const QuitorTimeoutInterviewReducer = (state = quitTimeoutInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case QUIT_TIMEOUT_INTERVIEW_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case QUIT_TIMEOUT_INTERVIEW_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case QUIT_TIMEOUT_INTERVIEW_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const StudentInterviewsReducer = (state = studentInterviewsInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_STUDENT_INTERVIEWS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_STUDENT_INTERVIEWS_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_STUDENT_INTERVIEWS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { GetInterviewQuestionsReducer, SaveInterviewReducer, SkipInterviewReducer, SubmitInterviewReducer, SkipandSubmitReducer, getInterviewsByTopicReducer, getInterviewAttemptsReducer, QuitorTimeoutInterviewReducer, getInterviewsByScheduleReducer, StudentInterviewsReducer };
