import React, { useState, useEffect } from 'react';
import { Card, Button, Modal, Input, Upload, message, Form, Select, Result } from 'antd';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import Heading from '../../components/heading/heading';
import Dragger from 'antd/lib/upload/Dragger';
import UilExport from "@iconscout/react-unicons/icons/uil-export";
import { ImportStyleWrap } from '../styled';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import UilTrash from '@iconscout/react-unicons/icons/uil-trash-alt';
import { useDispatch, useSelector } from 'react-redux';
import { badgesDataRead, badgesDataSubmit, badgesDataUpdate, badgesDataDelete } from '../../redux/badges/actionCreator';
import { alertModal } from '../../components/modals/antd-modals';
import classnames from 'classnames';
import './imagedim.css';
import { Tooltip } from 'antd';
import { getItem } from '../../utility/localStorageControl';

const { Option } = Select;

const Badges = () => {
  const loggedadmin = getItem('username');
  let ClgName = '(' + 'ALL' + ')';
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [formTitle, setFormTitle] = useState('');
  const [selectedDescription, setSelectedDescription] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(null);

  const [editIndex, setEditIndex] = useState(null);
  const [dataSource, setDataSource] = useState([]);

  const [imageFile, setImageFile] = useState(null);
  const [imageList, setImageList] = useState([])

  const { Meta } = Card;

  const dispatch = useDispatch();
  const { badges, isLoading, BadgesAddRes, isBadgesUpdating, BadgesUpdateRes, isBadgesAdding, BadgesDeleteRes, isBadgesDeleting } = useSelector((state) => {
    return {
      badges: state.Badges.data,
      isLoading: state.Badges.loading,
      BadgesAddRes: state.BadgesAddRes.data,
      isBadgesAdding: state.BadgesAddRes.loading,
      BadgesUpdateRes: state.BadgesUpdateRes.data,
      isBadgesUpdating: state.BadgesUpdateRes.loading,
      BadgesDeleteRes: state.BadgesDeleteRes.data,
      isBadgesDeleting: state.BadgesDeleteRes.loading,

    };
  });
  useEffect(() => {
    if (dispatch) {
      // dispatch(badgesDataRead());
    }
  }, [dispatch]);

  useEffect(() => {
    if (BadgesAddRes !== null) {
      // dispatch(badgesDataRead());
    }
  }, [BadgesAddRes])

  useEffect(() => {
    if (BadgesUpdateRes !== null) {
      // dispatch(badgesDataRead());
    }
  }, [BadgesUpdateRes])

  useEffect(() => {
    if (BadgesDeleteRes !== null) {
      // dispatch(badgesDataRead());
    }
  }, [BadgesDeleteRes])


  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState('');

  const openImageModal = (imageSrc, title, description) => {
    setSelectedImage(imageSrc);
    setSelectedTitle(title);
    setSelectedDescription(description);
    setIsImageModalVisible(true);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
    setSelectedTitle('');
    setIsImageModalVisible(false);
  };


  const handleResetImage = () => {
    setImageFile(null);
    setImageList([]);
  };

  const handleAddBadge = () => {
    handleResetImage();
    setIsModalVisible(true);
  };

  useEffect(() => {
    if (badges) {
      setDataSource(badges);
    }
  }, [badges]);

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setIsEditModalVisible(false);
    setFormTitle('');
  };

  const handleEditBadge = (index) => {
    const badge = dataSource[index];
    setEditIndex(index);
    setIsEditModalVisible(true);
    editForm.setFieldsValue({
      title: badge.title,
      status: badge.status,
      description: badge.description,
    });
  };

  const showDeleteConfirm = (index) => {
    alertModal.confirm({
      title: `Do you want to delete this badge`,
      content: 'When clicked the OK button, the badge will be deleted and cannot be undone.',
      onOk() {
        const badgeId = dataSource.length !== 0 && dataSource[index].id
        dispatch(badgesDataDelete(badgeId));
      },
      onCancel() { },
    });
  };

  const handleFormSubmit = () => {
    if (imageFile !== null && formTitle && imageFile && form.getFieldValue("description")) {
      const data = new FormData();

      data.append("title", formTitle);
      data.append("image", imageFile)
      data.append("description", form.getFieldValue("description"));

      form.resetFields();
      setFormTitle('');
      setIsModalVisible(false);
      dispatch(badgesDataSubmit(data));
    }
    else {
      message.error('Please enter all fields!');
    }
  }

  const handleFormUpdate = () => {
    if (editIndex !== null && editForm.getFieldValue("status") && editForm.getFieldValue("description") && editForm.getFieldValue("title")) {
      const badgeToUpdate = dataSource[editIndex];
      const updatedTitle = editForm.getFieldValue("title");
      const updatedStatus = editForm.getFieldValue("status");
      const updatedDescription = editForm.getFieldValue("description");
      if (formTitle !== badgeToUpdate.title || selectedStatus !== badgeToUpdate.status) {
        const newRow = {
          id: badgeToUpdate.id,
          title: updatedTitle || badgeToUpdate.title,
          status: updatedStatus || badgeToUpdate.status,
          description: updatedDescription || badgeToUpdate.description,
        };
        setIsEditModalVisible(false);
        dispatch(badgesDataUpdate(newRow));
      } else {
        setIsEditModalVisible(false);
      }
    } else {
      message.error('Plaese enter all fields!');
    }
  };

  const handleRemoveImage = () => {
    setImageFile(null);
    setImageList([]);
  }

  const handleImageChange = (uploadedImage) => {
    if (uploadedImage !== null && uploadedImage.fileList.length !== 0) {
      setImageList(uploadedImage.fileList);
      message.success("File Uploaded");
    }
    else {
      message.warning("File Removed");
      handleRemoveImage()
    }
  };

  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  return (
    <div>
      <PageHeader className="ninjadash-page-header-main" title={`Badges ${loggedadmin === 'superadmin' ? ClgName : ''}`}
        buttons={
          loggedadmin !== 'superadmin' ?
            [
              <Button id="badges-page-add_badge-button" onClick={handleAddBadge} key="AddBadge" type="primary" size="default">
                Add Badge
              </Button>,
            ] : []}
      />

      <Main>
        {
          dataSource.length === 0 ? (
            <div>
              <Result
                title="No Badges found"
                status='warning'
              />
            </div>
          ) :
            (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {dataSource.map((badge, index) => (
                  <Card id="badges-display-card"
                    key={index}
                    style={{ width: '22%', margin: '10px' }}
                    cover={badge.image && (
                      <div
                        className={classnames('inactive-badge-container', {
                          'dim-image': badge.status === 'INACTIVE',
                        })}
                        onClick={() => openImageModal(`${process.env.REACT_APP_API_ENDPOINT}/${badge.image}`, badge.title, badge.description)}
                      >
                        <img alt="Badge" src={`${process.env.REACT_APP_API_ENDPOINT}/${badge.image}`} style={{ width: '100%', height: '100%' }} />
                        {badge.status === 'INACTIVE' && (
                          <span className="inactive-badge">
                            <div>{'INACTIVE'}</div>
                          </span>
                        )}
                      </div>
                    )}
                    actions={[
                      <UilEdit id="edit-badge-button" key="edit" onClick={() => handleEditBadge(index)} />,
                      <UilTrash id="delete-badge-button" key="delete" onClick={() => showDeleteConfirm(index)} />,
                    ]}
                  >
                    <Meta title={
                      <Tooltip title={badge.title}>
                        <span style={{ fontSize: '20px', textAlign: 'center', fontWeight: 'bold' }}>{badge.title}</span>
                      </Tooltip>
                    } />
                  </Card>
                ))}
              </div>
            )
        }
      </Main>
      <Modal
        title={selectedTitle}
        open={isImageModalVisible}
        onCancel={closeImageModal}
        maskClosable={false}
        footer={null}
        centered
      >
        <div>
          <img src={selectedImage} alt="Oops! badge is not available or loaded" style={{ width: '100%' }} />
          <span style={{ fontWeight: 'bold', color: 'black', fontSize: '25px' }}>Description :</span>
          <p style={{ fontWeight: 'bold', fontSize: '20px' }}>{selectedDescription ? selectedDescription : 'no description'}</p>
        </div>
      </Modal>
      <Modal
        title="Add Badge"
        open={isModalVisible}
        onCancel={handleModalCancel}
        maskClosable={false}
        footer={[
          <Button id="add-badge-button" key="AddBadgeRecord" type="primary" onClick={handleFormSubmit}>
            Submit
          </Button>,
        ]}
      >
        <Form id="add-badge-form" form={form} onFinish={handleFormSubmit} layout="vertical">
          <Form.Item label="Title" name="title" rules={[{ required: true, message: 'Please enter title' }]}>
            <Input id="add-badge-enter-title" placeholder="Enter badge title" onChange={(e) => setFormTitle(e.target.value)} />
          </Form.Item>
          <Form.Item label="Description" name="description" rules={[{ required: true, message: 'Please enter description' }]}>
            <Input.TextArea id="add-badge-enter-description" rows={3} placeholder="Enter badge description" />
          </Form.Item>
          <Form.Item name="image" rules={[{ required: true, message: 'Please Upload Image' }]} label="Image">
            <ImportStyleWrap>
              <Dragger id="add-badge-upload-badge"
                maxCount={1}
                beforeUpload={uploadedImage => {
                  if (uploadedImage !== null) {
                    setImageFile(uploadedImage);
                    return false;
                  }
                }}
                onChange={handleImageChange}
                onRemove={handleRemoveImage}
                fileList={imageList}
                accept="image/png"
              >
                <p className="ant-upload-drag-icon">
                  <UilExport />
                </p>
                <Heading as="h4" className="ant-upload-text">
                  Drop or Upload .png Image(Size should be less than 1000KB)
                </Heading>
              </Dragger>
            </ImportStyleWrap>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit Badge"
        open={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        maskClosable={false}
        footer={[
          <Button id="edit-badge-button" key="UpdateBadgeRecord" type="primary" onClick={handleFormUpdate}>
            Update
          </Button>,
        ]}
      >
        <Form id="edit-badge-form" form={editForm} onFinish={handleFormUpdate} layout="vertical">
          <Form.Item label="Title" name="title" rules={[{ required: true, message: 'Please enter title' }]}>
            <Input id="edit-badge-enter-title" onChange={(e) => setFormTitle(e.target.value)} placeholder="Enter badge title" />
          </Form.Item>
          <Form.Item label="Description" name="description" rules={[{ required: true, message: 'Please enter description' }]}>
            <Input.TextArea id="edit-badge-enter-description" rows={3} placeholder="Enter badge description" />
          </Form.Item>
          <Form.Item label="Status" name="status" >
            <Select id="edit-badge-select-badge-status" onChange={(e) => { handleStatusChange(e) }}>
              <Option value="ACTIVE">ACTIVE</Option>
              <Option value="INACTIVE">INACTIVE</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Badges;