import { Cards } from '../../../components/cards/frame/cards-frame';
import { Table } from 'antd';
import { BorderLessHeading, TableDefaultStyle } from '../../styled';
import { TopSellerWrap } from '../styles/dashboardStyle';

function RecentInterviews({ recentInterviews }) {

  const columns = [
    {
      title: 'Subject',
      dataIndex: 'subjectname',
      key: 'subjectname',
    },
    {
      title: 'Topic',
      dataIndex: 'topicname',
      key: 'topicname',
    },
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
    },
    {
      title: 'Questions Answered',
      dataIndex: 'questionsAnswered',
      key: 'questionsAnswered',
    },
    {
      title: 'Attempted On',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Start Time',
      dataIndex: 'starttime',
      key: 'starttime',
    },
    {
      title: 'End Time',
      dataIndex: 'endtime',
      key: 'endtime',
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      key: 'grade',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  let data = []

  recentInterviews.length !== 0 && recentInterviews.map((obj, index) => {
    const { id, subjectname, questionsattempted, topicname, avgscore, starttime, endtime, createdon, status, interviewlevel } = obj
    data.push({
      key: index,
      id,
      subjectname,
      topicname,
      level: interviewlevel,
      questionsAnswered: questionsattempted && questionsattempted === -1 ? 0 : questionsattempted,
      grade: avgscore && avgscore === -1 ? 0 : avgscore,
      date: createdon && new Date(createdon).toLocaleDateString(),
      status,
      starttime : starttime ? new Date(starttime).toLocaleTimeString() : 'NA',
      endtime : endtime ? new Date(endtime).toLocaleTimeString() : 'NA'
      // link: <div className='table-actions'>
      //   <Link to={`subject/${subjectId}/unit/${unitId}/topic/${topicId}`}><UilExternalLinkAlt style={{ width: '18px' }} /></Link>
      // </div>
    })
  })

  return (
    <div>
      <BorderLessHeading>
        <Cards title='Recent Interviews'>
          <TableDefaultStyle className="ninjadash-having-header-bg" style={{ minHeight: '260px' }} >
            <TopSellerWrap>
              <div className="table-bordered top-seller-table table-responsive">
                <Table columns={columns} dataSource={data} pagination={false} />
              </div>
            </TopSellerWrap>
          </TableDefaultStyle>
        </Cards>
      </BorderLessHeading>
    </div>
  );
}

export default RecentInterviews