export const READ_RECENTDATA_BEGIN = 'READ_RECENTDATA_BEGIN';
export const READ_RECENTDATA_SUCCESS = 'READ_RECENTDATA_SUCCESS';
export const READ_RECENTDATA_ERROR = 'READ_RECENTDATA_ERROR';

export const RECENT_INTERVIEWS_BEGIN = 'RECENT_INTERVIEWS_BEGIN';
export const RECENT_INTERVIEWS_SUCCESS = 'RECENT_INTERVIEWS_SUCCESS';
export const RECENT_INTERVIEWS_ERROR = 'RECENT_INTERVIEWS_ERROR';

export const readRecentDataBegin = () => ({
    type: READ_RECENTDATA_BEGIN,
});

export const readRecentDataSuccess = (data) => ({
    type: READ_RECENTDATA_SUCCESS,
    data,
});

export const readRecentDataError = (err) => ({
    type: READ_RECENTDATA_ERROR,
    err,
});

export const reentInterviewsBegin = () => ({
    type: RECENT_INTERVIEWS_BEGIN,
});

export const reentInterviewsSuccess = (data) => ({
    type: RECENT_INTERVIEWS_SUCCESS,
    data,
});

export const reentInterviewsError = (err) => ({
    type: RECENT_INTERVIEWS_ERROR,
    err,
});
