import react, { useState } from 'react'
import CustomSpinner from '../ui-elements/spinner/CustomSpinner'
import { Button } from '../../components/buttons/buttons'
import TopicStatusId from './TopicStatusId'
import { Badge, Form, Modal, Result, Select, message } from 'antd'
import { NoteCardWrap } from '../note/style'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { MapTessellatorTopic, MapTessellatorTopicToNull, TessellatorTopicProgramsDataRead, TessellatorTopicsDataRead } from '../../redux/tessellatorTopics/actionCreator'
import actions from '../../redux/tessellatorTopics/actions'
import { getTopicById, FreezeTopicById } from '../../redux/topics/actionCreator'
import { FormValidationWrap } from '../forms/overview/Style'

const { mapTessellatorTopicUpdateSuccess, tessellatorTopicProgramsReadSuccess, mapTessellatorTopicToNullUpdateSuccess } = actions

const { Option } = Select

function Programs({ topicStatus, testTopicID, topic }) {

    const { subjectId, unitId, topicId } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [form] = Form.useForm();

    const { TessellatorTopics, TessellatorTopicsLoading, TessellatorTopicPrograms, TessellatorTopicProgramsLoading, MapTessellatorTopicRes, MapTessellatorTopicResLoading, MapTsTopicToNullRes, isSettingToNull } = useSelector((state) => {
        return {
            TessellatorTopics: state.TessellatorTopics.data,
            TessellatorTopicsLoading: state.TessellatorTopics.loading,
            TessellatorTopicPrograms: state.TessellatorTopicPrograms.data,
            TessellatorTopicProgramsLoading: state.TessellatorTopicPrograms.loading,
            MapTessellatorTopicRes: state.MapTessellatorTopicRes.data,
            MapTessellatorTopicResLoading: state.MapTessellatorTopicRes.loading,
            MapTsTopicToNullRes: state.MapTsTopicToNullRes.data,
            isSettingToNull: state.MapTsTopicToNullRes.loading,
        };
    });

    const [testTopicId, setTestTopicId] = useState(0)
    const [isProgramsExists, setProgramsExists] = useState(false)
    const [verifyCompletedModal, setVerifyCompletedModal] = useState(false)

    useEffect(() => {
        if (testTopicID) {
            dispatch(TessellatorTopicProgramsDataRead(parseInt(testTopicID)))
        }
    }, [testTopicID])

    useEffect(() => {
        if (MapTsTopicToNullRes !== null && MapTsTopicToNullRes.Error === false) {
            dispatch(mapTessellatorTopicToNullUpdateSuccess(null));
            dispatch(getTopicById(parseInt(topicId)));
        }
    }, [MapTsTopicToNullRes])

    useEffect(() => {
        if (isProgramsExists && subjectId) {
            dispatch(TessellatorTopicsDataRead(subjectId))
        }
    }, [isProgramsExists])

    useEffect(() => {
        if (MapTessellatorTopicRes !== null && MapTessellatorTopicRes.message === "updated") {
            dispatch(getTopicById(parseInt(topicId)));
            setProgramsExists(false)
            setTestTopicId(0)
            message.success("Data Submitted successfully")
            dispatch(mapTessellatorTopicUpdateSuccess(null))
        }
        else if (MapTessellatorTopicRes !== null && (MapTessellatorTopicRes.Error || MapTessellatorTopicRes.message !== "updated")) {
            message.error("Failed to submit")
        }
    }, [MapTessellatorTopicRes])

    const fetchPrograms = (testtopicId) => {
        setTestTopicId(testtopicId)
        if (parseInt(testtopicId) !== 0) {
            dispatch(TessellatorTopicProgramsDataRead(parseInt(testtopicId)))
        }
    }

    const saveTopicMapping = (formData) => {
        if (formData) {
            dispatch(MapTessellatorTopic(parseInt(topicId), parseInt(formData.tessellatorTopic)))
        }
    }


    const onCancel = () => {
        if (testTopicID) {
            dispatch(TessellatorTopicProgramsDataRead(parseInt(testTopicID)))
        }
        setProgramsExists(false);
        setTestTopicId(0)
    }


    const ChangeStatusProgramsVerify = () => {
        dispatch(tessellatorTopicProgramsReadSuccess([]))
        setProgramsExists(false)
        const datatoPost = {
            id: parseInt(topicId),
            status: "PROGRAMS VERIFY"
        }
        dispatch(FreezeTopicById(datatoPost))
        setVerifyCompletedModal(true)
    }

    const resetToNull = () => {
        setProgramsExists(false)
        dispatch(MapTessellatorTopicToNull(parseInt(topicId)))
    }

    const FreezeTopic = () => {
        setVerifyCompletedModal(false)
        navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/freezeschedule`)
    }

    useEffect(() => {
        if (topicStatus === "PROGRAMS VERIFY") {
            setVerifyCompletedModal(true)
        }
    }, [topicStatus])

    return (
        <div>
            <NoteCardWrap>
                {
                    topicStatus === "CONTENT FREEZED" ?
                        <>
                            <Result
                                status="success"
                                title="Content Freezed"
                                subTitle ={`on ${topic !== null && new Date(topic.updatedon).toLocaleString()}`}
                            />
                        </>
                        :
                        TopicStatusId(topicStatus) == 0 ?
                            <div className='' style={{ fontSize: '18px' }}>
                                <Result
                                    status="warning"
                                    title="Content not found"
                                    extra={
                                        <Button id="programs_uploadcontent-button" type="primary" key="upload" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/upload`)}>
                                            Upload
                                        </Button>
                                    }
                                />
                            </div>
                            :
                            TopicStatusId(topicStatus) === 1 ?
                                <Result
                                    status="warning"
                                    title="Review Content"
                                    extra={
                                        <Button id="programs_reviewcontent-button" type="primary" key="navigateToReviewContent" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/review/content`)}>
                                            Review
                                        </Button>
                                    }
                                />
                                :
                                TopicStatusId(topicStatus) == 2 ?
                                    <Result
                                        status="warning"
                                        title="Content reviewed successfully"
                                        extra={
                                            <Button id="programs_generateqa-button" type="primary" key="generateqa" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/generate/questions`)}>
                                                Generate Q & A
                                            </Button>
                                        }
                                    />
                                    :
                                    TopicStatusId(topicStatus) == 3 ?
                                        <Result
                                            status="warning"
                                            title="Questions and Answers generated"
                                            extra={
                                                <Button id="programs_reviewqa-button" type="primary" key="reviewqa" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/review/questions`)}>
                                                    Review Q & A
                                                </Button>
                                            }
                                        />
                                        :
                                        TopicStatusId(topicStatus) == 4 ?
                                            <Result
                                                status="warning"
                                                title="MCQS not found"
                                                extra={
                                                    <Button id="programs_generatemcq-button" type="primary" key="generateQuiz" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/generate/quiz`)}>
                                                        Generate MCQS
                                                    </Button>
                                                }
                                            />
                                            :
                                            TopicStatusId(topicStatus) == 5 ?
                                                <Result
                                                    status="warning"
                                                    title="MCQS has been generated for this topic"
                                                    extra={
                                                        <Button id="programs_reviewmcq-button" type="primary" key="reviewQuiz" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/review/quiz`)}>
                                                            Review MCQS
                                                        </Button>
                                                    }
                                                />
                                                :
                                                TopicStatusId(topicStatus) == 6 && !isProgramsExists && (testTopicID !== false && testTopicID === null) ?
                                                    <div className='text-center'>
                                                        <p className="fontsize-16 m-0 mr-10">Does this topic contain programs?</p>
                                                        <div className="panel-actions mt-10">
                                                            <Button id="programs_yes-button" className="mr-10" outlined type="success" onClick={() => { setProgramsExists(true); form.resetFields() }}>
                                                                Yes
                                                            </Button>
                                                            <Button id="programs_no-button" outlined type="warning" onClick={() => ChangeStatusProgramsVerify()}>
                                                                No
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    :
                                                    TopicStatusId(topicStatus) == 7 && !isProgramsExists && (testTopicID !== false && testTopicID === null) ?
                                                        <div>
                                                            <Result
                                                                status="success"
                                                                title="Programs verified"
                                                                subTitle={
                                                                    (testTopicID !== false && testTopicID !== null) ?
                                                                        <div className='d-flex'>
                                                                            {
                                                                                TessellatorTopicProgramsLoading ?
                                                                                    <div className='custom-spin'>
                                                                                        <CustomSpinner />
                                                                                    </div>
                                                                                    :
                                                                                    <div className='m-auto'>
                                                                                        <div className='d-flex align-center-v mb-10'>
                                                                                            <p className='m-0'>Tessellator Topic: &nbsp;</p>
                                                                                            <h3 className='m-0'>{TessellatorTopicPrograms.length !== 0 && TessellatorTopicPrograms[0].topicname}</h3>
                                                                                        </div>
                                                                                        <p>No. of programs: {TessellatorTopicPrograms.length}</p>
                                                                                        {
                                                                                            TessellatorTopicPrograms.map((program) =>
                                                                                                <div key={program.id}>
                                                                                                    <Badge color='green' text={program.name} />
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        <div className='text-center mt-20'>
                                                                                            <Button id="programs_edit-button" type="primary" onClick={() => { dispatch(tessellatorTopicProgramsReadSuccess([])); setProgramsExists(true); setTestTopicId(testTopicID); }}>
                                                                                                Edit
                                                                                            </Button>
                                                                                        </div>
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <p>No. of programs: {TessellatorTopicPrograms.length}</p>
                                                                }
                                                                extra={
                                                                    <Button id="programs_edit-button1" type="primary" onClick={() => { setProgramsExists(true); form.resetFields() }}>
                                                                        Edit
                                                                    </Button>
                                                                }
                                                            />
                                                        </div>
                                                        :
                                                        isProgramsExists ?
                                                            <div className='d-flex'>
                                                                <div className='m-auto'>
                                                                    <p className="fontsize-16 m-0 mb-10">Select corresponding topic in tessellator</p>
                                                                    <FormValidationWrap>
                                                                        <Form id="programs_topics-form" name="sDash_validation-form" form={form} layout="vertical" onFinish={saveTopicMapping}>
                                                                            <Form.Item
                                                                                name="tessellatorTopic"
                                                                                rules={[{ required: true, message: 'Topic is required!' }]}
                                                                                label="Select Topic"
                                                                            >
                                                                                <Select
                                                                                    id="programs_select-topic"
                                                                                    loading={TessellatorTopicsLoading}
                                                                                    showSearch
                                                                                    style={{ width: 300 }}
                                                                                    placeholder="Select a topic"
                                                                                    optionFilterProp="children"
                                                                                    // filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}                                                                                                                                            
                                                                                    onChange={(e) => fetchPrograms(e)}
                                                                                >
                                                                                    {
                                                                                        TessellatorTopics.length !== 0 && TessellatorTopics.map((topic) =>
                                                                                            <Option key={topic.id} value={topic.id}>{topic.name}</Option>

                                                                                        )
                                                                                    }
                                                                                </Select>
                                                                            </Form.Item>

                                                                            <div className='text-right mb-20'>
                                                                                <Button id="programs_cancel-button" className="mr-10" outlined type="light" style={{ color: '#000' }} onClick={() => onCancel()}>
                                                                                    Cancel
                                                                                </Button>
                                                                                <Button id="programs_topic-submit-button" htmlType="submit" type="primary" onClick={() => saveTopicMapping()} >
                                                                                    {MapTessellatorTopicResLoading ? 'Please wait...' : 'Submit'}
                                                                                </Button>
                                                                            </div>
                                                                        </Form>
                                                                    </FormValidationWrap>
                                                                    {
                                                                        TessellatorTopicProgramsLoading &&
                                                                        <div className='custom-spin'>
                                                                            <CustomSpinner />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        !TessellatorTopicProgramsLoading && TessellatorTopicPrograms.length !== 0 && isProgramsExists && testTopicId !== 0 &&
                                                                        <div className='mt-25'>
                                                                            <p>No. of programs: {TessellatorTopicPrograms.length}</p>
                                                                            {
                                                                                TessellatorTopicPrograms.map((program) =>
                                                                                    <div key={program.id}>
                                                                                        <Badge color='green' text={program.name} />
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            testTopicID !== false && testTopicID !== null &&
                                                            <div className=''>
                                                                {
                                                                    TessellatorTopicProgramsLoading ?
                                                                        <div className='custom-spin'>
                                                                            <CustomSpinner />
                                                                        </div>
                                                                        :
                                                                        <Result
                                                                            status="success"
                                                                            title="Programs verified"
                                                                            subTitle={
                                                                                (testTopicID !== false && testTopicID !== null) ?
                                                                                    <div className='d-flex'>
                                                                                        {
                                                                                            TessellatorTopicProgramsLoading ?
                                                                                                <div className='custom-spin'>
                                                                                                    <CustomSpinner />
                                                                                                </div>
                                                                                                :
                                                                                                <div className='m-auto'>
                                                                                                    <div className='d-flex align-center-v mb-10'>
                                                                                                        <p className='m-0'>Tessellator Topic: &nbsp;</p>
                                                                                                        <h3 className='m-0'>{TessellatorTopicPrograms.length !== 0 && TessellatorTopicPrograms[0].topicname}</h3>
                                                                                                    </div>
                                                                                                    <p className=''>No. of programs: {TessellatorTopicPrograms.length}</p>
                                                                                                    {
                                                                                                        TessellatorTopicPrograms.map((program) =>
                                                                                                            <div key={program.id}>
                                                                                                                <Badge color='green' text={program.name} />
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                        }
                                                                                    </div>
                                                                                    :
                                                                                    <p className=''>No. of programs: {TessellatorTopicPrograms.length}</p>
                                                                            }
                                                                            extra={[
                                                                                <Button id="programs_edit-button2" type="primary" key="edit" onClick={() => { dispatch(tessellatorTopicProgramsReadSuccess([])); setProgramsExists(true); setTestTopicId(testTopicID); }} disabled={isSettingToNull ? true : false} >
                                                                                    Edit
                                                                                </Button>,
                                                                                <Button id="programs_reset-button" className="" outlined key="reset" onClick={() => resetToNull()} disabled={isSettingToNull ? true : false}>
                                                                                    {isSettingToNull ? 'Please Wait...' : 'Reset'}
                                                                                </Button>
                                                                            ]}
                                                                        />
                                                                    // <div className='m-auto'>
                                                                    //     <div className='d-flex align-center-v mb-10'>
                                                                    //         <p className='m-0'>Tessellator Topic: &nbsp;</p>
                                                                    //         <h3 className='m-0'>{TessellatorTopicPrograms.length !== 0 && TessellatorTopicPrograms[0].topicname}</h3>
                                                                    //     </div>
                                                                    //     <p>No. of programs: {TessellatorTopicPrograms.length}</p>
                                                                    //     {
                                                                    //         TessellatorTopicPrograms.map((program) =>
                                                                    //             <div key={program.id}>
                                                                    //                 <Badge color='green' text={program.name} />
                                                                    //             </div>
                                                                    //         )
                                                                    //     }
                                                                    //     <div className='text-center mt-20'>
                                                                    //         <Button type="primary" onClick={() => { dispatch(tessellatorTopicProgramsReadSuccess([])); setProgramsExists(true); setTestTopicId(testTopicID); }}>
                                                                    //             Edit
                                                                    //         </Button>
                                                                    //     </div>
                                                                    // </div>
                                                                }
                                                            </div>

                }
                <Modal open={verifyCompletedModal} title="Freeze Topic" onCancel={() => setVerifyCompletedModal(false)} onOk={() => FreezeTopic()} okText="Yes" cancelText="No">
                    <h4>Programs verified successfully</h4>
                    <h4>Do you want to freeze this topic now?</h4>
                </Modal>
            </NoteCardWrap >
        </div >
    )
}

export default Programs
