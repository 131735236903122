import React, { Suspense, useEffect, useLayoutEffect, useState } from 'react'
import { FullScreenToggle, Main } from '../styled'
import { PageHeader } from '../../components/page-headers/page-headers'
import { Link, NavLink, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import AngleLeftB from '@iconscout/react-unicons/icons/uil-angle-left-b';
import { Col, Result, Row, Space, Tooltip } from 'antd';
import { Cards } from '../../components/cards/frame/cards-frame';
import { NoteCardWrap, NoteWrapper } from '../note/style';
import { FaqCategoryBox } from '../pages/style';
import Heading from '../../components/heading/heading';
import { FixedSidebar, SidebarWrap } from '../teacher/styles/sidebarstyle';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import SelectTopic from '../teacher/SelectTopic';
import UilAngleLeft from '@iconscout/react-unicons/icons/uil-angle-left';
import UilAngleRight from '@iconscout/react-unicons/icons/uil-angle-right';
import UilTimes from '@iconscout/react-unicons/icons/uil-times';
import { Button } from '../../components/buttons/buttons';
import { setItem } from '../../utility/localStorageControl';
import UilAngleDoubleLeft from '@iconscout/react-unicons/icons/uil-angle-double-left';
import UilAngleDoubleRight from '@iconscout/react-unicons/icons/uil-angle-double-right';
import TopicView from './TopicView';
import { useSelector, useDispatch } from 'react-redux';
import { topicsOfSubjectDataRead } from '../../redux/teacherSubjectsUnits/actionCreator';
import CreateTopic from './CreateTopic'
import ImportTopic from './ImportTopic';

export default function Topics() {

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const { subjectName, subjectId } = useParams()

    const customWidth = { width: '96%', margin: 'auto' }

    const [fullScreen, setFullscreen] = useState(false)
    const [openCreateTopicModal, SetOpenCreateTopicModal] = useState(false)
    const [openImportTopicModal, SetOpenImportTopicModal] = useState(false)

    const [sideBarState, setSidebarState] = useState({
        responsive: 0,
        collapsed: false,
        visible: false,
        modalType: 'primary',
    });

    const { responsive, collapsed } = sideBarState;

    useLayoutEffect(() => {
        function updateSize() {
            const width = window.innerWidth;
            setSidebarState({ ...sideBarState, responsive: width });
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);


    const toggleCollapsed = () => {
        setSidebarState({
            ...sideBarState,
            collapsed: !collapsed,
        });
    };

    const { TopicsData, isTopicLoading } = useSelector((state) => {
        return {
            TopicsData: state.TopicsOfSubject.data,
            isTopicLoading: state.TopicsOfSubject.loading,
        };
    });

    useEffect(() => {
        if (dispatch) {
            dispatch(topicsOfSubjectDataRead(subjectId));
        }
    }, [dispatch])


    return (
        <div>
            <Main>
                {/* <div className='back-button'>
                    <Link to="/teacher/subjects"><AngleLeftB size={18} className="mb-2" /> Back to subjects</Link>
                </div> */}
                <PageHeader className="ninjadash-page-header-main" title={
                    <div className='d-flex align-center-v'><AngleLeftB size={24} className="cursor-pointer" onClick={() => navigate('/teacher')} /> &nbsp;Topics of {subjectName}</div>
                } />
                <NoteWrapper>
                    {
                        isTopicLoading ?
                            <div className='spin'>
                                <CustomSpinner Message={'Loading Topics...'} />
                            </div>
                            :
                            TopicsData?.length === 0 ?
                                <Row gutter={25}>
                                    <Col xs={24}>
                                        <Cards headless>
                                            <NoteCardWrap className='spin'>
                                                <Result
                                                    status='info'
                                                    title="Oops! Looks like there are no topics here."
                                                    extra={
                                                        <Space>
                                                            <Button type={'primary'} onClick={() => SetOpenCreateTopicModal(true)}>Create Topic</Button>
                                                            <Button type={'primary'} onClick={() => SetOpenImportTopicModal(true)}>Import Topic</Button>
                                                        </Space>
                                                    }
                                                />
                                            </NoteCardWrap>
                                        </Cards>
                                    </Col>
                                </Row>
                                :
                                <Row gutter={25}>
                                    <Col xxl={fullScreen ? 0 : 6} lg={fullScreen ? 0 : 6} md={fullScreen ? 0 : 8} xs={24}>
                                        {responsive <= 767 && (
                                            <div className="sidebar-trier-wrap text-center">
                                                <Button id="topicview_sidebar-trigger-button" type="link" className="sidebar-trigger" style={{ marginTop: 0 }} onClick={toggleCollapsed}>
                                                    {collapsed ? <UilAngleLeft /> : <UilAngleRight />}
                                                </Button>
                                            </div>
                                        )}
                                        {responsive > 767 ? (
                                            <div className="sidebar-card">
                                                <Cards headless>
                                                    <div className="note-sidebar-bottom">
                                                        <FaqCategoryBox>
                                                            {/* <Heading as='h3' className='text-center'>Topics</Heading> */}
                                                            <div className="d-flex justify-content-center mb-20">
                                                                <Space>
                                                                    <Button type={'primary'} onClick={() => SetOpenCreateTopicModal(true)}>Create Topic</Button>
                                                                    <Button type={'primary'} onClick={() => SetOpenImportTopicModal(true)}>Import Topic</Button>
                                                                </Space>
                                                            </div>
                                                            <ul className="ninjadash-taskApp-sidebar__nav">
                                                                {
                                                                    TopicsData?.length === 0 ? <div className='text-center mt-25' style={{ fontSize: '18px' }}>Topics Not Found</div> : TopicsData?.sort((a, b) => a.id < b.id ? 1 : -1)?.map((topicObj, index) =>
                                                                        <li key={index}>
                                                                            <NavLink className={topicObj.topicstatus === "QUESTIONS FREEZED" ? 'success' : 'primary'} to={`topic/${topicObj.id}/${topicObj.name}/`}
                                                                                onClick={() => setItem('activetopic', index)}>
                                                                                <span className="nav-text">
                                                                                    <Row gutter={15} className='d-flex'>
                                                                                        <Col style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: `${responsive > 1435 ? '220px' : ((responsive <= 1435 && responsive > 1275) || (responsive <= 991 && responsive > 936)) ? '180px' : ((responsive <= 1275 && responsive > 1165) || (responsive <= 936 && responsive > 876)) ? '180px' : ((responsive <= 1165 && responsive > 1130) || (responsive <= 876 && responsive > 846)) ? '150px' : ((responsive <= 1130 && responsive > 1090) || (responsive <= 846 && responsive > 816)) ? '150px' : ((responsive <= 1090 && responsive > 1045) || (responsive <= 816 && responsive > 786)) ? '150px' : ((responsive <= 1045 && responsive > 991) || (responsive <= 786 && responsive > 767)) && '140px'}` }}>
                                                                                            <Tooltip title={`${topicObj.name} (${topicObj.questions.length})`} placement="left">
                                                                                                {topicObj.name}&nbsp;({topicObj.questions.length}) <br /> {topicObj.topicschedules.length !== 0 && <span className='fontsize-12 text-success'>Scheduled</span>}
                                                                                            </Tooltip>
                                                                                        </Col>
                                                                                        <Col style={{ width: '40px' }}>
                                                                                            {/* <Tooltip title={`${topicObj.questions.easy + topicObj.questions.medium + topicObj.questions.hard} questions are generated in ${topicObj.topic}`} placement="left">
                                                                                            ({topicObj.questions.easy + topicObj.questions.medium + topicObj.questions.hard})
                                                                                        </Tooltip> */}
                                                                                        </Col>
                                                                                    </Row>
                                                                                </span>
                                                                            </NavLink>
                                                                        </li>
                                                                    )
                                                                }
                                                            </ul>
                                                        </FaqCategoryBox>
                                                    </div>
                                                </Cards>
                                            </div>
                                        ) : (
                                            <FixedSidebar className={collapsed ? 'show' : 'hide'}>
                                                <Link id="topicview_sidebar-close-button" to="#" type="link" className="trigger-close" onClick={toggleCollapsed}>
                                                    <UilTimes />
                                                </Link>
                                                <SidebarWrap className="mb-30">
                                                    <div className="ninjadash-taskApp-sidebar">
                                                        <Heading as='h3' className='text-center'>Topics</Heading>
                                                        <FaqCategoryBox>
                                                            <ul className="ninjadash-taskApp-sidebar__nav" style={{ paddingLeft: '10px', lineHeight: '18px' }}>
                                                                {
                                                                    TopicsData?.length === 0 ? <div className='text-center mt-25' style={{ fontSize: '18px' }}>Topics Not Found</div> : TopicsData?.sort((a, b) => a.id < b.id ? 1 : -1).map((topicObj, index) =>
                                                                        <li key={index} className="ninjadash-taskApp-sidebar__nav--item">
                                                                            <NavLink className={topicObj.topicstatus === "QUESTIONS FREEZED" ? 'success' : 'primary'} to={`topic/${topicObj.id}/${topicObj.name}/`}
                                                                                onClick={() => { setItem('activetopic', index); toggleCollapsed() }}>
                                                                                <span className="nav-item-text">
                                                                                    <Row gutter={15} className='d-flex'>
                                                                                        <Col title={topicObj.name} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '180px', width: '150px' }}>
                                                                                            {topicObj.name} <br /> {topicObj.topicschedules.length !== 0 && <span className='fontsize-12 text-success'>Scheduled</span>}
                                                                                        </Col>
                                                                                        {/* <Col style={{ width: '40px' }}>({topicObj.questions.easy + topicObj.questions.medium + topicObj.questions.hard})</Col> */}
                                                                                    </Row>
                                                                                </span>
                                                                            </NavLink>
                                                                        </li>
                                                                    )
                                                                }
                                                            </ul>
                                                        </FaqCategoryBox>
                                                    </div>
                                                </SidebarWrap>
                                            </FixedSidebar>
                                        )}
                                    </Col>
                                    <Col xxl={fullScreen ? 24 : 18} lg={fullScreen ? 24 : 18} md={fullScreen ? 24 : 16} xs={24}>
                                        <Suspense
                                            fallback={
                                                <div className="spin">
                                                    <CustomSpinner />
                                                </div>
                                            }
                                        >
                                            {
                                                responsive > 767 &&
                                                <FullScreenToggle>
                                                    <div className=''>
                                                        {
                                                            fullScreen ?
                                                                <UilAngleDoubleRight id="topicview_fullscreen-toggle-right-button" onClick={() => setFullscreen(false)} className='cursor-pointer align-center-v' />
                                                                :
                                                                <UilAngleDoubleLeft id="topicview_fullscreen-toggle-left-button" onClick={() => setFullscreen(true)} className='cursor-pointer align-center-v' />
                                                        }
                                                    </div>
                                                </FullScreenToggle>
                                            }
                                            {
                                                TopicsData.length !== 0 &&
                                                <Routes>
                                                    <Route path="topic/:topicId/:topicName/*" element={<TopicView customWidth={customWidth} fullScreen={fullScreen} />} />
                                                    <Route path="*" element={<SelectTopic />} />
                                                </Routes>
                                            }
                                        </Suspense>
                                    </Col>
                                </Row>
                    }

                    <CreateTopic open={openCreateTopicModal} close={SetOpenCreateTopicModal} />
                    <ImportTopic open={openImportTopicModal} close={SetOpenImportTopicModal} />
                </NoteWrapper>
            </Main>
        </div>
    )
}
