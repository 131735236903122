import { ConfigProvider, Modal, Spin, message } from 'antd';
import 'antd/dist/antd.less';
import React, { useEffect, useState, lazy } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
// import { isLoaded, ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import SimpleReactLightbox from 'simple-react-lightbox';
import { ThemeProvider } from 'styled-components';
import ProtectedRoute from './components/utilities/protectedRoute';
import config from './config/config';
import store, { rrfProps } from './redux/store';

import Admin from './routes/admin';
import Teacher from './routes/teacher';
import Student from './routes/student';
import Reviewer from './routes/reviewer';
import Auth from './routes/auth';
import './static/css/style.css';
import CustomSpinner from './container/ui-elements/spinner/CustomSpinner';
import { getCurrentWeek } from './redux/schedule/actionCreator';
import { getItem } from './utility/localStorageControl';
import { getMessage } from './redux/authentication/actionCreator';
import OfflinePage from './OfflinePage';
import UserGuide from './userGuide/UserGuide';

const NotFound = lazy(() => import('./container/pages/404'));

const { themeColor } = config;

function ProviderConfig() {
  const { rtl, isLoggedIn, topMenu, mainContent, auth, isLoading } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      mainContent: state.ChangeLayoutMode.mode,
      isLoggedIn: state.auth.login,
      isLoading: state.auth.loading,
      auth: state.auth,
    };
  });

  const dispatch = useDispatch()
  const [role, setRole] = useState("");

  useEffect(() => {
    const role = getItem('role')
    setRole(role)
  })


  const { msg, messageLoading } = useSelector((state) => {
    return {
      msg: state.msg.data,
      messageLoading: state.msg.loading
    };
  })

  // const [isOnline, setIsOnline] = useState(navigator.onLine);

  // useEffect(() => {
  //   // Define the event handlers
  //   const goOnline = () => setIsOnline(true);
  //   const goOffline = () => setIsOnline(false);

  //   // Add event listeners for online and offline events
  //   window.addEventListener('online', goOnline);
  //   window.addEventListener('offline', goOffline);

  //   // Cleanup function to remove event listeners when the component unmounts
  //   return () => {
  //     window.removeEventListener('online', goOnline);
  //     window.removeEventListener('offline', goOffline);
  //   };
  // }, []); // Empty array ensures this effect runs only once after the initial render


  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...themeColor, rtl, topMenu, mainContent }}>
        {isLoading ? (
          <div className="spin">
            <CustomSpinner Message={'Logging in... Please wait.'} />
          </div>
        ) : (
          <SimpleReactLightbox>
            <Router basename={''}>
              {
                // !isOnline ?
                //   <>
                //     <OfflinePage/>
                //   </>
                //   :
                  <div>
                    {!isLoggedIn ? (
                      <div>
                        <div className='text-center' dangerouslySetInnerHTML={{ __html: msg }} />
                        <Routes>
                          <Route path="/*" element={<Auth />} />
                          <Route path="/userguide" element={<UserGuide />} />
                        </Routes>
                      </div>
                    ) : (
                      isLoggedIn && role === 'ADMIN' ?
                        <Routes>
                          <Route path="/admin/*" element={<ProtectedRoute path="/*" Component={Admin} />} />
                          <Route path="*" element={<NotFound />} />
                        </Routes>
                        :
                        isLoggedIn && role === 'TEACHER' ?
                          <Routes>
                            <Route path="/teacher/*" element={<ProtectedRoute path="/*" Component={Teacher} />} />
                            <Route path="*" element={<NotFound />} />
                          </Routes>
                          :
                          isLoggedIn && role === 'STUDENT' ?
                          <Routes>
                            <Route path="/student/*" element={<ProtectedRoute path="/*" Component={Student} />} />
                            <Route path="*" element={<NotFound />} />
                          </Routes>
                          :
                          isLoggedIn && role === 'REVIEWER' &&
                          <Routes>
                            <Route path="/reviewer/*" element={<ProtectedRoute path="/*" Component={Reviewer} />} />
                            <Route path="*" element={<NotFound />} />
                          </Routes>
                    )
                    }
                    {isLoggedIn && role === 'ADMIN' && (
                      <Routes>
                        <Route path="/" element={<Navigate to="/admin" />} />
                      </Routes>
                    )}
                    {isLoggedIn && role === 'TEACHER' && (
                      <Routes>
                        <Route path="/" element={<Navigate to="/teacher" />} />
                      </Routes>
                    )}
                    {isLoggedIn && role === 'STUDENT' && (
                      <Routes>
                        <Route path="/" element={<Navigate to="/student" />} />
                      </Routes>
                    )}
                    {isLoggedIn && role === 'REVIEWER' && (
                      <Routes>
                        <Route path="/" element={<Navigate to="/reviewer" />} />
                      </Routes>
                    )}
                  </div>
              }
            </Router>
          </SimpleReactLightbox>
        )}
      </ThemeProvider>
    </ConfigProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default App;
