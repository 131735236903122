import React, { useState, useEffect } from 'react';
import { Result, Spin } from 'antd';
import { Cards } from '../../components/cards/frame/cards-frame';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { NoteCardWrap } from '../note/style';

function SelectTopic() {
  const [state, setState] = useState({
    isLoading: true,
  });
  useEffect(() => {
    setTimeout(() => {
      setState({ isLoading: false });
    }, 1500);
  }, []);
  return (
    <Cards headless>
      <NoteCardWrap>
        {state.isLoading ? (
          <div className="custom-spin">
            <CustomSpinner />
          </div>
        ) : (
          <Result
            status="warning"
            title="Nothing to show. Please select a topic from the list on the left"
            subTitle=""            
          />
        )}
      </NoteCardWrap>
    </Cards>
  );
}

export default SelectTopic;
