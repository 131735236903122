import React, { useEffect, useState } from 'react'
import { BorderLessHeading, TableDefaultStyle } from '../../styled'
import { Cards } from '../../../components/cards/frame/cards-frame'
import { Link } from 'react-router-dom'
import { Table } from 'antd'
import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons'

export default function SubjectsBestPerformer({ subjectsBestPerformer }) {

    const columns = [
        {
            title: 'Rollno',
            dataIndex: 'rollno',
            key: 'rollno',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        // {
        //     title: 'Questions Answered',
        //     dataIndex: 'questionsanswered',
        //     key: 'questionsanswered',
        // },
        {
            title: 'Performance',
            dataIndex: 'performance',
            key: 'performance',
        },
    ]

    const [selectedSubject, setSelectedSubject] = useState("");

    const [filters, setFilters] = useState([])
    const [studentsData, setStudentsData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [displayAll, setDisplayAll] = useState(false);

    useEffect(() => {
        const subjectFilters = [];
        if (subjectsBestPerformer.length !== 0) {
            setSelectedSubject(subjectsBestPerformer[0].subjectName)
            subjectsBestPerformer.map((value, index) => {
                const { subjectName, cohortName, data } = value;
                return subjectFilters.push({
                    key: index + 1,
                    subjectName,
                    cohortName,
                    data
                });
            })
            setFilters(subjectFilters)
            const tableData = []
            subjectFilters.length !== 0 && subjectFilters[0].data.map((obj, index) => {
                return tableData.push({
                    key: index + 1,
                    rollno: obj.userName,
                    name: obj.studentName ? obj.studentName[0].toUpperCase() + obj.studentName.slice(1).toLowerCase() : 'NA',
                    questionsanswered: obj.questionsAnswered,
                    performance: obj.weightedAverageScore
                });
            })
            setStudentsData(tableData)
            setFilteredData(tableData)
        }
        else {
            setFilters([])
            setStudentsData([])
            setFilteredData([])
        }
    }, [subjectsBestPerformer])

    const handleTabChange = (subject, cohort, event) => {
        event.preventDefault();
        setSelectedSubject(subject);
        const tableData = []
        filters.filter((obj) => obj.subjectName === subject && obj.cohortName === cohort)[0].data.map((obj, index) => {
            return tableData.push({
                key: index + 1,
                rollno: obj.userName,
                name: obj.studentName ? obj.studentName[0].toUpperCase() + obj.studentName.slice(1).toLowerCase() : 'NA',
                questionsanswered: obj.questionsAnswered,
                performance: obj.weightedAverageScore
            });
        })
        setFilteredData(tableData)
    };

    const rowsToShow = displayAll ? filteredData : filteredData.slice(0, 5);

    return (
        <div>
            <div className="full-width-table">
                <BorderLessHeading>
                    <Cards
                        isbutton={
                            <div className="ninjadash-card-nav">
                                <ul>
                                    {
                                        filters.length !== 0 && filters.map((obj, index) =>
                                            <li key={index + 1} className={obj.subjectName === selectedSubject ? 'ninjadash-active' : ''}>
                                                <Link onClick={(e) => handleTabChange(obj.subjectName, obj.cohortName, e)} to="#">
                                                    {obj.subjectName} -&nbsp;<span className='fontsize-12'>{obj.cohortName}</span>
                                                </Link>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        }
                        title="Best Performer"
                        size="large"
                    >
                        <TableDefaultStyle className="ninjadash-having-header-bg" style={{ height: '302px' }}>
                            <div className="table-responsive">
                                <Table columns={columns} dataSource={rowsToShow} pagination={false} />
                            </div>
                        </TableDefaultStyle>
                        {filteredData.length > 5 && (
                            <div style={{ marginTop: 10 }} className='d-flex justify-content-center'>
                                <Link onClick={() => setDisplayAll(!displayAll)}>
                                    {displayAll ? <UilAngleUp /> : <UilAngleDown />}
                                </Link>
                            </div>
                        )}
                    </Cards>
                </BorderLessHeading>
            </div>
        </div>
    )
}
