import React, { useEffect } from 'react'
import { OverviewDataStyleWrap } from '../styles/dashboardStyle'
import OverviewCardMesh from '../../../components/cards/OverviewCardMesh';
import SignIn from '@iconscout/react-unicons/icons/uil-signin';
import Clock from '@iconscout/react-unicons/icons/uil-clock';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { CurrentweekYearwise, DailyLoginCountDataRead, TodaysReadAssesmentBadges } from '../../../redux/studentrelatedcount/actionCreator';
import { Skeleton } from 'antd';

export default function StudentRelatedCounts() {

    const dispatch = useDispatch();

    useEffect(() => {
        // dispatch(DailyLoginCountDataRead());
        // dispatch(CurrentweekYearwise());
        // dispatch(TodaysReadAssesmentBadges());
    }, [dispatch])

    const { DailyLoginCount, DailyLoginCountLoading, CurrentWeekYrwise, CurrentWeekYrwiseLoading, TodaysBadgesCount, TodaysBadgesCountLoading } = useSelector((state) => {
        return {
            DailyLoginCount: state.DailyLoginCount.data,
            DailyLoginCountLoading: state.DailyLoginCount.loading,
            CurrentWeekYrwise: state.CurrentWeekYrwise.data,
            CurrentWeekYrwiseLoading: state.CurrentWeekYrwise.loading,
            TodaysBadgesCount: state.TodaysBadgesCount.data,
            TodaysBadgesCountLoading: state.TodaysBadgesCount.loading,
        };
    });

    const yr2data = [
        {
            ...CurrentWeekYrwise !== null && CurrentWeekYrwise.Second,
            total: -1,
            icon: <Clock />,
            statusRate: null,
            type: 'primary'
        },
        {
            ...DailyLoginCount !== null && DailyLoginCount.Second,
            total: DailyLoginCount !== null && DailyLoginCount.Second.loginCount,
            icon: <SignIn />,
            statusRate: null,
            type: 'secondary'
        },        
        {
            label: TodaysBadgesCount !== null && TodaysBadgesCount.Second.readlabel,
            total: TodaysBadgesCount !== null && TodaysBadgesCount.Second.totalreadbadges,
            icon: null,
            img: TodaysBadgesCount !== null ? process.env.REACT_APP_API_ENDPOINT + TodaysBadgesCount.topicReadBadge : '',
            statusRate: null,
            type: 'success'
        },
        {
            label: TodaysBadgesCount !== null && TodaysBadgesCount.Second.assessmentlabel,
            statusRate: 5,
            total: TodaysBadgesCount !== null && TodaysBadgesCount.Second.totalassessmentbadges,
            icon: null,
            img: TodaysBadgesCount !== null ? process.env.REACT_APP_API_ENDPOINT + TodaysBadgesCount.topicCompleteBadge : '',
            statusRate: null,
            type: 'info'
        },
    ]
    const yr3data = [
        {
            ...CurrentWeekYrwise !== null && CurrentWeekYrwise.Third,
            total: -1,
            icon: <Clock />,
            statusRate: null,
            type: 'primary'
        },
        {
            ...DailyLoginCount !== null && DailyLoginCount.Third,
            total: DailyLoginCount !== null && DailyLoginCount.Third.loginCount,
            icon: <SignIn />,
            statusRate: null,
            type: 'secondary'
        },
        {
            label: TodaysBadgesCount !== null && TodaysBadgesCount.Third.readlabel,
            total: TodaysBadgesCount !== null && TodaysBadgesCount.Third.totalreadbadges,
            icon: null,
            img: TodaysBadgesCount !== null ? process.env.REACT_APP_API_ENDPOINT + TodaysBadgesCount.topicReadBadge : '',
            statusRate: null,
            type: 'success'
        },
        {
            label: TodaysBadgesCount !== null && TodaysBadgesCount.Third.assessmentlabel,
            statusRate: 2,
            total: TodaysBadgesCount !== null && TodaysBadgesCount.Third.totalassessmentbadges,
            icon: null,
            img: TodaysBadgesCount !== null ? process.env.REACT_APP_API_ENDPOINT + TodaysBadgesCount.topicCompleteBadge : '',
            statusRate: null,
            type: 'info'
        },
    ]
    return (
        <div>
            {
                DailyLoginCountLoading || TodaysBadgesCountLoading || CurrentWeekYrwiseLoading ?
                    <Skeleton active />
                    :
                    <OverviewDataStyleWrap className="card-mesh-wrap align-center-v">
                        {
                            yr2data.length !== 0 && yr2data.map((item, index) =>
                                <OverviewCardMesh key={index} data={item} />
                            )
                        }
                    </OverviewDataStyleWrap>
            }
            {
                CurrentWeekYrwiseLoading || TodaysBadgesCountLoading || CurrentWeekYrwiseLoading ?
                    <Skeleton active />
                    :
                    <OverviewDataStyleWrap className="card-mesh-wrap align-center-v">
                        {
                            yr3data.length !== 0 && yr3data.map((item, index) =>
                                <OverviewCardMesh key={index} data={item} />
                            )
                        }
                    </OverviewDataStyleWrap>
            }
        </div>
    )
}
