import React, { useEffect, useLayoutEffect, useState } from 'react';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Skeleton } from 'antd';
import { Cards } from '../../components/cards/frame/cards-frame';
import Summary from './DashboardOverview/Summary';
import TodaysSummary from './DashboardOverview/TodaysSummary';
import { GetOverallSummary, GetTodayOverallSummary } from '../../redux/studentPerformance/actionCreator'

const Dashboard = () => {

  const dispatch = useDispatch()

  const { overallSummary, overallSummaryLoading, todaysOverallSummary, todaysOverallSummaryLoading } = useSelector((state) => {
    return {
      overallSummary: state.overallSummary.data,
      overallSummaryLoading: state.overallSummary.loading,
      todaysOverallSummary: state.todaysOverallSummary.data,
      todaysOverallSummaryLoading: state.todaysOverallSummary.loading,
    }
  })

  useEffect(() => {
    dispatch(GetOverallSummary())
    dispatch(GetTodayOverallSummary())
  }, [])


  return (
    <div>
      <Main>
        <PageHeader className="ninjadash-page-header-main" title="Dashboard" />
        <div>
          <h3 className='mt-20 add-line'>Today's Summary &nbsp;</h3>
          {
            todaysOverallSummaryLoading ?
              <Cards headless>
                <Skeleton active />
              </Cards>
              :
              <TodaysSummary todaysOverallSummary={todaysOverallSummary} />
          }
        </div>
        <div>
        <h3 className='mt-20 add-line'>Overall Summary &nbsp;</h3>
          {
            overallSummaryLoading ?
              <Cards headless>
                <Skeleton active />
              </Cards>
              :
              <Summary overallSummary={overallSummary} />
          }
        </div>
        <Row gutter={25} justify="center">
          <Col xl={12} lg={12} md={24} xs={24}>

          </Col>
        </Row>
      </Main>

    </div>
  );
};

export default Dashboard;