import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { message, notification } from 'antd';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';

const updateNotificationSuccess = () => {
  notification.success({
    message: 'Marked as Reviewed',
  });
};

const deleteNotificationSuccess = () => {
  notification.success({
    message: 'Chunk deleted successfully',
  });
};

const deleteNotificationFailed = () => {
  notification.error({
    message: 'Chunk deletion failed',
  });
};


const {
  readChunkBegin,
  readChunkSuccess,
  readChunkErr,
  updateChunkBegin,
  updateChunkSuccess,
  updateChunkErr,
  deleteChunkBegin,
  deleteChunkSuccess,
  deleteChunkErr
} = actions;


const chunksDataRead = (topicId) => {
  return async (dispatch) => {
    try {
      await dispatch(readChunkBegin());
      const query = await DataService.get(`/topicmaster/chunk/${topicId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(readChunkSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(readChunkSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(readChunkSuccess(null));
        }
      }
      else {
        await dispatch(readChunkSuccess(null));
        // await dispatch(regulationAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(readChunkErr(err));
    }
  };
};

const updateChunk = (topicId, formData) => {
  return async (dispatch) => {
    try {
      await dispatch(updateChunkBegin());
      const response = await DataService.put(`/topicmaster/updatechunk/${topicId}`, formData);
      const token = getItem('access_token');
      const data = response.data
      if (token && data !== null && data.Error === false) {
        await dispatch(updateChunkSuccess(data.payload));
        updateNotificationSuccess()
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(updateChunkSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(updateChunkSuccess(null));
        }
      }
      else {
        await dispatch(updateChunkSuccess(null));
        // await dispatch(updateChunkErr('Unauthorized access'));        
      }
    } catch (err) {
      await dispatch(updateChunkErr(err));
    }
  };
};

const deleteChunk = (chunkId, topicId) => {
  return async (dispatch) => {
    try {
      await dispatch(deleteChunkBegin());
      const response = await DataService.delete(`/topicmaster/chunk/delete/${topicId}/${chunkId}`);
      const token = getItem('access_token');
      const data = response.data
      if (token) {
        if (data !== null && data.Error === false) {
          await dispatch(deleteChunkSuccess(data));
          deleteNotificationSuccess()
        }
        else if (data !== null && data.Error === true) {
          if (Object.keys(data).length >= 3 && data.statusCode == 401) {
            message.error(data.message)
            await dispatch(deleteChunkSuccess(null));
            dispatch(logOut())
          }
          else {
            await dispatch(deleteChunkSuccess(null));
          }
        }
        else {
          deleteNotificationFailed()
        }
      } else {
        await dispatch(deleteChunkErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(deleteChunkErr(err));
    }
  };
};




export {
  chunksDataRead,
  updateChunk,
  deleteChunk
};
