const actions = {

  CREATE_TOPIC_BEGIN: 'CREATE_TOPIC_BEGIN',
  CREATE_TOPIC_SUCCESS: 'CREATE_TOPIC_SUCCESS',
  CREATE_TOPIC_ERR: 'CREATE_TOPIC_ERR',

  TOPIC_SINGLE_DATA_BEGIN: 'TOPIC_SINGLE_DATA_BEGIN',
  TOPIC_SINGLE_DATA_SUCCESS: 'TOPIC_SINGLE_DATA_SUCCESS',
  TOPIC_SINGLE_DATA_ERR: 'TOPIC_SINGLE_DATA_ERR',

  TOPIC_STATUS_UPDATE_BEGIN: 'TOPIC_STATUS_UPDATE_BEGIN',
  TOPIC_STATUS_UPDATE_SUCCESS: 'TOPIC_STATUS_UPDATE_SUCCESS',
  TOPIC_STATUS_UPDATE_ERR: 'TOPIC_STATUS_UPDATE_ERR',

  TOPICS_READ_BEGIN: 'TOPICS_READ_BEGIN',
  TOPICS_READ_SUCCESS: 'TOPICS_READ_SUCCESS',
  TOPICS_READ_ERR: 'TOPICS_READ_ERR',

  STUDENT_TOPICS_READ_BEGIN: 'STUDENT_TOPICS_READ_BEGIN',
  STUDENT_TOPICS_READ_SUCCESS: 'STUDENT_TOPICS_READ_SUCCESS',
  STUDENT_TOPICS_READ_ERR: 'STUDENT_TOPICS_READ_ERR',

  TOPIC_CLEAR_BEGIN: 'TOPIC_CLEAR_BEGIN',
  TOPIC_CLEAR_SUCCESS: 'TOPIC_CLEAR_SUCCESS',
  TOPIC_CLEAR_ERR: 'TOPIC_CLEAR_ERR',

  TOPICS_BY_SUBJECT_ID_BEGIN: 'TOPICS_BY_SUBJECT_ID_BEGIN',
  TOPICS_BY_SUBJECT_ID_SUCCESS: 'TOPICS_BY_SUBJECT_ID_SUCCESS',
  TOPICS_BY_SUBJECT_ID_ERR: 'TOPICS_BY_SUBJECT_ID_ERR',

  IMPORT_TOPIC_BEGIN: 'IMPORT_TOPIC_BEGIN',
  IMPORT_TOPIC_SUCCESS: 'IMPORT_TOPIC_SUCCESS',
  IMPORT_TOPIC_ERR: 'IMPORT_TOPIC_ERR',

  createTopicBegin: () => {
    return {
      type: actions.CREATE_TOPIC_BEGIN,
    };
  },

  createTopicSuccess: (data) => {
    return {
      type: actions.CREATE_TOPIC_SUCCESS,
      data,
    };
  },

  createTopicErr: (err) => {
    return {
      type: actions.CREATE_TOPIC_ERR,
      err,
    };
  },

  topicSingleReadBegin: () => {
    return {
      type: actions.TOPIC_SINGLE_DATA_BEGIN,
    };
  },

  topicSingleReadSuccess: (data) => {
    return {
      type: actions.TOPIC_SINGLE_DATA_SUCCESS,
      data,
    };
  },

  topicSingleReadErr: (err) => {
    return {
      type: actions.TOPIC_SINGLE_DATA_ERR,
      err,
    };
  },

  topicStatusUpdateBegin: () => {
    return {
      type: actions.TOPIC_STATUS_UPDATE_BEGIN,
    };
  },

  topicStatusUpdateSuccess: (data) => {
    return {
      type: actions.TOPIC_STATUS_UPDATE_SUCCESS,
      data,
    };
  },

  topicStatusUpdateErr: (err) => {
    return {
      type: actions.TOPIC_STATUS_UPDATE_ERR,
      err,
    };
  },

  topicsReadBegin: () => {
    return {
      type: actions.TOPICS_READ_BEGIN,
    };
  },

  topicsReadSuccess: (data) => {
    return {
      type: actions.TOPICS_READ_SUCCESS,
      data,
    };
  },

  topicsReadErr: (err) => {
    return {
      type: actions.TOPICS_READ_ERR,
      err,
    };
  },

  studentTopicsReadBegin: () => {
    return {
      type: actions.STUDENT_TOPICS_READ_BEGIN,
    };
  },

  studentTopicsReadSuccess: (data) => {
    return {
      type: actions.STUDENT_TOPICS_READ_SUCCESS,
      data,
    };
  },

  studentTopicsReadErr: (err) => {
    return {
      type: actions.STUDENT_TOPICS_READ_ERR,
      err,
    };
  },

  clearTopicBegin: () => {
    return {
      type: actions.TOPIC_CLEAR_BEGIN,
    };
  },

  clearTopicSuccess: (data) => {
    return {
      type: actions.TOPIC_CLEAR_SUCCESS,
      data,
    };
  },

  clearTopicErr: (err) => {
    return {
      type: actions.TOPIC_CLEAR_ERR,
      err,
    };
  },

  topicsBySubjectIdBegin: () => {
    return {
      type: actions.TOPICS_BY_SUBJECT_ID_BEGIN,
    };
  },

  topicsBySubjectIdSuccess: (data) => {
    return {
      type: actions.TOPICS_BY_SUBJECT_ID_SUCCESS,
      data,
    };
  },

  topicsBySubjectIdErr: (err) => {
    return {
      type: actions.TOPICS_BY_SUBJECT_ID_ERR,
      err,
    };
  },


  importTopicBegin: () => {
    return {
      type: actions.IMPORT_TOPIC_BEGIN,
    };
  },

  importTopicSuccess: (data) => {
    return {
      type: actions.IMPORT_TOPIC_SUCCESS,
      data,
    };
  },

  importTopicErr: (err) => {
    return {
      type: actions.IMPORT_TOPIC_ERR,
      err,
    };
  },

};

export default actions;
