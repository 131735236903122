// import { firebaseReducer } from 'react-redux-firebase';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { AuthReducer, ForgotPasswordReducer, VerifyOTPReducer, ResetPassReducer, MessageReducer, CollegeWiseMessageReducer } from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import { GENERATE_QUESTIONSReducer, QUESTIONSOF_TOPICReducer, TeacherSubjectsReducer, SUBJECTReducer, TopicUpdateReducer, TOPICSOF_SUBJECTReducer, UNITReducer, ACCEPTORREJECT_Reducer, UPDATE_QUESTIONReducer, CreateQUESTION_Reducer, RegenerateQUESTIONS_Reducer, ImportQuestionsReducer } from './teacherSubjectsUnits/reducers'
import { TOPICSReducer, TOPICContentReducer } from './teacherTopicsMenu/reducers'
import { REGULATIONReducer, REGULATIONSingleReducer } from './regulation/reducers'
import { YEARSEMESTERReducer, YEARSEMESTERSingleReducer } from './yearsemester/reducers'
import { SUBJECTSReducer, SUBJECTSAddReducer, SUBJECTSUpdateReducer, SubjectsByCohortReducer } from './subjects/reducers'
import { UploadContentReducer } from './uploadContent/reducers'
import { ReviewContentReducer, ReviewChunkUpdateReducer, DeleteChunkReducer } from './reviewContent/reducers'
import { CreateTopicReducer, TOPICSingleReducer, TOPICSTATUSReducer, TOPICSByUnitReducer, TopicClearReducer, StudentTopicsByUnitReducer, TopicsBySubjectIdReducer, ImportTopicReducer } from './topics/reducers';
import { ReadQAReducer, postResponseReducer, UpdateQAReducer, ReadAllQAByTopicReducer, DeleteQAReducer, AnswerEvaluateReducer, GETATTEMPTEDQAReducer } from './generateQuestions/reducers';
import { postQuizResponseReducer, ReadAllQuizByTopicReducer, UpdateQuizReducer, DeleteQuizReducer, ReadAllQuizReducer } from './generateQuiz/reducers';
import { ReadStudentSubjectsReducer, ReadSubjectUnitsReducer, ReadTopicsByIdReducer } from './studentSubjectsUnits/reducers';
import { getScheduleWeeksReducer, updateScheduleReducer, getScheduledDataReducer, getCurrentWeekReducer, getRegYrsReducer, getNgrokUrlReducer, ScheduleTopicReducer, DeleteScheduleReducer, StudentSchedulesReducer } from './schedule/reducers';
import { ProgressReducer } from './teacherDashboard/reducers';
import { QAFReducer } from './QAFeedback/reducers';
import { ContentFeedbackReducer, GetContentFBQReducer, VerifyFeedbackReducer, PostFeedbackReducer } from './contentFeedback/reducers';
import { RecentActionsDataReducer, RecentInterviewsReducer } from './recentActions/reducers';
import { PdfFileReducer } from './pdf/reducers';
import { ReadAllQuizToAttemptReducer, SaveQuizReducer, SubmitAllQuizReducer, GetQuizResultReducer } from './getQuiztoAttempt/reducers';
import { StudentRecentActionsDataReducer } from './studentRecentActions/reducers';
import { StudentBadgesDataReducer, ReadBadgeByIdReducer, ReadSubBadgeByIdReducer } from './studentBadgesEarned/reducers';
import { StudentWeeklyTopicsReducer } from './studentWeeklyTopics/reducers';
import { TessellatorTopicsReducer, TessellatorTopicPogramsReducer, MapTessellatorTopicReducer, MapTessellatorTopicToNullReducer } from './tessellatorTopics/reducers';
import { TeacherMonitorReducer } from './teacher-monitor/reducers';
import { getSectionsReducer } from './section/reducers';
import { ChangePasswordReducer } from './changepassword/reducers';
import { getLeaderboardReducer } from './leaderboard/reducers';
import { DailyLoginCountReducer, CurrentweekYearwiseReducer, TodaysReadAssessmentBadgesReducer } from './studentrelatedcount/reducers';
import LayoutChangeReducer from './themeLayout/reducers';
import { AnswersReducer } from './answers/reducers';
import { GetAssessmentLoginsReducer, updateStudentFlagReducer } from './assessmentLogins/reducers';

import { CollegeReducer, CollegeAddReducer, CollegeUpdateReducer } from './Colleges/reducers'
import { CohortReducer, CohortAddReducer, CohortUpdateReducer } from './cohorts/reducers'
import { STUDENTSReducer, STUDENTSUpdateReducer, STUDENTSAddReducer } from './students/reducers';
import { TEACHERSReducer, TEACHERSUpdateReducer, TEACHERSAddReducer } from './teachers/reducers';
import { TEACHERSSUBJECTSReducer } from './teacherssubjects/reducers';
import { TOPICSADMINReducer, TOPICSADMINUpdateReducer, TOPICSADMINAddReducer } from './topicsadmin/reducers';
import { BADGESReducer, BADGESADDReducer, BADGESUpdateReducer, BADGESDELETEReducer } from './badges/reducers';
import { NOTICEReducer, NOTICEAddReducer, NOTICEUpdateReducer, NOTICECOLLEGEReducer, NOTICECOLLEGEAddReducer } from './notice/reducers';
import { USERSPWDCHANGEUpdateReducer } from './UsersPasswordChange/reducers';

import { GetInterviewQuestionsReducer, SubmitInterviewReducer, SkipInterviewReducer, SaveInterviewReducer, SkipandSubmitReducer, getInterviewsByTopicReducer, getInterviewAttemptsReducer, QuitorTimeoutInterviewReducer, getInterviewsByScheduleReducer, StudentInterviewsReducer } from './interview/reducers'
import {
  SubjectWisePerformanceReducer, TopicWisePerformanceReducer, SubjectSummaryReducer, SingleSubjectSummaryReducer,
  SubjectsBestPerformerReducer, InactiveStudentsReducer, ActiveStudentsReducer, OverallPerformanceByCohortReducer,
  OverallSummaryReducer, TodaysOverallSummaryReducer, StudentOverallSummaryReducer
} from './studentPerformance/reducers'

import { EvalauateWithAudioReducer, EvalauateWithoutAudioReducer, ReEvaluateSingleReducer } from './evaluate/reducers'

const rootReducers = combineReducers({
  // fb: firebaseReducer,
  fs: firestoreReducer,
  auth: AuthReducer,
  ForgotPass: ForgotPasswordReducer,
  VerifyOTP: VerifyOTPReducer,
  ResetPassRes: ResetPassReducer,
  ChangeLayoutMode,
  currentWeek: getCurrentWeekReducer,
  ChangePassword: ChangePasswordReducer,
  msg: MessageReducer,
  Layout: LayoutChangeReducer,

  // Admin reducers
  colleges: CollegeReducer,
  collegeAddRes: CollegeAddReducer,
  collegeUpdateRes: CollegeUpdateReducer,

  cohorts: CohortReducer,
  cohortAddRes: CohortAddReducer,
  cohortUpdateRes: CohortUpdateReducer,

  Students: STUDENTSReducer,
  StudentsUpdateRes: STUDENTSUpdateReducer,
  StudentsAddRes: STUDENTSAddReducer,

  Subjects: SUBJECTSReducer,
  SubjectsAddRes: SUBJECTSAddReducer,
  SubjectsUpdateRes: SUBJECTSUpdateReducer,

  Teachers: TEACHERSReducer,
  TeachersUpdateRes: TEACHERSUpdateReducer,
  TeachersAddRes: TEACHERSAddReducer,
  TeachersSubjects: TEACHERSSUBJECTSReducer,

  Topicsadmin: TOPICSADMINReducer,
  TopicsUpdateResadmin: TOPICSADMINUpdateReducer,
  TopicsAddadminRes: TOPICSADMINAddReducer,

  Badges: BADGESReducer,
  BadgesUpdateRes: BADGESUpdateReducer,
  BadgesAddRes: BADGESADDReducer,
  BadgesDeleteRes: BADGESDELETEReducer,

  Notice: NOTICEReducer,
  NoticeUpdateRes: NOTICEUpdateReducer,
  NoticeAddRes: NOTICEAddReducer,
  NoticeCollegeAddRes: NOTICECOLLEGEAddReducer,
  NoticeCollege: NOTICECOLLEGEReducer,

  UsersPwdChange: USERSPWDCHANGEUpdateReducer,


  overallSummary: OverallSummaryReducer,
  todaysOverallSummary: TodaysOverallSummaryReducer,

  cohortSubjects: SubjectsByCohortReducer,
  SubjectTopics: TopicsBySubjectIdReducer,
  TopicInterviews: getInterviewsByTopicReducer,
  interviewAttempts: getInterviewAttemptsReducer,
  quitortimeoutRes: QuitorTimeoutInterviewReducer,

  ScheduleUpdateRes: updateScheduleReducer,
  scheduleTopicRes: ScheduleTopicReducer,
  delScheduleRes: DeleteScheduleReducer,

  EvalauateWithAudioResponse: EvalauateWithAudioReducer,
  EvalauateWithoutAudioResponse: EvalauateWithoutAudioReducer,
  ReEvaluateSingleRes: ReEvaluateSingleReducer,

  // teacher reducers
  CreateTopicRes: CreateTopicReducer,
  ImportTopicRes: ImportTopicReducer,
  ProgressData: ProgressReducer,
  QAFeedbackData: QAFReducer,
  CFData: ContentFeedbackReducer,
  RecentActions: RecentActionsDataReducer,
  DailyLoginCount: DailyLoginCountReducer,
  CurrentWeekYrwise: CurrentweekYearwiseReducer,
  NgrokUrl: getNgrokUrlReducer,
  TodaysBadgesCount: TodaysReadAssessmentBadgesReducer,
  AssessmentLogins: GetAssessmentLoginsReducer,
  AssessmentStudentFlagUpdateRes: updateStudentFlagReducer,

  //teacher get topics
  TopicsOfSubject: TOPICSOF_SUBJECTReducer,
  GenerateQuestions: GENERATE_QUESTIONSReducer,
  QuestionsOfTopic: QUESTIONSOF_TOPICReducer,
  TopicUpdate: TopicUpdateReducer,
  AcceptOrRejectQuestion: ACCEPTORREJECT_Reducer,
  Updatequestion: UPDATE_QUESTIONReducer,
  CreateQuestion: CreateQUESTION_Reducer,
  RegenerateQuestions: RegenerateQUESTIONS_Reducer,
  ImportQuestions: ImportQuestionsReducer,


  TeacherSubjects: TeacherSubjectsReducer,
  Subject: SUBJECTReducer,
  Unit: UNITReducer,
  Topics: TOPICSReducer,
  UploadContentRes: UploadContentReducer,
  ChunksForReview: ReviewContentReducer,
  updatedChunk: ReviewChunkUpdateReducer,
  DeleteChunkRes: DeleteChunkReducer,
  singleTopic: TOPICSingleReducer,
  TopicStatus: TOPICSTATUSReducer,
  GenerateQARes: postResponseReducer,
  QA: ReadQAReducer,
  QAUpdateRes: UpdateQAReducer,
  AllQAByTopic: ReadAllQAByTopicReducer,
  DeleteQARes: DeleteQAReducer,
  AnsEvaluateRes: AnswerEvaluateReducer,
  GenerateQuizRes: postQuizResponseReducer,
  AllQuiz: ReadAllQuizReducer,
  quizUpdateRes: UpdateQuizReducer,
  DeleteQuizRes: DeleteQuizReducer,
  AllQuizByTopic: ReadAllQuizByTopicReducer,
  Weeks: getScheduleWeeksReducer,
  TopicClearRes: TopicClearReducer,
  TopicScheduledData: getScheduledDataReducer,
  TessellatorTopics: TessellatorTopicsReducer,
  TessellatorTopicPrograms: TessellatorTopicPogramsReducer,
  MapTessellatorTopicRes: MapTessellatorTopicReducer,
  MapTsTopicToNullRes: MapTessellatorTopicToNullReducer,
  TeacherMonitor: TeacherMonitorReducer,
  Answers: AnswersReducer,
  Sections: getSectionsReducer,
  RegYrsem: getRegYrsReducer,
  TopicContent: TOPICContentReducer,
  subjectsBestPerformer: SubjectsBestPerformerReducer,
  subjectSummary: SubjectSummaryReducer,
  singleSubjectSummary: SingleSubjectSummaryReducer,
  inactiveStudents: InactiveStudentsReducer,
  activeStudents: ActiveStudentsReducer,
  overallPerformanceByCohort: OverallPerformanceByCohortReducer,

  // admin reducers
  Regulations: REGULATIONReducer,
  RegulationSingle: REGULATIONSingleReducer,
  YearSemester: YEARSEMESTERReducer,
  YearSemesterSingle: YEARSEMESTERSingleReducer,
  interviewsBySchedule: getInterviewsByScheduleReducer,


  // student reducers
  studentSchedules: StudentSchedulesReducer,
  subjectWisePerformance: SubjectWisePerformanceReducer,
  topicWisePerformance: TopicWisePerformanceReducer,
  collegeWiseNotce: CollegeWiseMessageReducer,
  StudentSubjects: ReadStudentSubjectsReducer,
  StudentTopics: ReadTopicsByIdReducer,
  Units: ReadSubjectUnitsReducer,
  UnitTopics: TOPICSByUnitReducer,
  CFQuestions: GetContentFBQReducer, // content feedback questions
  VerifyRes: VerifyFeedbackReducer,
  FBPostRes: PostFeedbackReducer,
  PdfFile: PdfFileReducer,
  QuiztoAttempt: ReadAllQuizToAttemptReducer,
  QuizSaveRes: SaveQuizReducer,
  SubmitAllQuizRes: SubmitAllQuizReducer,
  QuizResult: GetQuizResultReducer,
  StudentRecentActions: StudentRecentActionsDataReducer,
  StudentBadges: StudentBadgesDataReducer,
  SingleBadgeImg: ReadBadgeByIdReducer,
  SubBadges: ReadSubBadgeByIdReducer,
  StudentWeeklyTopics: StudentWeeklyTopicsReducer,
  studentTopicsByUnit: StudentTopicsByUnitReducer,
  leaderboardResults: getLeaderboardReducer,
  AttemptedQA: GETATTEMPTEDQAReducer,

  RecentInterviews: RecentInterviewsReducer,

  interviewQuestions: GetInterviewQuestionsReducer,
  submitInterviewRes: SubmitInterviewReducer,
  interviewSaveRes: SaveInterviewReducer,
  interviewSkipRes: SkipInterviewReducer,
  skipSubmitRes: SkipandSubmitReducer,

  studentOverallSummary: StudentOverallSummaryReducer,
  studentInterviews: StudentInterviewsReducer,
});

export default rootReducers;
