import { READ_QAF_BEGIN, READ_QAF_SUCCESS, READ_QAF_ERROR } from './actions';

const initialState = {
    data: [],
    loading: false,
    error: null,
};

const QAFReducer = (state = initialState, action) => {
    switch (action.type) {
        case READ_QAF_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case READ_QAF_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false,
            };
        case READ_QAF_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};


export { QAFReducer };
