const actions = {

  CONTENT_ADD_BEGIN: 'CONTENT_ADD_BEGIN',
  CONTENT_ADD_SUCCESS: 'CONTENT_ADD_SUCCESS',
  CONTENT_ADD_ERR: 'CONTENT_ADD_ERR',


  contentAddBegin: () => {
    return {
      type: actions.CONTENT_ADD_BEGIN,
    };
  },

  contentAddSuccess: (data) => {    
    return {
      type: actions.CONTENT_ADD_SUCCESS,      
      data,
    };
  },

  contentAddErr: (err) => {
    return {
      type: actions.CONTENT_ADD_ERR,
      err,
    };
  },
};

export default actions;
