import React, { useEffect, useState } from 'react';
import UilCheck from '@iconscout/react-unicons/icons/uil-check';
import { PageHeader } from '../../components/page-headers/page-headers';
import { BasicFormWrapper, Main } from '../styled';
import { useNavigate, useParams } from 'react-router-dom';
import { WizardWrapper, WizardFour } from './styles/wizardstyle';
import { Steps } from '../../components/steps/steps';
import { Checkbox, Col, Form, Input, Modal, Result, Row, Select, Tooltip, notification } from 'antd';
import Heading from '../../components/heading/heading';
import { useDispatch, useSelector } from 'react-redux';
import { Cards } from '../../components/cards/frame/cards-frame';
import TextArea from 'antd/lib/input/TextArea';
import { SelectWrapperStyle } from '../ui-elements/ui-elements-styled';
import { Button } from '../../components/buttons/buttons';
import { Tag } from '../../components/tags/tags';
import { chunksDataRead, deleteChunk, updateChunk } from '../../redux/reviewContent/actionCreator';
import { clearTopicByTopicId, getTopicById, FreezeTopicById } from '../../redux/topics/actionCreator';
import { Bullet, NoteCardWrap } from '../note/style';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { alertModal } from '../../components/modals/antd-modals';
import UilTrashAlt from '@iconscout/react-unicons/icons/uil-trash-alt'

import actions from '../../redux/topics/actions';
import reviewContentActions from '../../redux/reviewContent/actions';
import TopicStatusId from './TopicStatusId';

const { deleteChunkSuccess, updateChunkSuccess } = reviewContentActions;

const { clearTopicSuccess } = actions;

const { Option } = Select;

function ReviewContent({ topicStatus, topic }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { subjectId, unitId, topicId } = useParams()
  const [form] = Form.useForm();

  const [chunks, setChunks] = useState([])
  const [selectedChunk, setSelectedChunk] = useState(0)
  const [currentChunkContent, setCurrentChunkContent] = useState("")

  const [changedContent, setChangedContent] = useState("")
  const [cancelModal, setCancelModal] = useState(false);
  const [reviewCompletedModal, setReviewCompletedModal] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const { chunksData, isLoading, updatedChunk, isUpdating, TopicStatusLoading, DeleteChunkRes, isDeleting, TopicClearRes, TopicClearLoading } = useSelector((state) => {
    return {
      chunksData: state.ChunksForReview.data,
      isLoading: state.ChunksForReview.loading,
      updatedChunk: state.updatedChunk.data,
      isUpdating: state.updatedChunk.loading,
      TopicStatusLoading: state.TopicStatus.loading,
      DeleteChunkRes: state.DeleteChunkRes.data,
      isDeleting: state.DeleteChunkRes.loading,
      TopicClearRes: state.TopicClearRes.data,
      TopicClearLoading: state.TopicClearRes.loading
    };
  });


  useEffect(() => {
    if (topicStatus === "CONTENT UPLOADED" || topicStatus === "CONTENT REVIEWED") {
      dispatch(chunksDataRead(parseInt(topicId)));
    }
    setIsDirty(false)
  }, [topicId, updatedChunk, DeleteChunkRes]);

  useEffect(() => {
    const updateFunction = async () => {
      if (updatedChunk !== null && updatedChunk.updatestatusres !== null && updatedChunk.updatestatusres.Error === false) {
        dispatch(getTopicById(parseInt(topicId)));
        dispatch(updateChunkSuccess(null))
        if (chunks.length - 1 !== selectedChunk) {
          const chunkContent = await chunks[parseInt(selectedChunk) + 1].content
          setSelectedChunk(parseInt(selectedChunk) + 1)
          setCurrentChunkContent(chunkContent)
          setChangedContent(chunkContent)
        }
      }
    }
    updateFunction()

  }, [updatedChunk])

  useEffect(() => {
    if (DeleteChunkRes !== null && DeleteChunkRes.Error === false) {
      dispatch(getTopicById(parseInt(topicId)));
      dispatch(deleteChunkSuccess(null))
      setSelectedChunk(0)
    }
  }, [DeleteChunkRes]);

  useEffect(() => {
    if (chunksData !== null) {
      setChunks(chunksData.chunks)
    }

    if (chunksData !== null && parseInt(chunksData.chunkscount) !== 0 && parseInt(chunksData.chunksreviewcount) === parseInt(chunksData.chunkscount) && chunksData.status === "CONTENT UPLOADED" && topicStatus === "CONTENT UPLOADED") {
      dispatch(getTopicById(parseInt(topicId)));
    }
    else if (chunksData !== null && parseInt(chunksData.chunkscount) !== 0 && topicStatus === "CONTENT REVIEWED") {
      setReviewCompletedModal(true)
    }
    else if (chunksData !== null && parseInt(chunksData.chunkscount) === 0 && topicStatus === "CONTENT UPLOADED") {
      dispatch(getTopicById(parseInt(topicId)));
    }
  }, [chunksData]);


  useEffect(() => {
    if (chunks.length !== 0) {
      setCurrentChunkContent(chunks[selectedChunk].content)
      setChangedContent(chunks[selectedChunk].content)
    }
  }, [chunks]);


  const handleSelectChange = async (index) => {
    if (isDirty) {
      notification.warn({
        message: 'Changes occur in current chunk',
      });
    }
    else {
      setSelectedChunk(parseInt(index))
      const chunkContent = await chunks[index].content
      setCurrentChunkContent(chunkContent)
      setChangedContent(chunkContent)
    }
  }

  const handleContentChange = (e) => {
    setChangedContent(e.target.value);
    if (currentChunkContent !== e.target.value) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  };

  const handleReset = () => {
    if (currentChunkContent !== changedContent) {
      setCancelModal(true);
    } else {
      setIsDirty(false);
    }
  };

  const handleDiscardChanges = () => {
    setChangedContent(currentChunkContent);
    setCancelModal(false);
    setIsDirty(false);
  };

  const handleModalCancel = () => {
    setCancelModal(false);
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);


  const handleSubmit = async () => {
    const data = {
      id: chunks[selectedChunk].id,
      content: changedContent
    }
    dispatch(updateChunk(parseInt(topicId), data))
    setIsDirty(false)
  }


  const showDeleteConfirm = () => {
    alertModal.confirm({
      title: `Do you want to delete chunk ${selectedChunk + 1}?`,
      content: 'Chunk once deleted cannot be undone.',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        const chunkId = chunks.length !== 0 && chunks[selectedChunk].id
        dispatch(deleteChunk(chunkId, parseInt(topicId)));
      },
      onCancel() { },
    });
  };

  const showReviewedConfirm = () => {
    alertModal.confirm({
      title: `Content chunks ${chunksData !== null && chunksData.chunksreviewcount} of ${chunksData !== null && chunksData.chunkscount} are Reviewed`,
      content: `Do you want to generate Q & A of topic ${chunksData !== null && chunksData.name}?`,
      onOk() {
        const datatoPost = {
          id: parseInt(topicId),
          status: "CONTENT REVIEWED"
        }
        dispatch(FreezeTopicById(datatoPost))
      },
      onCancel() { },
    });
  };

  const completeReview = () => {
    setReviewCompletedModal(false)
    navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/generate/questions`)
  }

  const clearTopic = () => {
    if (topicStatus === "CONTENT UPLOADED" || topicStatus === "CONTENT REVIEWED") {
      dispatch(clearTopicByTopicId(parseInt(topicId)))
    }
    else if (topicStatus === "NOTHING") {
      navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/upload`)
    }
  }

  useEffect(() => {
    if (TopicClearRes !== null && TopicClearRes.message === "deleted" && TopicClearRes.payload.status === "NOTHING") {
      dispatch(clearTopicSuccess(null))
      navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/upload`)
    }
  }, [TopicClearRes])


  return (
    <>
      <div>
        {
          isLoading || TopicStatusLoading || TopicClearLoading ?
            <div className="custom-spin">
              <CustomSpinner />
            </div>
            :
            <div>
              <div>
                {
                  chunksData !== null && parseInt(chunksData.chunkscount) === 0 || topicStatus === "NOTHING" ?
                    <div className='' style={{ fontSize: '18px' }}>
                      <Result
                        status="warning"
                        title="Content not found"
                        extra={
                          <Button id="reviewcontent_uploadcontent-button" type="primary" key="upload" onClick={() => clearTopic()}>
                            Upload
                          </Button>
                        }
                      />
                    </div>
                    :
                    (topicStatus === "CONTENT UPLOADED" || topicStatus === "CONTENT REVIEWED") ?
                      <div>
                        <div className='d-flex justify-content-between align-center-v mb-10'>
                          <SelectWrapperStyle>
                            Select&nbsp;
                            <Select id="reviewcontent_select-chunk" value={selectedChunk} style={{ width: 130 }} onSelect={(e) => handleSelectChange(e)} >
                              {
                                chunks.length !== 0 && chunks.map((chunckObj, index) =>
                                  <Option key={index} value={index} >Chunk {index + 1}</Option>
                                )
                              }
                            </Select>
                            &nbsp;of {chunks.length}
                          </SelectWrapperStyle>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className='taglist-wrap' style={{ marginRight: 20 }}>
                              <Tag color="orange">Last saved : {chunksData !== null && chunksData.chunks.length !== 0 && new Date(chunksData.chunks[selectedChunk].updatedon).toLocaleString()}</Tag>
                            </div>
                            <Tooltip placement='left' title="Delete current chunk">
                              <Button id="reviewcontent_delete-button" type="danger" transparented onClick={showDeleteConfirm} disabled={isDeleting ? true : false}>
                                {isDeleting ? 'Deleting' : <UilTrashAlt />}
                              </Button>
                            </Tooltip>
                          </div>
                        </div>
                        <Row justify="center">
                          <Col xs={24}>
                            <div className="create-account-form">
                              <Heading as="h4"></Heading>
                              <Form id="reviewchunk-form" form={form} name="review-chunk" initialValues={{ "chunk": chunks.length !== 0 && chunks[selectedChunk].content }}>
                                <Form.Item name="chunk">
                                  <TextAreaComponent id="reviewcontent_chunk-content-textarea" data={changedContent} chunks={chunks} selectedChunk={selectedChunk} onchange={handleContentChange} currentChunkContent={currentChunkContent} />
                                </Form.Item>
                              </Form>
                            </div>
                          </Col>
                        </Row>
                        <div className='d-flex justify-content-between mb-20'>
                          <div className='taglist-wrap'>
                            {/* <Checkbox style={{ marginLeft: '5px' }} checked>
                                  Auto save
                                </Checkbox> */}
                            {/* <br/> */}
                            {/* <Tag color="orange">Last saved : {chunksData !== null && chunksData.chunks.length !== 0 && new Date(chunksData.chunks[selectedChunk].updatedon).toLocaleString()}</Tag> */}
                          </div>
                          <div className=''>
                            <Tooltip placement='left' title="Changes will be discarded">
                              <Button id="reviewcontent_reset-button" htmlType="submit" type="white" outlined style={{ color: 'black', marginRight: '10px' }} onClick={handleReset} >
                                Reset
                              </Button>
                            </Tooltip>
                            <Button id="reviewcontent_review-button" htmlType="submit" type="primary" onClick={handleSubmit} disabled={isUpdating ? true : false} >
                              {isUpdating ? 'Please Wait..' : 'Review & Next'}
                            </Button>
                          </div>
                        </div>
                      </div>
                      :
                      (topicStatus === "QUESTIONS GENERATED" || topicStatus === "QUESTIONS REVIEWED") ?
                        <div>
                          <Result
                            status="warning"
                            title="Questions & Answers has been generated for this topic. You cannot modify the content."
                            extra={
                              <Button id="reviewcontent_reviewqa-button" type="primary" key="navigateToReviewContent" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/review/questions`)}>
                                Review Q & A
                              </Button>
                            }
                          />
                        </div>
                        :
                        (topicStatus === "QUIZ GENERATED" || topicStatus === "QUIZ REVIEWED") ?
                          <div>
                            <Result
                              status="warning"
                              title="MCQS has been generated for this topic. You cannot modify the content."
                              extra={
                                <Button id="reviewcontent_reviewmcq-button" type="primary" key="navigateToReviewContent" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/review/quiz`)}>
                                  Review MCQS
                                </Button>
                              }
                            />
                          </div>
                          :
                          TopicStatusId(topicStatus) === 7 ?
                            <div>
                              <Result
                                status='success'
                                title="Programs Verified"
                                extra={
                                  <Button id="reviewcontent_freezetopic-button" type="primary" key="freeze topic" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/freezeschedule`)}>
                                    Freeze Topic
                                  </Button>
                                }
                              />
                            </div>
                            :
                            (topicStatus === "CONTENT FREEZED") &&
                            <div>
                              <Result
                                status="success"
                                title="Content Freezed"
                                subTitle ={`on ${topic !== null && new Date(topic.updatedon).toLocaleString()}`}
                              />
                            </div>
                }
              </div>
            </div>
        }
      </div>
      <Modal open={cancelModal} title="Discard Changes" onCancel={handleModalCancel} onOk={handleDiscardChanges} okText="Yes" cancelText="No">
        <h3>Do you want to DISCARD the changes?</h3>
      </Modal>
      <Modal open={reviewCompletedModal} title="Content Review" onCancel={() => setReviewCompletedModal(false)} onOk={completeReview} okText="Yes" cancelText="No">
        <h3>Content chunks {chunksData !== null && chunksData.chunksreviewcount} of {chunksData !== null && chunksData.chunkscount} are reviewed</h3>
        <p>Do you want to generate Q & A for {chunksData !== null && chunksData.name}?</p>
      </Modal>
    </>
  );
}

export default ReviewContent;


const TextAreaComponent = ({ data, onchange, chunks, selectedChunk, currentChunkContent }) => {

  return (
    <div>
      <TextArea rows={18} placeholder='Chunk data here...' value={data} maxLength={currentChunkContent.length} onChange={onchange} style={{ textAlign: 'left', borderColor: `${chunks.length !== 0 && chunks[selectedChunk].reviewflag === true ? 'green' : ''}` }} />
      <div className='text-right mt-10'>
        <span className='fontsize-13'>Characters {data.length} / {currentChunkContent.length}</span>
      </div>
    </div>
  );
}
