import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Input, Modal, Form, Select, message, Checkbox, Space } from 'antd';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import { DownloadOutlined } from '@ant-design/icons';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'; // Import icons
import { ProjectList, ProjectSorting } from '../project/style';
import { Cards } from '../../components/cards/frame/cards-frame';
import { TableWrapper, Main } from '../styled';
import { AutoComplete } from '../../components/autoComplete/autoComplete';
import { Button } from '../../components/buttons/buttons';
import { PageHeader } from '../../components/page-headers/page-headers';
import { useDispatch, useSelector } from 'react-redux';
import { studentsDataRead, studentsDataSubmit, studentsDataUpdate } from '../../redux/students/actionCreator';
import Heading from '../../components/heading/heading';
import Dragger from 'antd/lib/upload/Dragger';
import UilExport from "@iconscout/react-unicons/icons/uil-export";
import { ImportStyleWrap } from '../styled';
import Papa from 'papaparse';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { getItem } from '../../utility/localStorageControl';
import { collegeDataRead } from '../../redux/Colleges/actionCreator';
import { cohortDataRead } from '../../redux/cohorts/actionCreator';

const { Option } = Select;

const sampleCSVData = [
    {
        name: "student1",
        username: "studentuser1",
        password: 'password',
    },
    {
        name: "student2",
        username: "studentuser2",
        password: 'password',
    },
    {
        name: "student3",
        username: "studentuser3",
        password: 'password',
    }];

function Students() {
    const clgName = getItem('collegeName');
    let ClgName = '(' + clgName + ')';
    const loggedadmin = getItem('username');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isBulkModalVisible, setIsBulkModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isNewModalVisible, setIsNewModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [editForm] = Form.useForm();
    const [bulkForm] = Form.useForm();

    const [csvFile, setCSVFile] = useState(null);
    const [csvFileList, setCSVFileList] = useState([]);
    const [parsedCSVData, setParsedCSVData] = useState([]);
    const [editRecord, setEditRecord] = useState(null);

    const dispatch = useDispatch();
    const { crud, isLoading, collegeData, cohortsData, StudentsUpdateRes, isStudentsUpdating, StudentsAddRes, isStudentsAdding, StudentsBulkAddRes, isStudentsBulkAdding, StudentsBulkUpdateRes, isStudentsBulkUpdating, StudentsBulkAddUpdateRes, isStudentsBulkAddingUpdating } = useSelector((state) => {
        return {
            crud: state.Students.data,
            isLoading: state.Students.loading,

            StudentsUpdateRes: state.StudentsUpdateRes.data,
            isStudentsUpdating: state.StudentsUpdateRes.loading,

            collegeData: state.colleges.data,

            StudentsAddRes: state.StudentsAddRes.data,
            isStudentsAdding: state.StudentsAddRes.loading,

            cohortsData: state.cohorts.data,
        };
    });

    useEffect(() => {
        dispatch(studentsDataRead());
        dispatch(collegeDataRead());
        dispatch(cohortDataRead());
    }, [dispatch]);

    useEffect(() => {
        if (StudentsUpdateRes && StudentsUpdateRes.Error === false) {
            dispatch(studentsDataRead());
        }
    }, [StudentsUpdateRes])

    // useEffect(()=> {
    //     if(StudentsBulkAddRes !== null || StudentsBulkAddRes !== undefined){
    //         // dispatch(studentsDataRead(clgName));
    //     }
    // },[StudentsBulkAddRes])

    // useEffect(()=> {
    //     if(StudentsBulkUpdateRes !== null || StudentsBulkUpdateRes !== undefined){
    //         // dispatch(studentsDataRead(clgName));
    //     }
    // },[StudentsBulkUpdateRes])

    // useEffect(()=> {
    //     if(StudentsBulkAddUpdateRes !== null || StudentsBulkAddUpdateRes !== undefined){
    //         // dispatch(studentsDataRead(clgName));
    //     }
    // },[StudentsBulkAddUpdateRes])

    useEffect(() => {
        if (StudentsAddRes && StudentsAddRes.Error === false) {
            dispatch(studentsDataRead());
        }
    }, [StudentsAddRes])

    const [dataSource, setDataSource] = useState([]);
    const [GoodData, setGoodData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [selectedRegulationId, setSelectedRegulationId] = useState(null);
    const [selectedYearId, setSelectedYearId] = useState(null);
    const [selectedBranchId, setSelectedBranchId] = useState(null);
    const [selectedSectionId, setSelectedSectionId] = useState(null);
    const [searchText, setsearchText] = useState(null);

    // const [isUsernameChecked, setUsernameChecked] = useState(true);
    // const [isNameChecked, setNameChecked] = useState(false);
    // const [isCurrentyearChecked, setCurrentyearChecked] = useState(false);
    // const [isCGPAChecked, setCGPAChecked] = useState(false);
    // const [isEmailChecked, setEmailChecked] = useState(false);
    // const [isPasswordChecked, setPasswordChecked] = useState(false);
    // const [isSectionChecked, setSectionChecked] = useState(false);
    // const [isBranchChecked, setBranchChecked] = useState(false);
    // const [isAllChecked, setIsAllChecked] = useState(false);


    // const handleAllCheckboxToggle = () => {
    //     const allCheckedState = !isAllChecked;
    //     setIsAllChecked(allCheckedState);
    //     if(selectedMode === '' && allCheckedState === true ){
    //         setEmailChecked(true);
    //         setCGPAChecked(true);
    //         setCurrentyearChecked(true);
    //         setNameChecked(true);
    //         setPasswordChecked(true);
    //         setSectionChecked(true);
    //         setBranchChecked(true);
    //     }
    //     if(selectedMode === '' && allCheckedState === false ){
    //         setEmailChecked(false);
    //         setCGPAChecked(false);
    //         setCurrentyearChecked(false);
    //         setNameChecked(false);
    //         setPasswordChecked(false);
    //         setSectionChecked(false);
    //         setBranchChecked(false);
    //     }
    //     if((selectedMode === 'Addnewstudents' || selectedMode === 'AddNewUpdateExistingStudents') && allCheckedState === true){
    //         setEmailChecked(true);
    //         setCGPAChecked(true);
    //         setCurrentyearChecked(true);
    //         setNameChecked(true);
    //         setPasswordChecked(true);
    //         setSectionChecked(true);
    //         setBranchChecked(true);
    //     }
    //     if((selectedMode === 'Addnewstudents' || selectedMode === 'AddNewUpdateExistingStudents') && allCheckedState === false){
    //         setEmailChecked(false);
    //         setCGPAChecked(false);
    //         setCurrentyearChecked(true);
    //         setNameChecked(true);
    //         setPasswordChecked(true);
    //         setSectionChecked(true);
    //         setBranchChecked(true);
    //     }
    //     if(selectedMode === 'Updateexistingstudents'  && allCheckedState === false){
    //         setEmailChecked(false);
    //         setCGPAChecked(false);
    //         setCurrentyearChecked(false);
    //         setNameChecked(false);
    //         setPasswordChecked(false);
    //         setSectionChecked(false);
    //         setBranchChecked(false);
    //     }
    //     if(selectedMode === 'Updateexistingstudents'  && allCheckedState === true){
    //         setEmailChecked(true);
    //         setCGPAChecked(true);
    //         setCurrentyearChecked(true);
    //         setNameChecked(true);
    //         setPasswordChecked(true);
    //         setSectionChecked(true);
    //         setBranchChecked(true);
    //     }
    //   };

    // const handleModeChange = (value) => {
    //     const shouldCheckNameAndPassword = value === 'Addnewstudents' || value === 'AddNewUpdateExistingStudents';
    //     setSelectedMode(value);
    //     setNameChecked(shouldCheckNameAndPassword);
    //     setPasswordChecked(shouldCheckNameAndPassword);
    //     setCurrentyearChecked(shouldCheckNameAndPassword);
    //     setBranchChecked(shouldCheckNameAndPassword);
    //     setSectionChecked(shouldCheckNameAndPassword);
    // };

    // const handleNameCheckboxChange = (e) => {
    //   if (selectedMode !== 'Addnewstudents' && selectedMode !== 'AddNewUpdateExistingStudents') {
    //     setNameChecked(e.target.checked);
    //   }
    // };

    // const handleSectionCheckboxChange = (e) => {
    //   if (selectedMode !== 'Addnewstudents' && selectedMode !== 'AddNewUpdateExistingStudents') {
    //     setSectionChecked(e.target.checked);
    //   }
    // };

    // const handleBranchCheckboxChange = (e) => {
    //   if (selectedMode !== 'Addnewstudents' && selectedMode !== 'AddNewUpdateExistingStudents') {
    //     setBranchChecked(e.target.checked);
    //   }
    // };

    // const handlePasswordCheckboxChange = (e) => {
    //   if (selectedMode !== 'Addnewstudents' && selectedMode !== 'AddNewUpdateExistingStudents') {
    //     setPasswordChecked(e.target.checked);
    //   }
    // };

    // const handleCurrentyearCheckboxChange = (e) => {
    //   if (selectedMode !== 'Addnewstudents' && selectedMode !== 'AddNewUpdateExistingStudents') {
    //     setCurrentyearChecked(e.target.checked);
    //   }
    // };

    // useEffect(() => {
    //     const allCheckboxes = [
    //       isNameChecked,
    //       isEmailChecked,
    //       isPasswordChecked,
    //       isCGPAChecked,
    //       isCurrentyearChecked,
    //       isSectionChecked,
    //       isBranchChecked,
    //     ];
    //     const allChecked = allCheckboxes.every((checkbox) => checkbox === true);
    //     setIsAllChecked(allChecked);
    // },[ isNameChecked,isBranchChecked, isSectionChecked, isPasswordChecked,isEmailChecked,isCGPAChecked,isCurrentyearChecked]);

    // function formatDateTime(dateTime) {
    //   const dateObject = dateTime;
    //   const formattedDate = dateObject.split('T')[0].replace(/-/g, '/');
    //   return formattedDate;
    // }

    useEffect(() => {
        if (Array.isArray(crud)) {
            const filteredData = crud.sort((a, b) => a.updatedon < b.updatedon ? 1 : -1).map((item, index) => ({
                key: item.id,
                sno: index + 1,
                id: item.id,
                name: item.name,
                username: item.usermaster?.username.toLowerCase(),
                status: item.usermaster?.status,
                cohort: item.cohorts?.map((item) => item.name).join(', '),
                updatedBy: item.updatedby !== null ? item.updatedby : '--------',
                updatedon: new Date(item.updatedon).toLocaleString('en-GB'),
                createdon: new Date(item.createdon).toLocaleString('en-GB'),

            }));
            setDataSource(filteredData);
            setGoodData(filteredData);
            setLoading(false);
        }
    }, [crud])

    const checkRegulation = (i) => {
        let tarr = i.regulation;
        let valueInArray = false;
        if (tarr === selectedRegulationId) {
            valueInArray = true;
        }
        return valueInArray;
    }

    const checkBranch = (i) => {
        let tarr = i.branch;
        let valueInArray = false;
        if (tarr === selectedBranchId) {
            valueInArray = true;
        }
        return valueInArray;
    }

    const checkYear = (i) => {
        let tarr = i.year;
        let valueInArray = false;
        if (tarr === selectedYearId) {
            valueInArray = true;
        }
        return valueInArray;
    }

    const checkSection = (i) => {
        let tarr = i.section;
        let valueInArray = false;
        if (tarr === selectedSectionId) {
            valueInArray = true;
        }
        return valueInArray;
    }

    const checkSearch = (i) => {
        for (let j in i) {
            if (i[j] !== null && i[j].toString().toLowerCase().includes(searchText.toString().toLowerCase())) {
                return true;
            }
        }
        return false;
    }
    useEffect(() => {
        let tempValues = [...GoodData];
        if (selectedRegulationId != null) {
            tempValues = tempValues.filter(checkRegulation);
        }
        if (selectedSectionId != null) {
            tempValues = tempValues.filter(checkSection);
        }
        if (selectedBranchId != null) {
            tempValues = tempValues.filter(checkBranch);
        }
        if (selectedYearId != null) {
            tempValues = tempValues.filter(checkYear);
        }
        if (searchText != null) {
            tempValues = tempValues.filter(checkSearch);
        }
        setDataSource(tempValues);
    }, [selectedSectionId, selectedBranchId, selectedYearId, selectedRegulationId, searchText])

    const handleSearch = (e) => {
        setsearchText(e)
    }

    const handleRemoveCSVFile = () => {
        setCSVFile(null);
        setCSVFileList([])
    }

    const handleCSVFileChange = (uploadedFile) => {
        if (uploadedFile !== null && uploadedFile.fileList.length !== 0) {
            setCSVFileList(uploadedFile.fileList);
            message.success("File Uploaded");
        } else {
            message.warning("File Removed");
            handleRemoveCSVFile();
        }
    };

    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
            sorter: (a, b) => a.sno > b.sno ? 1 : -1,
        },
        {
            title: 'Roll Number',
            dataIndex: 'username',
            key: 'username',
            sorter: (a, b) => a.username > b.username ? 1 : -1,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name > b.name ? 1 : -1,
        },
        {
            title: 'Batch(s)',
            dataIndex: 'cohort',
            key: 'cohort',
            filters: [...new Set(dataSource?.map(item => item.cohort))]?.map(cohort => ({ text: cohort, value: cohort })),
            onFilter: (value, record) => record.cohort === value,
            // sorter: (a, b) => a.cohort > b.cohort ? 1 : -1,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'Created On',
            dataIndex: 'createdon',
            key: 'createdon',
            sorter: (a, b) => a.createdon > b.createdon ? 1 : -1,
        },
        {
            title: 'Updated On',
            dataIndex: 'updatedon',
            key: 'updatedon',
            sorter: (a, b) => a.updatedon > b.updatedon ? 1 : -1,
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            sorter: (a, b) => a.updatedBy > b.updatedBy ? 1 : -1,
        },
    ];

    if (clgName === 'ALL') {
        columns.splice(8, 0,
            {
                title: 'College',
                dataIndex: 'collegename',
                key: 'collegename',
            }
        );
    }

    if (loggedadmin !== 'superadmin') {
        columns.push({
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <div className='table-actions'>
                    <Button
                        key="editStudent"
                        onClick={() => handleEdit(record)}
                        className="btn-icon "
                        type="primary"
                        to="#"
                        shape="circle"
                    >
                        <UilEdit />
                    </Button>
                </div>
            ),
        });
    }

    const handleButtonClick = () => {
        setIsModalVisible(true);
    };

    const handleButtonBulkClick = () => {
        setIsBulkModalVisible(true);
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const csvcolumns = [
        {
            title: 'Roll Number',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Student Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Password',
            dataIndex: 'password',
            key: 'password',
        },
    ];

    const validateUsername = (rule, value) => {
        let find = dataSource?.find((obj) => obj.username == value)
        if (!value) {
            return Promise.reject('Please enter student username');
        }
        else if (!/^[a-zA-Z0-9]+$/.test(value)) {
            return Promise.reject('Username can only contain numbers and alphabets');
        }
        else if (find) {
            return Promise.reject('Username already exists!');
        }
        else {
            return Promise.resolve();
        }
    };

    const validatePassword = (rule, value) => {
        if (!value) {
            return Promise.reject('Please enter a password');
        } else if (/\s/.test(value)) {
            return Promise.reject('Password cannot contain spaces');
        } else {
            return Promise.resolve();
        }
    };

    const handleModalAdd = () => {
        form.validateFields().then((values) => {
            const newRow = {
                name: values.name,
                username: values.username,
                password: values.password,
                collegeId: values.collegeId,
                cohortIds: values.cohortId,
            };
            form.resetFields();
            setIsModalVisible(false);
            // console.log(newRow);
            dispatch(studentsDataSubmit([newRow]));
        })
            .catch((error) => {
                console.error('Error while validating form:', error);
            });
    };

    const handleEdit = (record) => {
        setEditRecord(record);
        setIsEditModalVisible(true);
        const studentCohorts = record.cohort.split(",").map(cohort => cohort.trim());
        const cohort = cohortsData.filter((item) => studentCohorts.includes(item.name)).map(item => item.id)
        editForm.setFieldsValue({
            name: record.name,
            cohortId: cohort,
            status: record.status
        });
    };

    const handleBulkFormPreview = () => {
        bulkForm.validateFields().then((values) => {
            Papa.parse(csvFile, {
                complete: (result) => {
                    result.data.pop();
                    const invalidHeaders = result.meta.fields.filter(item => item !== "username" && item !== "name" && item !== 'password');
                    if (invalidHeaders.length > 0) {
                        message.error("Invalid columns found, only username, name and password columns are allowed", 3)
                        return;
                    }
                    const EmptyCells = result.data.filter((row) => {
                        return Object.values(row).every((cell) => cell.trim() !== '');
                    });
                    if (EmptyCells.length !== result.data.length) {
                        message.error("Empty values found in the uploaded CSV file. Please make sure all cells are filled.", 3);
                        return;
                    }
                    const filteredCSVData = result.data.filter((row) => Object.values(row).some((cell) => cell.trim() !== ''));
                    const parsedCSVData = filteredCSVData.map((item) => ({
                        username: item.username,
                        name: item.name,
                        password: item.password,
                        collegeId: values.collegeId,
                        cohortIds: values.cohortId,
                    }));
                    setParsedCSVData(parsedCSVData);
                    bulkForm.resetFields();
                    setCSVFile(null);
                    setCSVFileList([]);
                    setIsBulkModalVisible(false);
                    setIsNewModalVisible(true);
                },
                header: true,
            });
        }).catch((error) => {
            console.error('Error while validating form:', error);
        });
    };

    const handleBulkDispatch = () => {
        // console.log(parsedCSVData);
        dispatch(studentsDataSubmit(parsedCSVData));
        setIsNewModalVisible(false);
    }


    const handleModalUpdate = () => {
        editForm.validateFields().then((values) => {            
            const updatedRecord = {
                id: parseInt(editRecord.key),
                username: editRecord.username,
                name: values.name,
                cohortIds: values.cohortId,
                status : values.status
            };
            editForm.resetFields()
            setIsEditModalVisible(false);
            dispatch(studentsDataUpdate([updatedRecord]));
        }).catch((error) => {
            console.error('Error while validating form:', error);
        });
    };

    return (
        <>
            <PageHeader className="ninjadash-page-header-main" title={`Students ${loggedadmin === 'superadmin' ? ClgName : ''}`}
                buttons={
                    loggedadmin !== 'superadmin' ?
                        [
                            <Button id="students-page-add_student-button" onClick={handleButtonClick} key="AddStudent" type="primary" size="default">
                                Add Student
                            </Button>,
                            <Button id="students-page-add/update-bulk-student-button" onClick={handleButtonBulkClick} key="AddBulkStudents" type="primary" size="default">
                                Add Students in Bulk
                            </Button>,
                        ] : []}
            />
            <Main>
                {isLoading || isStudentsAdding || isStudentsUpdating ?
                    (
                        <div className="spin">
                            <CustomSpinner Message={'Loading please wait...'} />
                        </div>
                    ) : (
                        <Row gutter={25}>
                            <Col sm={24} xs={24}>
                                <Cards headless>
                                    <TableWrapper>
                                        <ProjectList>
                                            <div className='d-flex justify-content-between mb-15 p-0 align-center-v'>
                                                <div className="d-flex align-center-v" style={{ marginLeft: 'auto' }}>
                                                    <AutoComplete id="students-page-search-filter" onSearch={handleSearch} dataSource={dataSource} placeholder="Search here" patterns />
                                                </div>
                                            </div>
                                            <div className='table-responsive'>
                                                <Table dataSource={dataSource} columns={columns} />
                                            </div>
                                        </ProjectList>
                                    </TableWrapper>
                                </Cards>
                            </Col>
                        </Row>
                    )
                }
            </Main>
            <Modal
                title="Add Student"
                open={isModalVisible}
                onCancel={handleModalCancel}
                maskClosable={false}
                centered
                footer={[
                    <Button id="add-student-button" key="AddStudentRecord" type="primary" onClick={handleModalAdd}>
                        Add
                    </Button>,
                ]}
            >
                <Form id="add-student-form" form={form} layout="vertical">

                    <Form.Item label="College" name='collegeId' rules={[{ required: true, message: 'Please select college' }]}>
                        <Select placeholder='Select college' allowClear>
                            {collegeData.map((item) => (
                                <Option key={item.code} value={item.id}>
                                    {item.code}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter student name" }]}>
                        <Input id="add-student-enter-student-name" placeholder="Enter name" />
                    </Form.Item>
                    <Form.Item name="username" label="User Name" rules={[{ required: true, validator: validateUsername }]}>
                        <Input id="add-student-enter-student-username" placeholder="Enter username" />
                    </Form.Item>
                    <Form.Item name="cohortId" label="Batch" rules={[{ required: true, message: "Please select a batch" }]}>
                        <Select placeholder='Select batch' allowClear mode='multiple'>
                            {cohortsData.map((item) => (
                                <Option key={item.name} value={item.id}>
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="password" label="Password" rules={[{ required: true, validator: validatePassword }]}>
                        <Input.Password id="add-student-enter-password" placeholder="Enter password" />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Add Students in Bulk"
                open={isBulkModalVisible}
                onCancel={() => { setIsBulkModalVisible(false); bulkForm.resetFields(); }}
                maskClosable={false}
                centered
                footer={[
                    <Button id="add/update-bulk-students-preview-button" key="AddBulkStudentsRecords" type="primary" onClick={handleBulkFormPreview}>
                        Preview
                    </Button>,
                ]}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ fontSize: '17px', color: 'black', marginRight: '5px', marginBottom: '0' }}>Sample CSV File:</p>
                    <span className="download-text" style={{ marginLeft: '10px', marginRight: '5px', marginBottom: '0', fontSize: '17px' }}>sample.csv</span>
                    <DownloadOutlined id="students-bulk-add/update-file-upload"
                        style={{ verticalAlign: 'middle' }}
                        className="download-icon"
                        onClick={() => {
                            const csvContent = Papa.unparse(sampleCSVData);
                            const blob = new Blob([csvContent], { type: "text/csv" });
                            const link = document.createElement("a");
                            link.href = URL.createObjectURL(blob);
                            link.download = "StudentsBulkSample.csv";
                            link.click();
                        }}
                    />
                </div>
                <Form id="students-bulk-add-form" form={bulkForm} layout="vertical" style={{ marginTop: '15px' }}>
                    <Form.Item label='College' name='collegeId' rules={[{ required: true, message: 'Please select college' }]}>
                        <Select placeholder='Select college' allowClear>
                            {collegeData.map((item) => (
                                <Option key={item.code} value={item.id}>
                                    {item.code}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label='Batch' name='cohortId' rules={[{ required: true, message: 'Please select batch' }]}>
                        <Select placeholder='Select batch' allowClear mode='multiple'>
                            {cohortsData.map((item) => (
                                <Option key={item.name} value={item.id}>
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="file" rules={[{ required: true, message: 'Please upload csv file' }]} label="File">
                        <ImportStyleWrap>
                            <Dragger id="students-add/update-file-upload"
                                maxCount={1}
                                beforeUpload={uploadedFile => {
                                    if (uploadedFile !== null) {
                                        setCSVFile(uploadedFile);
                                        return false;
                                    }
                                }}
                                onChange={handleCSVFileChange}
                                onRemove={handleRemoveCSVFile}
                                fileList={csvFileList}
                                accept=".csv"
                            >
                                <p className="ant-upload-drag-icon">
                                    <UilExport />
                                </p>
                                <Heading as="h4" className="ant-upload-text">
                                    Drop or Upload .csv File
                                </Heading>
                            </Dragger>
                        </ImportStyleWrap>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Preview Students"
                open={isNewModalVisible}
                onCancel={() => setIsNewModalVisible(false)}
                maskClosable={false}
                centered
                width={1400}
                footer={[
                    <Button id="students-preview-cancel-button" key="cancelpreview" type="light" onClick={() => setIsNewModalVisible(false)}>
                        Cancel
                    </Button>,
                    <Button id="students-preview-add/update/add_new_update_existing-button" key="addpreviewstudents" type="primary" onClick={handleBulkDispatch}>
                        Submit
                    </Button>
                ]}
            >
                <Table columns={csvcolumns} dataSource={parsedCSVData} />
            </Modal>
            <Modal
                title={`Edit Student ${editRecord?.username}`}
                open={isEditModalVisible}
                onCancel={() => setIsEditModalVisible(false)}
                maskClosable={false}
                centered
                footer={[
                    <Button id="edit-student-button" key="UpdateStudents" type="primary" onClick={handleModalUpdate}>
                        Update
                    </Button>
                ]}
            >
                <Form id="edit-student-form" form={editForm} layout="vertical">
                    <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter student name" }]}>
                        <Input id="edit-student-enter-student-name" placeholder="Enter student name" />
                    </Form.Item>
                    <Form.Item name='cohortId' label='Batch' rules={[{ required: true, message: 'Please select batch' }]}>
                        <Select placeholder='Select batch' allowClear mode='multiple'>
                            {cohortsData.map((item) => (
                                <Option key={item.name} value={item.id} >
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name='status' label='Status' rules={[{ required: true, message: 'Please select status' }]}>
                        <Select placeholder='Select status' allowClear>
                            <Option key={'ACTIVE'} value={'ACTIVE'}>
                                ACTIVE
                            </Option>
                            <Option key={'INACTIVE'} value={'INACTIVE'}>
                                INACTIVE
                            </Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default Students;