import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { getItem } from '../../utility/localStorageControl';
import { logOut } from '../authentication/actionCreator';

const {
  collegeAddBegin,
  collegeAddSuccess,
  collegeAddErr,

  collegeReadBegin,
  collegeReadSuccess,
  collegeReadErr,

  collegeUpdateBegin,
  collegeUpdateSuccess,
  collegeUpdateErr,
} = actions;

const collegeDataSubmit = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(collegeAddBegin());
      const response = await DataService.post('/college/create', data);
      const token = getItem('access_token');
      if (token && response.data.Error === false) {
        await dispatch(collegeAddSuccess(response.data));      
        message.success(response.data.message);
      } else {
        if ( response.data.Error === true && response.data.statusCode === 401){
          message.error(response.data.message);
          dispatch(logOut());
        }
        throw response.data.message;       
      }
    } catch (err) {
      await dispatch(collegeAddErr(err));
      message.error(err,2);    }
  };
};

const collegeDataRead = () => {
  return async (dispatch) => {
    try {
      await dispatch(collegeReadBegin());
      const query = await DataService.get('/college');
      const token = getItem('access_token');
      if (token && query.data.Error === false) {
        await dispatch(collegeReadSuccess(query.data.payload));
      } else {
        if ( query.data.Error === true && query.data.statusCode === 401){
          message.error(query.data.message);
          dispatch(logOut());
        }
        throw query.data.message;
      }
    } catch (err) {
      await dispatch(collegeReadErr(err));
    }
  };
};

const collegeDataUpdate = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(collegeUpdateBegin());
      const response = await DataService.put(`/college/update`, data);
      const token = getItem('access_token');
      if (token && response.data.Error === false) {
        await dispatch(collegeUpdateSuccess(response.data));
        message.success(response.data.message);
      } else {
        if ( response.data.Error === true && response.data.statusCode === 401){
          message.error(response.data.message);
          dispatch(logOut());
        }
        throw response.data.message;
      }
    } catch (err) {
      await dispatch(collegeUpdateErr(err));
      message.error(err,2);
    }
  };
};

export {
  collegeDataRead,
  collegeDataSubmit,
  collegeDataUpdate,
};
