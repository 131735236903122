import { readQAFBegin, readQAFSuccess, readQAFError } from './actions';
import { DataService } from '../../config/dataService/dataService';
import { getItem } from '../../utility/localStorageControl';


export const QAFDataRead = () => {
    return async (dispatch) => {
        try {
            const userId = getItem('userId')
            await dispatch(readQAFBegin());
            const response = await DataService.get(`teachermaster/qnasfeedback/${userId}`);
            const token = getItem('access_token');
            const data = response.data;
            if (token && data !== null && data.Error === false) {
                await dispatch(readQAFSuccess(data.payload));
            } else {
                await dispatch(readQAFSuccess([]));
            }
        } catch (error) {
            await dispatch(readQAFError(error));
        }
    };
};
