import React from 'react'
import { TableWrapper, Main } from '../styled';
import { Col, Row, Table } from 'antd';
import { ProjectList } from '../project/style';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PageHeader } from '../../components/page-headers/page-headers';

export default function Sync() {

    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
        },
        {
            title: '..',
            dataIndex: 'syncbutton',
            key: 'syncbutton',
        },
    ]

    const dataSource = []

    return (
        <div>
            <Main>
                <PageHeader title={'Sync'} />
                <Row gutter={25}>
                    <Col sm={24} xs={24}>
                        <Cards headless>
                            <TableWrapper>
                                <ProjectList>
                                    <div className="table-responsive">
                                        <Table dataSource={dataSource} columns={columns} />
                                    </div>
                                </ProjectList>
                            </TableWrapper>
                        </Cards>
                    </Col>
                </Row>
            </Main>
        </div>
    )
}
