import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Col, Modal, Row, Select, Checkbox, Result } from 'antd';
import { SelectWrapperStyle } from '../ui-elements/ui-elements-styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Button } from '../../components/buttons/buttons';
import { NoteCardWrap } from '../note/style';
import Heading from '../../components/heading/heading';
import { useDispatch, useSelector } from 'react-redux';
import { getTopicById, FreezeTopicById } from '../../redux/topics/actionCreator';
import { generateQuizDataSubmit } from '../../redux/generateQuiz/actionCreator';
import { alertModal } from '../../components/modals/antd-modals';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';

import Quiz_Actions from '../../redux/generateQuiz/actions';
import TopicStatusId from './TopicStatusId';

const { generateQuizPostSuccess } = Quiz_Actions

const { Option } = Select;

function GenerateQuiz({ topicStatus, topicName, topic }) {

  const { subjectId, unitId, topicId } = useParams()

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { GenerateQuizRes } = useSelector((state) => {
    return {
      GenerateQuizRes: state.GenerateQuizRes.data,
    };
  });

  useEffect(() => {
    if (GenerateQuizRes !== null && GenerateQuizRes.Error === false && topicStatus === "QUESTIONS REVIEWED") {
      // const datatoPost = {
      //   id: parseInt(topicId),
      //   status: "QUIZ GENERATED"
      // }
      // dispatch(FreezeTopicById(datatoPost))
      dispatch(getTopicById(parseInt(topicId)));
    }
    dispatch(generateQuizPostSuccess(null))
  }, [GenerateQuizRes]);


  const [modalVisible, setModalVisible] = useState(false);

  const [allChecked, setAllChecked] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState({
    easy: false,
    medium: false,
    hard: false,
  });
  const [dropdownValues, setDropdownValues] = useState({
    all: 0,
    easy: 0,
    medium: 0,
    hard: 0,
  });


  const handleDropdownChange = (value, index) => {
    const dropdownKey = ['easy', 'medium', 'hard'][index];
    setDropdownValues({
      ...dropdownValues,
      [dropdownKey]: Number(value),
      all: 0
    });

  };

  const handleAllDropdownChange = (value) => {
    if (allChecked) {
      setDropdownValues({
        ...dropdownValues,
        easy: Number(value),
        medium: Number(value),
        hard: Number(value),
        all: Number(value)
      });
    }
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));

    if (checked) {
      const allCheckboxes = Object.values(checkboxValues);
      if (allCheckboxes.every((value) => value)) {
        setAllChecked(true);
      }
    } else {
      setDropdownValues({
        ...dropdownValues,
        [name]: 0
      })
      setAllChecked(false);
    }
  };

  const handleAllCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setAllChecked(isChecked);
    setCheckboxValues({
      easy: isChecked,
      medium: isChecked,
      hard: isChecked,
    });
    setDropdownValues({
      ...dropdownValues,
      easy: 0,
      medium: 0,
      hard: 0,
      all: 0
    });
  };

  const isAllIndeterminate =
    checkboxValues.easy !== checkboxValues.medium ||
    checkboxValues.medium !== checkboxValues.hard;

  const areAllChecked =
    checkboxValues.easy && checkboxValues.medium && checkboxValues.hard;

  function handleGenerate() {
    setModalVisible(true);
  }

  function handleModalConfirm() {
    const data = {
      id: parseInt(topicId),
      types: ["EASY", "MEDIUM", "HARD"],
      qno: [dropdownValues.easy, dropdownValues.medium, dropdownValues.hard]
    }
    if (topicStatus === "QUESTIONS REVIEWED") {
      dispatch(generateQuizDataSubmit(data))
      setModalVisible(false);
      // dispatch(getTopicById(parseInt(topicId)));
    }
  }

  function handleModalCancel() {
    setModalVisible(false);
  }

  return (
    <>
      <NoteCardWrap>
        {
          TopicStatusId(topicStatus) == 8 ?
            <Result
              status="success"
              title="Content Freezed"
              subTitle ={`on ${topic !== null && new Date(topic.updatedon).toLocaleString()}`}
            />
            :
            TopicStatusId(topicStatus) == 0 ?
              <Result
                status="warning"
                title="Content not found"
                extra={
                  <Button id="generateMCQ_uploadcontent-button" type="primary" key="navigateToReviewContent" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/upload`)}>
                    Upload
                  </Button>
                }
              />
              :
              TopicStatusId(topicStatus) == 1 ?
                <Result
                  status="warning"
                  title="Review Content"
                  extra={
                    <Button id="generateMCQ_reviewcontent-button" type="primary" key="navigateToReviewContent" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/review/content`)}>
                      Review
                    </Button>
                  }
                />
                :
                TopicStatusId(topicStatus) == 2 ?
                  <Result
                    status="warning"
                    title="Content reviewed successfully"
                    extra={
                      <Button id="generateMCQ_generateqa-button" type="primary" key="upload" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/generate/questions`)}>
                        Generate Q & A
                      </Button>
                    }
                  />
                  :
                  TopicStatusId(topicStatus) == 3 ?
                    <Result
                      status="warning"
                      title="Questions and Answers generated"
                      extra={
                        <Button id="generateMCQ_reviewqa-button" type="primary" key="upload" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/review/questions`)}>
                          Review Q & A
                        </Button>
                      }
                    />
                    :
                    TopicStatusId(topicStatus) === 4 ?
                      <div>
                        <Heading as="h6" className='mb-20'>Select quiz type and number</Heading>
                        <Row gutter={25} >
                          <Col lg={6} sm={12} xs={12}>
                            <Checkbox id="generateMCQ_easy-checkbox" name="easy" checked={checkboxValues.easy} onChange={handleCheckboxChange} >
                              Easy
                            </Checkbox>
                          </Col>
                          <Col lg={8} sm={12} xs={12}>
                            <SelectWrapperStyle>
                              <Select id="generateMCQ_easy-count" value={dropdownValues.easy} style={{ width: 120 }} onChange={(value) => handleDropdownChange(value, 0)} disabled={!checkboxValues.easy}>
                                <Option value="0">0</Option>
                                <Option value="5">5</Option>
                                <Option value="6">6</Option>
                                <Option value="7">7</Option>
                                <Option value="8">8</Option>
                                <Option value="9">9</Option>
                                <Option value="10">10</Option>
                                <Option value="11">11</Option>
                                <Option value="12">12</Option>
                                <Option value="13">13</Option>
                                <Option value="14">14</Option>
                                <Option value="15">15</Option>
                                <Option value="16">16</Option>
                                <Option value="17">17</Option>
                                <Option value="18">18</Option>
                                <Option value="19">19</Option>
                                <Option value="20">20</Option>
                              </Select>
                            </SelectWrapperStyle>
                          </Col>
                        </Row>
                        {/* <Row gutter={25} style={{ marginTop: '15px' }}>
                    <Col lg={6} sm={12} xs={12}>
                      <Checkbox name="medium" checked={checkboxValues.medium} onChange={handleCheckboxChange} >
                        Medium
                      </Checkbox>
                    </Col>
                    <Col lg={8} sm={12} xs={12}>
                      <SelectWrapperStyle>
                        <Select value={dropdownValues.medium} style={{ width: 120 }} onChange={(value) => handleDropdownChange(value, 1)} disabled={!checkboxValues.medium}>
                          <Option value="0">0</Option>
                          <Option value="1">1</Option>
                          <Option value="2">2</Option>
                          <Option value="3">3</Option>
                          <Option value="4">4</Option>
                          <Option value="5">5</Option>
                        </Select>
                      </SelectWrapperStyle>
                    </Col>
                  </Row> */}
                        {/* <Row gutter={25} style={{ marginTop: '15px' }}>
                    <Col lg={6} sm={12} xs={12}>
                      <Checkbox name="hard" checked={checkboxValues.hard} onChange={handleCheckboxChange}>
                        Hard
                      </Checkbox>
                    </Col>
                    <Col lg={8} sm={12} xs={12}>
                      <SelectWrapperStyle>
                        <Select value={dropdownValues.hard} style={{ width: 120 }} onChange={(value) => handleDropdownChange(value, 2)} disabled={!checkboxValues.hard}>
                          <Option value="0">0</Option>
                          <Option value="1">1</Option>
                          <Option value="2">2</Option>
                          <Option value="3">3</Option>
                          <Option value="4">4</Option>
                          <Option value="5">5</Option>
                        </Select>
                      </SelectWrapperStyle>
                    </Col>
                  </Row> */}
                        {/* <Row gutter={25} style={{ marginTop: '15px' }}>
                    <Col lg={6} sm={12} xs={12}>
                      <Checkbox checked={areAllChecked} indeterminate={isAllIndeterminate} onChange={handleAllCheckboxChange}>
                        All
                      </Checkbox>
                    </Col>
                    <Col lg={8} sm={12} xs={12}>
                      <SelectWrapperStyle>
                        <Select value={dropdownValues.all} style={{ width: 120 }} onChange={(value) => handleAllDropdownChange(value)} disabled={!areAllChecked}>
                          <Option value="0">0</Option>
                          <Option value="1">1</Option>
                          <Option value="2">2</Option>
                          <Option value="3">3</Option>
                          <Option value="4">4</Option>
                          <Option value="5">5</Option>
                        </Select>
                      </SelectWrapperStyle>
                    </Col>
                  </Row> */}
                        <div className='text-right mb-20 mt-20'>
                          <Button id="generateMCQ_cancel-button" htmlType="submit" type="white" outlined style={{ color: 'black', marginRight: '10px' }} onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/review/questions`)} >
                            Cancel
                          </Button>
                          <Button id="generateMCQ_generate-button" htmlType="submit" type="primary" onClick={handleGenerate} disabled={dropdownValues.easy || dropdownValues.medium || dropdownValues.hard ? false : true} >
                            Generate
                          </Button>
                        </div>
                      </div>
                      :
                      (TopicStatusId(topicStatus) == 5 || TopicStatusId(topicStatus) == 6) ?
                        <Result
                          status="warning"
                          title="MCQS has been generated for this topic"
                          extra={
                            <Button id="generateMCQ_reviewmcqs-button" type="primary" key="navigateToReviewContent" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/review/quiz`)}>
                              Review MCQS
                            </Button>
                          }
                        />
                        :
                        TopicStatusId(topicStatus) === 7 &&
                        <div>
                          <Result
                            status='success'
                            title="Programs Verified"
                            extra={
                              <Button id="generateMCQ_freezetopic-button" type="primary" key="freeze topic" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/freezeschedule`)}>
                                Freeze Topic
                              </Button>
                            }
                          />
                        </div>
        }
      </NoteCardWrap>
      <Modal
        open={modalVisible}
        title="Generate MCQs"
        onCancel={handleModalCancel}
        onOk={handleModalConfirm}
      // confirmLoading={isGenerating}

      >
        <p>You have chosen to generate quiz for:<br /> {`${topicName}`}</p>
        <p>This action may take several minutes to complete. Please do not refresh this page while generation is in the process. Proceed?</p>
      </Modal>
    </>
  );
}

export default GenerateQuiz;
