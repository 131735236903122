import { DataService } from '../../config/dataService/dataService';

import {
    readContentBegin,
    readContentSuccess,
    readContentError,

    getFBQuestionsBegin,
    getFBQuestionsSuccess,
    getFBQuestionsError,

    verifyFeedbackBegin,
    verifyFeedbackSuccess,
    verifyFeedbackError,

    postFeedbackBegin,
    postFeedbackSuccess,
    postFeedbackError


} from './actions';
import { logOut } from '../authentication/actionCreator';
import { message } from 'antd';
import { getItem } from '../../utility/localStorageControl';

const UserFeedbackDataRead = () => {
    return async (dispatch) => {
        try {
            const userId = getItem('userId')
            await dispatch(readContentBegin());
            const response = await DataService.get(`teachermaster/usercontentfeedback/${userId}`);
            const token = getItem('access_token');
            const data = response.data;
            if (token && data !== null && data.Error === false) {
                await dispatch(readContentSuccess(data.payload));
            } else {
                await dispatch(readContentSuccess([]));
            }
        } catch (error) {
            await dispatch(readContentError(error));
        }
    };
};

const getFeedbackQuestions = () => {
    return async (dispatch) => {
        try {
            await dispatch(getFBQuestionsBegin());
            const response = await DataService.get(`feedbackquestion/contentquestions`);
            const token = getItem('access_token');
            const data = response.data;
            if (token && data !== null && data.Error === false) {
                await dispatch(getFBQuestionsSuccess(data.payload));
            }
            else if (data !== null && data.Error === true) {
                if (Object.keys(data).length >= 3 && data.statusCode == 401) {
                    message.error(data.message)
                    await dispatch(getFBQuestionsSuccess([]));
                    dispatch(logOut())
                }
                else {
                    await dispatch(getFBQuestionsSuccess([]));
                }
            }
            else {
                await dispatch(getFBQuestionsSuccess([]));
            }
        } catch (error) {
            await dispatch(getFBQuestionsError(error));
        }
    };
};

// student
const postFeedback = (postdata) => {
    return async (dispatch) => {
        try {
            await dispatch(postFeedbackBegin());
            const response = await DataService.post(`usercontentfeedback/create`, postdata);
            const token = getItem('access_token');
            const data = response.data;
            if (token && data !== null && data.Error === false) {
                await dispatch(postFeedbackSuccess(data));
            }
            else if (data !== null && data.Error === true) {
                if (Object.keys(data).length >= 3 && data.statusCode == 401) {
                    message.error(data.message)
                    await dispatch(postFeedbackSuccess(null));
                    dispatch(logOut())
                }
                else {
                    await dispatch(postFeedbackSuccess(null));
                }
            }
            else {
                await dispatch(postFeedbackSuccess(null));
            }
        } catch (error) {
            await dispatch(postFeedbackError(error));
        }
    };
};

const verifyUserFeedback = (topicId) => {
    return async (dispatch) => {
        try {
            await dispatch(verifyFeedbackBegin());
            const response = await DataService.get(`usercontentfeedback/verify/${topicId}`);
            const token = getItem('access_token');
            const data = response.data;
            if (token && data !== null && data.Error === false) {
                await dispatch(verifyFeedbackSuccess(data.payload));
            }
            else if (data !== null && data.Error === true) {
                if (Object.keys(data).length >= 3 && data.statusCode == 401) {
                    message.error(data.message)
                    await dispatch(verifyFeedbackSuccess(null));
                    dispatch(logOut())
                }
                else {
                    await dispatch(verifyFeedbackSuccess(null));
                }
            }
            else {
                await dispatch(verifyFeedbackSuccess(null));
            }
        } catch (error) {
            await dispatch(verifyFeedbackError(error));
        }
    };
};


export { UserFeedbackDataRead, getFeedbackQuestions, verifyUserFeedback, postFeedback }