import { notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { getItem } from '../../utility/localStorageControl';

const addNotificationSuccess = () => {
  notification.success({
    message: 'Your Record has been Submited',
  });
};

const addNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const deleteNotificationSuccess = () => {
  notification.success({
    message: 'Your Record has been Deleted',
  });
};

const deleteNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const updateNotificationSuccess = () => {
  notification.success({
    message: 'Your Record has been updated',
  });
};

const updateNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const {
  yearsemesterAddBegin,
  yearsemesterAddSuccess,
  yearsemesterAddErr,

  yearsemesterReadBegin,
  yearsemesterReadSuccess,
  yearsemesterReadErr,

  yearsemesterUpdateBegin,
  yearsemesterUpdateSuccess,
  yearsemesterUpdateErr,

  yearsemesterDeleteBegin,
  yearsemesterDeleteSuccess,
  yearsemesterDeleteErr,

  yearsemesterSingleDataBegin,
  yearsemesterSingleDataSuccess,
  yearsemesterSingleDataErr,

  yearsemesterUploadBegin,
  yearsemesterUploadSuccess,
  yearsemesterUploadErr,
} = actions;

const yearsemesterDataSubmit = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(yearsemesterAddBegin());
      const response = await DataService.post('/yearsemester/create', data);
      const token = getItem('access_token');
      await dispatch(yearsemesterAddSuccess(response.data.data));
      if (token) {
        addNotificationSuccess();
      } else {
        await dispatch(yearsemesterAddErr('Unauthorized access'));
        addNotificationError('Unauthorized access');
      }
    } catch (err) {
      await dispatch(yearsemesterAddErr(err));
      addNotificationError(err);
    }
  };
};

const yearsemesterDataRead = () => {
  return async (dispatch) => {
    try {
      await dispatch(yearsemesterReadBegin());
      const query = await DataService.get('/yearsemester');
      const token = getItem('access_token');
      await dispatch(yearsemesterReadSuccess(query.data));      
      if (token) {
      } else {
        await dispatch(yearsemesterReadSuccess([]));
        // await dispatch(yearsemesterAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(yearsemesterReadErr(err));
    }
  };
};

const yearsemesterDataSearch = (searchItem) => {
  return async (dispatch) => {
    try {
      await dispatch(yearsemesterReadBegin());
      if (searchItem !== '') {
        const query = await DataService.get(`/data/search/${searchItem}`);
        await dispatch(yearsemesterReadSuccess(query.data.data));
      } else {
        try {
          const query = await DataService.get('/data/all');
          await dispatch(yearsemesterReadSuccess(query.data.data));
        } catch (err) {
          await dispatch(yearsemesterReadErr(err));
        }
      }
    } catch (err) {
      await dispatch(yearsemesterReadErr(err));
    }
  };
};

const yearsemesterDataUpdate = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(yearsemesterUpdateBegin());
      await DataService.put(`/yearsemester/update`, data);
      await dispatch(yearsemesterUpdateSuccess());
      updateNotificationSuccess();
    } catch (err) {
      await dispatch(yearsemesterUpdateErr(err));
      updateNotificationError(err);
    }
  };
};

const yearsemesterDataDelete = ({ id, getData }) => {
  return async (dispatch) => {
    try {
      await dispatch(yearsemesterDeleteBegin());
      const data = await DataService.post(`/${id}/delete`, {});

      await dispatch(yearsemesterDeleteSuccess(data.data));
      await getData();
      deleteNotificationSuccess();
    } catch (err) {
      await dispatch(yearsemesterDeleteErr(err));
      deleteNotificationError(err);
    }
  };
};

const yearsemesterDataSingle = (id) => {
  return async (dispatch) => {
    try {
      await dispatch(yearsemesterSingleDataBegin());
      const query = await DataService.get(`/data/${id}/show`);
      await dispatch(yearsemesterSingleDataSuccess(query.data.data[0]));
    } catch (err) {
      await dispatch(yearsemesterSingleDataErr(err));
    }
  };
};

const yearsemesterFileUploder = (imageAsFile) => {
  const data = new FormData();
  data.append('image', imageAsFile);

  return async (dispatch) => {
    try {
      await dispatch(yearsemesterUploadBegin());
      const query = await DataService.post('/data/image/upload', data, { 'Content-Type': 'multipart/form-data' });

      dispatch(yearsemesterUploadSuccess(`${query.data}`));
    } catch (err) {
      await dispatch(yearsemesterUploadErr(err));
    }
  };
};

const yearsemesterFileClear = () => {
  return async (dispatch) => {
    try {
      await dispatch(yearsemesterUploadBegin());
      dispatch(yearsemesterUploadSuccess(null));
    } catch (err) {
      await dispatch(yearsemesterUploadErr(err));
    }
  };
};

export {
  yearsemesterDataRead,
  yearsemesterDataSearch,
  yearsemesterDataSubmit,
  yearsemesterFileUploder,
  yearsemesterDataDelete,
  yearsemesterDataSingle,
  yearsemesterDataUpdate,
  yearsemesterFileClear,
};
