import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { NavLink } from 'react-router-dom';
import { ErrorWrapper } from './style';
import { Main } from '../styled';
import Heading from '../../components/heading/heading';
import { Button } from '../../components/buttons/buttons';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import {getItem} from '../../utility/localStorageControl'

function NotFound() {
  const [state, setState] = useState({
    isLoading: true,
  });
  useEffect(() => {
    setTimeout(() => {
      setState({ isLoading: false });
    }, 1500);
  }, []);

  const role = getItem('role')

  return (
    <Main>
      {state.isLoading ? (
        <div className="custom-spin">
          <CustomSpinner />
        </div>
      ) : (
        <ErrorWrapper>
          <img src={require(`../../static/img/pages/404.svg`).default} alt="404" />
          <Heading className="error-text" as="h3">
            404
          </Heading>
          <p>Sorry! the page you are looking for does not exist.</p>
          {
            role === "ADMIN" &&
            <NavLink to="/admin">
              <Button size="default" type="primary" to="/admin">
                Return Home
              </Button>
            </NavLink>
          }
          {
            role === "TEACHER" &&
            <NavLink to="/teacher">
              <Button size="default" type="primary" to="/teacher">
                Return Home
              </Button>
            </NavLink>
          }
          {
            role === "STUDENT" &&
            <NavLink to="/student">
              <Button size="default" type="primary" to="/student">
                Return Home
              </Button>
            </NavLink>
          }
        </ErrorWrapper>
      )}
    </Main>
  );
}

export default NotFound;
