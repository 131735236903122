import react, { useEffect } from 'react'
import { Steps, Step } from '../../components/steps/steps';
import { Button } from '../../components/buttons/buttons';
import { FreezeTopicById } from '../../redux/topics/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Result } from 'antd';
import TopicSchedule from './TopicSchedule'
import { topicsDataRead } from '../../redux/teacherTopicsMenu/actionCreator';

import actions from '../../redux/topics/actions';

const { topicStatusUpdateSuccess } =actions

function FreezeSchedule({ topicStatus }) {

    const { subjectId, unitId, topicId } = useParams()

    const dispatch = useDispatch()

    const { TopicStatus } = useSelector((state) => {
        return {
            TopicStatus: state.TopicStatus.data,
        };
    });

    useEffect(() => {
        if (TopicStatus !== null && TopicStatus.message === "updated") {
            dispatch(topicsDataRead({ subjectId, unitId }));
            dispatch(topicStatusUpdateSuccess(null))
        }
    }, [TopicStatus])



    const statusArray = [
        'NOTHING',
        'CONTENT UPLOADED',
        'CONTENT REVIEWED',
        'QUESTIONS GENERATED',
        'QUESTIONS REVIEWED',
        'QUIZ GENERATED',
        'QUIZ REVIEWED',
        'PROGRAMS VERIFY',
        'CONTENT FREEZED'
    ]

    const getStatusIndex = (topicStatus) => {
        const index = statusArray.indexOf(topicStatus)
        return index
    }

    const FreezeTopic = () => {
        const datatoPost = {
            id: parseInt(topicId),
            status: "CONTENT FREEZED"
        }
        dispatch(FreezeTopicById(datatoPost))
    }

    return (
        <div>
            <div className=''>
                <Steps current={5} size="small" >
                    <Step title="Content Review" status={`${getStatusIndex(topicStatus) == 1 ? 'process' : getStatusIndex(topicStatus) >= 2 ? 'finish' : 'wait'}`} />
                    <Step title="Q & A Review" status={`${getStatusIndex(topicStatus) == 3 ? 'process' : getStatusIndex(topicStatus) >= 4 ? 'finish' : 'wait'}`} />
                    <Step title="MCQS Review" status={`${getStatusIndex(topicStatus) == 5 ? 'process' : getStatusIndex(topicStatus) >= 6 ? 'finish' : 'wait'}`} />
                    <Step title="Verify Programs" status={`${getStatusIndex(topicStatus) == 6 ? 'process' : getStatusIndex(topicStatus) >= 7 ? 'finish' : 'wait'}`} />
                    <Step title="Freeze" status={`${getStatusIndex(topicStatus) == 7 ? 'process' : getStatusIndex(topicStatus) >= 8 ? 'finish' : 'wait'}`} />
                </Steps>
            </div>
            <div>
                {
                    getStatusIndex(topicStatus) == 7 &&
                    <div className='spin mt-25'>
                        <Result
                            status="warning"
                            title="Once the topic is freezed, you cannot unfreeze the topic anymore"
                            extra={
                                <Button id="freezeschedule_freezetopic-button" type="primary" onClick={() => FreezeTopic()}>
                                    Freeze
                                </Button>
                            }
                        />
                        {/* <div className='text-center'>
                            <p className='fontsize-16'>Once the topic is freezed, you cannot unfreeze</p>
                            <Button type="primary" onClick={() => FreezeTopic()}>
                                Freeze
                            </Button>
                        </div> */}
                    </div>
                }
                {
                    getStatusIndex(topicStatus) == 8 &&
                    <div className='' style={{ marginTop: '50px' }}>
                        <TopicSchedule />
                    </div>
                }
            </div>
        </div>
    );
}

export default FreezeSchedule