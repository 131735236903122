import actions from './actions';

const {
  EVALUATE_WITH_AUDIO_BEGIN,
  EVALUATE_WITH_AUDIO_SUCCESS,
  EVALUATE_WITH_AUDIO_ERR,

  EVALUATE_WITHOUT_AUDIO_BEGIN,
  EVALUATE_WITHOUT_AUDIO_SUCCESS,
  EVALUATE_WITHOUT_AUDIO_ERR,

  REEVALUATE_SINGLE_BEGIN,
  REEVALUATE_SINGLE_SUCCESS,
  REEVALUATE_SINGLE_ERR,

} = actions;

const evaluateWithAudioInitialState = {
  data: null,
  loading: false,
  error: null,
};

const evaluateWithoutAudioInitialState = {
  data: null,
  loading: false,
  error: null,
};

const ReEvaluateSingleInitialState = {
  data: null,
  loading: false,
  error: null,
};

const EvalauateWithAudioReducer = (state = evaluateWithAudioInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case EVALUATE_WITH_AUDIO_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case EVALUATE_WITH_AUDIO_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case EVALUATE_WITH_AUDIO_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const EvalauateWithoutAudioReducer = (state = evaluateWithoutAudioInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case EVALUATE_WITHOUT_AUDIO_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case EVALUATE_WITHOUT_AUDIO_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case EVALUATE_WITHOUT_AUDIO_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const ReEvaluateSingleReducer = (state = ReEvaluateSingleInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case REEVALUATE_SINGLE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case REEVALUATE_SINGLE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case REEVALUATE_SINGLE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};


export { EvalauateWithAudioReducer, EvalauateWithoutAudioReducer, ReEvaluateSingleReducer };
