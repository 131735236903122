import React, { Suspense, useEffect, useState } from 'react';
import { Col, Row, Skeleton, Table } from 'antd';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Main } from '../styled';
import { useDispatch, useSelector } from 'react-redux';
import { GetActiveStudents, GetInactiveStudents, GetOverallPerformanceByCohort, GetSubjectsBestPerformer } from '../../redux/studentPerformance/actionCreator';
import SubjectsBestPerformer from './DashboardOverview/SubjectsBestPerformer';
import InactiveStudents from './DashboardOverview/InactiveStudents';
import ActiveStudents from './DashboardOverview/ActiveStudents';
import OverallPerformanceCohortWise from './DashboardOverview/OverallPerformanceCohortWise';

function Dashboard() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetSubjectsBestPerformer());
    dispatch(GetInactiveStudents());
    dispatch(GetActiveStudents());
    dispatch(GetOverallPerformanceByCohort());
  }, [dispatch])

  const { subjectsBestPerformer, subjectsBestPerformerLoading, inactiveStudents, inactiveStudentsLoading, activeStudents, activeStudentsLoading, overallPerformanceByCohort, overallPerformanceByCohortLoading } = useSelector((state) => {
    return {
      subjectsBestPerformer: state.subjectsBestPerformer.data,
      subjectsBestPerformerLoading: state.subjectsBestPerformer.loading,
      activeStudents: state.activeStudents.data,
      activeStudentsLoading: state.activeStudents.loading,
      inactiveStudents: state.inactiveStudents.data,
      inactiveStudentsLoading: state.inactiveStudents.loading,
      overallPerformanceByCohort: state.overallPerformanceByCohort.data,
      overallPerformanceByCohortLoading: state.overallPerformanceByCohort.loading,
    };
  });

  return (
    <>
      <Main>
        <PageHeader className="ninjadash-page-header-main" title="Dashboard" />
        <Row gutter={25} justify="center">
          <Col xl={12} lg={12} md={24} xs={24}>
            {
              overallPerformanceByCohortLoading ?
                <Cards headless>
                  <Skeleton active />
                </Cards>
                :
                <OverallPerformanceCohortWise overallPerformanceByCohort={overallPerformanceByCohort} />
            }
          </Col>
          <Col xl={12} lg={12} md={24} xs={24}>
            {
              subjectsBestPerformerLoading ?
                <Cards headless>
                  <Skeleton active />
                </Cards>
                :
                <SubjectsBestPerformer subjectsBestPerformer={subjectsBestPerformer} />
            }
          </Col>
          <Col xl={12} lg={12} md={24} xs={24}>
            {
              activeStudentsLoading ?
                <Cards headless>
                  <Skeleton active />
                </Cards>
                :
                <ActiveStudents activeStudents={activeStudents} />
            }
          </Col>
          <Col xl={12} lg={12} md={24} xs={24}>
            {
              inactiveStudentsLoading ?
                <Cards headless>
                  <Skeleton active />
                </Cards>
                :
                <InactiveStudents inactiveStudents={inactiveStudents} />
            }
          </Col>
        </Row>
      </Main>
    </>
  );
}
export default Dashboard;