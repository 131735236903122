import actions from './actions';

const {
  SUBMIT_ALL_QUIZ_BEGIN,
  SUBMIT_ALL_QUIZ_SUCCESS,
  SUBMIT_ALL_QUIZ_ERR,

  SAVE_QUIZ_BEGIN,
  SAVE_QUIZ_SUCCESS,
  SAVE_QUIZ_ERR,

  GET_QUIZ_BEGIN,
  GET_QUIZ_SUCCESS,
  GET_QUIZ_ERR,
  
  GET_QUIZ_RESULT_BEGIN,
  GET_QUIZ_RESULT_SUCCESS,
  GET_QUIZ_RESULT_ERR,

} = actions;

const submitInitialState = {
  data: null,    
  loading: false,
  error: null,
};

const saveQuizInitialState = {
  data:null,
  loading:false,
  error:null,
}

const getAllQUIZInitialState = {
  data:null,
  loading:false,
  error:null,
}

const getQUIZResultInitialState = {
  data:null,
  loading:false,
  error:null,
}

const SubmitAllQuizReducer = (state = submitInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    
    case SUBMIT_ALL_QUIZ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case SUBMIT_ALL_QUIZ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case SUBMIT_ALL_QUIZ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const SaveQuizReducer = (state = saveQuizInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SAVE_QUIZ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case SAVE_QUIZ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case SAVE_QUIZ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const ReadAllQuizToAttemptReducer = (state = getAllQUIZInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_QUIZ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_QUIZ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_QUIZ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const GetQuizResultReducer = (state = getQUIZResultInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_QUIZ_RESULT_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_QUIZ_RESULT_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_QUIZ_RESULT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { SubmitAllQuizReducer, SaveQuizReducer, ReadAllQuizToAttemptReducer, GetQuizResultReducer };
