import React, { Suspense, useEffect, useState } from 'react';
import { Card, Col, Form, Input, Row, Spin, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Dragger from 'antd/lib/upload/Dragger';
import UilExport from "@iconscout/react-unicons/icons/uil-export";
import Heading from '../../components/heading/heading';
import { Button } from '../../components/buttons/buttons';
import { ImportStyleWrap } from './styles/styled';
import { FormValidationWrap } from '../forms/overview/Style';
import { NoteCardWrap } from '../note/style';
import { TopicContentSubmit } from '../../redux/uploadContent/actionCreator';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const TextUpload = ({ customWidth }) => {
  const [form] = Form.useForm();

  const navigate = useNavigate()
  const dispatch = useDispatch();

  const { subjectId, unitId, topicId } = useParams()

  const { UploadRes, isUploading } = useSelector((state) => {
    return {
      UploadRes: state.UploadContentRes.data,
      isUploading: state.UploadContentRes.loading,
    };
  });

  const [pdfFile, setPdfFile] = useState(null);
  const [pdfFileList, setPdfFileList] = useState([])

  const handlePdfChange = (uploadedFile) => {
    if (uploadedFile !== null && uploadedFile.fileList.length !== 0 && uploadedFile.fileList[0].type === "application/pdf") {
      setPdfFileList(uploadedFile.fileList);
    }
    else {
      handleRemovePdfFile()
    }
  };

  const handleRemovePdfFile = () => {
    setPdfFile(null);
    setPdfFileList([])
  }

  useEffect(() => {
    if (UploadRes !== null && UploadRes.status === "CONTENT UPLOADED") {
      form.resetFields()
      setPdfFile(null);
      setPdfFileList([])
    }
  }, [UploadRes])

  const handleFormSubmit = (formData) => {
    if (pdfFile !== null && formData.content !== "" && topicId > 0) {
      const data = new FormData();
      // const data = { "videourl": formData.fileVideourl, "content": fileContent, "pdf" : pdfFile }
      data.append("id", parseInt(topicId))
      data.append("videourl", formData.fileVideourl === undefined ? null : formData.fileVideourl)
      data.append("videodescription", formData.videodescription === undefined ? null : formData.videodescription)
      data.append("content", formData.content)
      data.append("pdf", pdfFile)
      // posting data to the server
      dispatch(TopicContentSubmit(data,topicId));
    }
    else {
      message.error('Select file(s) to upload.');
    }
  }

  return (
    <NoteCardWrap style={customWidth}>
      {
        isUploading ?
          <div className="custom-spin">
            <CustomSpinner />
          </div>
          :
          <FormValidationWrap>
            <Form name="sDash_validation-form" form={form} layout="vertical" onFinish={handleFormSubmit}>

              <Form.Item
                name="pdf"
                rules={[{ required: true, message: 'Study Material is required!' }]}
                label="Upload Topic Study Material"
              >
                <ImportStyleWrap>
                  <Dragger
                    maxCount={1}
                    beforeUpload={uploadedFile => {
                      if (uploadedFile !== null) {
                        setPdfFile(uploadedFile);
                        return false;
                      }
                    }}
                    onChange={handlePdfChange}
                    // onDrop={handlePdfChange}
                    onRemove={handleRemovePdfFile}
                    fileList={pdfFileList}
                    accept='.pdf'
                  >
                    <p className="ant-upload-drag-icon">
                      <UilExport />
                    </p>
                    <Heading as="h4" className="ant-upload-text">
                      Click here to select Topic Study Material (.pdf) file
                    </Heading>
                  </Dragger>
                </ImportStyleWrap>
              </Form.Item>

              <Form.Item
                name="content"
                rules={[{ required: true, message: 'Content required!' }]}
                label="Write or Paste Topic Text Content for Q & A"
              >
                <TextArea rows={7} placeholder='Content here...' />
              </Form.Item>

              <Form.Item
                name="videourl"
                rules={[{ required: false, message: 'Video URL required!' }]}
                label="Video URL"
              >
                <Input size="medium" placeholder="Video URL" />
              </Form.Item>

              <Form.Item
                name="videodescription"
                rules={[{ required: false, message: 'Video URL required!' }]}
                label="Video Description"
              >
                <Input size="medium" placeholder="Video Description" />
              </Form.Item>

              <div className='text-right mb-20'>
                <Button htmlType="submit" type="primary" >
                  Upload
                </Button>
              </div>
            </Form>
          </FormValidationWrap>
      }
    </NoteCardWrap>
  )

}

export default TextUpload