const actions = {
  TESSELLATOR_TOPICS_READ_BEGIN: 'TESSELLATOR_TOPICS_READ_BEGIN',
  TESSELLATOR_TOPICS_READ_SUCCESS: 'TESSELLATOR_TOPICS_READ_SUCCESS',
  TESSELLATOR_TOPICS_READ_ERR: 'TESSELLATOR_TOPICS_READ_ERR',

  TESSELLATOR_TOPIC_PROGRAMS_READ_BEGIN: 'TESSELLATOR_TOPIC_PROGRAMS_READ_BEGIN',
  TESSELLATOR_TOPIC_PROGRAMS_READ_SUCCESS: 'TESSELLATOR_TOPIC_PROGRAMS_READ_SUCCESS',
  TESSELLATOR_TOPIC_PROGRAMS_READ_ERR: 'TESSELLATOR_TOPIC_PROGRAMS_READ_ERR',

  MAP_TESSELLATOR_TOPIC_UPDATE_BEGIN: 'MAP_TESSELLATOR_TOPIC_UPDATE_BEGIN',
  MAP_TESSELLATOR_TOPIC_UPDATE_SUCCESS: 'MAP_TESSELLATOR_TOPIC_UPDATE_SUCCESS',
  MAP_TESSELLATOR_TOPIC_UPDATE_ERR: 'MAP_TESSELLATOR_TOPIC_UPDATE_ERR',
  
  MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_BEGIN: 'MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_BEGIN',
  MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_SUCCESS: 'MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_SUCCESS',
  MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_ERR: 'MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_ERR',

  tessellatorTopicsReadBegin: () => {
    return {
      type: actions.TESSELLATOR_TOPICS_READ_BEGIN,
    };
  },

  tessellatorTopicsReadSuccess: (data) => {
    return {
      type: actions.TESSELLATOR_TOPICS_READ_SUCCESS,
      data,
    };
  },

  tessellatorTopicsReadErr: (err) => {
    return {
      type: actions.TESSELLATOR_TOPICS_READ_ERR,
      err,
    };
  },

  tessellatorTopicProgramsReadBegin: () => {
    return {
      type: actions.TESSELLATOR_TOPIC_PROGRAMS_READ_BEGIN,
    };
  },

  tessellatorTopicProgramsReadSuccess: (data) => {
    return {
      type: actions.TESSELLATOR_TOPIC_PROGRAMS_READ_SUCCESS,
      data,
    };
  },

  tessellatorTopicProgramsReadErr: (err) => {
    return {
      type: actions.TESSELLATOR_TOPIC_PROGRAMS_READ_ERR,
      err,
    };
  },

  mapTessellatorTopicUpdateBegin: () => {
    return {
      type: actions.MAP_TESSELLATOR_TOPIC_UPDATE_BEGIN,
    };
  },

  mapTessellatorTopicUpdateSuccess: (data) => {
    return {
      type: actions.MAP_TESSELLATOR_TOPIC_UPDATE_SUCCESS,
      data,
    };
  },

  mapTessellatorTopicUpdateErr: (err) => {
    return {
      type: actions.MAP_TESSELLATOR_TOPIC_UPDATE_ERR,
      err,
    };
  },
  
  mapTessellatorTopicToNullUpdateBegin: () => {
    return {
      type: actions.MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_BEGIN,
    };
  },

  mapTessellatorTopicToNullUpdateSuccess: (data) => {
    return {
      type: actions.MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_SUCCESS,
      data,
    };
  },

  mapTessellatorTopicToNullUpdateErr: (err) => {
    return {
      type: actions.MAP_TESSELLATOR_TOPIC_TO_NULL_UPDATE_ERR,
      err,
    };
  },

}


export default actions;