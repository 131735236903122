import React, { useState } from 'react';
import { PageHeader } from '../../components/page-headers/page-headers';
import StudentsChangePassword from './StudentsChangePassword';
import TeachersChangePassword from './TeachersChangePassword';
import { Tabs } from 'antd';
import { getItem } from '../../utility/localStorageControl';

const { TabPane } = Tabs;

export default function ChangePassword() {

  let clgName = getItem('collegeName');
  let ClgName = '('+clgName+')';
  const loggedadmin = getItem('username');
  const [currentPage, setCurrentPage] = useState('students');

  const handleTabChange = (key) => {
    setCurrentPage(key);
  };

  return (
    <>
      <div>
        <PageHeader className="ninjadash-page-header-main" title={`Reset Password`} />
      </div>
      <div>
        <Tabs
          defaultActiveKey={currentPage}
          onChange={handleTabChange}
          tabBarStyle={{ marginLeft: '35px', marginTop: '10px' }}
        >
          <TabPane id="teachers-reset_password-tab" tab="STUDENTS" key="students" />
          <TabPane id="students-reset_password-tab" tab="TEACHERS" key="teachers" />
        </Tabs>
      </div>
      <div style={{ marginTop: '30px' }}>
        {currentPage === 'teachers' && <TeachersChangePassword />}
        {currentPage === 'students' && <StudentsChangePassword />}
      </div>
    </>
  );
}
