import { DataService } from '../../config/dataService/dataService';
import { getItem } from '../../utility/localStorageControl';
import {
    readRecentDataBegin,
    readRecentDataSuccess,
    readRecentDataError,
    
    reentInterviewsBegin,
    reentInterviewsSuccess,
    reentInterviewsError,

} from './actions';


export const RecentActionsDataRead = () => {
    return async (dispatch) => {
        try {
            const userId = getItem('userId')
            await dispatch(readRecentDataBegin());
            const response = await DataService.get(`userlog/useraction/${userId}`);
            const token = getItem('access_token');
            const data = response.data;
            if (token && data !== null && data.Error === false) {
                await dispatch(readRecentDataSuccess(data.payload));
            } else {
                // await dispatch(readRecentDataSuccess([]));
            }
        } catch (err) {
            await dispatch(readRecentDataError(err));
        }
    };
};

export const GetRecentInterviews = () => {
    return async (dispatch) => {
        try {
            const userId = getItem('roleId')
            await dispatch(reentInterviewsBegin());
            const response = await DataService.get(`interview-master/findbystudent/${userId}`);
            const token = getItem('access_token');
            const data = response.data;
            if (token && data !== null && data.Error === false) {
                await dispatch(reentInterviewsSuccess(data.payload));
            } else if(data !== null && data.Error === true) {
                await dispatch(reentInterviewsSuccess([]));
            }
        } catch (err) {
            await dispatch(reentInterviewsError(err));
        }
    };
};
