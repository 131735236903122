import React, { useEffect, useState } from 'react'
import { Main } from '../styled'
import { PageHeader } from '../../components/page-headers/page-headers'
import { Cards } from '../../components/cards/frame/cards-frame';
import { DataTableStyleWrap } from '../../components/table/Style';
import { NoteCardWrap } from '../note/style';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSchedule, getScheduledData, scheduleTopic, updateScheduleStatus } from '../../redux/schedule/actionCreator';
import scheduleActions from '../../redux/schedule/actions';
import { Button, Col, DatePicker, Form, Input, Modal, Popover, Row, Select, Table, Tooltip, message, Badge } from 'antd';
import { UilSearch, UilTrash, UilTimes, UilCheck } from '@iconscout/react-unicons';
import { useForm } from 'antd/lib/form/Form';
import { FormValidationWrap } from '../forms/overview/Style';
import { topicsOfSubjectDataRead } from '../../redux/teacherSubjectsUnits/actionCreator';
import topicActions from '../../redux/teacherSubjectsUnits/actions';
import moment from 'moment';
import { subjectsDataRead } from '../../redux/subjects/actionCreator';
import { cohortDataRead } from '../../redux/cohorts/actionCreator';
import FontAwesome from 'react-fontawesome';

const { topicsofSubjectReadSuccess } = topicActions
const { scheduleTopicSuccess, deleteScheduleSuccess, updateScheduleSuccess } = scheduleActions

export default function Schedules() {

    const {
        TopicScheduledData,
        isSchedulesLoading,
        Subjects,
        isSubjectsLoading,
        TopicsData,
        isTopicsLoading,
        scheduleTopicRes,
        isTopicScheduling,
        delScheduleRes,
        isScheduleDeleting,
        cohorts,
        isCohortsLoading,
        ScheduleUpdateRes,
        isScheduleUpdating
    } = useSelector((state) => {
        return {
            TopicScheduledData: state.TopicScheduledData.data,
            isSchedulesLoading: state.TopicScheduledData.loading,
            cohorts: state.cohorts.data,
            isCohortsLoading: state.cohorts.loading,
            Subjects: state.Subjects.data,
            isSubjectsLoading: state.Subjects.loading,
            TopicsData: state.TopicsOfSubject.data,
            isTopicsLoading: state.TopicsOfSubject.loading,
            scheduleTopicRes: state.scheduleTopicRes.data,
            isTopicScheduling: state.scheduleTopicRes.loading,
            delScheduleRes: state.delScheduleRes.data,
            isScheduleDeleting: state.delScheduleRes.loading,
            ScheduleUpdateRes: state.ScheduleUpdateRes.data,
            isScheduleUpdating: state.ScheduleUpdateRes.loading,
        }
    })

    const dispatch = useDispatch();
    const [addform] = useForm()
    const [topicsForSchedule, setTopicsForSchedule] = useState([''])

    useEffect(() => {
        setTopics([])
        if (dispatch) {
            if (Subjects.length === 0) {
                dispatch(subjectsDataRead());
            }
            dispatch(cohortDataRead());
            loadSchedules()
        }
    }, [dispatch]);

    const loadSchedules = () => {
        dispatch(getScheduledData())
    }

    const [visiblePopover, setVisiblePopover] = useState(null);
    const handleOpenChange = (newVisible, recordId) => {
        setVisiblePopover(newVisible ? recordId : null);
    };

    const [startStopVisiblePopover, setStartStopVisiblePopover] = useState(null);
    const handleStopStartOpenChange = (newVisible, recordId) => {
        setStartStopVisiblePopover(newVisible ? recordId : null);
    };

    const [markAsConductedvisiblePopover, setmarkAsConductedVisiblePopover] = useState(null);

    const handleMarkAsConductedOpenChange = (newVisible, recordId) => {
        setmarkAsConductedVisiblePopover(newVisible ? recordId : null);
    };

    const handleDelete = (recordName) => {
        dispatch(deleteSchedule(recordName));
        setVisiblePopover(null);
    };

    const handleCancel = () => {
        setVisiblePopover(null);
    };

    const handleStartStopCancel = () => {
        setStartStopVisiblePopover(null);
    };

    const handleMarkAsConductedConductedCancel = () => {
        setmarkAsConductedVisiblePopover(null);
    };

    const columnsSort = [
        {
            title: 'S. No.',
            dataIndex: 'sno',
            align: 'center'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            fixed: 'left',
        },
        {
            title: 'Topic(s)',
            dataIndex: 'topics',
        },
        {
            title: 'Batch',
            dataIndex: 'cohortsLength',
            align: 'center',
            sorter: (a, b) => a.cohortsLength > b.cohortsLength ? 1 : -1,
        },
        {
            title: 'Schedule Date',
            dataIndex: 'scheduleDate',
            key: 'scheduleDate',
            sorter: (a, b) => a.scheduleDate > b.scheduleDate ? 1 : -1,
        },
        {
            title: 'Startedon',
            dataIndex: 'startedonTime',
            sorter: (a, b) => a.startedonTime > b.startedonTime ? 1 : -1,
        },
        {
            title: 'Status',
            dataIndex: 'statusEle',
            key: 'status',
            filters: TopicScheduledData !== null && TopicScheduledData.Error === false && TopicScheduledData.payload.length !== 0 && Array.from(new Set(TopicScheduledData.payload.map(schedule => schedule.status))).map(schedule => ({ key: schedule, text: schedule, value: schedule })),
            onFilter: (value, record) => record.status === value,
            fixed: 'right',
        },
        // {
        //     title: 'Actions',
        //     fixed: 'right',
        // render: (text, record) => (
        //     <div className='table-actions align-center-v'>
        //         <Button
        //             key="start-stop"
        //             type="primary"
        //             className='mr-10'
        //             onClick={() => updateSchedule(record.name, record.status === "STARTED" ? "STOPPED" : "STARTED")}
        //             disabled={record.status === "COMPLETED"}
        //         >
        //             {record.status === "STARTED" ? "Stop" : "Start"}
        //         </Button>

        //         <Tooltip align={'top'} title="Mark as conducted" >
        //             <Button
        //                 key="mark-as-completed"
        //                 type="link"
        //                 to="#"
        //                 shape="circle"
        //                 onClick={() => updateSchedule(record.name, "COMPLETED")}
        //                 disabled={record.status === "COMPLETED" || record.status === "SCHEDULED" || record.status === "STARTED"}
        //             >
        //                 <FontAwesome name='check' />
        //             </Button>
        //         </Tooltip>

        //         {/* check date and disable del button */}
        //         <Popover
        //             key={record.key}
        //             action={record.status === "COMPLETED" || record.status === "STARTED" || record.status === "STOPPED" ? '' : "click"}
        //             placement="top"
        //             title={'Are you sure?'}
        //             content={
        //                 <div>
        //                     <Button className='mr-5 text-danger' onClick={() => handleDelete(record.name)} disabled={isScheduleDeleting}>
        //                         Yes
        //                     </Button>
        //                     <Button onClick={handleCancel}>No</Button>
        //                 </div>
        //             }
        //             open={parseInt(visiblePopover) == parseInt(record.key)}
        //             onOpenChange={(newVisible) => handleOpenChange(newVisible, record.key)}
        //         >
        //             <Button
        //                 type="link"
        //                 to="#"
        //                 shape="circle"
        //                 disabled={record.status === "COMPLETED" || record.status === "STARTED" || record.status === "STOPPED"}
        //                 onClick={() => { setVisiblePopover(record.key) }}
        //             >
        //                 <UilTrash size={18} />
        //             </Button>
        //         </Popover>
        //     </div>
        // ),
        // }
    ];


    const [topics, setTopics] = useState([])
    const [subjectId, setSubjectId] = useState(0)
    const [topicId, setTopicId] = useState(0)
    const [maxQuestionCount, setMaxQuestionCount] = useState(0)
    const [questionsCount, setQuestionsCount] = useState(0)
    const [tableData, setTableData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [newScheduleModalVisible, setNewScheduleModalVisible] = useState(false)

    useEffect(() => {
        if (TopicScheduledData !== null && TopicScheduledData.Error === false && TopicScheduledData.payload.length !== 0) {
            const data = TopicScheduledData.payload.map((obj, index) => {
                const getStatusBadge = (status) => {
                    switch (status) {
                        case "STARTED":
                            return <Badge status="warning" />;
                        case "STOPPED":
                            return <Badge status="error" />;
                        case "COMPLETED":
                            return <Badge status="success" />;
                        default:
                            return <Badge status="default" />;
                    }
                };
                return {
                    sno: index + 1,
                    key: index + 1,
                    id: obj.id,
                    name: obj.name,
                    allTopics: obj.topics?.map((topic) => `${topic.topicName}` + ' (' + `${topic.questionCount}Q` + ')').join(', '),
                    topics: obj.topics?.length > 1 ?
                        obj.topics?.slice(0, 1).map(topic => `${topic.topicName}` + ' (' + `${topic.questionCount}` + ')').join(', ') + '...'
                        :
                        obj.topics?.map(topic => `${topic.topicName}` + ' (' + `${topic.questionCount}` + ')').join(', '),
                    cohorts: obj.cohorts?.map((cohort) => cohort.cohortName).join(', '),
                    cohortsLength: obj.cohorts?.length > 1 ? '1+' : obj.cohorts?.length,
                    scheduleDate: obj.scheduleDate,
                    status: obj.status,
                    statusEle: getStatusBadge(obj.status),
                    startedonTime: obj.startedon ? new Date(obj.startedon).toLocaleTimeString() : 'NA',
                    startedon: obj.startedon ? new Date(obj.startedon).toLocaleString() : 'NA',
                    stoppedon: obj.stoppedon ? new Date(obj.stoppedon).toLocaleString() : 'NA',
                    completedon: obj.completedon
                }
            })

            setTableData(data)
            setFilteredData(data)
        }
        else {
            setTableData([])
            setFilteredData([])
        }
    }, [TopicScheduledData])

    const searchkeys = ['name', 'scheduleDate', 'topics', 'cohorts']
    const searchStudent = (e) => {
        const { value } = e.currentTarget;
        const searchData = tableData.length !== 0 && tableData.filter((obj) => searchkeys.some((key) => obj[key].toLowerCase().includes(value.toLowerCase())))
        setFilteredData(searchData)
    };


    useEffect(() => {
        if ((parseInt(subjectId) !== 0) && (subjectId !== undefined)) {
            dispatch(topicsOfSubjectDataRead(subjectId));
        }
        else {
            dispatch(topicsofSubjectReadSuccess([]))
        }
    }, [subjectId])

    const addTopic = () => {
        if (subjectId && topicId && questionsCount) {
            const findRes = topics.find(obj => obj.subjectId === subjectId && obj.topicId === topicId);
            if (findRes) {
                return message.error("Topic already addded!")
            }
            setTopicsForSchedule([...topicsForSchedule, ''])
            setTopics([
                ...topics,
                {
                    subjectId: subjectId,
                    topicId: topicId,
                    questionCount: parseInt(questionsCount) // Convert questionsCount to a number
                }
            ]);
            // Clear the form fields after adding a topic
            setSubjectId(0);
            setTopicId(0);
            setQuestionsCount(0);
            setMaxQuestionCount(0)
        } else {
            message.error('Please fill in all fields');
        }
    }

    const AddNewSchedule = (values) => {
        let YYYY = new Date(values.date).getFullYear()
        let MM = (new Date(values.date).getMonth() + 1).toString().padStart(2, 0)
        let DD = new Date(values.date).getDate().toString().padStart(2, 0)

        let alltopics = [...topics]
        if (subjectId && topicId && questionsCount) {
            alltopics = [
                ...topics,
                {
                    topicId: topicId,
                    questionCount: parseInt(questionsCount) // Convert questionsCount to a number
                }
            ]

            setSubjectId(0);
            setTopicId(0);
            setQuestionsCount(0);
            setMaxQuestionCount(0)
        }

        let dispatchData = {
            name: values.name.toString() + "-" + process.env.REACT_APP_COLLEGE_CODE,
            topics: alltopics,
            cohortId: values.cohortId,
            scheduleDate: DD + '-' + MM + '-' + YYYY
        }
        dispatch(scheduleTopic(dispatchData))
    }

    useEffect(() => {
        if (scheduleTopicRes !== null && scheduleTopicRes.Error === false) {
            setNewScheduleModalVisible(false)
            dispatch(scheduleTopicSuccess(null))
            loadSchedules()
            addform.resetFields()
            setTopicsForSchedule([''])
        }
    }, [scheduleTopicRes])


    const updateSchedule = (name, status) => {
        const dispatchData = {
            name: name,
            status: status
        }
        dispatch(updateScheduleStatus(dispatchData))
        setStartStopVisiblePopover(null);
        setmarkAsConductedVisiblePopover(null);
    }

    useEffect(() => {
        if (ScheduleUpdateRes !== null && ScheduleUpdateRes.Error === false) {
            dispatch(updateScheduleSuccess(null))
            loadSchedules()
        }
    }, [ScheduleUpdateRes])

    useEffect(() => {
        if (delScheduleRes !== null && delScheduleRes.Error === false) {
            dispatch(deleteScheduleSuccess(null))
            loadSchedules()
        }
    }, [delScheduleRes])

    const disabledDate = current => {
        // Disable dates before today
        return current && current < moment().startOf('day');
    };

    const validateName = (rule, value) => {
        let find = tableData?.find((obj) => obj.name == value)
        const regex = /^[a-zA-Z0-9_-]*$/; // Only allows letters, numbers, underscores, and hyphens                
        if (!value) {
            return Promise.reject('Please enter name');
        }
        else if (find) {
            return Promise.reject('Name already exists!');
        }
        else if (!regex.test(value)) {
            return Promise.reject('Special characters not allowed except _ and -');
        }
        else {
            return Promise.resolve();
        }
    };

    const setTopicIdandMaxQCount = (e) => {
        if (e) {
            const parsedData = JSON.parse(e)
            setTopicId(parsedData.id);
            setMaxQuestionCount(parsedData.questions.length);
        }
        else {
            setTopicId(0);
            setMaxQuestionCount(0);
        }
    }

    return (
        <div>
            <Main>
                <PageHeader className="ninjadash-page-header-main" title="Schedules"
                    buttons={
                        [
                            <Button id="shcedules-page-add_schedule-button" onClick={() => setNewScheduleModalVisible(true)} key="NewSchedule" type="primary" size="default">
                                New Schedule
                            </Button>,
                        ]
                    }
                />
                {
                    isSchedulesLoading ?
                        <div className="spin">
                            <CustomSpinner Message={'Loading please wait...'} />
                        </div>
                        :
                        <Cards headless>
                            <NoteCardWrap>
                                <DataTableStyleWrap>

                                    <div>
                                        <div className='d-flex justify-content-between mb-15 p-0 align-center-v'>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                                                    <Badge status="success" />
                                                    <span style={{ marginLeft: 8 }}>Completed</span>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                                                    <Badge status="warning" />
                                                    <span style={{ marginLeft: 8 }}>Started</span>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                                                    <Badge status="error" />
                                                    <span style={{ marginLeft: 8 }}>Stopped</span>
                                                </div>
                                            </div>
                                            <div className="d-flex align-center-v" style={{ marginLeft: 'auto' }}>
                                                <Input id="monitor_search-input" className='' onChange={searchStudent} size="default" placeholder="Search here..." prefix={<UilSearch />} />
                                            </div>
                                        </div>
                                        <Table
                                            className="table-responsive"
                                            pagination={{
                                                defaultPageSize: 15,
                                                total: filteredData.length,
                                                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                            }}
                                            columns={columnsSort}
                                            dataSource={filteredData}
                                            tableLayout='auto'
                                            size='small'
                                            expandable={{
                                                expandedRowRender: (record) =>
                                                    <>
                                                        {/* <div> */}
                                                        <div className='table-actions text-right'>
                                                            <Popover
                                                                key={record.key}
                                                                action={record.status === "COMPLETED" ? '' : "click"}
                                                                placement="top"
                                                                title={record.status === 'STARTED' ? 'Do yo want to stop?' : 'Do you want to start?'}
                                                                content={
                                                                    <div>
                                                                        <Button className='mr-5 text-danger' onClick={() => updateSchedule(record.name, record.status === "STARTED" ? "STOPPED" : "STARTED")} disabled={isScheduleUpdating}>
                                                                            Yes
                                                                        </Button>
                                                                        <Button onClick={handleStartStopCancel}>No</Button>
                                                                    </div>
                                                                }
                                                                open={parseInt(startStopVisiblePopover) == parseInt(record.key)}
                                                                onOpenChange={(newVisible) => handleStopStartOpenChange(newVisible, record.key)}
                                                            >

                                                                <Button
                                                                    key="start-stop"
                                                                    type="primary"
                                                                    className='mr-10'
                                                                    onClick={() => { setStartStopVisiblePopover(record.key) }}
                                                                    disabled={record.status === "COMPLETED"}
                                                                >
                                                                    {record.status === "STARTED" ? "Stop" : "Start"}
                                                                </Button>
                                                            </Popover>

                                                            {/* <Tooltip align={'top'} title="Mark as conducted" > */}
                                                            <Popover
                                                                key={"check" + record.key}
                                                                action={record.status === "STOPPED" ? 'click' : ''}
                                                                placement="top"
                                                                title={'Mark as conducted?'}
                                                                content={
                                                                    <div>
                                                                        <Button className='mr-5 text-danger' onClick={() => updateSchedule(record.name, "COMPLETED")} disabled={isScheduleUpdating}>
                                                                            Yes
                                                                        </Button>
                                                                        <Button onClick={handleMarkAsConductedConductedCancel}>No</Button>
                                                                    </div>
                                                                }
                                                                open={parseInt(markAsConductedvisiblePopover) == parseInt(record.key)}
                                                                onOpenChange={(newVisible) => handleMarkAsConductedOpenChange(newVisible, record.key)}
                                                            >

                                                                <Button
                                                                    key="mark-as-completed"
                                                                    type="link"
                                                                    to="#"
                                                                    shape="circle"
                                                                    onClick={() => { setmarkAsConductedVisiblePopover(record.key) }}
                                                                    disabled={record.status === "COMPLETED" || record.status === "SCHEDULED" || record.status === "STARTED"}
                                                                >
                                                                    <FontAwesome name='check' />
                                                                </Button>
                                                            </Popover>
                                                            {/* </Tooltip> */}

                                                            {/* check date and disable del button */}
                                                            <Popover
                                                                key={"del" + record.key}
                                                                action={record.status === "COMPLETED" || record.status === "STARTED" || record.status === "STOPPED" ? '' : "click"}
                                                                placement="top"
                                                                title={'Are you sure?'}
                                                                content={
                                                                    <div>
                                                                        <Button className='mr-5 text-danger' onClick={() => handleDelete(record.name)} disabled={isScheduleDeleting}>
                                                                            Yes
                                                                        </Button>
                                                                        <Button onClick={handleCancel}>No</Button>
                                                                    </div>
                                                                }
                                                                open={parseInt(visiblePopover) == parseInt(record.key)}
                                                                onOpenChange={(newVisible) => handleOpenChange(newVisible, record.key)}
                                                            >
                                                                <Button
                                                                    type="link"
                                                                    to="#"
                                                                    shape="circle"
                                                                    disabled={record.status === "COMPLETED" || record.status === "STARTED" || record.status === "STOPPED"}
                                                                    onClick={() => { setVisiblePopover(record.key) }}
                                                                >
                                                                    <UilTrash size={18} />
                                                                </Button>
                                                            </Popover>
                                                        </div>
                                                        {/* </div> */}

                                                        <div className='p-20'>
                                                            <Row gutter={[16, 16]} justify="center">
                                                                <Col xs={24} sm={12}>
                                                                    <div className='mb-10'>
                                                                        <span className=''>Schedule Name</span>: <h4 className='d-inline'>{record.name}</h4>
                                                                    </div>
                                                                    <div className='mb-10'>
                                                                        <span>Topics</span>: <h4 className='d-inline' style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: record.allTopics.split('),').join(')<br />') }}></h4>
                                                                    </div>
                                                                    <div className='mb-10'>
                                                                        <span className=''>Batch</span>: <h4 className='d-inline'>{record.cohorts}</h4>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={24} sm={12}>
                                                                    <div className='mb-10'>
                                                                        <span className=''>Schedule Date</span>: <h4 className='d-inline'>{record.scheduleDate}</h4>
                                                                    </div>
                                                                    <div className='mb-10'>
                                                                        <span className=''>Started on</span>: <h4 className='d-inline'>{record.startedon}</h4>
                                                                    </div>
                                                                    <div className='mb-10'>
                                                                        <span className=''>Stopped on</span>: <h4 className='d-inline'>{record.stoppedon}</h4>
                                                                    </div>

                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </>
                                            }}
                                        />
                                    </div>
                                </DataTableStyleWrap>
                            </NoteCardWrap>
                        </Cards>
                }

                <Modal
                    open={newScheduleModalVisible}
                    title={`New Schedule`}
                    footer={false}
                    centered
                    onCancel={() => { setNewScheduleModalVisible(false); addform.resetFields(); setTopicsForSchedule(['']); setTopics([]); setSubjectId(0); setTopicId(0); setQuestionsCount(0); setMaxQuestionCount(0) }}
                    maskClosable={false}
                    width={1200}
                >
                    {/* <div style={{ backgroundColor: '#F8F9FB', padding: '10px', borderRadius: '10px' }}>
                        <div>Topics: {topicsForSchedule.length}</div>
                        <div>Questions: {topics.reduce((sum, topic) => sum + topic.questionCount, 0)}</div>
                    </div> */}
                    <FormValidationWrap>
                        <Form name="sDash_validation-form" form={addform} layout="vertical" onFinish={AddNewSchedule}>
                            {
                                topicsForSchedule?.map((field, index) =>
                                    <Row gutter={20} justify={'center'} key={index}>
                                        <Col lg={8} md={24} sm={24} xs={24}>
                                            <Form.Item
                                                name={`subjectId` + (index + 1)}
                                                rules={[{ required: true, message: 'Subject is required!' }]}
                                                label="Subject"
                                            >
                                                <Select disabled={topicsForSchedule.length - 1 !== index} allowClear placeholder="Select" loading={isSubjectsLoading}
                                                    onChange={(e) => { setSubjectId(e); setTopicId(0); setMaxQuestionCount(0); setQuestionsCount(0); addform.setFieldsValue({ [`topicId${index + 1}`]: undefined, "cohortId": undefined, [`questionsCount${index + 1}`]: undefined }) }}
                                                    showSearch
                                                    filterOption={(inputValue, option) =>
                                                        option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {
                                                        Subjects.length !== 0 && Subjects.map((sub, index) =>
                                                            <Select.Option key={index} value={sub.id}>{sub.name}</Select.Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={8} md={24} sm={24} xs={24}>
                                            <Form.Item
                                                name={`topicId` + (index + 1)}
                                                rules={[{ required: true, message: 'Topic is required!' }]}
                                                label="Topic"
                                            >
                                                <Select disabled={topicsForSchedule.length - 1 !== index} allowClear placeholder="Select" loading={isTopicsLoading}
                                                    onChange={(e) => { setTopicIdandMaxQCount(e); setQuestionsCount(0); addform.setFieldsValue({ "cohortId": undefined, [`questionsCount${index + 1}`]: undefined }) }}
                                                    showSearch
                                                    filterOption={(inputValue, option) =>
                                                        option.children[0].toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {
                                                        (subjectId !== 0 || subjectId !== undefined) && TopicsData.length !== 0 && TopicsData.filter((obj) => obj.topicstatus === "QUESTIONS FREEZED" && obj.status === "ACTIVE").map((topic, index) =>
                                                            <Select.Option key={index} value={JSON.stringify(topic)}>{topic.name} ({topic.questions.length} Q)</Select.Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={index !== 0 ? 6 : 8} md={22} sm={22} xs={22}>
                                            <Form.Item
                                                name={`questionsCount` + (index + 1)}
                                                rules={[{ required: true, message: 'Question count is required!' }]}
                                                label="Questions"
                                            >
                                                <Select disabled={topicsForSchedule.length - 1 !== index} allowClear placeholder="Select" loading={isTopicsLoading} onChange={(e) => { setQuestionsCount(e); addform.setFieldsValue({ "cohortId": undefined }) }}>
                                                    {
                                                        Array.from({ length: maxQuestionCount }).map((_, index) =>
                                                            <Select.Option key={index} value={index + 1}>{index + 1}</Select.Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        {
                                            index !== 0 &&
                                            <Col lg={2} md={2} sm={2} xs={2}>
                                                <div className=''>
                                                </div><br />
                                                <Button type='link' className='text-danger mt-10' onClick={() => { setTopicsForSchedule(topicsForSchedule.slice(0, -1)); setSubjectId(topics ? topics[topics.length - 1].subjectId : 0); setTopicId(topics ? topics[topics.length - 1].topicId : 0); setQuestionsCount(topics ? topics[topics.length - 1].questionCount : 0); topics.splice(topics.length - 1, 1); }} ><UilTimes /></Button>
                                            </Col>
                                        }
                                    </Row>
                                )
                            }
                            <Button type='link' className='mt-10 mb-20' onClick={() => addTopic()}>+ Add topic</Button>
                            <Row gutter={20} justify={'center'}>
                                <Col lg={8} md={24} sm={24} xs={24}>
                                    <Form.Item
                                        name={`name`}
                                        rules={[{ required: true, validator: validateName }]}
                                        label="Name"
                                    >
                                        <Input id="schedule-name" placeholder="Enter schedule name" size='small' addonAfter={process.env.REACT_APP_COLLEGE_CODE} />
                                    </Form.Item>
                                </Col>
                                <Col lg={8} md={24} sm={24} xs={24}>
                                    <Form.Item
                                        name={`cohortId`}
                                        rules={[{ required: true, message: 'Batch required!' }]}
                                        label="Batch"
                                    >
                                        <Select allowClear placeholder="Select" loading={isCohortsLoading}
                                            showSearch
                                            filterOption={(inputValue, option) =>
                                                option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                            }
                                        >
                                            {
                                                topicId !== 0 && Subjects.length !== 0 && cohorts.map((cohort, index) =>
                                                    <Select.Option key={index} value={cohort.id}>{cohort.name}</Select.Option>
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={8} md={24} sm={24} xs={24}>
                                    <Form.Item
                                        name="date"
                                        rules={[{ required: true, message: 'Date required!' }]}
                                        label="Date"
                                    >
                                        <DatePicker
                                            disabledDate={disabledDate}
                                            format="YYYY-MM-DD"
                                            placeholder="YYYY-MM-DD"
                                            allowClear
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <div className=' d-flex justify-content-end mb-20'>
                                <Button htmlType="reset" type="secondary" className='mr-10' onClick={() => {
                                    setTopics([])
                                    setTopicId(0)
                                    setSubjectId(0)
                                    setQuestionsCount(0)
                                    setMaxQuestionCount(0)
                                    setTopicsForSchedule([''])
                                }} >
                                    Reset
                                </Button>
                                <Button htmlType="submit" type="primary" disabled={isTopicScheduling} >
                                    {isTopicScheduling ? 'Scheduling...' : 'Schedule'}
                                </Button>
                            </div>
                        </Form>
                    </FormValidationWrap>
                </Modal>

            </Main>
        </div>
    )
}
