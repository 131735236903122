import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import { Result, Tooltip, Select, message, Modal, Form, Input, Button as AntButton } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import { UilCheck, UilMultiply } from '@iconscout/react-unicons';
import { DownloadOutlined } from '@ant-design/icons';
import Papa from 'papaparse';
// import ImportQuestions from './ImportQuestions';
import UilExport from "@iconscout/react-unicons/icons/uil-export";
import Dragger from 'antd/lib/upload/Dragger';
import { Button } from '../../components/buttons/buttons';
import { useDispatch, useSelector } from 'react-redux';
import { TableDefaultStyle } from '../styled';
import { TopSellerWrap } from '../teacher/styles/dashboardStyle';
import { Table } from 'antd';
import { Steps, Step } from '../../components/steps/steps';
import { Cards } from '../../components/cards/frame/cards-frame';
import { alertModal } from '../../components/modals/antd-modals';
import { NoteCardWrap } from '../note/style';
import { GenerateQuestionsOfTopic, ImportQuestionsOfTopic, ReGenerateQuestionsOfTopic, CreateQuestionOfTopic, AcceptOrRejectQuestion, UpdateQuestion, GetQuestionsOfTopicDataRead, TopicUpdate, topicsOfSubjectDataRead } from '../../redux/teacherSubjectsUnits/actionCreator';
import actions from '../../redux/teacherSubjectsUnits/actions'
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import TextArea from 'antd/lib/input/TextArea';
import GenerateQuestions from '../teacher/GenerateQuestions';
import { FreezeTopicById } from '../../redux/topics/actionCreator';
import { getTopicById } from '../../redux/topics/actionCreator';
import FontAwesome from 'react-fontawesome';
import WaveSurfer from 'wavesurfer.js'
import ReviewAndGenerateQuestions from '../teacher/ReviewAndRegenerateQuestions';
import { subjectsDataRead } from '../../redux/subjects/actionCreator';

const { TopicUpdateSuccess } = actions

function TopicView({ customWidth }) {
  var { subjectId, subjectName, topicId, topicName } = useParams()
  const { Option } = Select;
  const [form] = Form.useForm();
  const [createform] = Form.useForm();
  const [importform] = Form.useForm();
  const [Regenerateform] = Form.useForm();
  const [updateTopicForm] = Form.useForm();

  const dispatch = useDispatch();

  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1);

  const {
    teacherSubjectsData,
    isteacherSubjectsDataLoading,
    QuestionsOfTopic,
    isQuestionsOfTopicLoading,
    TopicUpdateRes,
    isTopicUpdateLoading,
    AcceptOrRejectQuestionData,
    isAcceptOrRejectQuestionLoading,
    // UpdateQuestionData,
    // isUpdateQuestionDataLoading,
    TopicStatusData,
    isTopicStatusDataLoading,
    singleTopicData,
    issingleTopicDataLoading,
    // isCreateQuestionDataLoading,
    // CreateQuestionData,
    isRegenerateQuestionsDataLoading,
    RegenerateQuestionsData,
    isImportQuestionsDataLoading,
    ImportQuestionsData,
  } = useSelector((state) => {
    return {
      teacherSubjectsData: state.TeacherSubjects.data,
      isteacherSubjectsDataLoading: state.TeacherSubjects.data,
      QuestionsOfTopic: state.QuestionsOfTopic.data,
      isQuestionsOfTopicLoading: state.QuestionsOfTopic.loading,
      TopicUpdateRes: state.TopicUpdate.data,
      isTopicUpdateLoading: state.TopicUpdate.loading,
      AcceptOrRejectQuestionData: state.AcceptOrRejectQuestion.data,
      isAcceptOrRejectQuestionLoading: state.AcceptOrRejectQuestion.loading,
      // UpdateQuestionData: state.Updatequestion.data,
      // isUpdateQuestionDataLoading: state.Updatequestion.loading,
      TopicStatusData: state.TopicStatus.data,
      isTopicStatusDataLoading: state.TopicStatus.loading,
      singleTopicData: state.singleTopic.data,
      issingleTopicDataLoading: state.singleTopic.data,
      // CreateQuestionData: state.CreateQuestion.data,
      // isCreateQuestionDataLoading: state.CreateQuestion.loading,
      RegenerateQuestionsData: state.RegenerateQuestions.data,
      isRegenerateQuestionsDataLoading: state.RegenerateQuestions.loading,
      ImportQuestionsData: state.ImportQuestions.data,
      isImportQuestionsDataLoading: state.ImportQuestions.loading,
    };
  });

  useEffect(() => {
    // let fieldsValue = updateTopicForm.getFieldsValue()
    // let subObject = SubjectsUnits.find((obj) => obj.subjectId == parseInt(fieldsValue.subjectId))    
    if (TopicUpdateRes && TopicUpdateRes.Error === false) {
      setTopicUpdateModal(false);
      // navigate(`/teacher/subjects/${subObject.subjectname}/${fieldsValue.subjectId}/topic/${topicId}/${fieldsValue.topic}/${topicstatus}/${fieldsValue.status}`)
      navigate(`/teacher/${subjectName}/${parseInt(subjectId)}}`)
      dispatch(topicsOfSubjectDataRead(parseInt(subjectId)));
      // dispatch(topicsofSubjectReadSuccess(null));
      dispatch(TopicUpdateSuccess(null))
      updateTopicForm.resetFields()
    }
  }, [TopicUpdateRes])

  useEffect(() => {    
    dispatch(GetQuestionsOfTopicDataRead(topicId));
    if (teacherSubjectsData.length === 0) {
      dispatch(subjectsDataRead())
    }
    dispatch(getTopicById(topicId))
    setviewQuestionsAfterFreeze(false);
  }, [topicId])

  // useEffect(() => {
  //   if (CreateQuestionData !== null && CreateQuestionData.Error === false) {
  //     dispatch(GetQuestionsOfTopicDataRead(topicId));
  //     dispatch(getTopicById(topicId));
  //   }
  // }, [CreateQuestionData])

  useEffect(() => {
    if (RegenerateQuestionsData !== null && RegenerateQuestionsData.Error === false) {
      dispatch(GetQuestionsOfTopicDataRead(topicId));
      dispatch(getTopicById(topicId));
    }
  }, [RegenerateQuestionsData])

  // useEffect(() => {
  //   if (ImportQuestionsData !== null && ImportQuestionsData.Error === false) {
  //     dispatch(GetQuestionsOfTopicDataRead(topicId));
  //     dispatch(getTopicById(topicId));
  //   }
  // }, [ImportQuestionsData])

  useEffect(() => {
    if (AcceptOrRejectQuestionData !== null && AcceptOrRejectQuestionData.Error === false) {
      dispatch(GetQuestionsOfTopicDataRead(topicId));
    }
  }, [AcceptOrRejectQuestionData])

  // useEffect(() => {
  //   if (TopicStatusData !== null && TopicStatusData.Error === false) {
  //     dispatch(getTopicById(topicId));
  //   }
  // }, [TopicStatusData])

  // useEffect(() => {
  //   if (UpdateQuestionData !== null && UpdateQuestionData.Error === false) {
  //     dispatch(GetQuestionsOfTopicDataRead(topicId));
  //   }
  // }, [UpdateQuestionData])

  const [sideBarState, setSidebarState] = useState({
    responsive: 0,
    collapsed: false,
    visible: false,
    modalType: 'primary',
  });

  useLayoutEffect(() => {
    function updateSize() {
      const width = window.innerWidth;
      setSidebarState({ ...sideBarState, responsive: width });
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);


  const [TableData, setTableData] = useState([]);
  const [GoodData, setGoodData] = useState([]);

  useEffect(() => {
    if (QuestionsOfTopic) {
      const filteredData = QuestionsOfTopic.map((item, index) => ({
        id: item.id,
        key: index + 1,
        sno: index + 1,
        question: item.question.replace(/<[^>]*>|&nbsp;/g, ' '),
        audio: item.audiolink !== null ? <AudioPlayer audioUrl={item.audiolink} id={`waveform-${index + 1}`} /> : 'NA',
        difficultylevel: item.difficultylevel,
        questionType: item.questionType,
        statuss: <span className={`status ${item.status.toLowerCase()}`}>{item.status}</span>,
        status: item.status,
        tagId: item.tags ? item.tags.map((i) => i.id) : [],
        tags: item.tags ? item.tags.map((i) => i.name) : [],
      }));
      setTableData(filteredData);
      setGoodData(filteredData);
    }
  }, [QuestionsOfTopic])


  const [QuestionEditModal, setQuestionEditModal] = useState(false);
  const [EditQuestionRecord, setEditQuestionRecord] = useState(null);

  const handleEditQuestion = (record) => {
    setEditQuestionRecord(record);
    setQuestionEditModal(true);
    form.setFieldsValue({
      question: record.question,
    })
  }

  const handleEditQuestionSubmit = () => {
    form.validateFields().then((values) => {
      const datatopost = {
        question: values.question,
        id: EditQuestionRecord.id,
        tags: EditQuestionRecord.tagId,
      }
      dispatch(UpdateQuestion(datatopost));
      setQuestionEditModal(false);
      form.resetFields();
      setEditQuestionRecord(null);
    })
      .catch((error) => {
        console.error('Error while validating form:', error);
      });
  }


  const AudioPlayer = ({ audioUrl, id }) => {
    const [waveSurfer, setWaveSurfer] = useState(null);
    const [currentlyPlayingId, setCurrentlyPlayingId] = useState(null);


    useEffect(() => {
      const newWaveSurfer = WaveSurfer.create({
        container: `#${id}`,
        waveColor: '#6FA2CE',
        progressColor: '#2D5D85',
        cursorColor: '#224563',
        cursorWidth: 2,
        barWidth: 2,
        barGap: 1,
        barRadius: 2,
        height: 20,
        width: 100,
      });

      newWaveSurfer.on('finish', () => {
        setIsPlaying(false);
      });

      setWaveSurfer(newWaveSurfer);

      return () => {
        newWaveSurfer.destroy();
      };
    }, []);

    useEffect(() => {
      if (waveSurfer && audioUrl) {
        waveSurfer.load(process.env.REACT_APP_API_ENDPOINT + audioUrl);
      }
    }, [waveSurfer, audioUrl]);

    const [isPlaying, setIsPlaying] = useState(false);
    const handlePlayPause = () => {
      if (waveSurfer.isPlaying()) {
        waveSurfer.pause();
        setIsPlaying(false);
      } else {
        waveSurfer.play();
        setIsPlaying(true);
      }
    };

    return (
      <div >
        <div className='d-flex justify-content-center align-center-v'>
          <div id={id} className='waveform-review d-none' />
          <AntButton onClick={handlePlayPause} className='icon-btn mb-0' type='link'>
            {isPlaying ? (
              <FontAwesome name="pause" style={{ fontSize: '14px', background: '#eee', padding: '6px 14px', borderRadius: '6px' }} />
            ) : (
              <FontAwesome name="play" style={{ fontSize: '14px', background: '#eee', padding: '6px 14px', borderRadius: '6px' }} />
            )}
          </AntButton>
        </div>
      </div>
    );
  };

  const CSVColumns = [
    {
      title: 'Question',
      key: 'question',
      dataIndex: 'question',
    },
    {
      title: 'Difficulty Level',
      key: 'difficultylevel',
      dataIndex: 'difficultylevel',
    },
    // {
    //   title: 'Tags',
    //   key: 'tags',
    //   dataIndex: [],
    // },
  ];

  const columns = [
    {
      title: 'S.No',
      key: 'sno',
      dataIndex: 'sno',
      align: 'center',
      width: 60
    },
    {
      title: 'Question',
      key: 'question',
      dataIndex: 'question',
      ellipsis: true,
      width: 420,
    },
    {
      title: 'Type',
      key: 'questionType',
      dataIndex: 'questionType',
      width: 80,
    },
    {
      title: 'Audio',
      dataIndex: 'audio',
      width: '30%',
      align: 'center',
      width: 100,
    },
    {
      title: 'Actions',
      key: 'question',
      align: 'center',
      width: 100,
      render: (_, record) => (
        <div className=''>
          {
            record.status === 'ACCEPTED' ?
              <Button
                transparented className="mr-5"
                onClick={() => handleAcceptOrIgnoreQuestion(record, 0)}
              >
                <FontAwesome name="times" style={{ fontSize: '18px', background: '#eee', padding: '6px 14px', borderRadius: '6px' }} />
              </Button>
              :
              <Button
                transparented className="mr-5"
                onClick={() => handleAcceptOrIgnoreQuestion(record, 1)}
              >
                <FontAwesome name="check" style={{ fontSize: '18px', background: '#eee', padding: '6px 14px', borderRadius: '6px' }} />
              </Button>
          }
        </div>
      ),
    },
  ]

  const [selectedDifficultyLevel, setSelectedDifficultyLevel] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const checkDifficultyLevel = (i) => {
    let tarr = i.difficultylevel;
    let valueInArray = false;
    if (tarr === selectedDifficultyLevel) {
      valueInArray = true;
    }
    return valueInArray;
  }

  const checkStatus = (i) => {
    let tarr = i.status;
    let valueInArray = false;
    if (tarr === selectedStatus) {
      valueInArray = true;
    }
    return valueInArray;
  }

  useEffect(() => {
    let tempValues = [...GoodData];
    if (selectedDifficultyLevel != null) {
      tempValues = tempValues.filter(checkDifficultyLevel);
    }
    if (selectedStatus != null) {
      tempValues = tempValues.filter(checkStatus);
    }
    setTableData(tempValues);
  }, [selectedDifficultyLevel, selectedStatus])

  const handleDifficultyLevelChange = (e) => {
    setSelectedDifficultyLevel(e);
  }

  const handleStatusChange = (e) => {
    setSelectedStatus(e);
  }

  const [topicUpdateModal, setTopicUpdateModal] = useState(false);

  const handleTopicEditButton = () => {
    setTopicUpdateModal(true);
    updateTopicForm.setFieldsValue({
      subjectId: parseInt(subjectId),
      status: singleTopicData?.status,
      topic: singleTopicData?.name,
    })
  }

  const handleAcceptOrIgnoreQuestion = (record, value) => {
    const datatoPost = {
      id: record.id,
      status: value === 0 ? 'REJECTED' : 'ACCEPTED',
    }
    dispatch(AcceptOrRejectQuestion(datatoPost));
  }

  const handleTopicUpdate = () => {
    updateTopicForm.validateFields().then((values) => {
      const DispatchData = {
        id: parseInt(topicId),
        name: values.topic,
        status: values.status,
        subjectId: parseInt(values.subjectId)
      }
      dispatch(TopicUpdate(DispatchData));
    })
      .catch((error) => {
        console.error('Error while validating form:', error);
      });
  }
  const handleFreezeQuestions = () => {
    alertModal.confirm({
      title: `Are you sure to freeze questions for ${topicName}`,
      content: <div>
        <span>Once the topic is freezed, you cannot unfreeze the topic anymore</span>
      </div>,
      onOk() {
        dispatch(FreezeTopicById(topicId));
      },
      onCancel() { },
    });
  }

  const [createQuestionModal, setCreateQuestionModal] = useState(false);
  const [RegenerateQuestionsModal, setRegenerateQuestionsModal] = useState(false);

  const handleCreateQuestion = () => {
    setCreateQuestionModal(true);
  }

  const handleRegenerateQuestions = () => {
    setRegenerateQuestionsModal(true);
  }

  const handleCreateQuestionSubmit = () => {
    createform.validateFields().then((values) => {
      const datatoPost = {
        question: values.question,
        difficultylevel: values.difficultylevel,
        topicId: parseInt(topicId),
        tags: [],
      }
      dispatch(CreateQuestionOfTopic(datatoPost));
      setCreateQuestionModal(false);
      createform.resetFields();
    })
      .catch((error) => {
        console.error('Error while validating form:', error);
      });
  }

  const handleRegenerateQuestionsSubmit = () => {
    Regenerateform.validateFields().then((values) => {
      const datatoPost = {
        topicId: parseInt(topicId),
        easy: parseInt(values.easy),
        intermediate: parseInt(values.intermediate),
        advance: parseInt(values.advanced),
      }
      dispatch(ReGenerateQuestionsOfTopic(datatoPost))
      setRegenerateQuestionsModal(false);
      Regenerateform.resetFields();
    })
      .catch((error) => {
        console.error('Error while validating form:', error);
      });
  }



  const [csvFile, setCSVFile] = useState(null);
  const [csvFileList, setCSVFileList] = useState([]);
  const [previewtabledata, setpreviewtabledata] = useState([]);
  const [PreviewModal, setPreviewModal] = useState(false);

  const [importModal, setimportModal] = useState(false);

  const sampleCSVData = [
    {
      question: "Features of java 8",
      difficultylevel: "intermediate",
    },
    {
      question: "What is an object?",
      difficultylevel: "easy",
    },
    {
      question: "Purpose of using TreeMap",
      difficultylevel: "advanced",
    },
  ];

  const [ImportQuestionsComponent, setImportQuestionsComponent] = useState(false);
  const handleImportQuestions = () => {
    // setimportModal(true);
    setImportQuestionsComponent(true);
  }

  const handleQuestionsImported = () => {
    setImportQuestionsComponent(false);
  }

  const handlePreviewQuestionsImport = () => {
    Papa.parse(csvFile, {
      complete: (result) => {
        // result.data.pop();
        const invalidHeaders = result.meta.fields.filter(item => item !== "question" && item !== "difficultylevel");
        if (invalidHeaders.length > 0) {
          message.error("Invalid columns found, only question and difficultylevel columns are allowed", 3)
          return;
        }
        const EmptyCells = result.data.filter((row) => {
          return Object.values(row).every((cell) => cell.trim() !== '');
        });
        if (EmptyCells.length !== result.data.length) {
          message.error("Empty values found in the uploaded CSV file. Please make sure all cells are filled.", 3);
          return;
        }
        const filteredData = result.data.filter((row) => Object.values(row).some((cell) => cell.trim() !== ''));
        const filteredCSVData = filteredData.map((row) => (
          row !== null &&
          {
            question: row.question,
            difficultylevel: row.difficultylevel,
            tags: [],
            topicId: parseInt(topicId),
          }
        ));
        setpreviewtabledata(filteredCSVData)
        importform.resetFields();
        setimportModal(false);
        setCSVFileList([]);
        setCSVFile(null);
        setPreviewModal(true);
      },
      header: true,
    });
  }

  const handleUploadQuestionsSubmit = () => {
    dispatch(ImportQuestionsOfTopic(previewtabledata))
    setPreviewModal(false);
    setpreviewtabledata([]);
  }


  const GlobalStatus = singleTopicData?.status;
  const Topicstatus = singleTopicData?.topicstatus;

  useEffect(() => {
    if (singleTopicData?.topicstatus === "QUESTIONS GENERATING") {
      const intervalCall = setInterval(() => {
        console.log('called')
        dispatch(getTopicById(parseInt(topicId)));
      }, 30000);
      return () => {
        // clean up
        clearInterval(intervalCall);
      };
    }
    else if (singleTopicData?.topicstatus === "QUESTIONS GENERATED") {
      dispatch(GetQuestionsOfTopicDataRead(topicId));
    }
  }, [singleTopicData]);

  const [viewQuestionsAfterFreeze, setviewQuestionsAfterFreeze] = useState(false);
  const handleViewQuestions = () => {
    setviewQuestionsAfterFreeze(true);
  }

  return (
    <>



      <Cards headless>
        <div className='d-flex justify-content-center mb-20'>
          <h2>Questions of {topicName}</h2>
          {
            Topicstatus === 'NOTHING' &&
            <div className=''>
              <Tooltip title="Update Topic" align={'top'}>
                <Button type='link' onClick={handleTopicEditButton} ><UilEdit size={16} style={{ marginTop: '5px' }} /></Button>
              </Tooltip>
            </div>
          }
          {/* {Topicstatus === 'QUESTIONS GENERATED' ?
              <Button type='primary' onClick={handleImportQuestions}>
              Import Questions
              </Button>
              : ''
            } */}
        </div>

        {GlobalStatus !== null && GlobalStatus === 'ACTIVE' ?
          <>
            <div className='ml-20 mr-20 mb-20' style={customWidth}>
              <Steps current={5} size="small" >
                <Step title="Generate/Import" status={`${Topicstatus === 'NOTHING' || Topicstatus === 'QUESTIONS GENERATING' ? 'process' : 'finish'}`} />
                <Step title="Review/Regenerate" status={`${Topicstatus === 'QUESTIONS GENERATED' ? 'process' : Topicstatus === 'QUESTIONS FREEZED' ? 'finish' : 'wait'}`} />
                <Step title="Freeze" status={`${Topicstatus === 'QUESTIONS FREEZED' ? 'finish' : (Topicstatus === 'QUESTIONS GENERATED' || Topicstatus === 'NOTHING') ? 'wait' : 'process'}`} />
              </Steps>
            </div>
            {isImportQuestionsDataLoading || isTopicStatusDataLoading || isAcceptOrRejectQuestionLoading ?
              <NoteCardWrap>
                <div className='custom-spin'>
                  <CustomSpinner Message={'Loading... Please wait.'} marginTop={40} />
                </div>
              </NoteCardWrap>
              :
              <>
                {
                  Topicstatus === 'NOTHING' ?
                    <>
                      <GenerateQuestions customWidth={customWidth} />
                    </>
                    :
                    Topicstatus === 'QUESTIONS GENERATING' || isRegenerateQuestionsDataLoading ?
                      <NoteCardWrap>
                        <div className='custom-spin'>
                          <CustomSpinner Message={'Questions are being generating... Please wait.'} marginTop={40} />
                        </div>
                      </NoteCardWrap>
                      :
                      Topicstatus === 'QUESTIONS GENERATED' ?
                        <div>
                          <ReviewAndGenerateQuestions Topicstatus={Topicstatus} customWidth={customWidth} />
                        </div>
                        :

                        Topicstatus === 'QUESTIONS FREEZED' && viewQuestionsAfterFreeze ?
                          <div>
                            <div className='text-right mb-10'>
                              <Button type='primary' onClick={() => setviewQuestionsAfterFreeze(false)}>Back</Button>
                            </div>
                            <TableDefaultStyle className="ninjadash-having-header-bg">
                              <TopSellerWrap>
                                <div className="table-bordered top-seller-table table-responsive">
                                  <Table
                                    columns={columns}
                                    dataSource={TableData}
                                    pagination={{
                                      className: 'd-flex align-center-v',
                                      size: 'small',
                                      responsive: true,
                                      // current: currentPage,
                                      // onChange: (page) => setCurrentPage(page),
                                      // showSizeChanger: true,
                                      // hideOnSinglePage:true,                                      
                                      showTotal: (total) => `Total ${total} Questions`
                                    }}
                                    expandable={{
                                      expandedRowRender: (record) =>
                                      <div dangerouslySetInnerHTML={{ __html: record.question }} className='p-20 d-flex justify-content-start'>
                                      </div>
                                    }}
                                  />
                                </div>
                              </TopSellerWrap>
                            </TableDefaultStyle>
                          </div>
                          :
                          Topicstatus === 'QUESTIONS FREEZED' &&
                          <NoteCardWrap>
                            <Result status="success" title={`Questions of ${topicName} has been Freezed`} extra={<Button type='primary' onClick={handleViewQuestions}>View</Button>} />
                          </NoteCardWrap>
                }
              </>
            }

          </>
          :
          <Result status="warning" title="Topic is Inactive" />
        }
      </Cards>


      <Modal
        title="Edit Question"
        open={QuestionEditModal}
        onCancel={() => { setQuestionEditModal(false); form.resetFields(); setEditQuestionRecord(null); }}
        maskClosable={false}
        footer={[
          <Button id="edit-question-button" key="EditQuestionButton" type="primary" onClick={handleEditQuestionSubmit}>
            Update
          </Button>,
        ]}

      >
        <Form id="edit-question-form" form={form} layout="vertical">
          <Form.Item name='question' label='Question' rules={[{ required: true, message: 'please enter the question' }]}>
            <TextArea rows={2} id="edit-Question-text-box" placeholder="Enter Question" />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Add Question"
        open={createQuestionModal}
        onCancel={() => { setCreateQuestionModal(false); createform.resetFields(); }}
        maskClosable={false}
        footer={[
          <Button id="create-question-button" key="createQuestionButton" type="primary" onClick={handleCreateQuestionSubmit}>
            Submit
          </Button>,
        ]}
      >
        <Form id="create-question-form" form={createform} layout="vertical">
          <Form.Item name='question' label='Question' rules={[{ required: true, message: 'please enter the question' }]}>
            <TextArea rows={2} id="edit-Question-text-box" placeholder="Enter Question" />
          </Form.Item>
          <Form.Item name='difficultylevel' label='Difficulty Level' rules={[{ required: true, message: 'please select difficulty level ' }]}>
            <Select placeholder='Select Difficulty level' allowClear>
              <Option value='EASY' key='easy'>Easy</Option>
              <Option value='INTERMEDIATE' key='intermediate'>Intermediate</Option>
              <Option value='ADVANCED' key='advanced' >Advanced</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Preview Questions"
        open={PreviewModal}
        onCancel={() => { setPreviewModal(false); setpreviewtabledata([]) }}
        maskClosable={false}
        footer={[
          <Button id="preview--question-submit-button" key="previewQuestionButton" type="primary" onClick={handleUploadQuestionsSubmit}>
            Submit
          </Button>,
        ]}
        width={1000}
      >
        <Table dataSource={previewtabledata} columns={CSVColumns} pagination={true} />
      </Modal>
      <Modal
        title="Import Questions"
        open={importModal}
        onCancel={() => { setimportModal(false); importform.resetFields(); setCSVFileList([]); setCSVFile(null); }}
        maskClosable={false}
        footer={[
          <Button id="import-question-button" key="importQuestionButton" type="primary" onClick={handlePreviewQuestionsImport}>
            Preview
          </Button>,
        ]}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ fontSize: '17px', color: 'black', marginRight: '5px', marginBottom: '0' }}>Sample CSV File:</p>
          <span className="download-text" style={{ marginLeft: '10px', marginRight: '5px', marginBottom: '0', fontSize: '17px' }}>sample.csv</span>
          <DownloadOutlined id="download-sample-csv-file"
            style={{ verticalAlign: 'middle' }}
            className="download-icon"
            onClick={() => {
              const csvContent = Papa.unparse(sampleCSVData);
              const blob = new Blob([csvContent], { type: "text/csv" });
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = "samplequestions.csv";
              link.click();
            }}
          />
        </div>
        <Form id="import-question-form" form={importform} layout="vertical">
          <Form.Item
            name="csvFile"
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              const fileList = e && e.fileList.slice(-1);
              setCSVFileList(fileList);
              return fileList;
            }}
            rules={[
              { required: true, message: 'Please upload a CSV file!' },
              ({ fileList }) => ({
                validator(_, value) {
                  const file = fileList && fileList[0];
                  if (file) {
                    const fileName = file.name.toLowerCase();
                    if (fileName.endsWith('.csv')) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Please upload a CSV file!');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Dragger
              accept=".csv"
              beforeUpload={(file) => {
                setCSVFile(file);
                return false;
              }}
              fileList={csvFileList}
            >
              <p className="ant-upload-drag-icon">
                <UilExport />
              </p>
              <p className="ant-upload-text">Drag or upload .csv file!  </p>
            </Dragger>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={`Regenerate Questions for ${topicName}`}
        open={RegenerateQuestionsModal}
        onCancel={() => { setRegenerateQuestionsModal(false); Regenerateform.resetFields(); }}
        maskClosable={false}
        footer={[
          <Button id="regenerate-question-button" key="regenerateQuestionButton" type="primary" onClick={handleRegenerateQuestionsSubmit}>
            Submit
          </Button>,
        ]}
      >
        <Form id="regenerate-questions-form" form={Regenerateform} layout="vertical">
          <Form.Item name='easy' label='Easy' rules={[{ required: true, message: 'please select no. of easy questions' }]}>
            <Select placeholder='Select no of easy questions' allowClear>
              <Option key='10' value='10'>10</Option>
              <Option key='20' value='20'>20</Option>
              <Option key='30' value='30'>30</Option>
              <Option key='40' value='40'>40</Option>
              <Option key='50' value='50'>50</Option>
            </Select>
          </Form.Item>
          <Form.Item name='intermediate' label='Intermediate' rules={[{ required: true, message: 'please select no. of intermediate questions' }]}>
            <Select placeholder='Select no of intermediate questions' allowClear>
              <Option key='10' value='10'>10</Option>
              <Option key='20' value='20'>20</Option>
              <Option key='30' value='30'>30</Option>
              <Option key='40' value='40'>40</Option>
              <Option key='50' value='50'>50</Option>
            </Select>
          </Form.Item>
          <Form.Item name='advanced' label='Advanced' rules={[{ required: true, message: 'please select no. of advanced questions' }]}>
            <Select placeholder='Select no of advanced questions' allowClear>
              <Option key='10' value='10'>10</Option>
              <Option key='20' value='20'>20</Option>
              <Option key='30' value='30'>30</Option>
              <Option key='40' value='40'>40</Option>
              <Option key='50' value='50'>50</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Update Topic"
        open={topicUpdateModal}
        onCancel={() => { setTopicUpdateModal(false); updateTopicForm.resetFields(); }}
        maskClosable={false}
        footer={[
          <Button id="update-topic-button" key="UpdateTopicButton" type="primary" onClick={handleTopicUpdate} disabled={isTopicUpdateLoading}>
            {isTopicUpdateLoading ? 'Updating...' : 'Update'}
          </Button>,
        ]}
      >
        <Form id="update-topic-form" form={updateTopicForm} layout="vertical">
          <Form.Item
            name="subjectId"
            rules={[{ required: true, message: 'Subject required!' }]}
            label="Subject"
          >
            <Select placeholder="Select subject" loading={isteacherSubjectsDataLoading}>
              {
                teacherSubjectsData.length !== 0 && teacherSubjectsData.map((sub, index) =>
                  <Option key={index} value={sub.subjectId}>{sub.subjectname}</Option>
                )
              }
            </Select>
          </Form.Item>
          <Form.Item name='topic' label='Topic' rules={[{ required: true, message: 'Please enter topic name' }]}>
            <Input id="update-topic_enter-topic-name" placeholder="Enter topic name" />
          </Form.Item>
          <Form.Item name='status' label='Status' rules={[{ required: true, message: 'Please select status' }]}>
            <Select placeholder='Select Status' allowClear>
              <Option key='ACTIVE' value="ACTIVE">ACTIVE</Option>
              <Option key='INACTIVE' value="INACTIVE">INACTIVE</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default TopicView;