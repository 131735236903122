import React, { useEffect, useState } from 'react'
import { Main } from '../styled'
import { PageHeader } from '../../components/page-headers/page-headers'
import { Cards } from '../../components/cards/frame/cards-frame';
import { DataTableStyleWrap } from '../../components/table/Style';
import { NoteCardWrap } from '../note/style';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSchedule, getScheduledData, scheduleTopic } from '../../redux/schedule/actionCreator';
import scheduleActions from '../../redux/schedule/actions';
import { Button, DatePicker, Form, Input, Modal, Select, Table } from 'antd';
import { UilSearch, UilTrash } from '@iconscout/react-unicons';
import { useForm } from 'antd/lib/form/Form';
import { FormValidationWrap } from '../forms/overview/Style';
import { teacherSubjectsDataRead, topicsOfSubjectDataRead } from '../../redux/teacherSubjectsUnits/actionCreator';
import moment from 'moment';
import { Popover } from '../../components/popup/popup';

const { scheduleTopicSuccess, deleteScheduleSuccess } = scheduleActions

export default function Schedules() {

    const { TopicScheduledData, isSchedulesLoading, TeacherSubjects, isSubjectsLoading, TopicsData, isTopicsLoading, scheduleTopicRes, isTopicScheduling, delScheduleRes, isScheduleDeleting } = useSelector((state) => {
        return {
            TopicScheduledData: state.TopicScheduledData.data,
            isSchedulesLoading: state.StudentSubjects.loading,
            TeacherSubjects: state.TeacherSubjects.data,
            isSubjectsLoading: state.TeacherSubjects.loading,
            TopicsData: state.TopicsOfSubject.data,
            isTopicsLoading: state.TopicsOfSubject.loading,
            scheduleTopicRes: state.scheduleTopicRes.data,
            isTopicScheduling: state.scheduleTopicRes.loading,
            delScheduleRes: state.delScheduleRes.data,
            isScheduleDeleting: state.delScheduleRes.loading,
        }
    })

    const dispatch = useDispatch();
    const [addform] = useForm()

    useEffect(() => {
        if (dispatch) {
            if (TeacherSubjects.length === 0) {
                dispatch(teacherSubjectsDataRead());
            }
            loadSchedules()
        }
    }, [dispatch]);

    const loadSchedules = () => {
        dispatch(getScheduledData())
    }

    const columnsSort = [
        {
            title: 'S. No.',
            dataIndex: 'sno',
            align: 'center'
        },
        {
            title: 'Subject',
            dataIndex: 'subjectName',
        },
        {
            title: 'Topic',
            dataIndex: 'topicName',
        },
        {
            title: 'Deadline',
            dataIndex: 'deadline',
            key: 'deadline',
        },
        {
            title: 'Actions',
            render: (text, record) => (
                <div className='table-actions'>
                    <Popover action="click" placement="top" title={'Are you sure?'} content={<div className=''><Button className='mr-5 text-danger' onClick={() => dispatch(deleteSchedule(record.id))} disabled={isScheduleDeleting}>Yes</Button> <Button>No</Button></div>}>
                        <Button
                            key="del-schedule"
                            type="link"
                            to="#"
                            shape="circle"
                        >
                            <UilTrash size={18} />
                        </Button>
                    </Popover>
                </div>
            ),
        }
    ];



    const [subjectId, setSubjectId] = useState(0)
    const [topicId, setTopicId] = useState(0)
    const [tableData, setTableData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [newScheduleModalVisible, setNewScheduleModalVisible] = useState(false)

    useEffect(() => {
        if (TopicScheduledData !== null && TopicScheduledData.Error === false && TopicScheduledData.payload.length !== 0) {
            const data = TopicScheduledData.payload.map((obj, index) => {
                return {
                    sno: index + 1,
                    key: obj.id,
                    id: obj.id,
                    topicId: obj.topic?.id,
                    topicName: obj.topic?.name,
                    topicStatus: obj.topic?.topicStatus,
                    subjectId: obj.topic?.subject?.id,
                    subjectName: obj.topic?.subject?.name,
                    deadline: obj.deadline
                }
            })

            setTableData(data)
            setFilteredData(data)
        }
        else {
            setTableData([])
            setFilteredData([])
        }
    }, [TopicScheduledData])

    const searchkeys = ['topicName', 'deadline']
    const searchStudent = (e) => {
        const { value } = e.currentTarget;
        const searchData = tableData.length !== 0 && tableData.filter((obj) => searchkeys.some((key) => obj[key].toLowerCase().includes(value.toLowerCase())))
        setFilteredData(searchData)
    };


    useEffect(() => {
        if ((parseInt(subjectId) !== 0) && (subjectId !== undefined)) {
            dispatch(topicsOfSubjectDataRead(subjectId));
        }
    }, [subjectId])

    const AddNewSchedule = (values) => {
        let YYYY = new Date(values.deadline).getFullYear()
        let MM = (new Date(values.deadline).getMonth() + 1).toString().padStart(2, 0)
        let DD = new Date(values.deadline).getDate().toString().padStart(2, 0)
        const dataToPost = {
            topicId: values.topicId,
            cohortId: values.cohortId,
            deadline: DD + '-' + MM + '-' + YYYY
        }
        dispatch(scheduleTopic(dataToPost))
    }

    useEffect(() => {
        if (scheduleTopicRes !== null && scheduleTopicRes.Error === false) {
            setNewScheduleModalVisible(false)
            dispatch(scheduleTopicSuccess(null))
            loadSchedules()
            addform.resetFields()
        }
    }, [scheduleTopicRes])

    useEffect(() => {
        if (delScheduleRes !== null && delScheduleRes.Error === false) {
            dispatch(deleteScheduleSuccess(null))
            loadSchedules()
        }
    }, [delScheduleRes])

    const disabledDate = current => {
        // Disable dates before today
        return current && current < moment().startOf('day');
    };

    return (
        <div>
            <Main>
                <PageHeader className="ninjadash-page-header-main" title="Schedules"
                    buttons={
                        [
                            <Button id="shcedules-page-add_schedule-button" onClick={() => setNewScheduleModalVisible(true)} key="NewSchedule" type="primary" size="default">
                                New Schedule
                            </Button>,
                        ]
                    }
                />
                <Cards headless>
                    <NoteCardWrap>
                        <DataTableStyleWrap>
                            {/* <div className="ninjadash-datatable-filter">
                            <div className="ninjadash-datatable-filter__left">
                                <div className="ninjadash-datatable-filter__input">
                                    <span className="label">Subject:</span>
                                    <Select id="monitor_select-subject" style={{ width: 250 }} className="ninjadash-data-status" placeholder="select" onChange={(e) => { setSubjectId(e); }} loading={isSubjectsLoading}>
                                        {
                                            StudentSubjects.length !== 0 && StudentSubjects.sort((a, b) => a.name > b.name ? 1 : -1).map((subjectObj, subjectIndex) =>
                                                <Select.Option value={subjectObj.subjectId} key={subjectIndex}>{subjectObj.subjectname} - {subjectObj.cohortname}</Select.Option>
                                            )
                                        }
                                    </Select>
                                </div>
                                <div className="ninjadash-datatable-filter__action">
                                    <Button id="monitor_get-button" type="primary" size="small" onClick={() => { loadPerformance() }}>
                                        Get
                                    </Button>
                                </div>
                            </div>
                        </div> */}
                            {
                                isSchedulesLoading ?
                                    <div className="custom-spin">
                                        <CustomSpinner />
                                    </div>
                                    :
                                    <div>
                                        <div className='d-flex justify-content-between mb-15 p-0 align-center-v'>

                                            <div className="d-flex align-center-v" style={{ marginLeft: 'auto' }}>
                                                <Input id="monitor_search-input" className='' onChange={searchStudent} size="default" placeholder="Search here..." prefix={<UilSearch />} />
                                            </div>
                                        </div>
                                        <Table
                                            className="table-responsive"
                                            pagination={{
                                                defaultPageSize: 15,
                                                total: filteredData.length,
                                                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                            }}
                                            columns={columnsSort}
                                            dataSource={filteredData}
                                            tableLayout='auto'
                                            size='middle'
                                        />
                                    </div>
                            }
                        </DataTableStyleWrap>
                    </NoteCardWrap>
                </Cards>

                <Modal open={newScheduleModalVisible} title={`New Schedule`} footer={false} onCancel={() => { setNewScheduleModalVisible(false); addform.resetFields() }} maskClosable={false}>
                    <FormValidationWrap>
                        <Form name="sDash_validation-form" form={addform} layout="vertical" onFinish={AddNewSchedule}>
                            <Form.Item
                                name="subjectId"
                                rules={[{ required: true, message: 'Subject required!' }]}
                                label="Subject"
                            >
                                <Select allowClear placeholder="Select" loading={isSubjectsLoading} onChange={(e) => { setSubjectId(e); setTopicId(0); addform.setFieldsValue({ "topicId": undefined, "cohortId": undefined }) }}>
                                    {
                                        TeacherSubjects.length !== 0 && TeacherSubjects.map((sub, index) =>
                                            <Select.Option key={index} value={sub.subjectId}>{sub.subjectname}</Select.Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="topicId"
                                rules={[{ required: true, message: 'Topic required!' }]}
                                label="Topic"
                            >
                                <Select allowClear placeholder="Select" loading={isTopicsLoading} onChange={(e) => { setTopicId(e); addform.setFieldsValue({ "cohortId": undefined }) }}>
                                    {
                                        subjectId !== 0 && TopicsData.length !== 0 && TopicsData.filter((obj) => obj.topicstatus === "QUESTIONS FREEZED").map((topic, index) =>
                                            <Select.Option key={index} value={topic.id}>{topic.name}</Select.Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="cohortId"
                                rules={[{ required: true, message: 'Cohort required!' }]}
                                label="Cohort"
                            >
                                <Select allowClear placeholder="Select" loading={isSubjectsLoading}>
                                    {
                                        topicId !== 0 && TeacherSubjects.length !== 0 && TeacherSubjects.filter((obj) => obj.subjectId == parseInt(subjectId))[0].cohorts.map((cohort, index) =>
                                            <Select.Option key={index} value={cohort.id}>{cohort.name}</Select.Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="deadline"
                                rules={[{ required: true, message: 'Deadline required!' }]}
                                label="Deadline"
                            >
                                <DatePicker
                                    disabledDate={disabledDate}
                                    format="YYYY-MM-DD"
                                    placeholder="YYYY-MM-DD"
                                    allowClear
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>

                            <div className=' d-flex justify-content-end mb-20'>
                                <Button htmlType="reset" type="secondary" className='mr-10' >
                                    Reset
                                </Button>
                                <Button htmlType="submit" type="primary" disabled={isTopicScheduling} >
                                    {isTopicScheduling ? 'Scheduling...' : 'Schedule'}
                                </Button>
                            </div>
                        </Form>
                    </FormValidationWrap>
                </Modal>

            </Main>
        </div>
    )
}
