import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import UilBell from '@iconscout/react-unicons/icons/uil-bell';
import UilFileAlt from '@iconscout/react-unicons/icons/uil-file-alt';
import UilDollarSign from '@iconscout/react-unicons/icons/uil-dollar-sign';
import UilSetting from '@iconscout/react-unicons/icons/uil-setting';
import UilSignout from '@iconscout/react-unicons/icons/uil-signout';
import UilUser from '@iconscout/react-unicons/icons/uil-user';
import UilUsersAlt from '@iconscout/react-unicons/icons/uil-users-alt';
import UilWrench from '@iconscout/react-unicons/icons/uil-wrench';
import { Avatar } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import Message from './Message';
import Notification from './Notification';
import Search from './Search';
import Settings from './settings';
import { logOut } from '../../../redux/authentication/actionCreator';

import { Dropdown } from '../../dropdown/dropdown';
import Heading from '../../heading/heading';
import { Popover } from '../../popup/popup';
import { getItem } from '../../../utility/localStorageControl';

const AuthInfo = React.memo(() => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    flag: 'en',
  });
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { flag } = state;

  const SignOut = (e) => {
    e.preventDefault();

    dispatch(logOut(() => navigate('/')));
  };

  const { currentWeek, currentWeekLoading } = useSelector((state) => {
    return {
      currentWeek: state.currentWeek.data,
      currentWeekLoading: state.currentWeek.loading
    }
  })

  const getNumberFromString = (weekname) => {
    const arr = weekname.split('-')
    return arr[1];
  }

  const username = getItem('profilename')
  const collegeName = getItem('collegeName')  
  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img src={require('../../../static/img/avatar/chat-auth.png')} alt="" />
          <figcaption>
            <Heading as="h5">{username ? (username[0].toUpperCase() + username.slice(1).toLowerCase()) : 'Unknown'}</Heading>
            <p>{collegeName}</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          {/* <li>
            <Link to="#">
              <UilUser /> Profile
            </Link>
          </li> */}
          {/* <li>
            <Link to="#">
              <UilUsersAlt /> Activity
            </Link>
          </li> */}
          {/* <li>
            <Link to="#">
              <UilFileAlt /> My Reports
            </Link>
          </li> */}
          {/* <li>
            <Link to="#">
              <UilBell /> Help
            </Link>
          </li> */}
          {/* <li>
            <Link to="change-password">
              <UilWrench /> Password
            </Link>
          </li> */}
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <UilSignout /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = (value, e) => {
    e.preventDefault();
    setState({
      ...state,
      flag: value,
    });
    i18n.changeLanguage(value);
  };

  const country = (
    <NavAuth>
      <Link onClick={(e) => onFlagChangeHandle('en', e)} to="#">
        <img src={require('../../../static/img/flag/en.png')} alt="" />
        <span>English</span>
      </Link>
      <Link onClick={(e) => onFlagChangeHandle('esp', e)} to="#">
        <img src={require('../../../static/img/flag/esp.png')} alt="" />
        <span>Spanish</span>
      </Link>
      <Link onClick={(e) => onFlagChangeHandle('ar', e)} to="#">
        <img src={require('../../../static/img/flag/ar.png')} alt="" />
        <span>Arabic</span>
      </Link>
    </NavAuth>
  );

  return (
    <InfoWraper>
      {/* <div style={{ color: '#2D5D85', fontSize: '13px', textAlign: 'right', backgroundColor: '#fcefeb', paddingLeft: '10px', paddingRight: '10px', marginRight: '10px' }}>
        Please note all data created is temporary and some features may not be available yet.
      </div> */}
      <div className='fontsize-14' style={{ fontWeight: 500, textAlign: 'right', paddingLeft: '10px', paddingRight: '10px', marginRight: '10px' }}>
        {
          currentWeek !== null &&
          <span>Current : <span style={{ color: '#2D5D85' }}>{currentWeek.name}</span></span>
        }
      </div>
      {/* <div>Week-2</div> */}
      {/* <Search /> */}
      {/* <Message />
      <Notification />
      <Settings /> */}
      {/* <div className="ninjadash-nav-actions__item ninjadash-nav-actions__language">
        <Dropdown placement="bottomRight" content={country} trigger="click">
          <Link to="#" className="ninjadash-nav-action-link">
            <img src={require(`../../../static/img/flag/${flag}.png`)} alt="" />
          </Link>
        </Dropdown>
      </div> */}
      <div className="ninjadash-nav-actions__item ninjadash-nav-actions__author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="ninjadash-nav-action-link">
            <Avatar icon={<UilUser />} />
            <span className="ninjadash-nav-actions__author--name">{username ? (username[0].toUpperCase() + username.slice(1).toLowerCase()) : 'Unknown'}</span>
            <UilAngleDown />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
});

export default AuthInfo;
