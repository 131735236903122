import react from 'react'
import {
    UilUpload,
    UilSchedule,
    UilCircle
} from '@iconscout/react-unicons';
import './Customtabs.css'
import { NavLink } from 'react-router-dom';

function TabsSlider() {

    const tabsBox = document.querySelector(".tabs-box"),
        allTabs = tabsBox && tabsBox.querySelectorAll(".tab"),
        arrowIcons = document.querySelectorAll(".icon i");

    let isDragging = false;

    const handleIcons = (scrollVal) => {
        let maxScrollableWidth = tabsBox.scrollWidth - tabsBox.clientWidth;
        arrowIcons[0].parentElement.style.display = scrollVal <= 0 ? "none" : "flex";
        arrowIcons[1].parentElement.style.display = maxScrollableWidth - scrollVal <= 1 ? "none" : "flex";
    }

    arrowIcons.forEach(icon => {
        icon.addEventListener("click", () => {
            // if clicked icon is left, reduce 350 from tabsBox scrollLeft else add
            let scrollWidth = tabsBox.scrollLeft += icon.id === "left" ? -340 : 340;
            handleIcons(scrollWidth);
        });
    });

    allTabs && allTabs.forEach(tab => {
        tab.addEventListener("click", () => {
            tabsBox.querySelector(".active").classList.remove("active");
            tab.classList.add("active");
        });
    });

    const dragging = (e) => {
        if (!isDragging) return;
        tabsBox && tabsBox.classList.add("dragging");
        tabsBox.scrollLeft -= e.movementX;
        handleIcons(tabsBox.scrollLeft)
    }

    const dragStop = () => {
        isDragging = false;
        tabsBox && tabsBox.classList.remove("dragging");
    }

    tabsBox && tabsBox.addEventListener("mousedown", () => isDragging = true);
    tabsBox && tabsBox.addEventListener("mousemove", dragging);
    document.addEventListener("mouseup", dragStop);

    return (
        <div className="wrapper">
            <div className="icon">Left</div>
            <ul className='tabs-box'>
                <li className='tab'>
                    <NavLink to="upload" className="">
                        <UilUpload />Upload
                    </NavLink>
                </li>
                <li className='tab'>
                    <NavLink to="review/content" className="">
                        <UilCircle />Review Content
                    </NavLink>
                </li>
                <li className='tab'>
                    <NavLink to="generate/questions" className="">
                        <UilCircle />Generate Questions
                    </NavLink>
                </li>
                <li className='tab'>
                    <NavLink to="review/questions" className="">
                        <UilCircle />Review Questions
                    </NavLink>
                </li>
                <li className='tab'>
                    <NavLink to="generate/quiz" className="">
                        <UilCircle />Generate Quiz
                    </NavLink>
                </li>
                <li className='tab'>
                    <NavLink to="review/quiz" className="">
                        <UilCircle />Review Quiz
                    </NavLink>
                </li>
                <li className='tab'>
                    <NavLink to="view/q&a" className="">
                        <UilCircle />View Q&A
                    </NavLink>
                </li>
                <li className='tab'>
                    <NavLink to="view/mcqs" className="">
                        <UilCircle />View Quiz
                    </NavLink>
                </li>
                <li className='tab'>
                    <NavLink to="freezeschedule" className="">
                        <UilSchedule />Freeze & Schedule
                    </NavLink>
                </li>
            </ul>
            <div className="icon">Right</div>
        </div>
    );
}

export default TabsSlider