import react, { useState } from 'react'
import { Link, NavLink, Navigate, Route, Routes, useParams } from 'react-router-dom';
import UploadContent from './UploadContent';
import ReviewContent from './ReviewContent';
import GenerateQuestions from './GenerateQuestions';
import ReviewQuestions from './ReviewQuestions';
import GenerateQuiz from './GenerateQuiz';
import ReviewQuiz from './ReviewQuiz';
import ViewQAQuiz from './ViewQAQuiz';
import NotFound from '../pages/404';
import { AddUser } from './styles/tablestyle';
import { Cards } from '../../components/cards/frame/cards-frame';
import { NoteCardWrap, NoteCardWrapWithHeader } from '../note/style';
import {
    UilUpload,
    UilSchedule,
    UilCircle,
    UilAngleLeftB,
    UilAngleRightB
} from '@iconscout/react-unicons';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getTopicById } from '../../redux/topics/actionCreator';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import FreezeSchedule from './FreezeSchedule';
import ViewQuestions from './ViewQuestions';
import ViewQuiz from './ViewQuiz';
import Programs from './Programs';
import { Select } from 'antd';
import ViewPdfFile from './ViewPdfFile';
import ViewTxtFile from './ViewTxtFile';

const { Option } = Select

function PagesView({ customWidth, fullScreen }) {

    const { topicId } = useParams()

    const dispatch = useDispatch();

    const tabsBox = document.querySelector(".tabs-box");
    const arrowIcons = document.querySelectorAll(".icon .i");

    const { topic, isTopicLoading, UploadRes, TopicStatus } = useSelector((state) => {
        return {
            topic: state.singleTopic.data,
            isTopicLoading: state.singleTopic.loading,
            UploadRes: state.UploadContentRes.data,
            TopicStatus: state.TopicStatus.data,
        };
    });

    // useEffect(() => {
    //     if (arrowIcons.length) {
    //         if (fullScreen === true) {
    //             arrowIcons[0].parentElement.style.display = "none"
    //             arrowIcons[1].parentElement.style.display = "none"
    //         }
    //         else {
    //             arrowIcons[0].parentElement.style.display = "flex"
    //             arrowIcons[1].parentElement.style.display = "flex"
    //         }
    //     }
    // })

    useEffect(() => {
        dispatch(getTopicById(parseInt(topicId)));
    }, [topicId]);

    useEffect(() => {
        if (TopicStatus !== null && TopicStatus.message === "updated") {
            dispatch(getTopicById(parseInt(topicId)));
        }
    }, [TopicStatus]);

    const handleIcons = (scrollVal) => {
        let maxScrollableWidth = tabsBox.scrollWidth - tabsBox.clientWidth;
        arrowIcons[0].parentElement.style.display = scrollVal <= 0 ? "flex" : "flex";
        arrowIcons[1].parentElement.style.display = maxScrollableWidth - scrollVal <= 1 ? "flex" : "flex";
    }
    arrowIcons && arrowIcons.forEach(icon => {
        icon.addEventListener("click", () => {
            // if clicked icon is left, reduce 350 from tabsBox scrollLeft else add            
            let scrollWidth = tabsBox && (tabsBox.scrollLeft += icon.id === "left" ? -240 : 240);
            handleIcons(scrollWidth);
        });
    });


    return (
        <div>
            <AddUser>
                <Cards title={
                    <div className="card-nav">
                        <div className="icon">
                            <UilAngleLeftB id="left" className="i" />
                        </div>
                        <ul className='tabs-box'>
                            <li>
                                <NavLink id="pagesview_upload-tab" to="upload" className="">
                                    Upload
                                </NavLink>
                            </li>
                            <li>
                                <NavLink id="pagesview_reviewcontent-tab" to="review/content" className="">
                                    Review Content
                                </NavLink>
                            </li>
                            <li>
                                <NavLink id="pagesview_generateqa-tab" to="generate/questions" className="">
                                    Generate Q&A
                                </NavLink>
                            </li>
                            <li>
                                <NavLink id="pagesview_reviewqa-tab" to="review/questions" className="">
                                    Review Q&A
                                </NavLink>
                            </li>
                            <li>
                                <NavLink id="pagesview_generatemcq-tab" to="generate/quiz" className="">
                                    Generate MCQ
                                </NavLink>
                            </li>
                            <li>
                                <NavLink id="pagesview_reviewmcq-tab" to="review/quiz" className="">
                                    Review MCQ
                                </NavLink>
                            </li>
                            <li>
                                <NavLink id="pagesview_programs-tab" to="programs" className="">
                                    Programs
                                </NavLink>
                            </li>
                            <li>
                                <NavLink id="pagesview_freezeschedule-tab" to="freezeschedule" className="">
                                    Freeze & Schedule
                                </NavLink>
                            </li>
                            {/* <li>
                                <NavLink to="view/q&a" className="">
                                    View Q&A
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="view/mcqs" className="">
                                    View MCQ
                                </NavLink>
                            </li> */}
                            <li className='p-0'>
                                <Select id="pagesview_select-view-tab" placeholder='View' className='view-select' >
                                    <Option value="MCQ">
                                        <NavLink id="pagesview_viewmcq-tab" to="view/mcqs" className="">
                                            MCQ
                                        </NavLink>
                                    </Option>
                                    <Option value="Q&A">
                                        <NavLink id="pagesview_viewqa-tab" to="view/q&a" className="">
                                            Q&A
                                        </NavLink>
                                    </Option>
                                    <Option value="PDF">
                                        <NavLink id="pagesview_viewpdf-tab" to="view/pdf" className="">
                                            PDF
                                        </NavLink>
                                    </Option>
                                    <Option value="TXT">
                                        <NavLink id="pagesview_viewtxt-tab" to="view/txt" className="">
                                            TXT
                                        </NavLink>
                                    </Option>
                                </Select>                                
                            </li>
                        </ul>
                        <div className="icon">
                            <UilAngleRightB id="right" className="i" />
                        </div>
                    </div>
                }>
                    <NoteCardWrapWithHeader style={customWidth} >
                        <h3 className='mb-20'>{topic !== null ? topic.name : ''}</h3>
                        {
                            isTopicLoading ?
                                <div className='custom-spin'>
                                    <CustomSpinner />
                                </div>
                                :
                                <div>
                                    <Routes>
                                        {/* <Route path="/" element={<Navigate to=
                                        {
                                            topic !== null && topic.status === "NOTHING" ? 'upload' :                                            
                                            topic !== null && topic.status === "CONTENT UPLOADED" ? 'review/content' :                                            
                                            topic !== null && topic.status === "CONTENT REVIEWED" ? 'generate/questions' :                                            
                                            topic !== null && topic.status === "QUESTIONS GENERATED" ? 'review/questions' :                                            
                                            topic !== null && topic.status === "QUESTIONS REVIEWED" ? 'generate/quiz' :                                            
                                            topic !== null && topic.status === "QUIZ GENERATED" ? 'review/quiz' :                                            
                                            topic !== null && topic.status === "QUIZ REVIEWED" ? 'freezeschedule' :  ''                                          
                                        }
                                        />} /> */}
                                        <Route path="/" element={<Navigate to="review/content" />} />
                                        <Route path="upload/*" element={<UploadContent customWidth={customWidth} topicStatus={topic !== null && topic.status} topicName={topic !== null && topic.name} topic={topic} />} />
                                        <Route path="review/content" element={<ReviewContent customWidth={customWidth} topicStatus={topic !== null && topic.status} topicName={topic !== null && topic.name} topic={topic} />} />
                                        <Route path="generate/questions" element={<GenerateQuestions customWidth={customWidth} topicStatus={topic !== null && topic.status} topicName={topic !== null && topic.name} topic={topic} />} />
                                        <Route path="review/questions" element={<ReviewQuestions customWidth={customWidth} topicStatus={topic !== null && topic.status} topicName={topic !== null && topic.name} topic={topic} />} />
                                        <Route path="generate/quiz" element={<GenerateQuiz customWidth={customWidth} topicStatus={topic !== null && topic.status} topicName={topic !== null && topic.name} topic={topic} />} />
                                        <Route path="review/quiz" element={<ReviewQuiz customWidth={customWidth} topicStatus={topic !== null && topic.status} topicName={topic !== null && topic.name} topic={topic} />} />
                                        <Route path="programs" element={<Programs customWidth={customWidth} topicStatus={topic !== null && topic.status} topicName={topic !== null && topic.name} testTopicID={topic !== null && topic.tesstopicId} topic={topic} />} />
                                        <Route path="freezeschedule" element={<FreezeSchedule customWidth={customWidth} topicStatus={topic !== null && topic.status} topicName={topic !== null && topic.name} topic={topic} />} />
                                        <Route path="view/q&a" element={<ViewQuestions customWidth={customWidth} topicStatus={topic !== null && topic.status} topicName={topic !== null && topic.name} topic={topic} />} />
                                        <Route path="view/mcqs" element={<ViewQuiz customWidth={customWidth} topicStatus={topic !== null && topic.status} topicName={topic !== null && topic.name} topic={topic} />} />
                                        <Route path="view/pdf" element={<ViewPdfFile customWidth={customWidth} topicStatus={topic !== null && topic.status} topicName={topic !== null && topic.name} topic={topic} />} />
                                        <Route path="view/txt" element={<ViewTxtFile customWidth={customWidth} topicStatus={topic !== null && topic.status} topicName={topic !== null && topic.name} topic={topic} />} />
                                        <Route path="*" element={<NotFound />} />
                                    </Routes >
                                </div>
                        }
                    </NoteCardWrapWithHeader>
                </Cards>
            </AddUser>
        </div >
    );
}

export default PagesView