import React, { Suspense, useEffect, useLayoutEffect, useState } from 'react'
import { FullScreenToggle, Main } from '../styled'
import { PageHeader } from '../../components/page-headers/page-headers'
import { Link, NavLink, Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import AngleLeftB from '@iconscout/react-unicons/icons/uil-angle-left-b';
import { Col, Result, Row, Tooltip } from 'antd';
import { Cards } from '../../components/cards/frame/cards-frame';
import { NoteCardWrap, NoteWrapper } from '../note/style';
import { FaqCategoryBox } from '../pages/style';
import Heading from '../../components/heading/heading';
import { FixedSidebar, SidebarWrap } from '../teacher/styles/sidebarstyle';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import SelectTopic from '../teacher/SelectTopic';
import UilAngleLeft from '@iconscout/react-unicons/icons/uil-angle-left';
import UilAngleRight from '@iconscout/react-unicons/icons/uil-angle-right';
import UilTimes from '@iconscout/react-unicons/icons/uil-times';
import { Button } from '../../components/buttons/buttons';
import { setItem } from '../../utility/localStorageControl';
import UilAngleDoubleLeft from '@iconscout/react-unicons/icons/uil-angle-double-left';
import UilAngleDoubleRight from '@iconscout/react-unicons/icons/uil-angle-double-right';
import TopicView from './TopicView';
import { useDispatch, useSelector } from 'react-redux';
import { getStudentTopicsById } from '../../redux/studentSubjectsUnits/actionCreator';
import SingleSubjectSummary from './SingleSubjectSummary';

export default function Topics() {

    const { subjectName, subjectId } = useParams()

    const navigate = useNavigate()
    const customWidth = { width: '96%', margin: 'auto' }

    const [fullScreen, setFullscreen] = useState(false)

    const [sideBarState, setSidebarState] = useState({
        responsive: 0,
        collapsed: false,
        visible: false,
        modalType: 'primary',
    });

    const { responsive, collapsed } = sideBarState;

    useLayoutEffect(() => {
        function updateSize() {
            const width = window.innerWidth;
            setSidebarState({ ...sideBarState, responsive: width });
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);


    const toggleCollapsed = () => {
        setSidebarState({
            ...sideBarState,
            collapsed: !collapsed,
        });
    };

    const dispatch = useDispatch();
    useEffect(() => {
        if (subjectId) {
            dispatch(getStudentTopicsById(parseInt(subjectId)));
        }
    }, [subjectId]);

    const { StudentTopics, isTopicsLoading } = useSelector((state) => {
        return {
            StudentTopics: state.StudentTopics.data,
            isTopicsLoading: state.StudentTopics.loading,
        };
    });

    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        if (StudentTopics) {
            let data = []

            StudentTopics.map((topic) =>
                data.push({
                    id: topic.id,
                    name: topic.name,
                    scheduleId: topic.topicschedules.length !== 0 ? topic.topicschedules[0].id : '',
                    deadline: topic.topicschedules.length !== 0 ? topic.topicschedules[0].deadline : '',
                    topicstatus: topic.topicstatus
                }))

            setFilteredData(data)
        }
    }, [StudentTopics])

    // const topics = [
    //     {
    //         "id": 1,
    //         "topic": "Introduction to Java",
    //         "description": "Overview of the Java programming language.",
    //         "level": "Beginner"
    //     },
    //     {
    //         "id": 2,
    //         "topic": "Java Basics",
    //         "description": "Fundamental concepts such as variables, data types, and operators.",
    //         "level": "Beginner"
    //     },
    //     {
    //         "id": 3,
    //         "topic": "Object-Oriented Programming in Java",
    //         "description": "Understanding classes, objects, inheritance, and polymorphism.",
    //         "level": "Intermediate"
    //     },
    //     {
    //         "id": 4,
    //         "topic": "Exception Handling",
    //         "description": "Dealing with exceptions and error handling in Java.",
    //         "level": "Intermediate"
    //     },
    //     {
    //         "id": 5,
    //         "topic": "Java Collections Framework",
    //         "description": "Working with collections like lists, sets, and maps.",
    //         "level": "Intermediate"
    //     },
    //     {
    //         "id": 6,
    //         "topic": "Multithreading in Java",
    //         "description": "Concurrency and parallel programming concepts.",
    //         "level": "Advanced"
    //     },
    //     {
    //         "id": 7,
    //         "topic": "Java Database Connectivity (JDBC)",
    //         "description": "Connecting Java applications to databases.",
    //         "level": "Advanced"
    //     },
    //     {
    //         "id": 8,
    //         "topic": "Java Enterprise Edition (Java EE)",
    //         "description": "Building enterprise-level applications with Java EE technologies.",
    //         "level": "Advanced"
    //     }
    // ]


    return (
        <div>
            <Main>
                {/* <div className='back-button d-inline'>
                    <Link to="/student/subjects"> Back to subjects</Link>
                </div> */}
                <PageHeader className="ninjadash-page-header-main" title={<div className='d-flex align-center-v'><AngleLeftB size={24} className="cursor-pointer" onClick={() => navigate('/student/subjects')} /> &nbsp;Topics of {subjectName}</div>} />
                <NoteWrapper>
                    {
                        isTopicsLoading ?
                            <div className='spin'>
                                <CustomSpinner Message={'Loading Topics...'}/>
                            </div>
                            :
                            filteredData.length === 0 ?
                                <Row gutter={25}>
                                    <Col xs={24}>
                                        <Cards headless>
                                            <NoteCardWrap className='spin'>
                                                <Result
                                                    status='warning'
                                                    title="Topics not found"
                                                    subTitle="Please select a subject from the top menu to proceed."
                                                />
                                            </NoteCardWrap >
                                        </Cards>
                                    </Col>
                                </Row>
                                :
                                <Row gutter={25}>
                                    <Col xxl={fullScreen ? 0 : 6} lg={fullScreen ? 0 : 6} md={fullScreen ? 0 : 8} xs={24}>
                                        {responsive <= 767 && (
                                            <div className="sidebar-trier-wrap text-center">
                                                <Button id="topicview_sidebar-trigger-button" type="link" className="sidebar-trigger" style={{ marginTop: 0 }} onClick={toggleCollapsed}>
                                                    {collapsed ? <UilAngleLeft /> : <UilAngleRight />}
                                                </Button>
                                            </div>
                                        )}
                                        {responsive > 767 ? (
                                            <div className="sidebar-card">
                                                <Cards headless>
                                                    <div className="note-sidebar-bottom">
                                                        <FaqCategoryBox>
                                                            {/* <Heading as='h3' className='text-center'>Topics ({filteredData.length})</Heading> */}
                                                            <span className='fontsize-12 d-flex justify-content-center mb-10'>({filteredData.length}) Topics Found</span>
                                                            <ul className="ninjadash-taskApp-sidebar__nav">
                                                                <li>
                                                                    <NavLink to={`single-subject-summary`}>
                                                                        <span className="nav-text">
                                                                            <Row gutter={15} className='d-flex'>
                                                                                <Col style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: `${responsive > 1435 ? '220px' : ((responsive <= 1435 && responsive > 1275) || (responsive <= 991 && responsive > 936)) ? '180px' : ((responsive <= 1275 && responsive > 1165) || (responsive <= 936 && responsive > 876)) ? '180px' : ((responsive <= 1165 && responsive > 1130) || (responsive <= 876 && responsive > 846)) ? '150px' : ((responsive <= 1130 && responsive > 1090) || (responsive <= 846 && responsive > 816)) ? '150px' : ((responsive <= 1090 && responsive > 1045) || (responsive <= 816 && responsive > 786)) ? '150px' : ((responsive <= 1045 && responsive > 991) || (responsive <= 786 && responsive > 767)) && '140px'}` }}>
                                                                                    <Tooltip title='Subject Summary' placement="left">
                                                                                        Subject Summary
                                                                                    </Tooltip>
                                                                                </Col>
                                                                            </Row>
                                                                        </span>
                                                                    </NavLink>
                                                                </li>
                                                                {
                                                                    filteredData.length === 0 ? <div className='text-center mt-25' style={{ fontSize: '18px' }}>Topics Not Found</div> : filteredData.map((topicObj, index) =>
                                                                        <li key={index}>
                                                                            <NavLink className={topicObj.status === "QUESTIONS FREZZED" ? 'success' : 'primary'} to={`topic/${topicObj.id}/${topicObj.name}`}
                                                                                onClick={() => setItem('activetopic', index)}>
                                                                                <span className="nav-text">
                                                                                    <Row gutter={15} className='d-flex'>
                                                                                        <Col style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: `${responsive > 1435 ? '220px' : ((responsive <= 1435 && responsive > 1275) || (responsive <= 991 && responsive > 936)) ? '180px' : ((responsive <= 1275 && responsive > 1165) || (responsive <= 936 && responsive > 876)) ? '180px' : ((responsive <= 1165 && responsive > 1130) || (responsive <= 876 && responsive > 846)) ? '150px' : ((responsive <= 1130 && responsive > 1090) || (responsive <= 846 && responsive > 816)) ? '150px' : ((responsive <= 1090 && responsive > 1045) || (responsive <= 816 && responsive > 786)) ? '150px' : ((responsive <= 1045 && responsive > 991) || (responsive <= 786 && responsive > 767)) && '140px'}` }}>
                                                                                            <Tooltip title={topicObj.name} placement="left">
                                                                                                {topicObj.name} <br /> {topicObj.deadline !== "" && <span className='fontsize-12 status text-danger'>Deadline: {topicObj.deadline}</span>}
                                                                                            </Tooltip>
                                                                                        </Col>
                                                                                        <Col style={{ width: '40px' }}>
                                                                                            {/* <Tooltip title={`${topicObj.questions.easy + topicObj.questions.medium + topicObj.questions.hard} questions are generated in ${topicObj.name}`} placement="left">
                                                                                            ({topicObj.questions.easy + topicObj.questions.medium + topicObj.questions.hard})
                                                                                        </Tooltip> */}
                                                                                        </Col>
                                                                                    </Row>
                                                                                </span>
                                                                            </NavLink>
                                                                        </li>
                                                                    )
                                                                }
                                                            </ul>
                                                        </FaqCategoryBox>
                                                    </div>
                                                </Cards>
                                            </div>
                                        ) : (
                                            <FixedSidebar className={collapsed ? 'show' : 'hide'}>
                                                <Link id="topicview_sidebar-close-button" to="#" type="link" className="trigger-close" onClick={toggleCollapsed}>
                                                    <UilTimes />
                                                </Link>
                                                <SidebarWrap className="mb-30">
                                                    <div className="ninjadash-taskApp-sidebar">
                                                        <Heading as='h3' className='text-center'>Topics ({filteredData.length})</Heading>
                                                        <FaqCategoryBox>
                                                            <ul className="ninjadash-taskApp-sidebar__nav" style={{ paddingLeft: '10px', lineHeight: '18px' }}>
                                                                <li>
                                                                    <NavLink to={`single-subject-summary`}>
                                                                        <span className="nav-text">
                                                                            <Row gutter={15} className='d-flex'>
                                                                                <Col style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: `${responsive > 1435 ? '220px' : ((responsive <= 1435 && responsive > 1275) || (responsive <= 991 && responsive > 936)) ? '180px' : ((responsive <= 1275 && responsive > 1165) || (responsive <= 936 && responsive > 876)) ? '180px' : ((responsive <= 1165 && responsive > 1130) || (responsive <= 876 && responsive > 846)) ? '150px' : ((responsive <= 1130 && responsive > 1090) || (responsive <= 846 && responsive > 816)) ? '150px' : ((responsive <= 1090 && responsive > 1045) || (responsive <= 816 && responsive > 786)) ? '150px' : ((responsive <= 1045 && responsive > 991) || (responsive <= 786 && responsive > 767)) && '140px'}` }}>
                                                                                    Subject Summary
                                                                                </Col>
                                                                            </Row>
                                                                        </span>
                                                                    </NavLink>
                                                                </li>
                                                                {
                                                                    filteredData.length === 0 ? <div className='text-center mt-25' style={{ fontSize: '18px' }}>Topics Not Found</div> : filteredData.map((topicObj, index) =>
                                                                        <li key={index} className="ninjadash-taskApp-sidebar__nav--item">
                                                                            <NavLink className={topicObj.status === "QUESTIONS FREEZED" ? 'success' : 'primary'} to={`topic/${topicObj.id}/${topicObj.name}`}
                                                                                onClick={() => { setItem('activetopic', index); toggleCollapsed() }}>
                                                                                <span className="nav-item-text">
                                                                                    <Row gutter={15} className='d-flex'>
                                                                                        <Col title={topicObj.name} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '180px', width: '150px' }}>
                                                                                            {topicObj.name} <br /> {topicObj.deadline !== "" && <span className='fontsize-12 status text-danger'>Deadline: {topicObj.deadline}</span>}
                                                                                        </Col>
                                                                                        {/* <Col style={{ width: '40px' }}>({topicObj.questions.easy + topicObj.questions.medium + topicObj.questions.hard})</Col> */}
                                                                                    </Row>
                                                                                </span>
                                                                            </NavLink>
                                                                        </li>
                                                                    )
                                                                }
                                                            </ul>
                                                        </FaqCategoryBox>
                                                    </div>
                                                </SidebarWrap>
                                            </FixedSidebar>
                                        )}
                                    </Col>
                                    <Col xxl={fullScreen ? 24 : 18} lg={fullScreen ? 24 : 18} md={fullScreen ? 24 : 16} xs={24}>
                                        <Suspense
                                            fallback={
                                                <div className="spin">
                                                    <CustomSpinner />
                                                </div>
                                            }
                                        >
                                            {
                                                responsive > 767 &&
                                                <FullScreenToggle>
                                                    <div className=''>
                                                        {
                                                            fullScreen ?
                                                                <UilAngleDoubleRight id="topicview_fullscreen-toggle-right-button" onClick={() => setFullscreen(false)} className='cursor-pointer align-center-v' />
                                                                :
                                                                <UilAngleDoubleLeft id="topicview_fullscreen-toggle-left-button" onClick={() => setFullscreen(true)} className='cursor-pointer align-center-v' />
                                                        }
                                                    </div>
                                                </FullScreenToggle>
                                            }
                                            {
                                                filteredData.length !== 0 &&
                                                <Routes>
                                                    <Route path="single-subject-summary" element={<SingleSubjectSummary customWidth={customWidth} />} />
                                                    <Route path="/" element={<Navigate to="single-subject-summary" />} />
                                                    <Route path="topic/:topicId/:topicName/*" element={<TopicView customWidth={customWidth} fullScreen={fullScreen} topics={filteredData} />} />
                                                    <Route path="*" element={<SelectTopic />} />
                                                </Routes>
                                            }
                                        </Suspense>
                                    </Col>
                                </Row>
                    }
                </NoteWrapper>
            </Main>
        </div>
    )
}
