import React from 'react'
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Progress, Table } from 'antd';
import { BorderLessHeading, TableDefaultStyle } from '../../styled';
import { TopSellerWrap } from '../styles/dashboardStyle';

function SubjectProgress({ ProgressData }) {

    const columns = [
        {
            title: 'Subject',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Total Topics',
            dataIndex: 'totaltopics',
            key: 'totaltopics',
        },
        {
            title: 'Remaining',
            dataIndex: 'unfreezedtopics',
            key: 'unfreezedtopics',
        },
        {
            title: 'Freezed',
            dataIndex: 'FreezedTopics',
            key: 'FreezedTopics',
        },        
        {
            title: '',
            dataIndex: 'progress',
            key: 'progress',
        },
    ];

    const data = []

    ProgressData.length !== 0 && ProgressData.map((subject, index) => {
        const percentage = (subject.freezedtopics / subject.totaltopics) * 100;
        data.push({
            key:index,
            name: subject.subjectName,
            FreezedTopics: subject.freezedtopics,
            totaltopics: subject.totaltopics,
            unfreezedtopics: subject.unfreezedtopics,
            percentage: percentage.toFixed(0),
            progress: <Progress strokeWidth={10} type="circle" showInfo={false} width={25} percent={percentage.toFixed(0)} />,
        });
    });

    return (
        <div>
            <BorderLessHeading>
                <Cards
                    title="My Subjects"
                >
                    <TableDefaultStyle className="ninjadash-having-header-bg" style={{ minHeight: '294px' }}>
                        <TopSellerWrap>
                            <div className="table-bordered top-seller-table table-responsive">
                                <Table dataSource={data} columns={columns} pagination={false} />
                            </div>
                        </TopSellerWrap>
                    </TableDefaultStyle>
                </Cards>
            </BorderLessHeading>
        </div>
    )
}


export default SubjectProgress