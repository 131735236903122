const actions = {

  CHANGE_PASSWORD_BEGIN: 'CHANGE_PASSWORD_BEGIN',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERR: 'CHANGE_PASSWORD_ERR',


  changePasswordBegin: () => {
    return {
      type: actions.CHANGE_PASSWORD_BEGIN,
    };
  },

  changePasswordSuccess: (data) => {
    return {
      type: actions.CHANGE_PASSWORD_SUCCESS,
      data,
    };
  },

  changePasswordErr: (err) => {
    return {
      type: actions.CHANGE_PASSWORD_ERR,
      err,
    };
  },
};

export default actions;
