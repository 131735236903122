import { notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';

const addNotificationSuccess = () => {
  notification.success({
    message: 'A new global notice has been created',
  });
};

const addNotificationError = (err) => {
  const errorMessage = `Creating new global notice has been failed(${err})`;
  notification.error({
    message: errorMessage,
    duration: 0,
  });
};

const addcollegeNotificationSuccess = () => {
  notification.success({
    message: 'A new college notice has been created',
  });
};

const addcollegeNotificationError = (err) => {
  const errorMessage = `Creating new college notice has been failed(${err})`;
  notification.error({
    message: errorMessage,
    duration: 0,
  });
};

const updateNotificationSuccess = () => {
  notification.success({
    message: 'Notice has been updated',
  });
};

const updateNotificationError = (err) => {
  const errorMessage = `Updating notice has been failed(${err})`;
  notification.error({
    message: errorMessage,
    duration: 0,
  });
};


const {
  noticeAddBegin,
  noticeAddSuccess,
  noticeAddErr,

  noticecollegeAddBegin,
  noticecollegeAddSuccess,
  noticecollegeAddErr,

  noticeReadBegin,
  noticeReadSuccess,
  noticeReadErr,

  noticeCollegeReadBegin,
  noticeCollegeReadSuccess,
  noticeCollegeReadErr,

  noticeUpdateBegin,
  noticeUpdateSuccess,
  noticeUpdateErr,
} = actions;

const noticeDataSubmit = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(noticeAddBegin());
      const response = await DataService.post('/notice/globalcreate', data);
      const token = getItem('access_token');
      if (token && response.data.Error === false) {
        await dispatch(noticeAddSuccess(response.data));
        addNotificationSuccess();
      } else {
        if ( response.data.Error === true && response.data.statusCode === 401){
          notification.error({ message: response.data.message })
          dispatch(logOut());
        }
        throw response.data.message;
        // await dispatch(regulationAddErr('No Unauthorize access'));
        // addNotificationError('No Unauthorize access');
      }
    } catch (err) {
      await dispatch(noticeAddErr(err));
      addNotificationError(err);
    }
  };
};

const noticecollegeDataSubmit = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(noticecollegeAddBegin());
      const response = await DataService.post('/notice/clgcreate', data);
      const token = getItem('access_token');
      if (token && response.data.Error === false) {
        await dispatch(noticecollegeAddSuccess(response.data));
        addcollegeNotificationSuccess();
      } else {
        if ( response.data.Error === true && response.data.statusCode === 401){
          notification.error({ message: response.data.message })
          dispatch(logOut());
        }
        throw response.data.message;
        // await dispatch(regulationAddErr('No Unauthorize access'));
        // addNotificationError('No Unauthorize access');
      }
    } catch (err) {
      await dispatch(noticecollegeAddErr(err));
      addcollegeNotificationError(err);
    }
  };
};

const noticeDataRead = () => {
  return async (dispatch) => {
    try {
      await dispatch(noticeReadBegin());
      const response = await DataService.get(`/notice/globalmsg`);
      const token = getItem('access_token');

      if (token && response.status === 200 && response.data !== null && response.data.Error === false) {
        await dispatch(noticeReadSuccess(response.data.payload.length !== 0 ? response.data.payload[0] : null));
      } else {
        if (response.data.Error === true && response.data.statusCode === 401){
          notification.error({ message: response.data.message })
          dispatch(logOut())
        }
        throw response.data.message;
      }
    } catch (err) {
      await dispatch(noticeReadErr(err));
    }
  };
};

const noticeCollegeDataRead = () => {
  return async (dispatch) => {
    try {
      await dispatch(noticeCollegeReadBegin());
      const response = await DataService.get(`/notice/adminclgmsg`);
      const token = getItem('access_token');

      if (token && response.status === 200 && response.data !== null && response.data.Error === false) {
        await dispatch(noticeCollegeReadSuccess(response.data.payload));
      } else {
        if (response.data.Error === true && response.data.statusCode === 401){
          notification.error({ message: response.data.message })
          dispatch(logOut())
        }
        throw response.data.message;
      }
    } catch (err) {
      await dispatch(noticeCollegeReadErr(err));
    }
  };
};

const noticeDataUpdate = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(noticeUpdateBegin());
      const response = await DataService.put(`/notice/update`, data);
      const token = getItem('access_token');
      if(token && response.data.Error === false){
        await dispatch(noticeUpdateSuccess(response.data));
        updateNotificationSuccess();
      }else{
        if (response.data.Error === true && response.data.statusCode === 401){
          notification.error({ message: response.data.message })
          dispatch(logOut())
        }
        throw response.data.message;
        // await dispatch(regulationDataUpdate('No Unauthorize access'))
      }
    } catch (err) {
      await dispatch(noticeUpdateErr(err));
      updateNotificationError(err);
    }
  };
};



export {
  noticeDataRead,
  noticeDataSubmit,
  noticeDataUpdate,
  noticecollegeDataSubmit,
  noticeCollegeDataRead,
};

