import { message, notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';


const {
  getInterviewQuestionsBegin,
  getInterviewQuestionsSuccess,
  getInterviewQuestionsErr,

  saveInterviewBegin,
  saveInterviewSuccess,
  saveInterviewErr,

  skipInterviewBegin,
  skipInterviewSuccess,
  skipInterviewErr,

  skipandSubmitInterviewBegin,
  skipandSubmitInterviewSuccess,
  skipandSubmitInterviewErr,

  submitInterviewBegin,
  submitInterviewSuccess,
  submitInterviewErr,

  getInterviewByTopicBegin,
  getInterviewByTopicSuccess,
  getInterviewByTopicErr,

  getInterviewByScheduleBegin,
  getInterviewByScheduleSuccess,
  getInterviewByScheduleErr,

  getInterviewAttemptsBegin,
  getInterviewAttemptsSuccess,
  getInterviewAttemptsErr,

  quitortimeoutInterviewBegin,
  quitortimeoutInterviewSuccess,
  quitortimeoutInterviewErr,

  getStudentInterviewsBegin,
  getStudentInterviewsSuccess,
  getStudentInterviewsErr,

} = actions;


const GetInterviewQuestions = (postData) => {
  return async (dispatch) => {
    try {
      await dispatch(getInterviewQuestionsBegin());
      const query = await DataService.post(`/interview-master/create`, postData);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(getInterviewQuestionsSuccess(data));
      }
      else if (data !== null && data.Error === true) {
        message.error(data.message)
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          await dispatch(getInterviewQuestionsSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(getInterviewQuestionsSuccess(data));
        }
      }
      else {
        await dispatch(getInterviewQuestionsSuccess(null));
      }
    } catch (err) {
      await dispatch(getInterviewQuestionsErr(err));
    }
  };
};

const saveInterviewQuestion = (formData) => {
  return async (dispatch) => {
    try {
      await dispatch(saveInterviewBegin());
      const response = await DataService.fileUpload('/interview-attempts/singlesave', formData);
      const token = getItem('access_token');
      const data = response.data
      if (token && data !== null && data.Error === false) {
        await dispatch(saveInterviewSuccess(data));
      }
      else if (data !== null && data.Error === true) {
        message.error(data.message)
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          await dispatch(saveInterviewSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(saveInterviewSuccess(null));
        }
      }
      else {
        await dispatch(saveInterviewSuccess(null));
      }
    } catch (err) {
      await dispatch(saveInterviewErr(err));
    }
  };
};

const skipInterviewQuestion = (attemptId) => {
  return async (dispatch) => {
    try {
      await dispatch(skipInterviewBegin());
      const response = await DataService.put(`/interview-attempts/skip/${attemptId}`);
      const token = getItem('access_token');
      const data = response.data
      if (token && data !== null && data.Error === false) {
        await dispatch(skipInterviewSuccess(data));
      }
      else if (data !== null && data.Error === true) {
        message.error(data.message)
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          await dispatch(skipInterviewSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(skipInterviewSuccess(null));
        }
      }
      else {
        await dispatch(skipInterviewSuccess(null));
      }
    } catch (err) {
      await dispatch(skipInterviewErr(err));
    }
  };
};

const skipandSubmitInterview = (attemptId) => {
  return async (dispatch) => {
    try {
      await dispatch(skipandSubmitInterviewBegin());
      const response = await DataService.put(`/interview-attempts/skipandsubmit/${attemptId}`);
      const token = getItem('access_token');
      const data = response.data
      if (token && data !== null && data.Error === false) {
        await dispatch(skipandSubmitInterviewSuccess(data));
      }
      else if (data !== null && data.Error === true) {
        message.error(data.message)
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          await dispatch(skipandSubmitInterviewSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(skipandSubmitInterviewSuccess(null));
        }
      }
      else {
        await dispatch(skipandSubmitInterviewSuccess(null));
      }
    } catch (err) {
      await dispatch(skipandSubmitInterviewErr(err));
    }
  };
};

const submitInterview = (formData) => {
  return async (dispatch) => {
    try {
      await dispatch(submitInterviewBegin());
      const response = await DataService.fileUpload('/interview-attempts/submit', formData);
      const token = getItem('access_token');
      const data = response.data
      if (token && data !== null && data.Error === false) {
        await dispatch(submitInterviewSuccess(data));
      }
      else if (data !== null && data.Error === true) {
        message.error(data.message)
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          await dispatch(submitInterviewSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(submitInterviewSuccess(null));
        }
      }
      else {
        await dispatch(submitInterviewSuccess(null));
      }
    } catch (err) {
      await dispatch(submitInterviewErr(err));
    }
  };
};

const getInterviewsByTopicId = (topicId, cohortId) => {
  return async (dispatch) => {
    try {
      await dispatch(getInterviewByTopicBegin());
      const response = await DataService.get(`/interview-master/findbytopic/${topicId}/${cohortId}`);
      const token = getItem('access_token');
      const data = response.data
      if (token && data !== null && data.Error === false) {
        await dispatch(getInterviewByTopicSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        message.error(data.message)
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          await dispatch(getInterviewByTopicSuccess([]));
          dispatch(logOut())
        }
        else {
          await dispatch(getInterviewByTopicSuccess([]));
        }
      }
      else {
        await dispatch(getInterviewByTopicSuccess([]));
      }
    } catch (err) {
      await dispatch(getInterviewByTopicErr(err));
    }
  };
};

const getInterviewsBySchedule = (scheduleName) => {
  return async (dispatch) => {
    try {
      await dispatch(getInterviewByScheduleBegin());
      const response = await DataService.get(`/interview/${scheduleName}`);
      const token = getItem('access_token');
      const data = response.data
      if (token && data !== null && data.Error === false) {
        await dispatch(getInterviewByScheduleSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        message.error(data.message)
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          await dispatch(getInterviewByScheduleSuccess([]));
          dispatch(logOut())
        }
        else {
          await dispatch(getInterviewByScheduleSuccess([]));
        }
      }
      else {
        await dispatch(getInterviewByScheduleSuccess([]));
      }
    } catch (err) {
      await dispatch(getInterviewByScheduleErr(err));
    }
  };
};

const getInterviewAttemptsById = (interviewId) => {
  return async (dispatch) => {
    try {
      await dispatch(getInterviewAttemptsBegin());
      const response = await DataService.get(`/interview-attempts/findbyinterviewMr/${interviewId}`);
      const token = getItem('access_token');
      const data = response.data
      if (token && data !== null && data.Error === false) {
        await dispatch(getInterviewAttemptsSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        message.error(data.message)
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          await dispatch(getInterviewAttemptsSuccess([]));
          dispatch(logOut())
        }
        else {
          await dispatch(getInterviewAttemptsSuccess([]));
        }
      }
      else {
        await dispatch(getInterviewAttemptsSuccess([]));
      }
    } catch (err) {
      await dispatch(getInterviewAttemptsErr(err));
    }
  };
};


const QuitorTimeoutInterview = (interviewMrId, interviewStatus) => {
  return async (dispatch) => {
    try {
      await dispatch(quitortimeoutInterviewBegin());
      const response = await DataService.put(`/interview-master/quitortimeout/${interviewMrId}/${interviewStatus}`);
      const token = getItem('access_token');
      const data = response.data
      if (token && data !== null && data.Error === false) {
        await dispatch(quitortimeoutInterviewSuccess(data));
      }
      else if (data !== null && data.Error === true) {
        message.error(data.message)
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          await dispatch(quitortimeoutInterviewSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(quitortimeoutInterviewSuccess(null));
        }
      }
      else {
        await dispatch(quitortimeoutInterviewSuccess(null));
      }
    } catch (err) {
      await dispatch(quitortimeoutInterviewErr(err));
    }
  };
};

const GetStudentInterviews = () => {
  return async (dispatch) => {
    try {
      await dispatch(getStudentInterviewsBegin());
      const response = await DataService.get(`/interview-master/interviews`);
      const token = getItem('access_token');
      const data = response.data
      if (token && data !== null && data.Error === false) {
        await dispatch(getStudentInterviewsSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        message.error(data.message)
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          await dispatch(getStudentInterviewsSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(getStudentInterviewsSuccess(null));
        }
      }
      else {
        await dispatch(getStudentInterviewsSuccess(null));
      }
    } catch (err) {
      await dispatch(getStudentInterviewsErr(err));
    }
  };
};

export {
  submitInterview,
  GetInterviewQuestions,
  skipInterviewQuestion,
  saveInterviewQuestion,
  skipandSubmitInterview,
  getInterviewsByTopicId,
  getInterviewAttemptsById,
  QuitorTimeoutInterview,
  getInterviewsBySchedule,
  GetStudentInterviews
};
