const actions = {

  GET_LEADERBOARD_RESULTS_BEGIN: 'GET_LEADERBOARD_RESULTS_BEGIN',
  GET_LEADERBOARD_RESULTS_SUCCESS: 'GET_LEADERBOARD_RESULTS_SUCCESS',
  GET_LEADERBOARD_RESULTS_ERR: 'GET_LEADERBOARD_RESULTS_ERR',  

  getLeaderboardBegin: () => {
    return {
      type: actions.GET_LEADERBOARD_RESULTS_BEGIN,
    };
  },

  getLeaderboardSuccess: (data) => {
    return {
      type: actions.GET_LEADERBOARD_RESULTS_SUCCESS,
      data,
    };
  },

  getLeaderboardErr: (err) => {
    return {
      type: actions.GET_LEADERBOARD_RESULTS_ERR,
      err,
    };
  },

};

export default actions;
