import React, { useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { TopMenuStyle } from './Style';


function ReviewerMenu() {
  const path = '/reviewer';

  return (
    <TopMenuStyle>
      <div className="ninjadash-top-menu">
        <ul>
          <li className="">
            <NavLink to={`${path}`} >
              Dashboard
            </NavLink>
          </li>
          <li className="">
            <NavLink to={`${path}/subjects`} >
              Subjects
            </NavLink>
          </li>
          <li className="">
            <NavLink to={`${path}/schedules`} >
              Schedules
            </NavLink>
          </li>
          <li className="">
            <NavLink to={`${path}/interviews`} >
              Interviews
            </NavLink>
          </li>
          {/* <li className="">
            <NavLink to={`${path}/subjects`} >
              Subjects
            </NavLink>
          </li> */}
          {/* <li className="">
            <NavLink to={`${path}/performance`} >
              Performance
            </NavLink>
          </li> */}
        </ul>
      </div>
    </TopMenuStyle>
  );
}

export default ReviewerMenu;