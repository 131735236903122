import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import RecordPlugin from 'wavesurfer.js/dist/plugins/record.esm.js'

const AudioRecorder = () => {
    const wavesurferRef = useRef(null);
    const recordPluginRef = useRef(null);
    const micSelectRef = useRef(null);
    const recButtonRef = useRef(null);
    const pauseButtonRef = useRef(null);
    const progressRef = useRef(null);
    const [scrollingWaveform, setScrollingWaveform] = useState(false);

    useEffect(() => {

        createWaveSurfer();

        // return () => {
        //     if (wavesurferRef.current) {
        //         wavesurferRef.current.destroy();
        //     }
        // };
    }, [scrollingWaveform]);

    const createWaveSurfer = () => {
        if (wavesurferRef.current) {
            wavesurferRef.current.destroy();
        }

        wavesurferRef.current = WaveSurfer.create({
            container: '#mic',
            waveColor: 'rgb(200, 0, 200)',
            progressColor: 'rgb(100, 0, 100)',
        });

        recordPluginRef.current = wavesurferRef.current.registerPlugin(
            RecordPlugin.create({ scrollingWaveform, renderRecordedAudio: false })
        );

        recordPluginRef.current.on('record-end', (blob) => {
            const container = document.querySelector('#recordings');
            const recordedUrl = URL.createObjectURL(blob);

            const wavesurferRecorded = WaveSurfer.create({
                container,
                waveColor: 'rgb(200, 100, 0)',
                progressColor: 'rgb(100, 50, 0)',
                url: recordedUrl,
            });

            const button = container.appendChild(document.createElement('button'));
            button.textContent = 'Play';
            button.onclick = () => wavesurferRecorded.playPause();
            wavesurferRecorded.on('pause', () => (button.textContent = 'Play'));
            wavesurferRecorded.on('play', () => (button.textContent = 'Pause'));

            const link = container.appendChild(document.createElement('a'));
            Object.assign(link, {
                href: recordedUrl,
                download: `recording.${blob.type.split(';')[0].split('/')[1] || 'webm'}`,
                textContent: 'Download recording',
            });
        });

        pauseButtonRef.current.style.display = 'none';
        recButtonRef.current.textContent = 'Record';

        recordPluginRef.current.on('record-progress', (time) => {
            updateProgress(time);
        });
    };

    const updateProgress = (time) => {
        const formattedTime = [
            Math.floor((time % 3600000) / 60000),
            Math.floor((time % 60000) / 1000),
        ]
            .map((v) => (v < 10 ? '0' + v : v))
            .join(':');
        progressRef.current.textContent = formattedTime;
    };

    const handleRecord = () => {
        if (recordPluginRef.current.isRecording() || recordPluginRef.current.isPaused()) {
            recordPluginRef.current.stopRecording();
            recButtonRef.current.textContent = 'Record';
            pauseButtonRef.current.style.display = 'none';
            return;
        }

        recButtonRef.current.disabled = true;

        const deviceId = micSelectRef.current.value;
        recordPluginRef.current.startRecording({ deviceId }).then(() => {
            recButtonRef.current.textContent = 'Stop';
            recButtonRef.current.disabled = false;
            pauseButtonRef.current.style.display = 'inline';
        });
    };

    const handlePause = () => {
        if (recordPluginRef.current.isPaused()) {
            recordPluginRef.current.resumeRecording();
            pauseButtonRef.current.textContent = 'Pause';
            return;
        }

        recordPluginRef.current.pauseRecording();
        pauseButtonRef.current.textContent = 'Resume';
    };

    const handleMicSelectChange = (e) => {
        setScrollingWaveform(e.target.checked);
        createWaveSurfer();
    };

    return (
        <div>
            <h1 style={{ marginTop: 0 }}>Press Record to start recording 🎙️</h1>

            <p>
                📖 <a href="https://wavesurfer.xyz/docs/classes/plugins_record.RecordPlugin">Record plugin docs</a>
            </p>

            <button ref={recButtonRef} onClick={handleRecord}>
                Record
            </button>
            <button ref={pauseButtonRef} style={{ display: 'none' }} onClick={handlePause}>
                Pause
            </button>

            <select ref={micSelectRef} id="mic-select">
                <option value="" hidden>
                    Select mic
                </option>
            </select>
            <label style={{ display: 'inline-block' }}>
                <input type="checkbox" onChange={handleMicSelectChange} /> Scrolling waveform
            </label>
            <p ref={progressRef}>00:00</p>

            <div id="mic" style={{ border: '1px solid #ddd', borderRadius: '4px', marginTop: '1rem' }}></div>

            <div id="recordings" style={{ margin: '1rem 0' }}></div>
        </div>
    );
};

export default AudioRecorder;
