import actions from './actions';

const {

  TEACHERSSUBJECTS_READ_BEGIN,
  TEACHERSSUBJECTS_READ_SUCCESS,
  TEACHERSSUBJECTS_READ_ERR,

} = actions;

const initialState = {
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
};



const TEACHERSSUBJECTSReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    
    case TEACHERSSUBJECTS_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TEACHERSSUBJECTS_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TEACHERSSUBJECTS_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { TEACHERSSUBJECTSReducer};
