
import React, { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';
import CustomSpinner from '../../container/ui-elements/spinner/CustomSpinner';
import Interviews from '../../container/admin/Interviews';
import Schedules from '../../container/admin/Schedules';
import Sync from '../../container/admin/Sync';
import Evaluate from '../../container/admin/Evaluate';
import { getItem } from '../../utility/localStorageControl';

const DashboardComponent = lazy(() => import('../../container/admin/Dashboard'));
const CollegeComponent = lazy(() => import('../../container/admin/Colleges'));
const Cohorts = lazy(() => import('../../container/admin/Cohorts'));
const StudentComponent = lazy(() => import('../../container/admin/Students'));
const SubjectComponent = lazy(() => import('../../container/admin/Subjects'));
const TeachersComponent = lazy(() => import('../../container/admin/Teachers'));
const TopicsComponent = lazy(() => import('../../container/admin/Topics'));
const BadgesComponent = lazy(() => import('../../container/admin/Badges'));
const ChangePasswordComponent = lazy(() => import('../../container/admin/ChangePassword'));
const NoticeComponent = lazy(() => import('../../container/admin/Notice'));
const NotFound = lazy(() => import('../../container/pages/404'));

const Admin = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const username = getItem('username')
  return (
    <Suspense
      fallback={
        <div className="spin">
          <CustomSpinner />
        </div>
      }
    >
      <Routes>

        <Route path="/" element={<Navigate to="dashboard" />} />
        <Route index path="/dashboard" element={<DashboardComponent />} />
        <Route index path="/colleges" element={<CollegeComponent />} />
        <Route path="/cohorts" element={<Cohorts />} />
        <Route index path="/students" element={<StudentComponent />} />
        <Route index path="/subjects" element={<SubjectComponent />} />
        <Route index path="/teachers" element={<TeachersComponent />} />
        <Route index path="/topics" element={<TopicsComponent />} />
        <Route index path="/badges" element={<BadgesComponent />} />
        <Route path="/schedules" element={<Schedules />} />
        <Route index path="/interviews" element={<Interviews />} />
        <Route index path="/resetpassword" element={<ChangePasswordComponent />} />
        <Route index path="/notice" element={<NoticeComponent />} />
        {
          username == "sai6868" &&
          <Route index path="/evaluate" element={<Evaluate />} />
        }
        {/* <Route index path="/sync" element={<Sync />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);