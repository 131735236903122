import UilAngleLeft from '@iconscout/react-unicons/icons/uil-angle-left';
import UilAngleRight from '@iconscout/react-unicons/icons/uil-angle-right';
import UilSearchPlus from '@iconscout/react-unicons/icons/uil-search-plus';
import UilSearchMinus from '@iconscout/react-unicons/icons/uil-search-minus';
import UilDownloadAlt from '@iconscout/react-unicons/icons/uil-download-alt';
import React from 'react';
// import PDFPrinter from './PDFPrinter';
// https://codesandbox.io/s/pdf-reader-y457i?file=/src/components/ControlPanel.jsx
// https://codesandbox.io/examples/package/react-pdf

const PdfControlPanel = (props) => {
  const { file, pageNumber, numPages, setPageNumber, scale, setScale } = props;

  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === numPages;

  const firstPageClass = isFirstPage ? 'disabled' : 'clickable';
  const lastPageClass = isLastPage ? 'disabled' : 'clickable';

  const goToFirstPage = () => {
    if (!isFirstPage) setPageNumber(1);
  };
  const goToPreviousPage = () => {
    if (!isFirstPage) {
      setPageNumber(pageNumber - 1);
      window.scrollTo(0, 0)
    }
  };
  const goToNextPage = () => {
    if (!isLastPage) {
      setPageNumber(pageNumber + 1);
      window.scrollTo(0, 0)
    }
  };
  const goToLastPage = () => {
    if (!isLastPage) setPageNumber(numPages);
  };

  const onPageChange = (e) => {
    const { value } = e.target;
    setPageNumber(Number(value));
  };

  const isMinZoom = scale < 0.6;
  const isMaxZoom = scale >= 2.0;

  const zoomOutClass = isMinZoom ? 'disabled' : 'clickable';
  const zoomInClass = isMaxZoom ? 'disabled' : 'clickable';

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1);
  };

  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1);
  };

  return (
    <div className="pdf-control-panel d-flex align-center-v justify-content-around">
      <div className="d-flex justify-content-between align-center-v">
        <UilAngleLeft id="pdfcontrolpanel_left" onClick={goToPreviousPage} className="cursor-pointer" />
        <span>
          &nbsp;&nbsp;Page&nbsp;{pageNumber}&nbsp;of {numPages}&nbsp;&nbsp;
        </span>
        <UilAngleRight id="pdfcontrolpanel_right" onClick={goToNextPage} className="cursor-pointer" />
      </div>
      <div className="d-flex justify-content-between align-center-v">
        <UilSearchMinus id="pdfcontrolpanel_minus" onClick={zoomOut} className="cursor-pointer" />
        <span>&nbsp;&nbsp;{(scale * 100).toFixed()}%&nbsp;&nbsp;</span>
        <UilSearchPlus id="pdfcontrolpanel_plus" onClick={zoomIn} className="cursor-pointer" />
      </div>
      {/* <div >
        <a href={file} id='pdfcontrolpanel_download' target="_blank" rel="noreferrer" download={true} title="download">
          <UilDownloadAlt />
        </a>
      </div> */}
    </div>
  );
};

export default PdfControlPanel;
