const actions = {
  GET_ASSESSMENT_LOGINS_BEGIN: 'GET_ASSESSMENT_LOGINS_BEGIN',
  GET_ASSESSMENT_LOGINS_SUCCESS: 'GET_ASSESSMENT_LOGINS_SUCCESS',
  GET_ASSESSMENT_LOGINS_ERR: 'GET_ASSESSMENT_LOGINS_ERR',
  
  UPDATE_STUDENT_FLAG_BEGIN: 'UPDATE_STUDENT_FLAG_BEGIN',
  UPDATE_STUDENT_FLAG_SUCCESS: 'UPDATE_STUDENT_FLAG_SUCCESS',
  UPDATE_STUDENT_FLAG_ERR: 'UPDATE_STUDENT_FLAG_ERR',

  getAssessmentLoginsBegin: () => {
    return {
      type: actions.GET_ASSESSMENT_LOGINS_BEGIN,
    };
  },

  getAssessmentLoginsSuccess: (data) => {    
    return {
      type: actions.GET_ASSESSMENT_LOGINS_SUCCESS,
      data,
    };
  },

  getAssessmentLoginsErr: (err) => {
    return {
      type: actions.GET_ASSESSMENT_LOGINS_ERR,
      err,
    };
  },
  
  updateStudentFlagBegin: () => {
    return {
      type: actions.UPDATE_STUDENT_FLAG_BEGIN,
    };
  },

  updateStudentFlagSuccess: (data) => {    
    return {
      type: actions.UPDATE_STUDENT_FLAG_SUCCESS,
      data,
    };
  },

  updateStudentFlagErr: (err) => {
    return {
      type: actions.UPDATE_STUDENT_FLAG_ERR,
      err,
    };
  },
};

export default actions;
