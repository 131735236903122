
import React, { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import withReviewerLayout from '../../layout/withReviewerLayout';
import CustomSpinner from '../../container/ui-elements/spinner/CustomSpinner';
import Interviews from '../../container/admin/Interviews';
import Schedules from '../../container/admin/Schedules';
import Subjects from '../../container/reviewer/Subjects';
import Topics from '../../container/reviewer/Topics';

const DashboardComponent = lazy(() => import('../../container/admin/Dashboard'));
const CollegeComponent = lazy(() => import('../../container/admin/Colleges'));
const Cohorts = lazy(() => import('../../container/admin/Cohorts'));
const StudentComponent = lazy(() => import('../../container/admin/Students'));
const SubjectComponent = lazy(() => import('../../container/admin/Subjects'));
const TeachersComponent = lazy(() => import('../../container/admin/Teachers'));
const TopicsComponent = lazy(() => import('../../container/admin/Topics'));
const BadgesComponent = lazy(() => import('../../container/admin/Badges'));
const ChangePasswordComponent = lazy(() => import('../../container/admin/ChangePassword'));
const NoticeComponent = lazy(() => import('../../container/admin/Notice'));
const NotFound = lazy(() => import('../../container/pages/404'));

const Reviewer = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="spin">
          <CustomSpinner />
        </div>
      }
    >
      <Routes>

        <Route path="/" element={<Navigate to="dashboard" />} />
        <Route index path="/dashboard" element={<DashboardComponent />} />        
        <Route path="/subjects" element={<Subjects />} />
        <Route path="subjects/:subjectName/:subjectId/*" element={<Topics />} />        
        <Route path="/teachers" element={<TeachersComponent />} />
        <Route path="/topics" element={<TopicsComponent />} />        
        <Route path="/schedules" element={<Schedules />} />
        <Route path="/interviews" element={<Interviews />} />                
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default withReviewerLayout(Reviewer);