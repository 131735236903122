import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { ComposedChart, Line, Area, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, Label } from 'recharts';

const SubjectWisePerformanceBarChart = React.memo(({ data }) => {

  const [state, setState] = useState({
    responsive: 0,
  });
  const { responsive } = state;

  useLayoutEffect(() => {
    function updateSize() {
      const element = document.querySelector('.recharts-wrapper');
      const width =
        element !== null
          ? element.closest('.ant-card-body').clientWidth
          : document.querySelector('.ant-card-body').clientWidth;
      setState({ responsive: width });
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);


  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    if (data !== null && data.payload.length !== 0) {
      let mappedData = data.payload.map((obj) => {
        return {
          subject: obj.name,
          easyLabel: 'Easy',
          easyAttempts: obj.easyAttempts,
          EasyPercentage: obj.easyPercentage,
          easyPerformance: obj.easyPerformance,
          intermediateLabel: 'Intermediate',
          intermediateAttempts: obj.intermediateAttempts,
          IntermediatePercentage: obj.intermediatePercentage,
          intermediatePerformance: obj.intermediatePerformance,
          advancedLabel: 'Advanced',
          advancedAttempts: obj.advancedAttempts,
          AdvancedPercentage: obj.advancedPercentage,
          dvancedPerformance: obj.advancedPerformance,
          overallAttempts: obj.overallAttempts,
          OverallPercentage: obj.overallPercentage,
          overallPerformance: obj.overallPerformance,
        }
      })
      setFilteredData(mappedData)
    }
    else {
      setFilteredData([])
    }
  }, [data])


  return (
    <Cards title="Subject Wise Performance" size="large">
      <ComposedChart
        width={responsive >= 1270 ? responsive - 50 : (responsive > 991 && responsive < 1270) ? responsive - 50 : (responsive <= 991 && responsive > 667) ? responsive - 50 : (responsive <= 667 && responsive > 450) ? responsive - 50 : 310}
        height={300}
        className='m-auto'
        data={filteredData}
        margin={{
          top: 40,
          right: window.innerWidth <= 375 ? 34 : 40,
          left: window.innerWidth <= 375 ? -16 : 0,
          bottom: 10,
        }}
      >
        {/* <CartesianGrid stroke="#f5f5f5" /> */}
        <XAxis dataKey="subject" fontSize={12} />
        <YAxis />
        <Tooltip />
        <Legend fontSize={12} />
        <Bar dataKey="EasyPercentage" barSize={30} fill="#B3CEE5" background={{ fill: '#f9f9f9' }} >
          <LabelList
            dataKey="easyLabel"
            position="top"
            angle={270}
            offset={23}
            fill="#2D5D85"
            fontSize={10}
          />
          <LabelList
            dataKey="EasyPercentage"
            position="top"
            angle={0}
            offset={2}
            fill="#2D5D85"
            fontSize={10}
          />
        </Bar>
        <Bar dataKey="IntermediatePercentage" barSize={30} fill="#4C8CC2" background={{ fill: '#f9f9f9' }} >
          <LabelList
            dataKey="intermediateLabel"
            position="top"
            angle={270}
            offset={42}
            fill="#2D5D85"
            fontSize={10}
          />
          <LabelList
            dataKey="IntermediatePercentage"
            position="top"
            angle={0}
            offset={2}
            fill="#2D5D85"
            fontSize={10}
          />
        </Bar>
        <Bar dataKey="AdvancedPercentage" barSize={30} fill="#2D5D85" background={{ fill: '#f9f9f9' }} >
          <LabelList
            dataKey="advancedLabel"
            position="top"
            angle={270}
            offset={37}
            fill="#2D5D85"
            fontSize={10}
          />
          <LabelList
            dataKey="AdvancedPercentage"
            position="top"
            angle={0}
            offset={2}
            fill="#2D5D85"
            fontSize={10}
          />
        </Bar>
        {/* <Area type="monotone" dataKey="overallPercentage" fill="#8884d8" stroke="#8884d8" /> */}
        <Line type="monotone" dataKey="OverallPercentage" stroke="#2D5D85" />
      </ComposedChart>
    </Cards>
  );
});

export default SubjectWisePerformanceBarChart;
