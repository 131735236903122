import actions from './actions';

const {
  COHORT_ADD_BEGIN,
  COHORT_ADD_SUCCESS,
  COHORT_ADD_ERR,

  COHORT_UPDATE_BEGIN,
  COHORT_UPDATE_SUCCESS,
  COHORT_UPDATE_ERR,

  COHORT_READ_BEGIN,
  COHORT_READ_SUCCESS,
  COHORT_READ_ERR
} = actions;

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const addInitialState = {
  data: null,
  loading: false,
  error: null,
};

const updateInitialState = {
  data: null,
  loading: false,
  error: null,
};


const CohortReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case COHORT_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case COHORT_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case COHORT_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const CohortAddReducer = (state = addInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case COHORT_ADD_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case COHORT_ADD_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case COHORT_ADD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const CohortUpdateReducer = (state = updateInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case COHORT_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case COHORT_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case COHORT_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export { CohortReducer, CohortAddReducer, CohortUpdateReducer };
