import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../components/page-headers/page-headers';
import { BasicFormWrapper, Main } from '../styled';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Checkbox, Col, Form, Input, Modal, Pagination, Radio, Result, Row, Select, Tag, Tooltip, message, notification } from 'antd';
import Heading from '../../components/heading/heading';
import TextArea from 'antd/lib/input/TextArea';
import { WizardWrapper, WizardFour } from './styles/wizardstyle';
import { Steps } from '../../components/steps/steps';
import { ProjectSorting } from '../project/style';
import { Cards } from '../../components/cards/frame/cards-frame';
import { SelectWrapperStyle } from '../ui-elements/ui-elements-styled';
import { NoteCardWrap } from '../note/style';
import { EyeOutlined } from '@ant-design/icons';
import { getTopicById, FreezeTopicById } from '../../redux/topics/actionCreator';
import { Button } from '../../components/buttons/buttons';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { alertModal } from '../../components/modals/antd-modals';
import { QuizDataUpdate, QuizQuestionsDataRead, deleteQuiz } from '../../redux/generateQuiz/actionCreator';
import actions from '../../redux/generateQuiz/actions';
import { theme } from '../../config/theme/themeVariables';
import UilTrashAlt from '@iconscout/react-unicons/icons/uil-trash-alt'

import TopicStatusActions from '../../redux/topics/actions';
import TopicStatusId from './TopicStatusId';

const { Option } = Select;

const { generateQuizPostSuccess, QuizUpdateSuccess, deleteQuizSuccess } = actions
const { topicStatusUpdateSuccess } = TopicStatusActions;

function ReviewQuiz({ topicStatus, topic }) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { subjectId, unitId, topicId } = useParams()
  const [form] = Form.useForm();

  const [selectedChunk, setSelectedChunk] = useState(0);
  const [chunkContent, setChunkContent] = useState("");
  const [QAContent, setQAContent] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);
  const [selectedCategory, setCategory] = useState("Easy")
  const [selectedQuizIndex, setSelectedQuizIndex] = useState(0)
  const [changedQuestion, setChangedQuestion] = useState("")
  const [changedOptions, setChangedOptions] = useState("")
  const [questionId, setQuestionId] = useState(0)
  const [questionCount, setQuestionsCount] = useState(0)
  const [reviewCompletedModal, setReviewCompletedModal] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);

  const { topicData, isLoading, quizUpdateRes, isQuizUpdating, TopicStatus, TopicStatusLoading, DeleteQuizRes, isQuizDeleting } = useSelector((state) => {
    return {
      topicData: state.AllQuiz.data,
      isLoading: state.AllQuiz.loading,
      quizUpdateRes: state.quizUpdateRes.data,
      isQuizUpdating: state.quizUpdateRes.loading,
      TopicStatus: state.TopicStatus.data,
      TopicStatusLoading: state.TopicStatus.loading,
      DeleteQuizRes: state.DeleteQuizRes.data,
      isQuizDeleting: state.DeleteQuizRes.loading,
    };
  });


  useEffect(() => {
    dispatch(generateQuizPostSuccess(null))
    if (TopicStatusId(topicStatus) > 4 && TopicStatusId(topicStatus) < 7) {
      dispatch(QuizQuestionsDataRead(parseInt(topicId)));
    }
    setIsDirty(false)
  }, [topicId, quizUpdateRes, DeleteQuizRes]);

  useEffect(() => {
    if (DeleteQuizRes !== null && DeleteQuizRes.Error === false) {
      dispatch(getTopicById(parseInt(topicId)));
      dispatch(deleteQuizSuccess(null))
      setSelectedQuizIndex(0)
    }
  }, [DeleteQuizRes]);

  useEffect(() => {
    if (topicData !== null && topicData.quizReviewflag === true && topicData.status === "QUIZ GENERATED" && topicStatus === "QUIZ GENERATED") {
      // const datatoPost = {
      //   id: parseInt(topicId),
      //   status: "QUIZ REVIEWED"
      // }
      // dispatch(FreezeTopicById(datatoPost))
      dispatch(getTopicById(parseInt(topicId)));
    }
    else if (topicStatus === "QUIZ REVIEWED" && topicData !== null && topicData.quizReviewflag === true) {
      setReviewCompletedModal(true)
    }
    // else if (topicData !== null && topicData.quizReviewflag === false && topicStatus === "QUIZ REVIEWED") {
    //   // const datatoPost = {
    //   //   id: parseInt(topicId),
    //   //   status: "QUIZ GENERATED"
    //   // }
    //   // dispatch(FreezeTopicById(datatoPost))
    //   dispatch(getTopicById(parseInt(topicId)));
    // }
  }, [topicData]);


  const VerifyPrograms = () => {
    setReviewCompletedModal(false)
    navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/programs`)
  }


  const handleEyeIconClick = () => {
    setShowTextArea(!showTextArea);
  };

  const handleSelectChange = async (index) => {
    if (isDirty) {
      notification.warn({
        message: 'Changes occur in current chunk',
      });
    }
    else {
      setCategory('Easy');
      setSelectedQuizIndex(0);
      setSelectedChunk(index)
    }
  }


  const handleReset = () => {
    if (QAContent[selectedQuizIndex].question !== changedQuestion || QAContent[selectedQuizIndex].optionswithanswer !== JSON.stringify(changedOptions)) {
      setCancelModal(true);
    } else {
      setIsDirty(false);
    }
  };

  const handleDiscardChanges = () => {
    setChangedQuestion(QAContent[selectedQuizIndex].question);
    setChangedOptions(JSON.parse(QAContent[selectedQuizIndex].optionswithanswer));
    setCancelModal(false);
    setIsDirty(false);
  };

  const handleModalCancel = () => {
    setCancelModal(false);
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  const [filterLoading, setFilterLoading] = useState(false)
  useEffect(() => {
    parseOptions()
  }, [topicData, selectedChunk, selectedCategory])

  const parseOptions = async () => {
    setFilterLoading(true)
    if (topicData !== null && topicData.chunks.length !== 0 && topicData.chunks[selectedChunk].quizes.length !== 0) {
      let array = topicData.chunks[selectedChunk].quizes.filter(qObj => qObj.type.toLowerCase() === selectedCategory.toLowerCase())

      setQAContent(array)
      if (array.length !== 0 && selectedQuizIndex !== array.length) {
        setQuestionId(array[selectedQuizIndex].id)
        setChangedQuestion(array[selectedQuizIndex].question)
        const options = await JSON.parse(array[selectedQuizIndex].optionswithanswer)
        setChangedOptions(options)
      }

      setChunkContent(topicData.chunks[selectedChunk].content)
    }
    else {
      setQAContent([])
      setQuestionId(0)
      setChangedQuestion("")
      setChangedOptions("")
      setChunkContent(topicData !== null && topicData.chunks.length !== 0 && topicData.chunks[selectedChunk].content)
    }

    setFilterLoading(false)
  }

  const getSelectedQuestion = async (object, index) => {
    setQuestionId(object.id)
    setSelectedQuizIndex(index)
    setChangedQuestion(QAContent[index].question)
    const options = await JSON.parse(QAContent[index].optionswithanswer)
    setChangedOptions(options)
  }

  useEffect(() => {
    async function abc() {
      if (quizUpdateRes !== null && quizUpdateRes.message == "updated") {
        if (TopicStatusId(topicStatus) > 2) {
          dispatch(QuizQuestionsDataRead(parseInt(topicId)));
        }
        let array = topicData !== null && topicData.chunks.length !== 0 && topicData.chunks[selectedChunk].quizes.length !== 0 && topicData.chunks[selectedChunk].quizes.filter(qObj => qObj.type.toLowerCase() === selectedCategory.toLowerCase())
        if ((selectedQuizIndex < (array.length - 1)) && QAContent.length !== 0) {
          setSelectedQuizIndex(parseInt(selectedQuizIndex) + 1)
          setChangedQuestion(QAContent[selectedQuizIndex].question)
          const options = await JSON.parse(QAContent[selectedQuizIndex].optionswithanswer)
          setChangedOptions(options)
        }
        dispatch(QuizUpdateSuccess(null));
        setIsDirty(false)
      }
    }
    abc()
  }, [quizUpdateRes])

  const UpdateQuiz = () => {
    if (Object.keys(changedOptions).length === 5 && (changedQuestion !== "" || changedOptions.a !== "" || changedOptions.b !== "" || changedOptions.c !== "" || changedOptions.d !== "" || changedOptions.ans !== "")) {
      const data = {
        id: questionId,
        question: changedQuestion,
        optionswithanswer: JSON.stringify(changedOptions)
      }
      dispatch(QuizDataUpdate(data))
      setIsDirty(false)
    }
    else {
      message.warn("All fields are required")
    }
  }

  const showDeleteConfirm = () => {
    alertModal.confirm({
      title: `Do you want to delete this quiz?`,
      content: 'Quiz once deleted cannot be undone.',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        if (questionId !== 0) {
          dispatch(deleteQuiz(questionId, parseInt(topicId)));
          // dispatch(QuizQuestionsDataRead(parseInt(topicId)));
        }
      },
      onCancel() { },
    });
  };

  const generateQuiz = () => {
    dispatch(generateQuizPostSuccess(null))
    if (topicStatus !== "QUESTIONS REVIEWED") {
      const datatoPost = {
        id: parseInt(topicId),
        status: "QUESTIONS REVIEWED"
      }
      dispatch(FreezeTopicById(datatoPost))
    }
    else if (topicStatus === "QUESTIONS REVIEWED") {
      navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/generate/quiz`)
    }
  }

  useEffect(() => {
    if (TopicStatus !== null && TopicStatus.message === "updated" && topicStatus === "QUESTIONS REVIEWED") {
      navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/generate/quiz`)
      dispatch(topicStatusUpdateSuccess(null));
    }
  }, [TopicStatus])

  return (
    <>
      <Row gutter={25} justify="center">
        <Col lg={showTextArea ? 14 : 24} xs={24}>
          <NoteCardWrap>
            {
              topicStatus === "CONTENT FREEZED" ?
                <>
                  <Result
                    status="success"
                    title="Content Freezed"
                    subTitle ={`on ${topic !== null && new Date(topic.updatedon).toLocaleString()}`}
                  />
                </>
                :
                TopicStatusId(topicStatus) == 0 ?
                  <div className='' style={{ fontSize: '18px' }}>
                    <Result
                      status="warning"
                      title="Content not found"
                      extra={
                        <Button id="reviewmcq_uploadcontent-button" type="primary" key="upload" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/upload`)}>
                          Upload
                        </Button>
                      }
                    />
                  </div>
                  :
                  TopicStatusId(topicStatus) === 1 ?
                    <Result
                      status="warning"
                      title="Review Content"
                      extra={
                        <Button id="reviewmcq_reviewcontent-button" type="primary" key="navigateToReviewContent" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/review/content`)}>
                          Review
                        </Button>
                      }
                    />
                    :
                    TopicStatusId(topicStatus) == 2 ?
                      <Result
                        status="warning"
                        title="Content reviewed successfully"
                        extra={
                          <Button id="reviewmcq_generateqa-button" type="primary" key="upload" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/generate/questions`)}>
                            Generate Q & A
                          </Button>
                        }
                      />
                      :
                      TopicStatusId(topicStatus) == 3 ?
                        <Result
                          status="warning"
                          title="Questions and Answers generated"
                          extra={
                            <Button id="reviewmcq_reviewqa-button" type="primary" key="upload" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/review/questions`)}>
                              Review Q & A
                            </Button>
                          }
                        />
                        :
                        TopicStatusId(topicStatus) == 4 ?
                          <Result
                            status="warning"
                            title="MCQS not found"
                            extra={
                              <Button id="reviewmcq_generatemcq-button" type="primary" key="generateQuiz" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/generate/quiz`)}>
                                Generate MCQS
                              </Button>
                            }
                          />
                          :
                          TopicStatusId(topicStatus) === 7 ?
                            <div>
                              <Result
                                status='success'
                                title="Programs Verified"
                                extra={
                                  <Button id="reviewmcq_freezetopic-button" type="primary" key="freeze topic" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/freezeschedule`)}>
                                    Freeze Topic
                                  </Button>
                                }
                              />
                            </div>
                            :
                            isLoading ?
                              <div className="custom-spin">
                                <CustomSpinner />
                              </div>
                              :
                              <div>
                                {
                                  (topicData !== null && topicData.quizquestioncount === 0) && TopicStatusId(topicStatus) >= 4 ?
                                    <div className='' style={{ fontSize: '18px' }}>
                                      <Result
                                        status="warning"
                                        title="Quiz questions not found"
                                        extra={
                                          <Button id="reviewmcq_generatemcq-button1" type="primary" key="generateQuiz" onClick={() => generateQuiz()}>
                                            Generate MCQS
                                          </Button>
                                        }
                                      />
                                    </div>
                                    :
                                    < div >
                                      <div className='d-flex justify-content-between'>
                                        <SelectWrapperStyle>
                                          <Select id="reviewmcq_select-chunk" value={selectedChunk} style={{ width: 120 }} onChange={handleSelectChange} >
                                            {
                                              topicData !== null && topicData.chunks.length !== 0 && topicData.chunks.map((chunckObj, index) =>
                                                <Option key={index} value={index}>Chunk {index + 1}</Option>
                                              )
                                            }
                                          </Select>
                                          &nbsp;of {topicData !== null && topicData.chunks.length}
                                        </SelectWrapperStyle>
                                        {/* <ProjectSorting>
                                        <div className="project-sort-bar">
                                          <div className="project-sort-nav" style={{ boxShadow: '0px 0px 15px 0px #eee', borderRadius: '6px' }}>
                                            <nav>
                                              <ul>
                                                <li className={selectedCategory === 'Easy' ? 'active' : 'deactivate'}>
                                                  <Link onClick={() => { setCategory('Easy'); setSelectedQuizIndex(0) }} to="#">
                                                    Easy ({topicData !== null && topicData.chunks.length !== 0 && topicData.chunks[selectedChunk].quizes.length !== 0 ? topicData.chunks[selectedChunk].quizes.filter(qObj => qObj.type.toLowerCase() === 'easy').length : 0})
                                                  </Link>
                                                </li>
                                                <li className={selectedCategory === 'Medium' ? 'active' : 'deactivate'}>
                                                  <Link onClick={() => { setCategory('Medium'); setSelectedQuizIndex(0) }} to="#">
                                                    Medium ({topicData !== null && topicData.chunks.length !== 0 && topicData.chunks[selectedChunk].quizes.length !== 0 ? topicData.chunks[selectedChunk].quizes.filter(qObj => qObj.type.toLowerCase() === 'medium').length : 0})
                                                  </Link>
                                                </li>
                                                <li className={selectedCategory === 'Hard' ? 'active' : 'deactivate'}>
                                                  <Link onClick={() => { setCategory('Hard'); setSelectedQuizIndex(0) }} to="#">
                                                    Hard ({topicData !== null && topicData.chunks.length !== 0 && topicData.chunks[selectedChunk].quizes.length !== 0 ? topicData.chunks[selectedChunk].quizes.filter(qObj => qObj.type.toLowerCase() === 'hard').length : 0})
                                                  </Link>
                                                </li>
                                              </ul>
                                            </nav>
                                          </div>
                                        </div>
                                      </ProjectSorting> */}
                                      </div>
                                      <div>
                                        <div className='d-flex justify-content-between'>
                                          <h4>{selectedCategory} quiz</h4>
                                          <div className='taglist-wrap'>
                                            <Tag color="orange">Last saved : {QAContent.length !== 0 && selectedQuizIndex < QAContent.length && new Date(QAContent[selectedQuizIndex].updatedon).toLocaleString()}</Tag>
                                          </div>
                                        </div>
                                        <div className='mt-20 mb-20 d-flex justify-content-between'>
                                          <div>
                                            {
                                              topicData !== null && topicData.chunks.length !== 0 && topicData.chunks[selectedChunk].quizes.length !== 0 && topicData.chunks[selectedChunk].quizes.filter(qObj => qObj.type.toLowerCase() === selectedCategory.toLowerCase()).map((filteredData, index) =>
                                                <Button id="reviewmcq_select-mcq" className="mb-10" key={index} shape="circle" type={'white'} raised style={{ color: 'black', marginRight: 10, boxShadow: `${selectedQuizIndex === index ? `0px 0px 8px 1px ${theme['primary-color']}` : ''}` }} onClick={() => getSelectedQuestion(filteredData, index)}>
                                                  {filteredData.reviewflag === true && <Badge dot color={theme['success-color']} className='mr-3' />}{index + 1}
                                                </Button>
                                              )
                                            }
                                          </div>
                                          <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {
                                              topicData !== null && topicData.quizquestioncount !== 0 &&
                                              <Button id="reviewmcq_view-hide-content" type={'info'} transparented className="mr-3" onClick={handleEyeIconClick}>
                                                <Tooltip title={showTextArea ? 'Hide chunk content' : 'View chunk content'} placement="left">
                                                  <EyeOutlined style={{ fontSize: 20 }} />
                                                </Tooltip>
                                              </Button>
                                            }
                                            {
                                              QAContent.length !== 0 &&
                                              <Tooltip placement='left' title="Delete current quiz">
                                                <Button id="reviewmcq_delete-quiz" type="danger" transparented onClick={showDeleteConfirm} disabled={isQuizDeleting ? true : false}>
                                                  {isQuizDeleting ? 'Deleting' : <UilTrashAlt />}
                                                </Button>
                                              </Tooltip>
                                            }
                                          </div>
                                        </div>
                                      </div>
                                      <div className="">
                                        <Row justify="center">
                                          <Col xs={24}>
                                            <div className="create-account-form">
                                              {
                                                filterLoading === true ? "loading" :
                                                  <>
                                                    {
                                                      QAContent.length !== 0 ?
                                                        <MyForm id="reviewmcq_form" changedQuestion={changedQuestion} changedOptions={changedOptions} setChangedQuestion={setChangedQuestion} setChangedOptions={setChangedOptions} setIsDirty={setIsDirty} />
                                                        :
                                                        <h3 className='text-center mb-20 mt-20'>No Data Found</h3>

                                                    }
                                                  </>
                                              }
                                            </div>
                                          </Col>
                                        </Row>
                                        {
                                          QAContent.length !== 0 &&
                                          <div className='d-flex justify-content-between mb-20'>
                                            <div className='taglist-wrap'>

                                            </div>
                                            <div className=''>
                                              <Button id="reviewmcq_reset-button" htmlType="submit" type="white" outlined style={{ color: 'black', marginRight: '10px' }} onClick={handleReset} >
                                                Reset
                                              </Button>
                                              <Button id="reviewmcq_review-button" htmlType="submit" type="primary" onClick={UpdateQuiz} disabled={isQuizUpdating ? true : false}>
                                                {isQuizUpdating ? 'Please Wait...' : 'Review & Next'}
                                              </Button>
                                            </div>
                                          </div>
                                        }
                                      </div>
                                    </div>
                                }
                              </div>
            }
          </NoteCardWrap>
        </Col>
        {
          showTextArea && (
            <Col lg={10} xs={24}>
              <Cards headless>
                <h3>Content</h3>
                <NoteCardWrap>
                  <TextArea id="reviewmcq_content-textarea" rows={20} cols={10} value={chunkContent} readOnly className='mb-20' />
                </NoteCardWrap>
              </Cards>
            </Col>
          )
        }
      </Row>
      <Modal open={cancelModal} title="Discard Changes" onCancel={handleModalCancel} onOk={handleDiscardChanges} okText="Yes" cancelText="No">
        <h3>Do you want to DISCARD the changes?</h3>
      </Modal>
      <Modal open={reviewCompletedModal} title="Verify Programs" onCancel={() => setReviewCompletedModal(false)} onOk={() => VerifyPrograms()} okText="Yes" cancelText="No">
        <h4>All mcqs are reviewed successfully</h4>
        <h4>Do you want to verify programs?</h4>
      </Modal>
    </>
  );
}

export default ReviewQuiz;


const MyForm = ({ changedQuestion, changedOptions, setChangedQuestion, setChangedOptions, setIsDirty }) => {

  return (
    <>
      <div>
        <h4>Question</h4>
        <Input id="reviewmcq_question-input" placeholder="Question" value={changedQuestion} onChange={(e) => { setChangedQuestion(e.target.value); setIsDirty(true) }} />
      </div>
      <div className='mt-20 mb-20'>
        <h4>Answer</h4>
        <Select id="reviewmcq_change-option" value={changedOptions.ans} style={{ width: '100%' }} onChange={(e) => { setChangedOptions({ ...changedOptions, 'ans': e }); setIsDirty(true) }}  >
          {
            changedOptions !== null && Object.keys(changedOptions).length !== 0 && Object.keys(changedOptions).sort((a, b) => a > b ? 1 : -1).map(obj =>
              obj !== 'ans' &&
              <Option key={obj} value={`${obj}`}>{obj}</Option>
            )
          }
        </Select>
      </div>
      <div className='mt-20 mb-20'>
        <h4>Option a</h4>
        <Input id="reviewmcq_option-a-input" placeholder="Option" value={changedOptions.a} onChange={(e) => { setChangedOptions({ ...changedOptions, 'a': e.target.value }); setIsDirty(true) }} />
      </div>
      <div className='mt-20 mb-20'>
        <h4>Option b</h4>
        <Input id="reviewmcq_option-b-input" placeholder="Option" value={changedOptions.b} onChange={(e) => { setChangedOptions({ ...changedOptions, 'b': e.target.value }); setIsDirty(true) }} />
      </div>
      <div className='mt-20 mb-20'>
        <h4>Option c</h4>
        <Input id="reviewmcq_option-c-input" placeholder="Option" value={changedOptions.c} onChange={(e) => { setChangedOptions({ ...changedOptions, 'c': e.target.value }); setIsDirty(true) }} />
      </div>
      <div className='mt-20 mb-20'>
        <h4>Option d</h4>
        <Input id="reviewmcq_option-d-input" placeholder="Option" value={changedOptions.d} onChange={(e) => { setChangedOptions({ ...changedOptions, 'd': e.target.value }); setIsDirty(true) }} />
      </div>
    </>
  );
}
