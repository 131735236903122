import React from 'react'

export default function AppLogo({width}) {
    return (
        <div>
            {
                process.env.REACT_APP_PM_TYPE == "ACADEMIC" ?
                    <img width={width} src={require(`../static/img/icon/pm-academic-logo-text-new.png`)} alt="LOGO" style={{ mixBlendMode: 'darken' }} />
                    :
                    process.env.REACT_APP_PM_TYPE == "FINISHINGSCHOOL" &&
                    <img width={width} src={require(`../static/img/icon/pm-fs-logo-text-new.png`)} alt="LOGO" style={{ mixBlendMode: 'darken' }} />

            }
        </div>
    )
}
