const actions = {

  GET_SCHEDULE_WEEKS_BEGIN: 'GET_SCHEDULE_WEEKS_BEGIN',
  GET_SCHEDULE_WEEKS_SUCCESS: 'GET_SCHEDULE_WEEKS_SUCCESS',
  GET_SCHEDULE_WEEKS_ERR: 'GET_SCHEDULE_WEEKS_ERR',

  GET_SCHEDULED_DATA_BEGIN: 'GET_SCHEDULED_DATA_BEGIN',
  GET_SCHEDULED_DATA_SUCCESS: 'GET_SCHEDULED_DATA_SUCCESS',
  GET_SCHEDULED_DATA_ERR: 'GET_SCHEDULED_DATA_ERR',

  SCHEDULE_TOPIC_BEGIN: 'SCHEDULE_TOPIC_BEGIN',
  SCHEDULE_TOPIC_SUCCESS: 'SCHEDULE_TOPIC_SUCCESS',
  SCHEDULE_TOPIC_ERR: 'SCHEDULE_TOPIC_ERR',

  DELETE_SCHEDULE_BEGIN: 'DELETE_SCHEDULE_BEGIN',
  DELETE_SCHEDULE_SUCCESS: 'DELETE_SCHEDULE_SUCCESS',
  DELETE_SCHEDULE_ERR: 'DELETE_SCHEDULE_ERR',

  STUDENT_SCHEDULES_BEGIN: 'STUDENT_SCHEDULES_BEGIN',
  STUDENT_SCHEDULES_SUCCESS: 'STUDENT_SCHEDULES_SUCCESS',
  STUDENT_SCHEDULES_ERR: 'STUDENT_SCHEDULES_ERR',

  UPDATE_SCHEDULE_BEGIN: 'UPDATE_SCHEDULE_BEGIN',
  UPDATE_SCHEDULE_SUCCESS: 'UPDATE_SCHEDULE_SUCCESS',
  UPDATE_SCHEDULE_ERR: 'UPDATE_SCHEDULE_ERR',

  GET_CURRENT_WEEK_BEGIN: 'GET_CURRENT_WEEK_BEGIN',
  GET_CURRENT_WEEK_SUCCESS: 'GET_CURRENT_WEEK_SUCCESS',
  GET_CURRENT_WEEK_ERR: 'GET_CURRENT_WEEK_ERR',

  GET_REG_YRS_BEGIN: 'GET_REG_YRS_BEGIN',
  GET_REG_YRS_SUCCESS: 'GET_REG_YRS_SUCCESS',
  GET_REG_YRS_ERR: 'GET_REG_YRS_ERR',

  GET_NGROK_URL_BEGIN: 'GET_NGROK_URL_BEGIN',
  GET_NGROK_URL_SUCCESS: 'GET_NGROK_URL_SUCCESS',
  GET_NGROK_URL_ERR: 'GET_NGROK_URL_ERR',


  getScheduleBegin: () => {
    return {
      type: actions.GET_SCHEDULE_WEEKS_BEGIN,
    };
  },

  getScheduleSuccess: (data) => {
    return {
      type: actions.GET_SCHEDULE_WEEKS_SUCCESS,
      data,
    };
  },

  getScheduleErr: (err) => {
    return {
      type: actions.GET_SCHEDULE_WEEKS_ERR,
      err,
    };
  },

  getScheduledDataBegin: () => {
    return {
      type: actions.GET_SCHEDULED_DATA_BEGIN,
    };
  },

  getScheduledDataSuccess: (data) => {
    return {
      type: actions.GET_SCHEDULED_DATA_SUCCESS,
      data,
    };
  },

  getScheduledDataErr: (err) => {
    return {
      type: actions.GET_SCHEDULED_DATA_ERR,
      err,
    };
  },

  scheduleTopicBegin: () => {
    return {
      type: actions.SCHEDULE_TOPIC_BEGIN,
    };
  },

  scheduleTopicSuccess: (data) => {
    return {
      type: actions.SCHEDULE_TOPIC_SUCCESS,
      data,
    };
  },

  scheduleTopicErr: (err) => {
    return {
      type: actions.SCHEDULE_TOPIC_ERR,
      err,
    };
  },

  deleteScheduleBegin: () => {
    return {
      type: actions.DELETE_SCHEDULE_BEGIN,
    };
  },

  deleteScheduleSuccess: (data) => {
    return {
      type: actions.DELETE_SCHEDULE_SUCCESS,
      data,
    };
  },

  deleteScheduleErr: (err) => {
    return {
      type: actions.DELETE_SCHEDULE_ERR,
      err,
    };
  },

  getStudentSchedulesBegin: () => {
    return {
      type: actions.STUDENT_SCHEDULES_BEGIN,
    };
  },

  getStudentSchedulesSuccess: (data) => {
    return {
      type: actions.STUDENT_SCHEDULES_SUCCESS,
      data,
    };
  },

  getStudentSchedulesErr: (err) => {
    return {
      type: actions.STUDENT_SCHEDULES_ERR,
      err,
    };
  },

  updateScheduleBegin: () => {
    return {
      type: actions.UPDATE_SCHEDULE_BEGIN,
    };
  },

  updateScheduleSuccess: (data) => {
    return {
      type: actions.UPDATE_SCHEDULE_SUCCESS,
      data,
    };
  },

  updateScheduleErr: (err) => {
    return {
      type: actions.UPDATE_SCHEDULE_ERR,
      err,
    };
  },

  getCureentWeekBegin: () => {
    return {
      type: actions.GET_CURRENT_WEEK_BEGIN,
    };
  },

  getCureentWeekSuccess: (data) => {
    return {
      type: actions.GET_CURRENT_WEEK_SUCCESS,
      data,
    };
  },

  getCureentWeekErr: (err) => {
    return {
      type: actions.GET_CURRENT_WEEK_ERR,
      err,
    };
  },

  getregyrsemBegin: () => {
    return {
      type: actions.GET_REG_YRS_BEGIN,
    };
  },

  getregyrsemSuccess: (data) => {
    return {
      type: actions.GET_REG_YRS_SUCCESS,
      data,
    };
  },

  getregyrsemErr: (err) => {
    return {
      type: actions.GET_REG_YRS_ERR,
      err,
    };
  },

  getNgrokURLBegin: () => {
    return {
      type: actions.GET_NGROK_URL_BEGIN,
    };
  },

  getNgrokURLSuccess: (data) => {
    return {
      type: actions.GET_NGROK_URL_SUCCESS,
      data,
    };
  },

  getNgrokURLErr: (err) => {
    return {
      type: actions.GET_NGROK_URL_ERR,
      err,
    };
  },

};

export default actions;
