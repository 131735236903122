import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Step, Steps } from '../../components/steps/steps';
import { Button } from '../../components/buttons/buttons';
import { useSpeechRecognition } from 'react-speech-recognition';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Input, Result, Row, Tooltip, message } from 'antd';
import { GetInterviewQuestions, saveInterviewQuestion, skipInterviewQuestion, skipandSubmitInterview, submitInterview, QuitorTimeoutInterview } from '../../redux/interview/actionCreator';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import actions from '../../redux/interview/actions';
import { ActionWrapper, StepsStyle } from '../../components/steps/style';
import UilArrowRight from '@iconscout/react-unicons/icons/uil-arrow-right';
import UilStopwatch from '@iconscout/react-unicons/icons/uil-stopwatch';
import { ReactMic } from 'react-mic';
import jsPDF from 'jspdf';
import FontAwesome from 'react-fontawesome';
import { InterviewActions, QuestionsContainer } from '../styled';
import { ThunderboltOutlined } from '@ant-design/icons';
import { MailBox } from '../email/overview/style';
import RichTextEditor, { EditorValue } from 'react-rte';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import Sortable from 'sortablejs';
import CodeMirror from "@uiw/react-codemirror";
import UserPrompt from './UserPrompt';

const { saveInterviewSuccess, skipInterviewSuccess, getInterviewQuestionsSuccess, quitortimeoutInterviewSuccess, submitInterviewSuccess, skipandSubmitInterviewSuccess } = actions

export default function InterviewQuestions() {

    const { interviewName, subjectId, subjectName, topicName, topicId } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { questions, isQuestionsLoading, saveRes, isSaving, skipRes, isSkipping, submitRes, isSubmitting, isSkipandSubmitting, quitortimeoutRes, isQuittingorTimeout } = useSelector((state) => {
        return {
            questions: state.interviewQuestions.data,
            isQuestionsLoading: state.interviewQuestions.loading,
            saveRes: state.interviewSaveRes.data,
            isSaving: state.interviewSaveRes.loading,
            skipRes: state.interviewSkipRes.data,
            isSkipping: state.interviewSkipRes.loading,
            submitRes: state.submitInterviewRes.data || state.skipSubmitRes.data,
            isSubmitting: state.submitInterviewRes.loading,
            // skipSubmitRes: state.skipSubmitRes.data,
            isSkipandSubmitting: state.skipSubmitRes.loading,
            quitortimeoutRes: state.quitortimeoutRes.data,
            isQuittingorTimeout: state.quitortimeoutRes.loading,
        }
    })

    const microphoneRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const chunksRef = useRef([]);
    const audioRef = useRef(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [evaluationCurrentQuestionIndex, setEvaluationCurrentQuestionIndex] = useState(0);
    // const { transcript, listening, resetTranscript } = useSpeechRecognition();
    const [ansAudio, setAnsAudio] = useState(null)
    const [isListening, setIsListening] = useState(false);
    const [transcript, setTranscript] = useState('');
    // const [timeRemaining, setTimeRemaining] = useState(0);
    const jumbleRef = useRef(null);
    const [codeAns, setCodeAns] = useState('')
    const [debugCode, setDebugCode] = useState('')
    const [explainCodeAns, setExplainCodeAns] = useState('')
    const [theoryAns, setTheoryAns] = useState('')
    const [predictOutputAns, setPredictOutputAns] = useState('')
    const [userPrompt, setUserPrompt] = useState('')

    const GetQuestions = () => {
        // const ENUM = {
        //     'Easy': 'EASY',
        //     'Intermediate': 'INTERMEDIATE',
        //     'Advanced': 'ADVANCED',
        // }
        const postData = {
            // topicId: parseInt(topicId),
            // level: ENUM[interviewType]
            scheduleName: interviewName
        }
        dispatch(GetInterviewQuestions(postData))
    }

    useEffect(() => {
        dispatch(quitortimeoutInterviewSuccess(null))
        dispatch(submitInterviewSuccess(null))
        dispatch(skipandSubmitInterviewSuccess(null))
        GetQuestions()
    }, [interviewName])

    useEffect(() => {
        if (questions && questions.Error === false) {
            if (questions.payload.questions.length !== 0) {
                questions.payload.questions[currentQuestionIndex].questionType === "VIVA" &&
                    handleReadAudio(questions.payload.questions[currentQuestionIndex].audiolink)
            }
        }
        else if (questions && questions.Error === true) {
            navigate(`/student`)
        }
    }, [questions])


    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = SpeechRecognition ? new SpeechRecognition() : null;


    const onCodeAnswerChange = (value) => {
        setCodeAns(value);
    };

    const FormatPostData = async () => {
        // const codeAns = codeAnswer.value.toString('html')

        const filePath = '../static/audio/dummy.wav'
        const response = await fetch(filePath);
        const fileData = await response.blob();
        const dummyAudioBlob = new Blob([fileData], { type: 'audio/wav' });
        const currentQuestion = questions?.payload?.questions[currentQuestionIndex]
        if (currentQuestion) {
            const id = currentQuestion.interviewattemptsId
            let answer = ''
            let questionType = ''
            let audioFile = null
            let prompt = null

            if (currentQuestion.questionType === "VIVA" && ansAudio !== null) {
                answer = null
                questionType = "VIVA"
                audioFile = ansAudio
                prompt = null
            }
            else if (currentQuestion.questionType == "JUMBLED CODE" && ansAudio === null) {
                const jumbledCode = Array.from(jumbleRef.current.children)
                    .map((li) => li.textContent.replace(/^(?:.*?(\d+):\s*)?(.*)$/, '$2'))
                    .join('\n');

                answer = jumbledCode
                questionType = "JUMBLED CODE"
                audioFile = dummyAudioBlob
                prompt = userPrompt !== "" ? userPrompt : null
            }
            else if (currentQuestion.questionType == "DEBUG CODE" && ansAudio === null && debugCode !== '') {
                const debug_Code = debugCode.split('\n').filter(line => line.trim() !== '').join('\n');

                answer = debug_Code
                questionType = "DEBUG CODE"
                audioFile = dummyAudioBlob
                prompt = userPrompt !== "" ? userPrompt : null
            }
            else if (currentQuestion.questionType == "EXPLAIN CODE" && ansAudio === null && explainCodeAns !== '') {
                const explain_Ans = explainCodeAns.split('\n').filter(line => line.trim() !== '').join('\n');

                answer = explain_Ans
                questionType = "EXPLAIN CODE"
                audioFile = dummyAudioBlob
                prompt = userPrompt !== "" ? userPrompt : null
            }
            else if (currentQuestion.questionType == "PREDICT OUTPUT" && ansAudio === null && predictOutputAns !== '') {
                const predict_Ans = predictOutputAns.split('\n').filter(line => line.trim() !== '').join('\n');

                answer = predict_Ans
                questionType = "PREDICT OUTPUT"
                audioFile = dummyAudioBlob
                prompt = userPrompt !== "" ? userPrompt : null
            }
            else if (currentQuestion.questionType == "CODE" && ansAudio === null && codeAns !== "") {

                answer = codeAns
                questionType = "CODE"
                audioFile = dummyAudioBlob
                prompt = null
            }
            else if (currentQuestion.questionType == "THEORY" && ansAudio === null && theoryAns !== "") {

                answer = theoryAns
                questionType = "THEORY"
                audioFile = dummyAudioBlob
                prompt = userPrompt !== "" ? userPrompt : null
            }
            else {
                message.error('Answer not recorded')
                answer = ''
                questionType = ''
                audioFile = null
                prompt = null
                return null;
            }

            const postData = new FormData();
            postData.append("interviewAttemptId", parseInt(id))
            postData.append("answer", answer)
            postData.append("audiolink", '--')
            postData.append("audioFile", audioFile)
            postData.append("questionType", questionType)
            postData.append("userPrompt", prompt)

            return postData
        }
    }

    const Save = async () => {
        recognition.stop();
        mediaRecorderRef.current?.stop();
        const postData = await FormatPostData()
        if (postData)
            dispatch(saveInterviewQuestion(postData))
    }

    useEffect(() => {
        if (saveRes && saveRes.Error === false) {
            chunksRef.current = [];
            jumbleRef.current = null
            setAnsAudio(null)
            setTranscript('')
            setCodeAns('')
            setDebugCode('')
            setExplainCodeAns('')
            setTheoryAns('')
            setPredictOutputAns('')
            setUserPrompt('')
            GetQuestions()
            handleNext();
            dispatch(saveInterviewSuccess(null))
            // setCodeAns({ value: RichTextEditor.createEmptyValue() });
        }
        else if (saveRes && saveRes.Error === true) {
            message.error(saveRes.message)
        }
    }, [saveRes])

    const handleSkip = () => {
        setButtonClicked(true)
        if (audioRef.current) {
            audioRef.current.pause(); // Pause the current audio
            audioRef.current.currentTime = 0; // Reset playback to the beginning            
        }
        setIsListening(false)
        recognition.stop();
        mediaRecorderRef.current?.stop();
        const id = questions?.payload?.questions[currentQuestionIndex].interviewattemptsId
        dispatch(skipInterviewQuestion(id))
    }

    useEffect(() => {
        if (skipRes && skipRes.Error === false) {
            chunksRef.current = [];
            jumbleRef.current = null
            setAnsAudio(null)
            setTranscript('')
            setCodeAns('')
            setDebugCode('')
            setExplainCodeAns('')
            setTheoryAns('')
            setPredictOutputAns('')
            setUserPrompt('')
            GetQuestions()
            handleNext();
            dispatch(skipInterviewSuccess(null))
            // setCodeAns({ value: RichTextEditor.createEmptyValue() });
        } else if (skipRes && skipRes.Error === true) {
            message.error(skipRes.message)
        }
    }, [skipRes])

    const Submit = async () => {
        setIsListening(false)
        recognition.stop();
        mediaRecorderRef.current?.stop();
        const postData = await FormatPostData()
        if (postData)
            dispatch(submitInterview(postData))
    };

    useEffect(() => {
        if (submitRes && submitRes.Error === false) {
            chunksRef.current = [];
            jumbleRef.current = null
            setAnsAudio(null)
            setTranscript('')
            setCodeAns('')
            setDebugCode('')
            setExplainCodeAns('')
            setTheoryAns('')
            setPredictOutputAns('')
            setUserPrompt('')
            // setCodeAns({ value: RichTextEditor.createEmptyValue() });
            // dispatch(submitInterviewSuccess(null))
        }
        else if (submitRes && submitRes.Error === true) {
            message.error(submitRes.message)
        }
    }, [submitRes])

    const SkipandSubmit = () => {
        recognition.stop();
        mediaRecorderRef.current?.stop();
        const id = questions?.payload?.questions[currentQuestionIndex].interviewattemptsId
        dispatch(skipandSubmitInterview(id))
    };

    useEffect(() => {
        if (quitortimeoutRes && quitortimeoutRes.Error === false) {
            dispatch(getInterviewQuestionsSuccess(null))
        }
    }, [quitortimeoutRes])


    // const generatePDF = () => {
    //     if (submitRes && submitRes.Error === false) {
    //         const doc = new jsPDF();
    //         doc.setFont('Arial');
    //         let content = `\n\nSubject: ${subjectName}\nTopic: ${topicName}\n\n`;
    //         content += `\nTotal score : ${submitRes?.payload?.totalscore}`
    //         submitRes?.payload?.attempts.forEach((qa, index) => {
    //             content += `\n\nQuestion ${index + 1}\n`;
    //             content += `Status:${qa.status}\n\n`;
    //             content += `Q : ${qa.question}\n\n`;
    //             content += `Student Answer:\n${qa.answer || 'N/A'}\n\n`;
    //             content += `Evaluation Feedback1:\n${qa.evaluationfeedback1 || 'N/A'}\n\n`;
    //             content += `Evaluation Score1:${qa.evaluationscore1 || 'N/A'}\n\n`;
    //             content += `Evaluation Feedback2:\n${qa.evaluationfeedback2 || 'N/A'}\n\n`;
    //             content += `Evaluation Score2:${qa.evaluationscore2 || 'N/A'}\n`;
    //             content += `Score Awarded Question:${qa.avgscore || '0'}\n`;

    //         });
    //         const pageWidth = doc.internal.pageSize.getWidth();
    //         const margin = 10;
    //         let yPosition = margin;
    //         const lineHeight = 10;
    //         let lines = doc.splitTextToSize(content, pageWidth - 2 * margin);

    //         lines.forEach((line) => {
    //             if (yPosition + lineHeight > doc.internal.pageSize.getHeight() - margin) {
    //                 doc.addPage();
    //                 yPosition = margin;
    //             }
    //             doc.text(line, margin, yPosition);
    //             yPosition += lineHeight;
    //         });

    //         doc.save(`${interviewName}_answers.pdf`);
    //     }
    // };

    const RecordAudio = (isListening) => {
        if (isListening) {
            mediaRecorderRef.current?.stop();
            recognition.stop();
            // chunksRef.current.pop()
            chunksRef.current = []; // Clear the chunks array
        } else {
            microphoneRef.current?.classList.add("listening");

            navigator.mediaDevices
                .getUserMedia({ audio: true })
                .then((stream) => {
                    mediaRecorderRef.current = new MediaRecorder(stream, {
                        audioBitsPerSecond: 128000, // Set the desired bitrate (in bits per second)
                        mimeType: 'audio/webm;codecs=opus' // Specify the audio codec and container format
                    });

                    mediaRecorderRef.current.ondataavailable = (e) => {
                        if (e.data.size > 0) {
                            chunksRef.current.push(e.data);
                        }
                    };

                    mediaRecorderRef.current.onstop = () => {
                        const audioBlob = new Blob(chunksRef.current, {
                            type: "audio/wav", // WebM format
                        });
                        // downloadAudio(audioBlob, "speech.mp3");
                        setAnsAudio(audioBlob)
                    };

                    mediaRecorderRef.current.start();
                })
                .catch((err) => {
                    message.error("Error accessing microphone!")
                    console.error("Error accessing microphone:", err)
                });
        }
    };

    const toggleRecording = () => {

        if (audioRef.current) {
            audioRef.current.pause(); // Pause the current audio
            audioRef.current.currentTime = 0; // Reset playback to the beginning            
        }

        fetchInputDevices().then(async (res) => {
            if (res.Error === false) {
                try {
                    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                    setIsListening(prevState => !prevState)
                    RecordAudio(isListening)
                    stream.getTracks().forEach(track => track.stop());
                } catch (error) {
                    // If access is denied or an error occurs, update the state with the error
                    message.error('Permission denied. Please enable microphone access in your browser settings.');
                }
            }
            else if (res.Error === true) {
                message.error(res.message)
            }
        })
    }

    // Checking existance of microphone
    const fetchInputDevices = async () => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const inputDevices = devices.filter((device) => device.kind === 'audioinput');
            if (inputDevices.length === 0) {
                setIsListening(false);
                return { Error: true, message: 'Error accessing microphone!' }
            }
            else {
                return { Error: false, message: 'Device found!' }
            }
        } catch (error) {
            console.error('Error accessing microphone!:', error);
            return { Error: true, message: `Error accessing microphone + ${error}` }
        }
    };

    // useEffect(() => {
    //     if (isListening) {
    //         const updateTimer = () => {
    //             setQuestionTimer((prevTime) => {
    //                 const newSeconds = prevTime.seconds + 1;

    //                 if (newSeconds === 60) {
    //                     // If 60 seconds is reached, reset seconds to 0 and increment minutes
    //                     return { minutes: prevTime.minutes + 1, seconds: 0 };
    //                 }

    //                 return { ...prevTime, seconds: newSeconds };
    //             });
    //         };
    //         const intervalId = setInterval(updateTimer, 1000);
    //         return () => clearInterval(intervalId);
    //     }
    // }, [isListening]);

    useEffect(() => {
        if (!recognition) {
            message.error("Your browser doesn't support the Web Speech API. Please use Google Chrome.");
            console.error("Your browser doesn't support the Web Speech API. Please use Google Chrome.");
            return;
        }

        recognition.continuous = true;
        recognition.lang = 'en-IN';
        recognition.interimResults = true;

        let finalTranscript = '';

        recognition.onresult = (event) => {
            let interimTranscript = '';
            for (let i = event.resultIndex; i < event.results.length; ++i) {
                const transcriptionPiece = event.results[i][0].transcript;
                if (event.results[i].isFinal) {
                    finalTranscript += transcriptionPiece;
                } else {
                    interimTranscript += transcriptionPiece;
                }
            }
            // console.log(finalTranscript + interimTranscript)
            setTranscript(finalTranscript + interimTranscript);
        };

        recognition.onaudiostart = () => {
            // console.log('Audio started');
        };

        recognition.onaudioend = () => {
            // console.log('Conversion ended');
        };

        recognition.onend = () => {
            // console.log('Speech recognition ended');
            // mediaRecorderRef.current?.stop();                        
            // if (isListening) {
            //     recognition.start(); // Restart recognition if still listening
            // }
        };

        if (isListening) {
            recognition.start();
        } else {
            recognition.stop();
        }

        return () => {
            recognition.stop();
            // mediaRecorderRef.current?.stop();
        };
    }, [isListening]);


    const downloadAudio = (blob, filename) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const handleNext = () => {
        setIsListening(false)
        if (currentQuestionIndex + 1 < questions?.payload?.questions.length) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            questions.payload.questions[currentQuestionIndex + 1].questionType === "VIVA" &&
                handleReadAudio(questions?.payload?.questions[currentQuestionIndex + 1].audiolink)
        }
        // else {
        //     setShowSubmitPage(true);
        // }
    };

    const handleReadAudio = (audioSrc) => {
        setIsListening(false);

        if (audioRef.current) {
            // Pause and reset the current audio
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            audioRef.current.load(); // Reset the audio element
        }

        let audioPath = process.env.REACT_APP_API_ENDPOINT + audioSrc;
        audioRef.current = new Audio(audioPath);

        // Add an event listener to handle errors during playback
        audioRef.current.addEventListener('error', (error) => {
            message.error('Error during audio playback');
            // console.error('Error during audio playback:', error);
        });

        // Play the new audio
        audioRef.current.play().catch((error) => {
            // message.error('Error playing audio');
            // console.error('Error playing audio:', error);
        });
    };

    const handlePrevQuestion = () => {
        if (evaluationCurrentQuestionIndex + 1 !== 0) {
            setEvaluationCurrentQuestionIndex(evaluationCurrentQuestionIndex - 1);
        }
    }

    const handleNextQuestion = () => {
        if (evaluationCurrentQuestionIndex + 1 < submitRes?.payload?.attempts.length) {
            setEvaluationCurrentQuestionIndex(evaluationCurrentQuestionIndex + 1);
        }
    }

    const handleQuitInterview = () => {
        const interviewmasterId = questions?.payload?.interviewId
        dispatch(QuitorTimeoutInterview(interviewmasterId, 'QUIT'))
    }

    const QuitButton = () => {
        return (
            <Tooltip title={'Quit Interview'} placement='top'>
                <Button onClick={() => { handleQuitInterview() }} className='interview-quit-btn' disabled={isSaving || isSkipping || isQuittingorTimeout}>
                    {isQuittingorTimeout ? <FontAwesome name='spinner' className='spinNow' /> : 'Quit'}
                </Button>
            </Tooltip>
        )
    }

    const ReplayQuestionButton = () => {
        return (
            <Tooltip title={'Replay Question'} placement='top'>
                <Button
                    type={'primary'}
                    className='icon-btn'
                    disabled={isSaving || isSkipping || isListening || questions.payload.questions[currentQuestionIndex].questionType !== "VIVA"}
                    onClick={() =>
                        handleReadAudio(
                            questions?.payload?.questions[currentQuestionIndex].audiolink
                        )
                    }
                >
                    {/* Replay */}
                    <FontAwesome name="refresh" />
                </Button>
            </Tooltip>
        )
    }

    const MicrophoneButton = () => {
        return (
            <Tooltip title={isListening ? "Stop Answering" : "Start Answering"} placement='top'>
                <Button
                    type={'primary'}
                    onClick={() => { toggleRecording() }}
                    className='icon-btn answer-btn'
                    disabled={isSaving || isSkipping || isQuittingorTimeout || questions.payload.questions[currentQuestionIndex].questionType !== "VIVA"}>
                    {isListening && <div className="pulse-ring"></div>}
                    {
                        isListening ? <FontAwesome name="microphone" /> : <FontAwesome name="microphone-slash" />
                    }
                </Button>
            </Tooltip>
        )
    }

    const SkipSubmitButton = () => {
        return (
            <Button type={'primary'} className='icon-btn' onClick={SkipandSubmit} disabled={isSaving || isSkipping || isListening || isSkipandSubmitting || isSubmitting || isQuittingorTimeout || buttonClicked}>
                {isSkipandSubmitting ? <FontAwesome name='spinner' className='spinNow' /> : <FontAwesome name='forward' />}
            </Button>
        )
    }

    const SubmitButton = () => {
        return (
            <Tooltip title={(questions.payload.questions[currentQuestionIndex].questionType === "VIVA" && ansAudio === null) ? 'You must attempt the question before submitting.' : 'Submit Interview'} placement='top'>
                <Button
                    type={'primary'}
                    className='icon-btn last-question'
                    onClick={() =>
                        (questions.payload.questions[currentQuestionIndex].questionType === "VIVA" && ansAudio === null) ?
                            message.error('You must attempt the question before submitting.')
                            :
                            Submit()
                    }
                    disabled={(
                        questions.payload.questions[currentQuestionIndex].questionType === "VIVA" ? ansAudio === null :
                            questions.payload.questions[currentQuestionIndex].questionType === "CODE" ? codeAns == "" :
                                questions.payload.questions[currentQuestionIndex].questionType === "EXPLAIN CODE" ? explainCodeAns == "" :
                                    questions.payload.questions[currentQuestionIndex].questionType === "PREDICT OUTPUT" ? predictOutputAns == "" :
                                        questions.payload.questions[currentQuestionIndex].questionType === "THEORY" ? theoryAns == "" :
                                            questions.payload.questions[currentQuestionIndex].questionType === "DEBUG CODE" && debugCode == ""

                    ) ||
                        isSaving || isSkipping || isQuittingorTimeout || buttonClicked}
                >
                    {isSubmitting ? <FontAwesome name='spinner' className='spinNow' /> : <FontAwesome name='save' />}
                </Button>
            </Tooltip>
        )
    }

    const [buttonClicked, setButtonClicked] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setButtonClicked(false)
        }, 2000);
    }, [buttonClicked]);

    const SaveButton = () => {
        return (
            <Tooltip title={'Save Question & Move Next'} placement='topRight'>
                <Button
                    type={'primary'}
                    className='icon-btn'
                    onClick={() => {
                        Save();
                        setButtonClicked(true);
                    }}
                    disabled={(
                        questions.payload.questions[currentQuestionIndex].questionType === "VIVA" ? ansAudio === null :
                            questions.payload.questions[currentQuestionIndex].questionType === "CODE" ? codeAns == "" :
                                questions.payload.questions[currentQuestionIndex].questionType === "EXPLAIN CODE" ? explainCodeAns == "" :
                                    questions.payload.questions[currentQuestionIndex].questionType === "PREDICT OUTPUT" ? predictOutputAns == "" :
                                        questions.payload.questions[currentQuestionIndex].questionType === "THEORY" ? theoryAns == "" :
                                            questions.payload.questions[currentQuestionIndex].questionType === "DEBUG CODE" && debugCode == ""

                    ) ||
                        isSaving || isSkipping || isQuittingorTimeout || buttonClicked}
                >
                    {/* Save & next */}
                    {isSaving ? <FontAwesome name='spinner' className='spinNow' /> : <FontAwesome name='save' />}
                </Button>
            </Tooltip>
        )
    }

    const SkipButton = () => {
        return (
            <Tooltip title={'Skip Question'} placement='top'>
                <Button type={'primary'} className='icon-btn' onClick={handleSkip} disabled={isSaving || isSkipping || isListening || isSkipandSubmitting || isSubmitting || isQuittingorTimeout || buttonClicked}>
                    {/* skip */}
                    {isSkipping ? <FontAwesome name='spinner' className='spinNow' /> : <FontAwesome name='forward' />}
                </Button>
            </Tooltip>
        )
    }

    const currentQuestion = (currentQuestionIndex + 1) + '. ' + questions?.payload?.questions[currentQuestionIndex].question

    const sortableComponent = (code) => {
        return code?.split('\n').map((line, index) => {
            if (line.trim() !== '') {
                return (
                    <li
                        key={index}
                        style={{
                            border: '1px solid #e8e8e8',
                            padding: '4px',
                            marginBottom: '4px',
                            cursor: 'move',
                            display: 'flex',
                            fontSize: '14px'
                        }}
                    >
                        <span onCopy={e => e.preventDefault()} style={{ userSelect: 'none' }}>{line}</span>
                    </li>
                );
            } else {
                return null;
            }
        });
    };

    useEffect(() => {
        if (jumbleRef.current) {
            const sortable = new Sortable(jumbleRef.current, {
                animation: 150,
                // Add any additional options here
            });

            // Clean up Sortable instance on unmount
            return () => {
                sortable.destroy();
            };
        }
    }, [currentQuestion, jumbleRef.current]);

    const filterBlankLines = (value) => {
        return value.split('\n').filter(line => line.trim() !== '').join('\n');
    };

    return (
        <div>
            {
                isQuestionsLoading ?
                    <div className="custom-spin">
                        <CustomSpinner />
                    </div>
                    :
                    <div>
                        {/* <p>{formatTime(timeRemaining)}</p> */}
                        {
                            submitRes !== null && submitRes.Error === false ?
                                <div>
                                    <Result
                                        status={'success'}
                                        title="Interview Completed Successfully"
                                        subTitle={<div>
                                            <div>Results shall be published on <a href='https://fs.tesseractonline.com/' target='_blank'><b>fs.tesseractonline.com</b></a> by tomorrow 9AM</div>
                                            <div>Use your rollno as username and tesseract password as password.</div>
                                        </div>}
                                    />
                                </div>
                                :
                                quitortimeoutRes && quitortimeoutRes.Error === false ?
                                    <div>
                                        <Result
                                            status={'error'}
                                            title={quitortimeoutRes.message}
                                        />
                                    </div>
                                    :
                                    questions !== null && questions.Error === false && questions.payload.questions.length !== 0 ?
                                        <QuestionsContainer>
                                            <div className='mb-20 d-flex justify-content-between align-center-v'>
                                                <div className='ant-steps-item-container'>
                                                    {
                                                        questions.payload.questions.map((question, index) =>
                                                            <div className='ant-steps-item-icon'>
                                                                <div questionType={question.questionType} className={`ant-steps-icon fontsize-12 ${currentQuestionIndex == index ? 'process' : question.status == "ATTEMPTED" ? 'finish' : question.status == "SKIPPED" ? 'error' : 'wait'} `}>
                                                                    {index + 1}
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                {/* <Steps size="small">
                                                    {
                                                        questions.payload.questions.map((question, index) =>
                                                            <Step key={index} status={`${currentQuestionIndex == index ? 'process' : question.status == "ATTEMPTED" ? 'finish' : question.status == "SKIPPED" ? 'error' : 'wait'}`} />
                                                        )
                                                    }
                                                </Steps> */}
                                                <InterviewActions>
                                                    {
                                                        questions?.payload?.questions[currentQuestionIndex].status === "NOTATTEMPTED" ?
                                                            <div>
                                                                <div className='buttons-container-1 d-flex align-center-v'>
                                                                    {/* <QuitButton /> */}
                                                                    {
                                                                        currentQuestionIndex + 1 !== questions?.payload?.questions.length &&
                                                                        <div className='mr-10'>
                                                                            <SkipButton />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        currentQuestionIndex + 1 === questions?.payload?.questions.length &&
                                                                        <div className='mr-10'>
                                                                            <SkipSubmitButton />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        <ReplayQuestionButton />
                                                                    }
                                                                    {
                                                                        <MicrophoneButton />
                                                                    }
                                                                    {
                                                                        currentQuestionIndex + 1 === questions?.payload?.questions.length &&
                                                                        <SubmitButton />
                                                                    }
                                                                    {
                                                                        currentQuestionIndex + 1 !== questions?.payload?.questions.length &&
                                                                        <SaveButton />
                                                                    }
                                                                </div>
                                                                <div className='buttons-container-2 align-center-v'>
                                                                    <div className='d-flex justify-content-around align-center-v mb-20'>
                                                                        {
                                                                            questions.payload.questions[currentQuestionIndex].questionType === "VIVA" &&
                                                                            <ReplayQuestionButton />
                                                                        }
                                                                        {
                                                                            questions.payload.questions[currentQuestionIndex].questionType === "VIVA" &&
                                                                            <MicrophoneButton />
                                                                        }
                                                                        {
                                                                            currentQuestionIndex + 1 === questions?.payload?.questions.length &&
                                                                            <SubmitButton />
                                                                        }
                                                                        {
                                                                            currentQuestionIndex + 1 !== questions?.payload?.questions.length &&
                                                                            <SaveButton />

                                                                        }
                                                                    </div>
                                                                    <div className='d-flex justify-content-around align-center-v'>
                                                                        {/* <QuitButton /> */}
                                                                        {
                                                                            currentQuestionIndex + 1 === questions?.payload?.questions.length &&
                                                                            <SkipSubmitButton />
                                                                        }
                                                                        {
                                                                            currentQuestionIndex + 1 !== questions?.payload?.questions.length &&
                                                                            <SkipButton />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            questions?.payload?.questions[currentQuestionIndex].status === "ATTEMPTED" ?
                                                                <div className="">
                                                                    <h4>Question Attempted</h4>
                                                                    <Tooltip title={'Move Next'} placement='top'>
                                                                        <Button type={'primary'} className='icon-btn' onClick={handleNext}><FontAwesome name="arrow-right" /></Button>
                                                                    </Tooltip>
                                                                </div>
                                                                :
                                                                questions?.payload?.questions[currentQuestionIndex].status === "SKIPPED" &&
                                                                <div className="">
                                                                    <h4>Question Skipped</h4>
                                                                    <Tooltip title={'Move Next'} placement='top'>
                                                                        <Button type={'primary'} className='icon-btn' onClick={handleNext}><FontAwesome name="arrow-right" /></Button>
                                                                    </Tooltip>
                                                                </div>
                                                    }
                                                </InterviewActions>
                                            </div>
                                            <div className='question-waveform'>
                                                <div>
                                                    {
                                                        questions.payload.questions[currentQuestionIndex].questionType === "VIVA" ?
                                                            <div>
                                                                <h2 className='question d-flex' dangerouslySetInnerHTML={{ __html: currentQuestion }}></h2>
                                                                {
                                                                    questions.payload.questions[currentQuestionIndex].questionType === "VIVA" &&
                                                                    <div className='mictest'>
                                                                        <ReactMic
                                                                            record={isListening}
                                                                            className="sound-wave"
                                                                            strokeColor="#2D5D85"
                                                                            visualSetting='sinewave'
                                                                            channelCount={200}
                                                                            mimeType='audio/wav'
                                                                            backgroundColor="#f9f9f9"
                                                                            echoCancellation={true} // defaults -> false
                                                                            autoGainControl={true}  // defaults -> false
                                                                            noiseSuppression={true} // defaults -> false
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                            :
                                                            questions.payload.questions[currentQuestionIndex].questionType === "CODE" ?
                                                                <div className="page-panel">
                                                                    <PanelGroup direction="horizontal">
                                                                        <Panel key="left" collapsible={false} collapsedSize={10} defaultSize={34} minSize={10} maxSize={70}>
                                                                            <p className='code-question mb-0' dangerouslySetInnerHTML={{ __html: currentQuestion }} onCopy={e => e.preventDefault()} style={{ userSelect: 'none' }}></p>
                                                                        </Panel>
                                                                        <PanelResizeHandle className="hresizer" />
                                                                        <Panel key="right">
                                                                            <CodeMirror
                                                                                theme='light'
                                                                                height="300px"
                                                                                placeholder="Type your code/answer"
                                                                                value={codeAns}
                                                                                onChange={(value) => onCodeAnswerChange(value)}
                                                                                style={{ marginTop: '10px' }}
                                                                            />
                                                                            {/* <MailBox>
                                                                                <div className='body'>
                                                                                    <div className="group">
                                                                                        <RichTextEditor
                                                                                            toolbarStyle={{ display: 'none' }}
                                                                                            editorStyle={{ lineHeight: '7px', border: '1px solid #e3e6ef', borderRadius: 6 }}
                                                                                            placeholder="Type your code/answer"
                                                                                            value={codeAnswer.value}
                                                                                            onChange={onCodeAnswerChange}
                                                                                            autoFocus
                                                                                            toolbarConfig={{
                                                                                                display: [],
                                                                                                INLINE_STYLE_BUTTONS: [],
                                                                                                BLOCK_TYPE_DROPDOWN: [],
                                                                                                BLOCK_TYPE_BUTTONS: [],
                                                                                                BLOCK_TYPE_BUTTONS: [],
                                                                                                BLOCK_TYPE_DROPDOWN: [],
                                                                                                LINK_BUTTONS: [],
                                                                                                BLOCK_TYPE_DROPDOWN: [],
                                                                                                HISTORY_BUTTONS: [],
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </MailBox> */}
                                                                        </Panel>
                                                                    </PanelGroup>
                                                                </div>
                                                                :
                                                                questions.payload.questions[currentQuestionIndex].questionType === "THEORY" ?
                                                                    <div>
                                                                        <h2 className='question mb-0 d-flex' dangerouslySetInnerHTML={{ __html: currentQuestion }} onCopy={e => e.preventDefault()} style={{ userSelect: 'none' }}></h2>
                                                                        <Input.TextArea placeholder='Type here' value={theoryAns || questions.payload.questions[currentQuestionIndex].answer} rows={8} onChange={(e) => setTheoryAns(e.target.value)} disabled={questions.payload.questions[currentQuestionIndex].status === "ATTEMPTED"} />
                                                                        {
                                                                            process.env.REACT_APP_ENVIRONMENT == "DEVELOPMENT" &&
                                                                            <div>
                                                                                <UserPrompt userPrompt={userPrompt} setUserPrompt={setUserPrompt} dbPrompt={questions.payload.questions[currentQuestionIndex].userPrompt} status={questions.payload.questions[currentQuestionIndex].status} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    :
                                                                    questions.payload.questions[currentQuestionIndex].questionType === "DEBUG CODE" ?
                                                                        <div>
                                                                            <p className='question' dangerouslySetInnerHTML={{ __html: currentQuestion }} onCopy={e => e.preventDefault()} style={{ userSelect: 'none' }}></p>
                                                                            <CodeMirror
                                                                                className='text-left'
                                                                                theme='light'
                                                                                height="400px"
                                                                                placeholder=""
                                                                                value={debugCode || questions.payload.questions[currentQuestionIndex].modifiedProgram}
                                                                                onChange={(value) => setDebugCode(filterBlankLines(value))}
                                                                                disabled={questions.payload.questions[currentQuestionIndex].status === "ATTEMPTED"}
                                                                                editable={questions.payload.questions[currentQuestionIndex].status === "ATTEMPTED" ? false : true}
                                                                            />
                                                                            {
                                                                                process.env.REACT_APP_ENVIRONMENT == "DEVELOPMENT" &&
                                                                                <div>
                                                                                    <UserPrompt userPrompt={userPrompt} setUserPrompt={setUserPrompt} dbPrompt={questions.payload.questions[currentQuestionIndex].userPrompt} status={questions.payload.questions[currentQuestionIndex].status} />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        :
                                                                        questions.payload.questions[currentQuestionIndex].questionType === "JUMBLED CODE" ?
                                                                            <div>
                                                                                <p className='question' dangerouslySetInnerHTML={{ __html: currentQuestion }} onCopy={e => e.preventDefault()} style={{ userSelect: 'none' }}></p>
                                                                                {
                                                                                    questions.payload.questions[currentQuestionIndex].status === "ATTEMPTED" ?
                                                                                        <div>
                                                                                            {sortableComponent(questions.payload.questions[currentQuestionIndex].modifiedProgram)}
                                                                                        </div>
                                                                                        :
                                                                                        <div>
                                                                                            <div ref={jumbleRef} style={{ padding: '5px', height: '500px', overflow: 'auto' }}>
                                                                                                {sortableComponent(questions.payload.questions[currentQuestionIndex].modifiedProgram)}
                                                                                            </div>
                                                                                            {
                                                                                                process.env.REACT_APP_ENVIRONMENT == "DEVELOPMENT" &&
                                                                                                <div>
                                                                                                    <UserPrompt userPrompt={userPrompt} setUserPrompt={setUserPrompt} dbPrompt={questions.payload.questions[currentQuestionIndex].userPrompt} status={questions.payload.questions[currentQuestionIndex].status} />
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                }
                                                                            </div>
                                                                            :
                                                                            questions.payload.questions[currentQuestionIndex].questionType === "EXPLAIN CODE" ?
                                                                                <div className="page-panel">
                                                                                    <PanelGroup direction="horizontal">
                                                                                        <Panel key="left" collapsible={false} collapsedSize={10} defaultSize={34} minSize={10} maxSize={70}>
                                                                                            <div className='code-question'>
                                                                                                <p className='question' dangerouslySetInnerHTML={{ __html: currentQuestion }} onCopy={e => e.preventDefault()} style={{ userSelect: 'none' }}></p>
                                                                                                <p>
                                                                                                    {
                                                                                                        questions.payload.questions[currentQuestionIndex].modifiedProgram ? questions.payload.questions[currentQuestionIndex].modifiedProgram.split('\n').map((line, index) => {
                                                                                                            if (line.trim() !== '') {
                                                                                                                return (
                                                                                                                    <li
                                                                                                                        key={index}
                                                                                                                        style={{
                                                                                                                            display: 'flex',
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span>{line}</span>
                                                                                                                    </li>
                                                                                                                );
                                                                                                            } else {
                                                                                                                return null;
                                                                                                            }
                                                                                                        })
                                                                                                            :
                                                                                                            <div className='d-flex'>
                                                                                                                NA
                                                                                                            </div>
                                                                                                    }
                                                                                                </p>
                                                                                            </div>
                                                                                        </Panel>
                                                                                        <PanelResizeHandle className="hresizer" />
                                                                                        <Panel key="right" collapsible={false} minSize={10} >
                                                                                            <Input.TextArea placeholder='Type here' rows={20} value={explainCodeAns || questions.payload.questions[currentQuestionIndex].answer} onChange={(e) => setExplainCodeAns(e.target.value)} disabled={questions.payload.questions[currentQuestionIndex].status === "ATTEMPTED"} />
                                                                                        </Panel>
                                                                                        {
                                                                                            process.env.REACT_APP_ENVIRONMENT == "DEVELOPMENT" &&
                                                                                            <>
                                                                                                <PanelResizeHandle className="hresizer" />
                                                                                                <Panel key="right2" collapsible={false} minSize={10} >
                                                                                                    <div>
                                                                                                        <UserPrompt userPrompt={userPrompt} setUserPrompt={setUserPrompt} dbPrompt={questions.payload.questions[currentQuestionIndex].userPrompt} status={questions.payload.questions[currentQuestionIndex].status} />
                                                                                                    </div>
                                                                                                </Panel>
                                                                                            </>
                                                                                        }

                                                                                    </PanelGroup>
                                                                                </div>
                                                                                :
                                                                                questions.payload.questions[currentQuestionIndex].questionType === "PREDICT OUTPUT" &&
                                                                                <div className="page-panel">
                                                                                    <PanelGroup direction="horizontal">
                                                                                        <Panel key="left" collapsible={false} collapsedSize={10} defaultSize={34} minSize={10} maxSize={70}>
                                                                                            <p className='question' dangerouslySetInnerHTML={{ __html: currentQuestion }} onCopy={e => e.preventDefault()} style={{ userSelect: 'none' }}></p>
                                                                                            <p>
                                                                                                {
                                                                                                    questions.payload.questions[currentQuestionIndex].modifiedProgram ? questions.payload.questions[currentQuestionIndex].modifiedProgram.split('\n').map((line, index) => {
                                                                                                        if (line.trim() !== '') {
                                                                                                            return (
                                                                                                                <li
                                                                                                                    key={index}
                                                                                                                    style={{
                                                                                                                        display: 'flex',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span>{line}</span>
                                                                                                                </li>
                                                                                                            );
                                                                                                        } else {
                                                                                                            return null;
                                                                                                        }
                                                                                                    })
                                                                                                        :
                                                                                                        <div className='d-flex'>
                                                                                                            NA
                                                                                                        </div>
                                                                                                }
                                                                                            </p>
                                                                                        </Panel>
                                                                                        <PanelResizeHandle className="hresizer" />
                                                                                        <Panel key="right">
                                                                                            <Input.TextArea placeholder='Type output here' rows={20} value={predictOutputAns || questions.payload.questions[currentQuestionIndex].answer} onChange={(e) => setPredictOutputAns(e.target.value)} disabled={questions.payload.questions[currentQuestionIndex].status === "ATTEMPTED"} />
                                                                                        </Panel>
                                                                                        {
                                                                                            process.env.REACT_APP_ENVIRONMENT == "DEVELOPMENT" &&
                                                                                            <>
                                                                                                <PanelResizeHandle className="hresizer" />
                                                                                                <Panel key="right2" collapsible={false} minSize={10} >
                                                                                                    <div>
                                                                                                        <UserPrompt userPrompt={userPrompt} setUserPrompt={setUserPrompt} dbPrompt={questions.payload.questions[currentQuestionIndex].userPrompt} status={questions.payload.questions[currentQuestionIndex].status} />
                                                                                                    </div>
                                                                                                </Panel>
                                                                                            </>
                                                                                        }
                                                                                    </PanelGroup>
                                                                                </div>
                                                    }

                                                </div>
                                            </div>
                                        </QuestionsContainer>
                                        :
                                        questions !== null && questions.Error === false && questions.payload.questions.length === 0 &&
                                        <div>
                                            <Result
                                                status={'warning'}
                                                title="Questions not found!"
                                            />
                                        </div>
                        }
                    </div>
            }
        </div>
    )
}