import actions from './actions';

const {
  DAILY_LOGIN_COUNT_BEGIN,
  DAILY_LOGIN_COUNT_SUCCESS,
  DAILY_LOGIN_COUNT_ERR,
  CURRENT_WEEK_BEGIN,
  CURRENT_WEEK_SUCCESS,
  CURRENT_WEEK_ERR,
  TODAYS_BADGES_BEGIN,
  TODAYS_BADGES_SUCCESS,
  TODAYS_BADGES_ERR,
} = actions;

const initialSubjectsState = {
  data: null,  
  loading: false,
  error: null,
};

const initialUnitsState = {
  data: null,  
  loading: false,
  error: null,
};

const initialTodaysBadgesState = {
  data: null,  
  loading: false,
  error: null,
};

const DailyLoginCountReducer = (state = initialSubjectsState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case DAILY_LOGIN_COUNT_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case DAILY_LOGIN_COUNT_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case DAILY_LOGIN_COUNT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const CurrentweekYearwiseReducer = (state = initialUnitsState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case CURRENT_WEEK_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CURRENT_WEEK_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case CURRENT_WEEK_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const TodaysReadAssessmentBadgesReducer = (state = initialTodaysBadgesState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case TODAYS_BADGES_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TODAYS_BADGES_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TODAYS_BADGES_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { DailyLoginCountReducer, CurrentweekYearwiseReducer,TodaysReadAssessmentBadgesReducer };
