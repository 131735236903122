import React, { useEffect, useState } from 'react'
import { Col, Result, Row } from 'antd'
import { UilUser, UilStopwatch, UilShieldQuestion, UilAward, UilAwardAlt, UilBooks, UilBullseye } from '@iconscout/react-unicons'
import { OverviewDataStyleWrap } from '../styles/dashboardStyle'
import OverviewCard from '../../../components/cards/OverviewCard'

export default function Summary({ overallSummary }) {

    const [summaryData, setSummaryData] = useState([])    
    useEffect(() => {
        const Data = [
            {
                key: 1,
                type: "primary",
                icon: <UilStopwatch />,
                label: "Time Spent (Mins)",
                total: overallSummary?.totalMinutesSpent || 0,
            },
            {
                key: 2,
                type: "primary",
                icon: <UilShieldQuestion />,
                label: "Questions Answered",
                total: overallSummary?.totalQuestionsAnswered || 0,
            },
            {
                key: 3,
                type: "primary",
                icon: <UilAward />,
                label: "Avg Score",
                total: overallSummary?.overallAverageScore || 0,
            },
            {
                key: 4,
                type: "primary",
                icon: <UilBullseye />,
                label: "Interviews Taken",
                total: overallSummary?.totalInterviews || 0,
            },
            {
                key: 5,
                type: "primary",
                icon: <UilUser />,
                label: "Students",
                total: overallSummary?.totalStudents || 0,
            },
            {
                key: 6,
                type: "primary",
                icon: <UilBooks />,
                label: "Subjects",
                total: overallSummary?.totalSubjects || 0,
            },
        ]
        setSummaryData(Data)
    }, [overallSummary])

    return (
        <div>
            {
                summaryData.length !== 0 &&
                <div>
                    <OverviewDataStyleWrap>
                        <Row gutter={25}>
                            {summaryData.map((item, i) =>
                                i <= 3 ?
                                    <Col xxl={6} xl={8} lg={8} sm={12} xs={24} key={i}>
                                        <OverviewCard data={item} contentFirst bottomStatus={false} />
                                    </Col>
                                    :
                                    <Col xxl={12} xl={8} lg={8} sm={12} xs={24} key={i}>
                                        <OverviewCard data={item} contentFirst bottomStatus={false} />
                                    </Col>
                            )}
                        </Row>
                    </OverviewDataStyleWrap>
                </div>
            }
        </div>
    )
}
