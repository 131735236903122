import react from 'react'
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Table, Tooltip } from 'antd';
import { BorderLessHeading, TableDefaultStyle } from '../../styled';
import { TopSellerWrap } from '../styles/dashboardStyle';
import topicReadBadge from '../../../static/img/TOPIC-READ/TOPIC-READ-24X24.png'
import topicCompleteBadge from '../../../static/img/TOPIC-COMPLETE/TOPIC-COMPLETE-24X24.png'
import unitCompleteBadge from '../../../static/img/UNIT-COMPLETE/UNIT-COMPLETE-24x24.png'
import subjectCompleteBadge from '../../../static/img/SUBJECT-COMPLETE/SUBJECT-COMPLETE-24X24.png'
import rockstarbadge from '../../../static/img/ROCKSTAR/ROCKSTAR-24X24.png'
function BadgesCount({ StudentBadges }) {

    const columns = [
        {
            title: "Subject",
            dataIndex: 'subjectname',
            key: 'subjectname',
        },
        {
            title: <Tooltip title='Read Badge'><img src={StudentBadges.length === 0 ? topicReadBadge : process.env.REACT_APP_API_ENDPOINT + StudentBadges[0].topicReadBadge} width={16} /></Tooltip>,
            dataIndex: 'topicreadcount',
            key: 'topicreadcount',
        },
        {
            title: <Tooltip title='Topic Badge'><img src={StudentBadges.length === 0 ? topicCompleteBadge : process.env.REACT_APP_API_ENDPOINT + StudentBadges[0].topicCompleteBadge} width={16} /></Tooltip>,
            dataIndex: 'topiccompletecount',
            key: 'topiccompletecount',
        },
        {
            title: <Tooltip title='Unit Badge'> <img src={StudentBadges.length === 0 ? unitCompleteBadge : process.env.REACT_APP_API_ENDPOINT + StudentBadges[0].unitCompleteBadge} width={16} /></Tooltip>,
            dataIndex: 'unitcompletecount',
            key: 'unitcompletecount',
        },
        {
            title: <Tooltip title='Subject Badge'> <img src={StudentBadges.length === 0 ? subjectCompleteBadge : process.env.REACT_APP_API_ENDPOINT + StudentBadges[0].subCompleteBadge} width={16} /></Tooltip>,
            dataIndex: 'subjectcompletecount',
            key: 'subjectcompletecount',
        },
        // {
        //     title: <Tooltip title='Course Complete Badge'> <img src={rockstarbadge} /></Tooltip>,
        //     dataIndex: 'action',
        //     key: 'action',
        // },

    ];

    let data = []

    StudentBadges.length !== 0 && StudentBadges.map((obj, index) => {
        const { subjectId, subjectname, topicreadcount, topiccompletecount, unitcompletecount, totaltopics, subjectcompletecount, subCompleteBadge } = obj
        data.push({
            key: index,
            subjectId,
            subjectname,
            topicreadcount: topicreadcount !== null ? topicreadcount : '-',
            topiccompletecount: topiccompletecount !== null ? topiccompletecount : '-',
            unitcompletecount: unitcompletecount !== null ? unitcompletecount : '-',
            subjectcompletecount: subjectcompletecount !== null ? subjectcompletecount : '-',            
            totaltopics,
        })
    })

    return (
        <div>
            <BorderLessHeading>
                <Cards title='Badges Earned'>
                    <TableDefaultStyle className="ninjadash-having-header-bg" style={{ minHeight: '260px' }} >
                        <TopSellerWrap>
                            <div className="table-bordered top-seller-table table-responsive">
                                <Table columns={columns} dataSource={data} pagination={false} />
                            </div>
                        </TopSellerWrap>
                    </TableDefaultStyle>
                </Cards>
            </BorderLessHeading>
        </div>
    );
}

export default BadgesCount