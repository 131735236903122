import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';

const {
  studentsAddBegin,
  studentsAddSuccess,
  studentsAddErr,

  studentsReadBegin,
  studentsReadSuccess,
  studentsReadErr,

  studentsUpdateBegin,
  studentsUpdateSuccess,
  studentsUpdateErr,
} = actions;

const studentsDataSubmit = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(studentsAddBegin());
      const response = await DataService.post('/student-master/create', data);
      const token = getItem('access_token');

      if (token && response.data.Error === false) {
        await dispatch(studentsAddSuccess(response.data));
        response.data?.payload?.dumObj?.length>0 ? message.success(`${response.data.message} and ${response.data?.payload?.dumObj.length} duplicates are found`,3) : message.success(`${response.data.message}`) ;           
      } else {
        if (response.data.Error === true && response.data.statusCode === 401){
          message.err(response.data.message);
          dispatch(logOut())
        }
        throw response.data.message;
      }
    } catch (err) {
      await dispatch(studentsAddErr(err));
      message.error(err);
    }
  };
};

const studentsDataRead = () => {
  return async (dispatch) => {
    try {
      await dispatch(studentsReadBegin());
      const query = await DataService.get(`/student-master`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(studentsReadSuccess(query.data.payload));
      } else {
        if (query.data.Error === true && query.data.statusCode === 401){
          message.error(query.data.message);
          dispatch(logOut())
        }
        throw query.data.message;
      }
    } catch (err) {
      await dispatch(studentsReadErr(err));
      message.error(err);
    }
  };
};

const studentsDataUpdate = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(studentsUpdateBegin());
      const response = await DataService.put(`/student-master/update`, data);
      const token = getItem('access_token');
      if(token && response.data.Error === false){
        await dispatch(studentsUpdateSuccess(response.data));
        message.success(response.data.message);
      }else{
        if (response.data.Error === true && response.data.statusCode === 401){
          message.error(response.data.message);
          dispatch(logOut())
        }
        throw response.data.message;
      }
    } catch (err) {
      await dispatch(studentsUpdateErr(err));
      message.error(err);
    }
  };
};


export {
  studentsDataRead,
  studentsDataSubmit,
  studentsDataUpdate,
};
