export const READ_PROGRESS_BEGIN = 'READ_PROGRESS_BEGIN';
export const READ_PROGRESS_SUCCESS = 'READ_PROGRESS_SUCCESS';
export const READ_PROGRESS_ERROR = 'READ_PROGRESS_ERROR';

export const readProgressBegin = () => ({
    type: READ_PROGRESS_BEGIN,
});

export const readProgressSuccess = (data) => ({
    type: READ_PROGRESS_SUCCESS,
    payload: data,
});

export const readProgressError = (error) => ({
    type: READ_PROGRESS_ERROR,
    payload: error,
});
