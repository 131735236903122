import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Input, Modal, Form, Select, message } from 'antd';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import { Cards } from '../../components/cards/frame/cards-frame';
import { TableWrapper, Main } from '../styled';
import { PageHeader } from '../../components/page-headers/page-headers';
import { cohortDataRead, cohortDataSubmit, cohortDataUpdate } from '../../redux/cohorts/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectList, ProjectSorting } from '../project/style';
import { AutoComplete } from '../../components/autoComplete/autoComplete';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { Button } from '../../components/buttons/buttons';
import { getItem } from '../../utility/localStorageControl';

const { Option } = Select;

function Cohorts() {
  const clg = getItem('collegeName');
  let ClgName = '(' + clg + ')';
  const loggedadmin = getItem('username');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [editRecord, setEditRecord] = useState(null);

  const dispatch = useDispatch();
  const { cohorts, isLoading, cohortUpdateRes, isCohortUpdating, cohortAddRes, isCohortAdding } = useSelector((state) => {
    return {
      cohorts: state.cohorts.data,
      isLoading: state.cohorts.loading,
      cohortUpdateRes: state.cohortUpdateRes.data,
      isCohortUpdating: state.cohortUpdateRes.loading,
      cohortAddRes: state.cohortAddRes.data,
      isCohortAdding: state.cohortAddRes.loading,
    };
  });

  useEffect(() => {
    if (dispatch) {
      dispatch(cohortDataRead());
    }
  }, [dispatch]);

  useEffect(() => {
    if (cohortUpdateRes && cohortUpdateRes.Error === false) {
      dispatch(cohortDataRead());
    }
  }, [cohortUpdateRes])

  useEffect(() => {
    if (cohortAddRes && cohortAddRes.Error === false) {
      dispatch(cohortDataRead());
    }
  }, [cohortAddRes])

  const [loading, setLoading] = useState(true);
  const [selectedCohortId, setSelectedCohortId] = useState(null);
  const [searchText, setsearchText] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [GoodData, setGoodData] = useState([]);

  useEffect(() => {
    if (cohorts) {
      const newData = cohorts.sort((a, b) => a.updatedon < b.updatedon ? 1 : -1).map((item, index) => ({
        key: item.id,
        sno: index + 1,
        id: item.id,
        code: item.code,
        name: item.name,
        status: item.status,
        updatedon: new Date(item.updatedon).toLocaleString('en-GB'),
        createdon: new Date(item.createdon).toLocaleString('en-GB'),
        updatedBy: item.updatedby !== null ? item.updatedby : '--------',
      }));
      setDataSource(newData);
      setGoodData(newData);
      setLoading(false);
    }
  }, [cohorts])

  const checkBranch = (i) => {
    let tarr = i.code;
    let valueInArray = false;
    if (tarr === selectedCohortId) {
      valueInArray = true;

    }
    return valueInArray;
  }

  const checkSearch = (i) => {
    for (let j in i) {
      if (i[j] != null && i[j].toString().toLowerCase().includes(searchText.toString().toLowerCase())) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    let tempValues = [...GoodData];
    if (selectedCohortId != null) {
      tempValues = tempValues.filter(checkBranch);
    }
    if (searchText != null) {
      tempValues = tempValues.filter(checkSearch);
    }
    setDataSource(tempValues);
  }, [selectedCohortId, searchText])

  const handleSearch = (e) => {
    setsearchText(e)
  }

  const columns = [
    {
      title: 'S.No.',
      dataIndex: 'sno',
      key: 'sno',
      sorter: (a, b) => a.sno > b.sno ? 1 : -1,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name > b.name ? 1 : -1,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Created On',
      dataIndex: 'createdon',
      key: 'createdon',
      sorter: (a, b) => a.createdon > b.createdon ? 1 : -1,
    },
    {
      title: 'Last Updated',
      dataIndex: 'updatedon',
      key: 'updatedon',
      sorter: (a, b) => a.updatedon > b.updatedon ? 1 : -1,
    },
    {
      title: 'Updated By',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      sorter: (a, b) => a.updatedon > b.updatedon ? 1 : -1,
    },
  ];

  if (clg === 'ALL') {
    columns.splice(3, 0,
      {
        title: 'College',
        dataIndex: 'collegename',
        key: 'collegename',
      }
    );
  }

  if (loggedadmin !== 'superadmin') {
    columns.push({
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <div className='table-actions'>
          <Button
            key="editBranch"
            onClick={() => handleEdit(record)}
            className="btn-icon "
            type="primary"
            to="#"
            shape="circle"
          >
            <UilEdit />
          </Button>
        </div>
      ),
    });
  }

  const handleButtonClick = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleModalAdd = () => {
    form.validateFields().then((values) => {
      const newRow = {
        name: values.name,
      };
      if (cohorts.filter((cohort) => cohort.name == values.name)[0]) {
        message.error("The batch already exists!")
        return
      }
      form.resetFields();
      setIsModalVisible(false);
      dispatch(cohortDataSubmit(newRow));
    })
      .catch((error) => {
        console.error('Error while validating form:', error);
      });
  };

  const handleModalUpdate = () => {
    editForm.validateFields().then((values) => {
      const updatedRecord = {
        id: editRecord.id,
        name: values.name,
        status : values.status
      };
      if (cohorts.filter((cohort) => cohort.name == values.name && cohort.id !== editRecord.id)[0]) {
        message.error("The batch already exists!")
        return
      }
      editForm.resetFields()
      setIsEditModalVisible(false);
      dispatch(cohortDataUpdate(updatedRecord));
    })
      .catch((error) => {
        console.error('Error while validating form:', error);
      });
  };

  const handleEdit = (record) => {
    setEditRecord(record);
    setIsEditModalVisible(true);
    editForm.setFieldsValue({
      name: record.name,
      status: record.status
    });
  };

  return (
    <>
      <PageHeader className="ninjadash-page-header-main" title={`Batches`}
        buttons={
          loggedadmin !== 'superadmin' ?
            [
              <Button id="cohort-page-add_cohort-button" onClick={handleButtonClick} key="AddCohort" type="primary" size="default">
                Add Batch
              </Button>,
            ] : []}
      />
      <Main>
        {isLoading || loading || isCohortUpdating || isCohortAdding ?
          (
            <div className="spin">
              <CustomSpinner Message={'Loading please wait...'} />
            </div>
          ) : (
            <Row gutter={25}>
              <Col sm={24} xs={24}>
                <Cards headless>
                  <TableWrapper>
                    <ProjectList>
                      <div className='d-flex justify-content-between mb-15 p-0 align-center-v'>
                        <div className="d-flex align-center-v" style={{ marginLeft: 'auto' }}>
                          <AutoComplete id="cohort-page-search-filter" onSearch={handleSearch} dataSource={dataSource} placeholder="Search here" patterns />
                        </div>
                      </div>
                      <div className="table-responsive">
                        <Table dataSource={dataSource} columns={columns} />
                      </div>
                    </ProjectList>
                  </TableWrapper>
                </Cards>
              </Col>
            </Row>
          )
        }
      </Main>
      <Modal
        title="Add Batch"
        open={isModalVisible}
        onCancel={handleModalCancel}
        maskClosable={false}
        centered
        footer={[
          <Button id="add-cohort-button" key="AddCohortRecord" type="primary" onClick={handleModalAdd}>
            Add
          </Button>,
        ]}
      >
        <Form id="add-branch-form" form={form} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please enter batch name' }]}>
            <Input id="add-branch-enter-branch-name" placeholder="Enter batch name" />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit Batch"
        open={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        maskClosable={false}
        centered
        footer={[
          <Button id="edit-branch-button" key="UpdateBranchRecord" type="primary" onClick={handleModalUpdate}>
            Update
          </Button>
        ]}
      >
        <Form id="edit-branch-form" form={editForm} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please enter batch name' }]}>
            <Input id="edit-branch-enter-branch-name" placeholder="Enter batch name" />
          </Form.Item>
          <Form.Item name='status' label='Status' rules={[{ required: true, message: 'Please select status' }]}>
            <Select placeholder='Select status' allowClear>
              <Option key={'ACTIVE'} value={'ACTIVE'}>
                ACTIVE
              </Option>
              <Option key={'INACTIVE'} value={'INACTIVE'}>
                INACTIVE
              </Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Cohorts;