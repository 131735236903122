import React, { useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
// import {
//   UilBooks,
//   UilBook,
// } from '@iconscout/react-unicons';
import { TopMenuStyle } from './Style';
import { useDispatch, useSelector } from 'react-redux';
import { studentSubjectsDataRead } from '../redux/studentSubjectsUnits/actionCreator';
// import { ellipsis } from '../utility/utility'
import { useState } from 'react';
import { useRef } from 'react';
import { removeItem } from '../utility/localStorageControl';
import { Tooltip } from 'antd';


function TeacherMenu() {
  const path = '/teacher';

  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  const [megamenuVisible, setMegamenuViible] = useState(false)

  const addParentActive = (event) => {
    removeItem('activeunit')
    removeItem('activestudenttopic')
    document.querySelectorAll('.parent').forEach((element) => {
      element.classList.remove('active');
    });

    const hasSubMenuLeft = event.currentTarget.closest('.has-subMenu-left');
    const megaMenu = event.currentTarget.closest('.megaMenu-wrapper');
    if (!megaMenu) {
      event.currentTarget.closest('ul').previousSibling.classList.add('active');
      if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
    } else {
      event.currentTarget.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
    }
  };

  // useEffect(() => {
  //   if (dispatch) {
  //     dispatch(studentSubjectsDataRead());
  //   }
  // }, [dispatch]);

  const { StudentSubjects } = useSelector((state) => {
    return {
      StudentSubjects: state.StudentSubjects.data,
      isLoading: state.StudentSubjects.loading,
    };
  });

  const toggleMegamenu = () => {
    setMegamenuViible(!megamenuVisible)
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMegamenuViible(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <TopMenuStyle>
      <div className="ninjadash-top-menu">
        <ul>
          {/* <li className="">
            <NavLink to={`${path}`} >
              Dashboard
            </NavLink>
          </li>          */}
          <li className="">
            <NavLink to={`${path}`} >
              Subjects
            </NavLink>
          </li>
          {/* <li className="">
            <NavLink to={`${path}/schedules`} >
              Schedules
            </NavLink>
          </li> */}
          {/* <li className="">
            <NavLink to={`${path}/Interview_reports`} >
              Interviews
            </NavLink>
          </li> */}

          {/* <li className="mega-item has-subMenu" ref={dropdownRef}>
            <Link to="#" className="" onClick={toggleMegamenu}>
              Learn
            </Link>
            {
              megamenuVisible &&
              <ul className="megaMenu-wrapper megaMenu-wide" style={{ paddingBottom: '50px' }}>
                <li>
                  <span className="mega-title">1st Year</span>
                  <ul>
                    <span className="mega-sub-title">Semester 1</span>
                    <TopmenuSubjectLinks path={path} StudentSubjects={StudentSubjects} yearsem_code1="I-I" yearsem_code2="I" toggleMegamenu={toggleMegamenu} addParentActive={addParentActive} />
                  </ul>
                  <ul>
                    <span className="mega-sub-title">Semester 2</span>
                    <TopmenuSubjectLinks path={path} StudentSubjects={StudentSubjects} yearsem_code1="I-II" yearsem_code2="II" toggleMegamenu={toggleMegamenu} addParentActive={addParentActive} />
                  </ul>
                </li>
                <li>
                  <span className="mega-title">2nd Year</span>
                  <ul>
                    <span className="mega-sub-title">Semester 1</span>
                    <TopmenuSubjectLinks path={path} StudentSubjects={StudentSubjects} yearsem_code1="II-I" yearsem_code2="III" toggleMegamenu={toggleMegamenu} addParentActive={addParentActive} />
                  </ul>
                  <ul>
                    <span className="mega-sub-title">Semester 2</span>
                    <TopmenuSubjectLinks path={path} StudentSubjects={StudentSubjects} yearsem_code1="II-II" yearsem_code2="IV" toggleMegamenu={toggleMegamenu} addParentActive={addParentActive} />
                  </ul>
                </li>
                <li>
                  <span className="mega-title">3rd Year</span>
                  <ul>
                    <span className="mega-sub-title">Semester 1</span>
                    <TopmenuSubjectLinks path={path} StudentSubjects={StudentSubjects} yearsem_code1="III-I" yearsem_code2="V" toggleMegamenu={toggleMegamenu} addParentActive={addParentActive} />
                  </ul>
                  <ul>
                    <span className="mega-sub-title">Semester 2</span>
                    <TopmenuSubjectLinks path={path} StudentSubjects={StudentSubjects} yearsem_code1="III-II" yearsem_code2="VI" toggleMegamenu={toggleMegamenu} addParentActive={addParentActive} />
                  </ul>
                </li>
                <li>
                  <span className="mega-title">4th Year</span>
                  <ul>
                    <span className="mega-sub-title">Semester 1</span>
                    <TopmenuSubjectLinks path={path} StudentSubjects={StudentSubjects} yearsem_code1="IV-I" yearsem_code2="VII" toggleMegamenu={toggleMegamenu} addParentActive={addParentActive} />
                  </ul>
                  <ul>
                    <span className="mega-sub-title">Semester 2</span>
                    <TopmenuSubjectLinks path={path} StudentSubjects={StudentSubjects} yearsem_code1="IV-II" yearsem_code2="VIII" toggleMegamenu={toggleMegamenu} addParentActive={addParentActive} />
                  </ul>
                </li>
              </ul>
            }
          </li> */}
        </ul>
      </div>
    </TopMenuStyle>
  );
}

export default TeacherMenu;


const TopmenuSubjectLinks = ({ path, StudentSubjects, yearsem_code1, yearsem_code2, toggleMegamenu, addParentActive }) => {
  return (
    <>
      {
        StudentSubjects.length !== 0 && StudentSubjects.filter(sub => sub.yearsemester_code === yearsem_code1 || sub.yearsemester_code === yearsem_code2).sort((a, b) => a.subject_name > b.subject_name ? 1 : -1).map((obj, index) =>
          <li key={index} className='text-ellipsis' onClick={toggleMegamenu}>
            <NavLink onClick={addParentActive} to={`${path}/subject/${obj.subject_id}`}>
              <Tooltip title={obj.subject_name} placement="left">{obj.subject_name}</Tooltip>
              {/* {obj.subject_name} */}
              {/* <img src={subjectCompleteBadge} className='img-grayscale'/> */}
            </NavLink>
          </li>
        )
      }
    </>
  );
}