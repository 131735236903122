const actions = {

  TEACHER_SUBJECTS_READ_BEGIN: 'TEACHER_SUBJECTS_READ_BEGIN',
  TEACHER_SUBJECTS_READ_SUCCESS: 'TEACHER_SUBJECTS_READ_SUCCESS',
  TEACHER_SUBJECTS_READ_ERR: 'TEACHER_SUBJECTS_READ_ERR',

  TOPICS_OF_SUBJECT_READ_BEGIN: 'TOPICS_OF_SUBJECT_READ_BEGIN',
  TOPICS_OF_SUBJECT_READ_SUCCESS: 'TOPICS_OF_SUBJECT_READ_SUCCESS',
  TOPICS_OF_SUBJECT_READ_ERR: 'TOPICS_OF_SUBJECT_READ_ERR',

  SUBJECT_READ_BEGIN : 'SUBJECT_READ_BEGIN',
  SUBJECT_READ_SUCCESS : 'SUBJECT_READ_SUCCESS',
  SUBJECT_READ_ERR : 'SUBJECT_READ_ERR',

  UNIT_READ_BEGIN : 'UNIT_READ_BEGIN',
  UNIT_READ_SUCCESS : 'UNIT_READ_SUCCESS',
  UNIT_READ_ERR : 'UNIT_READ_ERR',

  GENERATE_QUESTIONS_OF_TOPIC_READ_BEGIN: 'GENERATE_QUESTIONS_OF_TOPIC_READ_BEGIN',
  GENERATE_QUESTIONS_OF_TOPIC_READ_SUCCESS: 'GENERATE_QUESTIONS_OF_TOPIC_READ_SUCCESS',
  GENERATE_QUESTIONS_OF_TOPIC_READ_ERR: 'GENERATE_QUESTIONS_OF_TOPIC_READ_ERR',

  GET_QUESTIONS_OF_TOPIC_READ_BEGIN: 'GET_QUESTIONS_OF_TOPIC_READ_BEGIN',
  GET_QUESTIONS_OF_TOPIC_READ_SUCCESS: 'GET_QUESTIONS_OF_TOPIC_READ_SUCCESS',
  GET_QUESTIONS_OF_TOPIC_READ_ERR: 'GET_QUESTIONS_OF_TOPIC_READ_ERR',

  TOPIC_UPDATE_BEGIN: 'TOPIC_UPDATE_BEGIN',
  TOPIC_UPDATE_SUCCESS: 'TOPIC_UPDATE_SUCCESS',
  TOPIC_UPDATE_ERR: 'TOPIC_UPDATE_ERR',

  ACCEPT_OR_REJECT_QUESTION_BEGIN: 'ACCEPT_OR_REJECT_QUESTION_BEGIN',
  ACCEPT_OR_REJECT_QUESTION_SUCCESS: 'ACCEPT_OR_REJECT_QUESTION_SUCCESS',
  ACCEPT_OR_REJECT_QUESTION_ERR: 'ACCEPT_OR_REJECT_QUESTION_ERR',

  UPDATE_QUESTION_BEGIN: 'UPDATE_QUESTION_BEGIN',
  UPDATE_QUESTION_SUCCESS: 'UPDATE_QUESTION_SUCCESS',
  UPDATE_QUESTION_ERR: 'UPDATE_QUESTION_ERR',

  CREATE_QUESTION_BEGIN: 'CREATE_QUESTION_BEGIN',
  CREATE_QUESTION_SUCCESS: 'CREATE_QUESTION_SUCCESS',
  CREATE_QUESTION_ERR: 'CREATE_QUESTION_ERR',  

  REGENERATE_QUESTIONS_BEGIN: 'REGENERATE_QUESTION_BEGIN',
  REGENERATE_QUESTIONS_SUCCESS: 'REGENERATE_QUESTION_SUCCESS',
  REGENERATE_QUESTIONS_ERR: 'REGENERATE_QUESTION_ERR',

  IMPORT_QUESTIONS_BEGIN: 'IMPORT_QUESTION_BEGIN',
  IMPORT_QUESTIONS_SUCCESS: 'IMPORT_QUESTION_SUCCESS',
  IMPORT_QUESTIONS_ERR: 'IMPORT_QUESTION_ERR',  


  ImportQuestionsBegin: () => {
    return {
      type: actions.IMPORT_QUESTIONS_BEGIN,
    };
  },

  ImportQuestionsSuccess: (data) => {    
    return {
      type: actions.IMPORT_QUESTIONS_SUCCESS,      
      data,
    };
  },

  ImportQuestionsErr: (err) => {
    return {
      type: actions.IMPORT_QUESTIONS_ERR,
      err,
    };
  },
  RegenerateQuestionsBegin: () => {
    return {
      type: actions.REGENERATE_QUESTIONS_BEGIN,
    };
  },

  RegenerateQuestionsSuccess: (data) => {    
    return {
      type: actions.REGENERATE_QUESTIONS_SUCCESS,      
      data,
    };
  },

  RegenerateQuestionsErr: (err) => {
    return {
      type: actions.REGENERATE_QUESTIONS_ERR,
      err,
    };
  },

  teacherSubjectsReadBegin: () => {
    return {
      type: actions.TEACHER_SUBJECTS_READ_BEGIN,
    };
  },

  teacherSubjectsReadSuccess: (data) => {    
    return {
      type: actions.TEACHER_SUBJECTS_READ_SUCCESS,      
      data,
    };
  },

  teacherSubjectsReadErr: (err) => {
    return {
      type: actions.TEACHER_SUBJECTS_READ_ERR,
      err,
    };
  },

  CreateQuestionBegin: () => {
    return {
      type: actions.CREATE_QUESTION_BEGIN,
    };
  },

  CreateQuestionSuccess: (data) => {    
    return {
      type: actions.CREATE_QUESTION_SUCCESS,      
      data,
    };
  },

  CreateQuestionErr: (err) => {
    return {
      type: actions.CREATE_QUESTION_ERR,
      err,
    };
  },

  UpdateQuestionBegin: () => {
    return {
      type: actions.UPDATE_QUESTION_BEGIN,
    };
  },

  UpdateQuestionSuccess: (data) => {    
    return {
      type: actions.UPDATE_QUESTION_SUCCESS,      
      data,
    };
  },

  UpdateQuestionErr: (err) => {
    return {
      type: actions.UPDATE_QUESTION_ERR,
      err,
    };
  },

  AcceptOrRejectQuestionBegin: () => {
    return {
      type: actions.ACCEPT_OR_REJECT_QUESTION_BEGIN,
    };
  },

  AcceptOrRejectQuestionSuccess: (data) => {    
    return {
      type: actions.ACCEPT_OR_REJECT_QUESTION_SUCCESS,      
      data,
    };
  },

  AcceptOrRejectQuestionErr: (err) => {
    return {
      type: actions.ACCEPT_OR_REJECT_QUESTION_ERR,
      err,
    };
  },

  GetQuestionsOfTopicReadBegin: () => {
    return {
      type: actions.GET_QUESTIONS_OF_TOPIC_READ_BEGIN,
    };
  },

  GetQuestionsOfTopicReadSuccess: (data) => {    
    return {
      type: actions.GET_QUESTIONS_OF_TOPIC_READ_SUCCESS,      
      data,
    };
  },

  GetQuestionsOfTopicReadErr: (err) => {
    return {
      type: actions.GET_QUESTIONS_OF_TOPIC_READ_ERR,
      err,
    };
  },

  generateQuestionsofTopicReadBegin: () => {
    return {
      type: actions.GENERATE_QUESTIONS_OF_TOPIC_READ_BEGIN,
    };
  },

  generateQuestionsofTopicReadSuccess: (data) => {    
    return {
      type: actions.GENERATE_QUESTIONS_OF_TOPIC_READ_SUCCESS,      
      data,
    };
  },

  generateQuestionsofTopicReadErr: (err) => {
    return {
      type: actions.GENERATE_QUESTIONS_OF_TOPIC_READ_ERR,
      err,
    };
  },

  TopicUpdateBegin: () => {
    return {
      type: actions.TOPIC_UPDATE_BEGIN,
    };
  },

  TopicUpdateSuccess: (data) => {    
    return {
      type: actions.TOPIC_UPDATE_SUCCESS,      
      data,
    };
  },

  TopicUpdateErr: (err) => {
    return {
      type: actions.TOPIC_UPDATE_ERR,
      err,
    };
  },

  topicsofSubjectReadBegin: () => {
    return {
      type: actions.TOPICS_OF_SUBJECT_READ_BEGIN,
    };
  },

  topicsofSubjectReadSuccess: (data) => {    
    return {
      type: actions.TOPICS_OF_SUBJECT_READ_SUCCESS,      
      data,
    };
  },

  topicsofSubjectReadErr: (err) => {
    return {
      type: actions.TOPICS_OF_SUBJECT_READ_ERR,
      err,
    };
  },

  subjectReadBegin: () => {
    return {
      type: actions.SUBJECT_READ_BEGIN,
    };
  },

  subjectReadSuccess: (data) => {    
    return {
      type: actions.SUBJECT_READ_SUCCESS,      
      data,
    };
  },

  subjectReadErr: (err) => {
    return {
      type: actions.SUBJECT_READ_ERR,
      err,
    };
  },

  unitReadBegin: () => {
    return {
      type: actions.UNIT_READ_BEGIN,
    };
  },

  unitReadSuccess: (data) => {    
    return {
      type: actions.UNIT_READ_SUCCESS,      
      data,
    };
  },

  unitReadErr: (err) => {
    return {
      type: actions.UNIT_READ_ERR,
      err,
    };
  },

};

export default actions;