import React, { Suspense } from 'react';
import { AuthenticationWrap } from './overview/style';
import CustomSpinner from '../../ui-elements/spinner/CustomSpinner';

const AuthLayout = (WraperContent) => {
  return function () {
    return (
      <Suspense
        fallback={
          <div className="spin">
            <CustomSpinner />
          </div>
        }
      >
        <AuthenticationWrap style={{ backgroundImage: `url("${require('../../../static/img/admin-bg-light-blue.png')}")` }}>
          <div className="ninjadash-authentication-wrap">            
            <WraperContent />
          </div>
          {/* <div className="ninjadash-authentication-brand">
            <img src={require(`../../../static/img/icon/tesseract-learning-logo.png`)} alt="" width={270} style={{ mixBlendMode: 'darken' }} />
          </div> */}
        </AuthenticationWrap>
      </Suspense>
    );
  };
};

export default AuthLayout;
