const actions = {
  COHORT_ADD_BEGIN: 'COHORT_ADD_BEGIN',
  COHORT_ADD_SUCCESS: 'COHORT_ADD_SUCCESS',
  COHORT_ADD_ERR: 'COHORT_ADD_ERR',

  COHORT_READ_BEGIN: 'COHORT_READ_BEGIN',
  COHORT_READ_SUCCESS: 'COHORT_READ_SUCCESS',
  COHORT_READ_ERR: 'COHORT_READ_ERR',

  COHORT_UPDATE_BEGIN: 'COHORT_UPDATE_BEGIN',
  COHORT_UPDATE_SUCCESS: 'COHORT_UPDATE_SUCCESS',
  COHORT_UPDATE_ERR: 'COHORT_UPDATE_ERR',

  cohortAddBegin: () => {
    return {
      type: actions.COHORT_ADD_BEGIN,
    };
  },

  cohortAddSuccess: (data) => {
    return {
      type: actions.COHORT_ADD_SUCCESS,
      data,
    };
  },

  cohortAddErr: (err) => {
    return {
      type: actions.COHORT_ADD_ERR,
      err,
    };
  },

  cohortReadBegin: () => {
    return {
      type: actions.COHORT_READ_BEGIN,
    };
  },

  cohortReadSuccess: (data) => {
    return {
      type: actions.COHORT_READ_SUCCESS,
      data,
    };
  },

  cohortReadErr: (err) => {
    return {
      type: actions.COHORT_READ_ERR,
      err,
    };
  },

  cohortUpdateBegin: () => {
    return {
      type: actions.COHORT_UPDATE_BEGIN,
    };
  },

  cohortUpdateSuccess: (data) => {
    return {
      type: actions.COHORT_UPDATE_SUCCESS,
      data,
    };
  },

  cohortUpdateErr: (err) => {
    return {
      type: actions.COHORT_UPDATE_ERR,
      err,
    };
  },
};

export default actions;
