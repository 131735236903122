const actions = {
  TEACHERS_ADD_BEGIN: 'TEACHERS_ADD_BEGIN',
  TEACHERS_ADD_SUCCESS: 'TEACHERS_ADD_SUCCESS',
  TEACHERS_ADD_ERR: 'TEACHERS_ADD_ERR',

  TEACHERS_READ_BEGIN: 'TEACHERS_READ_BEGIN',
  TEACHERS_READ_SUCCESS: 'TEACHERS_READ_SUCCESS',
  TEACHERS_READ_ERR: 'TEACHERS_READ_ERR',

  TEACHERS_UPDATE_BEGIN: 'TEACHERS_UPDATE_BEGIN',
  TEACHERS_UPDATE_SUCCESS: 'TEACHERS_UPDATE_SUCCESS',
  TEACHERS_UPDATE_ERR: 'TEACHERS_UPDATE_ERR',

  teachersAddBegin: () => {
    return {
      type: actions.TEACHERS_ADD_BEGIN,
    };
  },

  teachersAddSuccess: (data) => {
    return {
      type: actions.TEACHERS_ADD_SUCCESS,
      data,
    };
  },

  teachersAddErr: (err) => {
    return {
      type: actions.TEACHERS_ADD_ERR,
      err,
    };
  },

  teachersReadBegin: () => {
    return {
      type: actions.TEACHERS_READ_BEGIN,
    };
  },

  teachersReadSuccess: (data) => {
    return {
      type: actions.TEACHERS_READ_SUCCESS,
      data,
    };
  },

  teachersReadErr: (err) => {
    return {
      type: actions.TEACHERS_READ_ERR,
      err,
    };
  },

  teachersUpdateBegin: () => {
    return {
      type: actions.TEACHERS_UPDATE_BEGIN,
    };
  },

  teachersUpdateSuccess: (data) => {
    return {
      type: actions.TEACHERS_UPDATE_SUCCESS,
      data,
    };
  },

  teachersUpdateErr: (err) => {
    return {
      type: actions.TEACHERS_UPDATE_ERR,
      err,
    };
  },
};

export default actions;
