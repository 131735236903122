import react, { useEffect, useState } from 'react'
import { Col, Form, Input, Radio, Result, Row, Select } from 'antd';
import { NoteCardWrap } from '../note/style';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { getRegulationYearsems, getScheduleWeeks, getScheduledData } from '../../redux/schedule/actionCreator';
import { FormValidationWrap } from '../forms/overview/Style';
import { Button } from '../../components/buttons/buttons';
import actions from '../../redux/schedule/actions';

const { Option } = Select;
const { getScheduleSuccess } = actions;

function TopicSchedule() {

    const dispatch = useDispatch();
    const { subjectId, topicId } = useParams()
    const [form] = Form.useForm();
    const [form1] = Form.useForm();

    const [showUpdateForm, setShowUpdateForm] = useState(true)

    const { Weeks, weeksLoading, TopicScheduledData, TopicScheduledDataLoading, ScheduleUpdateRes, isScheduling, TopicInfo, TopicLoading, RegYrsem, RegYrsemLoading } = useSelector((state) => {
        return {
            Weeks: state.Weeks.data,
            weeksLoading: state.Weeks.loading,
            ScheduleUpdateRes: state.ScheduleUpdateRes.data,
            isScheduling: state.ScheduleUpdateRes.loading,
            TopicScheduledData: state.TopicScheduledData.data,
            TopicScheduledDataLoading: state.TopicScheduledData.loading,
            TopicInfo: state.singleTopic.data,
            TopicLoading: state.singleTopic.loading,
            RegYrsem: state.RegYrsem.data,
            RegYrsemLoading: state.RegYrsem.loading,
        };
    });

    // useEffect(() => {
    //     dispatch(getRegulationYearsems(subjectId))
    // },[subjectId])    

    useEffect(() => {
        // dispatch(getTopicById(parseInt(topicId)))
        // setFormInitialValues({ readtime: '', scheduleId: '' })
        form.setFieldsValue({ readtime: '', scheduleId: '' })
        if (topicId) {
            dispatch(getScheduledData(parseInt(topicId)))
        }
    }, [])

    useEffect(() => {
        if (topicId && ScheduleUpdateRes !== null) {
            dispatch(getScheduledData(parseInt(topicId)))
        }
    }, [ScheduleUpdateRes])

    useEffect(() => {
        if (TopicScheduledData !== null && Object.keys(TopicScheduledData).length !== 0 && TopicScheduledData.scheduleId === 0) {
            // setFormInitialValues({ readtime: '', scheduleId: '' })
            form.setFieldsValue({ readtime: '', scheduleId: '' })
            setShowUpdateForm(true)
            dispatch(getRegulationYearsems(subjectId))
            dispatch(getScheduleSuccess([]))
        }
        else if (TopicScheduledData !== null && Object.keys(TopicScheduledData).length !== 0 && TopicScheduledData.scheduleId !== 0) {
            setShowUpdateForm(false)
            // setFormInitialValues(TopicScheduledData)
        }
    }, [TopicScheduledData])

    const UpdateSchedule = (formData) => {
        if ((formData.scheduleId !== '') && topicId) {
            let data = {
                id: parseInt(topicId),
                readtime: formData.readtime === "" ? 0 : parseInt(formData.readtime),
                scheduleId: parseInt(formData.scheduleId)
            }
            // dispatch(updateSchedule(data))
        }
    }

    const GetWeeks = (formData) => {
        if (formData !== null)
            dispatch(getScheduleWeeks(formData.regulationId, formData.yearsemId))
    }

    const handleKeyDown = e => {
        if (e.key === '-' || e.key === '+') {
            e.preventDefault();
        }
    };

    const ShowScheduleForm = () => {
        dispatch(getRegulationYearsems(subjectId))
        dispatch(getScheduleSuccess([]))
        if (TopicScheduledData !== null) {
            // setFormInitialValues(TopicScheduledData)
            form.setFieldsValue(TopicScheduledData)
            form1.resetFields()
            setShowUpdateForm(true)
        }
    }

    return (
        <>
            <Row gutter={25} justify="center">
                <Col lg={24} xs={24}>
                    <NoteCardWrap>
                        {/* <h3 className='mb-20'>Schedule: {TopicInfo !== null ? TopicInfo.name : ''}</h3> */}
                        {
                            weeksLoading || TopicScheduledDataLoading ?
                                <div className="custom-spin">
                                    <CustomSpinner />
                                </div>
                                :
                                (TopicScheduledData !== null && Object.keys(TopicScheduledData).length !== 0 && TopicScheduledData.scheduleId === 0) || showUpdateForm ?
                                    <div>
                                        <FormValidationWrap>
                                            <Form id="topicschedule_getschedules-form" name="reg-yr-sem-form" form={form1} layout="vertical" onFinish={GetWeeks} initialValues={{ regulationId: '', yearsemId: '' }}>
                                                <Row gutter={25}>
                                                    <Col xs={12}>
                                                        <Form.Item
                                                            name="regulationId"
                                                            rules={[{ required: true, message: 'Regulation is required!' }]}
                                                            label="Regulation"
                                                        >
                                                            <Select id="topicschedule_select-regulation" size='middle'>
                                                                <Option value="">Select</Option>
                                                                {
                                                                    RegYrsem !== null && RegYrsem.regulations.length !== 0 && RegYrsem.regulations.map((reg, index) =>
                                                                        <Option key={index + 1} value={reg.id}>{reg.code}</Option>
                                                                    )
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={12}>
                                                        <Form.Item
                                                            name="yearsemId"
                                                            rules={[{ required: true, message: 'Year Semester is required!' }]}
                                                            label="YearSem"
                                                        >
                                                            <Select id="topicschedule_select-yearsem" size='middle'>
                                                                <Option value="">Select</Option>
                                                                {
                                                                    RegYrsem !== null && RegYrsem.yearsemesters.length !== 0 && RegYrsem.yearsemesters.map((yrsem, index) =>
                                                                        <Option key={index + 1} value={yrsem.id}>{yrsem.code}</Option>
                                                                    )
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>


                                                <div className='text-right mb-20'>
                                                    <Button id="topicschedule_cancel-getschedules" type="primary" style={{ marginRight: '10px' }} outlined onClick={() => { form1.resetFields(); setShowUpdateForm(false) }}>
                                                        Cancel
                                                    </Button>
                                                    <Button id="topicschedule_getschedules-button" htmlType="submit" type="primary" >
                                                        {RegYrsemLoading ? 'Loading' : 'Get Schedule'}
                                                    </Button>
                                                </div>
                                            </Form>
                                        </FormValidationWrap>
                                        {
                                            Weeks.length !== 0 &&
                                            <FormValidationWrap>
                                                <Form id="topicschedule_updateschedules-form" name="sDash_validation-form" form={form} layout="vertical" onFinish={UpdateSchedule}>
                                                    <Form.Item
                                                        name="readtime"
                                                        rules={[{ required: false, message: 'Read Time is required!' }]}
                                                        label="Read Time (in  minutes)"
                                                    >
                                                        <Input id="topicschedule_readtime-input" type='number' placeholder='Readtime' onKeyDown={handleKeyDown}
                                                            onInput={(e) => {
                                                                const value = parseInt(e.target.value);
                                                                if (value < 0) {
                                                                    e.target.value = '0';
                                                                }
                                                            }}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="scheduleId"
                                                        rules={[{ required: true, message: 'Week is required!' }]}
                                                        label="Week"
                                                    >
                                                        <Select id="topicschedule_select-schedule" size='large'>
                                                            <Option value="">Select</Option>
                                                            {
                                                                Weeks.length !== 0 && Weeks.map((week, index) =>
                                                                    <Option key={index + 1} value={week.id}>{week.name}</Option>
                                                                )
                                                            }
                                                        </Select>
                                                    </Form.Item>

                                                    <div className='text-right mb-20'>
                                                        {
                                                            TopicScheduledData !== null && Object.keys(TopicScheduledData).length !== 0 && TopicScheduledData.scheduleId !== 0 &&
                                                            <Button id="topicschedule_cancel-updateschedule" type="primary" style={{ marginRight: '10px' }} outlined onClick={() => setShowUpdateForm(false)}>
                                                                Cancel
                                                            </Button>
                                                        }
                                                        <Button id="topicschedule_updateschedule-button" htmlType="submit" type="primary" >
                                                            {isScheduling ? 'Scheduling' : 'Schedule'}
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </FormValidationWrap>
                                        }
                                    </div>
                                    :
                                    <div>
                                        <Result
                                            status={'success'}
                                            title="Schedule Topic"
                                            subTitle={<div>
                                                <p className='m-0'>
                                                    Topic: {TopicInfo !== null ? TopicInfo.name : ''}
                                                </p>
                                                <p className='m-0'>
                                                    {'Week: '}
                                                    {
                                                        TopicScheduledData !== null && Object.keys(TopicScheduledData).length !== 0 && TopicScheduledData.weekname
                                                    }
                                                </p>
                                                <p className='m-0'>
                                                    {'Read Time: '}
                                                    {
                                                        TopicScheduledData !== null && Object.keys(TopicScheduledData).length !== 0 && TopicScheduledData.readtime
                                                    }
                                                    {' mins'}
                                                </p>
                                            </div>}
                                            extra={
                                                <div>
                                                    <Button id="topicschedule_editschedule-button" type="primary" onClick={() => { ShowScheduleForm() }}>Edit Schedule</Button>
                                                </div>
                                            }
                                        />
                                    </div>
                        }
                    </NoteCardWrap>
                </Col>
            </Row>
        </>
    );
}

export default TopicSchedule


const MyForm = ({ formInitialValues, UpdateSchedule, TopicScheduledData, handleKeyDown, setShowUpdateForm, Weeks, isScheduling }) => {
    const [form] = Form.useForm();
    form.setFieldsValue(formInitialValues)
    return (
        <FormValidationWrap>
            <Form name="sDash_validation-form" form={form} layout="vertical" onFinish={UpdateSchedule}>
                <Form.Item
                    name="readtime"
                    rules={[{ required: true, message: 'Read Time is required!' }]}
                    label="Read Time (minutes)"
                >
                    <Input type='number' placeholder='Readtime' onKeyDown={handleKeyDown} />
                </Form.Item>
                <Form.Item
                    name="scheduleId"
                    rules={[{ required: true, message: 'Week is required!' }]}
                    label="Week"
                >
                    <Select size='large'>
                        <Option value="">Select</Option>
                        {
                            Weeks.length !== 0 && Weeks.map((week, index) =>
                                <Option key={index + 1} value={week.id}>{week.name}</Option>
                            )
                        }
                    </Select>
                </Form.Item>

                <div className='text-right mb-20'>
                    {
                        TopicScheduledData !== null && Object.keys(TopicScheduledData).length !== 0 && TopicScheduledData.scheduleId !== 0 &&
                        <Button type="primary" style={{ marginRight: '10px' }} outlined onClick={() => setShowUpdateForm(false)}>
                            Cancel
                        </Button>
                    }
                    <Button htmlType="submit" type="primary" >
                        {isScheduling ? 'Scheduling' : 'Schedule'}
                    </Button>
                </div>
            </Form>
        </FormValidationWrap>
    );
}