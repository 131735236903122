import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Input, Modal, Form, message } from 'antd';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import { Cards } from '../../components/cards/frame/cards-frame';
import { TableWrapper, Main } from '../styled';
import { useDispatch, useSelector } from 'react-redux';
import { teachersDataRead } from '../../redux/teachers/actionCreator';
import { ProjectList, ProjectSorting } from '../project/style';
import { AutoComplete } from '../../components/autoComplete/autoComplete';
import { Button } from '../../components/buttons/buttons';
import { userspwdDataUpdate } from '../../redux/UsersPasswordChange/actionCreator';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { alertModal } from '../../components/modals/antd-modals';
import { getItem } from '../../utility/localStorageControl';

function Teachers() {
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editForm] = Form.useForm();
  const [searchText, setsearchText] = useState(null);
  const [editRecord, setEditRecord] = useState(null);

  const dispatch = useDispatch();
  const { teachers, isLoading, UsersPwdChange, isUsersPwdChanging } = useSelector((state) => {
    return {
      teachers: state.Teachers.data,
      isLoading: state.Teachers.loading,
      UsersPwdChange: state.UsersPwdChange.data,
      isUsersPwdChanging: state.UsersPwdChange.loading,
    };
  });

  useEffect(() => {
    dispatch(teachersDataRead());
  }, [dispatch]);

  useEffect(() => {
    if (UsersPwdChange !== null) {
      dispatch(teachersDataRead());
    }
  }, [UsersPwdChange])

  const [dataSource, setDataSource] = useState([]);
  const [GoodData, setGoodData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (teachers) {
      const filteredData = teachers.map((item, index) => {
        if (item && item.id && item.name && item.usermaster) {
          return {
            key: item.id,
            sno: index + 1,
            id: item.id,
            name: item.name,
            username: item.usermaster.username,
          };
        }
        return null;
      });
      setDataSource(filteredData);
      setGoodData(filteredData);
      setLoading(false);
    }
  }, [teachers])

  const checkSearch = (i) => {
    for (let j in i) {
      if (i[j] != null && i[j].toString().toLowerCase().includes(searchText.toString().toLowerCase())) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    let tempValues = [...GoodData];
    if (searchText != null) {
      tempValues = tempValues.filter(checkSearch);
    }
    setDataSource(tempValues);
  }, [searchText])

  const handleSearch = (e) => {
    setsearchText(e)
  }

  const columns = [
    {
      title: 'S.No.',
      dataIndex: 'sno',
      key: 'sno',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => a.username > b.username ? 1 : -1,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name > b.name ? 1 : -1,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <div className='table-actions'>
          <Button onClick={() => handleEdit(record)} key='editTeacherPassword' className="btn-icon " type="primary" to="#" shape="circle">
            <UilEdit />
          </Button>
        </div>
      ),
    },
  ];

  const [dispatchuser, setDispatchuser] = useState('');

  const validateUsername = (rule, value) => {
    if (!value) {
      return Promise.reject('Please enter student username');
    } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
      return Promise.reject('Username can only contain numbers and alphabets');
    } else {
      return Promise.resolve();
    }
  };

  const validatePassword = (rule, value) => {
    if (!value) {
      return Promise.reject('Please enter a password');
    } else if (/\s/.test(value)) {
      return Promise.reject('Password cannot contain spaces');
    } else {
      return Promise.resolve();
    }
  };

  const validateConfirmPassword = (rule, value) => {
    if (!value) {
      return Promise.reject('Please enter confirm password');
    } else if (/\s/.test(value)) {
      return Promise.reject('Password cannot contain spaces');
    } else {
      return Promise.resolve();
    }
  };

  const handleEdit = (record) => {
    setEditRecord(record);
    setDispatchuser(record.username);
    setIsEditModalVisible(true);
    editForm.setFieldsValue({
      username: record.username,
      password: '',
      confirmpassword: '',
    });
  };

  const handleModalUpdate = () => {
    editForm.validateFields().then((values) => {
      if (values.password !== values.confirmpassword) {
        message.error('passwords are not matched!');
      }
      else {
        const updatedRecord = {
          password: values.password,
          newusername: values.username,
          username: dispatchuser,
        };
        setIsEditModalVisible(false);
        alertModal.confirm({
          title: `Confirm change password ?`,
          content: 'When clicked the OK button, the password will be changed.',
          onOk() {
            dispatch(userspwdDataUpdate(updatedRecord));
          },
          onCancel() { },
        });
      }
    }).catch((error) => {
      console.error('Error while validating form:', error);
    });
  };


  const ButtonClicked = () => {
    setEditRecord(!editRecord);
  };

  return (
    <>
      <Main>
        {isLoading || isUsersPwdChanging ?
          (
            <div className="custom-loading-spin-with-text">
              <CustomSpinner />
              <br />
              <br />
              <p style={{ fontWeight: 'bold', color: '#2D5D85' }}>
                Loading please wait...
              </p>
            </div>
          ) : (
            <Row gutter={25}>
              <Col sm={24} xs={24}>
                <Cards headless>
                  <TableWrapper>
                    <ProjectList>
                      <div className='d-flex justify-content-between mb-15 p-0 align-center-v'>
                        <div className="d-flex align-center-v" style={{ marginLeft: 'auto' }}>
                          <AutoComplete id="teachers-reset_password-search-filter" onSearch={handleSearch} dataSource={dataSource} placeholder="Search here" patterns />
                        </div>
                      </div>
                      <div className='table-responsive'>
                        <Table dataSource={dataSource} columns={columns} />
                      </div>
                    </ProjectList>
                  </TableWrapper>
                </Cards>
              </Col>
            </Row>
          )
        }
      </Main>
      <Modal
        title="Edit Teacher Password"
        open={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        maskClosable={false}
        footer={[
          <Button id="teachers-reset_password-update-button" key="UpdateTeacherPassword" type="primary" onClick={handleModalUpdate}>
            Update
          </Button>
        ]}
      >
        <Form form={editForm} layout="vertical">
          <Form.Item name="username" label="UserName" rules={[{ validator: validateUsername }]}>
            <Input id="teachers-reset_password-username-disabled" disabled={!!editRecord}
              addonAfter=
              {<Button onClick={() => ButtonClicked()} key='editStudentPassword' className="btn-icon" to="#" shape="circle">
                <UilEdit />
              </Button>}
            />
          </Form.Item>
          <Form.Item name="password" label="Password" rules={[{ required: true, validator: validatePassword }]}>
            <Input.Password id="teachers-reset_password-enter-password" placeholder='Enter Password' />
          </Form.Item>
          <Form.Item name="confirmpassword" label="Confirm Password" rules={[{ required: true, validator: validateConfirmPassword }]}>
            <Input.Password id="teachers-reset_password-enter-confirm_password" placeholder='Confirm the entered password' />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Teachers;