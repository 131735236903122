import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';

const {
  subjectsAddBegin,
  subjectsAddSuccess,
  subjectsAddErr,

  subjectsReadBegin,
  subjectsReadSuccess,
  subjectsReadErr,

  subjectsByCohortIdBegin,
  subjectsByCohortIdSuccess,
  subjectsByCohortIdErr,

  subjectsUpdateBegin,
  subjectsUpdateSuccess,
  subjectsUpdateErr,
} = actions;

const subjectsDataSubmit = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(subjectsAddBegin());
      const response = await DataService.post('/subject-master/create', data);
      const token = getItem('access_token');
      if (token && response.data.Error === false) {
        await dispatch(subjectsAddSuccess(response.data));
        message.success(response.data.message);
      } else {
        if (response.data.Error === true && response.data.statusCode === 401) {
          message.error(response.data.message);
          dispatch(logOut());
        }
        throw response.data.message;
      }
    } catch (err) {
      await dispatch(subjectsAddErr(err));
      message.error(err);
    }
  };
};

const subjectsDataRead = () => {
  return async (dispatch) => {
    try {
      await dispatch(subjectsReadBegin());
      const query = await DataService.get(`/subject-master/all`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(subjectsReadSuccess(query.data.payload));
      } else {
        if (query.data.Error === true && query.data.statusCode === 401) {
          message.error(query.data.message);
          dispatch(logOut());
        }
        throw query.data.message;
      }
    } catch (err) {
      await dispatch(subjectsReadErr(err));
      message.error(err);
    }
  };
};

const subjectsDataUpdate = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(subjectsUpdateBegin());
      const response = await DataService.put(`/subject-master/update`, data);
      const token = getItem('access_token');
      if (token && response.data.Error === false) {
        await dispatch(subjectsUpdateSuccess(response.data));
        message.success(response.data.message);
      } else {
        if (response.data.Error === true && response.data.statusCode === 401) {
          message.error(response.data.message);
          dispatch(logOut())
        }
        throw response.data.message;
      }
    } catch (err) {
      await dispatch(subjectsUpdateErr(err));
      message.error(err);
    }
  };
};


const subjectsByCohortId = (cohortId) => {
  return async (dispatch) => {
    try {
      await dispatch(subjectsByCohortIdBegin());
      const query = await DataService.get(`/subject-master/find/${cohortId}`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(subjectsByCohortIdSuccess(query.data.payload));
      } else {
        if (query.data.Error === true && query.data.statusCode === 401) {
          message.error(query.data.message);
          dispatch(logOut());
        }
        throw query.data.message;
      }
    } catch (err) {
      await dispatch(subjectsByCohortIdErr(err));
      message.error(err);
    }
  };
};

export {
  subjectsDataRead,
  subjectsDataSubmit,
  subjectsDataUpdate,
  subjectsByCohortId
};
