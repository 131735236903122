import react, { useEffect } from 'react';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Table } from 'antd';
import { BorderLessHeading, TableDefaultStyle } from '../../styled';
import { TopSellerWrap } from '../styles/dashboardStyle';
import { Link } from 'react-router-dom';
import { UilExternalLinkAlt } from '@iconscout/react-unicons'
import { StudentRecentActionsDataRead } from '../../../redux/studentRecentActions/actionCreator';
import { useDispatch, useSelector } from 'react-redux';

function SubjectSummary() {

  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(StudentRecentActionsDataRead());
  }, [dispatch])

  const { StudentRecentActions } = useSelector((state) => {
    return {
      StudentRecentActions: state.StudentRecentActions.data,
      StudentRecentActionsLoading: state.StudentRecentActions.loading,
    };
  });

  const columns = [
    {
      title: 'Subject',
      dataIndex: 'subjectname',
      key: 'subjectname',
    },
    {
      title: 'Total Topics',
      dataIndex: 'topics',
      key: 'topics',
    },
    {
      title: 'Topics Completed',
      dataIndex: 'topicsCompleted',
      key: 'topicsCompleted',
    },
    {
      title: 'Average Grade',
      dataIndex: 'avgGrade',
      key: 'avgGrade',
    }
  ];

  let data = [
    {
      subjectname: 'Java',
      topics: 10,
      topicsCompleted: 2,
      avgGrade: '20%'
    },
    {
      subjectname: 'Data Structures',
      topics: 13,
      topicsCompleted: 5,
      avgGrade: '35%'
    }
  ]

  StudentRecentActions.length !== 0 && StudentRecentActions.map((obj, index) => {
    const { subjectId, subjectname, unitId, topicId, topicname, info, action, createdon } = obj
    data.push({
      key: index,
      subjectId,
      subjectname,
      unitId,
      topicId,
      topicname,
      info,
      action,
      createdon,
      link: <div className='table-actions'>
        <Link to={`subject/${subjectId}/unit/${unitId}/topic/${topicId}`}><UilExternalLinkAlt style={{ width: '18px' }} /></Link>
      </div>
    })
  })

  return (
    <div>
      <BorderLessHeading>
        <Cards title='Subject Summary'>
          <TableDefaultStyle className="ninjadash-having-header-bg" style={{ minHeight: '260px' }} >
            <TopSellerWrap>
              <div className="table-bordered top-seller-table table-responsive">
                <Table columns={columns} dataSource={data} pagination={false} />
              </div>
            </TopSellerWrap>
          </TableDefaultStyle>
        </Cards>
      </BorderLessHeading>
    </div>
  );
}

export default SubjectSummary