import UilFacebook from '@iconscout/react-unicons/icons/uil-facebook-f';
import UilGithub from '@iconscout/react-unicons/icons/uil-github';
import UilTwitter from '@iconscout/react-unicons/icons/uil-twitter';
import UilQuestionCircle from '@iconscout/react-unicons/icons/uil-question-circle';
import { Button, Col, Form, Input, Row, Skeleton } from 'antd';
import { Auth0Lock } from 'auth0-lock';
import React, { useCallback, useState, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ReactSVG } from 'react-svg';
import { AuthFormWrap } from './style';
import { Checkbox } from '../../../../components/checkbox/checkbox';
import { auth0options } from '../../../../config/auth0';
import { login } from '../../../../redux/authentication/actionCreator';
import ReCAPTCHA from "react-google-recaptcha";
import helpDoc from '../../../../static/help/WorkFlowGuideforStudents.pdf'


function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, mode } = useSelector((state) => {
    return {
      isLoading: state.auth.loading,
      mode: state.Layout.mode
    }
  });
  const [form] = Form.useForm();
  // const captchaRef = useRef(null)

  // const lock = new Auth0Lock(auth0options);

  const handleSubmit = useCallback(
    (values) => {
      // const token = captchaRef.current.getValue();
      // const token = await captchaRef.current.executeAsync().then(value => {
      //   console.log("executeAsync promise - Captcha value:", value);
      // });
      // values = { ...values, "token": token }
      // captchaRef.current.reset();
      dispatch(login(values));
    },
    [dispatch],
  );

  // lock.on('authenticated', (authResult) => {
  //   lock.getUserInfo(authResult.accessToken, (error) => {
  //     if (error) {
  //       return;
  //     }

  //     handleSubmit();
  //     lock.hide();
  //   });
  // });
  const grecaptchaObject = window.grecaptcha

  window.recaptchaOptions = {
    useRecaptchaNet: true,
    enterprise: true,
  };

  return (
    <Row justify="center">
      <Col xxl={6} xl={8} md={12} sm={18} xs={24}>
        <AuthFormWrap>
          {/* <div className="ninjadash-authentication-top">
            <h2 className="ninjadash-authentication-top__title">Sign in HexaDash</h2>
          </div> */}
          <div className="ninjadash-authentication-content">
            <Form id="signin_form" name="login" form={form} onFinish={handleSubmit} layout="vertical">
              <Form.Item
                name="username"
                rules={[{ message: 'Please input your username!', required: true }]}
                label="Username"
              >
                <Input id="signin_username-input" placeholder="Username" autoFocus />
              </Form.Item>
              <Form.Item name="password"
                label="Password"
                rules={[{ message: 'Please input your password!', required: true }]}
              >
                <Input.Password id="signin_password-input" placeholder="Password" />
              </Form.Item>
              {/* <div className="ninjadash-auth-extra-links">
                <NavLink className="forgot-pass-link" to="/forgotPassword">
                  Forgot password?
                </NavLink>
              </div> */}
              {/* <Form.Item name="token"
                label=""
                rules={[{ message: 'Captcha required!', required: true }]}
                className='text-center'
              >
                <ReCAPTCHA
                  style={{ display: "inline-block" }}
                  theme={mode === 'darkMode' ? "dark" : "light"}
                  size="normal"
                  sitekey={process.env.REACT_APP_SITE_KEY}
                // ref={captchaRef}
                // grecaptcha={grecaptchaObject}
                />
              </Form.Item> */}
              <Form.Item>
                <Button id="signin-button" className="btn-signin m-0" htmlType="submit" type="primary" size="large">
                  {isLoading ? 'Loading...' : 'Sign In'}
                </Button>
              </Form.Item>
            </Form>
            <div className='fontsize-13'>
              <a href={helpDoc} target='_blank' rel="noopener noreferrer">
                <div className='d-flex align-center-v justify-content-center'>
                  <UilQuestionCircle size={16} /> Help Doc for Students
                </div>
              </a>
            </div>
          </div>
        </AuthFormWrap>
      </Col>
    </Row>
  );
}

export default SignIn;
