const actions = {
  REGULATION_ADD_BEGIN: 'REGULATION_ADD_BEGIN',
  REGULATION_ADD_SUCCESS: 'REGULATION_ADD_SUCCESS',
  REGULATION_ADD_ERR: 'REGULATION_ADD_ERR',

  REGULATION_READ_BEGIN: 'REGULATION_READ_BEGIN',
  REGULATION_READ_SUCCESS: 'REGULATION_READ_SUCCESS',
  REGULATION_READ_ERR: 'REGULATION_READ_ERR',

  REGULATION_UPDATE_BEGIN: 'REGULATION_UPDATE_BEGIN',
  REGULATION_UPDATE_SUCCESS: 'REGULATION_UPDATE_SUCCESS',
  REGULATION_UPDATE_ERR: 'REGULATION_UPDATE_ERR',

  REGULATION_DELETE_BEGIN: 'REGULATION_DELETE_BEGIN',
  REGULATION_DELETE_SUCCESS: 'REGULATION_DELETE_SUCCESS',
  REGULATION_DELETE_ERR: 'REGULATION_DELETE_ERR',

  REGULATION_SINGLE_DATA_BEGIN: 'REGULATION_SINGLE_DATA_BEGIN',
  REGULATION_SINGLE_DATA_SUCCESS: 'REGULATION_SINGLE_DATA_SUCCESS',
  REGULATION_SINGLE_DATA_ERR: 'REGULATION_SINGLE_DATA_ERR',

  REGULATION_UPLOAD_BEGIN: 'REGULATION_UPLOAD_BEGIN',
  REGULATION_UPLOAD_SUCCESS: 'REGULATION_UPLOAD_SUCCESS',
  REGULATION_UPLOAD_ERR: 'REGULATION_UPLOAD_ERR',

  regulationUploadBegin: () => {
    return {
      type: actions.REGULATION_UPLOAD_BEGIN,
    };
  },

  regulationUploadSuccess: (data) => {
    return {
      type: actions.REGULATION_UPLOAD_SUCCESS,
      data,
    };
  },

  regulationUploadErr: (err) => {
    return {
      type: actions.REGULATION_UPLOAD_ERR,
      err,
    };
  },

  regulationAddBegin: () => {
    return {
      type: actions.REGULATION_ADD_BEGIN,
    };
  },

  regulationAddSuccess: (data) => {
    return {
      type: actions.REGULATION_ADD_SUCCESS,
      data,
    };
  },

  regulationAddErr: (err) => {
    return {
      type: actions.REGULATION_ADD_ERR,
      err,
    };
  },

  regulationReadBegin: () => {
    return {
      type: actions.REGULATION_READ_BEGIN,
    };
  },

  regulationReadSuccess: (data) => {
    return {
      type: actions.REGULATION_READ_SUCCESS,
      data,
    };
  },

  regulationReadErr: (err) => {
    return {
      type: actions.REGULATION_READ_ERR,
      err,
    };
  },

  regulationUpdateBegin: () => {
    return {
      type: actions.REGULATION_UPDATE_BEGIN,
    };
  },

  regulationUpdateSuccess: (data) => {
    return {
      type: actions.REGULATION_UPDATE_SUCCESS,
      data,
    };
  },

  regulationUpdateErr: (err) => {
    return {
      type: actions.REGULATION_UPDATE_ERR,
      err,
    };
  },

  regulationDeleteBegin: () => {
    return {
      type: actions.REGULATION_DELETE_BEGIN,
    };
  },

  regulationDeleteSuccess: (data) => {
    return {
      type: actions.REGULATION_DELETE_SUCCESS,
      data,
    };
  },

  regulationDeleteErr: (err) => {
    return {
      type: actions.REGULATION_DELETE_ERR,
      err,
    };
  },

  regulationSingleDataBegin: () => {
    return {
      type: actions.REGULATION_SINGLE_DATA_BEGIN,
    };
  },

  regulationSingleDataSuccess: (data) => {
    return {
      type: actions.REGULATION_SINGLE_DATA_SUCCESS,
      data,
    };
  },

  regulationSingleDataErr: (err) => {
    return {
      type: actions.REGULATION_SINGLE_DATA_ERR,
      err,
    };
  },
};

export default actions;
