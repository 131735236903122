import { 
    GET_PDF_BEGIN,
    GET_PDF_SUCCESS,
    GET_PDF_ERROR,

 } from './actions';

const initialState = {
    data: null,
    loading: false,
    error: null,
};


// student
const PdfFileReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PDF_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case GET_PDF_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false,
            };
        case GET_PDF_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

export { PdfFileReducer };
