import React, { useRef, useState } from 'react'
import { Microphone } from '../styled'
import { Checkbox, message } from 'antd'
import { Button } from '../../components/buttons/buttons'
import actions from '../../redux/interview/actions';
import { useDispatch } from 'react-redux'
import FontAwesome from 'react-fontawesome';
import { ReactMic } from 'react-mic'

const { saveInterviewSuccess, skipInterviewSuccess, submitInterviewSuccess, skipandSubmitInterviewSuccess, getInterviewQuestionsSuccess } = actions

export default function MicTest({ micTested, setMicTested, startInterview }) {

  const dispatch = useDispatch()  
  const testMicRef = useRef(null);  
  const [record, setRecord] = useState(false)

  const toggleMic = () => {    
    fetchInputDevices().then(async (res) => {
      if (res.Error === false) {
        setRecord(!record)        
      }
      else if (res.Error === true) {
        message.error(res.message)
      }
    })
  }      

  // Checking existance of microphone
  const fetchInputDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const inputDevices = devices.filter((device) => device.kind === 'audioinput');      
      if (inputDevices.length === 0) {
        setRecord(false);        
        return { Error: true, message: 'Microphone is not detected!' }
      }
      else {
        // Device found
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });          
          if (stream && stream.active && stream.getTracks().length > 0) {
            stream.getTracks().forEach(track => track.stop());
            return { Error: false, message: 'Device Found!' }
          }
        } catch (error) {
          setRecord(false)                  
          // If access is denied or an error occurs, update the state with the error          
          return { Error: true, message: 'Permission denied. Please enable microphone access in your browser settings.' }
        }

      }
    } catch (error) {
      setRecord(false)                  
      console.error('Error accessing microphone!:', error);
      return { Error: true, message: `Error accessing microphone + ${error}` }
    }
  };

  const handleStartInterview = () => {
    dispatch(submitInterviewSuccess(null))
    dispatch(skipandSubmitInterviewSuccess(null))
    dispatch(getInterviewQuestionsSuccess(null))
    dispatch(saveInterviewSuccess(null))
    dispatch(skipInterviewSuccess(null))
    
    setRecord(false);    
    startInterview();  
  }

  return (
    <div>
      <div className='text-center mt-20'>
        Note: Before starting the interview, please test your microphone
      </div>
      <div className='mictest mt-20'>
        <ReactMic
          record={record}
          className="sound-wave"
          strokeColor="#2D5D85"
          visualSetting='sinewave'
          channelCount={200}
          mimeType='audio/wav'
          backgroundColor="#f9f9f9"
          echoCancellation={true} // defaults -> false
          autoGainControl={true}  // defaults -> false
          noiseSuppression={true} // defaults -> false
        />
      </div>
      <div className='text-center d-flex justify-content-center align-center-v'>
        <Microphone ref={testMicRef} onClick={toggleMic} >          
          {record ? <FontAwesome name="microphone" /> : <FontAwesome name="microphone-slash" />}
        </Microphone>
      </div>
      <div className='text-center mt-25'>        
      </div>
      <div className='mt-15'>
        <Checkbox onClick={() => {setMicTested(!micTested); setRecord(false)}}>I have tested my mic.</Checkbox>
      </div>
      <div className='mt-15'>
        <Button type='primary'
          disabled={!micTested}
          onClick={() => { { handleStartInterview() } }}
        >
          Start Interview
        </Button>
      </div>

    </div>
  )
}
