import React, { useEffect, useState } from 'react'
import { SalesOverviewStyleWrap2 } from '../styles/dashboardStyle';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Link } from 'react-router-dom';
import DoughnutChart from '../../../components/charts/DoughnutChart';

export default function OverallPerformanceCohortWise({ overallPerformanceByCohort }) {

    const [selectedSubject, setSelectedSubject] = useState("");
    const [filters, setFilters] = useState([])
    const [percentages, setpercentages] = useState([])

    const labels = overallPerformanceByCohort.length !== 0 ? overallPerformanceByCohort[0].data?.map((label) => label.level) : []

    const options = {
        cutout: 62,
        borderWidth: 2,
        borderColor: '#fff',
        maintainAspectRatio: false,
        responsive: false,
        plugins: {
            legend: {
                display: false,
            },
            labels: {
                display: false,
            },
        },
        animation: {
            animateScale: true,
            animateRotate: true,
        },
    };

    useEffect(() => {
        const subjectFilters = [];
        if (overallPerformanceByCohort.length !== 0) {
            setSelectedSubject(overallPerformanceByCohort[0].subjectName)
            overallPerformanceByCohort.map((value, index) => {
                const { subjectName, cohortName, data } = value;
                return subjectFilters.push({
                    key: index + 1,
                    subjectName,
                    cohortName,
                    data
                });
            })
            const allpercentages = overallPerformanceByCohort.reduce((acc, subject) => {
                const percentages = subject.data.map(item => parseFloat(item.percentage));
                acc[subject.subjectName] = percentages;
                return acc;
            }, {});
            setpercentages(allpercentages[overallPerformanceByCohort[0].subjectName])
            setFilters(subjectFilters)
        }
        else {
            setSelectedSubject("")
            setFilters([])
            setpercentages([])
        }
    }, [overallPerformanceByCohort])

    const allpercentages = overallPerformanceByCohort.reduce((acc, subject) => {
        const percentages = subject.data.map(item => parseFloat(item.percentage));
        acc[subject.subjectName] = percentages;
        return acc;
    }, {});

    const dataset = [
        {
            data: percentages,
            backgroundColor: ['#B3CEE5', '#4C8CC2', '#2D5D85'],
            centerText: percentages.reduce((total, currentValue) => total + currentValue, 0) + '%',
            centerTextLabel: '',
        }
    ]

    const handleTabChange = (subject, cohort, event) => {
        if (allpercentages) {
            event.preventDefault();
            setSelectedSubject(subject);
            setpercentages(allpercentages[subject])
        }

    };

    return (
        <div>
            <SalesOverviewStyleWrap2>
                <Cards
                    isbutton={
                        <div className="ninjadash-card-nav">
                            <ul>
                                {
                                    filters.length !== 0 && filters.map((obj, index) =>
                                        <li key={index + 1} className={obj.subjectName === selectedSubject ? 'ninjadash-active' : ''}>
                                            <Link onClick={(e) => handleTabChange(obj.subjectName, obj.cohortName, e)} to="#">
                                                {obj.subjectName} -&nbsp;<span className='fontsize-12'>{obj.cohortName}</span>
                                            </Link>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    }
                    title="Overall Performance"
                    size="large"
                >
                    <div className="ninjadash-overview-wrap">
                        {/* Doughnut Chart */}

                        <DoughnutChart
                            type="doughnut"
                            id="overallperformance"
                            labels={labels}
                            datasets={dataset}
                            height={200}
                            width={window.innerWidth <= 575 ? 200 : 250}
                            option={options}
                            tooltip={{
                                backgroundColor: '#FFF',
                                titleFontSize: 16,
                                titleFontColor: '#0066ff',
                                bodyFontColor: '#000',
                                bodyFontSize: 14,
                                displayColors: false,

                                callbacks: {},
                            }}
                        />

                        {/* Pverview Percentage */}
                        <div className="ninjadash-overview-percentage">
                            {percentages.map((value, index) => {
                                const itemPercent = value;
                                return (
                                    <div className="ninjadash-overview-percentage__item" key={index}>
                                        <span
                                            className="ninjadash-overview-percentage__point"
                                            style={{
                                                backgroundColor: dataset[0].backgroundColor[index],
                                            }}
                                        />
                                        <span className="ninjadash-overview-percentage__text">{labels[index]} ({itemPercent}%)</span>
                                    </div>
                                );
                            })}
                        </div>

                        {/* <div className="ninjadash-overview-box align-center-v justify-content-between">
                                {percentages.map((value, index) => {
                                    return (
                                        <div className="ninjadash-overview-box-item" key={index}>
                                            <h4>{value}</h4>
                                            <p>{labels[index]}</p>
                                        </div>
                                    );
                                })}
                            </div> */}
                    </div>
                </Cards>
            </SalesOverviewStyleWrap2>
        </div>
    )
}
