import React, { useEffect, useState } from 'react'
import { Col, Result, Row } from 'antd'
import { UilUser, UilStopwatch, UilShieldQuestion, UilAward, UilAwardAlt, UilBooks, UilBullseye } from '@iconscout/react-unicons'
import { OverviewDataStyleWrap } from '../styles/dashboardStyle'
import OverviewCard from '../../../components/cards/OverviewCard'
import OverviewCardMesh from '../../../components/cards/OverviewCardMesh'

export default function TodaysSummary({ todaysOverallSummary }) {

    const [summaryData, setSummaryData] = useState([])
    useEffect(() => {
        const Data = [
            {
                key: 1,
                type: "primary",
                icon: <UilStopwatch />,
                label: "Time Spent (Mins)",
                total: todaysOverallSummary?.totalMinutesSpentToday || 0,
                statusRate : null
            },
            {
                key: 2,
                type: "primary",
                icon: <UilShieldQuestion />,
                label: "Questions Answered",
                total: todaysOverallSummary?.questionsAnsweredToday || 0,
                statusRate : null
            },
            {
                key: 3,
                type: "primary",
                icon: <UilAward />,
                label: "Avg Score",
                total: todaysOverallSummary?.averageScoreToday || 0,
                statusRate : null
            },
            {
                key: 4,
                type: "primary",
                icon: <UilBullseye />,
                label: "Interviews Taken",
                total: todaysOverallSummary?.interviewsToday || 0,
                statusRate : null
            },
        ]
        setSummaryData(Data)
    }, [todaysOverallSummary])

    return (
        <div>
            {
                summaryData.length !== 0 &&
                <Row>
                    <Col xs={24}>
                        <OverviewDataStyleWrap className="card-mesh-wrap align-center-v">
                            {summaryData.map((item, i) =>
                                <OverviewCardMesh data={item} circleIcon={true} key={i} />
                            )}
                        </OverviewDataStyleWrap>
                    </Col>
                </Row>
            }
        </div>
    )
}
