import {
  UilBooks,
  UilCreateDashboard,
  UilCalender,
  UilMeetingBoard,
  UilLaptop
} from '@iconscout/react-unicons';
import { Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
// import { changeDirectionMode, changeLayoutMode, changeMenuMode } from '../redux/themeLayout/actionCreator';
import { getItem } from '../utility/localStorageControl';

const userId = getItem('userId')

function MenuItems({ toggleCollapsed }) {

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const dispatch = useDispatch();

  const path = '/teacher';

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  // const changeLayout = (mode) => {
  //   dispatch(changeLayoutMode(mode));
  // };
  // const changeNavbar = (topMode) => {
  //   const html = document.querySelector('html');
  //   if (topMode) {
  //     html.classList.add('ninjadash-topmenu');
  //   } else {
  //     html.classList.remove('ninjadash-topmenu');
  //   }
  //   dispatch(changeMenuMode(topMode));
  // };
  // const changeLayoutDirection = (rtlMode) => {
  //   if (rtlMode) {
  //     const html = document.querySelector('html');
  //     html.setAttribute('dir', 'rtl');
  //   } else {
  //     const html = document.querySelector('html');
  //     html.setAttribute('dir', 'ltr');
  //   }
  //   dispatch(changeDirectionMode(rtlMode));
  // };

  // const darkmodeActivated = () => {
  //   document.body.classList.add('dark-mode');
  // };

  // const darkmodeDiactivated = () => {
  //   document.body.classList.remove('dark-mode');
  // };


  const [createTopicModal, setCreateTopicModal] = useState(false)

  // const subjects = []

  // SubjectsUnits !== null && SubjectsUnits.subjects.length !== 0 && SubjectsUnits.subjects.map((subjObj) =>
  //   subjects.push(
  //     getItem(subjObj.name, subjObj.code, !topMenu && <UilBooks />,
  //       subjObj.units.map((unitObj) =>
  //         getItem(
  //           <NavLink
  //             to={`${path}/subjects/subject/${subjObj.id}/unit/${unitObj.id}`}
  //             onClick={toggleCollapsed}
  //           >
  //             {unitObj.name} ({unitObj.topics.length})
  //           </NavLink>,
  //           unitObj.id,
  //           null,
  //         )),
  //     )
  //   ))

  // subjects.push(
  //   getItem(
  //     <li className='align-center pt-10'>
  //       <Button type='primary' outlined onClick={() => { setCreateTopicModal(true) }} >+ Create Topic</Button>
  //     </li>,
  //     'create-topic',
  //     null
  //   )
  // )

  const items = [
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}`}>
    //     Dashboard
    //   </NavLink>,
    //   'dashboard',
    //   !topMenu &&
    //   <NavLink className="menuItem-iocn" to={`${path}`}>
    //     <UilCreateDashboard />
    //   </NavLink>,
    // ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}`}>
        Subjects
      </NavLink>,
      'teacher',
      !topMenu &&
      <NavLink className="menuItem-iocn" to={`${path}`}>
        <UilBooks />
      </NavLink>,
    ),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/schedules`}>
    //     Schedules
    //   </NavLink>,
    //   'schedules',
    //   !topMenu &&
    //   <NavLink className="menuItem-iocn" to={`${path}/schedules`}>
    //     <UilCalender />
    //   </NavLink>,
    // ),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/Interview_reports`}>
    //     Interviews
    //   </NavLink>,
    //   'Interview_reports',
    //   !topMenu &&
    //   <NavLink className="menuItem-iocn" to={`${path}/Interview_reports`}>
    //     <UilMeetingBoard />
    //   </NavLink>,
    // ),

    // ...subjects,

    // getItem('Students', 'students', !topMenu && <UilUsersAlt />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/monitor-students`}>
    //       Activity Monitor
    //     </NavLink>,
    //     'students',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/answers`}>
    //       Q & A Submissions
    //     </NavLink>,
    //     'answers',
    //     null,
    //   ),

    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/assessment`}>
    //       Unlock Logins
    //     </NavLink>,
    //     'assessment',
    //     null,
    //   )
    // ]),

    // getItem(t('layouts'), 'layout', !topMenu && <UilWindowSection />, [
    //   getItem(
    //     <NavLink
    //       onClick={() => {
    //         toggleCollapsed();
    //         darkmodeDiactivated();
    //         changeLayout('lightMode');
    //       }}
    //       to="#"
    //     >
    //       {t('light')} {t('mode')}
    //     </NavLink>,
    //     'light',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink
    //       onClick={() => {
    //         toggleCollapsed();
    //         darkmodeActivated();
    //         changeLayout('darkMode');
    //       }}
    //       to="#"
    //     >
    //       {t('dark')} {t('mode')}
    //     </NavLink>,
    //     'dark',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink
    //       onClick={() => {
    //         toggleCollapsed();
    //         changeNavbar(true);
    //       }}
    //       to="#"
    //     >
    //       {t('top')} {t('menu')}
    //     </NavLink>,
    //     'topMenu',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink
    //       onClick={() => {
    //         toggleCollapsed();
    //         changeNavbar(false);
    //       }}
    //       to="#"
    //     >
    //       {t('side')} {t('menu')}
    //     </NavLink>,
    //     'sideMenu',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink
    //       onClick={() => {
    //         toggleCollapsed();
    //         changeLayoutDirection(true);
    //       }}
    //       to="#"
    //     >
    //       RTL
    //     </NavLink>,
    //     'rtl',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink
    //       onClick={() => {
    //         toggleCollapsed();
    //         changeLayoutDirection(false);
    //       }}
    //       to="#"
    //     >
    //       LTR
    //     </NavLink>,
    //     'ltr',
    //     null,
    //   ),
    // ]),

  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
            `${mainPathSplit.length === 1 ? 'dashboard' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
            }`,
          ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
