import React from 'react'

const randomImage = (index) => {

    const imageUrls = [
        "static/img/techimages/1.jpg",
        "static/img/techimages/2.avif",
        "static/img/techimages/3.webp",
        "static/img/techimages/4.webp",
        "static/img/techimages/5.webp",
        "static/img/techimages/6.jpg",
        "static/img/techimages/7.jpg",
        "static/img/techimages/8.jpg",
        "static/img/techimages/9.webp",
        "static/img/techimages/10.jpg",
        "static/img/techimages/11.webp",
    ]

    const randomIndex = Math.floor(Math.random() * imageUrls.length);    
    // return imageUrls[index]
    return imageUrls[randomIndex]

}

export default randomImage