import React from 'react';
import { Avatar, Table } from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { BorderLessHeading, TableDefaultStyle } from '../../styled';
import { TopSellerWrap } from '../styles/dashboardStyle';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function WeeklyTopics({ StudentWeeklyTopics }) {

    const [displayAll, setDisplayAll] = useState(false);
    const columns = [
        {
            title: 'Topic',
            dataIndex: 'topicName',
            key: 'topicName',
        },
        {
            title: 'Est. Read Time',
            key: 'readTime',
            render: (text, record) => (
                <span>{record.readTime} min</span>
            ),
        },
        {
            title: 'Schedule',
            dataIndex: 'scheduleName',
            key: 'scheduleName',
        },
        {
            title: 'Unit',
            dataIndex: 'unitName',
            key: 'unitName',
        },
        {
            title: 'Subject',
            dataIndex: 'subjectName',
            key: 'subjectName',
        },
        // {
        //     title: (
        //         <div>
        //             Est. Read Time
        //             <br />
        //             (min)
        //         </div>
        //     ),
        //     dataIndex: 'readTime',
        //     key: 'readTime',
        // },
    ];

    const { currentWeek, currentWeekLoading } = useSelector((state) => {
        return {
            currentWeek: state.currentWeek.data,
            currentWeekLoading: state.currentWeek.loading
        }
    })

    let data = []

    StudentWeeklyTopics.length !== 0 && StudentWeeklyTopics.map((obj, index) => {
        const { subjectName, unitName, topicName, scheduleName, readTime, QuizAttemptData, QaAttemptData, topicReadBadge, topicCompleteBadge } = obj
        data.push({
            key: index,
            subjectName,
            unitName,
            topicName: topicName,
            // <div>
            //     {
            //         QaAttemptData === 0 ?
            //             <div className='align-center-v'><Avatar className={QuizAttemptData === 0 ? 'img-grayscale' : ''} src={process.env.REACT_APP_API_ENDPOINT + topicReadBadge} size={16} />&nbsp;{topicName}</div>
            //             :
            //             <div className='align-center-v'><Avatar src={process.env.REACT_APP_API_ENDPOINT + topicCompleteBadge} size={16} />&nbsp;{topicName}</div>
            //     }
            // </div>,
            scheduleName,
            readTime,
        })
    })
    const filteredData = data.filter(obj => obj.scheduleName.toLowerCase() === (currentWeek !== null && currentWeek.name.toLowerCase()));
    const rowsToShow = displayAll ? filteredData : filteredData.slice(0, 4);

    return (
        <div>
            <BorderLessHeading>
                <Cards title='Current Week Schedule'>
                    <TableDefaultStyle className="ninjadash-having-header-bg" style={{ minHeight: filteredData.length > 4 ? '230px' : '260px' }} >
                        <TopSellerWrap>
                            <div className="table-bordered top-seller-table table-responsive">
                                <Table columns={columns} dataSource={rowsToShow} pagination={false} />
                            </div>
                        </TopSellerWrap>
                    </TableDefaultStyle>
                    {filteredData.length > 4 && (
                        <div style={{ marginTop: 10 }}>
                            <Link onClick={() => setDisplayAll(!displayAll)}>
                                {displayAll ? 'See Less' : 'See More'}
                            </Link>
                        </div>
                    )}
                </Cards>
            </BorderLessHeading>
        </div>
    );
}

export default WeeklyTopics;
