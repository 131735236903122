import react, { useEffect, useState } from 'react'
import { Main } from '../styled';
import { Col, Collapse, Input, Radio, Result, Row, Select } from 'antd';
import { NoteCardWrap } from '../note/style';
import { ProjectSorting } from '../project/style';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import { getQuizByTopic } from '../../redux/generateQuiz/actionCreator';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { FaqWrapper } from './styles/tablestyle';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';
import UilMinus from '@iconscout/react-unicons/icons/uil-minus';
import TopicStatusId from './TopicStatusId'
import { Button } from '../../components/buttons/buttons';

const { Option } = Select;
const { Panel } = Collapse;

function ViewQuiz({ topicStatus }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { subjectId, unitId, topicId } = useParams()

    const [selectedCategory, setCategory] = useState("Easy")
    const [QA, setQA] = useState([])

    const { quizData, isLoading } = useSelector((state) => {
        return {
            quizData: state.AllQuizByTopic.data,
            isLoading: state.AllQuizByTopic.loading,
        };
    });

    useEffect(() => {
        if (TopicStatusId(topicStatus) > 4) {
            dispatch(getQuizByTopic(parseInt(topicId)));
        }
    }, [topicId]);

    useEffect(() => {
        if (quizData !== null && quizData.quizes[`${selectedCategory.toLocaleLowerCase()}`].length !== 0) {
            setQA(quizData.quizes[`${selectedCategory.toLocaleLowerCase()}`])
            // setCurrentQA({"question":quizData.quizes[`${selectedCategory.toLocaleLowerCase()}`][selectedQuestion].question,"answer":quizData.quizes[`${selectedCategory.toLocaleLowerCase()}`][selectedQuestion].answer})
        }
        else if (quizData !== null && quizData.quizes[`${selectedCategory.toLocaleLowerCase()}`].length === 0) {
            setQA([])
        }
    }, [quizData, selectedCategory]);

    return (
        <>
            <NoteCardWrap>
                {
                    TopicStatusId(topicStatus) === 0 ?
                        <Result
                            status="warning"
                            title="Content not found"
                            extra={
                                <Button id="viewmcq_uploadcontent-button" type="primary" key="upload" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/upload`)}>
                                    Upload
                                </Button>
                            }
                        />
                        :
                        isLoading ?
                            <div className='custom-spin'>
                                <CustomSpinner />
                            </div>
                            :
                            TopicStatusId(topicStatus) > 4 ?
                                <div>
                                    <div className='align-center-v d-flex justify-content-between'>
                                        <h3>M C Q
                                            {/* : {quizData !== null && quizData.name} */}
                                        </h3>
                                        <ProjectSorting>
                                            <div className="project-sort-bar">
                                                <div className="project-sort-nav" style={{ boxShadow: '0px 0px 15px 0px #eee', borderRadius: '6px' }}>
                                                    <nav>
                                                        <ul>
                                                            <li className={selectedCategory === 'Easy' ? 'active' : 'deactivate'}>
                                                                <Link id="viewmcq_easy-button" onClick={() => { setCategory('Easy') }} to="#">
                                                                    Easy ({quizData !== null && quizData.quizes[`easy`].length})
                                                                </Link>
                                                            </li>
                                                            <li className={selectedCategory === 'Medium' ? 'active' : 'deactivate'}>
                                                                <Link id="viewmcq_medium-button" onClick={() => { setCategory('Medium') }} to="#">
                                                                    Medium ({quizData !== null && quizData.quizes[`medium`].length})
                                                                </Link>
                                                            </li>
                                                            <li className={selectedCategory === 'Hard' ? 'active' : 'deactivate'}>
                                                                <Link id="viewmcq_hard-button" onClick={() => { setCategory('Hard') }} to="#">
                                                                    Hard ({quizData !== null && quizData.quizes[`hard`].length})
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </div>
                                        </ProjectSorting>
                                    </div>
                                    <Row justify="center">
                                        <Col xs={24}>
                                            <FaqWrapper>
                                                {
                                                    QA.length !== 0 ? QA.map((Object, index) =>
                                                        <MyForm key={index} index={index + 1} currentQA={Object} options={JSON.parse(Object.optionswithanswer)} />
                                                    )
                                                        :
                                                        <div className='spin' style={{ fontSize: '18px' }}>
                                                            No Data Found
                                                        </div>
                                                }
                                            </FaqWrapper>
                                        </Col>
                                    </Row>
                                </div>
                                :
                                <Result
                                    status="warning"
                                    title="MCQS not found!"
                                    extra={
                                        <Button id="viewmcq_generatemcq-button" type="primary" key="generateQuiz" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/generate/quiz`)}>
                                            Generate MCQS
                                        </Button>
                                    }
                                />
                }
            </NoteCardWrap>
        </>
    );
}

export default ViewQuiz


const MyForm = ({ currentQA, options, index }) => {

    return (
        // <div className='mb-20' style={{ border: '1px solid #eee', borderRadius: '10px', padding: '15px' }}>
        //     <div>
        //         <h4>Question</h4>
        //         <p>{currentQA.question}</p>
        //     </div>
        //     <div className='mt-20'>
        //         <h4>Options</h4>
        //         {
        //             Object.keys(options).sort((a, b) => a > b ? 1 : -1).map((obj, index) =>
        //                 obj !== 'ans' &&
        //                 <p key={index} style={{ color: options['ans'] === obj && 'green', fontWeight: options['ans'] === obj && '500' }}>{obj}{'. '}{options[obj]}</p>
        //             )
        //         }
        //     </div>
        // </div>        
        <Collapse defaultActiveKey={['1']} bordered={false} expandIcon={({ isActive }) => (isActive ? <UilMinus /> : <UilPlus />)} className='m-0 mb-5' >
            <Panel header={index + '. ' + currentQA.question} key={index}>
                <h4>Options</h4>
                <div className='m-0'>
                    {
                        Object.keys(options).sort((a, b) => a > b ? 1 : -1).map((obj, index) =>
                            obj !== 'ans' &&
                            <p className='m-0' key={index} style={{ color: options['ans'] === obj && 'green', fontWeight: options['ans'] === obj && '500' }}>{obj}{'. '}{options[obj]}</p>
                        )
                    }
                </div>
            </Panel>
        </Collapse>
    );
}