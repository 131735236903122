export const GET_ANSWERS_BEGIN = 'GET_ANSWERS_BEGIN';
export const GET_ANSWERS_SUCCESS = 'GET_ANSWERS_SUCCESS';
export const GET_ANSWERS_ERROR = 'GET_ANSWERS_ERROR';

export const readAnswersBegin = () => ({
    type: GET_ANSWERS_BEGIN,
});

export const readAnswersSuccess = (data) => ({
    type: GET_ANSWERS_SUCCESS,
    payload: data,
});

export const readAnswersError = (error) => ({
    type: GET_ANSWERS_ERROR,
    payload: error,
});
