import actions from './actions';

const {
  SUBJECT_WISE_PERFORMANCE_READ_BEGIN,
  SUBJECT_WISE_PERFORMANCE_READ_SUCCESS,
  SUBJECT_WISE_PERFORMANCE_READ_ERR,

  TOPIC_WISE_PERFORMANCE_READ_BEGIN,
  TOPIC_WISE_PERFORMANCE_READ_SUCCESS,
  TOPIC_WISE_PERFORMANCE_READ_ERR,

  SUBJECT_SUMMARY_READ_BEGIN,
  SUBJECT_SUMMARY_READ_SUCCESS,
  SUBJECT_SUMMARY_READ_ERR,

  SINGLE_SUBJECT_SUMMARY_READ_BEGIN,
  SINGLE_SUBJECT_SUMMARY_READ_SUCCESS,
  SINGLE_SUBJECT_SUMMARY_READ_ERR,

  SUBJECTS_BEST_PERFORMER_READ_BEGIN,
  SUBJECTS_BEST_PERFORMER_READ_SUCCESS,
  SUBJECTS_BEST_PERFORMER_READ_ERR,

  ACTIVE_STUDENTS_READ_BEGIN,
  ACTIVE_STUDENTS_READ_SUCCESS,
  ACTIVE_STUDENTS_READ_ERR,

  INACTIVE_STUDENTS_READ_BEGIN,
  INACTIVE_STUDENTS_READ_SUCCESS,
  INACTIVE_STUDENTS_READ_ERR,

  OVERALL_PERFORMANCE_BY_COHORT_BEGIN,
  OVERALL_PERFORMANCE_BY_COHORT_SUCCESS,
  OVERALL_PERFORMANCE_BY_COHORT_ERR,

  OVERALL_SUMMARY_BEGIN,
  OVERALL_SUMMARY_SUCCESS,
  OVERALL_SUMMARY_ERR,

  TODAYS_OVERALL_SUMMARY_BEGIN,
  TODAYS_OVERALL_SUMMARY_SUCCESS,
  TODAYS_OVERALL_SUMMARY_ERR,

  STUDENT_OVERALL_SUMMARY_BEGIN,
  STUDENT_OVERALL_SUMMARY_SUCCESS,
  STUDENT_OVERALL_SUMMARY_ERR,
} = actions;

const subjectWiseInitialState = {
  data: null,
  loading: false,
  error: null,
};

const topicWiseInitialState = {
  data: null,
  loading: false,
  error: null,
};

const subjectSummaryInitialState = {
  data: null,
  loading: false,
  error: null,
};

const singleSubjectSummaryInitialState = {
  data: null,
  loading: false,
  error: null,
};

const bestperformerInitialState = {
  data: [],
  loading: false,
  error: null,
};

const activeStudentsInitialState = {
  data: [],
  loading: false,
  error: null,
};

const inactiveStudentsInitialState = {
  data: [],
  loading: false,
  error: null,
};

const overallPerformanceInitialState = {
  data: [],
  loading: false,
  error: null,
};

const overallSummaryInitialState = {
  data: null,
  loading: false,
  error: null,
};

const todaysOverallSummaryInitialState = {
  data: null,
  loading: false,
  error: null,
};

const studentOverallSummaryInitialState = {
  data: null,
  loading: false,
  error: null,
};

const SubjectWisePerformanceReducer = (state = subjectWiseInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SUBJECT_WISE_PERFORMANCE_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case SUBJECT_WISE_PERFORMANCE_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case SUBJECT_WISE_PERFORMANCE_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const TopicWisePerformanceReducer = (state = topicWiseInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case TOPIC_WISE_PERFORMANCE_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TOPIC_WISE_PERFORMANCE_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TOPIC_WISE_PERFORMANCE_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const SubjectSummaryReducer = (state = subjectSummaryInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SUBJECT_SUMMARY_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case SUBJECT_SUMMARY_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case SUBJECT_SUMMARY_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const SingleSubjectSummaryReducer = (state = singleSubjectSummaryInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SINGLE_SUBJECT_SUMMARY_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case SINGLE_SUBJECT_SUMMARY_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case SINGLE_SUBJECT_SUMMARY_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const SubjectsBestPerformerReducer = (state = bestperformerInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SUBJECTS_BEST_PERFORMER_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case SUBJECTS_BEST_PERFORMER_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case SUBJECTS_BEST_PERFORMER_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const ActiveStudentsReducer = (state = activeStudentsInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ACTIVE_STUDENTS_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ACTIVE_STUDENTS_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case ACTIVE_STUDENTS_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const InactiveStudentsReducer = (state = inactiveStudentsInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case INACTIVE_STUDENTS_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case INACTIVE_STUDENTS_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case INACTIVE_STUDENTS_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const OverallPerformanceByCohortReducer = (state = overallPerformanceInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case OVERALL_PERFORMANCE_BY_COHORT_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case OVERALL_PERFORMANCE_BY_COHORT_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case OVERALL_PERFORMANCE_BY_COHORT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const OverallSummaryReducer = (state = overallSummaryInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case OVERALL_SUMMARY_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case OVERALL_SUMMARY_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case OVERALL_SUMMARY_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const TodaysOverallSummaryReducer = (state = todaysOverallSummaryInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case TODAYS_OVERALL_SUMMARY_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TODAYS_OVERALL_SUMMARY_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TODAYS_OVERALL_SUMMARY_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const StudentOverallSummaryReducer = (state = studentOverallSummaryInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case STUDENT_OVERALL_SUMMARY_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case STUDENT_OVERALL_SUMMARY_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case STUDENT_OVERALL_SUMMARY_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export {
  SubjectWisePerformanceReducer,
  TopicWisePerformanceReducer,
  SubjectSummaryReducer,
  SingleSubjectSummaryReducer,
  SubjectsBestPerformerReducer,
  ActiveStudentsReducer,
  InactiveStudentsReducer,
  OverallPerformanceByCohortReducer,
  OverallSummaryReducer,
  TodaysOverallSummaryReducer,
  StudentOverallSummaryReducer
};
