import { Form, Input, message, Modal, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GetQuestionsOfTopicDataRead, ImportQuestionsOfTopic } from '../../redux/teacherSubjectsUnits/actionCreator';
import { Button } from '../../components/buttons/buttons';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
// import { MailBox } from '../email/overview/style';
// import RichTextEditor from 'react-rte';
import { useParams } from 'react-router-dom';
import Sortable from 'sortablejs';
import CodeMirror from "@uiw/react-codemirror";
import { UilShuffle } from '@iconscout/react-unicons'
import { filterBlankLines, handleShuffle, renderCodeWithLineNumbers } from './QuestionMethods';

const { Option } = Select

export default function AddQuestion({ createQuestionModal, setCreateQuestionModal }) {

    const { topicId } = useParams()
    const dispatch = useDispatch()
    const [createform] = Form.useForm();
    const [questionType, setQuestionType] = useState(null)
    // const [codeQuestion, setCodeQuestion] = useState({
    //     value: RichTextEditor.createEmptyValue()
    // });
    const jumbleRef = useRef(null);
    const [code, setCode] = useState('');
    const [errorCode, setErrorCode] = useState('');
    // const [explainCode, setExplainCode] = useState('');
    // const [predictOutputCode, setPredictOutputCode] = useState('');

    const { CreateQuestionData, isCreateQuestionDataLoading } = useSelector((state) => {
        return {
            CreateQuestionData: state.CreateQuestion.data,
            isCreateQuestionDataLoading: state.CreateQuestion.loading,
        };
    });

    useEffect(() => {
        // setCodeQuestion({ value: RichTextEditor.createEmptyValue() });
        setCode('')
        createform.resetFields()
        setQuestionType(null)
    }, [createQuestionModal])

    useEffect(() => {
        if (CreateQuestionData !== null && CreateQuestionData.Error === false) {
            setCreateQuestionModal(false);
            createform.resetFields();
            setCode('')
            // setCodeQuestion({ value: RichTextEditor.createEmptyValue() });
            dispatch(GetQuestionsOfTopicDataRead(topicId));
        }
    }, [CreateQuestionData])

    // const onCodeQuestionChange = (value) => {
    //     setCodeQuestion({ ...codeQuestion, value });
    // };

    useEffect(() => {
        if (jumbleRef.current) {
            const sortable = new Sortable(jumbleRef.current, {
                animation: 150,
            });

            return () => {
                sortable.destroy();
            };
        }
    }, [code]);

    // // Handle sorting
    // const handleSortChange = () => {
    //     const sortedLines = Array.from(jumbleRef.current.childNodes).map(node => node.innerText);
    //     // setShuffledCode(sortedLines);
    //     console.log(sortedLines)
    // };

    const handleCreateQuestionSubmit = () => {
        const expectedConceptualKnowledge = createform.getFieldValue('expectedConceptualKnowledge')
        const questionfield = createform.getFieldValue('question') || ''
        // const codeQ = codeQuestion.value.toString('html')

        let question = ''
        let program = ''
        let modifiedProgram = ''
        if (questionType == "JUMBLED CODE" && questionfield !== "" && jumbleRef.current !== null && code !== "") {
            const jumbledCode = Array.from(jumbleRef.current.children)
                .map((li) => li.textContent.replace(/^(?:.*?(\d+):\s*)?(.*)$/, '$2'))
                .join('\n');
            question = questionfield
            program = code
            modifiedProgram = jumbledCode
        }
        else if (questionType == "DEBUG CODE" && questionfield !== "" && code !== "" && errorCode !== "") {
            question = questionfield
            program = code
            modifiedProgram = errorCode
        }
        else if ((questionType == "THEORY" || questionType == "VIVA") && questionfield !== "") {
            question = questionfield
            program = null
            modifiedProgram = null
        }
        else if (questionType == "CODE" && code !== "") {
            question = code
            program = null
            modifiedProgram = null
        }
        else if (questionType == "EXPLAIN CODE" && questionfield !== "" && (createform.getFieldValue('explainprogram') !== "" && createform.getFieldValue('explainprogram') !== undefined)) {
            question = questionfield
            program = null
            modifiedProgram = createform.getFieldValue('explainprogram')
        }
        else if (questionType == "PREDICT OUTPUT" && questionfield !== "" && (createform.getFieldValue('predictprogram') !== "" && createform.getFieldValue('predictprogram') !== undefined)) {
            question = questionfield
            program = null
            modifiedProgram = createform.getFieldValue('predictprogram')
        }
        else {
            question = ''
            program = ''
            modifiedProgram = ''
            message.error('Fill all the required fields!')
            return null
        }

        const datatoPost = {
            question: question,
            questionType: questionType,
            program: program,
            modifiedProgram: modifiedProgram,
            topicId: parseInt(topicId),
            tags: [],
            expectedConceptualKnowledge: expectedConceptualKnowledge,
        }
        dispatch(ImportQuestionsOfTopic([datatoPost]));
    }

    return (
        <div>
            <Modal
                title="Add Question"
                open={createQuestionModal}
                onCancel={() => { setCreateQuestionModal(false); setCode('') }}
                maskClosable={false}
                footer={[
                    <Button id="create-question-button" key="createQuestionButton" type="primary" onClick={handleCreateQuestionSubmit} disabled={isCreateQuestionDataLoading}>
                        {isCreateQuestionDataLoading ? 'Adding...' : 'Submit'}
                    </Button>,
                ]}
                width={1300}
                centered
            >
                <Form id="create-question-form" form={createform} layout="vertical">
                    <Form.Item name='questionType' label='Question Type' rules={[{ required: true, message: 'please select question type' }]}>
                        <Select placeholder='Select' allowClear onChange={(e) => { setQuestionType(e); createform.setFieldsValue({ question: undefined, program: undefined }); setCode(''); setErrorCode(''); jumbleRef.current = null }}>
                            <Option value='THEORY' key='theory'>Theory</Option>
                            <Option value='CODE' key='code'>Code</Option>
                            <Option value='VIVA' key='viva'>Viva</Option>
                            <Option value='DEBUG CODE' key='error'>Debug Code</Option>
                            <Option value='JUMBLED CODE' key='shuffled'>Jumbled Code</Option>
                            <Option value='EXPLAIN CODE' key='explain'>Explain Code</Option>
                            <Option value='PREDICT OUTPUT' key='predictoutput'>Predict Output</Option>
                        </Select>
                    </Form.Item>
                    {
                        questionType == "JUMBLED CODE" || questionType == "THEORY" || questionType == "VIVA" || questionType == "DEBUG CODE" || questionType == "EXPLAIN CODE" || questionType == "PREDICT OUTPUT" ?
                            <Form.Item name='question' label='Question' rules={[{ required: true, message: 'please enter the question' }]}>
                                <Input.TextArea placeholder='Question' rows={2} />
                            </Form.Item>
                            :
                            questionType == "CODE" &&
                            <Form.Item name='question' label='Question' rules={[{ required: true, message: 'please enter the question' }]}>
                                <div className="page-panel">
                                    <PanelGroup direction="horizontal">
                                        <Panel key="left" collapsible={false} collapsedSize={10} defaultSize={34} minSize={10} maxSize={70}>
                                            <CodeMirror
                                                theme='light'
                                                height="300px"
                                                placeholder="Write / Paste your program here..."
                                                value={code}
                                                onChange={(value) => setCode(value)}
                                                style={{ marginTop: '10px' }}
                                            />
                                            {/* <MailBox>
                                                <div className='body'>
                                                    <div className="group p-0">
                                                        <RichTextEditor
                                                            toolbarStyle={{ display: 'none' }}
                                                            editorStyle={{ lineHeight: '7px', border : '1px solid #e3e6ef', borderRadius : 6 }}
                                                            placeholder="Type here"
                                                            value={codeQuestion.value}
                                                            onChange={onCodeQuestionChange}
                                                            autoFocus
                                                            toolbarConfig={{
                                                                display: [],
                                                                INLINE_STYLE_BUTTONS: [],
                                                                BLOCK_TYPE_DROPDOWN: [],
                                                                BLOCK_TYPE_BUTTONS: [],
                                                                BLOCK_TYPE_BUTTONS: [],
                                                                BLOCK_TYPE_DROPDOWN: [],
                                                                LINK_BUTTONS: [],
                                                                BLOCK_TYPE_DROPDOWN: [],
                                                                HISTORY_BUTTONS: [],
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </MailBox> */}
                                        </Panel>
                                    </PanelGroup>
                                </div>
                            </Form.Item>
                    }
                    {
                        questionType == "JUMBLED CODE" ?
                            <Form.Item name='jumbledprogram' label='Program' rules={[{ required: true, message: 'please enter the program' }]}>
                                <div className="page-panel">
                                    <PanelGroup direction="horizontal">
                                        <Panel key="left" collapsible={false} collapsedSize={10} defaultSize={34} minSize={10} maxSize={70}>
                                            <CodeMirror
                                                theme='light'
                                                height="300px"
                                                placeholder="Paste your program here..."
                                                value={code}
                                                onChange={(value) => setCode(value)}
                                                style={{ marginTop: '10px' }}
                                            />
                                        </Panel>
                                        <PanelResizeHandle style={{ width: '1px', backgroundColor: '#ddd' }} />
                                        <Panel key="right" collapsible={false} collapsedSize={10} defaultSize={34} minSize={10} maxSize={70}>
                                            <div className='d-flex align-center-v justify-content-between'>
                                                <p className='text-center m-0 ml-10'>Shuffle here</p>
                                                {
                                                    code &&
                                                    <Button type="primary" onClick={() => handleShuffle(jumbleRef)} style={{ marginRight: '10px' }} transparented>
                                                        <UilShuffle />
                                                    </Button>
                                                }
                                            </div>
                                            <div ref={jumbleRef} style={{ padding: '5px', height: '300px', overflow: 'auto', userSelect: 'none' }}>
                                                {renderCodeWithLineNumbers((code))}
                                            </div>
                                        </Panel>
                                    </PanelGroup>
                                </div>
                            </Form.Item>
                            :
                            questionType == "DEBUG CODE" ?
                                <Form.Item name='errorprogram' label='Program' rules={[{ required: true, message: 'please enter the program' }]}>
                                    <div className="page-panel">
                                        <PanelGroup direction="horizontal">
                                            <Panel key="left" collapsible={false} collapsedSize={10} defaultSize={34} minSize={10} maxSize={70}>
                                                <CodeMirror
                                                    theme='light'
                                                    height="300px"
                                                    placeholder="Type/Paste your program here..."
                                                    value={code}
                                                    onChange={(value) => { setCode(value); setErrorCode(filterBlankLines(value)) }}
                                                    style={{ marginTop: '10px' }}
                                                />
                                            </Panel>
                                            <PanelResizeHandle style={{ width: '1px', backgroundColor: '#ddd' }} />
                                            <Panel key="right" collapsible={false} collapsedSize={10} defaultSize={34} minSize={10} maxSize={70}>
                                                <div className='d-flex align-center-v justify-content-between'>
                                                    <p className='text-center m-0 ml-10'>Make an erroneous code here</p>
                                                </div>
                                                <div style={{ padding: '5px', height: '300px', overflow: 'auto', userSelect: 'none' }}>
                                                    <CodeMirror
                                                        theme='light'
                                                        height="280px"
                                                        value={errorCode}
                                                        onChange={(value) => setErrorCode(filterBlankLines(value))}
                                                        style={{ marginTop: '10px' }}
                                                    />
                                                </div>
                                            </Panel>
                                        </PanelGroup>
                                    </div>
                                </Form.Item>
                                :
                                questionType == "EXPLAIN CODE" ?
                                    <Form.Item name='explainprogram' label='Program' rules={[{ required: true, message: 'please enter the program' }]}>
                                        <CodeMirror
                                            theme='light'
                                            height="400px"
                                            placeholder="Type/Paste your program here..."
                                        // value={explainCode}
                                        // onChange={(value) => { setExplainCode(filterBlankLines(value)) }}                                        
                                        />
                                    </Form.Item>
                                    :
                                    questionType == "PREDICT OUTPUT" &&
                                    <Form.Item name='predictprogram' label='Program' rules={[{ required: true, message: 'please enter the program' }]}>
                                        <CodeMirror
                                            theme='light'
                                            height="400px"
                                            placeholder="Type/Paste your program here..."
                                        // value={predictOutputCode}
                                        // onChange={(value) => { setPredictOutputCode(filterBlankLines(value)) }}                                        
                                        />
                                    </Form.Item>
                    }
                    <Form.Item name='expectedConceptualKnowledge' label='Expected conceptual knowledge (optional)' rules={[{ required: false, message: '' }]}>
                        <Input.TextArea placeholder='Type here' rows={5} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}
