import actions from './actions';

const {
  CHANGE_PASSWORD_BEGIN,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERR,
} = actions;

const getInitialState = {
  data: null,    
  loading: false,
  error: null,
};


const ChangePasswordReducer = (state = getInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CHANGE_PASSWORD_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case CHANGE_PASSWORD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};



export { ChangePasswordReducer };
