import react, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { UserFeedbackDataRead } from '../../../redux/contentFeedback/actionCreator';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, Label } from 'recharts';
import { Card, Col, Row } from 'antd';
import { useLayoutEffect } from 'react';
import { useState } from 'react';


function QAFeedback({ QAFData }) {

  const [responsive, setResponsive] = useState(0);

  useLayoutEffect(() => {
    function updateSize() {
      const width = window.innerWidth;
      setResponsive(width);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <div>
      {
        responsive > 370 &&
        <Cards title="Q & A Feedback">
          <Row gutter={25}>
            {
              QAFData.length !== 0 && QAFData.map((data, index) =>
                <Col xl={12} lg={12} md={24} xs={24} key={index} className='text-center mb-20 pb-20'>
                  <QAFeedbackGraph data={data} responsive={responsive} />
                  <h3 className='mt-10'>{data.coursename}</h3>
                </Col>
              )
            }
          </Row>
        </Cards>
      }
    </div>
  );
}

export default QAFeedback

const QAFeedbackGraph = ({ data, responsive }) => {
  const quality = data.quality;
  const coverage = data.coverage;
  const correctness = data.correctness;
  const examples = data.examples;
  const slipTest = data.sliptest;

  const graphData = [
    { name: 'Quality', Excellent: quality.excellent, Good: quality.good, Average: quality.average, Bad: quality.bad },
    { name: 'Correctness', Excellent: correctness.excellent, Good: correctness.good, Average: correctness.average, Bad: correctness.bad },
    { name: 'Coverage', Excellent: coverage.excellent, Good: coverage.good, Average: coverage.average, Bad: coverage.bad },
    { name: 'Examples', Excellent: examples.excellent, Good: examples.good, Average: examples.average, Bad: examples.bad },
    { name: 'MCQS', Excellent: slipTest.excellent, Good: slipTest.good, Average: slipTest.average, Bad: slipTest.bad },
  ];

  return (    
    <BarChart height={280} width={responsive >= 1270 ? 550 : (responsive > 991 && responsive < 1270) ? 480 : (responsive <= 991 && responsive > 667) ? 550 : (responsive <= 667 && responsive > 450) ? 400 : 310} data={graphData} className='m-auto'>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" tickSize={5} fontSize={12}  >
        {/* <Label value="Categories" position="insideBottom" offset={0} dy={10} /> */}
      </XAxis>
      <YAxis tickSize={5} fontSize={12} >
        <Label value="Students" position="insideLeft" angle={-90} offset={10} dy={25} />
      </YAxis>
      <Tooltip />
      <Bar dataKey="Excellent" fill="#b0120a">
        <LabelList dataKey="Excellent" position="top" className='fontsize-12' />
      </Bar>
      <Bar dataKey="Good" fill="#ffab91">
        <LabelList dataKey="Good" position="top" className='fontsize-12' />
      </Bar>
      <Bar dataKey="Average" fill="#afab91">
        <LabelList dataKey="Average" position="top" className='fontsize-12' />
      </Bar>
      <Bar dataKey="Bad" fill="#e0120a">
        <LabelList dataKey="Bad" position="top" className='fontsize-12' />
      </Bar>
      <Legend align='center' height={50} verticalAlign='top' wrapperStyle={{fontSize:'12px'}} />
    </BarChart>
  );
};