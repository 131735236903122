import React, { useEffect } from 'react'
import { Cards } from '../../components/cards/frame/cards-frame'
import { useDispatch, useSelector } from 'react-redux'
import { ReadLeaderboardData } from '../../redux/leaderboard/actionCreator'
import { Table } from 'antd'

export default function Leaderboard() {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(ReadLeaderboardData())
  }, [])

  const { leaderboardResults, leaderboardLoading } = useSelector((state) => {
    return {
      leaderboardResults: state.leaderboardResults.data,
      leaderboardLoading: state.leaderboardResults.loading
    }
  })

  const columns = [
    {
      title: "S.No.",
      dataIndex: 'sno',
      key: 'sno',
    },
    {
      title: "Roll No",
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: "Name",
      dataIndex: 'studentname',
      key: 'studentname',
    },
    {
      title: "Section",
      dataIndex: 'section',
      key: 'section',
    },
    {
      title: <div className='align-center-v'>Badges&nbsp;<img src={leaderboardResults.length > 0 ? process.env.REACT_APP_API_ENDPOINT + leaderboardResults[0].topicReadBadge : ''} width={16} /></div>,
      dataIndex: 'noofbadges',
      key: 'noofbadges',
    },
    {
      title: "Rank",
      dataIndex: 'rank',
      key: 'rank',
    },
  ]


  let data = []

  leaderboardResults.length !== 0 && leaderboardResults.map((obj, index) => {
    const { rank, studentname, username, noofbadges, section, topicReadBadge } = obj
    data.push({
      key: index,
      sno: index + 1,
      rank,
      username,
      studentname,
      noofbadges: <>{noofbadges}</>,
      section
    })
  })


  return (
    <div>
      <Cards title='Leaderboard'>
        <Table
          pagination={{
            defaultPageSize: 10,
            total: data.length,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
          dataSource={data}
          columns={columns}
          size='large'
        />
      </Cards>
    </div>
  )
}
