const actions = {
  EVALUATE_WITH_AUDIO_BEGIN: 'EVALUATE_WITH_AUDIO_BEGIN',
  EVALUATE_WITH_AUDIO_SUCCESS: 'EVALUATE_WITH_AUDIO_SUCCESS',
  EVALUATE_WITH_AUDIO_ERR: 'EVALUATE_WITH_AUDIO_ERR',

  EVALUATE_WITHOUT_AUDIO_BEGIN: 'EVALUATE_WITHOUT_AUDIO_BEGIN',
  EVALUATE_WITHOUT_AUDIO_SUCCESS: 'EVALUATE_WITHOUT_AUDIO_SUCCESS',
  EVALUATE_WITHOUT_AUDIO_ERR: 'EVALUATE_WITHOUT_AUDIO_ERR',

  REEVALUATE_SINGLE_BEGIN: 'REEVALUATE_SINGLE_BEGIN',
  REEVALUATE_SINGLE_SUCCESS: 'REEVALUATE_SINGLE_SUCCESS',
  REEVALUATE_SINGLE_ERR: 'REEVALUATE_SINGLE_ERR',


  evaluateWithAudioBegin: () => {
    return {
      type: actions.EVALUATE_WITH_AUDIO_BEGIN,
    };
  },

  evaluateWithAudioSuccess: (data) => {
    return {
      type: actions.EVALUATE_WITH_AUDIO_SUCCESS,
      data,
    };
  },

  evaluateWithAudioErr: (err) => {
    return {
      type: actions.EVALUATE_WITH_AUDIO_ERR,
      err,
    };
  },

  evaluateWithoutAudioBegin: () => {
    return {
      type: actions.EVALUATE_WITHOUT_AUDIO_BEGIN,
    };
  },

  evaluateWithoutAudioSuccess: (data) => {
    return {
      type: actions.EVALUATE_WITHOUT_AUDIO_SUCCESS,
      data,
    };
  },

  evaluateWithoutAudioErr: (err) => {
    return {
      type: actions.EVALUATE_WITHOUT_AUDIO_ERR,
      err,
    };
  },

  reEvaluateSingleBegin: () => {
    return {
      type: actions.REEVALUATE_SINGLE_BEGIN,
    };
  },

  reEvaluateSingleSuccess: (data) => {
    return {
      type: actions.REEVALUATE_SINGLE_SUCCESS,
      data,
    };
  },

  reEvaluateSingleErr: (err) => {
    return {
      type: actions.REEVALUATE_SINGLE_ERR,
      err,
    };
  },

};

export default actions;
