import React, { useEffect, useLayoutEffect, useState } from 'react'
import { NoteCardWrap } from '../note/style'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import CustomSpinner from '../ui-elements/spinner/CustomSpinner'
import TopicStatusId from './TopicStatusId'
import { Result } from 'antd'
import { Button } from '../../components/buttons/buttons';
import PdfControlPanel from '../student/PdfControlPanel'
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

function ViewPdfFile({ topicStatus }) {
  const { subjectId, unitId, topicId } = useParams()  
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(0);

  useEffect(() => {
    setPageNumber(0)
    setNumPages(0)        
  }, [])

  const { topic, isTopicLoading } = useSelector((state) => {
    return {
      topic: state.singleTopic.data,
      isTopicLoading: state.singleTopic.loading,
    };
  });

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const pdf = topic !== null && topic.pdf
  const pdfFile = process.env.REACT_APP_API_ENDPOINT + pdf

  const [scale, setScale] = useState(1.0);


  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1)
  }

  const [pdfwidth, setPdfWidth] = useState(0);

  useLayoutEffect(() => {
    function updateSize() {
      const width = window.innerWidth;
      setPdfWidth(width);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <div>
      <NoteCardWrap>
        {
          TopicStatusId(topicStatus) === 0 ?
            <div className='' style={{ fontSize: '18px' }}>
              <Result
                status="warning"
                title="Content not found"
                extra={
                  <Button id="viewpdf_uploadcontent-button" type="primary" key="upload" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/upload`)}>
                    Upload
                  </Button>
                }
              />
            </div>
            :
            TopicStatusId(topicStatus) === 1 ?
              <Result
                status="warning"
                title="Review Content"
                extra={
                  <Button id="viewpdf_reviewcontent-button" type="primary" key="navigateToReviewContent" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/review/content`)}>
                    Review
                  </Button>
                }
              />
              :
              TopicStatusId(topicStatus) === 2 ?
                <Result
                  status="warning"
                  title="Questions and Answers not found"
                  extra={
                    <Button id="viewpdf_generateqa-button" type="primary" key="generateqa" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/generate/questions`)}>
                      Generate Q & A
                    </Button>
                  }
                />
                :
                TopicStatusId(topicStatus) == 3 ?
                  <Result
                    status="warning"
                    title="Questions and Answers generated"
                    extra={
                      <Button id="viewpdf_reviewqa-button" type="primary" key="reviewqa" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/review/questions`)}>
                        Review Q & A
                      </Button>
                    }
                  />
                  :
                  TopicStatusId(topicStatus) == 4 ?
                    <Result
                      status="warning"
                      title="MCQS not found"
                      extra={
                        <Button id="viewpdf_generatemcq-button" type="primary" key="generatemcqs" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/review/quiz`)}>
                          Generate MCQS
                        </Button>
                      }
                    />
                    :
                    TopicStatusId(topicStatus) == 5 ?
                      <Result
                        status="warning"
                        title="MCQS has been generated for this topic"
                        extra={
                          <Button id="viewpdf_reviewmcq-button" type="primary" key="navigateToReviewmcqs" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/review/quiz`)}>
                            Review MCQS
                          </Button>
                        }
                      />
                      :
                      TopicStatusId(topicStatus) == 6 ?
                        <Result
                          status="warning"
                          title="MCQS has been reviewed. Verify programs"
                          extra={
                            <Button id="viewpdf_verify-programs-button" type="primary" key="verifyprograms" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/programs`)}>
                              Verify Programs
                            </Button>
                          }
                        />
                        :
                        TopicStatusId(topicStatus) === 7 ?
                          <div>
                            <Result
                              status='success'
                              title="Programs Verified"
                              extra={
                                <Button id="viewpdf_freezetopic-button" type="primary" key="freeze topic" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/freezeschedule`)}>
                                  Freeze Topic
                                </Button>
                              }
                            />
                          </div>
                          :
                          isTopicLoading ?
                            <div className="custom-spin">
                              <CustomSpinner />
                            </div>
                            :
                            <div>
                              {
                                pdf !== null && numPages !== 0 &&
                                <PdfControlPanel
                                  scale={scale}
                                  setScale={setScale}
                                  numPages={numPages}
                                  pageNumber={pageNumber}
                                  setPageNumber={setPageNumber}
                                  file={pdfFile}
                                />
                              }
                              {
                                pdf !== null ?
                                  <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess} loading={<div className="custom-spin"><CustomSpinner /></div>} onLoadError={() => setNumPages(0)} className='text-center'>
                                    <Page pageNumber={pageNumber} scale={scale} width={(pdfwidth > 992 ? (pdfwidth) / 1.7 : (pdfwidth < 768 ? (pdfwidth) / 1.2 : (pdfwidth) / 2))} />
                                  </Document>
                                  :
                                  <div className='spin'>
                                    <span style={{ fontSize: '18px' }}>Pdf file not found</span>
                                  </div>
                              }
                              {
                                pdf !== null && numPages !== 0 &&
                                <PdfControlPanel
                                  scale={scale}
                                  setScale={setScale}
                                  numPages={numPages}
                                  pageNumber={pageNumber}
                                  setPageNumber={setPageNumber}
                                  file={pdfFile}
                                />
                              }
                            </div>
        }
      </NoteCardWrap>
    </div>
  )
}

export default ViewPdfFile