import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Modal, Input, Form, Select, message } from 'antd';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main, BannerCardStyleWrap } from '../styled';
import { useDispatch, useSelector } from 'react-redux';
import { noticeDataRead, noticeDataSubmit, noticeDataUpdate, noticeCollegeDataRead, noticecollegeDataSubmit } from '../../redux/notice/actionCreator';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import BannerCard from '../../components/cards/BannerCard';
import { getItem } from '../../utility/localStorageControl';
import MailComposer from '../email/overview/MailComposer';
import SampleCardSeven from '../../components/cards/sampleCard/SampleCardSeven';

const { Option } = Select;

const Notice = () => {
  let ClgName = '(' + 'ALL' + ')';
  const loggedadmin = getItem('username');
  const dispatch = useDispatch();
  const { crud, isLoading, NoticeAddRes, isNoticeUpdating, NoticeUpdateRes, isNoticeAdding, noticecollege, isNoticeCollegeLoading, NoticeCollegeAddRes, isNoticeCollegeAdding } = useSelector((state) => {
    return {
      crud: state.Notice.data,
      isLoading: state.Badges.loading,
      noticecollege: state.NoticeCollege.data,
      isNoticeCollegeLoading: state.NoticeCollege.loading,
      NoticeAddRes: state.NoticeAddRes.data,
      isNoticeAdding: state.NoticeAddRes.loading,
      NoticeUpdateRes: state.NoticeUpdateRes.data,
      isNoticeUpdating: state.NoticeUpdateRes.loading,
      NoticeCollegeAddRes: state.NoticeCollegeAddRes.data,
      isNoticeCollegeAdding: state.NoticeCollegeAddRes.loading,
    };
  });


  const logininfo = getItem('username');
  const loggedclg = getItem('collegeName');
  const formTitle = 'Create College Notice';

  const image = loggedclg ? loggedclg.toLowerCase() : '';

  useEffect(() => {
    if (dispatch) {
      // dispatch(noticeDataRead());
      // dispatch(noticeCollegeDataRead());
    }
  }, [dispatch]);

  useEffect(() => {
    if (NoticeAddRes !== null) {
      // dispatch(noticeDataRead());
      // dispatch(noticeCollegeDataRead());
    }
  }, [NoticeAddRes]);

  useEffect(() => {
    if (NoticeUpdateRes !== null) {
      // dispatch(noticeDataRead());
      // dispatch(noticeCollegeDataRead());
    }
  }, [NoticeUpdateRes]);

  useEffect(() => {
    if (NoticeCollegeAddRes !== null) {
      // dispatch(noticeDataRead());
      // dispatch(noticeCollegeDataRead());
    }
  }, [NoticeCollegeAddRes]);

  const [editform] = Form.useForm();
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [editRecord, setEditRecord] = useState(null);


  const handleModalCancel = () => {
    setIsEditModalVisible(false);
    setModalVisible(false);
    form.resetFields();
    editform.resetFields();
    // setSelectedType(false);
    setSelectedRole(false);
  }

  const handleButtonClick = () => {
    setModalVisible(true);
  }

  const handleModalAdd = () => {
    form.validateFields().then((values) => {
      let dispatchdata = {};
      if (logininfo === 'superadmin' && crud === null) {
        dispatchdata = {
          notice: values.notice,
          status: values.status,
          title: values.title,
        }
        dispatch(noticeDataSubmit(dispatchdata));
      }
      form.resetFields();
      setModalVisible(false);
      // setSelectedType(false);
      setSelectedRole(false);
      if (logininfo === 'superadmin' && (crud && Object.keys(crud).length > 0)) {
        message.error("Cannot create more than one global notice", 5);
      }
      if (logininfo !== 'superadmin') {
        if (values.role === 'Student') {
          dispatchdata = {
            notice: values.notice,
            status: values.status,
            title: values.title,
            studentYear: parseInt(values.studentYear),
            role: 'STUDENT',
          }
        }
        if (values.role === 'Teacher') {
          dispatchdata = {
            notice: values.notice,
            status: values.status,
            title: values.title,
            studentYear: 0,
            role: 'TEACHER',
          }
        }
        dispatch(noticecollegeDataSubmit(dispatchdata));
      }
    })
      .catch((error) => {
        console.error('Error while validating form:', error);
      });
  };

  const [noticeischanged, setNoticeischanged] = useState(false);
  const [editNotice, setEditNotice] = useState(null);
  const [NewNotice, setNewNotice] = useState(null);

  const onChangeNotice = (value) => {
    if (value === null || value === undefined)
      setNoticeischanged(false);
    else if (value !== null && editNotice !== NewNotice) {
      setNoticeischanged(true);
      setNewNotice(value);
    }
    else if (value !== null && editNotice === NewNotice) {
      setNewNotice(value);
    }
  };

  const formvalue = noticeischanged === false ? editNotice : NewNotice;


  const handleModalUpdate = () => {
    editform.validateFields().then((values) => {
      const updatedRecord = {
        id: editRecord,
        notice: (NewNotice && editNotice === NewNotice) ? NewNotice : formvalue,
        status: values.status,
        title: values.title,
      };
      setIsEditModalVisible(false);
      dispatch(noticeDataUpdate(updatedRecord));
    })
      .catch((error) => {
        console.error('Error while validating form:', error);
      });
  };

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const handleEditModalOpen = (item) => {
    setEditRecord(item.id);
    setIsEditModalVisible(true);
    if (item.title === 'GLOBAL NOTICE') {
      if (crud && Object.keys(crud).length > 0) {
        setEditNotice(crud.notice)
        editform.setFieldsValue({
          notice: crud.notice,
          status: crud.status,
          title: crud.title,
        });
      } else {
        message.error("No content to edit", 5);
        setIsEditModalVisible(false);
      }
    } else {
      if (noticecollege.length !== 0) {
        setEditNotice(item.notice)
        editform.setFieldsValue({
          notice: item.content,
          status: item.status,
          title: item.title,
        });
      } else {
        message.error("No content to edit", 5);
        setIsEditModalVisible(false);
      }
    }

  };

  // const [selectedType, setSelectedType] = useState(false);
  const [selectedRole, setSelectedRole] = useState(false);


  // const onTypeOfNoticeChange = (value) => {
  //   if (value === 'College') {
  //     setSelectedType(true);
  //   } else {
  //     setSelectedType(false);
  //   }
  // };

  const onRoleOfNoticeChange = (value) => {
    if (value === 'Student')
      setSelectedRole(true);
    else if (value === 'Teacher')
      setSelectedRole(false);
  };


  return (
    <div>
      <PageHeader className="ninjadash-page-header-main" title={`Notice ${loggedadmin === 'superadmin' ? ClgName : ''}`}
        buttons={[
          <Button onClick={handleButtonClick} id='create-global/college-notice-button' key="AddRegulation" type="primary" size="default">
            {formTitle}
          </Button>,
        ]}
      />
      <Main>
        {isLoading || isNoticeUpdating || isNoticeAdding || isNoticeCollegeLoading || isNoticeCollegeAdding ?
          (
            <div className="custom-loading-spin-with-text">
              <CustomSpinner />
              <p style={{ fontWeight: 'bold', color: '#8231D3' }}>
                Loading please wait...
              </p>
            </div>
          ) : (
            <div>
              <Col xs={24}>
                <BannerCardStyleWrap>
                  <Row gutter="25">
                    {logininfo === 'superadmin' && (
                      <Col xxl={8} md={12} className="mb-25">
                        <BannerCard
                          id='global-notice-cards'
                          item={{
                            id: crud !== null && crud.id,
                            type: 'dark',
                            image: 'tesseract-logo.png',
                            status: <span className={`status ${crud !== null && crud.status.toLowerCase()}`}>{crud !== null && crud.status}</span>,
                            icon: 'clg.png',
                            title: 'GLOBAL NOTICE',
                            content: (
                              crud !== null ? (
                                <div dangerouslySetInnerHTML={{ __html: crud.notice }} />
                              ) : (
                                <span style={{ color: 'white' }}>No Data</span>
                              )
                            ),

                          }}
                          onEditClick={handleEditModalOpen}
                        />
                      </Col>
                    )}
                  </Row>
                </BannerCardStyleWrap>
              </Col>
              <Col xs={24}>
                <Row gutter="25">
                  {noticecollege && (noticecollege).map((item) => (
                    <Col xxl={8} md={12} className="mb-25">
                      <SampleCardSeven
                        id='college-notice-cards'
                        key={item.id}
                        item={{
                          id: item.id,
                          role: item.role,
                          image: `${image}.png`,
                          status: (
                            <span className={`status ${item.status && item.status.toLowerCase()}`}>
                              {item.status}
                            </span>
                          ),
                          statuss: item.status,
                          title: item.title,
                          content: item.notice ? (
                            <div dangerouslySetInnerHTML={{ __html: item.notice }} />
                          ) : (
                            <span style={{ color: 'white' }}>No Data</span>
                          ),
                        }}
                        onEditClick={() => handleEditModalOpen(item)}
                        editNotice={item.notice}
                        NewNotice={editNotice}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </div>
          )
        }
      </Main>
      <Modal
        title={formTitle}
        open={modalVisible}
        onCancel={handleModalCancel}
        maskClosable={false}
        footer={[
          <Button key="createnotice" type="primary" id='create-notice-button' onClick={handleModalAdd}>
            Create
          </Button>,
        ]}
      >
        <Form id='create-notice-form' form={form} layout='vertical'>
          {/* <Form.Item label="Type of notice" name="typeofnotice" rules={[{ required: true , message: "Please select type of notice"}]}>
                <Select id='create-notice-select-type_of_notice' placeholder="Select type of notice">
                    <Option key="Global">Global</Option>
                    <Option key="College">College</Option>
                </Select>
            </Form.Item> */}
          {logininfo !== 'superadmin' && (

            <Form.Item label="Role" name="role" rules={[{ required: true, message: "Please select role" }]}>
              <Select id='create-notice-select-role' placeholder="Select role" onChange={onRoleOfNoticeChange}>
                <Option key="Student">Student</Option>
                <Option key="Teacher">Teacher</Option>
              </Select>
            </Form.Item>
          )}
          {(selectedRole) && (
            <Form.Item label="Student Year" name="studentYear" rules={[{ required: true, message: "Please selected student year" }]}>
              <Select id='create-notice-select-studentyear' placeholder="Select year">
                <Option key="1">1</Option>
                <Option key="2">2</Option>
                <Option key="3">3</Option>
                <Option key="4">4</Option>
              </Select>
            </Form.Item>
          )}
          <Form.Item label="Title" name="title" rules={[{ required: true, message: "Please enter title" }]} initialValue={''}>
            <Input id='create-notice-enter-title' placeholder='Enter title(e.g.,KMIT 1st year Notice or KMIT Teachers Notice)' />
          </Form.Item>
          <Form.Item label="Notice" name="notice" rules={[{ required: true, message: "Please enter notice description" }]}>
            <MailComposer id='create-notice-enter-notice_description' text initialValue={''} />
          </Form.Item>
          <Form.Item label="Status" name="status" rules={[{ required: true, message: "Please select status" }]}>
            <Select id='create-notice-select-status' placeholder="Select status">
              <Option key="ON">ON</Option>
              <Option key="OFF">OFF</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit Notice"
        open={isEditModalVisible}
        onCancel={handleModalCancel}
        maskClosable={false}
        footer={[
          <Button key="EditNotice" type="primary" onClick={handleModalUpdate}>
            Update
          </Button>,
        ]}
      >
        <Form id='edit-notice-form' form={editform} layout='vertical'>
          <Form.Item label="Title" name="title" rules={[{ required: true, message: "Please enter title" }]}>
            <Input id='edit-notice-enter-title' placeholder='Enter title(e.g.,KMIT 1st year Notice or KMIT Teachers Notice)' />
          </Form.Item>
          <Form.Item label="Notice" name="notice" rules={[]}>
            <MailComposer id='edit-notice-enter-notice_description' text={false} editNotice={editNotice} onChange={onChangeNotice} />
          </Form.Item>
          <Form.Item label="Status" name="status" rules={[{ required: true, message: "Please select status" }]}>
            <Select id='edit-notice-select-status' placeholder="Select status">
              <Option key="ON">ON</Option>
              <Option key="OFF">OFF</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Notice;