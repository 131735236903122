import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const UploadContent = lazy(() => import('../../container/teacher/UploadContent'))
const ReviewContent = lazy(() => import('../../container/teacher/ReviewContent'))
const GenerateQuestions = lazy(() => import('../../container/teacher/GenerateQuestions'))
const ReviewQuestions = lazy(() => import('../../container/teacher/ReviewQuestions'))
const NotFound = lazy(() => import('../../container/pages/404'));

function TopicRoutes() {
  return (
    <Routes>      
      <Route path="/upload/content" element={<UploadContent />} />   
      <Route path="/review/content/*" element={<ReviewContent />} />                  
      <Route path="/generate/questions" element={<GenerateQuestions />} />
      <Route path="/review/questions" element={<ReviewQuestions />} />
      <Route path="/freeze/topic" element={<ReviewQuestions />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default TopicRoutes;

// NOT IN USE