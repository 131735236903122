import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Row, Col, message } from 'antd';
import { AuthFormWrap } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { ResetPassword, VerifyOTP, forgotPassword } from '../../../../redux/authentication/actionCreator';
import actions from '../../../../redux/authentication/actions';


const { forgotPassSuccess, resetPassSuccess } = actions;

function ForgotPassword() {
  const [email, setEmail] = useState("");

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { OTP, isSendingOTP, VerifyOTPData, isVerifyingOTP, ResetPassRes, isResetting } = useSelector((state) => {
    return {
      OTP: state.ForgotPass.data,
      isSendingOTP: state.ForgotPass.loading,
      VerifyOTPData: state.VerifyOTP.data,
      isVerifyingOTP: state.VerifyOTP.loading,
      ResetPassRes: state.ResetPassRes.data,
      isResetting: state.ResetPassRes.loading,
    };
  });

  useEffect(() => {
    if (ResetPassRes !== null && ResetPassRes.Error === false) {
      dispatch(resetPassSuccess(null))
      navigate('/')
    }
  }, [ResetPassRes])

  const handleSubmit = (values) => {
    // forgotPassSuccess(null)
    setEmail(values.email);
    if (OTP === null) {
      dispatch(forgotPassword(values.email))
    }
    else if (OTP !== null && OTP.Error === false) {
      const data = {
        email: values.email,
        otp: parseInt(values.otp)
      }
      dispatch(VerifyOTP(data))
    }
  };

  const resetPassword = (values) => {
    if (email !== "") {
      if (values.password === values.confirmpassword) {
        const data = {
          email: email,
          password: values.password
        }
        dispatch(ResetPassword(data))
      }
      else {
        message.error("Password and confirm password mismatch!")
      }
    }
    else {
      message.error("Failed to reset. Please try again")
    }
  }

  return (
    <Row justify="center">
      <Col xxl={6} xl={8} md={12} sm={18} xs={24}>
        <AuthFormWrap>
          {
            (VerifyOTPData !== null && VerifyOTPData.Error === false) ?
              <Form name="forgotPass" onFinish={resetPassword} layout="vertical">
                <div className="ninjadash-authentication-top">
                  <h2 className="ninjadash-authentication-top__title">Reset Password</h2>
                </div>
                <div className="ninjadash-authentication-content">
                  {/* <p className="forgot-text">
                    Enter the email address you used when you joined and we’ll send you OTP to reset your password.
                  </p> */}
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input password!', type: 'string' }]}
                  >
                    <Input.Password placeholder="password" />
                  </Form.Item>
                  <Form.Item
                    label="Confirm Password"
                    name="confirmpassword"
                    rules={[{ required: true, message: 'Please input confirm password!', type: 'string' }]}
                  >
                    <Input.Password placeholder="password" />
                  </Form.Item>
                  <Form.Item>
                    <Button className="btn-reset" htmlType="submit" type="primary" size="large" disabled={(isResetting) ? true : false}>
                      {isResetting ? 'Please wait' : 'Reset'}
                    </Button>
                  </Form.Item>
                </div>
                <div className="ninjadash-authentication-bottom">
                  <p className="return-text">
                    Return to <Link to="/">Sign In</Link>
                  </p>
                </div>
              </Form>
              :
              <Form name="forgotPass" onFinish={handleSubmit} layout="vertical">
                <div className="ninjadash-authentication-top">
                  <h2 className="ninjadash-authentication-top__title">Forgot Password?</h2>
                </div>
                <div className="ninjadash-authentication-content">
                  <p className="forgot-text">
                    Enter the email address you used when you joined and we’ll send you OTP to reset your password.
                  </p>
                  <Form.Item
                    label="Email Address"
                    name="email"
                    rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
                    hidden={(OTP !== null && OTP.Error === false) ? true : false}
                  >
                    <Input placeholder="name@example.com" />
                  </Form.Item>
                  {
                    (OTP !== null && OTP.Error === false) &&
                    <Form.Item
                      label="OTP (One Time password)"
                      name="otp"
                      rules={[{ required: true, message: 'OTP is required', type: 'string' }]}
                    >
                      <Input placeholder="000000" />
                    </Form.Item>
                  }
                  <Form.Item>
                    <Button className="btn-reset" htmlType="submit" type="primary" size="large" disabled={(isSendingOTP || isVerifyingOTP) ? true : false}>
                      {isSendingOTP ? 'Sending OTP' : (OTP !== null && OTP.Error === false) ? isVerifyingOTP ? 'Verifying OTP' : 'Submit' : 'Send OTP'}
                    </Button>
                  </Form.Item>
                </div>
                <div className="ninjadash-authentication-bottom">
                  <p className="return-text">
                    Return to <Link to="/">Sign In</Link>
                  </p>
                </div>
              </Form>
          }
        </AuthFormWrap>
      </Col>
    </Row>
  );
}

export default ForgotPassword;
