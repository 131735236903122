import { GET_TEACHER_MONITOR_BEGIN, GET_TEACHER_MONITOR_SUCCESS, GET_TEACHER_MONITOR_ERROR } from './actions';

const initialState = {
    data: [],
    loading: false,
    error: null,
};

const TeacherMonitorReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TEACHER_MONITOR_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case GET_TEACHER_MONITOR_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false,
            };
        case GET_TEACHER_MONITOR_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};


export { TeacherMonitorReducer };
