import { readStudentActionsBegin, readStudentActionsSuccess, readStudentActionsError } from './actions';
import { DataService } from '../../config/dataService/dataService';
import { message } from 'antd';
import { logOut } from '../authentication/actionCreator'
import { getItem } from '../../utility/localStorageControl';


export const StudentRecentActionsDataRead = () => {
    return async (dispatch) => {
        try {
            const userId = getItem('userId')
            await dispatch(readStudentActionsBegin());
            const response = await DataService.get(`userlog/useraction/${userId}`);
            const token = getItem('access_token');
            const data = response.data;
            if (token && data !== null && data.Error === false) {
                await dispatch(readStudentActionsSuccess(data.payload));
            }
            else if (data !== null && data.Error === true) {
                if (Object.keys(data).length >= 3 && data.statusCode == 401) {
                    message.error(data.message)
                    await dispatch(readStudentActionsSuccess([]));
                    dispatch(logOut())
                }
                else {
                    await dispatch(readStudentActionsSuccess([]));
                }
            }
            else {
                await dispatch(readStudentActionsSuccess([]));
            }
        } catch (err) {
            await dispatch(readStudentActionsError(err));
        }
    };
};
