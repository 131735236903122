
import react from 'react'
import { Main } from '../styled';
import { Col, Row } from 'antd';
import { Cards } from '../../components/cards/frame/cards-frame';
import { NoteCardWrap } from '../note/style';

function Empty() {
    return (
        <>
            <Main>
                <Row gutter={25} justify="center">
                    <Col lg={24} xs={24}>
                        <Cards title={''}>
                            <NoteCardWrap>


                            </NoteCardWrap>
                        </Cards>
                    </Col>
                </Row>
            </Main>
        </>
    );
}

export default Empty