import { readTeacherMonitorBegin, readTeacherMonitorSuccess, readTeacherMonitorError } from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { message } from 'antd';
import { getItem } from '../../utility/localStorageControl';


export const getTeacherMonitorData = (subjectId, weekId, sectionId) => {
    return async (dispatch) => {
        try {
            await dispatch(readTeacherMonitorBegin());
            const response = await DataService.get(`/teachermaster/student/${subjectId}/${weekId}/${sectionId}`);
            const token = getItem('access_token');
            const data = response.data;
            if (token && data.Error === false) {
                await dispatch(readTeacherMonitorSuccess(data.payload));
            }
            else if (data !== null && data.Error === true) {
                if (Object.keys(data).length >= 3 && data.statusCode == 401) {
                    message.error(data.message)
                    await dispatch(readTeacherMonitorSuccess([]));
                    dispatch(logOut())
                }
                else {
                    await dispatch(readTeacherMonitorSuccess([]));
                }
            }
            else {
                await dispatch(readTeacherMonitorSuccess([]));
            }
        } catch (error) {
            await dispatch(readTeacherMonitorError(error));
        }
    };
};
