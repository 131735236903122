import React, { useEffect, useState } from 'react'
import { Main } from '../styled'
import { PageHeader } from '../../components/page-headers/page-headers';
import { Cards } from '../../components/cards/frame/cards-frame';
import { DataTableStyleWrap } from '../../components/table/Style';
import { Input, Select, Table } from 'antd';
import { Button } from '../../components/buttons/buttons';
import { useDispatch, useSelector } from 'react-redux';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import UilSearch from '@iconscout/react-unicons/icons/uil-search';
import { studentSubjectsDataRead } from '../../redux/studentSubjectsUnits/actionCreator';
import { GetTopicWisePerformance } from '../../redux/studentPerformance/actionCreator';
import actions from '../../redux/studentPerformance/actions';
import { NoteCardWrap } from '../note/style'

const { topicWisePerformanceReadSuccess } = actions
 
export default function TopicWisePerformance() {

    const { topicWisePerformance, topicWisePerformanceLoading, StudentSubjects, isSubjectsLoading } = useSelector((state) => {
        return {
            topicWisePerformance: state.topicWisePerformance.data,
            topicWisePerformanceLoading: state.topicWisePerformance.loading,
            StudentSubjects: state.StudentSubjects.data,
            isSubjectsLoading: state.StudentSubjects.loading,
        }
    })

    const [subjectId, setSubjectId] = useState(0)

    const dispatch = useDispatch();

    useEffect(() => {
        if (dispatch) {
            setSubjectId(0)
            dispatch(topicWisePerformanceReadSuccess(null));
            dispatch(studentSubjectsDataRead());
        }
    }, [dispatch]);

    const loadPerformance = () => {
        dispatch(GetTopicWisePerformance(parseInt(subjectId)))
    }

    const columnsSort = [
        {
            title: '#',
            dataIndex: 'sno',
            align: 'center'
        },
        {
            title: 'Topic',
            dataIndex: 'name',
        },
        {
            title: 'Easy',
            children: [
                {
                    title: 'Attempts',
                    dataIndex: 'easyAttempts',
                    key: 'easyAttempts',
                    width: 100,
                    align: 'center'
                },
                {
                    title: 'Performance',
                    dataIndex: 'easyPerformance',
                    key: 'easyPerformance',
                    width: 150,
                    align: 'center'
                },
                {
                    title: 'Percentage',
                    dataIndex: 'easyPercentage',
                    key: 'easyPercentage',
                    width: 100,
                    align: 'center'
                },
            ],
        },
        {
            title: 'Intermediate',
            children: [
                {
                    title: 'Attempts',
                    dataIndex: 'intermediateAttempts',
                    key: 'intermediateAttempts',
                    width: 100,
                    align: 'center'
                },
                {
                    title: 'Performance',
                    dataIndex: 'intermediatePerformance',
                    key: 'intermediatePerformance',
                    width: 150,
                    align: 'center'
                },
                {
                    title: 'Percentage',
                    dataIndex: 'intermediatePercentage',
                    key: 'intermediatePercentage',
                    width: 100,
                    align: 'center'
                },
            ],
        },
        {
            title: 'Advanced',            
            children: [
                {
                    title: 'Attempts',
                    dataIndex: 'advancedAttempts',
                    key: 'advancedAttempts',
                    width: 100,
                    align: 'center'
                },
                {
                    title: 'Performance',
                    dataIndex: 'advancedPerformance',
                    key: 'advancedPerformance',
                    width: 150,     
                    align: 'center'               
                },
                {
                    title: 'Percentage',
                    dataIndex: 'advancedPercentage',
                    key: 'advancedPercentage',
                    width: 100,                    
                    align: 'center'
                },
            ],
        },
        {
            title: 'Overall',            
            children: [
                {
                    title: 'Attempts',
                    dataIndex: 'overallAttempts',
                    key: 'overallAttempts',
                    width: 100,      
                    align: 'center'              
                },
                {
                    title: 'Performance',
                    dataIndex: 'overallPerformance',
                    key: 'overallPerformance',
                    width: 150,                    
                    align: 'center'
                },
                {
                    title: 'Percentage',
                    dataIndex: 'overallPercentage',
                    key: 'overallPercentage',
                    width: 100,   
                    align: 'center'                 
                },
            ],
        },
    ];


    const [tableData, setTableData] = useState([])
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        let data = []
        topicWisePerformance && topicWisePerformance.payload.length !== 0 && topicWisePerformance.payload.map((obj, index) => {
            data.push({
                key: index,
                "sno": index + 1,
                ...obj
            })
        })
        setTableData(data)
        setFilteredData(data)
    }, [topicWisePerformance])


    const searchkeys = ['name']
    const searchStudent = (e) => {
        const { value } = e.currentTarget;
        const searchData = tableData.length !== 0 && tableData.filter((obj) => searchkeys.some((key) => obj[key].toLowerCase().includes(value.toLowerCase())))
        setFilteredData(searchData)
    };


    return (
        <div>
            <Main>
                <PageHeader className="ninjadash-page-header-main" title="Topic Wise Performance" />
                <Cards headless>
                    <DataTableStyleWrap>
                        <div className="ninjadash-datatable-filter">
                            <div className="ninjadash-datatable-filter__left">
                                <div className="ninjadash-datatable-filter__input">
                                    <span className="label">Subject:</span>
                                    <Select id="monitor_select-subject" style={{ width: 250 }} className="ninjadash-data-status" placeholder="select" onChange={(e) => { setSubjectId(e); }} loading={isSubjectsLoading}>
                                        {
                                            StudentSubjects.length !== 0 && StudentSubjects.sort((a, b) => a.name > b.name ? 1 : -1).map((subjectObj, subjectIndex) =>
                                                <Select.Option value={subjectObj.subjectId} key={subjectIndex}>{subjectObj.subjectname} - {subjectObj.cohortname}</Select.Option>
                                            )
                                        }
                                    </Select>
                                </div>
                                <div className="ninjadash-datatable-filter__action">
                                    <Button id="monitor_get-button" type="primary" size="small" onClick={() => { loadPerformance() }}>
                                        Get
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {
                            topicWisePerformanceLoading ?
                                <NoteCardWrap>
                                    <div className="custom-spin">
                                        <CustomSpinner/>
                                    </div>
                                </NoteCardWrap>
                                :
                                <div>
                                    <div className='d-flex justify-content-between mb-15 p-0 align-center-v'>

                                        <div className="d-flex align-center-v" style={{ marginLeft: 'auto' }}>
                                            {/* <CSVLink {...csvReport}>
                                                <Button id="monitor_export-button" type="primary" size="small" className="mr-10" disabled={filteredData.length !== 0 ? false : true}>
                                                    Export
                                                </Button>
                                            </CSVLink> */}
                                            <Input id="monitor_search-input" className='' onChange={searchStudent} size="default" placeholder="Search here..." prefix={<UilSearch />} />
                                        </div>
                                    </div>
                                    <Table
                                        className="table-responsive"
                                        bordered
                                        pagination={{
                                            defaultPageSize: 15,
                                            total: filteredData.length,
                                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                        }}
                                        columns={columnsSort}
                                        dataSource={filteredData}
                                        tableLayout='auto'
                                        size='middle'
                                    />
                                </div>
                        }
                    </DataTableStyleWrap>
                </Cards>
            </Main>
        </div>
    )
}
