import react, { Suspense } from 'react'
import { Select, Spin } from 'antd';
import { Cards } from '../../components/cards/frame/cards-frame';
import { NavLink, Navigate, Route, Routes } from 'react-router-dom';
import { AddUser } from './styles/tablestyle';
import ViewQuiz from './ViewQuiz';
import ViewQuestions from './ViewQuestions';
import TopicSchedule from './TopicSchedule';
import {  
    UilSchedule,   
    UilClipboardNotes,
    UilQuestionCircle  
  } from '@iconscout/react-unicons';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';

const { Option } = Select;

function ViewQAQuiz({customWidth}) {

    return (
        <>
            <AddUser>
                <Cards title={
                    <div className="card-nav">
                        <ul>                            
                            <li>
                                <NavLink to="q&a">
                                    <UilQuestionCircle className="m-0 mr-3"/>Q & A
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="mcqs">
                                    <UilClipboardNotes className="m-0 mr-3"/>M C Q
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="schedule">
                                   <UilSchedule className="m-0 mr-5"/>Schedule
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                }>
                    <Suspense
                        fallback={
                            <div className="spin">
                                <CustomSpinner />
                            </div>
                        }
                    >
                        <Routes>
                            <Route path="/" element={<Navigate to={`q&a`} replace={true} />} />
                            <Route path="q&a" element={<ViewQuestions customWidth={customWidth} />} />
                            <Route path="mcqs" element={<ViewQuiz customWidth={customWidth} />} />
                            <Route path="schedule" element={<TopicSchedule customWidth={customWidth} />} />
                        </Routes>
                    </Suspense>
                </Cards>
            </AddUser>
        </>
    );
}

export default ViewQAQuiz
