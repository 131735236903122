import { Button, Form, Input, message, Modal, Upload } from 'antd';
import react, { useEffect, useState } from 'react';
import { FormValidationWrap } from '../forms/overview/Style';
import { useDispatch, useSelector } from 'react-redux';
import { ImportNewTopic } from '../../redux/topics/actionCreator'
import actions from '../../redux/topics/actions'
import { useParams } from 'react-router-dom';
import UilExport from "@iconscout/react-unicons/icons/uil-export";
import { topicsOfSubjectDataRead } from '../../redux/teacherSubjectsUnits/actionCreator';
import { ImportStyleWrap } from '../styled';
import Heading from '../../components/heading/heading';
import JSZip from 'jszip';

const { Dragger } = Upload;

const { importTopicSuccess } = actions

function ImportTopic({ open, close }) {

    const { subjectId, subjectName } = useParams()
    const [file, setFile] = useState(null);
    const [fileList, setFileList] = useState([])

    const [form] = Form.useForm();
    const dispatch = useDispatch()
    const { ImportTopicRes, ImportingTopic } = useSelector((state) => {
        return {
            ImportTopicRes: state.ImportTopicRes.data,
            ImportingTopic: state.ImportTopicRes.loading,
        };
    });

    useEffect(() => {
        dispatch(importTopicSuccess(null))
    }, [])

    useEffect(() => {
        if (ImportTopicRes !== null && ImportTopicRes.Error === false) {
            form.resetFields()
            close(false)
            setFile(null)
            setFileList([])
            dispatch(importTopicSuccess(null))
            dispatch(topicsOfSubjectDataRead(subjectId));
        }
    }, [ImportTopicRes])

    const ImportTopic = () => {
        if (file) {
            const data = new FormData();
            data.append('file', file)
            data.append('subjectId', parseInt(subjectId))

            dispatch(ImportNewTopic(data))
        }
        else {
            message.error('File is required')
        }
    }

    const handleFileChange = async (uploadedFile) => {
        if (uploadedFile !== null && uploadedFile.fileList.length !== 0 && uploadedFile.fileList[0].type === "application/zip") {
            try {
                const reader = new FileReader();

                reader.onload = async () => {
                    try {
                        const zip = new JSZip();
                        const zipData = await zip.loadAsync(reader.result);

                        // Check if there is exactly one JSON file and one audio folder
                        // let topicsJsonFileFound = false;
                        // let audioFolderFound = false;

                        const jsonFiles = Object.keys(zipData.files).filter(fileName => fileName.endsWith('.json'));
                        if (jsonFiles.length == 0) {
                            message.error('Invalid zip file.');
                            setFile(null)
                            setFileList([])
                            return;
                        }
                        else if (jsonFiles.length !== 1) {
                            message.error('There should be exactly one JSON file.');
                            setFile(null)
                            setFileList([])
                            return;
                        }

                        // zipData.forEach((relativePath, zipEntry) => {
                        //     // console.log(zipEntry)
                        //     // Check for a JSON file
                        //     if (!topicsJsonFileFound && zipEntry.name.endsWith('.json')) {
                        //         topicsJsonFileFound = true;
                        //     }

                        //     // Check for an audio folder
                        //     if (!audioFolderFound && zipEntry.dir && zipEntry.name.startsWith('audio/')) {
                        //         audioFolderFound = true;
                        //     }
                        // });
                        // console.log("topicsJsonFileFound", topicsJsonFileFound)
                        // console.log("audioFolderFound", audioFolderFound)
                        // if (!topicsJsonFileFound || !audioFolderFound) {
                        //     message.error('Invalid zip file.');
                        //     setFile(null)
                        //     setFileList([])
                        //     return;
                        // }

                        // Get the audio folder contents
                        // const audioFolder = zipData.folder('audio');
                        // const audioFiles = audioFolder ? Object.keys(audioFolder.files) : [];                        
                        // // Validate audio files if necessary
                        // if (audioFiles.length === 0) {
                        //     message.error('No audio files found in the audio folder.');
                        //     return;
                        // }

                        setFileList(uploadedFile.fileList);
                        // If validation is successful
                        message.success('Zip file is validated.');
                    } catch (error) {
                        // console.error('Error processing ZIP file:', error);
                        message.error('Error processing ZIP file.');
                    }
                };

                reader.readAsArrayBuffer(file);
            } catch (error) {
                // console.error('Error reading file:', error);
                console.log('Error reading file.');
            }
        }
        else if (uploadedFile !== null && uploadedFile.fileList.length !== 0 && uploadedFile.fileList[0].type !== "application/zip") {
            message.error("Only .zip file is allowed")
            setFile(null);
            setFileList([])
        }

    };


    const handleRemovefile = () => {
        message.warn("File has been removed")
        setFile(null);
        setFileList([])
    }


    return (
        <div>
            <Modal open={open} centered width={800} title={`Import Topic to ${subjectName}`} footer={false} onCancel={() => { close(false); setFile(null); setFileList([]) }} maskClosable={false}>
                <FormValidationWrap>
                    <Form name="sDash_validation-form" form={form} layout="vertical" onFinish={ImportTopic}>
                        {/* <Form.Item
                            name="subjectId"
                            rules={[{ required: true, message: 'Subject required!' }]}
                            label="Subject"
                        >
                            <Select placeholder="Select">
                                {
                                    SubjectsUnits.length !== 0 && SubjectsUnits.map((sub, index) =>
                                        <Option key={index} value={sub.subjectId}>{sub.subjectname}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item> */}

                        <Form.Item
                            name="file"
                            rules={[{ required: true, message: '.zip file is required!' }]}
                            label=""
                        >
                            <ImportStyleWrap>
                                <Dragger
                                    id="zip-file-input"
                                    maxCount={1}
                                    beforeUpload={uploadedFile => {
                                        if (uploadedFile !== null) {
                                            setFile(uploadedFile);
                                            return false;
                                        }
                                    }}
                                    onChange={handleFileChange}
                                    onRemove={handleRemovefile}
                                    fileList={fileList}
                                    accept='.zip'
                                >
                                    <p className="ant-upload-drag-icon">
                                        <UilExport />
                                    </p>
                                    <Heading as="h4" className="ant-upload-text">
                                        Click here to select Topic (.zip) file
                                    </Heading>
                                </Dragger>
                            </ImportStyleWrap>
                        </Form.Item>

                        <div className=' d-flex justify-content-end mb-20'>
                            <Button htmlType="reset" type="secondary" className='mr-10' onClick={() => { setFile(null); setFileList([]) }} >
                                Reset
                            </Button>
                            <Button htmlType="submit" type="primary" disabled={ImportingTopic} >
                                {ImportingTopic ? 'Importing...' : 'Import'}
                            </Button>
                        </div>
                    </Form>
                </FormValidationWrap>
            </Modal>
        </div>
    )
}


export default ImportTopic