import React, { useEffect, useState } from 'react'
import { Col, Result, Row } from 'antd'
import { UilUser, UilStopwatch, UilShieldQuestion, UilAward, UilAwardAlt, UilBooks, UilBullseye } from '@iconscout/react-unicons'
import { OverviewDataStyleWrap } from '../styles/dashboardStyle'
import OverviewCard from '../../../components/cards/OverviewCard'

export default function OverallSummary({ studentOverallSummary }) {

    const [summaryData, setSummaryData] = useState([])    
    useEffect(() => {
        const Data = [
            {
                key: 1,
                type: "primary",
                icon: <UilStopwatch />,
                label: "Time Spent (Mins)",
                total: studentOverallSummary?.total_time_spent_minutes || 0,
            },
            {
                key: 2,
                type: "primary",
                icon: <UilShieldQuestion />,
                label: "Questions Answered",
                total: studentOverallSummary?.total_questions_answered || 0,
            },
            {
                key: 3,
                type: "primary",
                icon: <UilAward />,
                label: "Average Score",
                total: studentOverallSummary?.avg_score || 0,
            },
            {
                key: 4,
                type: "primary",
                icon: <UilBullseye />,
                label: "Interviews Taken",
                total: studentOverallSummary?.overall_interviews_taken || 0,
            },
            // {
            //     key: 5,
            //     type: "primary",
            //     icon: <UilUser />,
            //     label: "Students",
            //     total: studentOverallSummary?.attempted_topics_count || 0,
            // },
            // {
            //     key: 6,
            //     type: "primary",
            //     icon: <UilBooks />,
            //     label: "Subjects",
            //     total: studentOverallSummary?.NumberOfUniqueSubjects || 0,
            // },
        ]
        setSummaryData(Data)
    }, [studentOverallSummary])

    return (
        <div>
            {
                summaryData.length !== 0 &&
                <div>
                    <OverviewDataStyleWrap>
                        <Row gutter={25}>
                            {summaryData.map((item, i) =>
                                i <= 3 ?
                                    <Col xxl={6} xl={6} lg={6} sm={12} xs={24} key={i}>
                                        <OverviewCard data={item} contentFirst bottomStatus={false} />
                                    </Col>
                                    :
                                    <Col xxl={12} xl={8} lg={8} sm={12} xs={24} key={i}>
                                        <OverviewCard data={item} contentFirst bottomStatus={false} />
                                    </Col>
                            )}
                        </Row>
                    </OverviewDataStyleWrap>
                </div>
            }
        </div>
    )
}
