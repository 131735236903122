export const renderCodeWithLineNumbers = (code) => {
    return code?.split('\n').map((line, index) => {
        if (line.trim() !== '') {
            return (
                <li
                    key={index}
                    style={{
                        border: '1px solid #e8e8e8',
                        padding: '2px',
                        marginBottom: '4px',
                        cursor: 'move',
                        display: 'flex',
                        fontSize: '14px'
                    }}
                >
                    <span style={{ marginRight: '5px', color: '#888' }}>{index + 1}:</span>
                    <span>{line}</span>
                </li>
            );
        } else {
            return null;
        }
    });
};

    
export const filterBlankLines = (value) => {
    return value.split('\n').filter(line => line.trim() !== '').join('\n');
};

export const handleShuffle = (jumbleRef) => {
    const lines = Array.from(jumbleRef.current.children);
    const parent = jumbleRef.current;

    lines.forEach((li) => {
        li.style.transition = 'transform 3s ease';
        li.style.transform = `translate(${Math.random() * 10 - 5}px, ${Math.random() * 10 - 5
            }px)`;
    });

    setTimeout(() => {
        for (let i = parent.children.length; i >= 0; i--) {
            parent.appendChild(parent.children[(Math.random() * i) | 0]);
        }

        lines.forEach((li) => {
            li.style.transition = 'none';
            li.style.transform = 'none';
        });
    });
};