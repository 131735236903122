import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Input, Modal, Form, Select, message, Space, Checkbox, Tooltip } from 'antd';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import { DownloadOutlined } from '@ant-design/icons';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'; // Import icons
import { Cards } from '../../components/cards/frame/cards-frame';
import { TableWrapper, Main } from '../styled';
import { PageHeader } from '../../components/page-headers/page-headers';
import { useDispatch, useSelector } from 'react-redux';
import { teachersDataRead, teachersDataSubmit, teachersDataUpdate } from '../../redux/teachers/actionCreator';
import { ProjectList, ProjectSorting } from '../project/style';
import { AutoComplete } from '../../components/autoComplete/autoComplete';
import { Button } from '../../components/buttons/buttons';
import { subjectsDataRead } from '../../redux/subjects/actionCreator';
import Papa from 'papaparse';
import { ImportStyleWrap } from '../styled';
import Heading from '../../components/heading/heading';
import Dragger from 'antd/lib/upload/Dragger';
import UilExport from "@iconscout/react-unicons/icons/uil-export";
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { getItem } from '../../utility/localStorageControl';
import { cohortDataRead } from '../../redux/cohorts/actionCreator';

const { Option } = Select;

const sampleCSVData = [
  {
    name: "teacher1",
    username: "sampleteacher1",
    password: "sampleteacher1",
    email: "sampleteacher1@gmail.com",
    sub: ["Competitive Programming"],
  },
  {
    name: "teacher2",
    username: "sampleteacher2",
    email: "sampleteacher2@gmail.com",
    password: "sampleteacher1",
    sub: ["Parallel Programming", "Data Visualisation Techniques"],
  },
  {
    name: "teacher3",
    username: "sampleteacher3",
    password: "sampleteacher3",
    email: "sampleteacher3@gmail.com",
    sub: ["Operating Systems", "Computer Organisation and Architecture"],
  },
];


function Teachers() {

  const loggedadmin = getItem('username');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isBulkModalVisible, setIsBulkModalVisible] = useState(false);
  const [isNewModalVisible, setIsNewModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [bulkForm] = Form.useForm();

  const [selectedSubjectIds, setSelectedSubjectIds] = useState(null);
  const [searchText, setsearchText] = useState(null);
  const [selectedSubjects, setSelectedSubjects] = useState(null);

  const [csvFile, setCSVFile] = useState(null);
  const [csvFileList, setCSVFileList] = useState([]);
  const [parsedCSVData, setParsedCSVData] = useState([]);

  const [editRecord, setEditRecord] = useState(null);

  const [uniqueSubjects, setUniqueSubjects] = useState([])

  const dispatch = useDispatch();
  const { teachers, isLoading, SubjectsData, isSubjectsLoading, TeachersUpdateRes, isTeachersUpdating, TeachersAddRes, isTeachersAdding, cohorts, isCohortsLoading } = useSelector((state) => {
    return {
      teachers: state.Teachers.data,
      isLoading: state.Teachers.loading,

      TeachersUpdateRes: state.TeachersUpdateRes.data,
      isTeachersUpdating: state.TeachersUpdateRes.loading,

      TeachersAddRes: state.TeachersAddRes.data,
      isTeachersAdding: state.TeachersAddRes.loading,

      SubjectsData: state.Subjects.data,
      isSubjectsLoading: state.Subjects.loading,

      cohorts: state.cohorts.data,
      isCohortsLoading: state.cohorts.loading,
    };
  });

  useEffect(() => {
    dispatch(teachersDataRead());
    if (SubjectsData.length === 0) {
      dispatch(subjectsDataRead());
    }
    if (cohorts.length === 0) {
      dispatch(cohortDataRead());
    }
  }, [dispatch]);

  useEffect(() => {
    if (TeachersUpdateRes && TeachersUpdateRes.Error === false) {
      dispatch(teachersDataRead());
      editForm.resetFields();
    }
  }, [TeachersUpdateRes])

  useEffect(() => {
    if (TeachersAddRes && TeachersAddRes.Error === false) {
      dispatch(teachersDataRead());
      form.resetFields();
    }
  }, [TeachersAddRes])

  useEffect(() => {
    if (isModalVisible) {
      const combinedArray = [];
      SubjectsData.forEach(subObj => {        
        cohorts.forEach(cohortObj => {
          combinedArray.push({
            subjectId: subObj.id,
            subjectName: subObj.name,
            cohortId: cohortObj.id,
            cohortName: cohortObj.name
          });
        });
      });
      setUniqueSubjects(combinedArray);
    }
    else {
      setUniqueSubjects([]);
    }
  }, [isModalVisible])

  const [dataSource, setDataSource] = useState([]);
  const [GoodData, setGoodData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [isUsernameChecked, setUsernameChecked] = useState(true);
  const [isNameChecked, setNameChecked] = useState(false);
  const [isSubjectsChecked, setSubjectsChecked] = useState(false);
  const [isEmailChecked, setEmailChecked] = useState(false);
  const [isPasswordChecked, setPasswordChecked] = useState(false);
  const [isAllChecked, setIsAllChecked] = useState(false);

  const handleAllCheckboxToggle = () => {
    const allCheckedState = !isAllChecked;
    setIsAllChecked(allCheckedState);
    if (selectedMode === '' && allCheckedState === true) {
      setEmailChecked(true);
      setSubjectsChecked(true);
      setNameChecked(true);
      setPasswordChecked(true);
    }
    if (selectedMode === '' && allCheckedState === false) {
      setEmailChecked(false);
      setSubjectsChecked(false);
      setNameChecked(false);
      setPasswordChecked(false);
    }
    if ((selectedMode === 'AddnewTeachers' || selectedMode === 'AddNewUpdateExistingTeachers') && allCheckedState === true) {
      setEmailChecked(true);
      setSubjectsChecked(true);
      setNameChecked(true);
      setPasswordChecked(true);
      setSubjectsChecked(true);
    }
    if ((selectedMode === 'AddnewTeachers' || selectedMode === 'AddNewUpdateExistingTeachers') && allCheckedState === false) {
      setEmailChecked(true);
      setSubjectsChecked(false);
      setNameChecked(true);
      setPasswordChecked(true);
      setSubjectsChecked(true);
    }
    if (selectedMode === 'UpdateexistingTeachers' && allCheckedState === false) {
      setEmailChecked(false);
      setSubjectsChecked(false);
      setNameChecked(false);
      setPasswordChecked(false);
    }
    if (selectedMode === 'UpdateexistingTeachers' && allCheckedState === true) {
      setEmailChecked(true);
      setSubjectsChecked(true);
      setNameChecked(true);
      setPasswordChecked(true);
    }
  };


  const handleModeChange = (value) => {
    const shouldCheckNameAndPassword = value === 'AddnewTeachers' || value === 'AddNewUpdateExistingTeachers';
    setSelectedMode(value);
    setEmailChecked(shouldCheckNameAndPassword);
    setNameChecked(shouldCheckNameAndPassword);
    setPasswordChecked(shouldCheckNameAndPassword);
    setSubjectsChecked(shouldCheckNameAndPassword);
  };

  const handleNameCheckboxChange = (e) => {
    if (selectedMode !== 'AddnewTeachers' && selectedMode !== 'AddNewUpdateExistingTeachers') {
      setNameChecked(e.target.checked);
    }
  };

  const handlePasswordCheckboxChange = (e) => {
    if (selectedMode !== 'AddnewTeachers' && selectedMode !== 'AddNewUpdateExistingTeachers') {
      setPasswordChecked(e.target.checked);
    }
  };

  const handleEmailCheckboxChange = (e) => {
    if (selectedMode !== 'AddnewTeachers' && selectedMode !== 'AddNewUpdateExistingTeachers') {
      setEmailChecked(e.target.checked);
    }
  };

  const handleSubjectsCheckboxChange = (e) => {
    if (selectedMode !== 'AddnewTeachers' && selectedMode !== 'AddNewUpdateExistingTeachers') {
      setSubjectsChecked(e.target.checked);
    }
  };

  useEffect(() => {
    const allCheckboxes = [
      isNameChecked,
      isEmailChecked,
      isPasswordChecked,
      isSubjectsChecked,
    ];
    const allChecked = allCheckboxes.every((checkbox) => checkbox === true);
    setIsAllChecked(allChecked);
  }, [isNameChecked, isPasswordChecked, isEmailChecked, isSubjectsChecked]);

  useEffect(() => {
    if (teachers) {
      const filteredData = teachers.sort((a, b) => a.updatedon < b.updatedon ? 1 : -1).map((item, index) => {
        if (item !== undefined) {
          return {
            key: item.id,
            sno: index + 1,
            id: item.id,
            name: item.name,
            username: item.usermaster?.username,
            allsubjectsCohorts: item.tscm?.map(item => (
              `${item.subject.name}-${item.cohort.name}`
            )).join(', '),
            subjectsCohorts: item.tscm?.length > 1 ?
            item.tscm?.slice(0, 1).map(item => (
              `${item.subject.name}-${item.cohort.name}`
            )).join(', ') + ', ...'
            :
            item.tscm?.map(item => (
              `${item.subject.name}-${item.cohort.name}`
            )).join(', '),        
            tscm: item.tscm,
            updatedby: item.updatedby !== null ? item.updatedby : '--------',
            updatedon: new Date(item.updatedon).toLocaleDateString('en-GB'),
            createdon: new Date(item.createdon).toLocaleDateString('en-GB'),
          };
        }
      });
      setDataSource(filteredData);
      setGoodData(filteredData);
      setLoading(false);
    }
  }, [teachers])

  const checkSearch = (i) => {
    for (let j in i) {
      if (i[j] != null && i[j].toString().toLowerCase().includes(searchText.toString().toLowerCase())) {
        return true;
      }
    }
    return false;

  }

  const checkSubject = (i) => {
    let tarr = (i.subjects).split(",");
    let valueInArray = false;
    for (let j of tarr) {
      if (j.trim() === selectedSubjects) {
        valueInArray = true;
        break;
      }
    }
    return valueInArray;
  }

  useEffect(() => {
    let tempValues = [...GoodData];
    if (searchText != null) {
      tempValues = tempValues.filter(checkSearch);
    }
    if (selectedSubjects != null) {
      tempValues = tempValues.filter(checkSubject);
    }
    setDataSource(tempValues);
  }, [searchText, selectedSubjects])

  const handleSearch = (e) => {
    setsearchText(e)
  }


  const handleRemoveCSVFile = () => {
    setCSVFile(null);
    setCSVFileList([])
  }

  const handleCSVFileChange = (uploadedFile) => {
    if (uploadedFile !== null && uploadedFile.fileList.length !== 0) {
      setCSVFileList(uploadedFile.fileList);
      message.success("File Uploaded");
      setTimeout(() => {
        const csvFile = uploadedFile.fileList[0];
        Papa.parse(csvFile.originFileObj, {
          complete: (result) => {
            result.data.pop();
            const modifiedCSVData = result.data.map((row) => {
              if (row.sub) {
                const subjectNames = row.sub.split(',').map(name => name.trim());
                const subjectIds = subjectNames.map(name => {
                  // const subject = subjectsDatatt.find(sub => sub.name === name);
                  // return subject ? subject.id : null; 
                });
                return {
                  ...row,
                  role: "TEACHER",
                  sub: subjectIds.filter(id => id !== null),
                };
              } else {
                return row;
              }
            });
            setParsedCSVData(modifiedCSVData);
          },
          header: true,
        });
      }, 1000);
    } else {
      handleRemoveCSVFile();
      message.warning("File Removed");
    }
  };

  const columns = [
    {
      title: 'S.No.',
      dataIndex: 'sno',
      key: 'sno',
      sorter: (a, b) => a.sno > b.sno ? 1 : -1,
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => a.username > b.username ? 1 : -1,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name > b.name ? 1 : -1,
    },
    {
      title: 'Subjects',
      dataIndex: 'subjectsCohorts',
      key: 'subjectsCohorts',
      sorter: (a, b) => a.subjectsCohorts > b.subjectsCohorts ? 1 : -1,
    },
    {
      title: 'Created On',
      dataIndex: 'createdon',
      key: 'createdon',
      sorter: (a, b) => a.createdon > b.createdon ? 1 : -1,
    },
    {
      title: 'Updated On',
      dataIndex: 'updatedon',
      key: 'updatedon',
      sorter: (a, b) => a.updatedon > b.updatedon ? 1 : -1,
    },
    {
      title: 'Updated By',
      dataIndex: 'updatedby',
      key: 'updatedby',
      sorter: (a, b) => a.updatedby > b.updatedby ? 1 : -1,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <div className='table-actions'>
          <Button
            key="editTeacher"
            onClick={() => handleEdit(record)}
            className="btn-icon "
            type="primary"
            to="#"
            shape="circle"
          >
            <UilEdit />
          </Button>
        </div>
      ),
    }
  ];

  const handleButtonClick = () => {
    if (cohorts.length !== 0 && SubjectsData.length !== 0) {
      setIsModalVisible(true);
    }
    else {
      message.error("Subjects and cohorts must be created.")
    }
  };


  const handleModalCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleNewModalCancel = () => {
    setIsNewModalVisible(false);
    setDispatchData([]);
    setSelectedFieldsData([]);
    setSelectedMode('');
  }

  const handleModalCancell = () => {
    setIsBulkModalVisible(false);
    setCSVFile(null);
    setCSVFileList([]);
    bulkForm.resetFields();
    setNameChecked(false);
    setEmailChecked(false);
    setSubjectsChecked(false);
    setPasswordChecked(false);
  };

  const [selectedFieldsData, setSelectedFieldsData] = useState([]);
  const [dispatchData, setDispatchData] = useState([]);
  const [selectedMode, setSelectedMode] = useState('');

  const handleBulkModalAdd = () => {
    bulkForm.validateFields().then((values) => {
      const selectedFields = [];

      if (isUsernameChecked) selectedFields.push("username");
      if (isNameChecked) selectedFields.push("name");
      if (isSubjectsChecked) selectedFields.push("sub");
      if (isPasswordChecked) selectedFields.push("password");
      if (isEmailChecked) selectedFields.push("email");
      selectedFields.push('role');
      const modifiedCSVData = parsedCSVData.map((row) => {
        const filteredRow = {};
        selectedFields.forEach((field) => {
          if ((field === 'username' && isUsernameChecked) || (field === 'name' && isNameChecked) || (field === 'sub' && isSubjectsChecked) || (field === 'password' && isPasswordChecked) || (field === 'email' && isEmailChecked)) {
            filteredRow[field] = row[field] || '';
          }
        });
        selectedFieldsData.push(filteredRow);
        return filteredRow;
      });
      setSelectedMode(values.mode);
      setDispatchData(modifiedCSVData);
      setCSVFile(null);
      setCSVFileList([]);
      setIsBulkModalVisible(false);
      bulkForm.resetFields();
      setNameChecked(false);
      setEmailChecked(false);
      setSubjectsChecked(false);
      setPasswordChecked(false);
      setIsNewModalVisible(true);
    }).catch((error) => {
      console.error('Error while validating form:', error);
    });
  };

  let str = '';
  if (selectedMode === 'AddnewTeachers') {
    str = 'Add';
  } else if (selectedMode === 'UpdateexistingTeachers') {
    str = 'Update';
  } else if (selectedMode === 'AddNewUpdateExistingTeachers') {
    str = 'Add & Update';
  }

  const columnns = Object.keys(selectedFieldsData[0] || {}).map((key) => ({
    title: key,
    dataIndex: key,
    key: key,
  }));

  columnns.push({
    title: 'Valid',
    dataIndex: 'valid',
    key: 'valid',
    render: (valid) => (
      <Space size="middle">
        {valid ? <CheckCircleOutlined style={{ color: 'orange' }} /> : <CloseCircleOutlined style={{ color: 'red' }} />}
      </Space>
    ),
  });

  const tableData = selectedFieldsData.map((item, index) => {
    const rowData = {
      ...item,
      key: index,
    };
    if (Array.isArray(item.sub) && item.sub.length > 0) {
      const commaSeparatedSubs = item.sub.join(', ');
      if (commaSeparatedSubs) {
        const subIds = commaSeparatedSubs.split(',').map(id => id.trim());
        const subjectNames = subIds.map(subId => {
          // const matchingSubject = subjectsDatatt.find(subject => subject.id === parseInt(subId));
          // return matchingSubject ? matchingSubject.name : '';
        });
        rowData.sub = subjectNames.join(', ');
      }
    }

    rowData.valid = Object.keys(rowData).every(key => {
      if (key === 'valid') return true;
      const value = rowData[key];
      if (Array.isArray(value) && value.length === 0) return false;
      return value !== undefined && value !== '';
    });
    if (!rowData.valid) {
      str = '';
    }
    return rowData;
  });

  const handleBulkTecahersAdd = () => {
    // if(str === 'Add'){
    //     dispatch(teachersbulkDataSubmit(dispatchData));
    // }else if(str === 'Update'){
    //     dispatch(teachersBulkDataUpdate(dispatchData));
    // }else if(str === 'Add & Update'){
    //     dispatch(addnewteachersupdateexisting(dispatchData));
    // }
    // setSelectedFieldsData([]);
    // setIsNewModalVisible(false);
    // setSelectedMode('');
    // str='';
  }


  const validateUsername = (rule, value) => {
    let find = dataSource?.find((obj) => obj.username == value)
    if (!value) {
      return Promise.reject('Please enter student username');
    }
    else if (!/^[a-zA-Z0-9]+$/.test(value)) {
      return Promise.reject('Username can only contain numbers and alphabets');
    }
    else if (find) {
      return Promise.reject('Username already exists!');
    }
    else {
      return Promise.resolve();
    }
  };

  const validatePassword = (rule, value) => {
    if (!value) {
      return Promise.reject('Please enter a password');
    } else if (/\s/.test(value)) {
      return Promise.reject('Password cannot contain spaces');
    } else {
      return Promise.resolve();
    }
  };

  const handleModalAdd = () => {
    form.validateFields().then((values) => {
      const formattedData = values.subjectIds?.map((item) => {
        let subjectCohort = JSON.parse(item)
        return ({
          cohortId: subjectCohort.cohortId,
          subjectId: subjectCohort.subjectId,
        })
      })
      const newRow = {
        name: values.name,
        username: values.username,
        password: values.password,
        tsc: formattedData
      };
      setIsModalVisible(false);
      dispatch(teachersDataSubmit(newRow));
    })
      .catch((error) => {
        console.error('Error while validating form:', error);
      });
  };

  const handleModalUpdate = () => {
    editForm.validateFields().then((values) => {

      const formattedData = values.subjectIds?.map((item) => {
        let subjectCohort = JSON.parse(item)
        return ({
          cohortId: subjectCohort.cohortId,
          subjectId: subjectCohort.subjectId,
        })
      })

      const updatedRecord = {
        id: editRecord.id,
        name: values.name,
        tsc: formattedData
      };
      setIsEditModalVisible(false);
      dispatch(teachersDataUpdate(updatedRecord));
    }).catch((error) => {
      console.error('Error while validating form:', error);
    });
  };

  const handleEdit = (record) => {
    setEditRecord(record);
    setIsEditModalVisible(true);
    const assignedCohortIds = [...new Set(record.tscm?.map((obj) => obj.cohort.id))]

    const combinedArray = [];
    SubjectsData.forEach(subObj => {
      console.log(subObj)
      cohorts.forEach(cohortObj => {
        combinedArray.push({
          subjectId: subObj.id,
          subjectName: subObj.name,
          cohortId: cohortObj.id,
          cohortName: cohortObj.name
        });
      });
    });
    setUniqueSubjects(combinedArray);

    editForm.setFieldsValue({
      name: record.name,
      cohortIds: assignedCohortIds,
      subjectIds: record.tscm?.map((obj) => {
        return JSON.stringify({
          subjectId: obj.subject.id,
          subjectName: obj.subject.name,
          cohortId: obj.cohort.id,
          cohortName: obj.cohort.name
        })
      })
    });
  };

  return (
    <>
      <PageHeader className="ninjadash-page-header-main" title={`Teachers`}
        buttons={
          <Button id="teachers-page-add_teacher-button" onClick={handleButtonClick} key="AddTecaher" type="primary" size="default">
            Add Teacher
          </Button>
        }
      />
      <Main>
        {isLoading || loading || isTeachersUpdating || isTeachersAdding ?
          (
            <div className="spin">
              <CustomSpinner Message={'Loading please wait...'} />
            </div>
          ) : (
            <Row gutter={25}>
              <Col sm={24} xs={24}>
                <Cards headless>
                  <TableWrapper>
                    <ProjectList>
                      <div className='d-flex justify-content-between mb-15 p-0 align-center-v'>
                        <div className="d-flex align-center-v" style={{ marginLeft: 'auto' }}>
                          <AutoComplete id="teachers-page-search-filter" onSearch={handleSearch} dataSource={dataSource} placeholder="Search here" patterns />
                        </div>
                      </div>
                      <div className='table-responsive'>
                        <Table dataSource={dataSource} columns={columns} 
                        expandable={{
                          expandedRowRender: (record) =>
                              <div className='p-20'>
                                  <div className='mb-10'>
                                      <h4 className=''>Assigned Subjects</h4> <p className='d-inline'>{record.allsubjectsCohorts?.split(',').map((sub, index) => <div>{index+1}.{sub}</div>)}</p>
                                  </div>                                
                              </div>
                      }}
                        />
                      </div>
                    </ProjectList>
                  </TableWrapper>
                </Cards>
              </Col>
            </Row>
          )
        }
      </Main>
      <Modal
        title="Add Teacher"
        open={isModalVisible}
        onCancel={handleModalCancel}
        maskClosable={false}
        centered
        footer={[
          <Button id="add-teacher-button" key="AddTeacherRecord" type="primary" onClick={handleModalAdd}>
            Add
          </Button>,
        ]}
      >
        <Form id="add-teacher-form" form={form} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter teacher name" }]}>
            <Input id="add-teacher-enter-teacher-name" placeholder="Enter name" />
          </Form.Item>
          <Form.Item name="username" label="User Name" rules={[{ required: true, validator: validateUsername }]}>
            <Input id="add-teacher-enter-teacher-username" placeholder="Enter username" />
          </Form.Item>
          <Form.Item name="password" label="Password" rules={[{ required: true, validator: validatePassword }]}>
            <Input.Password id="add-teacher-enter-password" placeholder="Enter password" />
          </Form.Item>
          <Form.Item name="subjectIds" label="Subject(s)" rules={[{ required: true, message: "Please select subject(s)" }]}>
            <Select mode='multiple' id="add-teacher-select-subjects" placeholder="Select or search subject" onChange={(e) => setSelectedSubjectIds(e)}
              showSearch
              filterOption={(inputValue, option) =>
                option.children.some((obj) => obj.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0)
              }
              loading={isSubjectsLoading}
            >
              {uniqueSubjects.map((subject) => (
                <Option key={`${subject.subjectName}-${subject.cohortName}`} value={JSON.stringify(subject)}>
                  {subject.subjectName} - {subject.cohortName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Add/Update BulkTeachers"
        open={isBulkModalVisible}
        onCancel={handleModalCancell}
        maskClosable={false}
        footer={[
          <Button id="teachers-page-add/update-preview-teachers" key="AddBulkTeachersRecord" type="primary" onClick={handleBulkModalAdd}>
            Preview
          </Button>,
        ]}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ fontSize: '17px', color: 'black', marginRight: '5px', marginBottom: '0' }}>Sample CSV File:</p>
          <span className="download-text" style={{ marginLeft: '10px', marginRight: '5px', marginBottom: '0', fontSize: '17px' }}>sample.csv</span>
          <DownloadOutlined id="download-sample-csv-file"
            style={{ verticalAlign: 'middle' }}
            className="download-icon"
            onClick={() => {
              const csvContent = Papa.unparse(sampleCSVData);
              const blob = new Blob([csvContent], { type: "text/csv" });
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = "sample.csv";
              link.click();
            }}
          />
        </div>
        <Form id="add/update-teachers-form" form={bulkForm} layout="vertical" style={{ marginTop: '15px' }}>
          <Form.Item name="mode" label="Mode" rules={[{ required: true, message: "Please select mode" }]}>
            <Select id="teachers-page-add/update-select-mode" placeholder="Select mode" onChange={handleModeChange}>
              <Option value="AddnewTeachers">Add new teachers</Option>
              <Option value="UpdateexistingTeachers">Update existing teachers</Option>
              <Option value="AddNewUpdateExistingTeachers">Add new & update existing teachers</Option>
            </Select>
          </Form.Item>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox id="teacher-page-all-checkbox" checked={isAllChecked} onChange={handleAllCheckboxToggle}>
                All
              </Checkbox>
              <Checkbox id="teacher-page-username-checkbox" checked={isUsernameChecked}>
                username
              </Checkbox>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
              <Checkbox id="teacher-page-name-checkbox" checked={isNameChecked} onChange={handleNameCheckboxChange}>
                name
              </Checkbox>
              <Checkbox id="teacher-page-password-checkbox" checked={isPasswordChecked} onChange={handlePasswordCheckboxChange}>
                password
              </Checkbox>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
              <Checkbox id="teacher-page-subject(s)-checkbox" checked={isSubjectsChecked} onChange={handleSubjectsCheckboxChange}>
                Subjects
              </Checkbox>
              <Checkbox id="teacher-page-email-checkbox" checked={isEmailChecked} onChange={handleEmailCheckboxChange}>
                email
              </Checkbox>
            </div>
          </div>
          <Form.Item name="file" rules={[{ required: true, message: 'Please Upload CSV File' }]} style={{ marginTop: '10px' }} label="File">
            <ImportStyleWrap>
              <Dragger id="teachers-page-file-upload"
                maxCount={1}
                beforeUpload={uploadedFile => {
                  if (uploadedFile !== null) {
                    setCSVFile(uploadedFile);
                    return false;
                  }
                }}
                onChange={handleCSVFileChange}
                onRemove={handleRemoveCSVFile}
                fileList={csvFileList}
                accept=".csv"
              >
                <p className="ant-upload-drag-icon">
                  <UilExport />
                </p>
                <Heading as="h4" className="ant-upload-text">
                  Drop or Upload .csv File
                </Heading>
              </Dragger>
            </ImportStyleWrap>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Preview Teachers"
        open={isNewModalVisible}
        onCancel={handleNewModalCancel}
        maskClosable={false}
        width={1300}
        footer={[
          <Button id="preview-teachers-cancel-button" key="cancelpreview" type="light" onClick={handleNewModalCancel}>
            Cancel
          </Button>,
          <Button id="preview-teachers-add/update/add_new_update_existing-button" key="addpreviewstudents" type="primary" onClick={handleBulkTecahersAdd}>
            {str}
          </Button>
        ]}
      >
        <Table columns={columnns} dataSource={tableData} />
      </Modal>
      <Modal
        title="Edit Teacher"
        open={isEditModalVisible}
        onCancel={() => { setIsEditModalVisible(false); editForm.resetFields(); }}
        maskClosable={false}
        centered
        footer={[
          <Button id="edit-teacher-button" key="UpdateTeacherRecord" type="primary" onClick={handleModalUpdate}>
            Update
          </Button>
        ]}
      >
        <Form id="edit-teacher-form" form={editForm} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter teacher name" }]}>
            <Input id="edit-teacher-enter-teacher-name" placeholder='Enter name' />
          </Form.Item>
          <Form.Item name="subjectIds" label="Subject(s)" rules={[{ required: true, message: "Please select subject(s)" }]}>
            <Select mode='multiple' id="add-teacher-select-subjects" placeholder="Select or search subject" onChange={(e) => setSelectedSubjectIds(e)}
              showSearch
              filterOption={(inputValue, option) =>
                option.children.some((obj) => obj.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0)
              }
              loading={isSubjectsLoading}
            >
              {uniqueSubjects.map((subject) => (
                <Option key={`${subject.subjectName}-${subject.cohortName}`} value={JSON.stringify(subject)}>
                  {subject.subjectName} - {subject.cohortName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Teachers;