import React, { useEffect, useRef, useState } from 'react'
import { PageHeader } from '../../components/page-headers/page-headers'
import { Main, TableWrapper } from '../styled'
import { Button, Col, Modal, Result, Row, Select, Table, message } from 'antd'
import { ProjectList, ProjectSorting } from '../project/style'
import { AutoComplete } from '../../components/autoComplete/autoComplete'
import { useDispatch, useSelector } from 'react-redux'
import { Cards } from '../../components/cards/frame/cards-frame'
import { getTopicBySubjectId } from '../../redux/topics/actionCreator'
import { getInterviewAttemptsById, getInterviewsByTopicId } from '../../redux/interview/actionCreator'
import actions from '../../redux/interview/actions'
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { ActionWrapper, StepsStyle } from '../../components/steps/style'
import UilArrowRight from '@iconscout/react-unicons/icons/uil-arrow-right';
import UilArrowLeft from '@iconscout/react-unicons/icons/uil-arrow-left';
import WaveSurfer from 'wavesurfer.js'
import FontAwesome from 'react-fontawesome';
import jsPDF from 'jspdf'
import 'jspdf-autotable';
import { teacherSubjectsDataRead, topicsOfSubjectDataRead } from '../../redux/teacherSubjectsUnits/actionCreator'
import { NoteCardWrap } from '../note/style'

const { getInterviewAttemptsSuccess, getInterviewByTopicSuccess } = actions
const { Option } = Select

export default function InterviewReports() {

    const [selectedCohort, setSelectedCohort] = useState(null)
    const [selectedSubject, setSelectedSubject] = useState(null)
    const [selectedTopic, setSelectedTopic] = useState(null)
    const [tableData, setTableData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [modalVisisble, setModalVisisble] = useState(false)
    const [rowData, setRowData] = useState(null)
    const [questionIndex, setQuestionIndex] = useState(0)
    const waveSurferRef = useRef(null);

    const [uniqueCohorts, setUniqueCohorts] = useState([])
    const [uniqueSubjects, setUniqueSubjects] = useState([])

    const dispatch = useDispatch();
    const { TeacherSubjects, isSubjectsLoading, TopicsData, isTopicLoading, TopicInterviews, isTopicInterviewsLoading, interviewAttempts, isInterviewAttemptsLoading } = useSelector((state) => {
        return {
            TeacherSubjects: state.TeacherSubjects.data,
            isSubjectsLoading: state.TeacherSubjects.loading,
            TopicsData: state.TopicsOfSubject.data,
            isTopicLoading: state.TopicsOfSubject.loading,

            TopicInterviews: state.TopicInterviews.data,
            isTopicInterviewsLoading: state.TopicInterviews.loading,
            interviewAttempts: state.interviewAttempts.data,
            isInterviewAttemptsLoading: state.interviewAttempts.loading,
        };
    });

    useEffect(() => {
        if (dispatch) {
            dispatch(getInterviewByTopicSuccess([]))
            dispatch(getInterviewAttemptsSuccess([]))
            setTableData([])
            setFilteredData([])
            setModalVisisble(false)
            setQuestionIndex(0)
            setRowData(null)
            setSelectedCohort(null)
            setSelectedSubject(null)
            setSelectedTopic(null)
        }
    }, [dispatch]);

    useEffect(() => {
        dispatch(teacherSubjectsDataRead());
    }, []);

    useEffect(() => {
        if (TeacherSubjects.length !== 0) {
            const uniqueCohortsSet = new Set();

            // Iterate over each item in the data
            TeacherSubjects.forEach((item) => {
                // Extract the cohorts array from each item
                const cohorts = item.cohorts || [];

                // Iterate over cohorts and add them to the Set
                cohorts.forEach((cohort) => {
                    const cohortString = JSON.stringify(cohort);

                    if (!uniqueCohortsSet.has(cohortString)) {
                        uniqueCohortsSet.add(cohortString);
                    }
                });
            });

            // Convert the Set back to an array of objects
            const uniqueCohortsArray = Array.from(uniqueCohortsSet, JSON.parse);
            setUniqueCohorts(uniqueCohortsArray)
        }
    }, [TeacherSubjects])

    useEffect(() => {
        if (selectedCohort) {
            const filteredArray = TeacherSubjects.flatMap((subject) => {
                const subjectId = subject.subjectId;
                const subjectName = subject.subjectname;

                return subject.cohorts.map((cohort) => ({
                    subjectId,
                    subjectName,
                    cohortId: cohort.id,
                    cohortName: cohort.name
                }));
            });

            // Filter subjects based on matching cohort IDs
            const filteredSubjects = filteredArray?.filter((subject) => {
                return subject.cohortId === parseInt(selectedCohort)
            });
            setUniqueSubjects(filteredSubjects);
        }
    }, [selectedCohort]);

    useEffect(() => {
        if (selectedSubject) {
            dispatch(topicsOfSubjectDataRead(parseInt(selectedSubject)));
        }
    }, [selectedSubject]);

    const loadData = () => {
        if (selectedTopic && selectedCohort) {
            dispatch(getInterviewsByTopicId(selectedTopic, selectedCohort))
        }
    }

    useEffect(() => {
        if (TopicInterviews) {
            const data = TopicInterviews.sort((a, b) => a.starttime < b.starttime ? 1 : -1).map((obj, index) => ({
                sno: index + 1,
                id: obj.id,
                rollno: obj.student?.usermaster?.username,
                studentName: obj.student?.name,
                subjectName: obj.topic.subject.name,
                topicName: obj.topic.name,
                difficultylevel: obj.interviewlevel,
                interviewStatus: obj.interviewstatus,
                questionsattempted: obj.questionsattempted,
                attemptedon: obj.starttime && new Date(obj.starttime).toLocaleString(),
                avgscore: obj.avgscore === -1 ? 0 : obj.avgscore,
                actions: <div>
                    <Button type='primary' onClick={() => { setModalVisisble(true); dispatch(getInterviewAttemptsById(obj.id)); setRowData(obj) }} >Details</Button>
                </div>
            }))
            setTableData(data)
            setFilteredData(data)
        }
    }, [TopicInterviews])

    const searchkeys = ['rollno', 'studentName']
    const handleSearch = (e) => {
        const value = e;
        const searchData = tableData.length !== 0 && tableData.filter((obj) => searchkeys.some((key) => obj[key].toLowerCase().includes(value.toLowerCase())))
        setFilteredData(searchData)
    }

    const columns = [
        // {
        //     title: 'S.No.',
        //     dataIndex: 'sno',
        //     key: 'sno',
        // },
        {
            title: 'Rollno',
            dataIndex: 'rollno',
            key: 'rollno',
            sorter: (a, b) => a.rollno > b.rollno ? 1 : -1,
        },
        {
            title: 'Name',
            dataIndex: 'studentName',
            key: 'studentName',
        },
        {
            title: 'Difficulty Level',
            dataIndex: 'difficultylevel',
            key: 'difficultylevel',
            filters: Array.from(new Set(TopicInterviews.map(obj => obj.interviewlevel))).map(obj => ({ key: obj, text: obj, value: obj })),
            onFilter: (value, record) => record.difficultylevel === value,
        },
        {
            title: 'Answered',
            dataIndex: 'questionsattempted',
            key: 'questionsattempted',
            sorter: (a, b) => a.questionsattempted > b.questionsattempted ? 1 : -1,
        },
        {
            title: 'Avg Score',
            dataIndex: 'avgscore',
            key: 'avgscore',
            sorter: (a, b) => a.avgscore > b.avgscore ? 1 : -1,
        },
        {
            title: 'Status',
            dataIndex: 'interviewStatus',
            key: 'interviewStatus',
            sorter: (a, b) => a.interviewStatus > b.interviewStatus ? 1 : -1,
            sortDirections: ['ascend'],
        },
        {
            title: 'Attempted On',
            dataIndex: 'attemptedon',
            key: 'attemptedon',
        },
        {
            title: 'Actions',
            render: (_, row) => (
                <div>
                    {
                        row.interviewStatus === "EVALUATING" ?
                            <div className='text-danger'>Evaluating</div>
                            :
                            <Button size='small' type='primary' disabled={row.interviewStatus === "STARTED" || row.interviewStatus === "INCOMPLETE"} onClick={() => { setModalVisisble(true); dispatch(getInterviewAttemptsById(row.id)); setRowData(row); setQuestionIndex(0) }} >Details</Button>
                    }
                </div>
            )
        },
    ];

    const handlePrevQuestion = () => {
        if (questionIndex + 1 !== 0) {
            setQuestionIndex(questionIndex - 1);
        }
    }

    const handleNextQuestion = () => {
        if (questionIndex + 1 < interviewAttempts.length) {
            setQuestionIndex(questionIndex + 1);
        }
    }
    const AudioPlayer = ({ audioUrl }) => {

        useEffect(() => {
            // Initialize WaveSurfer when the component mounts
            waveSurferRef.current = WaveSurfer.create({
                container: '#waveform', // Use a div with the id 'waveform' as the container
                waveColor: 'rgb(230, 98, 58)',
                progressColor: 'rgb(150, 44, 12)',
                barWidth: 2,
                barGap: 1,
                barRadius: 2,
            });

            // Add event listener for the finish event
            waveSurferRef.current.on('finish', () => {
                setIsPlaying(false);
            });

            return () => {
                // Clean up when the component unmounts
                waveSurferRef.current.destroy();
                setIsPlaying(false)
            };
        }, []);

        useEffect(() => {
            // Load the new audio URL when it changes
            if (audioUrl) {
                waveSurferRef.current.load(process.env.REACT_APP_API_ENDPOINT + audioUrl);
            }
        }, [audioUrl]);

        const [isPlaying, setIsPlaying] = useState(false)
        const handlePlayPause = () => {
            if (waveSurferRef.current.isPlaying()) {
                waveSurferRef.current.pause();
                setIsPlaying(false)
            } else {
                waveSurferRef.current.play();
                setIsPlaying(true)
            }
        };

        return <div className='text-center'>
            <div id="waveform" />
            <Button onClick={handlePlayPause} className='mt-20 icon-btn' type='link'>
                {isPlaying ? <FontAwesome name="pause" style={{ fontSize: '22px', background: '#eee', padding: '6px 18px', borderRadius: '6px' }} /> : <FontAwesome name="play" style={{ fontSize: '22px', background: '#eee', padding: '6px 18px', borderRadius: '6px' }} />}
            </Button>
        </div>;
    };

    const generatePDF = () => {
        const now = new Date();
        const doc = new jsPDF('landscape');
        doc.setFont('Arial');
        doc.setFontSize(12);
        let startY = 45;
        let isFirstPage = true;
        let totalPagesExp = '{total_pages_count_string}';

        const data = interviewAttempts.map((obj, index) => [
            index + 1,
            obj.question.question,
            obj.answer || 'NA',
            obj.evaluationfeedback1 + "\n\nScore: " + obj.evaluationscore1 || 'NA',
            // obj.evaluationscore1,
            obj.evaluationfeedback2 + "\n\nScore: " + obj.evaluationscore2 || 'NA',
            // obj.evaluationscore2,
            obj.avgscore || 0
        ]);

        doc.autoTable({
            head: [['#', 'Question', "Student Answer", 'Evaluation Feedback 1', 'Evaluation Feedback 2', 'Avg Score']],
            body: data,
            startY: startY,
            theme: 'grid',
            columnStyles: {
                0: { cellWidth: 8 },
                1: { cellWidth: 50 },
                2: { cellWidth: 80 },
                3: { cellWidth: 53 },
                // 4: { cellWidth: 22 },
                4: { cellWidth: 53 },
                // 6: { cellWidth: 22 },
                5: { cellWidth: 25 },
            },

            didDrawPage: function (data) {
                const pageWidth = doc.internal.pageSize.width;

                if (isFirstPage) {
                    doc.setFontSize(12);
                    doc.text(`Roll No.: ${rowData?.rollno}`, startY, 10);
                    doc.text(`Student Name: ${rowData?.studentName}`, pageWidth - startY, 10, { align: 'right' });
                    doc.text(`Subject: ${rowData?.subjectName}`, startY, 22);
                    doc.text(`Topic: ${rowData?.topicName}`, pageWidth - startY, 22, { align: 'right' });
                    doc.text(`Difficulty Level: ${rowData?.difficultylevel}`, startY, 34);
                    doc.text(`Avg Score: ${rowData?.avgscore}`, pageWidth - startY, 34, { align: 'right' });
                    doc.text(``, pageWidth - startY, 45);
                    isFirstPage = false;
                }

                if (isFirstPage) {
                    startY = 45
                    data.settings.margin.top = startY;
                } else {
                    startY = 10;
                    data.settings.margin.top = startY;
                }
                var str = 'Page ' + doc.internal.getNumberOfPages();
                if (typeof doc.putTotalPages === 'function') {
                    str = str + ' of ' + totalPagesExp;
                }
                doc.setFontSize(10);
                const pageHeight = doc.internal.pageSize.height ? doc.internal.pageSize.height : doc.internal.pageSize.getHeight();
                const centerPos = pageWidth / 2;
                const textWidth = (doc.getStringUnitWidth('Date Generated: ' + now.toLocaleString()) * doc.internal.getFontSize() / doc.internal.scaleFactor) - 50;
                const xPos = centerPos - textWidth / 2;
                doc.text(`InterviewSystem`, startY, pageHeight - 10);
                doc.text('Date Generated: ' + now.toLocaleString(), xPos, pageHeight - 10, { align: 'center' });
                doc.text(str, pageWidth - startY + 20, pageHeight - 10, { align: 'right' });

            },
        });
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp)
        }

        doc.save(`${rowData.rollno}_answers.pdf`);
    };

    return (
        <div>
            <PageHeader className="ninjadash-page-header-main" title={`Interviews`} />
            <Main>
                <Row gutter={25}>
                    <Col sm={24} xs={24}>
                        <ProjectSorting>
                            <div className='project-sort-bar'>
                                <div className="sort-group">
                                    <Select id="interviews-page-cohort-filter" placeholder="Select cohort" value={selectedCohort} onChange={(e) => { setSelectedCohort(e); setSelectedSubject(null); setSelectedTopic(null) }} style={{ minWidth: '200px', fontSize: '14px' }} loading={isSubjectsLoading}>
                                        {uniqueCohorts.length !== 0 && uniqueCohorts.map((cohort) => (
                                            <Option key={cohort.id} value={cohort.id}>
                                                {cohort.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                                <div className="sort-group">
                                    <Select id="interviews-page-subject-filter" placeholder="Select subject" value={selectedSubject} onChange={(e) => { setSelectedSubject(e); setSelectedTopic(null) }} style={{ minWidth: '200px', fontSize: '14px' }} loading={isSubjectsLoading}>
                                        {selectedCohort && uniqueSubjects.length !== 0 && uniqueSubjects.map((subject) => (
                                            <Option key={subject.subjectId} value={subject.subjectId}>
                                                {subject.subjectName}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                                <div className="sort-group mr-10">
                                    <Select id="interviews-page-topic-filter" placeholder="Select topic" value={selectedTopic} onChange={(e) => { setSelectedTopic(e) }} style={{ minWidth: '200px', fontSize: '14px' }} loading={isTopicLoading}>
                                        {selectedSubject && TopicsData.length !== 0 && TopicsData.map((topic) => (
                                            <Option key={topic.id} value={topic.id}>
                                                {topic.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                                <div className="sort-group">
                                    <Button type={'primary'} onClick={() => { loadData() }} disabled={selectedTopic === null}>Get</Button>
                                </div>
                            </div>
                        </ProjectSorting>
                        <Cards headless>
                            <NoteCardWrap>
                                <div className="d-flex justify-content-end mb-20">
                                    <AutoComplete id="" onSearch={handleSearch} dataSource={filteredData} placeholder="Search here" patterns />
                                </div>
                                {
                                    isTopicInterviewsLoading ?
                                        <div className="custom-spin">
                                            <CustomSpinner />
                                        </div>
                                        :
                                        <TableWrapper>
                                            <ProjectList>
                                                <div className="table-responsive">
                                                    <Table                                                    
                                                        dataSource={filteredData}
                                                        columns={columns}
                                                        showSorterTooltip={false}
                                                        pagination={{
                                                            className: 'd-flex align-center-v',
                                                            size: 'small',
                                                            showSizeChanger: true,
                                                            showTotal: (total) => `Total ${total} Interviews`
                                                        }} />
                                                </div>
                                            </ProjectList>
                                        </TableWrapper>
                                }
                            </NoteCardWrap>
                        </Cards>
                    </Col>
                </Row>

                <Modal
                    open={modalVisisble}
                    title={`Attempt of ${rowData?.rollno} | ${rowData?.studentName}`}
                    width={1200}
                    centered
                    maskClosable={false}
                    footer={false}
                    onCancel={() => { setModalVisisble(false) }}
                >
                    {
                        isInterviewAttemptsLoading ?
                            <div className="spin">
                                <CustomSpinner Message={'Loading... Please wait.'} />
                            </div>
                            :
                            interviewAttempts.length === 0 ?
                                <div>
                                    <Result
                                        title="Attempts not found!"
                                        status="warning"
                                    />
                                </div>
                                :
                                interviewAttempts.length !== 0 &&
                                <div>
                                    <div>
                                        <div className='mb-10 d-flex justify-content-between'>
                                            <h3>Submitted Q & A</h3>
                                            <div>
                                                Avg score: {interviewAttempts[0].interview.avgscore}
                                                <Button type={'primary'} onClick={() => { generatePDF() }} style={{ marginLeft: '15px' }}>Download PDF</Button>
                                            </div>
                                        </div>
                                        <Row gutter={25}>
                                            <Col xxl={{ span: 22, offset: 1 }} xl={{ span: 22, offset: 1 }} lg={{ span: 22, offset: 1 }} md={{ span: 22, offset: 1 }} xs={{ span: 22, offset: 1 }}>
                                                <div>
                                                    <StepsStyle current={questionIndex} size="small">
                                                        {interviewAttempts.map((question, index) => (
                                                            <StepsStyle.Step key={index} />
                                                        ))}
                                                    </StepsStyle>
                                                    <div className={'mt-30'} style={{ textAlign: 'left' }}>
                                                        <div style={{ marginTop: '15px', marginBottom: '10px' }} className='d-flex justify-content-between align-center-v'>
                                                            <h3>{questionIndex + 1}. {interviewAttempts[questionIndex].question?.question}</h3>
                                                            <span className='status'>{interviewAttempts[questionIndex].status}</span>
                                                        </div>
                                                        <div>
                                                            <p>Ans: {interviewAttempts[questionIndex].answer}</p>

                                                            {
                                                                interviewAttempts[questionIndex].status === "ATTEMPTED" &&
                                                                <AudioPlayer audioUrl={interviewAttempts[questionIndex].audiolink} />
                                                                // <audio controls
                                                                //     key={
                                                                //         process.env.REACT_APP_API_ENDPOINT +
                                                                //         interviewAttempts[questionIndex].audiolink
                                                                //     }
                                                                //     onError={(e) => message.error('Audio error')}
                                                                // >
                                                                //     <source src={process.env.REACT_APP_API_ENDPOINT + interviewAttempts[questionIndex].audiolink} type="audio/wav" />
                                                                //     Your browser does not support the audio element.
                                                                // </audio>
                                                            }
                                                        </div>
                                                        <div style={{ marginTop: '30px' }}>
                                                            <h4 className='mt-10'>Evaluation Feedback-1 | {interviewAttempts[questionIndex].evaluationfeedback1 !== null && "Score: " + interviewAttempts[questionIndex].evaluationscore1}</h4>
                                                            <p style={{ textAlign: 'justify', paddingBottom: '15px' }} className='m-0'>
                                                                {
                                                                    interviewAttempts[questionIndex].evaluationfeedback1 === null ?
                                                                        'NA'
                                                                        :
                                                                        interviewAttempts[questionIndex].evaluationfeedback1
                                                                }
                                                            </p>
                                                            <h4 className='mt-10'>Evaluation Feedback-2 | {interviewAttempts[questionIndex].evaluationfeedback2 !== null && "Score: " + interviewAttempts[questionIndex].evaluationscore2}</h4>
                                                            <p style={{ textAlign: 'justify', paddingBottom: '15px' }} className='m-0'>
                                                                {interviewAttempts[questionIndex].evaluationfeedback2 === null ? 'NA' : interviewAttempts[questionIndex].evaluationfeedback2}
                                                            </p>
                                                        </div>
                                                        <div>Question Average score {interviewAttempts[questionIndex].avgscore}</div>
                                                    </div>
                                                </div>
                                                <ActionWrapper>
                                                    <div className="step-action-wrap pb-20">
                                                        <div className="step-action-inner">
                                                            <div className="steps-action">

                                                                <Button className="btn-prev" type="white" outlined style={{ color: '#555', marginRight: '10px' }} onClick={handlePrevQuestion} disabled={questionIndex === 0}>
                                                                    <UilArrowLeft />
                                                                    Previous
                                                                </Button>
                                                                {questionIndex < (interviewAttempts.length !== 0 && interviewAttempts.length - 1) && (
                                                                    <Button
                                                                        className="btn-next"
                                                                        type="primary"
                                                                        onClick={handleNextQuestion}
                                                                    >
                                                                        Next
                                                                        <UilArrowRight />
                                                                    </Button>
                                                                )}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </ActionWrapper>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                    }
                </Modal>

            </Main>
        </div>
    )
}
