import {
  UilUsersAlt,
  UilGraduationCap,
  UilBookOpen,
  UilArrowGrowth,
  UilAward,
  UilCalender,
  UilBookAlt,
  UilUserMd,
  UilEye,
  UilApps,
  UilTable,
  UilSchedule,
  UilWrench,
  UilUniversity,
  UilMeetingBoard
} from '@iconscout/react-unicons';
import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const path = '/reviewer';

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const items = [

    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
        {t('Dashboard')}
      </NavLink>,
      'dashboard',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
          <UilApps />
        </NavLink>
      ),
      null
    ),

    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/subjects`}>
        {t('Subjects')}
      </NavLink>,
      'subjects',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/subjects`}>
          <UilBookAlt />
        </NavLink>
      ),
      null
    ),

    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/schedules`}>
        Schedules
      </NavLink>,
      'schedules',
      !topMenu &&
      <NavLink className="menuItem-iocn" to={`${path}/schedules`}>
        <UilCalender />
      </NavLink>,
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/interviews`}>
        {t('Interviews')}
      </NavLink>,
      'interviews',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/interviews`}>
          <UilMeetingBoard />
        </NavLink>
      ),
      null,
    ),

    // getItem(t('Assign'), 'Assign', !topMenu && <UilWrench />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/students-cohort`}>
    //       {t('Student to a Cohort')}
    //     </NavLink>,
    //     'students-cohort',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/teachers-cohort`}>
    //       {t('Teachers to a Cohort')}
    //     </NavLink>,
    //     'teachers-cohort',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/subjects-cohort`}>
    //       {t('Subjects to a Cohort')}
    //     </NavLink>,
    //     'subjects-cohort',
    //     null,
    //   ),
    // ]),    
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
            `${mainPathSplit.length === 1 ? 'dashboard' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
            }`,
          ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
