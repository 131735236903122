import actions from './actions';

const {
  GET_LEADERBOARD_RESULTS_BEGIN,
  GET_LEADERBOARD_RESULTS_SUCCESS,
  GET_LEADERBOARD_RESULTS_ERR,
} = actions;

const getInitialState = {
  data: [],    
  loading: false,
  error: null,
};

const getLeaderboardReducer = (state = getInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_LEADERBOARD_RESULTS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_LEADERBOARD_RESULTS_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_LEADERBOARD_RESULTS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};



export { getLeaderboardReducer };
