import React, { useEffect, useState } from 'react';
import { Cards } from '../../components/cards/frame/cards-frame';
import { NoteCardWrap } from '../note/style';
import Heading from '../../components/heading/heading';
import { FormValidationWrap } from '../forms/overview/Style';
import { Col, Form, Rate, Result, Row } from 'antd';
import { Button } from '../../components/buttons/buttons';
import { useDispatch, useSelector } from 'react-redux';
import { getFeedbackQuestions, postFeedback, verifyUserFeedback } from '../../redux/contentFeedback/actionCreator';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import UilInfoCircle from '@iconscout/react-unicons/icons/uil-info-circle';
import { alertModal } from '../../components/modals/antd-modals';

function ContentFeedback({ topicName, topicId }) {

    const [form] = Form.useForm();

    const dispatch = useDispatch();

    const { CFQuestions, isLoading, VerfiyRes, isVerify, FBPostRes, isPosting } = useSelector((state) => {
        return {
            CFQuestions: state.CFQuestions.data,
            isLoading: state.CFQuestions.loading,
            VerfiyRes: state.VerifyRes.data,
            isVerify: state.VerifyRes.loading,
            FBPostRes: state.FBPostRes.data,
            isPosting: state.FBPostRes.loading,

        };
    });

    // console.log(FBPostRes) {msg:'saved'}    

    useEffect(() => {
        if (topicId) {
            dispatch(verifyUserFeedback(parseInt(topicId)))
            form.resetFields()
        }
    }, [dispatch, FBPostRes])

    useEffect(() => {
        // if true the user has not submitted feedback yet
        if (VerfiyRes !== null && VerfiyRes.feedback === true) {
            dispatch(getFeedbackQuestions())
        }
    }, [VerfiyRes])

    const FormatScore = (score) => {
        if (score === 1) {
            return 25
        }
        else if (score === 2) {
            return 50
        }
        else if (score === 3) {
            return 75
        }
        else if (score === 4) {
            return 100
        }
    }

    const handleFormSubmit = (formData) => {
        alertModal.confirm({
            title: `Are you sure?`,
            content: 'Once given cannot be changed.',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                const feedbackData = {
                    topicId: parseInt(topicId),
                    feedback: Object.keys(formData).length !== 0 && Object.keys(formData).map((questionId) => ({
                        feedbackQId: parseInt(questionId),
                        score: FormatScore(formData[questionId]),
                    })),
                };
                dispatch(postFeedback(feedbackData));
            },
            onCancel() { },
        });
    };

    const desc = ['Poor', 'Average', 'Good', 'Excellent'];

    return (
        <div>
            <Cards headless>
                <Row className='d-flex justify-content-between' gutter={25}>
                    <Col xxl={{ span: 11, offset: 1 }} xl={{ span: 11, offset: 1 }} lg={{ span: 11, offset: 1 }} md={{ span: 11, offset: 1 }} xs={12}>
                        <h3>Your feedback on the content</h3>
                    </Col>
                    <Col xxl={{ span: 11, offset: 1 }} xl={{ span: 11, offset: 1 }} lg={{ span: 11, offset: 1 }} md={{ span: 11, offset: 1 }} xs={12}>
                        <p className='fontsize-12 align-center-v justify-content-end'><UilInfoCircle size={14} color={'orange'} />&nbsp;<i>Feedback once given cannot be changed!</i></p>
                    </Col>
                </Row>
                <Heading as={'h3'} className='text-center mb-25'>{topicName}</Heading>
                <NoteCardWrap>
                    {
                        isLoading || isVerify || isPosting ?
                            <div className="custom-spin">
                                <CustomSpinner />
                            </div>
                            :
                            VerfiyRes !== null && VerfiyRes.feedback === true ?
                                <Row gutter={25}>
                                    <Col xxl={{ span: 22, offset: 1 }} xl={{ span: 22, offset: 1 }} lg={{ span: 22, offset: 1 }} md={{ span: 22, offset: 1 }} xs={{ span: 22, offset: 1 }}>
                                        <FormValidationWrap>
                                            {CFQuestions.length !== 0 ?
                                                <Form name="sDash_validation-form" form={form} layout="vertical" onFinish={handleFormSubmit}>
                                                    {
                                                        CFQuestions.map((data, index) =>
                                                            <Form.Item
                                                                key={index}
                                                                name={data.id}
                                                                rules={[{ required: true, message: 'This field is required!' }]}
                                                                label={index + 1 + '. ' + data.question}
                                                            >
                                                                <Rate
                                                                    tooltips={desc}
                                                                    count={4}
                                                                    allowClear
                                                                    allowHalf={false}
                                                                    style={{ fontSize: 30 }}
                                                                />
                                                            </Form.Item>
                                                        )
                                                    }
                                                    <div className='text-right mb-20'>
                                                        <Button htmlType="submit" type="primary" >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </Form>
                                                :
                                                <Result
                                                    status="warning"
                                                    title="Feedback not found"
                                                />

                                            }
                                        </FormValidationWrap>
                                    </Col>
                                </Row>
                                :
                                <div className=''>
                                    <Result
                                        status="success"
                                        title="Thank you for your feedback!"
                                        subTitle={<span className='fontsize-13'>Submitted on {VerfiyRes != null && new Date(VerfiyRes.lastupdatedon).toLocaleString()}</span>}
                                    />
                                </div>

                    }
                </NoteCardWrap>
            </Cards>
        </div>
    );
}

export default ContentFeedback;