import React from 'react';
import { Avatar, Upload } from 'antd';
import UilCamera from '@iconscout/react-unicons/icons/uil-camera';
import UilTrophy from '@iconscout/react-unicons/icons/uil-trophy';
import UilClock from '@iconscout/react-unicons/icons/uil-clock';
import { Link, NavLink } from 'react-router-dom';
import { ProfileAuthorBox } from './style';
import Heading from '../../../../components/heading/heading';
import { Cards } from '../../../../components/cards/frame/cards-frame';
import { getItem } from '../../../../utility/localStorageControl';

function TeacherAuthorBox() {
  const profilename = getItem('profilename')
  const username = getItem('username')
  const collegeName = getItem('collegeName')
  const name = profilename ? profilename : 'Unknown'
  return (
    <ProfileAuthorBox>
      <Cards headless>
        <div className="author-info">
          <figure>
            <Avatar size={110}>{name[0].toUpperCase()}</Avatar>
            {/* <img src={require('../../../../static/img/users/1.png')} alt="" /> */}

            {/* <Upload>
              <Link to="#">
                <UilCamera />
              </Link>
            </Upload> */}
          </figure>
          <figcaption>
            <div className="info">
              <Heading as="h4">{name[0].toUpperCase() + name.slice(1).toLowerCase()}</Heading>
              <p>{username} ~ {collegeName}</p>
            </div>
          </figcaption>
        </div>
        <nav className="settings-menmulist">
          <ul>
            {/* <li>
              <NavLink to={`leaderboard`}>
                <UilTrophy />
                Leaderboard
              </NavLink>
            </li> */}
            <li>
              <NavLink to={`change-password`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-key"
                >
                  <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4" />
                </svg>
                Change Password
              </NavLink>
            </li>
          </ul>
        </nav>
      </Cards>
    </ProfileAuthorBox>
  );
}

export default TeacherAuthorBox;
