import { 
    READ_STUDENT_BADGES_BEGIN,
    READ_STUDENT_BADGES_SUCCESS,
    READ_STUDENT_BADGES_ERROR,

    READ_BADGE_BY_ID_BEGIN,
    READ_BADGE_BY_ID_SUCCESS,
    READ_BADGE_BY_ID_ERROR,

    READ_SUB_BADGE_BY_ID_BEGIN,
    READ_SUB_BADGE_BY_ID_SUCCESS,
    READ_SUB_BADGE_BY_ID_ERROR,
 } from './actions';

const initialState = {
    data: [],
    loading: false,
    error: null,
};

const initialBadgeState = {
    data: null,
    loading: false,
    error: null,
};

const initialSubBadgeState = {
    data: null,
    loading: false,
    error: null,
};

const StudentBadgesDataReducer = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case READ_STUDENT_BADGES_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case READ_STUDENT_BADGES_SUCCESS:
            return {
                ...state,
                data,
                error: false,
                loading: false,
            };
        case READ_STUDENT_BADGES_ERROR:
            return {
                ...state,
                error : err,
                loading: false,
            };
        default:
            return state;
    }
};

const ReadBadgeByIdReducer = (state = initialBadgeState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case READ_BADGE_BY_ID_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case READ_BADGE_BY_ID_SUCCESS:
            return {
                ...state,
                data,
                error: false,
                loading: false,
            };
        case READ_BADGE_BY_ID_ERROR:
            return {
                ...state,
                error : err,
                loading: false,
            };
        default:
            return state;
    }
};

const ReadSubBadgeByIdReducer = (state = initialSubBadgeState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case READ_SUB_BADGE_BY_ID_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case READ_SUB_BADGE_BY_ID_SUCCESS:
            return {
                ...state,
                data,
                error: false,
                loading: false,
            };
        case READ_SUB_BADGE_BY_ID_ERROR:
            return {
                ...state,
                error : err,
                loading: false,
            };
        default:
            return state;
    }
};


export { StudentBadgesDataReducer, ReadBadgeByIdReducer, ReadSubBadgeByIdReducer };
