import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Input, Modal, Form, Select } from 'antd';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import { Cards } from '../../components/cards/frame/cards-frame';
import { TableWrapper, Main } from '../styled';
import { PageHeader } from '../../components/page-headers/page-headers';
import { yearsemesterDataRead, yearsemesterDataSubmit, yearsemesterDataUpdate } from '../../redux/yearsemester/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectList, ProjectSorting } from '../project/style';
import { AutoComplete } from '../../components/autoComplete/autoComplete';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { Button } from '../../components/buttons/buttons';
import { getItem } from '../../utility/localStorageControl';

const { Option } = Select;

function YearSemester() {
  const clgName = getItem('collegeName');
  let ClgName = '('+clgName+')';
  const loggedadmin = getItem('username');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  const [searchText, setsearchText] = useState(null);
  const [selectedYearSemesterId, setSelectedYearSemesterId] = useState(null);

  const [editRecord, setEditRecord] = useState(null);

  const dispatch = useDispatch();
  const { crud, isLoading, YearsemesterUpdateRes, isYearsemesterUpdating ,YearsemesterAddRes, isYearsemesterAdding} = useSelector((state) => {
    return {
      crud: state.YearSemester.data,
      isLoading: state.YearSemester.loading,
      YearsemesterUpdateRes: state.YearsemesterUpdateRes.data,
      isYearsemesterUpdating: state.YearsemesterUpdateRes.loading,
      YearsemesterAddRes: state.YearsemesterAddRes.data,
      isYearsemesterAdding: state.YearsemesterAddRes.loading,

    };
  });
  
  useEffect(() => {
    if(dispatch){
      dispatch(yearsemesterDataRead(clgName));
    }
  }, [dispatch]);
  
  const [dataSource, setDataSource] = useState([]);
  const [GoodData, setGoodData] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(()=> {
    if(YearsemesterUpdateRes !== null){
      dispatch(yearsemesterDataRead(clgName));
    }
  },[YearsemesterUpdateRes])
  
  useEffect(()=> {
    if(YearsemesterAddRes.length !== 0){
      dispatch(yearsemesterDataRead(clgName));
    }
  },[YearsemesterAddRes])

  function formatDateTime(dateTime) {
    const dateObject = dateTime;
    const formattedDate = dateObject.split('T')[0].replace(/-/g, '/');
    return formattedDate;
  }
  
  useEffect(() => {
    if (crud) {
      const newData = crud.map((yrsem,index) => ({
        key: yrsem.id,
        sno:index+1,
        id: yrsem.id,
        code: yrsem.code,
        name: yrsem.name,
        updatedon: formatDateTime(yrsem.updatedon),
      }));
      setDataSource(newData);
      setGoodData(newData);
      setLoading(false);
    }
  }, [crud]);
  
  const checkYearSemester=(i)=>{
    let tarr = i.code;
    let valueInArray=false;
    if(tarr === selectedYearSemesterId){
      valueInArray=true;
    }
    return valueInArray;
  }
  
  const checkSearch=(i)=>{
    for(let j in i){
      if(i[j]!=null && i[j].toString().toLowerCase().includes(searchText.toString().toLowerCase())){
        return true;
      }
    }
    return false;
  }
  
  useEffect(() => {
    let tempValues=[...GoodData];
    if(selectedYearSemesterId!=null){
      tempValues=tempValues.filter(checkYearSemester);
    }
    if(searchText!=null){
      tempValues=tempValues.filter(checkSearch);
    }
    setDataSource(tempValues); 
  },[selectedYearSemesterId, searchText])
  
  const handleYearSemesterChange = (value) => {
    setSelectedYearSemesterId(value);
  };

  const handleSearch=(e)=>{
    setsearchText(e)
  }

    const columns = [
      {
        title: 'S.No.',
        dataIndex: 'sno',
        key: 'sno',
      },
      {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Updated On',
        dataIndex: 'updatedon',
        key: 'updatedon',
      },
    ];

    if (loggedadmin !== 'superadmin') {
      columns.push({
        title: 'Actions',
        key: 'actions',
        render: (text, record) => (
          <div className='table-actions'>
            <Button
              key="editYearSemster"
              onClick={() => handleEdit(record)}
              className="btn-icon "
              type="primary"
              to="#"
              shape="circle"
            >
              <UilEdit />
            </Button>
          </div>
        ),
      });
    }

  const handleButtonClick = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleModalAdd = () => {
    form.validateFields().then((values) => {
      const newRow = {
        code: values.code,
        name: values.name,
      };
      form.resetFields();
      setIsModalVisible(false);
      dispatch(yearsemesterDataSubmit(newRow));
    })
    .catch((error) => {
      console.error('Error while validating form:', error);
    });
  };

  const handleModalUpdate = () => {
    editForm.validateFields().then((values) => {
      const updatedRecord = {
        id: editRecord.id,
        code: values.code,
        name: values.name,
      };
      setIsEditModalVisible(false);
      dispatch(yearsemesterDataUpdate(updatedRecord));
    })
    .catch((error) => {
      console.error('Error while validating form:', error);
    });
  };

  const handleEdit = (record) => {
    setEditRecord(record);
    setIsEditModalVisible(true);
    editForm.setFieldsValue({
      code: record.code,
      name: record.name,
    });
  };

  return (
    <>
      <PageHeader className="ninjadash-page-header-main" title={`Year-Semesters ${loggedadmin === 'superadmin' ? ClgName : ''}`} 
        buttons={
          loggedadmin !== 'superadmin' ?
          [
            <Button id="yearsemester-page-add_yearsemester-button" onClick={handleButtonClick} key="AddYearSemester" type="primary" size="default">
              Add Year-Semester
            </Button>,
          ] : []}
          />
      <Main>
      {isLoading || loading || isYearsemesterUpdating || isYearsemesterAdding? 
        (
          <div className="custom-loading-spin-with-text">
            <CustomSpinner />
            <p style={{fontWeight: 'bold',color: '#8231D3'}}>
            Loading please wait...
            </p>
          </div>
        ) : (
          <Row gutter={25}>
            <Col sm={24} xs={24}>
              <ProjectSorting>
                <div className='project-sort-bar'>
                  <div className="project-sort-search">
                      <AutoComplete id="yearsemester-page-search-filter" onSearch={handleSearch} dataSource={dataSource} placeholder="Search here" patterns />
                  </div>
                  <div className="project-sort-group">
                    <div className="sort-group">
                      <span>Year-Semester :</span>
                      <Select id="yearsemester-page-yearsemester-filter" onChange={(e)=>{handleYearSemesterChange(e)}}  defaultValue="ALL" style={{ minWidth: '200px', fontSize: '12px' }}>
                      { selectedYearSemesterId!=null && <Option key={"ALL"} value={null}>
                          {"ALL"}
                      </Option>}
                      {Array.from(new Set(crud.map(item => item.code))).map((yrsem) => (
                          <Option key={yrsem} value={yrsem}>
                              {yrsem}
                          </Option>
                      ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </ProjectSorting>
              <Cards headless>
                <TableWrapper>
                  <ProjectList>
                    <div className="table-responsive">
                      <Table dataSource={dataSource} columns={columns} />
                    </div>
                  </ProjectList>
                </TableWrapper>
              </Cards>
            </Col>
          </Row>
        )
      }
      </Main>
      <Modal
        title="Add Year-Semester"
        open={isModalVisible}
        onCancel={handleModalCancel}
        maskClosable={false}
        footer={[
          <Button id="add-yearsemester-button" key="AddYearSemesterRecord" type="primary" onClick={handleModalAdd}>
            Add
          </Button>,
        ]}
      >
        <Form id="add-yearsemester-form" form={form} layout="vertical">
          <Form.Item name="code" label="Code" rules={[{ required: true , message: "Please enter year-semester code"}]}>
            <Input id="add-yearsemester-enter-yearsemester-code" placeholder="Enter year-semester code(e.g., I-I)"/>
          </Form.Item>
          <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter year-semester name" }]}>
            <Input id="add-yearsemester-enter-yearsemester-name" placeholder="Enter year-semester name(e.g., I Year - I Semester)"/>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit Year-Semester"
        open={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        maskClosable={false}
        footer={[
          <Button id="edit-yearsemester-button" key="UpdateYearSemesterRecord" type="primary" onClick={handleModalUpdate}>
            Update
          </Button>,
        ]}
      >
        <Form id="edit-yearsemester-form" form={editForm} layout="vertical">
          <Form.Item name="code" label="Code" rules={[{ required: true, message: "Please enter year-semester code" }]}>
            <Input id="edit-yearsemester-enter-yearsemester-code" placeholder="Enter year-semester code(e.g., I-I)"/>
          </Form.Item>
          <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter year-semester name" }]}>
            <Input id="edit-yearsemester-enter-yearsemester-name" placeholder="Enter year-semester name(e.g., I Year - I Semester)"/>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default YearSemester;
