import { Input } from 'antd'
import React from 'react'

export default function UserPrompt({userPrompt, setUserPrompt,dbPrompt, status}) {
    return (
        <div>
            <p className='mt-10 mb-0 text-left'>Prompt</p>
            <Input.TextArea placeholder='Give your prompt here' 
            value={userPrompt || dbPrompt} 
            rows={8} onChange={(e) => setUserPrompt(e.target.value)} 
            disabled={status === "ATTEMPTED"} />
        </div>
    )
}
