import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Input, Modal, Form, Select, message } from 'antd';
import { UilEdit } from '@iconscout/react-unicons';
import { Cards } from '../../components/cards/frame/cards-frame';
import { TableWrapper, Main } from '../styled';
import { PageHeader } from '../../components/page-headers/page-headers';
import { useDispatch, useSelector } from 'react-redux';
import { subjectsDataRead, subjectsDataSubmit, subjectsDataUpdate } from '../../redux/subjects/actionCreator';
import { ProjectList, ProjectSorting } from '../project/style';
import { AutoComplete } from '../../components/autoComplete/autoComplete';
import { Button } from '../../components/buttons/buttons';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { getItem } from '../../utility/localStorageControl';
import { cohortDataRead } from '../../redux/cohorts/actionCreator';


const { Option } = Select;

function Subjects() {
  const clgName = getItem('collegeName');
  let ClgName = '(' + clgName + ')';
  const loggedadmin = getItem('username');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [editRecord, setEditRecord] = useState(null);

  const [selectedRegulationId, setSelectedRegulationId] = useState(null);
  const [selectedYearSemesterId, setSelectedYearSemesterId] = useState(null);
  const [selectedBranchId, setSelectedBranchId] = useState(null);
  const [searchText, setsearchText] = useState(null);

  const dispatch = useDispatch();
  const { crud, isLoading, SubjectsUpdateRes, isSubjectsUpdating, cohortsData, SubjectsAddRes, isSubjectsAdding } = useSelector((state) => {
    return {
      crud: state.Subjects.data,
      isLoading: state.Subjects.loading,
      SubjectsUpdateRes: state.SubjectsUpdateRes.data,
      isSubjectsUpdating: state.SubjectsUpdateRes.loading,
      SubjectsAddRes: state.SubjectsAddRes.data,
      isSubjectsAdding: state.SubjectsAddRes.loading,
      cohortsData: state.cohorts.data,
    };
  });

  useEffect(() => {
    if (dispatch) {
      dispatch(subjectsDataRead());
      dispatch(cohortDataRead());
    }
  }, [dispatch]);


  useEffect(() => {
    if (SubjectsUpdateRes && SubjectsUpdateRes.Error === false) {
      dispatch(subjectsDataRead());
    }
  }, [SubjectsUpdateRes])

  useEffect(() => {
    if (SubjectsAddRes && SubjectsAddRes.Error === false) {
      dispatch(subjectsDataRead());
    }
  }, [SubjectsAddRes])

  const [dataSource, setDataSource] = useState([]);
  const [GoodData, setGoodData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (Array.isArray(crud)) {
      const newData = crud.sort((a, b) => a.updatedon < b.updatedon ? 1 : -1).map((item, index) => ({
        key: item.id,
        sno: index + 1,
        id: item.id,
        name: item.name,
        updatedon: new Date(item.updatedon).toLocaleDateString('en-GB'),
        updatedby: item.updatedby !== '' ? item.updatedby : '--------'
      }));
      setDataSource(newData);
      setGoodData(newData)
      setLoading(false);
    }
  }, [crud]);


  const checkRegulation = (i) => {
    let tarr = (i.regulation).split(",");
    let valueInArray = false;
    for (let j of tarr) {
      if (j.trim() === selectedRegulationId) {
        valueInArray = true;
        break;
      }
    }
    return valueInArray;
  }

  const checkBranch = (i) => {
    let tarr = (i.branch).split(",");
    let valueInArray = false;
    for (let j of tarr) {
      if (j.trim() === selectedBranchId) {
        valueInArray = true;
        break;
      }
    }
    return valueInArray;
  }

  const checkYear = (i) => {
    let tarr = (i.yearsemester).split(",");
    let valueInArray = false;
    for (let j of tarr) {
      if (j.trim() === selectedYearSemesterId) {
        valueInArray = true;
        break;
      }
    }
    return valueInArray;
  }

  const checkSearch = (i) => {
    for (let j in i) {
      if (i[j] != null && i[j].toString().toLowerCase().includes(searchText.toString().toLowerCase())) {
        return true;
      }
    }
    return false;
  }
  useEffect(() => {
    let tempValues = [...GoodData];
    if (selectedRegulationId != null) {
      tempValues = tempValues.filter(checkRegulation);
    }
    if (selectedBranchId != null) {
      tempValues = tempValues.filter(checkBranch);
    }
    if (selectedYearSemesterId != null) {
      tempValues = tempValues.filter(checkYear);
    }
    if (searchText != null) {
      tempValues = tempValues.filter(checkSearch);
    }
    setDataSource(tempValues);
  }, [selectedBranchId, selectedYearSemesterId, selectedRegulationId, searchText])

  const handleSearch = (e) => {
    setsearchText(e)
  }

  const columns = [
    {
      title: 'S.No.',
      dataIndex: 'sno',
      key: 'sno',
      sorter: (a, b) => a.sno > b.sno ? 1 : -1,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name > b.name ? 1 : -1,
    },
    {
      title: 'Updated On',
      dataIndex: 'updatedon',
      key: 'updatedon',
      sorter: (a, b) => a.updatedon > b.updatedon ? 1 : -1,
    },
    {
      title: 'Updated By',
      dataIndex: 'updatedby',
      key: 'updatedby',
      sorter: (a, b) => a.updatedby > b.updatedby ? 1 : -1,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <div className='table-actions'>
          <Button
            key="editSubject"
            onClick={() => handleEdit(record)}
            className="btn-icon "
            type="primary"
            to="#"
            shape="circle"
          >
            <UilEdit />
          </Button>
        </div>
      ),
    }
  ];

  if (clgName === 'ALL') {
    columns.splice(7, 0,
      {
        title: 'College',
        dataIndex: 'collegename',
        key: 'collegename',
      }
    );
  }

  const handleButtonClick = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleModalAdd = () => {
    form.validateFields().then((values) => {
      const newRow = {
        name: values.name,
      };
      if(crud.filter((sub) => sub.name == values.name)[0]) {
        message.error("The subject already exists!")
        return
      }
      form.resetFields();
      setIsModalVisible(false);
      dispatch(subjectsDataSubmit(newRow));
    })
      .catch((error) => {
        console.error('Error while validating form:', error);
      });
  };

  const handleModalUpdate = () => {
    editForm.validateFields().then((values) => {
      const updatedRecord = {
        id: editRecord.id,
        name: values.name,        
      };
      if(crud.filter((sub) => sub.name == values.name)[0]) {
        message.error("The subject already exists!")
        return
      }
      setIsEditModalVisible(false);
      dispatch(subjectsDataUpdate(updatedRecord));
    })
      .catch((error) => {
        console.error('Error while validating form:', error);
      });
  };

  const handleEdit = (record) => {
    setEditRecord(record);
    setIsEditModalVisible(true);        

    editForm.setFieldsValue({
      id: record.id,
      name: record.name,            
    });
  };

  return (
    <>
      <PageHeader className="ninjadash-page-header-main" title={`Subjects ${loggedadmin === 'superadmin' ? ClgName : ''}`}
        buttons={
          loggedadmin !== 'superadmin' ?
            [
              <Button id="subject-page-add_subject-button" onClick={handleButtonClick} key="AddSubject" type="primary" size="default">
                Add Subject
              </Button>,
            ] : []}
      />
      <Main>
        {isLoading || isSubjectsAdding || isSubjectsUpdating ?
          (
            <div className="spin">
              <CustomSpinner Message={'Loading please wait...'} />
            </div>
          ) : (
            <Row gutter={25}>
              <Col sm={24} xs={24}>
                <Cards headless>
                  <TableWrapper>
                    <ProjectList>
                      <div className='d-flex justify-content-between mb-15 p-0 align-center-v'>
                        <div className="d-flex align-center-v" style={{ marginLeft: 'auto' }}>
                          <AutoComplete id="subject-page-search-filter" onSearch={handleSearch} dataSource={dataSource} placeholder="Search here" patterns />
                        </div>
                      </div>
                      <div className="table-responsive">
                        <Table dataSource={dataSource} columns={columns} />
                      </div>
                    </ProjectList>
                  </TableWrapper>
                </Cards>
              </Col>
            </Row>
          )
        }
      </Main>
      <Modal
        title="Add Subject"
        open={isModalVisible}
        onCancel={handleModalCancel}
        maskClosable={false}
        centered
        footer={[
          <Button id="add-subject-button" key="AddSubjectRecord" type="primary" onClick={handleModalAdd}>
            Add
          </Button>,
        ]}
      >
        <Form id="add-subject-form" form={form} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter subject name" }]}>
            <Input id="add-subject-enter-subject-name" placeholder="Enter subject name" />
          </Form.Item>
          {/* <Form.Item name="cohortsID" label="Cohorts" rules={[{ required: true, message: "Please select cohorts" }]}>
            <Select mode='multiple' placeholder='Select cohort(s)'>
              {cohortsData.map((item)=>(
                <Option key={item.name} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>                */}
        </Form>
      </Modal>
      <Modal
        title="Edit Subject"
        open={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        maskClosable={false}
        centered
        footer={[
          <Button id="edit-subject-button" key="UpdateSubjectRecord" type="primary" onClick={handleModalUpdate}>
            Update
          </Button>
        ]}
      >
        <Form id="edit-subject-form" form={editForm} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter subject name" }]}>
            <Input id="edit-subject-enter-subject-name" placeholder="Enter subject name" />
          </Form.Item>          
        </Form>
      </Modal>
    </>
  );
}

export default Subjects;