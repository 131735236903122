import React, { useEffect } from 'react'
import { Main } from '../styled';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Card, Col, Result, Row } from 'antd';
import { CourseCardWrap } from '../../components/cards/Style'
import { teacherSubjectsDataRead } from '../../redux/teacherSubjectsUnits/actionCreator';
import { Link, useNavigate } from 'react-router-dom';
import randomImage from './randomImage'
import { useSelector, useDispatch } from 'react-redux';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';

export default function Subjects() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { TeacherSubjects, isSubjectsLoading } = useSelector((state) => {
        return {
            TeacherSubjects: state.TeacherSubjects.data,
            isSubjectsLoading: state.TeacherSubjects.loading,
        };
    });

    useEffect(() => {
        if (dispatch) {
            dispatch(teacherSubjectsDataRead());
        }
    }, [dispatch])

    return (
        <div>
            <Main>
                <PageHeader className="ninjadash-page-header-main" title="Subjects" />
                {
                    isSubjectsLoading ?
                        <div className='spin'>
                            <CustomSpinner Message={'Loading Subjects...'} />
                        </div>
                        :
                        TeacherSubjects.length === 0 ?
                            <div className='text-center'>
                                <Result
                                    status={'info'}
                                    title="No subjects assigned yet!"
                                />
                            </div>
                            :
                            <Row gutter={25}>
                                {
                                    TeacherSubjects.map((sub, index) =>
                                        <Col key={index} xs={24} sm={12} md={8} lg={6}>
                                            <CourseCardWrap className="ninjadash-course-card-single">
                                                <Link to={`${sub.subjectname}/${sub.subjectId}`}>
                                                    <Card bordered={false}>
                                                        <div className="ninjadash-course-card-thumbnail">
                                                            <img src={require('../../' + randomImage(index))} alt="IMAGE" />
                                                        </div>
                                                        <div className="ninjadash-course-card-content">
                                                            <h4 className="ninjadash-course-card-title">
                                                                {sub.subjectname}
                                                            </h4>
                                                            <div className="mt-5">
                                                                <ul className="d-flex align-center-v">
                                                                    <span className='fontsize-12'>Batches: &nbsp;</span>
                                                                    {
                                                                        sub.cohorts?.map((cohort, index) =>
                                                                            <li key={index} className="fontsize-12">
                                                                                <span>{cohort.name}{index !== sub.cohorts.length - 1 && ','}&nbsp;</span>
                                                                            </li>
                                                                        )
                                                                    }
                                                                </ul>
                                                            </div>
                                                            <div className="mt-10">
                                                                <ul className="ninjadash-course-card-meta__right">
                                                                    <li className="bg-secondary">
                                                                        <span className='number'>{sub.topicscount}</span>
                                                                        <span>Topics</span>
                                                                    </li>
                                                                    <li className="bg-secondary">
                                                                        <span className='number'>{sub.questionscount}</span>
                                                                        <span>Questions</span>
                                                                    </li>
                                                                    {/* <li className="bg-secondary">
                                                            <span className='number'>{sub.percentageCompleted}</span>
                                                            <span>Completed</span>
                                                        </li> */}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </Link>
                                            </CourseCardWrap>
                                        </Col>
                                    )
                                }
                            </Row>
                }
            </Main>
        </div>
    )
}
