import React, { useEffect, useState } from 'react';
import { Modal, Input, Select, Table, message, Collapse, Checkbox } from 'antd';
import { Button } from '../../components/buttons/buttons';
// import 'antd/dist/antd.css';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import { DataTableStyleWrap } from '../../components/table/Style';
import { useDispatch, useSelector } from 'react-redux';
import { getAnswersData } from '../../redux/answers/actionCreator';
import { readAnswersSuccess } from '../../redux/answers/actions';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';
import UilMinus from '@iconscout/react-unicons/icons/uil-minus';

import { FaqWrapper } from './styles/tablestyle';
import UilSearch from '@iconscout/react-unicons/icons/uil-search';
import { DownloadOutlined } from '@ant-design/icons';
import jsPDF from 'jspdf';

const { Panel } = Collapse;

function ViewAnswers() {

  const dispatch = useDispatch()
  const [subjectId, setSubjectId] = useState(0)
  const [unitId, setUnitId] = useState(0);
  const [topicId, setTopicId] = useState(0);
  const [selectedStudentQA, setSelectedStudentQA] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [units, setUnits] = useState([]);
  const [topics, setTopics] = useState([]);

  const [selectedRollNo, setSelectedRollNo] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [selectedSection, setSelectedSection] = useState('');

  const { Answers, AnswersLoading, SubjectsUnits, SubjectsUnitsLoading } = useSelector((state) => {
    return {
      Answers: state.Answers.data,
      AnswersLoading: state.Answers.loading,
      SubjectsUnits: state.SubjectsUnits.data,
      SubjectsUnitsLoading: state.SubjectsUnits.loading,
    }
  })
  const sectionsSet = new Set();

  Answers.forEach(answer => {
    sectionsSet.add(answer.section);
  });
  const sections = [...sectionsSet]

  useEffect(() => {
    dispatch(readAnswersSuccess([]))
  }, [])

  const columns = [
    {
      title: 'S.No.',
      dataIndex: 'sno',
    },
    {
      title: 'Roll No',
      dataIndex: 'studentUsername',
      sorter: (a, b) => a.studentUsername > b.studentUsername ? 1 : -1,
      sortDirections: ['ascend'],

    },
    {
      title: 'Name',
      dataIndex: 'studentName',
      sorter: (a, b) => a.studentName?.length - b.studentName?.length,
      sortDirections: ['ascend'],

    },
    {
      title: 'Section',
      dataIndex: 'sectionName',
      filters: sections.map(section => ({ text: section, value: section })),
      onFilter: (value, record) => record.sectionName === value,
    },
    {
      title: '',
      render: (text, record) => (
        <Button
          type='primary'
          onClick={() => {
            const selectedAnswer = Answers.find(answer => answer.id == record.id);
            setSelectedStudentQA(selectedAnswer !== null && selectedAnswer.qaAttempts);
            setIsModalVisible(true);
            setSelectedRollNo(record.studentUsername);
            setSelectedName(record.studentName);
            setSelectedSection(record.sectionName);

          }}
        >
          Show
        </Button>
      )
    },
    {
      title: '',
      render: (_, record) => (
        <DownloadOutlined
          style={{ cursor: 'pointer' }}
          onClick={() => generatePDF(record)}
        />
      ),
    },
  ];

  const generatePDF = (rowData) => {
    const doc = new jsPDF();
    doc.setFont('Arial');
    let content = `\n\nRoll No: ${rowData.studentUsername}\nName: ${rowData.studentName}\nSection: ${rowData.sectionName}\n\n`;
    rowData.qaAttempts.forEach((qa, index) => {
      content += `Question ${index + 1}:\n${qa.qaMaster.question}\n\n`;
      content += `Correct Answer:\n${qa.correctAnswer}\n\n`;
      content += `Student Answer:\n${qa.userAnswer || 'N/A'}\n\n`;
      content += `Score Awarded:${qa.score || '0'}\n`;
      content += `Submitted on:${new Date(qa.updatedon).toLocaleString()}\n\n\n`;

    });
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10;
    let yPosition = margin;
    const lineHeight = 10;
    let lines = doc.splitTextToSize(content, pageWidth - 2 * margin);

    lines.forEach((line) => {
      if (yPosition + lineHeight > doc.internal.pageSize.getHeight() - margin) {
        doc.addPage();
        yPosition = margin;
      }
      doc.text(line, margin, yPosition);
      yPosition += lineHeight;
    });

    doc.save(`${rowData.studentUsername}_answers.pdf`);
  };
  //Generates pdf of a specific question

  const generateAnswerPDF = (ans) => {
    const doc = new jsPDF();
    doc.setFont('Arial');
    let ansContent = `\n\nRoll No: ${selectedRollNo}\nName: ${selectedName}\nSection: ${selectedSection}\nScore: ${ans.score} out of 10\n\nQuestion:\n${ans.qaMaster.question}\n\nCorrect Answer:\n${ans.correctAnswer}\n\nStudent Answer:\n${ans.userAnswer === null ? 'N/A' : ans.userAnswer}`;
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10;
    let yPosition = margin;
    const lineHeight = 10;
    let lines = doc.splitTextToSize(ansContent, pageWidth - 2 * margin);
    lines.forEach((line) => {
      if (yPosition + lineHeight > doc.internal.pageSize.getHeight() - margin) {
        doc.addPage();
        yPosition = margin;
      }
      doc.text(line, margin, yPosition);
      yPosition += lineHeight;
    });
    doc.save(`${selectedRollNo}` + '.pdf');
  }


  const [tableData, setTableData] = useState([])
  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    let data = []
    Answers.length !== 0 && Answers.map((obj, index) => {
      const { id, username, name, qaAttempts, section } = obj
      data.push({
        key: index,
        "sno": index + 1,
        id,
        studentUsername: username,
        studentName: name,
        sectionName: section,
        qaAttempts
      })
    })
    setTableData(data)
    setFilteredData(data)
  }, [Answers])



  useEffect(() => {
    if (subjectId !== 0) {
      const selectedSubject = SubjectsUnits.subjects.find(subject => subject.id === subjectId);
      setUnits(selectedSubject.units);
    }
    else {
      setUnits([]);
    }
  }, [subjectId, SubjectsUnits?.subjects]);

  useEffect(() => {
    if (unitId !== 0) {
      const selectedUnit = units.find(unit => unit.id === unitId);
      setTopics(selectedUnit?.topics);
    } else {
      setTopics([]);
    }
  }, [unitId, SubjectsUnits?.subjects?.units]);

  const loadData = () => {
    if (subjectId !== 0 && unitId !== 0 && topicId !== 0) {
      dispatch(getAnswersData(subjectId, unitId, topicId));
    } else {
      message.warn("Select all filters!!");
    }
  };

  const searchkeys = ['studentUsername', 'studentName']
  const searchStudent = (e) => {
    const { value } = e.currentTarget;
    const searchData = tableData.length !== 0 && tableData.filter((obj) => searchkeys.some((key) => obj[key].toLowerCase().includes(value.toLowerCase())))
    setFilteredData(searchData)
  };

  const getTopicName = (topics,topicId) => {
    const topicInfo = topics.filter((topic) => topic.id == topicId)    
    return topicInfo.length !== 0 && topicInfo[0].name
  }

  const [selectedQuestionIds, setSelectedQuestionIds] = useState([]);
  const [isCompareModalVisible, setIsCompareModalVisible] = useState(false);

  const handleCheckboxChange = (qaId, id) => {
    const updatedQuestionIds = [...selectedQuestionIds];
    updatedQuestionIds.push({ qaMasterId: qaId, aid: id });
    setSelectedQuestionIds(updatedQuestionIds);
    // console.log(updatedQuestionIds);
  };
  const modalColumns = [
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: 'User Answer',
      dataIndex: 'userAnswer',
      key: 'userAnswer',
    },
  ];


  const handleCompareClick = () => {
    if (selectedQuestionIds.length >= 2 && selectedQuestionIds.length <= 4) {
      const uniqueIds = new Set(selectedQuestionIds.map(({ qaMasterId }) => qaMasterId));

      if (uniqueIds.size === 1) {
        const selectedQuestions = selectedStudentQA.filter((qa) =>
          selectedQuestionIds.some(({ qaMasterId, aid }) => qa.qaMaster.id === qaMasterId && qa.id === aid)
        );

        setIsCompareModalVisible(true);

        console.log(selectedQuestions)

        Modal.info({
          title: 'Selected Questions and Answers',
          content: (
            <div>
              <Table
                dataSource={selectedQuestions.map((qa) => ({
                  key: qa.qaMaster.id,
                  question: qa.qaMaster.question,
                  userAnswer: qa.userAnswer || 'N/A',
                }))}
                columns={modalColumns}
                pagination={false}
              />
            </div>
          ),
          visible: isCompareModalVisible,
          onOk() {
            setIsCompareModalVisible(false);
          },
          onCancel() {
            setIsCompareModalVisible(false);
          },
          width: 800,
        });
      } else {
        message.warn('Please select same questions to compare.');
      }
    } else {
      message.warn('Please select at least 2 and atmost 4 questions to compare.');
    }
  };

  return (
    <div>
      <Main className='pt-20'>
        <Cards title="Q & A Submissions">
          <DataTableStyleWrap>
            <div className="ninjadash-datatable-filter">
              <div className="ninjadash-datatable-filter__left">

                <div className="ninjadash-datatable-filter__input">
                  <span className="label">Subject:</span>
                  <Select style={{ width: 400 }} className="ninjadash-data-status" placeholder="Select Subject" onChange={(e) => { setSubjectId(e) }} loading={SubjectsUnitsLoading}>
                    {
                      SubjectsUnits !== null && SubjectsUnits.subjects.length !== 0 && SubjectsUnits.subjects.sort((a, b) => a.name > b.name ? 1 : -1).map((subjectObj, subjectIndex) =>
                        <Select.Option value={subjectObj.id} key={subjectIndex}>{subjectObj.name}</Select.Option>
                      )
                    }
                  </Select>
                </div>
                <div className="ninjadash-datatable-filter__input">
                  <span className="label">Unit:</span>
                  <Select style={{ width: 200 }} className="ninjadash-data-status" placeholder="Select Unit" onChange={(e) => { setUnitId(e) }} loading={SubjectsUnitsLoading}>
                    <Select.Option value={0} key={'selectUnit'} >Select</Select.Option>
                    {units.map((unit) => (
                      <Select.Option key={unit.id} value={unit.id}>
                        {unit.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div className="ninjadash-datatable-filter__input">
                  <span className="label">Topic:</span>
                  <Select style={{ width: 400 }} className="ninjadash-data-status" placeholder="Select Topic" onChange={(e) => { setTopicId(e) }} loading={SubjectsUnitsLoading}>
                    <Select.Option value={0} key={'selectTopic'} >Select</Select.Option>
                    {topics?.map((topic) => (
                      <Select.Option key={topic.id} value={topic.id}>
                        {topic.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div className="ninjadash-datatable-filter__action">
                  <Button type="primary" size="small" onClick={() => { loadData() }}>
                    Get
                  </Button>
                </div>
                {/* <div className="ninjadash-datatable-filter__action">
                  <Button
                    type="primary"
                    size="small"
                    onClick={handleCompareClick}
                    style={{ margin: 10 }}
                  >
                    Compare
                  </Button>
                </div> */}
              </div>
            </div>
            <div className="mb-20" style={{ maxWidth: '350px', marginLeft: 'auto' }}>
              <Input className='' onChange={searchStudent} size="default" placeholder="Search by Rollno. or Name" prefix={<UilSearch />} />
            </div>
            {
              AnswersLoading ?
                <div style={{ width: '100%', height: '400px' }}>
                  <div className="custom-spin">
                    <CustomSpinner />
                  </div>
                </div>
                :
                <div>
                  <Table
                    className='table-responsive'
                    pagination={{
                      defaultPageSize: 50,
                      total: filteredData.length,
                      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    }}
                    columns={columns}
                    dataSource={filteredData}
                    tableLayout='auto'
                    size='middle'
                  />
                </div>
            }
          </DataTableStyleWrap>
        </Cards>
        <Modal
          title="Student Answers"
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
          width={1200}
        >
          <div className='mb-20 d-flex'>
            <p className='m-0 mr-10 fontsize-16'>Topic: </p><h3 className='m-0'>{topics.length !==0 ? getTopicName(topics,topicId) : ''}</h3>
          </div>
          {selectedStudentQA.length !== 0 && selectedStudentQA.sort((a, b) => a.updatedon < b.updatedon ? 1 : -1).map((qa, index) => (
            <div key={index}>
              <FaqWrapper>
                <Collapse defaultActiveKey={['0']} bordered={false} expandIcon={({ isActive }) => (isActive ? <UilMinus /> : <UilPlus />)} className='m-0 mb-5' >
                  <Panel header={
                    <div className='d-flex justify-content-between'>
                      <span>{qa.qaMaster.question}</span>
                      <div>
                        <span className='mr-10'>Score : {qa.score} of 10</span>
                        <span className='text-right fontsize-13 m-0'>| Submitted on : {new Date(qa.updatedon).toLocaleString()}</span>
                      </div>
                    </div>
                  } key={index}>
                    <div className='d-flex justify-content-between' style={{ marginBottom: 20 }}>
                      <Button type='primary'
                        style={{ cursor: 'pointer' }}
                        onClick={() => generateAnswerPDF(qa)}
                      >
                        Download
                      </Button>
                      <p className='text-right fontsize-13 m-0'>Submitted on : {new Date(qa.updatedon).toLocaleString()}</p>
                    </div>
                    <h4>Correct Answer</h4>
                    {/* <Checkbox onChange={() => handleCheckboxChange(qa.qaMaster.id, qa.id)} /> */}
                    <p style={{ backgroundColor: '#fdf2ef' }}>{qa.correctAnswer}</p>
                    <h4>Student Answer</h4>
                    <p style={{ textAlign: 'justify' }} className='m-0'>
                      {qa.userAnswer === null ? 'N/A' : qa.userAnswer}
                    </p>
                  </Panel>
                </Collapse>
              </FaqWrapper>
            </div>
          ))}
        </Modal>
      </Main>
    </div>
  );
}

export default ViewAnswers;
