import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Dashboard from '../../container/teacher/Dashboard';
import withTeacherLayout from '../../layout/withTeacherLayout';
import CustomSpinner from '../../container/ui-elements/spinner/CustomSpinner';
import Monitor from '../../container/teacher/Monitor';
import ChangePassword from '../../container/ChangePassword';
import Profile from '../../container/teacher/Profile';
import Answers from '../../container/teacher/Answers';
import AssessmentLogins from '../../container/teacher/AssessmentLogins';
import Subjects from '../../container/teacher/Subjects';
import TopicsList from '../../container/student/TopicsList';
import Topics from '../../container/teacher/Topics';
import Schedules from '../../container/teacher/Schedules';
import InterviewReports from '../../container/teacher/InterviewReports';
const NotFound = lazy(() => import('../../container/pages/404'));

const Teacher = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="spin">
          <CustomSpinner />
        </div>
      }
    >
      <Routes>
        <Route index path="/" element={<Subjects />} />   
        {/* <Route index path="/change-password" element={<ChangePassword />} />    */}
        <Route path="/teacher-profile/*" element={<Profile/>} />   
        {/* <Route path="/subjects" element={<Subjects />} />                                       */}
        <Route path="/schedules" element={<Schedules />} />                                      
        <Route path="/Interview_reports" element={<InterviewReports />} />                                      
        <Route path="/:subjectName/:subjectId/*" element={<Topics />} />        
        <Route path="/monitor-students/*" element={<Monitor />} />     
        <Route path="/answers" element={<Answers/>}/>
        <Route path="/assessment" element={<AssessmentLogins/>}/>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default withTeacherLayout(Teacher);
