import React, { useEffect, useState } from 'react'
import { Main } from '../styled';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Card, Col, Row } from 'antd';
import { CourseCardWrap } from '../../components/cards/Style'
import UilBook from '@iconscout/react-unicons/icons/uil-book-alt';
import UilClock from '@iconscout/react-unicons/icons/uil-clock';
import { Link } from 'react-router-dom';
import randomImage from './randomImage'
import { useDispatch, useSelector } from 'react-redux';
import { studentSubjectsDataRead } from '../../redux/studentSubjectsUnits/actionCreator';

export default function Subjects() {

    const dispatch = useDispatch();
    useEffect(() => {
        if (dispatch) {
            dispatch(studentSubjectsDataRead());
        }
    }, [dispatch]);

    const { StudentSubjects, isSubjectsLoading } = useSelector((state) => {
        return {
            StudentSubjects: state.StudentSubjects.data,
            isSubjectsLoading: state.StudentSubjects.loading,
        };
    });

    const [filteredData, setFilteredData] = useState([])
    useEffect(() => {
        if (StudentSubjects) {
            let data = []

            StudentSubjects.map((subject) =>
                data.push({
                    id: subject.subjectId,
                    name: subject.subjectname,
                    cohorts: subject.cohortname,
                    topicscount: subject.topicscount,
                    questionscount: subject.questionscount
                })
            )

            setFilteredData(data)
        }
    }, [StudentSubjects])

    return (
        <div>
            <Main>
                <PageHeader className="ninjadash-page-header-main" title="Subjects" />
                <Row gutter={25}>
                    {
                        filteredData.length !== 0 && filteredData.map((sub, index) =>
                            <Col key={index} xs={24} sm={12} md={8} lg={6}>
                                <CourseCardWrap className="ninjadash-course-card-single">
                                    <Link to={`${sub.id}/${sub.name}`}>
                                        <Card bordered={false}>
                                            <div className="ninjadash-course-card-thumbnail">
                                                <img src={require('../../' + randomImage(index))} alt="IMAGE" />
                                            </div>
                                            <div className="ninjadash-course-card-content">
                                                <h4 className="ninjadash-course-card-title">
                                                    {/* <Link to={`#`}>{sub.name}</Link> */}
                                                    {sub.name}
                                                </h4>
                                                <div className="ninjadash-course-card-meta">
                                                    {/* <div className="ninjadash-course-card-meta__left">
                                                    <span className="ninjadash-course-card-meta__pricing">Interviews {sub.interviewsCount}</span>
                                                </div> */}
                                                </div>
                                                <div className="mt-5">
                                                    <ul className="ninjadash-course-card-meta__right">
                                                        {
                                                            sub.cohorts?.map((cohort, index) =>
                                                                <li key={index} className="bg-secondary">
                                                                    <span>{cohort}</span>
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                                <div className="mt-10">
                                                    <ul className="ninjadash-course-card-meta__right">
                                                        <li className="bg-secondary">
                                                            <span className='number'>{sub.topicscount}</span>
                                                            <span>Topics</span>
                                                        </li>
                                                        <li className="bg-secondary">
                                                            <span className='number'>{sub.questionscount}</span>
                                                            <span>Questions</span>
                                                        </li>
                                                        {/* <li className="bg-secondary">
                                                            <span className='number'>{sub.percentageCompleted}</span>
                                                            <span>Completed</span>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Card>
                                    </Link>
                                </CourseCardWrap>
                            </Col>
                        )
                    }
                </Row>
            </Main>
        </div>
    )
}
