const actions = {
  NOTICE_ADD_BEGIN: 'NOTICE_ADD_BEGIN',
  NOTICE_ADD_SUCCESS: 'NOTICE_ADD_SUCCESS',
  NOTICE_ADD_ERR: 'NOTICE_ADD_ERR',

  NOTICECOLLEGE_ADD_BEGIN: 'NOTICECOLLEGE_ADD_BEGIN',
  NOTICECOLLEGE_ADD_SUCCESS: 'NOTICECOLLEGE_ADD_SUCCESS',
  NOTICECOLLEGE_ADD_ERR: 'NOTICECOLLEGE_ADD_ERR',

  NOTICE_READ_BEGIN: 'NOTICE_READ_BEGIN',
  NOTICE_READ_SUCCESS: 'NOTICE_READ_SUCCESS',
  NOTICE_READ_ERR: 'NOTICE_READ_ERR',

  NOTICECOLLEGE_READ_BEGIN: 'NOTICECOLLEGE_READ_BEGIN',
  NOTICECOLLEGE_READ_SUCCESS: 'NOTICECOLLEGE_READ_SUCCESS',
  NOTICECOLLEGE_READ_ERR: 'NOTICECOLLEGE_READ_ERR',

  NOTICE_UPDATE_BEGIN: 'NOTICE_UPDATE_BEGIN',
  NOTICE_UPDATE_SUCCESS: 'NOTICE_UPDATE_SUCCESS',
  NOTICE_UPDATE_ERR: 'NOTICE_UPDATE_ERR',

  noticeAddBegin: () => {
    return {
      type: actions.NOTICE_ADD_BEGIN,
    };
  },

  noticeAddSuccess: (data) => {
    return {
      type: actions.NOTICE_ADD_SUCCESS,
      data,
    };
  },

  noticeAddErr: (err) => {
    return {
      type: actions.NOTICE_ADD_ERR,
      err,
    };
  },

  noticecollegeAddBegin: () => {
    return {
      type: actions.NOTICECOLLEGE_ADD_BEGIN,
    };
  },

  noticecollegeAddSuccess: (data) => {
    return {
      type: actions.NOTICECOLLEGE_ADD_SUCCESS,
      data,
    };
  },

  noticecollegeAddErr: (err) => {
    return {
      type: actions.NOTICECOLLEGE_ADD_ERR,
      err,
    };
  },

  noticeReadBegin: () => {
    return {
      type: actions.NOTICE_READ_BEGIN,
    };
  },

  noticeReadSuccess: (data) => {
    return {
      type: actions.NOTICE_READ_SUCCESS,
      data,
    };
  },

  noticeReadErr: (err) => {
    return {
      type: actions.NOTICE_READ_ERR,
      err,
    };
  },

  noticeCollegeReadBegin: () => {
    return {
      type: actions.NOTICECOLLEGE_READ_BEGIN,
    };
  },

  noticeCollegeReadSuccess: (data) => {
    return {
      type: actions.NOTICECOLLEGE_READ_SUCCESS,
      data,
    };
  },

  noticeCollegeReadErr: (err) => {
    return {
      type: actions.NOTICECOLLEGE_READ_ERR,
      err,
    };
  },

  noticeUpdateBegin: () => {
    return {
      type: actions.NOTICE_UPDATE_BEGIN,
    };
  },

  noticeUpdateSuccess: (data) => {
    return {
      type: actions.NOTICE_UPDATE_SUCCESS,
      data,
    };
  },

  noticeUpdateErr: (err) => {
    return {
      type: actions.NOTICE_UPDATE_ERR,
      err,
    };
  },

};

export default actions;
