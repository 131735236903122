import React, { useEffect, useState } from 'react';
import { Form, Modal, Button, message, Table } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { UilExport } from '@iconscout/react-unicons';
import { DownloadOutlined } from '@ant-design/icons';
import Papa from 'papaparse';
import { NoteCardWrap } from '../note/style';
import { useParams } from 'react-router-dom';
import { ImportQuestionsOfTopic } from '../../redux/teacherSubjectsUnits/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { Cards } from '../../components/cards/frame/cards-frame';
import { GetQuestionsOfTopicDataRead } from '../../redux/teacherSubjectsUnits/actionCreator';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';

function ImportQuestions({ backButton }) {

    const { topicId } = useParams()
    const dispatch = useDispatch();

    const [ImportForm] = Form.useForm();
    const [csvFile, setCSVFile] = useState(null);
    const [csvFileList, setCSVFileList] = useState([]);
    const [previewtabledata, setpreviewtabledata] = useState([]);
    const [PreviewModal, setPreviewModal] = useState(false);

    const { ImportQuestionsData, isImportQuestionsLoading } = useSelector((state) => {
        return {
            ImportQuestionsData: state.ImportQuestions.data,
            isImportQuestionsLoading: state.ImportQuestions.loading,
        };
    });

    useEffect(() => {
        if (ImportQuestionsData !== null && ImportQuestionsData.Error === false) {
            dispatch(GetQuestionsOfTopicDataRead(topicId));
        }
    }, [ImportQuestionsData])

    const handleUploadQuestionsSubmit = () => {
        dispatch(ImportQuestionsOfTopic(previewtabledata))
        setPreviewModal(false);
        setpreviewtabledata([]);
    }

    const CSVColumns = [
        {
            title: 'Question',
            key: 'question',
            dataIndex: 'question',
        },
        {
            title: 'Type',
            key: 'questionType',
            dataIndex: 'questionType',
        },
        {
            title: 'Expected Conceptual Knowledge',
            key: 'expectedConceptualKnowledge',
            dataIndex: 'expectedConceptualKnowledge',
        }
    ];

    const sampleCSVData = [
        {
            question: "Features of java 8",
            type: 'VIVA',
            expectedConceptualKnowledge: 'Functional Interfaces, Lambda expressions, Optional class, Stream API, Method reference, Default methods'
        },
        {
            question: "Differences between method overloading and method overriding?",
            type: 'THEORY',
            expectedConceptualKnowledge: ''
        }
    ];

    const handlePreviewQuestionsImport = () => {
        ImportForm.validateFields().then((values) => {
            Papa.parse(csvFile, {
                complete: (result) => {
                    const invalidHeaders = result.meta.fields.filter(item => item !== "question" && item !== "type" && item !== "expectedConceptualKnowledge");
                    if (invalidHeaders.length > 0) {
                        message.error("Invalid columns found", 3)
                        return;
                    }
                    // const EmptyCells = result.data.filter((row) => {
                    //     return Object.values(row).every((cell) => cell.trim() !== '');
                    // });

                    // if (EmptyCells.length !== result.data.length) {
                    //     message.error("Empty values found in the uploaded CSV file. Please make sure all cells are filled.", 3);
                    //     return;
                    // }
                    const filteredData = result.data.filter((row) => Object.values(row).some((cell) => cell.trim() !== ''));
                    const filteredCSVData = filteredData.map((row) => (
                        row !== null &&
                        {
                            question: row.question,
                            questionType: row.type,
                            expectedConceptualKnowledge: row.expectedConceptualKnowledge,
                            tags: [],
                            topicId: parseInt(topicId),
                        }
                    ));
                    setpreviewtabledata(filteredCSVData)
                    ImportForm.resetFields();
                    setCSVFileList([]);
                    setCSVFile(null);
                    setPreviewModal(true);
                },
                header: true,
            });
        })
            .catch((error) => {
                console.error('Error while validating form:', error);
            });
    }


    return (
        <>
            {isImportQuestionsLoading ?
                <NoteCardWrap>
                    <div className='custom-spin'>
                        <CustomSpinner Message={'Loading... Please wait.'} marginTop={40} />
                    </div>
                </NoteCardWrap>
                :
                <>
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <p style={{ fontSize: '17px', color: 'black', marginRight: '5px', marginBottom: '0' }}>Sample CSV File:</p>
                            <span className="download-text" style={{ marginLeft: '10px', marginRight: '5px', marginBottom: '0', fontSize: '17px' }}>samplequestions.csv</span>
                            <DownloadOutlined id="download-sample-csv-file"
                                style={{ verticalAlign: 'middle' }}
                                className="download-icon"
                                onClick={() => {
                                    const csvContent = Papa.unparse(sampleCSVData);
                                    const blob = new Blob([csvContent], { type: "text/csv" });
                                    const link = document.createElement("a");
                                    link.href = URL.createObjectURL(blob);
                                    link.download = "samplequestions.csv";
                                    link.click();
                                }}
                            />
                        </div>
                        <Form id="import-question-form" form={ImportForm} layout="vertical">
                            <Form.Item
                                name="csvFile"
                                valuePropName="fileList"
                                getValueFromEvent={(e) => {
                                    const fileList = e && e.fileList.slice(-1);
                                    setCSVFileList(fileList);
                                    return fileList;
                                }}
                                rules={[
                                    { required: true, message: 'Please upload a CSV file!' },
                                    ({ fileList }) => ({
                                        validator(_, value) {
                                            const file = fileList && fileList[0];
                                            if (file) {
                                                const fileName = file.name.toLowerCase();
                                                if (fileName.endsWith('.csv')) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject('Please upload a CSV file!');
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Dragger
                                    accept=".csv"
                                    beforeUpload={(file) => {
                                        setCSVFile(file);
                                        return false;
                                    }}
                                    fileList={csvFileList}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <UilExport />
                                    </p>
                                    <p className="ant-upload-text">Drag or upload .csv file!  </p>
                                </Dragger>
                            </Form.Item>
                        </Form>
                        <div className='d-flex align-center-v justify-content-end'>
                            {backButton}
                            <Button type='primary' onClick={handlePreviewQuestionsImport}>Preview</Button>
                        </div>
                    </div>
                    <Modal
                        title="Preview Questions"
                        open={PreviewModal}
                        onCancel={() => { setPreviewModal(false); setpreviewtabledata([]) }}
                        maskClosable={false}
                        centered
                        footer={[
                            <Button id="preview--question-submit-button" key="previewQuestionButton" type="primary" onClick={handleUploadQuestionsSubmit}>
                                Submit
                            </Button>,
                        ]}
                        width={1000}
                    >
                        <Table dataSource={previewtabledata} columns={CSVColumns} pagination={true} />
                    </Modal>
                </>
            }
        </>
    );
}
export default ImportQuestions;