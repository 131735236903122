import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { message } from 'antd';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';

const {
  tessellatorTopicsReadBegin,
  tessellatorTopicsReadSuccess,
  tessellatorTopicsReadErr,

  tessellatorTopicProgramsReadBegin,
  tessellatorTopicProgramsReadSuccess,
  tessellatorTopicProgramsReadErr,

  mapTessellatorTopicUpdateBegin,
  mapTessellatorTopicUpdateSuccess,
  mapTessellatorTopicUpdateErr,

  mapTessellatorTopicToNullUpdateBegin,
  mapTessellatorTopicToNullUpdateSuccess,
  mapTessellatorTopicToNullUpdateErr,

} = actions;

const TessellatorTopicsDataRead = (subjectId) => {
  return async (dispatch) => {
    try {
      await dispatch(tessellatorTopicsReadBegin());
      const query = await DataService.get(`/subjectmaster/topicstscp/${subjectId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(tessellatorTopicsReadSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(tessellatorTopicsReadSuccess([]));
          dispatch(logOut())
        }
        else {
          await dispatch(tessellatorTopicsReadSuccess([]));
        }
      }
      else {
        await dispatch(tessellatorTopicsReadSuccess([]));
        // await dispatch(tessellatorTopicsAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(tessellatorTopicsReadErr(err));
    }
  };
};

const TessellatorTopicProgramsDataRead = (topicId) => {
  return async (dispatch) => {
    try {
      await dispatch(tessellatorTopicProgramsReadBegin());
      const query = await DataService.get(`/tssrprograms/${topicId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(tessellatorTopicProgramsReadSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(tessellatorTopicProgramsReadSuccess([]));
          dispatch(logOut())
        }
        else {
          await dispatch(tessellatorTopicProgramsReadSuccess([]));
        }
      }
      else {
        await dispatch(tessellatorTopicProgramsReadSuccess([]));
      }
    } catch (err) {
      await dispatch(tessellatorTopicProgramsReadErr(err));
    }
  };
};

// update
const MapTessellatorTopic = (topicId, testTopicId) => {
  return async (dispatch) => {
    try {
      await dispatch(mapTessellatorTopicUpdateBegin());
      const query = await DataService.put(`/topicmaster/tesstopicupdate/${topicId}/${testTopicId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(mapTessellatorTopicUpdateSuccess(data));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(mapTessellatorTopicUpdateSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(mapTessellatorTopicUpdateSuccess(null));
        }
      }
      else {
        // unauthorize
        await dispatch(mapTessellatorTopicUpdateSuccess(null));
      }
    } catch (err) {
      await dispatch(mapTessellatorTopicUpdateErr(err));
    }
  };
};

const MapTessellatorTopicToNull = (topicId) => {
  return async (dispatch) => {
    try {
      await dispatch(mapTessellatorTopicToNullUpdateBegin());
      const query = await DataService.put(`/topicmaster/tesstopicupdatetonull/${topicId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(mapTessellatorTopicToNullUpdateSuccess(data));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(mapTessellatorTopicToNullUpdateSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(mapTessellatorTopicToNullUpdateSuccess(null));
        }
      }
      else {
        // unauthorize
        await dispatch(mapTessellatorTopicToNullUpdateSuccess(null));
      }
    } catch (err) {
      await dispatch(mapTessellatorTopicToNullUpdateErr(err));
    }
  };
};

export { TessellatorTopicsDataRead, TessellatorTopicProgramsDataRead, MapTessellatorTopic, MapTessellatorTopicToNull };