import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Input, Modal, Form, Select, message } from 'antd';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import { ProjectList, ProjectSorting } from '../project/style';
import { Cards } from '../../components/cards/frame/cards-frame';
import { TableWrapper, Main } from '../styled';
import { AutoComplete } from '../../components/autoComplete/autoComplete';
import { Button } from '../../components/buttons/buttons';
import { useDispatch, useSelector } from 'react-redux';
import { studentsDataRead } from '../../redux/students/actionCreator';
import { alertModal } from '../../components/modals/antd-modals';
import { userspwdDataUpdate } from '../../redux/UsersPasswordChange/actionCreator';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';


function Students() {
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [editForm] = Form.useForm();
    const [editRecord, setEditRecord] = useState(null);

    const dispatch = useDispatch();
    const { crud, isLoading, UsersPwdChange, isUsersPwdChanging } = useSelector((state) => {
        return {
            crud: state.Students.data,
            isLoading: state.Students.loading,
            UsersPwdChange: state.UsersPwdChange.data,
            isUsersPwdChanging: state.UsersPwdChange.loading,
        };
    });

    useEffect(() => {
        dispatch(studentsDataRead());
    }, [dispatch]);

    useEffect(() => {
        if (UsersPwdChange !== null) {
            dispatch(studentsDataRead());
        }
    }, [UsersPwdChange])

    const [dataSource, setDataSource] = useState([]);
    const [GoodData, setGoodData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchText, setsearchText] = useState(null);

    useEffect(() => {
        if (Array.isArray(crud)) {
            const filteredData = crud.map((item, index) => ({
                key: item.id,
                sno: index + 1,
                id: item.id,
                name: item.name,
                username: item.usermaster?.username,
                collegeid: item.college.id,
                collegename: item.college.code,
            }));
            setDataSource(filteredData);
            setGoodData(filteredData);
            setLoading(false);
        }
    }, [crud])

    const checkSearch = (i) => {
        for (let j in i) {
            if (i[j] != null && i[j].toString().toLowerCase().includes(searchText.toString().toLowerCase())) {
                return true;
            }
        }
        return false;
    }
    useEffect(() => {
        let tempValues = [...GoodData];
        if (searchText != null) {
            tempValues = tempValues.filter(checkSearch);
        }
        setDataSource(tempValues);
    }, [searchText])

    const handleSearch = (e) => {
        setsearchText(e)
    }

    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
        },
        {
            title: 'Roll No',
            dataIndex: 'username',
            key: 'username',
            sorter: (a, b) => a.username > b.username ? 1 : -1,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name > b.name ? 1 : -1,
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <div className='table-actions'>
                    <Button onClick={() => handleEdit(record)} key='editStudentPassword' className="btn-icon " type="primary" to="#" shape="circle">
                        <UilEdit />
                    </Button>
                </div>
            ),
        },
    ];

    const [dispatchuser, setDispatchuser] = useState('');

    const validateUsername = (rule, value) => {
        if (!value) {
            return Promise.reject('Please enter student username');
        } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
            return Promise.reject('Username can only contain numbers and alphabets');
        } else {
            return Promise.resolve();
        }
    };

    const validatePassword = (rule, value) => {
        if (!value) {
            return Promise.reject('Please enter a password');
        } else if (/\s/.test(value)) {
            return Promise.reject('Password cannot contain spaces');
        } else {
            return Promise.resolve();
        }
    };

    const validateConfirmPassword = (rule, value) => {
        if (!value) {
            return Promise.reject('Please enter confirm password');
        } else if (/\s/.test(value)) {
            return Promise.reject('Password cannot contain spaces');
        } else {
            return Promise.resolve();
        }
    };

    const handleEdit = (record) => {
        setEditRecord(record);
        setDispatchuser(record.username);
        setIsEditModalVisible(true);
        editForm.setFieldsValue({
            username: record.username,
            password: '',
            confirmpassword: '',
        });
    };

    const handleModalUpdate = () => {
        editForm.validateFields().then((values) => {
            if (values.password !== values.confirmpassword) {
                message.error('passwords are not matched');
            }
            else {
                const updatedRecord = {
                    username: dispatchuser,
                    newusername: values.username,
                    password: values.password,
                };
                setIsEditModalVisible(false);
                alertModal.confirm({
                    title: `Confirm change password ?`,
                    content: 'When clicked the OK button, the password will be changed.',
                    onOk() {
                        dispatch(userspwdDataUpdate(updatedRecord));
                    },
                    onCancel() { },
                });
            }
        }).catch((error) => {
            console.error('Error while validating form:', error);
        });
    };



    const ButtonClicked = () => {
        setEditRecord(!editRecord);
    };

    return (
        <>
            <Main>
                {isLoading || isUsersPwdChanging ?
                    (
                        <div className="custom-loading-spin-with-text">
                            <CustomSpinner />
                            <br />
                            <br />
                            <p style={{ fontWeight: 'bold', color: '#2D5D85' }}>
                                Loading please wait...
                            </p>
                        </div>
                    ) : (
                        <Row gutter={25}>
                            <Col sm={24} xs={24}>
                                <Cards headless>
                                    <TableWrapper>
                                        <ProjectList>
                                            <div className='d-flex justify-content-between mb-15 p-0 align-center-v'>
                                                <div className="d-flex align-center-v" style={{ marginLeft: 'auto' }}>
                                                    <AutoComplete id="students-rest_password-search-filter" onSearch={handleSearch} dataSource={dataSource} placeholder="Search here" patterns />
                                                </div>
                                            </div>
                                            <div className='table-responsive'>
                                                <Table dataSource={dataSource} columns={columns} />
                                            </div>
                                        </ProjectList>
                                    </TableWrapper>
                                </Cards>
                            </Col>
                        </Row>
                    )
                }
            </Main>
            <Modal
                title="Edit Student Password"
                open={isEditModalVisible}
                onCancel={() => setIsEditModalVisible(false)}
                maskClosable={false}
                footer={[
                    <Button id="edit-student_password-button" key="UpdateStudentPassword" type="primary" onClick={handleModalUpdate}>
                        Update
                    </Button>
                ]}
            >
                <Form id="student-reset_password-form" form={editForm} layout="vertical">
                    <Form.Item name="username" label="UserName" rules={[{ validator: validateUsername }]}>
                        <Input id="student-reset_password-username-disabled" disabled={!!editRecord}
                            addonAfter=
                            {<Button onClick={() => ButtonClicked()} key='editStudentPassword' className="btn-icon" to="#" shape="circle">
                                <UilEdit />
                            </Button>}
                        />
                    </Form.Item>
                    <Form.Item name="password" label="Password" rules={[{ required: true, validator: validatePassword }]}>
                        <Input.Password id="student-reset_password-enter-password" placeholder='Enter Password!' />
                    </Form.Item>
                    <Form.Item name="confirmpassword" label="Confirm Password" rules={[{ required: true, validator: validateConfirmPassword }]}>
                        <Input.Password id="student-reset_password-enter-confirm_password" placeholder='Confirm entered password!' />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default Students;