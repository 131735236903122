import { message, notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';

const {
  teachersAddBegin,
  teachersAddSuccess,
  teachersAddErr,

  teachersReadBegin,
  teachersReadSuccess,
  teachersReadErr,

  teachersUpdateBegin,
  teachersUpdateSuccess,
  teachersUpdateErr,
} = actions;

const teachersDataSubmit = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(teachersAddBegin());
      const response = await DataService.post('/teacher-master/create', data);
      const token = getItem('access_token');
      if (token && response.data.Error === false) {
        await dispatch(teachersAddSuccess(response.data));
        message.success(response.data.message);
      } else {
        if (response.data.Error === true && response.data.statusCode === 401){
          message.error(response.data.message);
          dispatch(logOut())
        }
        throw response.data.message;
      }
    } catch (err) {
      await dispatch(teachersAddErr(err));
      message.error(err);
    }
  };
};

const teachersDataRead = () => {
  return async (dispatch) => {
    try {
      await dispatch(teachersReadBegin());
      const query = await DataService.get(`/teacher-master`);
      const token = getItem('access_token');
      if (token && query.data!== null && query.data.Error === false) {
        await dispatch(teachersReadSuccess(query.data.payload));
      } else {
        if (query.data.Error === true && query.data.statusCode === 401){
          message.error(query.data.message);
          dispatch(logOut())
        }
        throw query.data.message;
      }
    } catch (err) {
      await dispatch(teachersReadErr(err));
      message.error(err);
    }
  };
};

const teachersDataUpdate = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(teachersUpdateBegin());
      const response = await DataService.put(`/teacher-master/update`, data);
      const token = getItem('access_token');
      if(token && response.data.Error === false){
        await dispatch(teachersUpdateSuccess(response.data));
        message.success(response.data.message);
      }else{
        if (response.data.Error === true && response.data.statusCode === 401){
          message.error(response.data.message);
          dispatch(logOut())
        }
        throw response.data.message;
      }
    } catch (err) {
      await dispatch(teachersUpdateErr(err));
      message.error(err);
    }
  };
};

export {
  teachersDataRead,
  teachersDataSubmit,
  teachersDataUpdate,
};
