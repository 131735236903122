import React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getCollegeWiseNotice } from '../../../redux/authentication/actionCreator'
import { Modal } from 'antd'
import { useState } from 'react'

function NoticeModal() {
    const dispatch = useDispatch()

    const [visisble, setVisible] = useState(true)

    useEffect(() => {
        // dispatch(getCollegeWiseNotice())
    }, [])

    const { collegeWiseNotce, collegeWiseNotceLoading } = useSelector((state) => {
        return (
            {
                collegeWiseNotce: state.collegeWiseNotce.data,
                collegeWiseNotceLoading: state.collegeWiseNotce.loading
            }
        )
    })
    
    return (
        <div>
            {
                collegeWiseNotce !== null &&
                <Modal                    
                    footer={false}
                    open={visisble}
                    title={collegeWiseNotce.title}
                    onCancel={() => setVisible(false)}
                    width={800}
                >
                    <div dangerouslySetInnerHTML={{ __html: collegeWiseNotce.notice }} />
                </Modal>
            }
        </div >
    )
}

export default NoticeModal