import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { message, notification } from 'antd';
import { getTopicById } from '../topics/actionCreator';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';



const addNotificationSuccess = () => {
  notification.success({
    message: 'Data upload successfully',
  });
};

const {
  contentAddBegin,
  contentAddSuccess,
  contentAddErr,
} = actions;

const TopicContentSubmit = (formData, topicId) => {
  return async (dispatch) => {
    try {
      await dispatch(contentAddBegin());
      const response = await DataService.fileUpload('/topicmaster/pdffile', formData);
      const token = getItem('access_token');
      const data = response.data
      if (token && data !== null && data.Error === false) {
        await dispatch(contentAddSuccess(data));
        if (data.message === "updated") {
          dispatch(getTopicById(parseInt(topicId)));
          addNotificationSuccess()
        }
        else {
          message.error(data.message)
        }
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(contentAddSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(contentAddSuccess(null));
        }
      }
      else {
        await dispatch(contentAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(contentAddErr(err));
    }
  };
};




export {
  TopicContentSubmit,
};
