import React from 'react'
import SubListBlock from './SubListBlock'

export default function ListBlock({ id, header, subHeaders }) {
    return (
        <div>
            <div id={id}>
                <h2 className='header'>{header}</h2>
                <div className='content'>
                    {subHeaders.map((subBlock, subBlockIndex) => (
                        <div key={subBlockIndex}>
                            <h2 className='sub-header'>{subBlock.title}</h2>
                            {
                                subBlock.sections.length !== 0 &&
                                <SubListBlock data={subBlock.sections} />
                            }
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
