import { notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import axios from 'axios';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';

const addNotificationSuccess = () => {
  notification.success({
    message: 'A new badge has been Added',
  });
};

const addNotificationError = (err) => {
  const errorMessage = `Adding badge has been failed(${err})`;
  notification.error({
    message: errorMessage,
    duration: 0,
  });
};

const deleteNotificationSuccess = () => {
  notification.success({
    message: 'Badge has been Deleted',
  });
};

const deleteNotificationError = (err) => {
  const errorMessage = `Deleting badge has been failed(${err})`;
  notification.error({
    message: errorMessage,
    duration: 0,
  });
};

const updateNotificationSuccess = () => {
  notification.success({
    message: 'Badge Record has been updated',
  });
};

const updateNotificationError = (err) => {
  const errorMessage = `Updating badge has been failed(${err})`;
  notification.error({
    message: errorMessage,
    duration: 0,
  });
};

const {
  badgesAddBegin,
  badgesAddSuccess,
  badgesAddErr,

  badgesReadBegin,
  badgesReadSuccess,
  badgesReadErr,

  badgesUpdateBegin,
  badgesUpdateSuccess,
  badgesUpdateErr,

  badgesDeleteBegin,
  badgesDeleteSuccess,
  badgesDeleteErr,
} = actions;

const badgesDataSubmit = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(badgesAddBegin());
      const response = await DataService.post('/badges/create', data);
      const token = getItem('access_token');
      if (token && response.data === 'created') {
        await dispatch(badgesAddSuccess(response.data));
        addNotificationSuccess();
      } else {
        if (response.data.Error === true && response.data.statusCode === 401){
          notification.error({ message: response.data.message })
          dispatch(logOut())
        }
        throw response.data.message;
        // await dispatch(badgesAddErr('No Unauthorize access'));
        // addNotificationError('No Unauthorize access');
      }
    } catch (err) {
      await dispatch(badgesAddErr(err));
      addNotificationError(err);
    }
  };
};

const badgesDataRead = () => {
  return async (dispatch) => {
    try {
      await dispatch(badgesReadBegin());
      const query = await DataService.get('/badges');
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(badgesReadSuccess(query.data.payload));
      } else {
        if (query.data.Error === true && query.data.statusCode === 401){
          notification.error({ message: query.data.message })
          dispatch(logOut())
        }
        throw query.data.message;
        // await dispatch(badgesReadSuccess([]));
        // await dispatch(branchAddErr('No Unauthorize access'));
      }
    } catch (err) {
      await dispatch(badgesReadErr(err));
    }
  };
};

const badgesDataUpdate = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(badgesUpdateBegin());
      const response = await DataService.put(`/badges/update`, data);
      const token = getItem('access_token');
      if(token && response.data.Error === false){
        await dispatch(badgesUpdateSuccess(response.data));
        updateNotificationSuccess();
      }else{
        if (response.data.Error === true && response.data.statusCode === 401){
          notification.error({ message: response.data.message })
          dispatch(logOut())
        }
        throw response.data.message;
        // await dispatch(badgesDataUpdate('No Unauthorize access'))
      }
    } catch (err) {
      await dispatch(badgesUpdateErr(err));
      updateNotificationError(err);
    }
  };
};

const badgesDataDelete = (Id) => {
  return async (dispatch) => {
    try {
      await dispatch(badgesDeleteBegin());
      const query = await DataService.delete(`/badges/delete/${Id}`);
      const token = getItem('access_token');
      if (token) {
        await dispatch(badgesDeleteSuccess(query.data));
        deleteNotificationSuccess();
      } else {  
        if (query.data.Error === true && query.data.statusCode === 401){
          notification.error({ message: query.data.message })
          dispatch(logOut())
        }      
        throw query.data.message;
        // await dispatch(badgesDeleteErr('No Unauthorize access'));
      }
      // await getData();
    } catch (err) {
      await dispatch(badgesDeleteErr(err));
      deleteNotificationError(err);
    }
  };
};

export {
  badgesDataRead,
  badgesDataSubmit,
  badgesDataDelete,
  badgesDataUpdate,
};
