import { notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';

const updateNotificationSuccess = (data) => {
  const msg = data.username === data.newusername ? 'Password has been changed' : 'Username and password has been changed';
  notification.success({
    message: msg,
  });
};

const updateNotificationError = (err) => {
  const errorMessage = `Resetting password has been failed(${err})`;
  notification.error({
    message: errorMessage,
    duration: 0,
  });
};

const {

  userspwdchangeUpdateBegin,
  userspwdchangeUpdateSuccess,
  userspwdchangeUpdateErr,
} = actions;


const userspwdDataUpdate = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(userspwdchangeUpdateBegin());
      const response = await DataService.put(`/user-master/update`, data);
      const token = getItem('access_token');
      if(token && response.data.Error === false){
        await dispatch(userspwdchangeUpdateSuccess(response.data));
        updateNotificationSuccess(data);
      }else{
        if (response.data.Error === true && response.data.statusCode === 401){
          notification.error({ message: response.data.message })
          dispatch(logOut())
        }
        throw response.data.message;
        // await dispatch(userspwdDataUpdate('No Unauthorize access'));
      }
    } catch (err) {
      await dispatch(userspwdchangeUpdateErr(err));
      updateNotificationError(err);
    }
  };
};

export {
  userspwdDataUpdate,
};
