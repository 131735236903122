import { message, notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';

const addNotificationSuccess = () => {
  notification.success({
    message: 'A new topic has been Added',
  });
};

const addNotificationError = (err) => {
  const errorMessage = `Adding new topic has been failed(${err})`;
  notification.error({
    message: errorMessage,
    duration: 0,
  });
};

const updateNotificationSuccess = () => {
  notification.success({
    message: 'Topic Record has been updated',
  });
};

const updateNotificationError = (err) => {
  const errorMessage = `Updating topic has been failed(${err})`;
  notification.error({
    message: errorMessage,
    duration: 0,
  });
};

const {
  topicsadminAddBegin,
  topicsadminAddSuccess,
  topicsadminAddErr,

  topicsadminReadBegin,
  topicsadminReadSuccess,
  topicsadminReadErr,

  topicsadminUpdateBegin,
  topicsadminUpdateSuccess,
  topicsadminUpdateErr,
} = actions;

const topicsadminDataSubmit = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(topicsadminAddBegin());
      const response = await DataService.post('/topic-master/create', data);
      const token = getItem('access_token');
      if (token && response.data.Error === false) {
        await dispatch(topicsadminAddSuccess(response.data));
        message.success(response.data.message);
      } else {
        if (response.data.Error === true && response.data.statusCode === 401){
          message.error(response.data.message);
          dispatch(logOut())
        }
        throw response.data.message;
      }
    } catch (err) {
      await dispatch(topicsadminAddErr(err));
      message.error(err);
    }
  };
};

const topicsadminDataRead = () => {
  return async (dispatch) => {
    try {
      await dispatch(topicsadminReadBegin());
      const query = await DataService.get(`/topic-master/all`);
      const token = getItem('access_token');
      if (token && query.data.Error === false) {
        await dispatch(topicsadminReadSuccess(query.data.payload));
      } else {
        if (query.data.Error === true && query.data.statusCode === 401){
          message.error(query.data.message);
          dispatch(logOut())
        }
        throw query.data.message;
      }
    } catch (err) {
      await dispatch(topicsadminReadErr(err));
      message.error(err);
    }
  };
};

const topicsadminDataUpdate = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(topicsadminUpdateBegin());
      const response = await DataService.put(`/topic-master/update`, data);
      const token = getItem('access_token');
      if(token && response.data.Error === false){
        await dispatch(topicsadminUpdateSuccess(response.data));
        message.success(response.data.message);
      }else{
        if (response.data.Error === true && response.data.statusCode === 401){
          message.error(response.data.message);
          dispatch(logOut())
        }
        throw response.data.message;
      }
    } catch (err) {
      await dispatch(topicsadminUpdateErr(err));
      message.error(err);
    }
  };
};

export {
  topicsadminDataRead,
  topicsadminDataSubmit,
  topicsadminDataUpdate,
};
