const actions = {
  BADGES_ADD_BEGIN: 'BADGES_ADD_BEGIN',
  BADGES_ADD_SUCCESS: 'BADGES_ADD_SUCCESS',
  BADGES_ADD_ERR: 'BADGES_ADD_ERR',

  BADGES_READ_BEGIN: 'BADGES_READ_BEGIN',
  BADGES_READ_SUCCESS: 'BADGES_READ_SUCCESS',
  BADGES_READ_ERR: 'BADGES_READ_ERR',
  
  BADGES_UPDATE_BEGIN: 'BADGES_UPDATE_BEGIN',
  BADGES_UPDATE_SUCCESS: 'BADGES_UPDATE_SUCCESS',
  BADGES_UPDATE_ERR: 'BADGES_UPDATE_ERR',
  
  BADGES_DELETE_BEGIN: 'BADGES_DELETE_BEGIN',
  BADGES_DELETE_SUCCESS: 'BADGES_DELETE_SUCCESS',
  BADGES_DELETE_ERR: 'BADGES_DELETE_ERR',

  badgesAddBegin: () => {
    return {
      type: actions.BADGES_ADD_BEGIN,
    };
  },

  badgesAddSuccess: (data) => {
    return {
      type: actions.BADGES_ADD_SUCCESS,
      data,
    };
  },

  badgesAddErr: (err) => {
    return {
      type: actions.BADGES_ADD_ERR,
      err,
    };
  },

  badgesReadBegin: () => {
    return {
      type: actions.BADGES_READ_BEGIN,
    };
  },

  badgesReadSuccess: (data) => {
    return {
      type: actions.BADGES_READ_SUCCESS,
      data,
    };
  },

  badgesReadErr: (err) => {
    return {
      type: actions.BADGES_READ_ERR,
      err,
    };
  },

  badgesUpdateBegin: () => {
    return {
      type: actions.BADGES_UPDATE_BEGIN,
    };
  },

  badgesUpdateSuccess: (data) => {
    return {
      type: actions.BADGES_UPDATE_SUCCESS,
      data,
    };
  },

  badgesUpdateErr: (err) => {
    return {
      type: actions.BADGES_UPDATE_ERR,
      err,
    };
  },

  badgesDeleteBegin: () => {
    return {
      type: actions.BADGES_DELETE_BEGIN,
    };
  },

  badgesDeleteSuccess: (data) => {
    return {
      type: actions.BADGES_DELETE_SUCCESS,
      data,
    };
  },

  badgesDeleteErr: (err) => {
    return {
      type: actions.BADGES_DELETE_ERR,
      err,
    };
  },
};

export default actions;
