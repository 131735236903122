import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Select, Result, Table, Form, Tooltip, Button as AntBtn, Input, message } from 'antd';
import { Button } from '../../components/buttons/buttons';
import { NoteCardWrap } from '../note/style';
import { useDispatch, useSelector } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import WaveSurfer from 'wavesurfer.js'
import { AcceptOrRejectQuestion } from '../../redux/teacherSubjectsUnits/actionCreator';
import { UilEdit, UilExport, UilRedo, UilPlus } from '@iconscout/react-unicons';
import { TopSellerWrap } from './styles/dashboardStyle';
import { TableDefaultStyle } from '../styled';
import { getTopicById, FreezeTopicById } from '../../redux/topics/actionCreator';
import { alertModal } from '../../components/modals/antd-modals';
import { useForm } from 'antd/lib/form/Form';
import { GetQuestionsOfTopicDataRead } from '../../redux/teacherSubjectsUnits/actionCreator';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import ImportQuestions from './ImportQuestions';
import { CreateQuestionOfTopic, ReGenerateQuestionsOfTopic, UpdateQuestion } from '../../redux/teacherSubjectsUnits/actionCreator';
import RichTextEditor, { EditorValue } from 'react-rte';
import { MailBox } from '../email/overview/style';
import AddQuestion from './AddQuestion';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import CodeMirror from "@uiw/react-codemirror";
import { UilShuffle } from '@iconscout/react-unicons'
import Sortable from 'sortablejs';
import { filterBlankLines, handleShuffle, renderCodeWithLineNumbers } from './QuestionMethods';

const { Option } = Select;

function ReviewAndGenerateQuestions({ customWidth }) {
    const { topicName, topicId } = useParams()

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [Regenerateform] = Form.useForm();
    const [currentPage, setCurrentPage] = useState(1);
    const [questionType, setQuestionType] = useState(null)
    const jumbleRef = useRef(null);
    const [code, setCode] = useState('');
    const [jumbledCode, setJumbledCode] = useState(null)
    const [errorCode, setErrorCode] = useState(null)

    const { isCreateQuestionDataLoading, UpdateQuestionData, isUpdateQuestionDataLoading, isAcceptOrRejectQuestionLoading, AcceptOrRejectQuestionData, issingleTopicDataLoading, isRegenerateQuestionsDataLoading, RegenerateQuestionsData, singleTopicData, CreateQuestionData, isImportQuestionsDataLoading, ImportQuestionsData, GenerateQuestionsData, QuestionsOfTopic, isQuestionsOfTopicLoading, isGenerateQuestionsLoading } = useSelector((state) => {
        return {
            GenerateQuestionsData: state.GenerateQuestions.data,
            isGenerateQuestionsLoading: state.GenerateQuestions.loading,
            QuestionsOfTopic: state.QuestionsOfTopic.data,
            isQuestionsOfTopicLoading: state.QuestionsOfTopic.loading,
            ImportQuestionsData: state.ImportQuestions.data,
            isImportQuestionsDataLoading: state.ImportQuestions.loading,

            singleTopicData: state.singleTopic.data,
            issingleTopicDataLoading: state.singleTopic.data,

            CreateQuestionData: state.CreateQuestion.data,
            isCreateQuestionDataLoading: state.CreateQuestion.loading,

            RegenerateQuestionsData: state.RegenerateQuestions.data,
            isRegenerateQuestionsDataLoading: state.RegenerateQuestions.loading,

            AcceptOrRejectQuestionData: state.AcceptOrRejectQuestion.data,
            isAcceptOrRejectQuestionLoading: state.AcceptOrRejectQuestion.loading,

            UpdateQuestionData: state.Updatequestion.data,
            isUpdateQuestionDataLoading: state.Updatequestion.loading,
        };
    });

    useEffect(() => {
        dispatch(getTopicById(parseInt(topicId)))
    }, [dispatch])


    useEffect(() => {
        if (UpdateQuestionData !== null && UpdateQuestionData.Error === false) {
            setQuestionEditModal(false);
            form.resetFields();
            setJumbledCode(null)
            setCode('')
            setQuestionType(null)
            setEditQuestionRecord(null);
            // setCodeQuestion({ value: RichTextEditor.createEmptyValue() });
            dispatch(GetQuestionsOfTopicDataRead(topicId));
        }
    }, [UpdateQuestionData])

    useEffect(() => {
        if (RegenerateQuestionsData !== null && RegenerateQuestionsData.Error === false) {
            dispatch(GetQuestionsOfTopicDataRead(topicId));
        }
    }, [RegenerateQuestionsData])

    useEffect(() => {
        if (AcceptOrRejectQuestionData !== null && AcceptOrRejectQuestionData.Error === false) {
            dispatch(GetQuestionsOfTopicDataRead(topicId));
        }
    }, [AcceptOrRejectQuestionData])

    const Topicstatus = singleTopicData?.topicstatus;

    useEffect(() => {
        if (singleTopicData?.topicstatus === "QUESTIONS GENERATING") {
            const intervalCall = setInterval(() => {
                dispatch(getTopicById(parseInt(topicId)));
            }, 30000);
            return () => {
                // clean up
                clearInterval(intervalCall);
            };
        }
        else if (singleTopicData?.topicstatus === "QUESTIONS GENERATED") {
            dispatch(GetQuestionsOfTopicDataRead(topicId));
        }
    }, [singleTopicData]);

    const [GoodData, setGoodData] = useState([]);
    const [TableData, setTableData] = useState([]);

    const AudioPlayer = ({ audioUrl, id }) => {
        const [waveSurfer, setWaveSurfer] = useState(null);
        const [currentlyPlayingId, setCurrentlyPlayingId] = useState(null);

        useEffect(() => {
            const newWaveSurfer = WaveSurfer.create({
                container: `#${id}`,
                waveColor: '#6FA2CE',
                progressColor: '#2D5D85',
                cursorColor: '#224563',
                cursorWidth: 2,
                barWidth: 2,
                barGap: 1,
                barRadius: 2,
                height: 50,
                width: 100,
            });

            newWaveSurfer.on('finish', () => {
                setIsPlaying(false);
            });

            setWaveSurfer(newWaveSurfer);

            return () => {
                newWaveSurfer.destroy();
            };
        }, []);

        useEffect(() => {
            if (waveSurfer && audioUrl) {
                waveSurfer.load(process.env.REACT_APP_API_ENDPOINT + audioUrl);
            }
        }, [waveSurfer, audioUrl]);

        const [isPlaying, setIsPlaying] = useState(false);
        const handlePlayPause = () => {
            if (waveSurfer.isPlaying()) {
                waveSurfer.pause();
                setIsPlaying(false);
            } else {
                waveSurfer.play();
                setIsPlaying(true);
            }
        };

        return (
            <div >
                <div className='d-flex justify-content-center align-center-v'>
                    <div id={id} className='waveform-review d-none' />
                    <AntBtn onClick={handlePlayPause} className='icon-btn mb-0' type='link'>
                        {isPlaying ? (
                            <FontAwesome name="pause" style={{ fontSize: '14px', background: '#eee', padding: '6px 14px', borderRadius: '6px' }} />
                        ) : (
                            <FontAwesome name="play" style={{ fontSize: '14px', background: '#eee', padding: '6px 14px', borderRadius: '6px' }} />
                        )}
                    </AntBtn>
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (QuestionsOfTopic) {
            const filteredData = QuestionsOfTopic.map((item, index) => ({
                id: item.id,
                key: index + 1,
                sno: index + 1,
                question: item.question.replace(/<[^>]*>|&nbsp;/g, ' '),
                questionWithHtml: item.question,
                audio: item.audiolink !== null ? <AudioPlayer audioUrl={item.audiolink} id={`waveform-${index + 1}`} /> : 'no audio',
                difficultylevel: item.difficultylevel,
                questionType: item.questionType,
                expectedConceptualKnowledge: item.expectedConceptualKnowledge,
                statuss: <span className={`status ${item.status.toLowerCase()}`}>{item.status}</span>,
                status: item.status,
                tagId: item.tags ? item.tags.map((i) => i.id) : [],
                tags: item.tags ? item.tags.map((i) => i.name) : [],
                program: item.program,
                modifiedProgram: item.modifiedProgram
            }));
            setTableData(filteredData);
            setGoodData(filteredData)
        }
        else {
            setTableData([]);
            setGoodData([])
        }
    }, [QuestionsOfTopic])

    const [RegenerateQuestionsModal, setRegenerateQuestionsModal] = useState(false);
    const [QuestionEditModal, setQuestionEditModal] = useState(false);
    const [EditQuestionRecord, setEditQuestionRecord] = useState(null);

    const handleEditQuestion = (record) => {
        setQuestionType(record.questionType)
        setEditQuestionRecord(record);
        if (record.questionType === "JUMBLED CODE") {
            setCode(record.program)
            setJumbledCode(record.modifiedProgram)
            form.setFieldsValue({
                question: record.question,
                questionType: record.questionType,
                expectedConceptualKnowledge: record.expectedConceptualKnowledge,
            })
        }
        else if (record.questionType === "DEBUG CODE") {
            setCode(record.program)
            setErrorCode(record.modifiedProgram)
            form.setFieldsValue({
                question: record.question,
                questionType: record.questionType,
                expectedConceptualKnowledge: record.expectedConceptualKnowledge,
            })
        }
        else if (record.questionType === "EXPLAIN CODE") {
            setCode(record.modifiedProgram)
            form.setFieldsValue({
                question: record.question,
                questionType: record.questionType,
                expectedConceptualKnowledge: record.expectedConceptualKnowledge,
                explainprogram: record.modifiedProgram
            })
        }
        else if (record.questionType === "PREDICT OUTPUT") {
            setCode(record.modifiedProgram)
            form.setFieldsValue({
                question: record.question,
                questionType: record.questionType,
                expectedConceptualKnowledge: record.expectedConceptualKnowledge,
                predictprogram: record.modifiedProgram
            })
        }
        else if (record.questionType === "THEORY" || record.questionType === "VIVA") {
            form.setFieldsValue({
                question: record.question,
                questionType: record.questionType,
                expectedConceptualKnowledge: record.expectedConceptualKnowledge
            })
        }
        else if (record.questionType === "CODE") {
            // setCodeQuestion({ value: EditorValue.createFromString(record.questionWithHtml, 'html') })
            setCode(record.questionWithHtml)
            form.setFieldsValue({
                question: record.questionWithHtml,
                questionType: record.questionType,
                expectedConceptualKnowledge: record.expectedConceptualKnowledge
            })
        }
        setQuestionEditModal(true);
    }

    const handleRegenerateQuestionsSubmit = () => {
        Regenerateform.validateFields().then((values) => {
            const datatoPost = {
                topicId: parseInt(topicId),
                questionCount: parseInt(values.questionCount),
            }
            dispatch(ReGenerateQuestionsOfTopic(datatoPost))
            setRegenerateQuestionsModal(false);
            Regenerateform.resetFields();
        })
            .catch((error) => {
                console.error('Error while validating form:', error);
            });
    }

    // const [codeQuestion, setCodeQuestion] = useState({
    //     value: RichTextEditor.createEmptyValue()
    // });


    // const onCodeQuestionChange = (value) => {
    //     setCode(value);
    // };


    const [selectedQuestionType, setSelectedQuestionType] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);

    const checkQuestionType = (i) => {
        let tarr = i.questionType;
        let valueInArray = false;
        if (tarr === selectedQuestionType) {
            valueInArray = true;
        }
        return valueInArray;
    }

    const checkStatus = (i) => {
        let tarr = i.status;
        let valueInArray = false;
        if (tarr === selectedStatus) {
            valueInArray = true;
        }
        return valueInArray;
    }

    useEffect(() => {
        let tempValues = [...GoodData];
        if (selectedQuestionType != null) {
            tempValues = tempValues.filter(checkQuestionType);
        }
        if (selectedStatus != null) {
            tempValues = tempValues.filter(checkStatus);
        }
        setTableData(tempValues);
    }, [selectedQuestionType, selectedStatus])

    const [createQuestionModal, setCreateQuestionModal] = useState(false);

    const handleQuestionTypeChange = (e) => {
        setSelectedQuestionType(e);
    }

    const handleStatusChange = (e) => {
        setSelectedStatus(e);
    }

    const handleCreateQuestion = () => {
        // setCodeQuestion({ value: RichTextEditor.createEmptyValue() });
        setCode("")
        setCreateQuestionModal(true);
    }


    const handleAcceptOrIgnoreQuestion = (record, value) => {
        const datatoPost = {
            id: record.id,
            status: value === 0 ? 'REJECTED' : 'ACCEPTED',
        }
        dispatch(AcceptOrRejectQuestion(datatoPost));
    }

    const handleRegenerateQuestions = () => {
        setRegenerateQuestionsModal(true);
    }

    const handleFreezeQuestions = () => {
        alertModal.confirm({
            title: `Are you sure to freeze questions for ${topicName}`,
            content: <div>
                <span>Once the topic is freezed, you cannot unfreeze the topic anymore</span>
            </div>,
            onOk() {
                dispatch(FreezeTopicById(topicId));
            },
            onCancel() { },
        });
    }

    const handleEditQuestionSubmit = () => {
        const questionType = form.getFieldValue('questionType')
        const expectedConceptualKnowledge = form.getFieldValue('expectedConceptualKnowledge') || ''
        const questionfield = form.getFieldValue('question') || ''
        // const codeQ = codeQuestion.value.toString('html')

        let question = ''
        let program = ''
        let modifiedProgram = ''
        if (EditQuestionRecord) {
            if (questionType == "JUMBLED CODE" && questionfield !== "" && jumbleRef.current !== null && code !== "") {
                const jumbledCode = Array.from(jumbleRef.current.children)
                    .map((li) => li.textContent.replace(/^(?:.*?(\d+):\s*)?(.*)$/, '$2'))
                    .join('\n');

                question = questionfield
                program = code
                modifiedProgram = jumbledCode
            }
            else if (questionType == "DEBUG CODE" && questionfield !== "" && code !== "" && errorCode !== "") {
                question = questionfield
                program = code
                modifiedProgram = errorCode
            }
            else if ((questionType == "THEORY" || questionType == "VIVA") && questionfield !== "") {
                question = questionfield
                program = null
                modifiedProgram = null
            }
            else if (questionType == "CODE" && code != "") {
                question = code
                program = null
                modifiedProgram = null
            }
            else if (questionType == "EXPLAIN CODE" && questionfield !== "" && (form.getFieldValue('explainprogram') !== "" && form.getFieldValue('explainprogram') !== undefined)) {
                question = questionfield
                program = null
                modifiedProgram = form.getFieldValue('explainprogram')
            }
            else if (questionType == "PREDICT OUTPUT" && questionfield !== "" && (form.getFieldValue('predictprogram') !== "" && form.getFieldValue('predictprogram') !== undefined)) {
                question = questionfield
                program = null
                modifiedProgram = form.getFieldValue('predictprogram')
            }
            else {
                question = ''
                program = ''
                modifiedProgram = ''
                message.error('Fill all the required fields!')
                return null
            }
        }
        else {
            message.error('Failed to update..')
            return;
        }

        const datatoPost = {
            id: EditQuestionRecord.id,
            question: question,
            questionType: questionType,
            program: program,
            modifiedProgram: modifiedProgram,
            tags: EditQuestionRecord.tagId,
            expectedConceptualKnowledge: expectedConceptualKnowledge,
        }
        dispatch(UpdateQuestion(datatoPost));

    }

    const columns = [
        {
            title: 'S.No',
            key: 'sno',
            dataIndex: 'sno',
            align: 'center',
            width: 60
        },
        {
            title: 'Question',
            key: 'question',
            dataIndex: 'question',
            ellipsis: true,
            width: 350,
        },
        {
            title: 'Type',
            key: 'questionType',
            dataIndex: 'questionType',
            width: 100,
        },
        {
            title: 'Audio',
            dataIndex: 'audio',
            align: 'center',
            width: 200,
        },
        {
            title: 'Actions',
            key: 'question',
            // align: 'center',
            width: 200,
            render: (_, record) => (
                <div className='d-flex align-center-v'>
                    <Button key="editquestion" type='primary' transparented className="mr-5" onClick={() => handleEditQuestion(record)} >
                        <UilEdit size={16} />
                    </Button>
                    <div>
                        {
                            record.status === 'ACCEPTED' ?
                                <Tooltip title="Reject - This question will not be picked up for interview" align={'top'}>
                                    <Button
                                        transparented className="mr-5"
                                        onClick={() => handleAcceptOrIgnoreQuestion(record, 0)}
                                    >
                                        <FontAwesome name="times" style={{ fontSize: '18px', background: '#eee', padding: '6px 14px', borderRadius: '6px' }} />
                                    </Button>
                                </Tooltip>
                                :
                                <Tooltip title="Accept" align={'top'}>
                                    <Button
                                        transparented className="mr-5"
                                        onClick={() => handleAcceptOrIgnoreQuestion(record, 1)}
                                    >
                                        <FontAwesome name="check" style={{ fontSize: '18px', background: '#eee', padding: '6px 14px', borderRadius: '6px' }} />
                                    </Button>
                                </Tooltip>
                        }
                    </div>
                </div>
            ),
        },
    ]

    const [ImportQuestionsComponent, setImportQuestionsComponent] = useState(false);
    const handleImportQuestions = () => {
        setImportQuestionsComponent(true);
    }


    const onQuestionTypeChange = (type) => {
        setQuestionType(type);
        if (type == "JUMBLED CODE") {
            setJumbledCode(code)
        }
        else if (type == "DEBUG CODE") {
            setJumbledCode(null)
            setErrorCode(code)
        }
        else if (type === "THEORY" || type === "VIVA") {
            form.setFieldsValue({ question: EditQuestionRecord.question })
        }
        else if (type === "CODE") {
            form.setFieldsValue({ question: code })
        }
        else if (type === "EXPLAIN CODE") {
            form.setFieldsValue({ predictprogram: undefined })
            form.setFieldsValue({ explainprogram: code })
        }
        else if (type === "PREDICT OUTPUT") {
            form.setFieldsValue({ predictprogram: code })
            form.setFieldsValue({ explainprogram: undefined })
        }
        // setCode('')
        // form.setFieldsValue({ question: undefined, program: undefined })
    }

    useEffect(() => {
        if (jumbleRef.current) {
            const sortable = new Sortable(jumbleRef.current, {
                animation: 150,
            });

            return () => {
                sortable.destroy();
            };
        }
    }, [jumbledCode]);

    const CloseEditModal = () => {
        setQuestionEditModal(false);
        form.resetFields();
        setEditQuestionRecord(null);
        // setCodeQuestion({ value: RichTextEditor.createEmptyValue() });
        setCode('')
        setErrorCode(null)
        setJumbledCode(null)
        jumbleRef.current = null
    }

    return (
        <div style={customWidth}>
            {isCreateQuestionDataLoading || isUpdateQuestionDataLoading || isAcceptOrRejectQuestionLoading ?
                <NoteCardWrap>
                    <div className='custom-spin'>
                        <CustomSpinner Message={'Loading... Please wait.'} marginTop={40} />
                    </div>
                </NoteCardWrap>
                :

                Topicstatus === 'QUESTIONS GENERATING' || isRegenerateQuestionsDataLoading ?
                    <NoteCardWrap>
                        <div className='custom-spin'>
                            <CustomSpinner Message={'Questions are being generating... Please wait.'} marginTop={40} />
                        </div>
                    </NoteCardWrap> :

                    (
                        ImportQuestionsComponent ?
                            <NoteCardWrap>
                                <ImportQuestions backButton={<Button type='primary' transparented className="mr-10" onClick={() => setImportQuestionsComponent(false)}>Back</Button>} />
                            </NoteCardWrap>
                            :

                            (
                                isQuestionsOfTopicLoading ?
                                    <NoteCardWrap>
                                        <div className='custom-spin'>
                                            <CustomSpinner Message={'Fetching Questions... Please wait.'} marginTop={40} />
                                        </div>
                                    </NoteCardWrap>
                                    :
                                    TableData.length == 0 ?
                                        <NoteCardWrap>
                                            <Result
                                                title="Questions not found!"
                                                status={'warning'}
                                            />
                                        </NoteCardWrap>
                                        :
                                        TableData.length !== 0 &&
                                        <div>
                                            <div className='d-flex justify-content-between align-center-v'>
                                                <div style={{ display: 'flex' }}>
                                                    <Select placeholder='Question Type' style={{ width: '200px', marginBottom: '15px' }} onChange={(e) => handleQuestionTypeChange(e)} allowClear>
                                                        {Array.from(new Set(QuestionsOfTopic?.map(item => item.questionType))).map((questionType) => (
                                                            <Option key={questionType} value={questionType}>
                                                                {questionType}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                    <Select placeholder='Status' style={{ width: '200px', marginLeft: '10px', marginBottom: '15px' }} onChange={(e) => handleStatusChange(e)} allowClear>
                                                        {Array.from(new Set(QuestionsOfTopic?.map(item => item.status))).map((status) => (
                                                            <Option key={status} value={status}>
                                                                {status}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </div>
                                                <div>
                                                    <Tooltip title='Import Questions'>
                                                        <Button type='primary' onClick={handleImportQuestions}>
                                                            <UilExport />
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title='Regenerate Questions'>
                                                        <Button type='primary' className='mb-10 ml-10 text-right' onClick={handleRegenerateQuestions}><UilRedo /></Button>
                                                    </Tooltip>
                                                    <Tooltip title='Add Question'>
                                                        <Button type='primary' className='mb-10 ml-10 text-right' onClick={handleCreateQuestion}><UilPlus /></Button>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <TableDefaultStyle className="ninjadash-having-header-bg">
                                                <TopSellerWrap>
                                                    <div className="table-bordered top-seller-table table-responsive">
                                                        <Table
                                                            columns={columns}
                                                            dataSource={TableData}
                                                            pagination={{
                                                                className: 'd-flex align-center-v',
                                                                size: 'small',
                                                                responsive: true,
                                                                current: currentPage,
                                                                onChange: (page) => setCurrentPage(page),
                                                                showTotal: (total) => `Total ${total} Questions`
                                                            }}
                                                            expandable={{
                                                                expandedRowRender: (record) =>
                                                                    <div>
                                                                        {
                                                                            record.questionType == "CODE" ?
                                                                                <>
                                                                                    <h4 className='text-left'>Question</h4>
                                                                                    {
                                                                                        record.question.split('\n').map((line, index) => {
                                                                                            if (line.trim() !== '') {
                                                                                                return (
                                                                                                    <li
                                                                                                        key={index}
                                                                                                        style={{
                                                                                                            display: 'flex',
                                                                                                        }}
                                                                                                    >
                                                                                                        <span>{line}</span>
                                                                                                    </li>
                                                                                                );
                                                                                            } else {
                                                                                                return null;
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <div dangerouslySetInnerHTML={{ __html: 'Q. ' + record.question }} className='pl-20 d-flex justify-content-start' />
                                                                        }
                                                                        <div className='d-block pl-20'>
                                                                            {
                                                                                (record.questionType !== "THEORY" && record.questionType !== "VIVA" && record.questionType !== "CODE") &&
                                                                                <div className="page-panel">
                                                                                    <PanelGroup direction="horizontal">
                                                                                        <Panel key="left" collapsible={false} collapsedSize={10} defaultSize={34} minSize={10} maxSize={70}>
                                                                                            {
                                                                                                (record.questionType != "PREDICT OUTPUT" || record.questionType != "EXPLAIN CODE") ?
                                                                                                    <>
                                                                                                        <h4 className='text-left d-flex'>Program: </h4>
                                                                                                        {
                                                                                                            record.program && record.program.split('\n').map((line, index) => {
                                                                                                                if (line.trim() !== '') {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={index}
                                                                                                                            style={{
                                                                                                                                display: 'flex',
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span>{line}</span>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                } else {
                                                                                                                    return null;
                                                                                                                }
                                                                                                            })
                                                                                                        }
                                                                                                    </>
                                                                                                    :
                                                                                                    (record.questionType == "PREDICT OUTPUT" || record.questionType == "EXPLAIN CODE") ?
                                                                                                        <>
                                                                                                            <h4 className='text-left d-flex'>Program: </h4>
                                                                                                            {
                                                                                                                record.modifiedProgram && record.modifiedProgram.split('\n').map((line, index) => {
                                                                                                                    if (line.trim() !== '') {
                                                                                                                        return (
                                                                                                                            <li
                                                                                                                                key={index}
                                                                                                                                style={{
                                                                                                                                    display: 'flex',
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <span>{line}</span>
                                                                                                                            </li>
                                                                                                                        );
                                                                                                                    } else {
                                                                                                                        return null;
                                                                                                                    }
                                                                                                                })
                                                                                                            }
                                                                                                        </>
                                                                                                        :
                                                                                                        <div className='d-flex'>
                                                                                                            NA
                                                                                                        </div>
                                                                                            }
                                                                                        </Panel>
                                                                                        <PanelResizeHandle style={{ width: '1px', backgroundColor: '#ddd' }} />
                                                                                        <Panel key="right" collapsible={false} collapsedSize={10} defaultSize={34} minSize={10} maxSize={70}>
                                                                                            <div className='d-block' style={{ paddingLeft: '10px' }}>
                                                                                                <h4 className='text-left d-flex'>{record.questionType} </h4>
                                                                                                {
                                                                                                    record.modifiedProgram ? record.modifiedProgram.split('\n').map((line, index) => {
                                                                                                        if (line.trim() !== '') {
                                                                                                            return (
                                                                                                                <li
                                                                                                                    key={index}
                                                                                                                    style={{
                                                                                                                        display: 'flex',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span>{line}</span>
                                                                                                                </li>
                                                                                                            );
                                                                                                        } else {
                                                                                                            return null;
                                                                                                        }
                                                                                                    })
                                                                                                        :
                                                                                                        <div className='d-flex'>
                                                                                                            NA
                                                                                                        </div>
                                                                                                }
                                                                                            </div>
                                                                                        </Panel>
                                                                                    </PanelGroup>
                                                                                </div>
                                                                            }

                                                                        </div>

                                                                    </div>
                                                            }}
                                                        />
                                                        <div className='text-right mb-20 mt-20'>
                                                            <Button type='primary' onClick={handleFreezeQuestions}>Freeze Questions</Button>
                                                        </div>
                                                    </div>
                                                </TopSellerWrap>
                                            </TableDefaultStyle>
                                        </div>
                            )
                    )
            }
            <AddQuestion createQuestionModal={createQuestionModal} setCreateQuestionModal={setCreateQuestionModal} />

            <Modal
                title="Edit Question"
                open={QuestionEditModal}
                onCancel={() => { CloseEditModal() }}
                maskClosable={false}
                footer={[
                    <Button id="edit-question-button" key="EditQuestionButton" type="primary" onClick={handleEditQuestionSubmit} disabled={isUpdateQuestionDataLoading}>
                        {isUpdateQuestionDataLoading ? 'Updating...' : 'Update'}
                    </Button>,
                ]}
                width={1000}
                centered
            >
                <Form id="edit-question-form" form={form} layout="vertical">
                    <Form.Item name='questionType' label='Question Type' rules={[{ required: true, message: 'please select question type' }]}>
                        <Select placeholder='Select' allowClear onChange={(e) => { onQuestionTypeChange(e) }}>
                            {(EditQuestionRecord?.questionType == "THEORY" || EditQuestionRecord?.questionType == "VIVA") && <Option value='THEORY' key='theory'>Theory</Option>}
                            {(EditQuestionRecord?.questionType == "THEORY" || EditQuestionRecord?.questionType == "VIVA") && <Option value='VIVA' key='viva'>Viva</Option>}
                            {(EditQuestionRecord?.questionType == "CODE") && <Option value='CODE' key='code'>Code</Option>}
                            {(EditQuestionRecord?.questionType == "DEBUG CODE" || EditQuestionRecord?.questionType == "JUMBLED CODE") && <Option value='DEBUG CODE' key='error'>Debug Code</Option>}
                            {(EditQuestionRecord?.questionType == "DEBUG CODE" || EditQuestionRecord?.questionType == "JUMBLED CODE") && <Option value='JUMBLED CODE' key='shuffled'>Jumbled Code</Option>}
                            {(EditQuestionRecord?.questionType == "EXPLAIN CODE" || EditQuestionRecord?.questionType == "PREDICT OUTPUT") && <Option value='EXPLAIN CODE' key='explain'>Explain Code</Option>}
                            {(EditQuestionRecord?.questionType == "EXPLAIN CODE" || EditQuestionRecord?.questionType == "PREDICT OUTPUT") && <Option value='PREDICT OUTPUT' key='predictoutput'>Predict Output</Option>}
                        </Select>
                    </Form.Item>
                    {
                        questionType == "JUMBLED CODE" || questionType == "THEORY" || questionType == "VIVA" || questionType == "DEBUG CODE" || questionType == "EXPLAIN CODE" || questionType == "PREDICT OUTPUT" ?
                            <Form.Item name='question' label='Question' rules={[{ required: true, message: 'please enter the question' }]}>
                                <Input.TextArea placeholder='Question' rows={2} />
                            </Form.Item>
                            :
                            questionType == "CODE" &&
                            <Form.Item name='question' label='Question' rules={[{ required: true, message: 'please enter the question' }]}>
                                <div className="page-panel">
                                    <PanelGroup direction="horizontal">
                                        <Panel key="left" collapsible={false} collapsedSize={10} defaultSize={34} minSize={10} maxSize={70}>
                                            <CodeMirror
                                                theme='light'
                                                height="300px"
                                                placeholder="Write / Paste your program here..."
                                                value={code}
                                                onChange={(value) => setCode(value)}
                                                style={{ marginTop: '10px' }}
                                            />
                                            {/* <MailBox>
                                                <div className='body'>
                                                    <div className="group p-0">
                                                        <RichTextEditor
                                                            toolbarStyle={{ display: 'none' }}
                                                            editorStyle={{ lineHeight: '7px', border: '1px solid #e3e6ef', borderRadius: 6 }}
                                                            placeholder="Type here"
                                                            value={codeQuestion.value}
                                                            onChange={onCodeQuestionChange}
                                                            autoFocus
                                                            toolbarConfig={{
                                                                display: [],
                                                                INLINE_STYLE_BUTTONS: [],
                                                                BLOCK_TYPE_DROPDOWN: [],
                                                                BLOCK_TYPE_BUTTONS: [],
                                                                BLOCK_TYPE_BUTTONS: [],
                                                                BLOCK_TYPE_DROPDOWN: [],
                                                                LINK_BUTTONS: [],
                                                                BLOCK_TYPE_DROPDOWN: [],
                                                                HISTORY_BUTTONS: [],
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </MailBox> */}
                                        </Panel>
                                    </PanelGroup>
                                </div>
                            </Form.Item>
                    }
                    {
                        questionType == "JUMBLED CODE" ?
                            <Form.Item name='jumbledprogram' label='Program' rules={[{ required: true, message: 'please enter the program' }]}>
                                <div className="page-panel">
                                    <PanelGroup direction="horizontal">
                                        <Panel key="left" collapsible={false} collapsedSize={10} defaultSize={34} minSize={10} maxSize={70}>
                                            <CodeMirror
                                                theme='light'
                                                height="300px"
                                                placeholder="Type/Paste your program here..."
                                                value={code}
                                                onChange={(value) => { setCode(value); setJumbledCode(value) }}
                                                style={{ marginTop: '10px' }}
                                            />
                                        </Panel>
                                        <PanelResizeHandle style={{ width: '1px', backgroundColor: '#ddd' }} />
                                        <Panel key="right" collapsible={false} collapsedSize={10} defaultSize={34} minSize={10} maxSize={70}>
                                            <div className='d-flex align-center-v justify-content-between'>
                                                <p className='text-center m-0 ml-10'>Shuffle here</p>
                                                <Button type="primary" onClick={() => handleShuffle(jumbleRef)} style={{ marginRight: '10px' }} transparented>
                                                    <UilShuffle />
                                                </Button>
                                            </div>
                                            <div ref={jumbleRef} style={{ padding: '5px', height: '300px', overflow: 'auto' }}>
                                                {renderCodeWithLineNumbers((jumbledCode))}
                                            </div>
                                        </Panel>
                                    </PanelGroup>
                                </div>
                            </Form.Item>
                            :
                            questionType == "DEBUG CODE" ?
                                <Form.Item name='errorprogram' label='Program' rules={[{ required: true, message: 'please enter the program' }]}>
                                    <div className="page-panel">
                                        <PanelGroup direction="horizontal">
                                            <Panel key="left" collapsible={false} collapsedSize={10} defaultSize={34} minSize={10} maxSize={70}>
                                                <CodeMirror
                                                    theme='light'
                                                    height="300px"
                                                    placeholder="Type/Paste your program here..."
                                                    value={code}
                                                    onChange={(value) => { setCode(value); setErrorCode(filterBlankLines(value)) }}
                                                    style={{ marginTop: '10px' }}
                                                />
                                            </Panel>
                                            <PanelResizeHandle style={{ width: '1px', backgroundColor: '#ddd' }} />
                                            <Panel key="right" collapsible={false} collapsedSize={10} defaultSize={34} minSize={10} maxSize={70}>
                                                <div className='d-flex align-center-v justify-content-between'>
                                                    <p className='text-center m-0 ml-10'>Make an erroneous code here</p>
                                                </div>
                                                <div style={{ padding: '5px', height: '300px', overflow: 'auto', userSelect: 'none' }}>
                                                    <CodeMirror
                                                        theme='light'
                                                        height="280px"
                                                        value={errorCode}
                                                        onChange={(value) => setErrorCode(filterBlankLines(value))}
                                                        style={{ marginTop: '10px' }}
                                                    />
                                                </div>
                                            </Panel>
                                        </PanelGroup>
                                    </div>
                                </Form.Item>
                                :
                                questionType == "EXPLAIN CODE" ?
                                    <Form.Item name='explainprogram' label='Program' rules={[{ required: true, message: 'please enter the program' }]}>
                                        <CodeMirror
                                            theme='light'
                                            height="400px"
                                            placeholder="Type/Paste your program here..."
                                        />
                                    </Form.Item>
                                    :
                                    questionType == "PREDICT OUTPUT" &&
                                    <Form.Item name='predictprogram' label='Program' rules={[{ required: true, message: 'please enter the program' }]}>
                                        <CodeMirror
                                            theme='light'
                                            height="400px"
                                            placeholder="Type/Paste your program here..."
                                        />
                                    </Form.Item>
                    }

                    <Form.Item name='expectedConceptualKnowledge' label='Expected Conceptual Knowledge' rules={[{ required: false, message: 'please select expected conceptual knowledge' }]}>
                        <Input.TextArea rows={5} id="update-expectedConceptualKnowledge-text-box" placeholder="Type here" />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title={`Regenerate Questions for ${topicName}`}
                open={RegenerateQuestionsModal}
                onCancel={() => { setRegenerateQuestionsModal(false); Regenerateform.resetFields(); }}
                maskClosable={false}
                footer={[
                    <Button id="regenerate-question-button" key="regenerateQuestionButton" type="primary" onClick={handleRegenerateQuestionsSubmit} disable={isUpdateQuestionDataLoading}>
                        {isUpdateQuestionDataLoading ? 'Updating...' : 'Submit'}
                    </Button>,
                ]}
            >
                <Form id="regenerate-questions-form" form={Regenerateform} layout="vertical">
                    <Form.Item name='questionCount' label="No. of questions" rules={[{ required: true, message: 'Select no. of questions' }]}>
                        <Select id="regenerateQA_easy-count" placeholder="Select" allowClear>
                            <Option value={10}>10</Option>
                            <Option value={20}>20</Option>
                            <Option value={30}>30</Option>
                            <Option value={40}>40</Option>
                            <Option value={50}>50</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default ReviewAndGenerateQuestions;
