import actions from './actions';

const {
  COLLEGE_ADD_BEGIN,
  COLLEGE_ADD_SUCCESS,
  COLLEGE_ADD_ERR,

  COLLEGE_READ_BEGIN,
  COLLEGE_READ_SUCCESS,
  COLLEGE_READ_ERR,

  COLLEGE_UPDATE_BEGIN,
  COLLEGE_UPDATE_SUCCESS,
  COLLEGE_UPDATE_ERR,
} = actions;

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const addInitialState = {
  data: null,
  loading: false,
  error: null,
};

const updateStateSingle = {
  data: null,
  loading: false,
  error: null,
};

const CollegeReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case COLLEGE_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case COLLEGE_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case COLLEGE_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const CollegeAddReducer = (state = addInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case COLLEGE_ADD_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case COLLEGE_ADD_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case COLLEGE_ADD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const CollegeUpdateReducer = (state = updateStateSingle, action) => {
  const { type, data, err } = action;
  switch (type) {

    case COLLEGE_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case COLLEGE_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case COLLEGE_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { CollegeReducer, CollegeAddReducer, CollegeUpdateReducer };
