import React from 'react';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { Col, Row, Skeleton } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Cards } from '../../components/cards/frame/cards-frame';
import NoticeModal from './DashboardOverview/NoticeModal';
import RecentInterviews from './DashboardOverview/RecentInterviews';
import { GetRecentInterviews } from '../../redux/recentActions/actionCreator';
import SubjectWisePerformanceBarChart from './DashboardOverview/SubjectWisePerformanceBarChart';
import { GetStudentOverallSummary, SubjectWisePerformance } from '../../redux/studentPerformance/actionCreator';
import ScheduledTopics from './DashboardOverview/ScheduledTopics';
import { getStudentSchedules } from '../../redux/schedule/actionCreator';
import OverallSummary from './DashboardOverview/OverallSummary';
import ScheduledInterviews from './ScheduledInterviews';

function Dashboard() {

    const dispatch = useDispatch();

    useEffect(() => {
        // dispatch(GetRecentInterviews());
        // dispatch(SubjectWisePerformance());
        // dispatch(getStudentSchedules());
        // dispatch(GetStudentOverallSummary());
        // dispatch(studentWeeklyTopicsDataRead(regId,yrsemId,branchId));
    }, [dispatch])

    // const { studentSchedules, studentSchedulesLoading, subjectWisePerformance, subjectWisePerformanceLoading, recentInterviews, RecentInterviewsLoading, studentOverallSummary, studentOverallSummaryLoading } = useSelector((state) => {
    //     return {
    //         studentSchedules: state.studentSchedules.data,
    //         studentSchedulesLoading: state.studentSchedules.loading,
    //         recentInterviews: state.RecentInterviews.data,
    //         RecentInterviewsLoading: state.RecentInterviews.loading,
    //         subjectWisePerformance: state.subjectWisePerformance.data,
    //         subjectWisePerformanceLoading: state.subjectWisePerformance.loading,
    //         studentOverallSummary: state.studentOverallSummary.data,
    //         studentOverallSummaryLoading: state.studentOverallSummary.loading,
    //     };
    // });


    return (
        <div>
            <Main>
                <PageHeader className="ninjadash-page-header-main" title="Dashboard" />
                <div>
                    <ScheduledInterviews/>
                </div>
                {/* <div>
                    <h3 className='mt-10 add-line'>Overall Summary &nbsp;</h3>
                    {
                        studentOverallSummaryLoading ?
                            <Cards headless>
                                <Skeleton active />
                            </Cards>
                            :
                            <OverallSummary studentOverallSummary={studentOverallSummary} />
                    }
                    <div className='add-line mb-20' />
                </div> */}
                {/* <Row gutter={25}>
                    <Col md={12} xs={24}>
                        {
                            subjectWisePerformanceLoading ?
                                <Cards headless>
                                    <Skeleton active />
                                </Cards>
                                :
                                <SubjectWisePerformanceBarChart data={subjectWisePerformance} />
                        }
                    </Col>
                    <Col md={12} xs={24}>
                        {
                            studentSchedulesLoading ?
                                <Cards headless>
                                    <Skeleton active />
                                </Cards>
                                :
                                <ScheduledTopics studentSchedules={studentSchedules} />
                        }
                    </Col>
                    <Col xl={24} lg={24} md={24} xs={24}>
                        {
                            RecentInterviewsLoading ?
                                <Cards headless>
                                    <Skeleton active />
                                </Cards>
                                :
                                <RecentInterviews recentInterviews={recentInterviews} />
                        }
                    </Col>
                </Row> */}
                <NoticeModal />
            </Main>
        </div >
    );
}

export default Dashboard;