import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { getItem } from '../../utility/localStorageControl';

const {
  subjectWisePerformanceReadBegin,
  subjectWisePerformanceReadSuccess,
  subjectWisePerformanceReadErr,

  topicWisePerformanceReadBegin,
  topicWisePerformanceReadSuccess,
  topicWisePerformanceReadErr,

  subjectSummaryReadBegin,
  subjectSummaryReadSuccess,
  subjectSummaryReadErr,

  singleSubjectSummaryReadBegin,
  singleSubjectSummaryReadSuccess,
  singleSubjectSummaryReadErr,

  subjectsBestPerformerReadBegin,
  subjectsBestPerformerReadSuccess,
  subjectsBestPerformerReadErr,

  activeStudentsReadBegin,
  activeStudentsReadSuccess,
  activeStudentsReadErr,

  inactiveStudentsReadBegin,
  inactiveStudentsReadSuccess,
  inactiveStudentsReadErr,

  overallPerformanceByCohortBegin,
  overallPerformanceByCohortSuccess,
  overallPerformanceByCohortErr,

  overallSummaryBegin,
  overallSummarySuccess,
  overallSummaryErr,

  todayOverallSummaryBegin,
  todayOverallSummarySuccess,
  todayOverallSummaryErr,

  studentOverallSummaryBegin,
  studentOverallSummarySuccess,
  studentOverallSummaryErr,
} = actions;

// student
const SubjectWisePerformance = () => {
  return async (dispatch) => {
    try {
      await dispatch(subjectWisePerformanceReadBegin());
      const query = await DataService.get(`/allsubjectsperformance`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(subjectWisePerformanceReadSuccess(query.data));
      } else {
        await dispatch(subjectWisePerformanceReadSuccess(null));
      }
    } catch (err) {
      await dispatch(subjectWisePerformanceReadErr(err));
    }
  };
};

// student
const GetTopicWisePerformance = (subjectId) => {
  return async (dispatch) => {
    try {
      await dispatch(topicWisePerformanceReadBegin());
      const query = await DataService.get(`/singlesubjectperformance/${subjectId}`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(topicWisePerformanceReadSuccess(query.data));
      } else {
        await dispatch(topicWisePerformanceReadSuccess(null));
      }
    } catch (err) {
      await dispatch(topicWisePerformanceReadErr(err));
    }
  };
};

// student
const GetSubjectSummary = () => {
  return async (dispatch) => {
    try {
      await dispatch(subjectSummaryReadBegin());
      const query = await DataService.get(`/studentsummaryreportsubjectwise`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(subjectSummaryReadSuccess(query.data));
      } else {
        await dispatch(subjectSummaryReadSuccess(null));
      }
    } catch (err) {
      await dispatch(subjectSummaryReadErr(err));
    }
  };
};

// student
const GetSingleSubjectSummary = (subjectId) => {
  return async (dispatch) => {
    try {
      await dispatch(singleSubjectSummaryReadBegin());
      const query = await DataService.get(`/singlesubsummaryreport/${subjectId}`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(singleSubjectSummaryReadSuccess(query.data.payload));
      } else {
        await dispatch(singleSubjectSummaryReadSuccess(null));
      }
    } catch (err) {
      await dispatch(singleSubjectSummaryReadErr(err));
    }
  };
};

// teacher
const GetSubjectsBestPerformer = () => {
  return async (dispatch) => {
    try {
      await dispatch(subjectsBestPerformerReadBegin());
      const query = await DataService.get(`/bestperformers`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(subjectsBestPerformerReadSuccess(query.data.payload));
      } else {
        await dispatch(subjectsBestPerformerReadSuccess([]));
      }
    } catch (err) {
      await dispatch(subjectsBestPerformerReadErr(err));
    }
  };
};

// teacher
const GetActiveStudents = () => {
  return async (dispatch) => {
    try {
      await dispatch(activeStudentsReadBegin());
      const query = await DataService.get(`/active`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(activeStudentsReadSuccess(query.data.payload));
      } else {
        await dispatch(activeStudentsReadSuccess([]));
      }
    } catch (err) {
      await dispatch(activeStudentsReadErr(err));
    }
  };
};

// teacher
const GetInactiveStudents = () => {
  return async (dispatch) => {
    try {
      await dispatch(inactiveStudentsReadBegin());
      const query = await DataService.get(`/inactive`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(inactiveStudentsReadSuccess(query.data.payload));
      } else {
        await dispatch(inactiveStudentsReadSuccess([]));
      }
    } catch (err) {
      await dispatch(inactiveStudentsReadErr(err));
    }
  };
};

// teacher
const GetOverallPerformanceByCohort = () => {
  return async (dispatch) => {
    try {
      await dispatch(overallPerformanceByCohortBegin());
      const query = await DataService.get(`/levelwiseperformance`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(overallPerformanceByCohortSuccess(query.data.payload));
      } else {
        await dispatch(overallPerformanceByCohortSuccess([]));
      }
    } catch (err) {
      await dispatch(overallPerformanceByCohortErr(err));
    }
  };
};

// Admin
const GetOverallSummary = () => {
  return async (dispatch) => {
    try {
      await dispatch(overallSummaryBegin());
      const query = await DataService.get(`/overallsummary`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(overallSummarySuccess(query.data.payload));
      } else {
        await dispatch(overallSummarySuccess(null));
      }
    } catch (err) {
      await dispatch(overallSummaryErr(err));
    }
  };
};

// Admin
const GetTodayOverallSummary = () => {
  return async (dispatch) => {
    try {
      await dispatch(todayOverallSummaryBegin());
      const query = await DataService.get(`/todayoverallsummary`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(todayOverallSummarySuccess(query.data.payload));
      } else {
        await dispatch(todayOverallSummarySuccess(null));
      }
    } catch (err) {
      await dispatch(todayOverallSummaryErr(err));
    }
  };
};

// student
const GetStudentOverallSummary = () => {
  return async (dispatch) => {
    try {
      await dispatch(studentOverallSummaryBegin());
      const query = await DataService.get(`/studentoverallsummary`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(studentOverallSummarySuccess(query.data.payload));
      } else {
        await dispatch(studentOverallSummarySuccess(null));
      }
    } catch (err) {
      await dispatch(studentOverallSummaryErr(err));
    }
  };
};

export {
  SubjectWisePerformance,
  GetTopicWisePerformance,
  GetSubjectSummary,
  GetSingleSubjectSummary,
  GetSubjectsBestPerformer,
  GetActiveStudents,
  GetInactiveStudents,
  GetOverallPerformanceByCohort,
  GetOverallSummary,
  GetTodayOverallSummary,
  GetStudentOverallSummary
};