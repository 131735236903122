import React, { useEffect, useState } from 'react'
import { TableWrapper, Main } from '../styled';
import { Col, Row, Table, Tooltip } from 'antd';
import { ProjectList } from '../project/style';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PageHeader } from '../../components/page-headers/page-headers';
import { getScheduledData } from '../../redux/schedule/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../components/buttons/buttons';
import { UilVolumeUp } from '@iconscout/react-unicons'
import { evaluateWithAudio, evaluateWithoutAudio } from '../../redux/evaluate/actionCreator';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';

export default function Evaluate() {

    const dispatch = useDispatch();
    const [selectedSchedule, setSelectedSchedule] = useState(null)
    const {
        TopicScheduledData,
        isSchedulesLoading,
        EvalauateWithAudioResponse,
        isEvalutingwithAudio,
        EvalauateWithoutAudioResponse,
        isEvalutingwithoutAudio
    } = useSelector((state) => {
        return {
            TopicScheduledData: state.TopicScheduledData.data,
            isSchedulesLoading: state.TopicScheduledData.loading,
            EvalauateWithAudioResponse: state.EvalauateWithAudioResponse.data,
            isEvalutingwithAudio: state.EvalauateWithAudioResponse.loading,
            EvalauateWithoutAudioResponse: state.EvalauateWithoutAudioResponse.data,
            isEvalutingwithoutAudio: state.EvalauateWithoutAudioResponse.loading,
        }
    })

    useEffect(() => {
        loadSchedules()
    }, [dispatch]);

    const loadSchedules = () => {
        setSelectedSchedule(null)
        dispatch(getScheduledData())
    }

    const EvaluateWithAudio = (scheduleName) => {
        setSelectedSchedule(scheduleName)
        dispatch(evaluateWithAudio(scheduleName))
    }

    const EvaluateWithoutAudio = (scheduleName) => {
        setSelectedSchedule(scheduleName)
        dispatch(evaluateWithoutAudio(scheduleName))
    }

    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
        },
        {
            title: 'Interview Id',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Actions',
            render: (text, record) => (
                <div className='align-center-v'>
                    <Tooltip align={'top'} title="Convert audio and evaluate answers">
                        <Button
                            key="start-stop"
                            type="primary"
                            transparented
                            className='mr-10 d-flex align-center-v'
                            onClick={() => { EvaluateWithAudio(record.name) }}
                            disabled={record.status !== "COMPLETED" || selectedSchedule == record.name}
                        >
                            <span>Evaluate</span> &nbsp; <UilVolumeUp />
                        </Button>
                    </Tooltip>
                    <Tooltip align={'top'} title="Evaluate only answers">
                        <Button
                            key="start-stop"
                            type="primary"
                            transparented
                            className='mr-10'
                            onClick={() => { EvaluateWithoutAudio(record.name) }}
                            disabled={record.status !== "COMPLETED" || selectedSchedule == record.name}
                        >
                            Evaluate
                        </Button>
                    </Tooltip>
                </div>
            ),
        },
    ]

    const [tableData, setTableData] = useState([])
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        if (TopicScheduledData !== null && TopicScheduledData.Error === false && TopicScheduledData.payload.length !== 0) {
            const data = TopicScheduledData.payload.map((obj, index) => {
                return {
                    sno: index + 1,
                    key: index + 1,
                    id: obj.id,
                    name: obj.name,
                    topics: obj.topics?.map((topic) => `${topic.topicName}` + ' (' + `${topic.questionCount}Q` + ')').join(', '),
                    cohorts: obj.cohorts?.map((cohort) => cohort.cohortName).join(', '),
                    scheduleDate: obj.scheduleDate,
                    status: obj.status,
                    statusEle: <span className={`status ${obj.status === "STARTED" ? 'warning' : obj.status === "STOPPED" ? 'error' : obj.status === "COMPLETED" && 'Success'} `}>{obj.status}</span>,
                    startedon: obj.startedon ? new Date(obj.startedon).toLocaleString() : 'NA',
                    stoppedon: obj.stoppedon ? new Date(obj.stoppedon).toLocaleString() : 'NA',
                    completedon: obj.completedon
                }
            })

            setTableData(data)
            setFilteredData(data)
        }
        else {
            setTableData([])
            setFilteredData([])
        }
    }, [TopicScheduledData])

    return (
        <div>
            <Main>
                <PageHeader title={'Evaluate'} />
                {
                    isSchedulesLoading ?
                        <div className='spin'>
                            <CustomSpinner />
                        </div>
                        :
                        <Row gutter={25}>
                            <Col sm={24} xs={24}>
                                <Cards headless>
                                    <span className='text-danger mb-10 fontsize-12'>Note: Evaluation can be done upon completion of the interview.</span>
                                    <TableWrapper>
                                        <ProjectList>
                                            <div className="table-responsive">
                                                <Table dataSource={filteredData} columns={columns} />
                                            </div>
                                        </ProjectList>
                                    </TableWrapper>
                                </Cards>
                            </Col>
                        </Row>
                }
            </Main>
        </div>
    )
}
