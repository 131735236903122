import React, { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Dashboard from '../../container/student/Dashboard';
import Units from '../../container/student/Units';
import withStudentLayout from '../../layout/withStudentLayout';
import TopicContent from '../../container/student/TopicContent';
import CustomSpinner from '../../container/ui-elements/spinner/CustomSpinner';
import ChangePassword from '../../container/ChangePassword';
import Profile from '../../container/student/Profile';
import Subjects from '../../container/student/Subjects';
import Topics from '../../container/student/Topics';
import Interview from '../../container/student/Interview';
import AudioRecorder from '../../container/student/AudioRecorder';
import TopicWisePerformance from '../../container/student/TopicWisePerformance';
import Performance from '../../container/student/Performance';

const NotFound = lazy(() => import('../../container/pages/404'));

const Student = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="spin">
          <CustomSpinner />
        </div>
      }
    >
      <Routes>
        <Route index path="/" element={<Dashboard />} />
        {/* <Route path="/change-password" element={<ChangePassword />} /> */}        
        <Route path="/subjects/*" element={<Subjects/>} />        
        <Route path="/performance/*" element={<Performance/>} />        
        <Route path="/subjects/:subjectId/:subjectName/*" element={<Topics/>} />        
        {/* <Route path="/subjects/:subjectId/:subjectName/topic/:topicId/:topicName/interview_type/:interviewType/*" element={<Interview />} /> */}
        <Route path="/interview/:interviewName/*" element={<Interview />} />
        {/* <Route path="/subjects/:subjectId/:subjectName/topic/:topicId/:topicName/interview_type/:interviewType/*" element={<AudioRecorder />} /> */}

        <Route path="/student-profile/*" element={<Profile/>} />                
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default withStudentLayout(Student);
