import React, { useEffect, useRef, useState } from 'react'
import { Cards } from '../../components/cards/frame/cards-frame'
import { NoteCardWrap } from '../note/style'
import CustomSpinner from '../ui-elements/spinner/CustomSpinner'
import { Col, Modal, Result, Row, Table, Button, message } from 'antd'
import { Link, useParams } from 'react-router-dom'
import { DifficultyLevel, ExamInstructions, TableWrapper } from '../styled'
import { Button as ButtonComponent } from '../../components/buttons/buttons'
import { getInterviewAttemptsById, getInterviewsByTopicId } from '../../redux/interview/actionCreator'
import actions from '../../redux/interview/actions'
import { useDispatch, useSelector } from 'react-redux'
import { ProjectList } from '../project/style'
import { ActionWrapper, StepsStyle } from '../../components/steps/style'
import UilArrowRight from '@iconscout/react-unicons/icons/uil-arrow-right';
import UilArrowLeft from '@iconscout/react-unicons/icons/uil-arrow-left';
import UilEye from '@iconscout/react-unicons/icons/uil-eye';
import WaveSurfer from 'wavesurfer.js'
import FontAwesome from 'react-fontawesome';
import jsPDF from 'jspdf'
import 'jspdf-autotable';

const { getInterviewAttemptsSuccess } = actions

export default function TopicView({ customWidth, topics }) {
  const { topicName, topicId, subjectName } = useParams()
  const dispatch = useDispatch()
  const [currentTopic, setCurrentTopic] = useState(null)

  const { TopicInterviews, isTopicInterviewsLoading, interviewAttempts, isInterviewAttemptsLoading } = useSelector((state) => {
    return {
      TopicInterviews: state.TopicInterviews.data,
      isTopicInterviewsLoading: state.TopicInterviews.loading,
      interviewAttempts: state.interviewAttempts.data,
      isInterviewAttemptsLoading: state.interviewAttempts.loading,
    };
  });

  useEffect(() => {
    const selectedTopic = topics.length !== 0 ? topics.filter((topic) => topic.id === parseInt(topicId))[0] : null
    setCurrentTopic(selectedTopic)
    if (selectedTopic !== null && selectedTopic.topicstatus === "QUESTIONS FREEZED") {
      // sending 0 as cohort id in student role
      dispatch(getInterviewsByTopicId(topicId, 0))
    }

  }, [topicId])

  const HandleTakeInterview = () => {
    if ((currentTopic !== null && currentTopic.deadline !== "" && new Date(currentTopic.deadline) < new Date())) {
      message.error("The deadline for this topic has passed.")
    }
    else {
      setShowInstructions(true)
    }
  }

  useEffect(() => {
    if (dispatch) {
      dispatch(getInterviewAttemptsSuccess([]))
      setFilteredData([])
      setModalVisisble(false)
      setQuestionIndex(0)
      setRowData(null)
      setShowInstructions(false)
    }
  }, []);

  const [filteredData, setFilteredData] = useState([])
  const [modalVisisble, setModalVisisble] = useState(false)
  const [rowData, setRowData] = useState(null)
  const [questionIndex, setQuestionIndex] = useState(0)
  const waveSurferRef = useRef(null);
  const [showInstructions, setShowInstructions] = useState(false)

  useEffect(() => {
    if (TopicInterviews) {
      const data = TopicInterviews.sort((a, b) => a.starttime < b.starttime ? 1 : -1).map((obj, index) => ({
        sno: index + 1,
        key: index + 1,
        id: obj.id,
        difficultylevel: obj.interviewlevel,
        interviewStatus: obj.interviewstatus,
        questionsattempted: obj.questionsattempted,
        attemptedon: obj.starttime && new Date(obj.starttime).toLocaleString(),
        avgscore: obj.avgscore === -1 ? 0 : obj.avgscore,
      }))
      setFilteredData(data)
    }
  }, [TopicInterviews])

  const columns = [
    {
      title: 'S.No.',
      dataIndex: 'sno',
      key: 'sno',
    },
    {
      title: 'Difficulty Level',
      dataIndex: 'difficultylevel',
      key: 'difficultylevel',
    },
    {
      title: 'Answered',
      dataIndex: 'questionsattempted',
      key: 'questionsattempted',
    },
    {
      title: 'Avg Score',
      dataIndex: 'avgscore',
      key: 'avgscore',
    },
    {
      title: 'Attempted On',
      dataIndex: 'attemptedon',
      key: 'attemptedon',
    },
    {
      title: 'Status',
      dataIndex: 'interviewStatus',
      key: 'interviewStatus',
    },
    {
      title: 'Report',
      render: (_, row) => (
        <div>
          {
            row.interviewStatus === "EVALUATING" ?
              <div className='text-danger'>Evaluating</div>
              :
              <Button size='small' type='primary' disabled={row.interviewStatus === "STARTED" || row.interviewStatus === "INCOMPLETE"} onClick={() => { setModalVisisble(true); dispatch(getInterviewAttemptsById(row.id)); setRowData(row); setQuestionIndex(0) }} ><UilEye/></Button>
          }
        </div>
      )
    },
  ];

  const handlePrevQuestion = () => {
    if (questionIndex + 1 !== 0) {
      setQuestionIndex(questionIndex - 1);
    }
  }

  const handleNextQuestion = () => {
    if (questionIndex + 1 < interviewAttempts.length) {
      setQuestionIndex(questionIndex + 1);
    }
  }

  const AudioPlayer = ({ audioUrl }) => {

    useEffect(() => {
      // Initialize WaveSurfer when the component mounts
      waveSurferRef.current = WaveSurfer.create({
        container: '#waveform', // Use a div with the id 'waveform' as the container
        waveColor: 'rgb(230, 98, 58)',
        progressColor: 'rgb(150, 44, 12)',
        barWidth: 2,
        barGap: 1,
        barRadius: 2,
      });

      // Add event listener for the finish event
      waveSurferRef.current.on('finish', () => {
        setIsPlaying(false);
      });

      return () => {
        // Clean up when the component unmounts
        waveSurferRef.current.destroy();
        setIsPlaying(false)
      };
    }, []);

    useEffect(() => {
      // Load the new audio URL when it changes
      if (audioUrl) {
        waveSurferRef.current.load(process.env.REACT_APP_API_ENDPOINT + audioUrl);
      }
    }, [audioUrl]);

    const [isPlaying, setIsPlaying] = useState(false)
    const handlePlayPause = () => {
      console.log(waveSurferRef.current)
      if (waveSurferRef.current) {
        if (waveSurferRef.current.isPlaying()) {
          waveSurferRef.current.pause();
          setIsPlaying(false);
        } else {
          waveSurferRef.current.play();
          setIsPlaying(true);
        }
      } else {
        console.error('WaveSurfer instance is not initialized.');
        // Handle the error or display a message to the user
      }
    };

    return <div className='text-center'>
      <div id="waveform" />
      <Button onClick={handlePlayPause} className='mt-20 icon-btn' type='link'>
        {isPlaying ? <FontAwesome name="pause" style={{ fontSize: '22px', background: '#eee', padding: '6px 18px', borderRadius: '6px' }} /> : <FontAwesome name="play" style={{ fontSize: '22px', background: '#eee', padding: '6px 18px', borderRadius: '6px' }} />}
      </Button>
    </div>;
  };

  const generatePDF = () => {
    const now = new Date();
    const doc = new jsPDF('landscape');
    doc.setFont('Arial');
    doc.setFontSize(12);
    let startY = 35;
    let isFirstPage = true;
    let totalPagesExp = '{total_pages_count_string}';

    const data = interviewAttempts.map((obj, index) => [
      index + 1,
      obj.question.question,
      obj.answer || 'NA',
      (obj.evaluationfeedback1 || 'NA') + "\n\nScore: " + obj.evaluationscore1 || 'NA',
      // obj.evaluationscore1,
      (obj.evaluationfeedback2 || 'NA') + "\n\nScore: " + obj.evaluationscore2 || 'NA',
      // obj.evaluationscore2,
      obj.avgscore || 0
    ]);

    doc.autoTable({
      head: [['#', 'Question', "Student Answer", 'Evaluation Feedback 1', 'Evaluation Feedback 2', 'Avg Score']],
      body: data,
      startY: startY,
      theme: 'grid',
      columnStyles: {
        0: { cellWidth: 8 },
        1: { cellWidth: 50 },
        2: { cellWidth: 80 },
        3: { cellWidth: 53 },
        // 4: { cellWidth: 22 },
        4: { cellWidth: 53 },
        // 6: { cellWidth: 22 },
        5: { cellWidth: 25 },
      },

      didDrawPage: function (data) {
        const pageWidth = doc.internal.pageSize.width;

        if (isFirstPage) {
          doc.setFontSize(12);                    
          doc.text(`Subject: ${subjectName}`, startY, 10);
          doc.text(`Topic: ${topicName}`, pageWidth - startY, 10, { align: 'right' });
          doc.text(`Difficulty Level: ${rowData?.difficultylevel}`, startY, 22);
          doc.text(`Avg Score: ${rowData?.avgscore}`, pageWidth - startY, 22, { align: 'right' });
          doc.text(``, pageWidth - startY, 35);
          isFirstPage = false;
        }

        if (isFirstPage) {
          startY = 35
          data.settings.margin.top = startY;
        } else {
          startY = 10;
          data.settings.margin.top = startY;
        }
        var str = 'Page ' + doc.internal.getNumberOfPages();
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' of ' + totalPagesExp;
        }
        doc.setFontSize(10);
        const pageHeight = doc.internal.pageSize.height ? doc.internal.pageSize.height : doc.internal.pageSize.getHeight();
        const centerPos = pageWidth / 2;
        const textWidth = (doc.getStringUnitWidth('Interview taken on: ' + rowData.attemptedon) * doc.internal.getFontSize() / doc.internal.scaleFactor) - 50;
        const xPos = centerPos - textWidth / 2;
        doc.text(`InterviewSystem`, startY, pageHeight - 10);
        doc.text('Interview taken on: ' + rowData.attemptedon, xPos, pageHeight - 10, { align: 'center' });
        doc.text(str, pageWidth - startY + 20, pageHeight - 10, { align: 'right' });

      },
    });
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp)
    }
    console.log(rowData)
    const topicname = topicName?.replace(/\s/g, "_");
    doc.save(`${topicname}_${rowData?.difficultylevel}_answers.pdf`);
  };

  return (
    <Cards headless>
      <h2 className='mb-20 text-center'>{topicName}</h2>
      <NoteCardWrap style={customWidth}>
        {
          currentTopic !== null && currentTopic.topicstatus !== "QUESTIONS FREEZED" ?
            <div>
              <Result
                title="Topic not freezed yet"
                status={'warning'}
              />
            </div>
            :
            showInstructions ?
              <div>
                <div className='d-flex justify-content-end'>
                  <Button type='primary' className='mb-20' onClick={() => setShowInstructions(false)}>Back</Button>
                </div>
                <ExamInstructions>
                  <h4 className='title'>Instructions</h4>
                  <ul>
                    <li>Check Internet Connectivity</li>
                    <span>
                      Ensure that you have a stable and reliable internet connection before starting the
                      interview. A strong connection will help ensure smooth communication and minimize
                      disruptions during the interview process.
                    </span>
                    <li>Check Your Microphone</li>
                    <span>
                      Test your microphone in advance to verify that it is functioning properly. Clear audio is crucial for
                      effective communication during the interview. You can use built-in system tools or
                      external applications to check and adjust your microphone settings.
                    </span>
                    <li>Choose a Quiet Environment</li>
                    <span>
                      Find a quiet and well-lit space for the interview. Minimize background noise and distractions to create an environment conducive to focused communication.
                      Inform others in your household about the interview to reduce interruptions.
                    </span>
                    <li>Charge Your Device</li>
                    <span>
                      If you are using a laptop or a portable device for the interview, make sure it is fully charged or connected to a power source.
                      This ensures that your device doesn't run out of power during the interview.
                    </span>
                  </ul>
                </ExamInstructions>
                <DifficultyLevel>
                  <div>
                    <h4 className='title'>Choose difficulty level</h4>
                    <div className='buttons'>
                      <div className='level-duration'>
                        <Button type="link">
                          <Link to="interview_type/Easy">Easy </Link>
                        </Button>
                        <span className='duration'>(15 Mins)</span>
                      </div>

                      <div className='level-duration'>
                        <Button type="link">
                          <Link to="interview_type/Intermediate">Intermediate</Link>
                        </Button>
                        <span className='duration'>(20 Mins)</span>
                      </div>
                      <div className='level-duration'>
                        <Button type="link">
                          <Link to="interview_type/Advanced">Advanced</Link>
                        </Button>
                        <span className='duration'>(30 Mins)</span>
                      </div>
                    </div>
                  </div>
                </DifficultyLevel>
              </div>
              :
              isTopicInterviewsLoading ?
                <div className="custom-spin">
                  <CustomSpinner />
                </div>
                :
                <div>
                  <div className='d-flex justify-content-end'>
                    <ButtonComponent type='primary' transparented className='mb-20 mr-5' onClick={() => dispatch(getInterviewsByTopicId(topicId, 0))}>Refresh</ButtonComponent>
                    <Button type='primary' className='mb-20' onClick={() => HandleTakeInterview()} disabled={currentTopic !== null && currentTopic.deadline !== "" && new Date(currentTopic.deadline) < new Date()}>Take a New Interview</Button>
                  </div>
                  <TableWrapper>
                    <ProjectList>
                      <div className="table-responsive">
                        <Table size='large' dataSource={filteredData} columns={columns} />
                      </div>
                    </ProjectList>
                  </TableWrapper>
                </div>

        }

        <Modal
          open={modalVisisble}
          title={`Attempt`}
          width={1200}
          centered
          maskClosable={false}
          footer={false}
          onCancel={() => { setModalVisisble(false) }}
        >
          {
            isInterviewAttemptsLoading ?
              <div className="spin">
                <CustomSpinner Message={'Loading... Please wait.'} />
              </div>
              :
              interviewAttempts.length === 0 ?
                <div>
                  <Result
                    title="Attempts not found!"
                    status="warning"
                  />
                </div>
                :
                interviewAttempts.length !== 0 &&
                <div>
                  <div>
                    <div className='mb-10 d-flex justify-content-between'>
                      <h3>Submitted Q & A</h3>
                      <div>
                        Avg score: {interviewAttempts[0].interview.avgscore}
                        <Button type={'primary'} onClick={() => { generatePDF() }} style={{ marginLeft: '15px' }}>Download PDF</Button>
                      </div>
                    </div>
                    <Row gutter={25}>
                      <Col xxl={{ span: 22, offset: 1 }} xl={{ span: 22, offset: 1 }} lg={{ span: 22, offset: 1 }} md={{ span: 22, offset: 1 }} xs={{ span: 22, offset: 1 }}>
                        <div>
                          <StepsStyle current={questionIndex} size="small">
                            {interviewAttempts.map((question, index) => (
                              <StepsStyle.Step key={index} />
                            ))}
                          </StepsStyle>
                          <div className={'mt-30'} style={{ textAlign: 'left' }}>
                            <div style={{ marginTop: '15px', marginBottom: '10px' }} className='d-flex justify-content-between align-center-v'>
                              <h3>{questionIndex + 1}. {interviewAttempts[questionIndex].question?.question}</h3>
                              <span className='status'>{interviewAttempts[questionIndex].status}</span>
                            </div>
                            <div>
                              <p>Ans: {interviewAttempts[questionIndex].answer}</p>

                              {
                                interviewAttempts[questionIndex].status === "ATTEMPTED" &&
                                <AudioPlayer audioUrl={interviewAttempts[questionIndex].audiolink} />
                              }
                            </div>
                            <div style={{ marginTop: '30px' }}>
                              <h4 className='mt-10'>Evaluation Feedback-1 | {interviewAttempts[questionIndex].evaluationfeedback1 !== null && "Score: " + interviewAttempts[questionIndex].evaluationscore1}</h4>
                              <p style={{ textAlign: 'justify', paddingBottom: '15px' }} className='m-0'>
                                {
                                  interviewAttempts[questionIndex].evaluationfeedback1 === null ?
                                    'NA'
                                    :
                                    interviewAttempts[questionIndex].evaluationfeedback1
                                }
                              </p>
                              <h4 className='mt-10'>Evaluation Feedback-2 | {interviewAttempts[questionIndex].evaluationfeedback2 !== null && "Score: " + interviewAttempts[questionIndex].evaluationscore2}</h4>
                              <p style={{ textAlign: 'justify', paddingBottom: '15px' }} className='m-0'>
                                {interviewAttempts[questionIndex].evaluationfeedback2 === null ? 'NA' : interviewAttempts[questionIndex].evaluationfeedback2}
                              </p>
                            </div>
                            <div>Question Average score {interviewAttempts[questionIndex].avgscore}</div>
                          </div>
                        </div>
                        <ActionWrapper>
                          <div className="step-action-wrap pb-20">
                            <div className="step-action-inner">
                              <div className="steps-action">

                                <Button className="btn-prev" type="white" outlined style={{ color: '#555', marginRight: '10px' }} onClick={handlePrevQuestion} disabled={questionIndex === 0}>
                                  <UilArrowLeft />
                                  Previous
                                </Button>
                                {questionIndex < (interviewAttempts.length !== 0 && interviewAttempts.length - 1) && (
                                  <Button
                                    className="btn-next"
                                    type="primary"
                                    onClick={handleNextQuestion}
                                  >
                                    Next
                                    <UilArrowRight />
                                  </Button>
                                )}

                              </div>
                            </div>
                          </div>
                        </ActionWrapper>
                      </Col>
                    </Row>
                  </div>
                </div>
          }
        </Modal>
      </NoteCardWrap>
    </Cards>
  )
}
