import React from 'react';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Result } from 'antd';
import { Button } from '../../components/buttons/buttons';
import { AddUser } from './styles/tablestyle';
import TextUpload from './TextUpload';
import FileUpload from './FileUpload';
import TopicStatusId from './TopicStatusId';


function UploadContent({ topicStatus, topic }) {

  const { subjectId, unitId, topicId } = useParams()

  const navigate = useNavigate()


  return (
    <>
      <AddUser>
        {
          topicStatus === "NOTHING" ?
            <div>
              {/* <ul className="d-flex justify-content-around pb-20">
                <li>
                  <NavLink to="file">
                    Upload Files
                  </NavLink>
                </li>
                <li>
                  <NavLink to="text">
                    Write Text
                  </NavLink>
                </li>
              </ul> */}
              <Routes>
                <Route path="/" element={<Navigate to={`file`} replace={true} />} />
                <Route path="file" element={<FileUpload topicStatus={topicStatus} />} />
                <Route index path="text" element={<TextUpload topicStatus={topicStatus} />} />
              </Routes>
            </div>
            :
            TopicStatusId(topicStatus) === 1 ?
              <Result
                status="success"
                title="Content Uploaded"
                extra={
                  <Button id="uploadcontent_reviewcontent-button" type="primary" key="navigateToReviewContent" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/review/content`)}>
                    Review
                  </Button>
                }
              />
              :
              TopicStatusId(topicStatus) == 2 ?
                <Result
                  status="warning"
                  title="Content reviewed successfully"
                  extra={
                    <Button id="uploadcontent_generateqa-button" type="primary" key="generateqa" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/generate/questions`)}>
                      Generate Q & A
                    </Button>
                  }
                />
                :
                TopicStatusId(topicStatus) == 3 || TopicStatusId(topicStatus) == 4 ?
                  <Result
                    status="warning"
                    title="Questions and Answers generated"
                    extra={
                      <Button id="uploadcontent_reviewqa-button" type="primary" key="reviewqa" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/review/questions`)}>
                        Review Q & A
                      </Button>
                    }
                  />
                  :
                  TopicStatusId(topicStatus) == 5 || TopicStatusId(topicStatus) == 6 ?
                    <Result
                      status="warning"
                      title="MCQS has been generated for this topic"
                      extra={
                        <Button id="uploadcontent_reviewmcq-button" type="primary" key="reviewQuiz" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/review/quiz`)}>
                          Review MCQS
                        </Button>
                      }
                    />
                    :
                    TopicStatusId(topicStatus) === 7 ?
                      <div>
                        <Result
                          status='success'
                          title="Programs Verified"
                          extra={
                            <Button id="uploadcontent_freezetopic-button" type="primary" key="freeze topic" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/freezeschedule`)}>
                              Freeze Topic
                            </Button>
                          }
                        />
                      </div>
                      :
                      topicStatus === "CONTENT FREEZED" &&
                      <Result
                        status="success"
                        title="Content Freezed"
                        subTitle={`on ${topic !== null && new Date(topic.updatedon).toLocaleString()}`}
                      />

        }
      </AddUser>
    </>
  );
}


export default UploadContent;
