import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getStudentSchedules } from '../../redux/schedule/actionCreator';
import { Button, Col, Row, Skeleton, Modal, Tooltip } from 'antd';
import { Cards } from '../../components/cards/frame/cards-frame';
import { ProductCard } from './styles/dashboardStyle';
import Heading from '../../components/heading/heading';
import { useNavigate } from 'react-router-dom';
import { ExamInstructions } from '../styled';
import { UilListUl } from '@iconscout/react-unicons'

export default function ScheduledInterviews() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { studentSchedules, studentSchedulesLoading } = useSelector((state) => {
        return {
            studentSchedules: state.studentSchedules.data,
            studentSchedulesLoading: state.studentSchedules.loading,
        };
    });

    useEffect(() => {
        loadSchedules()
        setShowInstrcutions(true)
    }, []);

    const loadSchedules = () => {
        dispatch(getStudentSchedules());
    }

    const [showInstrctions, setShowInstrcutions] = useState(false)

    return (
        <div className='w-lg-75 m-auto'>
            <div className='d-flex justify-content-end'>
                <Tooltip title="Guidelines" placement='top'>
                    <Button type='link' className='mr-10' onClick={() => setShowInstrcutions(true)}><UilListUl /></Button>
                </Tooltip>
                <Button type='primary' onClick={loadSchedules}>Refresh</Button>
            </div>
            <div className='mt-25'>
                {
                    studentSchedulesLoading ?
                        <Cards headless>
                            <Skeleton active />
                        </Cards>
                        :
                        <div>
                            {
                                studentSchedules.length !== 0 && studentSchedules.map((schedule, scheduleIndex) =>
                                    <ProductCard className="list-view" key={scheduleIndex} style={{ marginBottom: 20 }}>
                                        <div className="product-list">
                                            <Row gutter={15}>
                                                <Col md={6} xs={24}>
                                                    <figure>
                                                        <img style={{ width: '100%' }} src={require(`../../static/img/icon/dev_focus.svg`).default} alt="" />
                                                    </figure>
                                                </Col>
                                                <Col md={12} xs={24}>
                                                    <div className="product-single-description">
                                                        <Heading className="product-single-title" as="h5">
                                                            {schedule.name}
                                                        </Heading>
                                                        <div>
                                                            <ul style={{ listStyle: 'inherit', listStylePosition: 'inside' }}>
                                                                {
                                                                    schedule.topics?.map((topic, index) =>
                                                                        <li key={index}>
                                                                            {topic.topicName}
                                                                        </li>
                                                                    )
                                                                }

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={6} xs={24} className='d-flex align-center-v'>
                                                    <div className="product-single-action w-100 m-0">
                                                        {
                                                            (schedule.status === "STARTED" && schedule.interviewFlag === false) ?
                                                                <Button type="primary" onClick={() => navigate(`interview/${schedule.name}`)}>
                                                                    Take Interview
                                                                </Button>
                                                                :
                                                                schedule.interviewFlag === true ?
                                                                    <h4 className='m-0'>Interview Taken</h4>
                                                                    :
                                                                    <h4 className='m-0'>Not Started</h4>
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </ProductCard>
                                )
                            }
                        </div>
                }
            </div>
            <Modal
                title="Guidelines for the Interview"
                open={showInstrctions}
                onCancel={() => { setShowInstrcutions(false) }}
                footer={null}
                width={800}
            >
                <div>
                    <ExamInstructions className='m-0 p-0'>
                        <ul>
                            {/* <li className='m-0'>Check Internet Connectivity</li>
                            <span>
                                Ensure that you have a stable and reliable internet connection before starting the
                                interview. A strong connection will help ensure smooth communication and minimize
                                disruptions during the interview process.
                            </span> */}
                            <li className='m-0'>Check Power Source</li>
                            <span>
                                Ensure that the power cable is securely connected to the system and the power outlet.
                            </span>
                            <li>Choose a Quiet Environment</li>
                            <span>
                                Find a quiet and well-lit space for the interview. Minimize background noise and distractions to create an environment conducive to focused communication.
                                Inform others in your household about the interview to reduce interruptions.
                            </span>
                            <li>Check Your Microphone</li>
                            <span>
                                Test your microphone in advance to verify that it is functioning properly. Clear audio is crucial for
                                effective communication during the interview. You can use built-in system tools or
                                external applications to check and adjust your microphone settings.
                            </span>
                            <li>
                                Once you've passed/skipped a question, you won't be able to return to it later.
                            </li>
                            <li>To Answer Viva Question</li>
                            <span>
                                Initiate the recording by clicking on the microphone icon labeled 'Start Answering' located at the top-right corner.
                                Once clicked, begin speaking. To conclude your response, click on the microphone icon again, labeled 'Stop Answering', to halt the recording.
                                Finally, save your recorded response by clicking on the save icon
                            </span>
                            <li>To Answer Code Question</li>
                            <span>
                                Provide the missing code snippet or complete program. Afterward, save your answer by clicking on the save icon.
                                You can resize the panels (left, right)<br/>
                                Note: <h4 className='d-inline'>Ctrl + S</h4> will not save your answer.
                            </span>
                        </ul>
                    </ExamInstructions>
                </div>
            </Modal>
        </div>
    )
}
