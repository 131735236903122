import React, { useState } from 'react'
import { Main } from '../styled'
import { useParams } from 'react-router-dom'
import { Cards } from '../../components/cards/frame/cards-frame';
import { NoteCardWrap } from '../note/style';
import MicTest from './MicTest';
import InterviewQuestions from './InterviewQuestions';

export default function Interview() {
    const { interviewName } = useParams()

    const [micTested, setMicTested] = useState(false)
    const [interviewStarted, setInterviewStarted] = useState(false)

    const startInterview = () => {
        setInterviewStarted(true);
        setMicTested(false);
    }

    return (
        <div>
            <Main>
                <div className='back-button'>
                </div>
                <Cards headless className='mt-15'>
                    <NoteCardWrap className='text-center'>
                        <div className='d-flex justify-content-center mb-20'>
                            <h3 className='fontsize-sm-14'>{interviewName}</h3>
                        </div>
                        {
                            !interviewStarted &&
                            <div>
                                <MicTest micTested={micTested} setMicTested={setMicTested} startInterview={startInterview} />
                            </div>
                        }

                        {
                            interviewStarted &&
                            <div className="">
                                <div>
                                    <InterviewQuestions />
                                </div>
                            </div>
                        }
                    </NoteCardWrap>
                </Cards>
            </Main>
        </div>
    )
}
