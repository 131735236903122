import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Input, Modal, Form, Select, Tag } from 'antd';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import { Cards } from '../../components/cards/frame/cards-frame';
import { TableWrapper, Main } from '../styled';
import { AutoComplete } from '../../components/autoComplete/autoComplete';
import { Button } from '../../components/buttons/buttons';
import { ProjectList, ProjectSorting } from '../project/style';
import { PageHeader } from '../../components/page-headers/page-headers';
import { useDispatch, useSelector } from 'react-redux';
import { collegeDataRead, collegeDataSubmit, collegeDataUpdate } from '../../redux/Colleges/actionCreator';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';

function Colleges() {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  const [editRecord, setEditRecord] = useState(null);
  const [searchText, setsearchText] = useState(null);

  const dispatch = useDispatch();
  const { colleges, isLoading, CollegeUpdateRes, isCollegeUpdating, CollegeAddRes, isCollegeAdding } = useSelector((state) => {
    return {
      colleges: state.colleges.data,
      isLoading: state.colleges.loading,
      CollegeUpdateRes: state.collegeUpdateRes.data,
      isCollegeUpdating: state.collegeUpdateRes.loading,
      CollegeAddRes: state.collegeAddRes.data,
      isCollegeAdding: state.collegeAddRes.loading,
    };
  });

  useEffect(() => {
    if (dispatch) {
      dispatch(collegeDataRead());
    }
  }, [dispatch]);

  const [dataSource, setDataSource] = useState([]);
  const [GoodData, setGoodData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (CollegeUpdateRes && CollegeUpdateRes.Error === false) {
      dispatch(collegeDataRead());
    }
  }, [CollegeUpdateRes])

  useEffect(() => {
    if (CollegeAddRes && CollegeAddRes.Error === false) {
      dispatch(collegeDataRead());
    }
  }, [CollegeAddRes])

  useEffect(() => {
    if (colleges) {
      const filteredData = colleges.sort((a, b) => a.updatedon < b.updatedon ? 1 : -1).map((item, index) => ({
        key: item.id,
        sno: index + 1,
        id: item.id,
        code: item.code,
        name: item.name,
        updatedon: new Date(item.updatedon).toLocaleDateString('en-GB'),
        createdon: new Date(item.createdon).toLocaleDateString('en-GB'),
        updatedby: item.updatedby !== null ? item.updatedby : '--------',
      }));
      setDataSource(filteredData);
      setGoodData(filteredData);
      setLoading(false);
    }
  }, [colleges])

  const checkSearch = (i) => {
    for (let j in i) {
      if (i[j].toString().toLowerCase().includes(searchText.toString().toLowerCase())) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    let tempValues = [...GoodData];
    if (searchText != null) {
      tempValues = tempValues.filter(checkSearch);
    }
    setDataSource(tempValues);
  }, [searchText])

  const handleSearch = (e) => {
    setsearchText(e)
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'sno',
      key: 'sno',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => a.code > b.code ? 1 : -1,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name > b.name ? 1 : -1,
    },
    {
      title: 'Created On',
      dataIndex: 'createdon',
      key: 'createdon',
    },
    {
      title: 'Updated On',
      dataIndex: 'updatedon',
      key: 'updatedon',
    },
    {
      title: 'Updated By',
      dataIndex: 'updatedby',
      key: 'updatedby',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <div className='table-actions'>
          <Button key="101" onClick={() => handleEdit(record)} className="btn-icon " type="primary" to="#" shape="circle">
            <UilEdit />
          </Button>
        </div>
      ),
    },
  ];

  const handleAddButtonClick = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleModalAdd = () => {
    form.validateFields().then((values) => {
      const newRow = {
        code: values.code,
        name: values.name,
      };
      form.resetFields();
      setIsModalVisible(false);
      dispatch(collegeDataSubmit(newRow));
    })
      .catch((error) => {
        console.error('Error while validating form:', error);
      });
  };

  const handleModalUpdate = () => {
    editForm.validateFields().then((values) => {
      const updatedRecord = {
        id: editRecord.id,
        code: values.code,
        name: values.name,
      };
      setIsEditModalVisible(false);
      editForm.resetFields();
      dispatch(collegeDataUpdate(updatedRecord));
    })
      .catch((error) => {
        console.error('Error while validating form:', error);
      });
  };

  const handleEdit = (record) => {
    setEditRecord(record);
    setIsEditModalVisible(true);
    editForm.setFieldsValue({
      code: record.code,
      name: record.name,
    });
  };

  return (
    <>
      <PageHeader className="ninjadash-page-header-main" title="Colleges"
        buttons={[
          <Button onClick={() => { handleAddButtonClick() }} key="AddCollege" type="primary" size="default">
            Add College
          </Button>,
        ]} />
      <Main>
        {isLoading || loading || isCollegeAdding || isCollegeUpdating ?
          (
            <div className="spin">
              <CustomSpinner Message={'Loading please wait...'} />
            </div>
          ) : (
            <Row gutter={25}>
              <Col sm={24} xs={24}>                
                <Cards headless>
                  <TableWrapper>
                    <ProjectList>
                      <div className='d-flex justify-content-between mb-15 p-0 align-center-v'>
                        <div className="d-flex align-center-v" style={{ marginLeft: 'auto' }}>
                          <AutoComplete id="college-page-search-filter" onSearch={handleSearch} dataSource={dataSource} placeholder="Search here" patterns />
                        </div>
                      </div>
                      <div className="table-responsive">
                        <Table dataSource={dataSource} columns={columns} />
                      </div>
                    </ProjectList>
                  </TableWrapper>
                </Cards>
              </Col>
            </Row>
          )}
      </Main>
      <Modal
        title="Add College"
        open={isModalVisible}
        onCancel={handleModalCancel}
        maskClosable={false}
        centered
        footer={[
          <Button key="AddCollegeRecord" type="primary" onClick={handleModalAdd}>
            Add
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="code" label="Code" rules={[{ required: true, message: "Please enter college code" }]}>
            <Input placeholder='Enter college code' />
          </Form.Item>
          <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter college name" }]}>
            <Input placeholder='Enter college name' />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit College"
        open={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        maskClosable={false}
        centered
        footer={[
          <Button key="UpdateCollegeRecord" type="primary" onClick={handleModalUpdate}>
            Update
          </Button>
        ]}
      >
        <Form form={editForm} layout="vertical">
          <Form.Item name="code" label="Code" rules={[{ required: true, message: "Please enter college code" }]}>
            <Input placeholder='Enter college code' />
          </Form.Item>
          <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter college name" }]}>
            <Input placeholder='Enter college name' />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Colleges;