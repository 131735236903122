const actions = {
  TOPICSADMIN_ADD_BEGIN: 'TOPICSADMIN_ADD_BEGIN',
  TOPICSADMIN_ADD_SUCCESS: 'TOPICSADMIN_ADD_SUCCESS',
  TOPICSADMIN_ADD_ERR: 'TOPICSADMIN_ADD_ERR',

  TOPICSADMIN_READ_BEGIN: 'TOPICSADMIN_READ_BEGIN',
  TOPICSADMIN_READ_SUCCESS: 'TOPICSADMIN_READ_SUCCESS',
  TOPICSADMIN_READ_ERR: 'TOPICSADMIN_READ_ERR',

  TOPICSADMIN_UPDATE_BEGIN: 'TOPICSADMIN_UPDATE_BEGIN',
  TOPICSADMIN_UPDATE_SUCCESS: 'TOPICSADMIN_UPDATE_SUCCESS',
  TOPICSADMIN_UPDATE_ERR: 'TOPICSADMIN_UPDATE_ERR',

  topicsadminAddBegin: () => {
    return {
      type: actions.TOPICSADMIN_ADD_BEGIN,
    };
  },

  topicsadminAddSuccess: (data) => {
    return {
      type: actions.TOPICSADMIN_ADD_SUCCESS,
      data,
    };
  },

  topicsadminAddErr: (err) => {
    return {
      type: actions.TOPICSADMIN_ADD_ERR,
      err,
    };
  },

  topicsadminReadBegin: () => {
    return {
      type: actions.TOPICSADMIN_READ_BEGIN,
    };
  },

  topicsadminReadSuccess: (data) => {
    return {
      type: actions.TOPICSADMIN_READ_SUCCESS,
      data,
    };
  },

  topicsadminReadErr: (err) => {
    return {
      type: actions.TOPICSADMIN_READ_ERR,
      err,
    };
  },

  topicsadminUpdateBegin: () => {
    return {
      type: actions.TOPICSADMIN_UPDATE_BEGIN,
    };
  },

  topicsadminUpdateSuccess: (data) => {
    return {
      type: actions.TOPICSADMIN_UPDATE_SUCCESS,
      data,
    };
  },

  topicsadminUpdateErr: (err) => {
    return {
      type: actions.TOPICSADMIN_UPDATE_ERR,
      err,
    };
  },
};

export default actions;
