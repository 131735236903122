import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../components/page-headers/page-headers';
import { BasicFormWrapper, Main } from '../styled';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Checkbox, Col, Form, Input, Modal, Pagination, Radio, Result, Row, Select, Tag, Tooltip, message, notification } from 'antd';
import Heading from '../../components/heading/heading';
import TextArea from 'antd/lib/input/TextArea';
import { WizardWrapper, WizardFour } from './styles/wizardstyle';
import { Steps } from '../../components/steps/steps';
import { ProjectSorting } from '../project/style';
import { Cards } from '../../components/cards/frame/cards-frame';
import { SelectWrapperStyle } from '../ui-elements/ui-elements-styled';
import { EvaluateAnswer, QADataUpdate, deleteQA, generateQuestionsDataRead } from '../../redux/generateQuestions/actionCreator';
import actions from '../../redux/generateQuestions/actions';
import { NoteCardWrap } from '../note/style';
import { EyeOutlined } from '@ant-design/icons';
import { getTopicById, FreezeTopicById } from '../../redux/topics/actionCreator';
import { Button } from '../../components/buttons/buttons';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { alertModal } from '../../components/modals/antd-modals';
import { theme } from '../../config/theme/themeVariables';
import UilTrashAlt from '@iconscout/react-unicons/icons/uil-trash-alt'

import TopicStatusActions from '../../redux/topics/actions';
import TopicStatusId from './TopicStatusId';
import QAactions from '../../redux/generateQuestions/actions';

const { QAUpdateSuccess, deleteQASuccess, answerEvaluateSuccess } = QAactions

const { Option } = Select;

const { generateQuestionsPostSuccess } = actions;
const { topicStatusUpdateSuccess } = TopicStatusActions;

function ReviewQuestions({ topicStatus, topic }) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { subjectId, unitId, topicId } = useParams()
  const [form] = Form.useForm();

  const [selectedChunk, setSelectedChunk] = useState(0);
  const [chunkContent, setChunkContent] = useState("");
  const [QAContent, setQAContent] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);
  const [selectedCategory, setCategory] = useState("Easy")
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0)
  const [changedQuestion, setChangedQuestion] = useState("")
  const [changedAnswer, setChangedAnswer] = useState("")
  const [teacherAnswer, setTeacherAnswer] = useState("")
  const [questionId, setQuestionId] = useState(0)
  const [questionCount, setQuestionsCount] = useState(0)
  const [reviewCompletedModal, setReviewCompletedModal] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [ansEvaluateModal, setAnsEvaluateModal] = useState(false);
  const [categoryReviewModal, setCategoryReviewModal] = useState(false);

  const { QaData, isLoading, QAUpdateRes, isQuestionUpdating, TopicStatus, TopicStatusLoading, DeleteQARes, isDeleting, isAnsEvaluating, AnsEvaluateRes } = useSelector((state) => {
    return {
      QaData: state.QA.data,
      isLoading: state.QA.loading,
      QAUpdateRes: state.QAUpdateRes.data,
      isQuestionUpdating: state.QAUpdateRes.loading,
      TopicStatus: state.TopicStatus.data,
      TopicStatusLoading: state.TopicStatus.loading,
      isDeleting: state.DeleteQARes.loading,
      DeleteQARes: state.DeleteQARes.data,
      isAnsEvaluating: state.AnsEvaluateRes.loading,
      AnsEvaluateRes: state.AnsEvaluateRes.data,
    };
  });

  useEffect(() => {
    dispatch(generateQuestionsPostSuccess(null))
    if (TopicStatusId(topicStatus) === 3 || TopicStatusId(topicStatus) === 4) {
      dispatch(generateQuestionsDataRead(parseInt(topicId)));
    }
    setIsDirty(false)
  }, [topicId, QAUpdateRes, DeleteQARes]);

  useEffect(() => {
    if (DeleteQARes !== null && DeleteQARes.Error === false) {
      dispatch(getTopicById(parseInt(topicId)));
      dispatch(deleteQASuccess(null))
      setSelectedQuestionIndex(0)
    }
  }, [DeleteQARes]);

  useEffect(() => {
    if (QaData !== null && QaData.qaReviewflag === true && QaData.status === "QUESTIONS GENERATED" && topicStatus === "QUESTIONS GENERATED") {
      dispatch(getTopicById(parseInt(topicId)));
    }
    else if (topicStatus === "QUESTIONS REVIEWED" && QaData !== null && QaData.qaReviewflag === true && QaData.status === "QUESTIONS REVIEWED") {
      setReviewCompletedModal(true)
    }

  }, [QaData]);


  const generateQuiz = () => {
    setReviewCompletedModal(false)
    navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/generate/quiz`)
  }


  const handleEyeIconClick = () => {
    setShowTextArea(!showTextArea);
  };

  const handleSelectChange = async (index) => {
    setTeacherAnswer('')
    if (isDirty) {
      notification.warn({
        message: 'Changes occur in current chunk',
      });
    }
    else {
      setCategory('Easy');
      setSelectedQuestionIndex(0);
      setSelectedChunk(index)
    }
  }


  const handleReset = () => {
    if (QAContent[selectedQuestionIndex].question !== changedQuestion || QAContent[selectedQuestionIndex].answer !== changedAnswer) {
      setCancelModal(true);
    } else {
      setIsDirty(false);
    }
  };

  const handleDiscardChanges = () => {
    setChangedQuestion(QAContent[selectedQuestionIndex].question);
    setChangedAnswer(QAContent[selectedQuestionIndex].answer);
    setCancelModal(false);
    setIsDirty(false);
  };

  const handleModalCancel = () => {
    setCancelModal(false);
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  const [filterLoading, setFilterLoading] = useState(false)
  useEffect(() => {
    setFilterLoading(true)
    if (QaData !== null && QaData.chunks.length !== 0 && QaData.chunks[selectedChunk].qas.length !== 0) {
      let array = QaData.chunks[selectedChunk].qas.filter(qObj => qObj.type.toLowerCase() === selectedCategory.toLowerCase())
      setQAContent(array)
      if (array.length !== 0 && selectedQuestionIndex !== array.length) {
        setQuestionId(array[selectedQuestionIndex].id)
        setChangedQuestion(array[selectedQuestionIndex].question)
        setChangedAnswer(array[selectedQuestionIndex].answer)
      }
      else {
        setQuestionId(0)
        setChangedQuestion("")
        setChangedAnswer("")
      }

      setChunkContent(QaData.chunks[selectedChunk].content)
    }
    else {
      setQAContent([])
      setQuestionId(0)
      setChangedQuestion("")
      setChangedAnswer("")
      setChunkContent(QaData !== null && QaData.chunks.length !== 0 && QaData.chunks[selectedChunk].content)
    }

    setFilterLoading(false)

  }, [QaData, selectedChunk, selectedCategory])

  const getSelectedQuestion = (object, index) => {
    setTeacherAnswer('')
    setQuestionId(object.id)
    setSelectedQuestionIndex(index)
    setChangedQuestion(QAContent[index].question)
    setChangedAnswer(QAContent[index].answer)
  }

  const moveToNextCategory = () => {
    setCategoryReviewModal(false)
    setTeacherAnswer('')
    if (topicStatus === "QUESTIONS GENERATED") {
      if (selectedCategory === "Easy") {
        setCategory('Medium');
        setSelectedQuestionIndex(0)
      }
      else if (selectedCategory === "Medium") {
        setCategory('Hard');
        setSelectedQuestionIndex(0)
      }
    }
  }

  useEffect(() => {
    const updateFunction = async () => {
      if (QAUpdateRes !== null && QAUpdateRes.Error === false) {
        // dispatch(getTopicById(parseInt(topicId)));
        let array = QaData !== null && QaData.chunks.length !== 0 && QaData.chunks[selectedChunk].qas.length !== 0 && QaData.chunks[selectedChunk].qas.filter(qObj => qObj.type.toLowerCase() === selectedCategory.toLowerCase())
        if ((selectedQuestionIndex < (array.length - 1)) && QAContent.length !== 0) {
          setSelectedQuestionIndex(parseInt(selectedQuestionIndex) + 1)
          setChangedQuestion(QAContent[parseInt(selectedQuestionIndex) + 1].question)
          setChangedAnswer(QAContent[parseInt(selectedQuestionIndex) + 1].answer)
          setTeacherAnswer('')
        }
        else if (QaData !== null && QaData.status !== "QUESTIONS REVIEWED") {
          if (selectedCategory !== "Hard") {
            setCategoryReviewModal(true)
          }
        }
        else if (QaData !== null && QaData.status === "QUESTIONS REVIEWED") {
          setReviewCompletedModal(true)
        }
        setIsDirty(false)
      }
      dispatch(QAUpdateSuccess(null));
    }
    updateFunction()

  }, [QAUpdateRes])

  const UpdateQA = () => {
    const data = {
      id: questionId,
      question: changedQuestion,
      answer: changedAnswer
    }
    dispatch(QADataUpdate(parseInt(topicId), data))
  }

  const showDeleteConfirm = () => {
    alertModal.confirm({
      title: `Do you want to delete this question?`,
      content: 'Q & A once deleted cannot be undone.',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        if (questionId !== 0) {
          dispatch(deleteQA(questionId, parseInt(topicId)));
        }
      },
      onCancel() { },
    });
  };

  const generateQuestions = () => {
    dispatch(generateQuestionsPostSuccess(null));
    const datatoPost = {
      id: parseInt(topicId),
      status: "CONTENT REVIEWED"
    }
    dispatch(FreezeTopicById(datatoPost))
  }

  useEffect(() => {
    if (TopicStatus !== null && TopicStatus.message === "updated" && topicStatus === "CONTENT REVIEWED") {
      navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/generate/questions`)
      dispatch(topicStatusUpdateSuccess(null));
    }
  }, [TopicStatus])


  const EvaluateQA = () => {
    dispatch(answerEvaluateSuccess(null))
    if (teacherAnswer !== '') {
      let answers = {
        correctAns: changedAnswer,
        userAns: teacherAnswer
      }      
      dispatch(EvaluateAnswer(answers));
    }
    else {
      message.error('Please write your answer then evaluate')
    }
  }

  useEffect(() => {    
    if(AnsEvaluateRes !== null)
    {
      setAnsEvaluateModal(true)
    }
  },[AnsEvaluateRes])

  const isAnsWritten = Math.ceil((QAContent.length !== 0 && QAContent[selectedQuestionIndex].answer.length / 100) * 60) <= teacherAnswer.trim().length ? true : false;  

  return (
    <>
      <Row gutter={25} justify="center">
        <Col lg={showTextArea ? 14 : 24} xs={24}>
          <NoteCardWrap>
            {
              topicStatus === "CONTENT FREEZED" ?
                <>
                  <Result
                    status="success"
                    title="Content Freezed"
                    subTitle ={`on ${topic !== null && new Date(topic.updatedon).toLocaleString()}`}
                  />
                </>
                :
                TopicStatusId(topicStatus) === 0 ?
                  <div className='' style={{ fontSize: '18px' }}>
                    <Result
                      status="warning"
                      title="Content not found"
                      extra={
                        <Button id="reviewQA_uploadcontent-button" type="primary" key="upload" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/upload`)}>
                          Upload
                        </Button>
                      }
                    />
                  </div>
                  :
                  TopicStatusId(topicStatus) === 1 ?
                    <Result
                      status="warning"
                      title="Review Content"
                      extra={
                        <Button id="reviewQA_reviewcontent-button" type="primary" key="navigateToReviewContent" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/review/content`)}>
                          Review
                        </Button>
                      }
                    />
                    :
                    TopicStatusId(topicStatus) === 2 ?
                      <Result
                        status="warning"
                        title="Questions and Answers not found"
                        extra={
                          <Button id="reviewQA_generateqa-button" type="primary" key="upload" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/generate/questions`)}>
                            Generate Q & A
                          </Button>
                        }
                      />
                      :
                      TopicStatusId(topicStatus) == 5 || TopicStatusId(topicStatus) == 6 ?
                        <Result
                          status="warning"
                          title="MCQS has been generated for this topic"
                          extra={
                            <Button id="reviewQA_reviewmcq-button" type="primary" key="navigateToReviewContent" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/review/quiz`)}>
                              Review MCQS
                            </Button>
                          }
                        />
                        :
                        TopicStatusId(topicStatus) === 7 ?
                          <div>
                            <Result
                              status='success'
                              title="Programs Verified"
                              extra={
                                <Button id="reviewQA_freezetopic-button" type="primary" key="freeze topic" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/freezeschedule`)}>
                                  Freeze Topic
                                </Button>
                              }
                            />
                          </div>
                          :
                          isLoading ?
                            <div className="custom-spin">
                              <CustomSpinner />
                            </div>
                            :
                            <div>
                              {
                                (QaData !== null && QaData.qaquestioncount === 0) && (TopicStatusId(topicStatus) == 3 || TopicStatusId(topicStatus) == 4) ?
                                  <div className='' style={{ fontSize: '18px' }}>
                                    <Result
                                      status="warning"
                                      title="Questions and Answers not found"
                                      extra={
                                        <Button id="reviewQA_generateqa-button1" type="primary" key="upload" onClick={() => generateQuestions()}>
                                          Generate Q & A
                                        </Button>
                                      }
                                    />
                                  </div>
                                  :
                                  < div >
                                    <div className='d-flex justify-content-between'>
                                      <SelectWrapperStyle>
                                        Select&nbsp;
                                        <Select id="reviewQA_select-chunk" value={selectedChunk} style={{ width: 120 }} onChange={handleSelectChange} >
                                          {
                                            QaData !== null && QaData.chunks.length !== 0 && QaData.chunks.map((chunckObj, index) =>
                                              <Option key={index} value={index}>Chunk {index + 1}</Option>
                                            )
                                          }
                                        </Select>
                                        &nbsp;of {QaData !== null && QaData.chunks.length}
                                      </SelectWrapperStyle>
                                      <ProjectSorting>
                                        <div className="project-sort-bar">
                                          <div className="project-sort-nav" style={{ boxShadow: '0px 0px 15px 0px #eee', borderRadius: '6px' }}>
                                            {/* border:'1px solid #e3e6ef',padding:'0px' */}
                                            <nav>
                                              <ul>
                                                <li className={selectedCategory === 'Easy' ? 'active' : 'deactivate'}>
                                                  <Link id="reviewQA_easy-button" onClick={() => { setCategory('Easy'); setSelectedQuestionIndex(0); setTeacherAnswer('') }} to="#">
                                                    Easy ({QaData !== null && QaData.chunks.length !== 0 && QaData.chunks[selectedChunk].qas.length !== 0 ? QaData.chunks[selectedChunk].qas.filter(qObj => qObj.type.toLowerCase() === 'easy').length : 0})
                                                  </Link>
                                                </li>
                                                <li className={selectedCategory === 'Medium' ? 'active' : 'deactivate'}>
                                                  <Link id="reviewQA_medium-button" onClick={() => { setCategory('Medium'); setSelectedQuestionIndex(0); setTeacherAnswer('') }} to="#">
                                                    Medium ({QaData !== null && QaData.chunks.length !== 0 && QaData.chunks[selectedChunk].qas.length !== 0 ? QaData.chunks[selectedChunk].qas.filter(qObj => qObj.type.toLowerCase() === 'medium').length : 0})
                                                  </Link>
                                                </li>
                                                <li className={selectedCategory === 'Hard' ? 'active' : 'deactivate'}>
                                                  <Link id="reviewQA_hard-button" onClick={() => { setCategory('Hard'); setSelectedQuestionIndex(0) ; setTeacherAnswer('') }} to="#">
                                                    Hard ({QaData !== null && QaData.chunks.length !== 0 && QaData.chunks[selectedChunk].qas.length !== 0 ? QaData.chunks[selectedChunk].qas.filter(qObj => qObj.type.toLowerCase() === 'hard').length : 0})
                                                  </Link>
                                                </li>
                                              </ul>
                                            </nav>
                                          </div>
                                        </div>
                                      </ProjectSorting>
                                    </div>
                                    <div>
                                      <div className='d-flex justify-content-between'>
                                        <h4>{selectedCategory} questions</h4>
                                        <div className='taglist-wrap'>
                                          <Tag color="orange">Last saved : {QAContent.length !== 0 && new Date(QAContent[selectedQuestionIndex].updatedon).toLocaleString()}</Tag>
                                        </div>
                                      </div>
                                      <div className='mt-20 mb-20 d-flex justify-content-between'>
                                        <div>
                                          {
                                            QaData !== null && QaData.chunks.length !== 0 && QaData.chunks[selectedChunk].qas.length !== 0 && QaData.chunks[selectedChunk].qas.filter(qObj => qObj.type.toLowerCase() === selectedCategory.toLowerCase()).map((filteredData, index) =>
                                              <Button id="reviewQA_select-qa" key={index} shape="circle" type={'white'} raised style={{ color: 'black', marginRight: 10, boxShadow: `${selectedQuestionIndex === index ? `0px 0px 8px 1px ${theme['primary-color']}` : ''}` }} onClick={() => getSelectedQuestion(filteredData, index)}>
                                                {filteredData.reviewflag === true && <Badge dot color={theme['success-color']} className='mr-3' />}{index + 1}
                                              </Button>
                                            )
                                          }
                                        </div>
                                        <div>
                                          {
                                            QaData !== null && QaData.qaquestioncount !== 0 &&
                                            <Button id="reviewQA_view-hide-content" type='info' transparented className="mr-3" onClick={handleEyeIconClick}>
                                              <Tooltip title={showTextArea ? 'Hide chunk content' : 'View chunk content'} placement="left">
                                                <EyeOutlined style={{ fontSize: 16 }} />
                                              </Tooltip>
                                            </Button>
                                          }
                                          {
                                            QAContent.length !== 0 &&
                                            <Tooltip placement='left' title="Delete current Q & A">
                                              <Button id="reviewQA_delete-button" type="danger" transparented onClick={showDeleteConfirm} disabled={isDeleting ? true : false}>
                                                {isDeleting ? 'Deleting' : <UilTrashAlt size={20} />}
                                              </Button>
                                            </Tooltip>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    <div className="">
                                      <Row justify="center">
                                        <Col xs={24}>
                                          <div className="create-account-form">
                                            {
                                              filterLoading === true ? "loading" :
                                                <>
                                                  {
                                                    QAContent.length !== 0 ?
                                                      <MyForm id="reviewQA_form" changedQuestion={changedQuestion} changedAnswer={changedAnswer} setChangedQuestion={setChangedQuestion} setChangedAnswer={setChangedAnswer} setIsDirty={setIsDirty} />
                                                      :
                                                      <h3 className='text-center mb-20 mt-20'>No Data Found</h3>

                                                  }
                                                </>
                                            }
                                          </div>
                                        </Col>
                                      </Row>
                                      {
                                        QAContent.length !== 0 &&
                                        <div className='d-flex justify-content-between mb-20'>
                                          <div className='taglist-wrap'>
                                            {/* <Checkbox style={{ marginLeft: '5px' }} checked>
                                  Auto save
                                </Checkbox> */}
                                            {/* <br /> */}
                                            {/* <Tag color="orange">Last saved : {QAContent.length !== 0 && new Date(QAContent[selectedQuestionIndex].updatedon).toLocaleString()}</Tag> */}
                                          </div>
                                          <div className=''>
                                            <Button id="reviewQA_reset-button" htmlType="submit" type="white" outlined style={{ color: 'black', marginRight: '10px' }} onClick={handleReset} >
                                              Reset
                                            </Button>
                                            <Button id="reviewQA_review-button" htmlType="submit" type="primary" onClick={UpdateQA} disabled={isQuestionUpdating ? true : false}>
                                              {isQuestionUpdating ? 'Please Wait...' : 'Review & Next'}
                                            </Button>
                                          </div>
                                        </div>
                                      }
                                    </div>
                                    <div>
                                      <div className='mt-20 mb-20'>
                                        <h4>Your Answer</h4>
                                        <TextArea id="reviewQA_teacher-answer" placeholder="Type your answer here..." rows={6} minLength={Math.ceil((QAContent.length !== 0 && QAContent[selectedQuestionIndex].answer.length / 100) * 60)} value={teacherAnswer} onChange={(e) => { setTeacherAnswer(e.target.value); }} style={{ textAlign: 'justify' }} />
                                        <span className='fontsize-13 d-flex justify-content-end'>Characters {teacherAnswer.trim().length} / {Math.ceil((QAContent.length !== 0 && QAContent[selectedQuestionIndex].answer.length / 100) * 60)}<i>(min)</i></span>
                                      </div>
                                      <div className='d-flex justify-content-between mb-20'>
                                        <div className='taglist-wrap'>
                                        </div>
                                        <div className=''>
                                          <Button id="reviewQA_evaluate-answers" htmlType="submit" type="primary" onClick={EvaluateQA} disabled={!isAnsWritten || isAnsEvaluating}>
                                            {isAnsEvaluating ? 'Please Wait...' : 'Evaluate'}
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                              }
                            </div>
            }
          </NoteCardWrap>
        </Col>
        {
          showTextArea && (
            <Col lg={10} xs={24}>
              <Cards headless>
                <h3>Content</h3>
                <NoteCardWrap>
                  {/* <MyTextArea content={chunkContent}/> */}
                  <TextArea id="reviewQA_content-textarea" rows={25} cols={10} value={chunkContent} readOnly className='mb-20' />
                </NoteCardWrap>
              </Cards>
            </Col>
          )
        }
      </Row>
      <Modal open={ansEvaluateModal} title="Evaluate Results" onCancel={() => setAnsEvaluateModal(false)} onOk={() => setAnsEvaluateModal(false)} okText="Ok" cancelText="Cancel">
        <h3>Evaluate Score : {AnsEvaluateRes !== null && AnsEvaluateRes}</h3>
      </Modal>
      <Modal open={cancelModal} title="Discard Changes" onCancel={handleModalCancel} onOk={handleDiscardChanges} okText="Yes" cancelText="No">
        <h3>Do you want to DISCARD the changes?</h3>
      </Modal>
      <Modal open={reviewCompletedModal} title="Generate MCQS" onCancel={() => setReviewCompletedModal(false)} onOk={() => generateQuiz()} okText="Yes" cancelText="No">
        <h4>All Questions and Answers are Reviewed</h4>
        <h4>Do you want to generate MCQS?</h4>
      </Modal>
      <CategoryReviewCompleted title={selectedCategory} setCategoryReviewModal={setCategoryReviewModal} categoryReviewModal={categoryReviewModal} nextCategory={selectedCategory === "Easy" ? "Medium" : selectedCategory === "Medium" ? "Hard" : selectedCategory === "Hard" && ''} moveToNextCategory={moveToNextCategory} />
    </>
  );
}

export default ReviewQuestions;


const MyForm = ({ changedQuestion, changedAnswer, setChangedQuestion, setChangedAnswer, setIsDirty }) => {

  return (
    <>
      <div>
        <h4>Question</h4>
        <Input id="reviewQA_question-input" placeholder="Question" value={changedQuestion} onChange={(e) => { setChangedQuestion(e.target.value); setIsDirty(true) }} />
      </div>
      <div className='mt-20 mb-20'>
        <h4>Answer</h4>
        <TextArea id="reviewQA_answer-input" placeholder="Answer" rows={6} value={changedAnswer} onChange={(e) => { setChangedAnswer(e.target.value); setIsDirty(true) }} style={{ textAlign: 'justify' }} />
      </div>
    </>
  );
}


const CategoryReviewCompleted = ({ title, moveToNextCategory, categoryReviewModal, setCategoryReviewModal, nextCategory }) => {
  return (
    <Modal open={categoryReviewModal} onCancel={() => setCategoryReviewModal(false)} onOk={() => moveToNextCategory()} okText="Yes" cancelText="No">
      <h4>All {title} Q & A are Reviewed</h4>
      <h4>Do you want to review {nextCategory} Q & A?</h4>
    </Modal>
  );
}
