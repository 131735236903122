import React, { useEffect, useRef, useState } from 'react'
import { Main, Microphone } from '../styled'
import { Link, useParams } from 'react-router-dom'
import AngleLeftB from '@iconscout/react-unicons/icons/uil-angle-left-b';
import UilMicrophone from '@iconscout/react-unicons/icons/uil-microphone';
import UilMicrophoneSlash from '@iconscout/react-unicons/icons/uil-microphone-slash';
import { Cards } from '../../components/cards/frame/cards-frame';
import { NoteCardWrap } from '../note/style';
import { ReactMic } from 'react-mic';
import { Button, Checkbox, Col, Row, message } from 'antd';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useDispatch, useSelector } from 'react-redux';
import { GetInterviewQuestions } from '../../redux/interview/actionCreator';
import { Step, Steps } from '../../components/steps/steps';
import Cookies from 'js-cookie';
import WaveSurfer from 'wavesurfer.js';
import RecordPlugin from 'wavesurfer.js/dist/plugins/record.esm.js'

export default function Interview() {
    const dispatch = useDispatch()
    const { subjectId, subjectName, topicId, topicName, interviewType } = useParams()

    const { questions, isQuestionsLoading, submitRes, isSubmitting } = useSelector((state) => {
        return {
            questions: state.interviewQuestions.data,
            isQuestionsLoading: state.interviewQuestions.loading,
            submitRes: state.submitInterviewRes.data,
            isSubmitting: state.submitInterviewRes.loading,
        }
    })    

    const [record, setRecord] = useState(false)
    const [micTest, setMicTest] = useState(false)

    const toggleMic = () => {
        setRecord(!record)
    }

    const microphoneRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const chunksRef = useRef([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [submittedQuestions, setSubmittedQuestions] = useState([]);
    const [showSubmitPage, setShowSubmitPage] = useState(false);
    const { transcript, listening, resetTranscript } = useSpeechRecognition();
    const [interviewStarted, setInterviewStarted] = useState(false)
    const [ansAudio, setAnsAudio] = useState(null)
    const [attempt, setAttempt] = useState(Cookies.get('attempt'))

    const startInterview = () => {
        // dispatch(GetInterviewQuestions())
        if (questions.Error === false && questions.payload.length !== 0) {
            setInterviewStarted(true);
            setMicTest(false);
            handleReadAudio(questions.payload[currentQuestionIndex].audio_file)
        }
        else if (questions.Error === false && questions.payload.length === 0) {
            message.error("Questions not found!")
        }
        else if (questions.Error === true) {
            message.error(questions.message)
        }
    }

    // useEffect(() => {
    //     if (questions.Error === false && questions.payload.length !== 0) {
    //         setInterviewStarted(true);
    //         setMicTest(false);
    //         handleReadAudio(questions.payload[currentQuestionIndex].audio_file)
    //         console.log(questions.payload[currentQuestionIndex].audio_file)
    //     }
    //     else if (questions.Error === false && questions.payload.length === 0) {
    //         message.error("Questions not found!")
    //     }
    //     else if (questions.Error === true) {
    //         message.error(questions.message)
    //     }
    // }, [questions])

    const handleNext = () => {
        if (currentQuestionIndex + 1 < questions?.payload.length) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            // handleQuestionSubmit(questions?.payload[currentQuestionIndex].id)
            handleReadAudio(questions?.payload[currentQuestionIndex + 1].audio_file)
        } else {
            setShowSubmitPage(true);
        }
    };

    const toggleRecording = () => {
        if (listening) {
            SpeechRecognition.stopListening();
            mediaRecorderRef.current.stop();
            chunksRef.current.pop()
        } else {
            microphoneRef.current?.classList.add("listening");
            SpeechRecognition.startListening({
                continuous: true,
            });

            navigator.mediaDevices
                .getUserMedia({ audio: true })
                .then((stream) => {
                    mediaRecorderRef.current = new MediaRecorder(stream);

                    mediaRecorderRef.current.ondataavailable = (e) => {
                        if (e.data.size > 0) {
                            chunksRef.current.push(e.data);
                        }
                    };

                    mediaRecorderRef.current.onstop = () => {
                        const audioBlob = new Blob(chunksRef.current, {
                            type: "audio/wav", // WebM format
                        });
                        // downloadAudio(audioBlob, "speech.mp3");
                        setAnsAudio(audioBlob)
                        // let data = []
                        // data.push(...attempt, { audioBlob })
                        // Cookies.set(data)
                    };

                    mediaRecorderRef.current.start();
                })
                .catch((err) => console.error("Error accessing microphone:", err));
        }
    };

    const downloadAudio = (blob, filename) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const handleQuestionSubmit = () => {
        const question = questions?.payload[currentQuestionIndex];
        setSubmittedQuestions([
            ...submittedQuestions,
            { question: question.question, answer: transcript, audio: ansAudio, audiourl: URL.createObjectURL(ansAudio) },
        ]);
        // Cookies.set("Attempt", [
        //     ...submittedQuestions,
        //     { question: question.question, answer: transcript, audio: ansAudio, audiourl: URL.createObjectURL(ansAudio) },
        // ])
        setAnsAudio(null)
        resetTranscript();
        handleNext();
    };

    const handleReadAudio = (audioSrc) => {
        const audio = new Audio(audioSrc);
        audio.play();
    };


    return (
        <div>
            <Main>
                <div className='back-button'>
                    <Link to={`/student/subjects/${subjectId}/${subjectName}/topic/${topicId}/${topicName}`}><AngleLeftB size={18} className="mb-2" /> Back to topics</Link>
                </div>
                <Cards headless className='mt-15'>
                    <NoteCardWrap className='text-center'>
                        <div className='d-flex justify-content-between align-center-v mb-20 pb-20'>
                            <h3>{subjectName}</h3>
                            <h3>Topic : {topicName}</h3>
                            <h5>Difficulty Level : <span className='status'>{interviewType}</span></h5>
                        </div>
                        {
                            !interviewStarted &&
                            <div>
                                <div className='text-center mt-20'>
                                    Note: Before starting the interview, please test your microphone
                                </div>
                                <div className='text-center d-flex justify-content-center align-center-v'>
                                    <Microphone onClick={() => toggleMic()} >
                                        {record ? <UilMicrophone size={25} /> : <UilMicrophoneSlash size={25} />}
                                    </Microphone>
                                </div>
                                <div className='text-center mt-25'>
                                    <ReactMic
                                        record={record}
                                        className="sound-wave"
                                        // onStop={onStop}
                                        // onData={onData}
                                        strokeColor="#555"
                                        visualSetting='sinewave'
                                        backgroundColor="#eee"
                                        echoCancellation={true} // defaults -> false
                                        autoGainControl={true}  // defaults -> false
                                        noiseSuppression={true} // defaults -> false
                                    />
                                </div>
                                <div className='mt-15'>
                                    <Checkbox onClick={() => setMicTest(!micTest)}>I have tested my mic.</Checkbox>
                                </div>
                                <div className='mt-15'>
                                    <Button type='primary'
                                        disabled={!micTest || isQuestionsLoading}
                                        onClick={() => { startInterview() }}
                                    >
                                        {isQuestionsLoading ? 'Loading...' : 'Start Interview'}
                                    </Button>
                                </div>
                            </div>
                        }

                        {
                            interviewStarted &&
                            <div className="ynos">
                                {showSubmitPage ? (
                                    <div style={{ textAlign: 'left' }}>
                                        <h2>Submitted Questions</h2>
                                        <ul>
                                            {submittedQuestions.map((q, index) => (
                                                <div key={index} className='mb-20'>
                                                    <h4>{index + 1}. {q.question}</h4>
                                                    <Row gutter={25} justify={'start'}>
                                                        <Col>
                                                            <p>Ans: {q.answer}</p>
                                                        </Col>
                                                        <Col>
                                                            <audio controls>
                                                                <source src={q.audiourl} type="audio/wav" />
                                                                Your browser does not support the audio element.
                                                            </audio>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))}
                                        </ul>
                                    </div>
                                ) : (

                                    questions !== null && questions.payload.length !== 0 &&
                                    <div style={{ maxWidth: '75%', margin: 'auto' }}>
                                        <div className='mb-20'>
                                            <Steps size="small">
                                                <Step status={`${currentQuestionIndex == 0 ? 'process' : currentQuestionIndex >= 1 ? 'finish' : 'wait'}`} />
                                                <Step status={`${currentQuestionIndex == 1 ? 'process' : currentQuestionIndex >= 2 ? 'finish' : 'wait'}`} />
                                                <Step status={`${currentQuestionIndex == 2 ? 'process' : currentQuestionIndex >= 3 ? 'finish' : 'wait'}`} />
                                                <Step status={`${currentQuestionIndex == 3 ? 'process' : currentQuestionIndex >= 4 ? 'finish' : 'wait'}`} />
                                                <Step status={`${currentQuestionIndex == 4 ? 'process' : currentQuestionIndex >= 5 ? 'finish' : 'wait'}`} />
                                                <Step status={`${currentQuestionIndex == 5 ? 'process' : currentQuestionIndex >= 6 ? 'finish' : 'wait'}`} />
                                                <Step status={`${currentQuestionIndex == 6 ? 'process' : currentQuestionIndex >= 7 ? 'finish' : 'wait'}`} />
                                                <Step status={`${currentQuestionIndex == 7 ? 'process' : currentQuestionIndex >= 8 ? 'finish' : 'wait'}`} />
                                                <Step status={`${currentQuestionIndex == 8 ? 'process' : currentQuestionIndex >= 9 ? 'finish' : 'wait'}`} />
                                                <Step status={`${currentQuestionIndex == 9 ? 'process' : currentQuestionIndex >= 10 ? 'finish' : 'wait'}`} />
                                            </Steps>
                                        </div>
                                        <h2>{currentQuestionIndex + 1}. {questions?.payload[currentQuestionIndex].question}</h2>
                                        {/* <input type="text" value={transcript} readOnly /> */}
                                        {/* <input
                                            type="text"
                                            value={transcript}
                                            readOnly
                                            style={{
                                                width: "90%",
                                                height: "100px",
                                                marginBottom: "10px",
                                                marginTop: "10px",
                                                padding: "10px",
                                                backgroundColor: "#e5eff7",
                                                borderRadius: "5px",
                                                border: "1px solid #ccc",
                                                fontSize: "20px",
                                            }}
                                        /> */}
                                        <div className="buttons-container">
                                            <Button onClick={toggleRecording} className='mr-10'>
                                                {listening ? "Stop Recording" : "Start Recording"}
                                            </Button>
                                            <Button
                                                className='mr-10'
                                                onClick={() =>
                                                    handleReadAudio(
                                                        questions?.payload[currentQuestionIndex].audio_file
                                                    )
                                                }
                                            >
                                                Replay
                                            </Button>
                                            <Button className='mr-10' onClick={handleNext}>Skip</Button>
                                            <Button
                                                className='mr-10'
                                                onClick={() =>
                                                    handleQuestionSubmit(questions?.payload[currentQuestionIndex].id)
                                                }
                                            >
                                                Save & Next
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                        {/* <div>
                            <h2>{questions?.payload[currentQuestionIndex].question}</h2>
                            <div className="buttons-container">
                                <button onClick={toggleRecording}>{listening ? 'Stop Recording' : 'Start Recording'}</button>
                                <button onClick={() => handleReadAudio(questions?.payload[currentQuestionIndex].question)}>Audio </button>
                                <button onClick={() => handleQuestionSubmit(questions?.payload[currentQuestionIndex].id)}>Submit</button>
                                <button onClick={handleNext}>Next</button>
                            </div>
                        </div> */}
                    </NoteCardWrap>
                </Cards>
            </Main>
        </div>
    )
}
