export const GET_PDF_BEGIN = 'GET_PDF_BEGIN';
export const GET_PDF_SUCCESS = 'GET_PDF_SUCCESS';
export const GET_PDF_ERROR = 'GET_PDF_ERROR';

export const getPdfFileBegin = () => ({
    type: GET_PDF_BEGIN,
});

export const getPdfFileSuccess = (data) => ({
    type: GET_PDF_SUCCESS,
    payload: data,
});

export const getPdfFileError = (error) => ({
    type: GET_PDF_ERROR,
    payload: error,
});
