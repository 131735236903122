const actions = {
  COLLEGE_ADD_BEGIN: 'COLLEGE_ADD_BEGIN',
  COLLEGE_ADD_SUCCESS: 'COLLEGE_ADD_SUCCESS',
  COLLEGE_ADD_ERR: 'COLLEGE_ADD_ERR',

  COLLEGE_READ_BEGIN: 'COLLEGE_READ_BEGIN',
  COLLEGE_READ_SUCCESS: 'COLLEGE_READ_SUCCESS',
  COLLEGE_READ_ERR: 'COLLEGE_READ_ERR',

  COLLEGE_UPDATE_BEGIN: 'COLLEGE_UPDATE_BEGIN',
  COLLEGE_UPDATE_SUCCESS: 'COLLEGE_UPDATE_SUCCESS',
  COLLEGE_UPDATE_ERR: 'COLLEGE_UPDATE_ERR',


  collegeAddBegin: () => {
    return {
      type: actions.COLLEGE_ADD_BEGIN,
    };
  },

  collegeAddSuccess: (data) => {
    return {
      type: actions.COLLEGE_ADD_SUCCESS,
      data,
    };
  },

  collegeAddErr: (err) => {
    return {
      type: actions.COLLEGE_ADD_ERR,
      err,
    };
  },

  collegeReadBegin: () => {
    return {
      type: actions.COLLEGE_READ_BEGIN,
    };
  },

  collegeReadSuccess: (data) => {
    return {
      type: actions.COLLEGE_READ_SUCCESS,
      data,
    };
  },

  collegeReadErr: (err) => {
    return {
      type: actions.COLLEGE_READ_ERR,
      err,
    };
  },

  collegeUpdateBegin: () => {
    return {
      type: actions.COLLEGE_UPDATE_BEGIN,
    };
  },

  collegeUpdateSuccess: (data) => {
    return {
      type: actions.COLLEGE_UPDATE_SUCCESS,
      data,
    };
  },

  collegeUpdateErr: (err) => {
    return {
      type: actions.COLLEGE_UPDATE_ERR,
      err,
    };
  },
};

export default actions;
