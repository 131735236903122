import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Input, Modal, Form, Select, message } from 'antd';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import { Cards } from '../../components/cards/frame/cards-frame';
import { TableWrapper, Main } from '../styled';
import { PageHeader } from '../../components/page-headers/page-headers';
import { useDispatch, useSelector } from 'react-redux';
import { topicsadminDataRead, topicsadminDataSubmit, topicsadminDataUpdate } from '../../redux/topicsadmin/actionCreator';
import { ProjectList, ProjectSorting } from '../project/style';
import { AutoComplete } from '../../components/autoComplete/autoComplete';
import { Button } from '../../components/buttons/buttons';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { getItem } from '../../utility/localStorageControl';
import { subjectsDataRead } from '../../redux/subjects/actionCreator';
import { SelectWrapperStyle } from '../../container/ui-elements/ui-elements-styled';
const { Option } = Select;

function Topics() {
  const clgName = getItem('collegeName');
  let ClgName = '(' + clgName + ')';
  const loggedadmin = getItem('username');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  const [selectedSubjects, setSelectedSubjects] = useState(null);
  const [searchText, setsearchText] = useState(null);

  const [editRecord, setEditRecord] = useState(null);

  const dispatch = useDispatch();
  const { crud, isLoading, subjectsData, TopicsUpdateResadmin, isTopicsUpdating, TopicsAddadminRes, isTopicsAdding } = useSelector((state) => {
    return {
      crud: state.Topicsadmin.data,
      isLoading: state.Topicsadmin.loading,

      TopicsUpdateResadmin: state.TopicsUpdateResadmin.data,
      isTopicsUpdating: state.TopicsUpdateResadmin.loading,

      TopicsAddadminRes: state.TopicsAddadminRes.data,
      isTopicsAdding: state.TopicsAddadminRes.loading,

      subjectsData: state.Subjects.data,

    };
  });

  useEffect(() => {
    if (dispatch) {
      dispatch(topicsadminDataRead());
      dispatch(subjectsDataRead());
    }
  }, [dispatch]);

  useEffect(() => {
    if (TopicsUpdateResadmin && TopicsUpdateResadmin.Error === false) {
      dispatch(topicsadminDataRead());
    }
  }, [TopicsUpdateResadmin])

  useEffect(() => {
    if (TopicsAddadminRes && TopicsAddadminRes.Error === false) {
      dispatch(topicsadminDataRead());
    }
  }, [TopicsAddadminRes])

  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    if (crud) {
      const filteredData = crud.sort((a, b) => a.updatedon < b.updatedon ? 1 : -1).map((item, index) => ({
        sno: index + 1,
        key: item.id,
        id: item.id,
        topicname: item.name,
        status: <span className={`status ${item.status.toLowerCase()}`}>{item.status}</span>,
        topicStatus: item.status,
        subjectname: item.subject?.name,
        updatedon: new Date(item.updatedon).toLocaleString('en-GB'),
        updatedby: item.updatedby !== null ? item.updatedby : '--------',
      }));
      setFilteredData(filteredData);
      setTableData(filteredData);
      setLoading(false);
    }
    else {
      setFilteredData([]);
      setTableData([]);
      setLoading(false);
    }
  }, [crud]);

  const checkSubject = (i) => {
    let tarr = i.subjectname;
    let valueInArray = false;
    if (tarr === selectedSubjects) {
      valueInArray = true;
    }
    return valueInArray;
  }

  const checkSearch = (i) => {
    for (let j in i) {
      if (i[j] != null && i[j].toString().toLowerCase().includes(searchText.toString().toLowerCase())) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    let tempValues = [...tableData];
    if (selectedSubjects != null || selectedSubjects !== undefined) {
      let tempData = tempValues.filter((obj) => obj.subjectname === selectedSubjects);
      setFilteredData(tempData);
      if (searchText != null) {
        let data = tempData.filter(checkSearch);
        setFilteredData(data);
      }
    }
    else {
      if (searchText != null) {
        let data = tempValues.filter(checkSearch);
        setFilteredData(data);
      }
      else {
        setFilteredData(tempValues);
      }
    }
  }, [selectedSubjects])

  useEffect(() => {
    let tempValues = [...tableData];
    if (searchText != null && tempValues.length !== 0) {
      let tempData = tempValues.filter(checkSearch);
      setFilteredData(tempData);
      if (selectedSubjects != null || selectedSubjects !== undefined) {
        let data = tempData.filter((obj) => obj.subjectname === selectedSubjects);
        setFilteredData(data);
      }
    }
    else {
      setFilteredData(tempValues);
    }
  }, [searchText])

  const handleSubjectChange = (value) => {
    setSelectedSubjects(value);
  };

  const handleSearch = (e) => {
    setsearchText(e)
  }

  const columns = [
    {
      title: 'S.No.',
      dataIndex: 'sno',
      key: 'sno',
      sorter: (a, b) => a.sno > b.sno ? 1 : -1,
    },
    {
      title: 'Topic Name',
      dataIndex: 'topicname',
      key: 'topicname',
      sorter: (a, b) => a.topicname > b.topicname ? 1 : -1,
    },
    {
      title: 'Subject',
      dataIndex: 'subjectname',
      key: 'subjectname',
      sorter: (a, b) => a.subjectname > b.subjectname ? 1 : -1,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: filteredData.length !== 0 && Array.from(new Set(filteredData?.map(item => item.topicStatus)))?.map(topicStatus => ({ key: topicStatus, text: topicStatus, value: topicStatus })),
      onFilter: (value, record) => record.topicStatus === value,
    },
    {
      title: 'Updated On',
      dataIndex: 'updatedon',
      key: 'updatedon',
      sorter: (a, b) => a.updatedon > b.updatedon ? 1 : -1,
    },
    {
      title: 'Updated By',
      dataIndex: 'updatedby',
      key: 'updatedby',
      sorter: (a, b) => a.updatedby > b.updatedby ? 1 : -1,
    },
  ];

  if (loggedadmin !== 'superadmin') {
    columns.push({
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <div className='table-actions'>
          <Button
            key="editTopic"
            onClick={() => handleEdit(record)}
            className="btn-icon "
            type="primary"
            to="#"
            shape="circle"
          >
            <UilEdit />
          </Button>
        </div>
      ),
    });
  }

  const handleButtonClick = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleModalAdd = () => {
    form.validateFields().then((values) => {
      const newRow = {
        subjectId: parseInt(values.subjectsId),
        name: values.name,
        // unitname: values.unitId,
      };      
      if (crud.filter((topic) => topic.name == values.name)[0]) {
        message.error("The topic already exists!")
        return
      }
      form.resetFields();
      setIsModalVisible(false);
      dispatch(topicsadminDataSubmit(newRow));
    })
      .catch((error) => {
        console.error('Error while validating form:', error);
      });
  };

  const handleModalUpdate = () => {
    editForm.validateFields().then((values) => {
      const updatedRecord = {
        id: editRecord.id,
        name: values.name,
        subjectId: values.subjectId,
        status: values.status
      };
      if (crud.filter((topic) => topic.name == values.name && topic.id !== editRecord.id)[0]) {
        message.error("The topic already exists!")
        return
      }
      editForm.resetFields()
      setIsEditModalVisible(false);
      dispatch(topicsadminDataUpdate(updatedRecord));
    }).catch((error) => {
      console.error('Error while validating form:', error);
    });
  };

  const handleEdit = (record) => {
    setEditRecord(record);
    setIsEditModalVisible(true);
    const subID = subjectsData && subjectsData.filter((item) => record.subjectname.includes(item.name)).map((item) => item.id);
    editForm.setFieldsValue({
      name: record.topicname,
      subjectId: subID.length !== 0 ? subID[0] : undefined,
      status: record.topicStatus
    });
  };

  return (
    <>
      <PageHeader className="ninjadash-page-header-main" title={`Topics ${loggedadmin === 'superadmin' ? ClgName : ''}`}
        buttons={
          loggedadmin !== 'superadmin' ?
            [
              <Button id="topic-page-add_topic-button" onClick={handleButtonClick} key="AddTopic" type="primary" size="default">
                Add Topic
              </Button>,
            ] : []}
      />
      <Main>
        {isLoading || loading || isTopicsUpdating || isTopicsAdding ?
          (
            <div className="spin">
              <CustomSpinner Message={'Loading please wait...'} />
            </div>
          ) : (
            <Row gutter={25}>
              <Col sm={24} xs={24}>
                <Cards headless>
                  <TableWrapper>
                    <ProjectList>
                      <div className='d-flex justify-content-between mb-15 p-0 align-center-v'>
                        <SelectWrapperStyle>
                          <div>
                            <span>Filter by:</span>
                            <Select id="topic-page-subjects-filter" onChange={(e) => { handleSubjectChange(e) }} placeholder='Select subject' style={{ minWidth: '250px' }}
                              showSearch
                              filterOption={(inputValue, option) =>
                                option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                              }
                              allowClear
                            >
                              {tableData && Array.from(new Set(tableData.map(item => item.subjectname))).map((subject) => (
                                <Option key={subject} value={subject}>
                                  {subject}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </SelectWrapperStyle>
                        <div className="d-flex align-center-v" style={{ marginLeft: 'auto' }}>
                          <AutoComplete id="topic-page-search-filter" onSearch={handleSearch} dataSource={filteredData} placeholder="Search here" patterns />
                        </div>
                      </div>
                      <div className="table-responsive">
                        <Table dataSource={filteredData} columns={columns} />
                      </div>
                    </ProjectList>
                  </TableWrapper>
                </Cards>
              </Col>
            </Row>
          )
        }
      </Main>
      <Modal
        title="Add Topic"
        open={isModalVisible}
        onCancel={handleModalCancel}
        maskClosable={false}
        centered
        footer={[
          <Button id="add-topic-button" key="AddTopicRecord" type="primary" onClick={handleModalAdd}>
            Add
          </Button>,
        ]}
      >
        <Form id="add-topic-form" form={form} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter topic name" }]}>
            <Input id="add-topic-enter-topic-name" placeholder="Enter topic name" />
          </Form.Item>

          <Form.Item name="subjectsId" label="Subject" rules={[{ required: true, message: "Please select subject" }]}>
            <Select id="add-topic-select-subject-code" placeholder="Select or search subject"
              showSearch
              filterOption={(inputValue, option) =>
                option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
              }
            >
              {subjectsData && subjectsData.map((item) => (
                <Option key={item.name} value={item.id}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit Topic"
        open={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        maskClosable={false}
        centered
        footer={[
          <Button id="edit-topic-button" key="UpdateTopicRecord" type="primary" onClick={handleModalUpdate}>
            Update
          </Button>
        ]}
      >
        <Form id="edit-topic-form" form={editForm} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter topic name" }]}>
            <Input id="edit-topic-enter-topic-name" placeholder="Enter topic name" />
          </Form.Item>
          <Form.Item name='subjectId' label='Subject' rules={[{ required: true, message: 'Please select subject' }]}>
            <Select placeholder='Select subject' allowClear>
              {subjectsData && subjectsData.map((item) => (
                <Option key={item.name} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='status' label='Status' rules={[{ required: true, message: 'Please select status' }]}>
            <Select placeholder='Select status' allowClear>
              <Option key={'ACTIVE'} value={'ACTIVE'}>
                ACTIVE
              </Option>
              <Option key={'INACTIVE'} value={'INACTIVE'}>
                INACTIVE
              </Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Topics;