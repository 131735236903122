import { notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { getItem } from '../../utility/localStorageControl';

const addNotificationSuccess = () => {
  notification.success({
    message: 'Your Record has been Submited',
  });
};

const addNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const deleteNotificationSuccess = () => {
  notification.success({
    message: 'Your Record has been Deleted',
  });
};

const deleteNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const updateNotificationSuccess = () => {
  notification.success({
    message: 'Your Record has been updated',
  });
};

const updateNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const {
  regulationAddBegin,
  regulationAddSuccess,
  regulationAddErr,

  regulationReadBegin,
  regulationReadSuccess,
  regulationReadErr,

  regulationUpdateBegin,
  regulationUpdateSuccess,
  regulationUpdateErr,

  regulationDeleteBegin,
  regulationDeleteSuccess,
  regulationDeleteErr,

  regulationSingleDataBegin,
  regulationSingleDataSuccess,
  regulationSingleDataErr,

  regulationUploadBegin,
  regulationUploadSuccess,
  regulationUploadErr,
} = actions;

const regulationDataSubmit = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(regulationAddBegin());
      const response = await DataService.post('/regulation/create', data);
      const token = getItem('access_token');
      await dispatch(regulationAddSuccess(response.data.data));
      if (token) {
        addNotificationSuccess();
      } else {
        await dispatch(regulationAddErr('Unauthorized access'));
        addNotificationError('Unauthorized access');
      }
    } catch (err) {
      await dispatch(regulationAddErr(err));
      addNotificationError(err);
    }
  };
};

const regulationDataRead = () => {
  return async (dispatch) => {
    try {
      await dispatch(regulationReadBegin());
      const query = await DataService.get('/regulation');
      const token = getItem('access_token');
      await dispatch(regulationReadSuccess(query.data));
      if (token) {
      } else {
        await dispatch(regulationReadSuccess([]));
        // await dispatch(regulationAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(regulationReadErr(err));
    }
  };
};


const regulationDataSearch = (searchItem) => {
  return async (dispatch) => {
    try {
      await dispatch(regulationReadBegin());
      if (searchItem !== '') {
        const query = await DataService.get(`/data/search/${searchItem}`);
        await dispatch(regulationReadSuccess(query.data.data));
      } else {
        try {
          const query = await DataService.get('/data/all');
          await dispatch(regulationReadSuccess(query.data.data));
        } catch (err) {
          await dispatch(regulationReadErr(err));
        }
      }
    } catch (err) {
      await dispatch(regulationReadErr(err));
    }
  };
};

const regulationDataUpdate = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(regulationUpdateBegin());
      await DataService.put(`/regulation/update`, data);
      await dispatch(regulationUpdateSuccess());
      updateNotificationSuccess();
    } catch (err) {
      await dispatch(regulationUpdateErr(err));
      updateNotificationError(err);
    }
  };
};

const regulationDataDelete = ({ id, getData }) => {
  return async (dispatch) => {
    try {
      await dispatch(regulationDeleteBegin());
      const data = await DataService.post(`/${id}/delete`, {});

      await dispatch(regulationDeleteSuccess(data.data));
      await getData();
      deleteNotificationSuccess();
    } catch (err) {
      await dispatch(regulationDeleteErr(err));
      deleteNotificationError(err);
    }
  };
};

const regulationDataSingle = (regulationId) => {
  return async (dispatch) => {
    try {
      await dispatch(regulationSingleDataBegin());
      const query = await DataService.get(`/regulation/all/${regulationId}`);
      const token = getItem('access_token');
      await dispatch(regulationSingleDataSuccess(query.data));
      if (token) {
      } else {
        await dispatch(regulationSingleDataSuccess([]));
        // await dispatch(regulationAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(regulationSingleDataErr(err));
    }
  };
};

const regulationFileUploder = (imageAsFile) => {
  const data = new FormData();
  data.append('image', imageAsFile);

  return async (dispatch) => {
    try {
      await dispatch(regulationUploadBegin());
      const query = await DataService.post('/data/image/upload', data, { 'Content-Type': 'multipart/form-data' });

      dispatch(regulationUploadSuccess(`${query.data}`));
    } catch (err) {
      await dispatch(regulationUploadErr(err));
    }
  };
};

const regulationFileClear = () => {
  return async (dispatch) => {
    try {
      await dispatch(regulationUploadBegin());
      dispatch(regulationUploadSuccess(null));
    } catch (err) {
      await dispatch(regulationUploadErr(err));
    }
  };
};

export {
  regulationDataRead,
  regulationDataSearch,
  regulationDataSubmit,
  regulationFileUploder,
  regulationDataDelete,
  regulationDataSingle,
  regulationDataUpdate,
  regulationFileClear,
};
