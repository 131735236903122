const actions = {
  SUBJECT_WISE_PERFORMANCE_READ_BEGIN: 'SUBJECT_WISE_PERFORMANCE_READ_BEGIN',
  SUBJECT_WISE_PERFORMANCE_READ_SUCCESS: 'SUBJECT_WISE_PERFORMANCE_READ_SUCCESS',
  SUBJECT_WISE_PERFORMANCE_READ_ERR: 'SUBJECT_WISE_PERFORMANCE_READ_ERR',

  TOPIC_WISE_PERFORMANCE_READ_BEGIN: 'TOPIC_WISE_PERFORMANCE_READ_BEGIN',
  TOPIC_WISE_PERFORMANCE_READ_SUCCESS: 'TOPIC_WISE_PERFORMANCE_READ_SUCCESS',
  TOPIC_WISE_PERFORMANCE_READ_ERR: 'TOPIC_WISE_PERFORMANCE_READ_ERR',

  SUBJECT_SUMMARY_READ_BEGIN: 'SUBJECT_SUMMARY_READ_BEGIN',
  SUBJECT_SUMMARY_READ_SUCCESS: 'SUBJECT_SUMMARY_READ_SUCCESS',
  SUBJECT_SUMMARY_READ_ERR: 'SUBJECT_SUMMARY_READ_ERR',

  SINGLE_SUBJECT_SUMMARY_READ_BEGIN: 'SINGLE_SUBJECT_SUMMARY_READ_BEGIN',
  SINGLE_SUBJECT_SUMMARY_READ_SUCCESS: 'SINGLE_SUBJECT_SUMMARY_READ_SUCCESS',
  SINGLE_SUBJECT_SUMMARY_READ_ERR: 'SINGLE_SUBJECT_SUMMARY_READ_ERR',

  SUBJECTS_BEST_PERFORMER_READ_BEGIN: 'SUBJECTS_BEST_PERFORMER_READ_BEGIN',
  SUBJECTS_BEST_PERFORMER_READ_SUCCESS: 'SUBJECTS_BEST_PERFORMER_READ_SUCCESS',
  SUBJECTS_BEST_PERFORMER_READ_ERR: 'SUBJECTS_BEST_PERFORMER_READ_ERR',

  ACTIVE_STUDENTS_READ_BEGIN: 'ACTIVE_STUDENTS_READ_BEGIN',
  ACTIVE_STUDENTS_READ_SUCCESS: 'ACTIVE_STUDENTS_READ_SUCCESS',
  ACTIVE_STUDENTS_READ_ERR: 'ACTIVE_STUDENTS_READ_ERR',

  INACTIVE_STUDENTS_READ_BEGIN: 'INACTIVE_STUDENTS_READ_BEGIN',
  INACTIVE_STUDENTS_READ_SUCCESS: 'INACTIVE_STUDENTS_READ_SUCCESS',
  INACTIVE_STUDENTS_READ_ERR: 'INACTIVE_STUDENTS_READ_ERR',

  OVERALL_PERFORMANCE_BY_COHORT_BEGIN: 'OVERALL_PERFORMANCE_BY_COHORT_BEGIN',
  OVERALL_PERFORMANCE_BY_COHORT_SUCCESS: 'OVERALL_PERFORMANCE_BY_COHORT_SUCCESS',
  OVERALL_PERFORMANCE_BY_COHORT_ERR: 'OVERALL_PERFORMANCE_BY_COHORT_ERR',

  OVERALL_SUMMARY_BEGIN: 'OVERALL_SUMMARY_BEGIN',
  OVERALL_SUMMARY_SUCCESS: 'OVERALL_SUMMARY_SUCCESS',
  OVERALL_SUMMARY_ERR: 'OVERALL_SUMMARY_ERR',

  TODAYS_OVERALL_SUMMARY_BEGIN: 'TODAYS_OVERALL_SUMMARY_BEGIN',
  TODAYS_OVERALL_SUMMARY_SUCCESS: 'TODAYS_OVERALL_SUMMARY_SUCCESS',
  TODAYS_OVERALL_SUMMARY_ERR: 'TODAYS_OVERALL_SUMMARY_ERR',

  STUDENT_OVERALL_SUMMARY_BEGIN: 'STUDENT_OVERALL_SUMMARY_BEGIN',
  STUDENT_OVERALL_SUMMARY_SUCCESS: 'STUDENT_OVERALL_SUMMARY_SUCCESS',
  STUDENT_OVERALL_SUMMARY_ERR: 'STUDENT_OVERALL_SUMMARY_ERR',

  subjectWisePerformanceReadBegin: () => {
    return {
      type: actions.SUBJECT_WISE_PERFORMANCE_READ_BEGIN,
    };
  },

  subjectWisePerformanceReadSuccess: (data) => {
    return {
      type: actions.SUBJECT_WISE_PERFORMANCE_READ_SUCCESS,
      data,
    };
  },

  subjectWisePerformanceReadErr: (err) => {
    return {
      type: actions.SUBJECT_WISE_PERFORMANCE_READ_ERR,
      err,
    };
  },

  topicWisePerformanceReadBegin: () => {
    return {
      type: actions.TOPIC_WISE_PERFORMANCE_READ_BEGIN,
    };
  },

  topicWisePerformanceReadSuccess: (data) => {
    return {
      type: actions.TOPIC_WISE_PERFORMANCE_READ_SUCCESS,
      data,
    };
  },

  topicWisePerformanceReadErr: (err) => {
    return {
      type: actions.TOPIC_WISE_PERFORMANCE_READ_ERR,
      err,
    };
  },

  subjectSummaryReadBegin: () => {
    return {
      type: actions.SUBJECT_SUMMARY_READ_BEGIN,
    };
  },

  subjectSummaryReadSuccess: (data) => {
    return {
      type: actions.SUBJECT_SUMMARY_READ_SUCCESS,
      data,
    };
  },

  subjectSummaryReadErr: (err) => {
    return {
      type: actions.SUBJECT_SUMMARY_READ_ERR,
      err,
    };
  },

  singleSubjectSummaryReadBegin: () => {
    return {
      type: actions.SINGLE_SUBJECT_SUMMARY_READ_BEGIN,
    };
  },

  singleSubjectSummaryReadSuccess: (data) => {
    return {
      type: actions.SINGLE_SUBJECT_SUMMARY_READ_SUCCESS,
      data,
    };
  },

  singleSubjectSummaryReadErr: (err) => {
    return {
      type: actions.SINGLE_SUBJECT_SUMMARY_READ_ERR,
      err,
    };
  },

  subjectsBestPerformerReadBegin: () => {
    return {
      type: actions.SUBJECTS_BEST_PERFORMER_READ_BEGIN,
    };
  },

  subjectsBestPerformerReadSuccess: (data) => {
    return {
      type: actions.SUBJECTS_BEST_PERFORMER_READ_SUCCESS,
      data,
    };
  },

  subjectsBestPerformerReadErr: (err) => {
    return {
      type: actions.SUBJECTS_BEST_PERFORMER_READ_ERR,
      err,
    };
  },

  activeStudentsReadBegin: () => {
    return {
      type: actions.ACTIVE_STUDENTS_READ_BEGIN,
    };
  },

  activeStudentsReadSuccess: (data) => {
    return {
      type: actions.ACTIVE_STUDENTS_READ_SUCCESS,
      data,
    };
  },

  activeStudentsReadErr: (err) => {
    return {
      type: actions.ACTIVE_STUDENTS_READ_ERR,
      err,
    };
  },

  inactiveStudentsReadBegin: () => {
    return {
      type: actions.INACTIVE_STUDENTS_READ_BEGIN,
    };
  },

  inactiveStudentsReadSuccess: (data) => {
    return {
      type: actions.INACTIVE_STUDENTS_READ_SUCCESS,
      data,
    };
  },

  inactiveStudentsReadErr: (err) => {
    return {
      type: actions.INACTIVE_STUDENTS_READ_ERR,
      err,
    };
  },

  overallPerformanceByCohortBegin: () => {
    return {
      type: actions.OVERALL_PERFORMANCE_BY_COHORT_BEGIN,
    };
  },

  overallPerformanceByCohortSuccess: (data) => {
    return {
      type: actions.OVERALL_PERFORMANCE_BY_COHORT_SUCCESS,
      data,
    };
  },

  overallPerformanceByCohortErr: (err) => {
    return {
      type: actions.OVERALL_PERFORMANCE_BY_COHORT_ERR,
      err,
    };
  },

  overallSummaryBegin: () => {
    return {
      type: actions.OVERALL_SUMMARY_BEGIN,
    };
  },

  overallSummarySuccess: (data) => {
    return {
      type: actions.OVERALL_SUMMARY_SUCCESS,
      data,
    };
  },

  overallSummaryErr: (err) => {
    return {
      type: actions.OVERALL_SUMMARY_ERR,
      err,
    };
  },

  todayOverallSummaryBegin: () => {
    return {
      type: actions.TODAYS_OVERALL_SUMMARY_BEGIN,
    };
  },

  todayOverallSummarySuccess: (data) => {
    return {
      type: actions.TODAYS_OVERALL_SUMMARY_SUCCESS,
      data,
    };
  },

  todayOverallSummaryErr: (err) => {
    return {
      type: actions.TODAYS_OVERALL_SUMMARY_ERR,
      err,
    };
  },

  studentOverallSummaryBegin: () => {
    return {
      type: actions.STUDENT_OVERALL_SUMMARY_BEGIN,
    };
  },

  studentOverallSummarySuccess: (data) => {
    return {
      type: actions.STUDENT_OVERALL_SUMMARY_SUCCESS,
      data,
    };
  },

  studentOverallSummaryErr: (err) => {
    return {
      type: actions.STUDENT_OVERALL_SUMMARY_ERR,
      err,
    };
  },
}
export default actions;