import { 
    READ_RECENTDATA_BEGIN,
    READ_RECENTDATA_SUCCESS,
    READ_RECENTDATA_ERROR,
    
    RECENT_INTERVIEWS_BEGIN,
    RECENT_INTERVIEWS_SUCCESS,
    RECENT_INTERVIEWS_ERROR,

 } from './actions';

const initialState = {
    data: [],
    loading: false,
    error: null,
};


const recentInterviews = {
    data: [],
    loading: false,
    error: null,
};

const RecentActionsDataReducer = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case READ_RECENTDATA_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case READ_RECENTDATA_SUCCESS:
            return {
                ...state,
                data,
                error: false,
                loading: false,
            };
        case READ_RECENTDATA_ERROR:
            return {
                ...state,
                error : err,
                loading: false,
            };
        default:
            return state;
    }
};

const RecentInterviewsReducer = (state = recentInterviews, action) => {
    const { type, data, err } = action;
    switch (type) {
        case RECENT_INTERVIEWS_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case RECENT_INTERVIEWS_SUCCESS:
            return {
                ...state,
                data,
                error: false,
                loading: false,
            };
        case RECENT_INTERVIEWS_ERROR:
            return {
                ...state,
                error : err,
                loading: false,
            };
        default:
            return state;
    }
};


export { RecentActionsDataReducer, RecentInterviewsReducer };
