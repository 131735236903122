import React, { useEffect, useState } from 'react'
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Input, Table } from 'antd';
import { Button } from '../../components/buttons/buttons';
import UilSearch from '@iconscout/react-unicons/icons/uil-search';
import { DataTableStyleWrap } from '../../components/table/Style';
import { useDispatch, useSelector } from 'react-redux';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import actions from '../../redux/assessmentLogins/actions';
import { GetAssessmentLogins, UpdateStudentFlag } from '../../redux/assessmentLogins/actionCreator';
import { alertModal } from '../../components/modals/antd-modals';

const { updateStudentFlagSuccess } = actions

function AssessmentLogins() {

    const dispatch = useDispatch()

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const { AssessmentLoginsData, AssessmentLoginsLoading, FlagUpdateRes, FlagUpdateResLoading } = useSelector((state) => {
        return {
            AssessmentLoginsData: state.AssessmentLogins.data,
            AssessmentLoginsLoading: state.AssessmentLogins.loading,
            FlagUpdateRes: state.AssessmentStudentFlagUpdateRes.data,
            FlagUpdateResLoading: state.AssessmentStudentFlagUpdateRes.loading,
        }
    })

    useEffect(() => {
        dispatch(GetAssessmentLogins([]))
    }, [])

    const sectionsSet = new Set();

    AssessmentLoginsData.forEach(obj => {
        sectionsSet.add(obj.section);
    });

    const sections = [...sectionsSet]

    useEffect(() => {
        if (FlagUpdateRes !== null && FlagUpdateRes.Error === false) {
            dispatch(GetAssessmentLogins([]))
            dispatch(updateStudentFlagSuccess(null))
            setSelectedRows([])
            setSelectedRowKeys([])
        }
    }, [FlagUpdateRes])

    const columnsSort = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
        },
        {
            title: 'Roll No',
            dataIndex: 'rollno',
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.rollno > b.rollno ? 1 : -1,
            sortDirections: ['ascend'],
        },
        {
            title: 'Name',
            dataIndex: 'studentname',
            onFilter: (value, record) => record.studentname.indexOf(value) === 0,
        },
        {
            title: 'Section',
            dataIndex: 'section',
            filters: sections.map(section => ({ text: section, value: section })),
            onFilter: (value, record) => record.section === value,
        },
        {
            title: 'Student Year',
            dataIndex: 'studentyear',
        },
        {
            title: 'Login Count',
            dataIndex: 'logincount',
            sorter: (a, b) => a.logincount > b.logincount ? 1 : -1,            
        },
        {
            title: 'Last Modified',
            dataIndex: 'date',
        },
        {
            title: '',
            render: (text, record) => {
                return (
                    <Button
                        id="assessmentlogins_unlock-single-button"
                        type='primary'
                        onClick={() => {
                            EnableLoginConfirm(record)
                        }}
                        disabled={selectedRows.length > 0}
                    >
                        {/* {FlagUpdateResLoading ? 'Loading...' : 'Enable'} */}
                        Unlock
                    </Button>
                )
            }
        },
    ];

    const EnableLoginConfirm = (record) => {
        alertModal.confirm({
            title: `Unlock student with roll number ${record.rollno} for login?`,
            content: 'Please note that once unlocked, cannot be revoked.',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                if (record !== null) {
                    const acids = [record.id]
                    dispatch(UpdateStudentFlag(acids))
                }
            },
            onCancel() { },
            width: 650
        });
    };


    const [tableData, setTableData] = useState([])
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        let data = []
        AssessmentLoginsData.length !== 0 && AssessmentLoginsData.map((obj, index) => {
            const { id, checkFlag, rollno, studentname, section, studentyear, userId, LoginCount, date } = obj
            data.push({
                key: index,
                "sno": index + 1,
                id,
                userId,
                rollno,
                studentname,
                section,
                studentyear,
                logincount: LoginCount,
                date: new Date(date).toLocaleString(),
                checkFlag: checkFlag ? 'TRUE' : 'FALSE'
            })
        })
        setTableData(data)
        setFilteredData(data)
    }, [AssessmentLoginsData])

    const searchkeys = ['rollno', 'studentname']
    const searchStudent = (e) => {
        const { value } = e.currentTarget;
        const searchData = tableData.length !== 0 && tableData.filter((obj) => searchkeys.some((key) => obj[key].toLowerCase().includes(value.toLowerCase())))
        setFilteredData(searchData)
    };

    const rowSelection = (selectedRowKeys, selectedRows) => {
        setSelectedRows(selectedRows)
        setSelectedRowKeys(selectedRowKeys)
    };

    const EnableLoginConfirmBulk = () => {
        alertModal.confirm({
            title: `Unlock the following students for login?`,
            content: <>
                {
                    selectedRows.length !== 0 && selectedRows.map((row) =>
                        <p key={row.sno}>{row.rollno} - {row.studentname}</p>
                    )
                }
                <span>Please note that once unlocked, cannot be revoked.</span>
            </>,
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                if (selectedRows.length !== 0) {
                    const ids = []
                    selectedRows.length !== 0 && selectedRows.map((item) => ids.push(item.id))
                    dispatch(UpdateStudentFlag(ids))
                }
            },
            onCancel() { },
            width: 650
        });
    };

    return (
        <div>
            <Main className='pt-20'>
                <Cards title="Unlock Assessment Logins">
                    <DataTableStyleWrap>
                        <div className="d-flex align-center-v mb-20 justify-content-between">
                            <Button
                                id="assessmentlogins_unlock-bulk-button"
                                type='primary'
                                onClick={() => {
                                    EnableLoginConfirmBulk()
                                }}
                                disabled={selectedRows.length === 0}
                                className="mr-10"
                            >
                                {/* {FlagUpdateResLoading ? 'Loading...' : 'Enable'} */}
                                Unlock
                            </Button>
                            <Input id="assessmentlogins_search-input" className='' onChange={searchStudent} style={{maxWidth:'350px'}} size="default" placeholder="Search by Rollno. or Name" prefix={<UilSearch />} />
                        </div>
                        {
                            AssessmentLoginsLoading || FlagUpdateResLoading ?
                                <div style={{ width: '100%', height: '400px' }}>
                                    <div className="custom-spin">
                                        <CustomSpinner />
                                    </div>
                                </div>
                                :
                                <div>
                                    <Table
                                        className="table-responsive"
                                        pagination={{
                                            defaultPageSize: 15,
                                            total: filteredData.length,
                                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                        }}
                                        columns={columnsSort}
                                        dataSource={filteredData}
                                        tableLayout='auto'
                                        size='middle'
                                        rowSelection={{ selectedRowKeys, onChange: rowSelection }}
                                    />
                                </div>
                        }
                    </DataTableStyleWrap>
                </Cards>
            </Main>
        </div >
    )
}

export default AssessmentLogins