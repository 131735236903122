import { message, notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { getItem } from '../../utility/localStorageControl';
import { logOut } from '../authentication/actionCreator';

const {
  cohortAddBegin,
  cohortAddSuccess,
  cohortAddErr,

  cohortReadBegin,
  cohortReadSuccess,
  cohortReadErr,

  cohortUpdateBegin,
  cohortUpdateSuccess,
  cohortUpdateErr,
} = actions;

const cohortDataSubmit = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(cohortAddBegin());
      const response = await DataService.post('/cohort-master/create', data);
      const token = getItem('access_token');
      if (token && response.data.Error === false) {
        await dispatch(cohortAddSuccess(response.data));
        message.success(response.data.message,2);
      } else {
        if ( response.data.Error === true && response.data.statusCode === 401){
          message.error(response.data.message);
          dispatch(logOut());
        }
        throw response.data.message;
      }
    } catch (err) {
      await dispatch(cohortAddErr(err));
      message.error(err,2);
    }
  };
};

const cohortDataRead = () => {
  return async (dispatch) => {
    try {
      await dispatch(cohortReadBegin());
      const query = await DataService.get('/cohort-master/all');
      const token = getItem('access_token');
      if (token && query.data.Error === false) {
        await dispatch(cohortReadSuccess(query.data.payload));
      } else {
        if ( query.data.Error === true && query.data.statusCode === 401){
          message.error(query.data.message);
          dispatch(logOut());
        }
        throw query.data.message;        
      }
    } catch (err) {
      await dispatch(cohortReadErr(err));
      message.error(err);
    }
  };
};


const cohortDataUpdate = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(cohortUpdateBegin());
      const response = await DataService.put('/cohort-master/update', data);
      const token = getItem('access_token');
      if (token && response.data.Error === false) {
        await dispatch(cohortUpdateSuccess(response.data));
        message.success(response.data.message,2);
      } else {
        if ( response.data.Error === true && response.data.statusCode === 401){
          message.error(response.data.message);
          dispatch(logOut());
        }
        throw response.data.message;
      }
    } catch (err) {
      await dispatch(cohortUpdateErr(err));
      message.error(err,2);
    }
  };
};

export {
  cohortDataRead,
  cohortDataSubmit,
  cohortDataUpdate
};
