import { message, notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';

const addNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const deleteNotificationSuccess = () => {
  notification.success({
    message: 'MCQ deleted successfully',
  });
};

const deleteNotificationError = () => {
  notification.error({
    message: 'MCQ deletion failed',
  });
};

const updateNotificationSuccess = () => {
  notification.success({
    message: 'MCQ Reviewed Successfully',
  });
};

const updateNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const {
  generateQuizPostBegin,
  generateQuizPostSuccess,
  generateQuizPostErr,

  quizQuestionsReadBegin,
  quizQuestionsReadSuccess,
  quizQuestionsReadErr,

  QuizUpdateBegin,
  QuizUpdateSuccess,
  QuizUpdateErr,

  deleteQuizBegin,
  deleteQuizSuccess,
  deleteQuizErr,

  getMCQSBegin,
  getMCQSSuccess,
  getMCQSErr

} = actions;


const generateQuizDataSubmit = (formData) => {
  return async (dispatch) => {
    try {
      await dispatch(generateQuizPostBegin());
      const response = await DataService.post('/topicmaster/text-to-quiz', formData);
      const token = getItem('access_token');
      const data = response.data
      if (token && data !== null && data.Error === false) {
        await dispatch(generateQuizPostSuccess(data));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(generateQuizPostSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(generateQuizPostSuccess(null));
        }
      }
      else {
        await dispatch(generateQuizPostSuccess(null));
      }
    } catch (err) {
      await dispatch(generateQuizPostErr(err));
      addNotificationError(err);
    }
  };
};

const QuizQuestionsDataRead = (topicId) => {
  return async (dispatch) => {
    try {
      await dispatch(quizQuestionsReadBegin());
      const query = await DataService.get(`/topicmaster/quiz/${topicId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(quizQuestionsReadSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(quizQuestionsReadSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(quizQuestionsReadSuccess(null));
        }
      }
      else {
        await dispatch(quizQuestionsReadSuccess(null));
      }
    } catch (err) {
      await dispatch(quizQuestionsReadErr(err));
    }
  };
};

const QuizDataUpdate = (formData) => {
  return async (dispatch) => {
    try {
      await dispatch(QuizUpdateBegin());
      const response = await DataService.put(`/quizmaster/questionupdate`, formData);
      const token = getItem('access_token');
      const data = response.data
      if (token && data !== null && data.Error === false) {
        await dispatch(QuizUpdateSuccess(data));
        updateNotificationSuccess();
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(QuizUpdateSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(QuizUpdateSuccess(null));
        }
      }
      else {
        await dispatch(QuizUpdateSuccess(null));
      }
    } catch (err) {
      await dispatch(QuizUpdateErr(err));
      updateNotificationError(err);
    }
  };
};

const deleteQuiz = (quizId, topicId) => {
  return async (dispatch) => {
    try {
      await dispatch(deleteQuizBegin());
      const query = await DataService.delete(`/topicmaster/quiz/delete/${topicId}/${quizId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token) {
        if (data !== null && data.Error === false) {
          deleteNotificationSuccess()
          await dispatch(deleteQuizSuccess(data));
        }
        else if (data !== null && data.Error === true) {
          if (Object.keys(data).length >= 3 && data.statusCode == 401) {
            message.error(data.message)
            await dispatch(deleteQuizSuccess(null));
            dispatch(logOut())
          }
          else {
            deleteNotificationError()
            await dispatch(deleteQuizSuccess(null));
          }
        }
        else {
          await dispatch(deleteQuizSuccess(null));
        }
      } else {
        // await dispatch(generateQuizErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(deleteQuizErr(err));
    }
  };
}

const getQuizByTopic = (topicId) => {
  return async (dispatch) => {
    try {
      await dispatch(getMCQSBegin());
      const query = await DataService.get(`/topicmaster/quizquestions/${topicId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(getMCQSSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(getMCQSSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(getMCQSSuccess(null));
        }
      }
      else {
        await dispatch(getMCQSSuccess(null));
        // await dispatch(generateQuizErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(getMCQSErr(err));
    }
  };
};

export {
  generateQuizDataSubmit,
  QuizQuestionsDataRead,
  QuizDataUpdate,
  deleteQuiz,
  getQuizByTopic
};
