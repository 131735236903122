const actions = {
  GET_INTERVIEW_QUESTIONS_BEGIN: 'GET_INTERVIEW_QUESTIONS_BEGIN',
  GET_INTERVIEW_QUESTIONS_SUCCESS: 'GET_INTERVIEW_QUESTIONS_SUCCESS',
  GET_INTERVIEW_QUESTIONS_ERR: 'GET_INTERVIEW_QUESTIONS_ERR',

  SAVE_INTERVIEW_BEGIN: 'SAVE_INTERVIEW_BEGIN',
  SAVE_INTERVIEW_SUCCESS: 'SAVE_INTERVIEW_SUCCESS',
  SAVE_INTERVIEW_ERR: 'SAVE_INTERVIEW_ERR',

  SKIP_INTERVIEW_BEGIN: 'SKIP_INTERVIEW_BEGIN',
  SKIP_INTERVIEW_SUCCESS: 'SKIP_INTERVIEW_SUCCESS',
  SKIP_INTERVIEW_ERR: 'SKIP_INTERVIEW_ERR',

  SKIP_AND_SUBMIT_INTERVIEW_BEGIN: 'SKIP_AND_SUBMIT_INTERVIEW_BEGIN',
  SKIP_AND_SUBMIT_INTERVIEW_SUCCESS: 'SKIP_AND_SUBMIT_INTERVIEW_SUCCESS',
  SKIP_AND_SUBMIT_INTERVIEW_ERR: 'SKIP_AND_SUBMIT_INTERVIEW_ERR',

  SUBMIT_INTERVIEW_BEGIN: 'SUBMIT_INTERVIEW_BEGIN',
  SUBMIT_INTERVIEW_SUCCESS: 'SUBMIT_INTERVIEW_SUCCESS',
  SUBMIT_INTERVIEW_ERR: 'SUBMIT_INTERVIEW_ERR',

  GET_INTERVIEW_BY_TOPIC_ID_BEGIN: 'GET_INTERVIEW_BY_TOPIC_ID_BEGIN',
  GET_INTERVIEW_BY_TOPIC_ID_SUCCESS: 'GET_INTERVIEW_BY_TOPIC_ID_SUCCESS',
  GET_INTERVIEW_BY_TOPIC_ID_ERR: 'GET_INTERVIEW_BY_TOPIC_ID_ERR',

  GET_INTERVIEW_BY_SCHEDULE_BEGIN: 'GET_INTERVIEW_BY_SCHEDULE_BEGIN',
  GET_INTERVIEW_BY_SCHEDULE_SUCCESS: 'GET_INTERVIEW_BY_SCHEDULE_SUCCESS',
  GET_INTERVIEW_BY_SCHEDULE_ERR: 'GET_INTERVIEW_BY_SCHEDULE_ERR',

  GET_INTERVIEW_ATTEMPTS_BEGIN: 'GET_INTERVIEW_ATTEMPTS_BEGIN',
  GET_INTERVIEW_ATTEMPTS_SUCCESS: 'GET_INTERVIEW_ATTEMPTS_SUCCESS',
  GET_INTERVIEW_ATTEMPTS_ERR: 'GET_INTERVIEW_ATTEMPTS_ERR',

  QUIT_TIMEOUT_INTERVIEW_BEGIN: 'QUIT_TIMEOUT_INTERVIEW_BEGIN',
  QUIT_TIMEOUT_INTERVIEW_SUCCESS: 'QUIT_TIMEOUT_INTERVIEW_SUCCESS',
  QUIT_TIMEOUT_INTERVIEW_ERR: 'QUIT_TIMEOUT_INTERVIEW_ERR',

  GET_STUDENT_INTERVIEWS_BEGIN: 'GET_STUDENT_INTERVIEWS_BEGIN',
  GET_STUDENT_INTERVIEWS_SUCCESS: 'GET_STUDENT_INTERVIEWS_SUCCESS',
  GET_STUDENT_INTERVIEWS_ERR: 'GET_STUDENT_INTERVIEWS_ERR',


  getInterviewQuestionsBegin: () => {
    return {
      type: actions.GET_INTERVIEW_QUESTIONS_BEGIN,
    };
  },

  getInterviewQuestionsSuccess: (data) => {
    return {
      type: actions.GET_INTERVIEW_QUESTIONS_SUCCESS,
      data,
    };
  },

  getInterviewQuestionsErr: (err) => {
    return {
      type: actions.GET_INTERVIEW_QUESTIONS_ERR,
      err,
    };
  },

  saveInterviewBegin: () => {
    return {
      type: actions.SAVE_INTERVIEW_BEGIN,
    };
  },

  saveInterviewSuccess: (data) => {
    return {
      type: actions.SAVE_INTERVIEW_SUCCESS,
      data,
    };
  },

  saveInterviewErr: (err) => {
    return {
      type: actions.SAVE_INTERVIEW_ERR,
      err,
    };
  },

  skipInterviewBegin: () => {
    return {
      type: actions.SKIP_INTERVIEW_BEGIN,
    };
  },

  skipInterviewSuccess: (data) => {
    return {
      type: actions.SKIP_INTERVIEW_SUCCESS,
      data,
    };
  },

  skipInterviewErr: (err) => {
    return {
      type: actions.SKIP_INTERVIEW_ERR,
      err,
    };
  },

  skipandSubmitInterviewBegin: () => {
    return {
      type: actions.SKIP_AND_SUBMIT_INTERVIEW_BEGIN,
    };
  },

  skipandSubmitInterviewSuccess: (data) => {
    return {
      type: actions.SKIP_AND_SUBMIT_INTERVIEW_SUCCESS,
      data,
    };
  },

  skipandSubmitInterviewErr: (err) => {
    return {
      type: actions.SKIP_AND_SUBMIT_INTERVIEW_ERR,
      err,
    };
  },

  submitInterviewBegin: () => {
    return {
      type: actions.SUBMIT_INTERVIEW_BEGIN,
    };
  },

  submitInterviewSuccess: (data) => {
    return {
      type: actions.SUBMIT_INTERVIEW_SUCCESS,
      data,
    };
  },

  submitInterviewErr: (err) => {
    return {
      type: actions.SUBMIT_INTERVIEW_ERR,
      err,
    };
  },

  getInterviewByTopicBegin: () => {
    return {
      type: actions.GET_INTERVIEW_BY_TOPIC_ID_BEGIN,
    };
  },

  getInterviewByTopicSuccess: (data) => {
    return {
      type: actions.GET_INTERVIEW_BY_TOPIC_ID_SUCCESS,
      data,
    };
  },

  getInterviewByTopicErr: (err) => {
    return {
      type: actions.GET_INTERVIEW_BY_TOPIC_ID_ERR,
      err,
    };
  },

  getInterviewByScheduleBegin: () => {
    return {
      type: actions.GET_INTERVIEW_BY_SCHEDULE_BEGIN,
    };
  },

  getInterviewByScheduleSuccess: (data) => {
    return {
      type: actions.GET_INTERVIEW_BY_SCHEDULE_SUCCESS,
      data,
    };
  },

  getInterviewByScheduleErr: (err) => {
    return {
      type: actions.GET_INTERVIEW_BY_SCHEDULE_ERR,
      err,
    };
  },

  getInterviewAttemptsBegin: () => {
    return {
      type: actions.GET_INTERVIEW_ATTEMPTS_BEGIN,
    };
  },

  getInterviewAttemptsSuccess: (data) => {
    return {
      type: actions.GET_INTERVIEW_ATTEMPTS_SUCCESS,
      data,
    };
  },

  getInterviewAttemptsErr: (err) => {
    return {
      type: actions.GET_INTERVIEW_ATTEMPTS_ERR,
      err,
    };
  },

  quitortimeoutInterviewBegin: () => {
    return {
      type: actions.QUIT_TIMEOUT_INTERVIEW_BEGIN,
    };
  },

  quitortimeoutInterviewSuccess: (data) => {
    return {
      type: actions.QUIT_TIMEOUT_INTERVIEW_SUCCESS,
      data,
    };
  },

  quitortimeoutInterviewErr: (err) => {
    return {
      type: actions.QUIT_TIMEOUT_INTERVIEW_ERR,
      err,
    };
  },

  getStudentInterviewsBegin: () => {
    return {
      type: actions.GET_STUDENT_INTERVIEWS_BEGIN,
    };
  },

  getStudentInterviewsSuccess: (data) => {
    return {
      type: actions.GET_STUDENT_INTERVIEWS_SUCCESS,
      data,
    };
  },

  getStudentInterviewsErr: (err) => {
    return {
      type: actions.GET_STUDENT_INTERVIEWS_ERR,
      err,
    };
  },

};

export default actions;
