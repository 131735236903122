import react, { useEffect, useState } from 'react'
import { Main } from '../styled';
import { Col, Collapse, Input, Radio, Result, Row, Select } from 'antd';
import { Cards } from '../../components/cards/frame/cards-frame';
import { NoteCardWrap } from '../note/style';
import { ProjectSorting } from '../project/style';
import { SelectWrapperStyle } from '../ui-elements/ui-elements-styled';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import { getQAByTopic } from '../../redux/generateQuestions/actionCreator';
import TextArea from 'antd/lib/input/TextArea';
import { AddUser, FaqWrapper } from './styles/tablestyle';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';
import UilMinus from '@iconscout/react-unicons/icons/uil-minus';
import TopicStatusId from './TopicStatusId';
import { Button } from '../../components/buttons/buttons';

const { Option } = Select;
const { Panel } = Collapse;

function ViewQuestions({ topicStatus }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { subjectId, unitId, topicId } = useParams()

    const [selectedCategory, setCategory] = useState("Easy")
    const [QA, setQA] = useState([])

    const { topicData, isLoading } = useSelector((state) => {
        return {
            topicData: state.AllQAByTopic.data,
            isLoading: state.AllQAByTopic.loading,
        };
    });

    useEffect(() => {
        if (TopicStatusId(topicStatus) > 2) {
            dispatch(getQAByTopic(parseInt(topicId)));
        }
    }, [topicId]);

    useEffect(() => {
        if (topicData !== null && topicData.qas[`${selectedCategory.toLocaleLowerCase()}`].length !== 0) {
            setQA(topicData.qas[`${selectedCategory.toLocaleLowerCase()}`])
            // setCurrentQA({"question":topicData.qas[`${selectedCategory.toLocaleLowerCase()}`][selectedQuestion].question,"answer":topicData.qas[`${selectedCategory.toLocaleLowerCase()}`][selectedQuestion].answer})
        }
        else if (topicData !== null && topicData.qas[`${selectedCategory.toLocaleLowerCase()}`].length === 0) {
            setQA([])
        }
    }, [topicData, selectedCategory]);

    return (
        <>
            <Row gutter={25} justify="center">
                <Col lg={24} xs={24}>
                    <NoteCardWrap>
                        {
                            TopicStatusId(topicStatus) === 0 ?
                                <Result
                                    status="warning"
                                    title="Content not found"
                                    extra={
                                        <Button id="viewqa_uploadcontent-button" type="primary" key="upload" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/upload`)}>
                                            Upload
                                        </Button>
                                    }
                                />
                                :
                                TopicStatusId(topicStatus) === 2 ?
                                    <Result
                                        status="warning"
                                        title="Questions and Answers not found"
                                        extra={
                                            <Button id="viewqa_generateqa-button" type="primary" key="upload" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/generate/questions`)}>
                                                Generate Q & A
                                            </Button>
                                        }
                                    />
                                    :
                                    isLoading ?
                                        <div className='custom-spin'>
                                            <CustomSpinner />
                                        </div>
                                        :
                                        TopicStatusId(topicStatus) > 2 ?
                                            <div>
                                                <div className='align-center-v d-flex justify-content-between'>
                                                    <h3>Q & A
                                                        {/* : {topicData !== null && topicData.name} */}
                                                    </h3>
                                                    <ProjectSorting>
                                                        <div className="project-sort-bar">
                                                            <div className="project-sort-nav" style={{ boxShadow: '0px 0px 15px 0px #eee', borderRadius: '6px' }}>
                                                                {/* border:'1px solid #e3e6ef',padding:'0px' */}
                                                                <nav>
                                                                    <ul>
                                                                        <li className={selectedCategory === 'Easy' ? 'active' : 'deactivate'}>
                                                                            <Link id="viewqa_easy-button" onClick={() => { setCategory('Easy') }} to="#">
                                                                                Easy ({topicData !== null && topicData.qas[`easy`].length})
                                                                            </Link>
                                                                        </li>
                                                                        <li className={selectedCategory === 'Medium' ? 'active' : 'deactivate'}>
                                                                            <Link id="viewqa_medium-button" onClick={() => { setCategory('Medium') }} to="#">
                                                                                Medium ({topicData !== null && topicData.qas[`medium`].length})
                                                                            </Link>
                                                                        </li>
                                                                        <li className={selectedCategory === 'Hard' ? 'active' : 'deactivate'}>
                                                                            <Link id="viewqa_hard-button" onClick={() => { setCategory('Hard') }} to="#">
                                                                                Hard ({topicData !== null && topicData.qas[`hard`].length})
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                </nav>
                                                            </div>
                                                        </div>
                                                    </ProjectSorting>
                                                </div>
                                                <Row justify="center">
                                                    <Col xs={24}>
                                                        <FaqWrapper>
                                                            {
                                                                QA.length !== 0 ? QA.map((Object, index) =>
                                                                    <MyForm key={index} index={index + 1} currentQA={Object} />
                                                                )
                                                                    :
                                                                    <div className='spin' style={{ fontSize: '18px' }}>
                                                                        No Data Found
                                                                    </div>
                                                            }
                                                        </FaqWrapper>
                                                    </Col>
                                                </Row>
                                            </div>
                                            :
                                            <Result
                                                status="warning"
                                                title="Questions not found!"
                                                extra={
                                                    <Button id="viewqa_generateqa-button1" type="primary" key="generateQA" onClick={() => navigate(`/teacher/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/generate/questions`)}>
                                                        Generate Q & A
                                                    </Button>
                                                }
                                            />
                        }
                    </NoteCardWrap>
                </Col>
            </Row>
        </>
    );
}

export default ViewQuestions


const MyForm = ({ currentQA, index }) => {
    return (
        <div>
            <Collapse defaultActiveKey={['1']} bordered={false} expandIcon={({ isActive }) => (isActive ? <UilMinus /> : <UilPlus />)} className='m-0 mb-5' >
                <Panel header={index + '. ' + currentQA.question} key={index}>
                    <h4>Answer</h4>
                    <p style={{ textAlign: 'justify' }} className='m-0'>
                        {currentQA.answer}
                    </p>
                </Panel>
            </Collapse>
        </div>
    );
}