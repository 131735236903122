import { 
    READ_ContentFeedback_BEGIN,
    READ_ContentFeedback_SUCCESS,
    READ_ContentFeedback_ERROR,

    GET_FB_QUESTIONS_BEGIN,
    GET_FB_QUESTIONS_SUCCESS,
    GET_FB_QUESTIONS_ERROR,

    VERIFY_FEEDBACK_BEGIN,
    VERIFY_FEEDBACK_SUCCESS,
    VERIFY_FEEDBACK_ERROR,

    POST_FEEDBACK_BEGIN,
    POST_FEEDBACK_SUCCESS,
    POST_FEEDBACK_ERROR
 } from './actions';

const initialState = {
    data: [],
    loading: false,
    error: null,
};

const feedbackQuestions = {
    data: [],
    loading: false,
    error: null,
};

const verifyInitialState = {
    data: null,
    loading: false,
    error: null,
};

const postInitialState = {
    data: null,
    loading: false,
    error: null,
};

// teacher
const ContentFeedbackReducer = (state = initialState, action) => {
    switch (action.type) {
        case READ_ContentFeedback_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case READ_ContentFeedback_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false,
            };
        case READ_ContentFeedback_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

// student 
const GetContentFBQReducer = (state = feedbackQuestions, action) => {
    switch (action.type) {
        case GET_FB_QUESTIONS_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case GET_FB_QUESTIONS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false,
            };
        case GET_FB_QUESTIONS_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

// student
const VerifyFeedbackReducer = (state = verifyInitialState, action) => {
    switch (action.type) {
        case VERIFY_FEEDBACK_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case VERIFY_FEEDBACK_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false,
            };
        case VERIFY_FEEDBACK_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

// student
const PostFeedbackReducer = (state = postInitialState, action) => {
    switch (action.type) {
        case POST_FEEDBACK_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case POST_FEEDBACK_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false,
            };
        case POST_FEEDBACK_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};


export { ContentFeedbackReducer, GetContentFBQReducer, VerifyFeedbackReducer, PostFeedbackReducer };
