import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Select, message, Table, Form, Card, Row, Col } from 'antd';
import { Button } from '../../components/buttons/buttons';
import { NoteCardWrap } from '../note/style';
import { useDispatch, useSelector } from 'react-redux';
import UilExport from "@iconscout/react-unicons/icons/uil-export";
import UilPlus from "@iconscout/react-unicons/icons/uil-plus";
import Papa from 'papaparse';
import ImportQuestions from './ImportQuestions';
import { getTopicById } from '../../redux/topics/actionCreator';
import { alertModal } from '../../components/modals/antd-modals';
import Generate_Actions from '../../redux/teacherSubjectsUnits/actions'
import { useForm } from 'antd/lib/form/Form';
import { GenerateQuestionsOfTopic } from '../../redux/teacherSubjectsUnits/actionCreator';
import { GetQuestionsOfTopicDataRead } from '../../redux/teacherSubjectsUnits/actionCreator';
import Dragger from 'antd/lib/upload/Dragger';
import { DownloadOutlined } from '@ant-design/icons';
import { ImportQuestionsOfTopic } from '../../redux/teacherSubjectsUnits/actionCreator';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import AddQuestion from './AddQuestion';
import { Cards } from '../../components/cards/frame/cards-frame';

const { generateQuestionsofTopicReadSuccess } = Generate_Actions

const { Option } = Select;

function GenerateQuestions({ customWidth }) {

  const { topicName, topicId } = useParams()

  const dispatch = useDispatch();
  const [generateForm] = useForm()
  const [importform] = Form.useForm();
  const [createQuestionModal, setCreateQuestionModal] = useState(false);


  const { isImportQuestionsDataLoading, ImportQuestionsData, GenerateQuestionsData, isGenerateQuestionsLoading, CreateQuestionData, isCreateQuestionDataLoading } = useSelector((state) => {
    return {
      GenerateQuestionsData: state.GenerateQuestions.data,
      isGenerateQuestionsLoading: state.GenerateQuestions.loading,
      // QuestionsOfTopic: state.QuestionsOfTopic.data,
      // isQuestionsOfTopicLoading: state.QuestionsOfTopic.loading,
      ImportQuestionsData: state.ImportQuestions.data,
      isImportQuestionsDataLoading: state.ImportQuestions.loading,
      CreateQuestionData: state.CreateQuestion.data,
      isCreateQuestionDataLoading: state.CreateQuestion.loading,
    };
  });

  useEffect(() => {
    if (CreateQuestionData !== null && CreateQuestionData.Error === false) {
      dispatch(getTopicById(parseInt(topicId)));
    }
  }, [CreateQuestionData])

  useEffect(() => {
    if (GenerateQuestionsData !== null && GenerateQuestionsData.Error === false) {
      dispatch(GetQuestionsOfTopicDataRead(parseInt(topicId)));
      dispatch(getTopicById(parseInt(topicId)));
    }
    dispatch(generateQuestionsofTopicReadSuccess(null))
  }, [GenerateQuestionsData]);

  useEffect(() => {
    if (ImportQuestionsData !== null && ImportQuestionsData.Error === false) {
      dispatch(getTopicById(topicId));
      dispatch(GetQuestionsOfTopicDataRead(topicId));
    }
  }, [ImportQuestionsData])

  const sampleCSVData = [
    {
      question: "Features of java 8",
      difficultylevel: "intermediate",
    },
    {
      question: "What is an object?",
      difficultylevel: "easy",
    },
    {
      question: "Purpose of using TreeMap",
      difficultylevel: "advanced",
    },
  ];

  const CSVColumns = [
    {
      title: 'Question',
      key: 'question',
      dataIndex: 'question',
    },
    {
      title: 'Difficulty Level',
      key: 'difficultylevel',
      dataIndex: 'difficultylevel',
    },
  ];

  function handleGenerate(values) {
    alertModal.confirm({
      title: `You have chosen to generate questions for ${topicName}`,
      content: <div>
        <span> Questions: {values.questionCount}</span><br />
        {/* <span> Intermediate: {values.intermediate}</span><br />
        <span> Advanced: {values.advanced}</span><br /> */}
      </div>,
      onOk() {
        const dispatchData = {
          topicId: parseInt(topicId),
          questionCount: values.questionCount,
          // intermediate: values.intermediate,
          // advance: values.advanced,
        }
        dispatch(GenerateQuestionsOfTopic(dispatchData));
      },
      onCancel() { },
    });
  }

  const [csvFileList, setCSVFileList] = useState([]);
  const [csvFile, setCSVFile] = useState(null);
  const [previewtabledata, setpreviewtabledata] = useState([]);
  const [PreviewModal, setPreviewModal] = useState(false);
  const [importModal, setimportModal] = useState(false);

  const [importPageVisible, setimportPageVisible] = useState(false);
  const handleImportQuestions = () => {
    setimportPageVisible(true);

  }

  const handleUploadQuestionsSubmit = () => {
    dispatch(ImportQuestionsOfTopic(previewtabledata))
    setPreviewModal(false);
    setpreviewtabledata([]);
  }

  const handlePreviewQuestionsImport = () => {
    importform.validateFields().then((values) => {

      Papa.parse(csvFile, {
        complete: (result) => {
          const invalidHeaders = result.meta.fields.filter(item => item !== "question" && item !== "difficultylevel");
          if (invalidHeaders.length > 0) {
            message.error("Invalid columns found, only question and difficultylevel columns are allowed", 3)
            return;
          }
          const EmptyCells = result.data.filter((row) => {
            return Object.values(row).every((cell) => cell.trim() !== '');
          });
          if (EmptyCells.length !== result.data.length) {
            message.error("Empty values found in the uploaded CSV file. Please make sure all cells are filled.", 3);
            return;
          }
          const filteredData = result.data.filter((row) => Object.values(row).some((cell) => cell.trim() !== ''));
          const filteredCSVData = filteredData.map((row) => (
            row !== null && {
              question: row.question,
              difficultylevel: row.difficultylevel,
              tags: [],
              topicId: parseInt(topicId),
            }
          ));
          setpreviewtabledata(filteredCSVData)
          importform.resetFields();
          setimportModal(false);
          setPreviewModal(true);
        },
        header: true,
      });
    })
      .catch((error) => {
        console.error('Error while validating form:', error);
      });
  }
  const [Clicked, setClicked] = useState(false);
  const handleClick = () => {
    setClicked(true)
  }


  const handleCreateQuestion = () => {
    setCreateQuestionModal(true);
  }



  return (
    <>
      <NoteCardWrap style={customWidth} className='d-flex justify-content-center mt-20'>


        {importPageVisible &&
          <div className='w-100'>
            <ImportQuestions backButton={<Button type='primary' transparented className="mr-10" onClick={() => setimportPageVisible(false)}>Back</Button>} />
          </div>
        }

        {
          !Clicked && !importPageVisible &&
          <div className='w-100'>
            <Row className='mt-20' gutter={20} style={{ flexWrap: 'wrap' }} >
              <Col xs={8} className='generate-q-col'>
                <div className='p-20 h-100 col-card'>
                  <div>
                    <h3 className='mb-0'>Description</h3>
                    <p className='m-0 text-justify fontsize-14'>
                      This option allows you to automatically generate a specified number of viva-type questions.
                      Viva questions are oral questions used to assess a student's understanding of a subject.
                      Selecting this option will streamline the process of creating these questions,
                      ensuring you have a ready set of questions without manually drafting each one.
                    </p>
                    <h3 className='mt-10 mb-0'>Instructions</h3>
                    <ul style={{ listStyle: 'inside', listStyleType: 'disc' }} className='fontsize-14 mb-20'>
                      <li>
                        Select the number of viva questions you need.
                      </li>
                      <li>
                        Click the "Generate" button.
                      </li>
                    </ul>
                  </div>
                  <Button type='primary' className="w-100" onClick={handleClick}>Auto Generate</Button>
                </div>
              </Col>
              <Col xs={8} className='generate-q-col'>
                <div className='p-20 h-100 col-card'>
                  <div>
                    <h3 className='mb-0'>Description</h3>
                    <p className='m-0 text-justify fontsize-14'>
                      This option lets you import questions from an external CSV file.
                      The CSV file can contain both Viva-type (oral) and Theory-type (written) questions.
                      This feature is useful for integrating a pre-existing set of questions into the system.
                    </p>
                    <h3 className='mt-10 mb-0'>Instructions</h3>
                    <ul style={{ listStyle: 'inside', listStyleType: 'disc' }} className='fontsize-14 mb-20'>
                      <li className='text-justify'>
                        Prepare a CSV file with your questions. Ensure the file is formatted correctly,
                        with appropriate headers for viva and theory questions.
                      </li>
                      <li className='text-justify'>
                        Select your CSV file from your device and click on "Preview" button then click on "Submit" button.
                      </li>
                      <span className='fontsize-12'>Note: If file content is not valid then submit button will not appear.</span>
                    </ul>
                  </div>
                  <Button type='primary' className="w-100" onClick={handleImportQuestions}>Import</Button>
                </div>
              </Col>
              <Col xs={8} className='generate-q-col'>
                <div className='p-20 h-100 col-card'>
                  <div>
                    <h3 className='mb-0'>Description</h3>
                    <p className='m-0 text-justify fontsize-14'>
                      This option provides the flexibility to manually add any type of question,
                      including Viva, Theory, Code, Jumbled Code, Debug Code, Explain Code, or Predict Output. This feature is designed for situations where you need to create
                      specific questions that are not covered by the automated generation or import options.
                    </p>
                    <h3 className='mt-10 mb-0'>Instructions</h3>
                    <ul style={{ listStyle: 'inside', listStyleType: 'disc' }} className='fontsize-14 mb-20'>
                      <li className='text-justify'>
                        Click the "Add Question" button.
                      </li>
                      <li className='text-justify'>
                        Fill in all the required fields and click on "Submit" button.
                      </li>                      
                    </ul>
                  </div>
                  <Button type='primary' className="w-100" onClick={handleCreateQuestion}>Add Question</Button>
                </div>
              </Col>
            </Row>


          </div>
        }

        {Clicked &&
          <>

            {
              isImportQuestionsDataLoading ?
                <div className='custom-spin'>
                  <CustomSpinner Message={'Questions are importing... Please wait.'} marginTop={40} />
                </div>
                :
                isGenerateQuestionsLoading ?
                  <div className='custom-spin'>
                    <CustomSpinner Message={'Questions are being generating... Please wait.'} marginTop={40} />
                  </div>
                  :
                  <div className='mt-20'>
                    {/* <Heading as="h6" className='mb-20 mt-20'>Select Question Type and Number</Heading> */}
                    <Form form={generateForm} layout='vertical' initialValues={{ 'questionCount': 20 }} onFinish={handleGenerate}>
                      <Form.Item name='questionCount' label="No. of questions" rules={[{ required: true, message: 'Select no. of questions' }]}>
                        <Select id="generateQA_easy-count" placeholder="Select" style={{ width: 400 }} allowClear>
                          <Option value={10}>10</Option>
                          <Option value={20}>20</Option>
                          <Option value={30}>30</Option>
                          <Option value={40}>40</Option>
                          <Option value={50}>50</Option>
                        </Select>
                      </Form.Item>
                      {/* <Form.Item name="intermediate" label="Intermediate" rules={[{ required: true, message: 'Select Intermediate' }]}>
                        <Select id="generateQA_intermediate-count" style={{ width: 400 }} allowClear>                          
                          <Option value={10}>10</Option>
                          <Option value={20}>20</Option>
                          <Option value={30}>30</Option>
                          <Option value={40}>40</Option>
                          <Option value={50}>50</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item name="advanced" label="Advanced" rules={[{ required: true, message: 'Select Advanced' }]}>
                        <Select id="generateQA_advanced-count" style={{ width: 400 }} allowClear>                          
                          <Option value={10}>10</Option>
                          <Option value={20}>20</Option>
                          <Option value={30}>30</Option>
                          <Option value={40}>40</Option>
                          <Option value={50}>50</Option>
                        </Select>
                      </Form.Item> */}
                      <div className='d-flex justify-content-end'>
                        <Button type='primary' transparented className="mr-10" onClick={() => setClicked(false)}>Back</Button>
                        <Button id="generateQA_generate-button" htmlType="submit" type="primary" disabled={isGenerateQuestionsLoading} >
                          Generate
                        </Button>
                      </div>
                    </Form>
                  </div>
            }
          </>
        }
      </NoteCardWrap>

      <AddQuestion createQuestionModal={createQuestionModal} setCreateQuestionModal={setCreateQuestionModal} />

      <Modal
        title="Import Questions"
        open={importModal}
        onCancel={() => { setimportModal(false); importform.resetFields(); }}
        maskClosable={false}
        footer={[
          <Button id="import-question-button" key="importQuestionButton" type="primary" onClick={handlePreviewQuestionsImport}>
            Preview
          </Button>,
        ]}
        centered
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ fontSize: '17px', color: 'black', marginRight: '5px', marginBottom: '0' }}>Sample CSV File:</p>
          <span className="download-text" style={{ marginLeft: '10px', marginRight: '5px', marginBottom: '0', fontSize: '17px' }}>sample.csv</span>
          <DownloadOutlined id="download-sample-csv-file"
            style={{ verticalAlign: 'middle' }}
            className="download-icon"
            onClick={() => {
              const csvContent = Papa.unparse(sampleCSVData);
              const blob = new Blob([csvContent], { type: "text/csv" });
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = "samplequestions.csv";
              link.click();
            }}
          />
        </div>
        <Form id="import-question-form" form={importform} layout="vertical">
          <Form.Item
            name="csvFile"
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              const fileList = e && e.fileList.slice(-1);
              setCSVFileList(fileList);
              return fileList;
            }}
            rules={[
              { required: true, message: 'Please upload a CSV file!' },
              ({ fileList }) => ({
                validator(_, value) {
                  const file = fileList && fileList[0];
                  if (file) {
                    const fileName = file.name.toLowerCase();
                    if (fileName.endsWith('.csv')) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Please upload a CSV file!');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Dragger
              accept=".csv"
              beforeUpload={(file) => {
                setCSVFile(file);
                return false;
              }}
              fileList={csvFileList}
            >
              <p className="ant-upload-drag-icon">
                <UilExport />
              </p>
              <p className="ant-upload-text">Drag or upload .csv file</p>
            </Dragger>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Preview Questions"
        open={PreviewModal}
        onCancel={() => { setPreviewModal(false); setpreviewtabledata([]) }}
        maskClosable={false}
        footer={[
          <Button id="preview--question-submit-button" key="previewQuestionButton" type="primary" onClick={handleUploadQuestionsSubmit}>
            Submit
          </Button>,
        ]}
        width={1000}
      >
        <Table dataSource={previewtabledata} columns={CSVColumns} pagination={true} />
      </Modal>
    </>
  );
}

export default GenerateQuestions;
