import React from 'react';
import { Cards } from '../../components/cards/frame/cards-frame';
import Heading from '../../components/heading/heading';
import { NoteCardWrap } from '../note/style';
import { Col, Result, Row } from 'antd';
import './customstyles.css'
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';

function VideoPage({ topicsLoading, videoURL, topicName }) {

    const formattedUrl = (url) => {
        if (url !== "null") {
            const videoId = url && url.match(/(?:\/|v=|be\/|embed\/)([\w-]{11})/)[1];
            return `https://www.youtube.com/embed/${videoId}`;
        }
        else {
            return null
        }
    }

    return (
        <div>
            <Cards headless>
                <Heading as={'h3'} className='text-center'>{topicName}</Heading>
                <NoteCardWrap>
                    {
                        topicsLoading ?
                            <div className="custom-spin">
                                <CustomSpinner />
                            </div>
                            :
                            formattedUrl(videoURL) !== null ?
                                <div className='mt-25 text-center'>
                                    <iframe
                                        className='videoframe'
                                        src={formattedUrl(videoURL)}
                                        title="Video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen>
                                    </iframe>
                                </div>
                                :
                                <div className='custom-spin'>
                                    <Result
                                        status={'warning'}
                                        title="Video not available"
                                    />
                                </div>
                    }
                </NoteCardWrap>
            </Cards>
        </div>
    );
}

export default VideoPage;