import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { message } from 'antd';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';

const {

  createTopicBegin,
  createTopicSuccess,
  createTopicErr,

  topicSingleReadBegin,
  topicSingleReadSuccess,
  topicSingleReadErr,

  topicStatusUpdateBegin,
  topicStatusUpdateSuccess,
  topicStatusUpdateErr,

  topicsReadBegin,
  topicsReadSuccess,
  topicsReadErr,

  clearTopicBegin,
  clearTopicSuccess,
  clearTopicErr,

  studentTopicsReadBegin,
  studentTopicsReadSuccess,
  studentTopicsReadErr,

  topicsBySubjectIdBegin,
  topicsBySubjectIdSuccess,
  topicsBySubjectIdErr,
  
  importTopicBegin,
  importTopicSuccess,
  importTopicErr,

} = actions;


const CreateNewTopic = (formData) => {
  return async (dispatch) => {
    try {
      await dispatch(createTopicBegin());
      const query = await DataService.post(`/topic-master/create`, formData);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        message.success(data.message)
        await dispatch(createTopicSuccess(data));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(createTopicSuccess(null));
          dispatch(logOut())
        }
        else if (data !== null && data.Error === true) {
          message.error(data.message)
          await dispatch(createTopicSuccess(null));
        }
      }
      else {
        await dispatch(createTopicSuccess(null));
      }
    } catch (err) {
      await dispatch(createTopicErr(err));
    }
  };
};

const getTopicById = (topicId) => {
  return async (dispatch) => {
    try {
      await dispatch(topicSingleReadBegin());
      const query = await DataService.get(`/topic-master/single/${topicId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(topicSingleReadSuccess(data.payload));
      } else {
        await dispatch(topicSingleReadSuccess(null));
        // await dispatch(regulationAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(topicSingleReadErr(err));
    }
  };
};


const FreezeTopicById = (id) => {
  return async (dispatch) => {
    try {
      await dispatch(topicStatusUpdateBegin());
      const query = await DataService.put(`/topic-master/freeze/${id}`);
      const token = getItem('access_token');
      if (token && query.data.Error === false) {
        await dispatch(topicStatusUpdateSuccess(query.data));
        message.success(query.data.message);
      } else {
        if (query.data.Error === true && query.data.statusCode === 401){
          message.error(query.data.message);
          dispatch(logOut())
        }
        throw query.data.message;
      }
      // const data = query.data
      // if (token && data !== null && data.Error === false) {
      //   await dispatch(topicStatusUpdateSuccess(data));
      // }
      // else if (data !== null && data.Error === true) {
      //   if (Object.keys(data).length >= 3 && data.statusCode == 401) {
      //     message.error(data.message)
      //     await dispatch(topicStatusUpdateSuccess(null));
      //     dispatch(logOut())
      //   }
      //   else {
      //     await dispatch(topicStatusUpdateSuccess(null));
      //   }
      // }
      // else {
      //   await dispatch(topicStatusUpdateSuccess(null));
      //   // await dispatch(regulationAddErr('Unauthorized access'));
      // }
    } catch (err) {
      await dispatch(topicStatusUpdateErr(err));
      message.error(err);
    }
  };
};

// not in use
const getTopicsByUnitId = (unitId) => {
  return async (dispatch) => {
    try {
      await dispatch(topicsReadBegin());
      const query = await DataService.get(`/studentmaster/get-topics-unit/${unitId}`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(topicsReadSuccess(query.data.payload));
      } else {
        await dispatch(topicsReadSuccess(null));
        // await dispatch(regulationAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(topicsReadErr(err));
    }
  };
};

const getStudentTopicsByUnitId = (unitId) => {
  return async (dispatch) => {
    try {
      const userId = getItem('userId')
      await dispatch(studentTopicsReadBegin());
      const query = await DataService.get(`/studentmaster/get-topics-unit/${unitId}/${userId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(studentTopicsReadSuccess(query.data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(studentTopicsReadSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(studentTopicsReadSuccess(null));
        }
      }
      else {
        await dispatch(studentTopicsReadSuccess(null));
        // await dispatch(regulationAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(studentTopicsReadErr(err));
    }
  };
};

const clearTopicByTopicId = (topicId) => {
  return async (dispatch) => {
    try {
      await dispatch(clearTopicBegin());
      const query = await DataService.delete(`/topicmaster/topic/${topicId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(clearTopicSuccess(data));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(clearTopicSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(clearTopicSuccess(null));
        }
      }
      else {
        await dispatch(clearTopicSuccess(null));
        // await dispatch(regulationAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(clearTopicErr(err));
    }
  };
};


const getTopicBySubjectId = (subjectId) => {
  return async (dispatch) => {
    try {
      await dispatch(topicsBySubjectIdBegin());
      const query = await DataService.get(`/topic-master/topics/${subjectId}`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(topicsBySubjectIdSuccess(data.payload));
      } else {
        await dispatch(topicsBySubjectIdSuccess([]));
        // await dispatch(regulationAddErr('Unauthorized access'));
      }
    } catch (err) {
      await dispatch(topicsBySubjectIdErr(err));
    }
  };
}

const ImportNewTopic = (formData) => {
  return async (dispatch) => {
    try {
      await dispatch(importTopicBegin());
      const query = await DataService.filePost(`/question-master/import`, formData);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        message.success(data.message)
        await dispatch(importTopicSuccess(data));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(importTopicSuccess(null));
          dispatch(logOut())
        }
        else if (data !== null && data.Error === true) {
          message.error(data.message)
          await dispatch(importTopicSuccess(null));
        }
      }
      else {
        await dispatch(importTopicSuccess(null));
      }
    } catch (err) {
      await dispatch(importTopicErr(err));
    }
  };
};


export {
  CreateNewTopic,
  getTopicById,
  FreezeTopicById,
  getTopicsByUnitId,
  clearTopicByTopicId,
  getStudentTopicsByUnitId,
  getTopicBySubjectId,
  ImportNewTopic
};
